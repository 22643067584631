import { Menu, MenuItem, Box, Typography, Divider } from '@material-ui/core'
import { textTransform } from '../../aux/aux';
import './popUpMenu.css';
import { useUpdateScrollableDocumentCtx } from '../../customHooks/useUpdateScrollableDocumentCtx';
import { useEffect } from 'react';

const PopUpMenu = ({ 
    anchorEl, 
    isOpen, 
    optionList, 
    onSelectedOption=()=>{}, 
    closeOnClick=true, 
    onClose=()=>{}, 
    onClick=()=>{} 
}) => {
    const setIsScrollableDocument = useUpdateScrollableDocumentCtx()
    const handleOnClose = () => {
        onClose();
        setIsScrollableDocument(true);
    }

    const handleOnClick = () => {
        onClick();
        if(closeOnClick){
            handleOnClose();
        }
    }

    const handleOnSelectedOption = (e, index) => {
        onSelectedOption(index);
    }

    useEffect(() => {
        if(isOpen){
            setIsScrollableDocument(!isOpen)
        }
    },[isOpen])

    return (
        <Menu
            disableScrollLock={true}
            anchorEl={anchorEl.current}
            getContentAnchorEl={null}
            open={isOpen}
            onClose={handleOnClose}
            onClick={handleOnClick}
            PaperProps={{
                elevation: 0,
                className: 'popup-menu',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
        >
            <Box>
                {
                    optionList.map( (optionObject, index) => {
                        let Icon = optionObject.icon;
                        let label = optionObject.label;
                        let isDisabled = optionObject.isDisabled || false
                        return(
                            <div key={index}>
                                <MenuItem  onClick={(e) => handleOnSelectedOption(e, index)} disabled={isDisabled} >
                                    <Icon  className='popup-menu-item-icon'/>
                                    <Typography className='popup-menu-item-label'>
                                        {textTransform('title', label)}            
                                    </Typography>
                                </MenuItem>
                                {optionObject.followedByDivider && <Divider  className='popup-menu-divider'/>}
                            </div>
                        )
                    })
                }
            </Box>
        </Menu>    
    )
}

export default PopUpMenu