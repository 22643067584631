import { Box, Grid, Typography } from '@material-ui/core'
import { fromCentTo2dec, getFinalAmountWithoutPromoCode, productAmountHasPromoCodeApplied } from '../../aux/purchaseHelpers';
import { Skeleton } from '@mui/material'
import ImgDN from '../../cdn/imgDN';
import cloudinary from '../../cdn/providers/cloudinary';
import { useTheme } from '@material-ui/styles'
import { useTranslation } from 'react-i18next';
import CheckoutItemQuantity from '../CheckoutItemQuantity/CheckoutItemQuantity';
import CheckoutProductAmount from '../CheckoutProductAmount/CheckoutProductAmount';
import './checkoutItem.css';
import { useEffect, useState } from 'react';
import { getFromSafeObject, isNotEmptyObject, textTruncate } from '../../aux/aux';

const CheckoutItem = ({ product, productAmount, isCheckoutUpdating, deleteOrUpdateItemQuantity, nameToDisplay }) => {
    const MAX_NAME_CHAR = 23;
    const { t } = useTranslation();
    const [imgURL, setImgURL] = useState(null);
    const theme = useTheme();
    const styles = {
        icon:{
            color:theme.palette.success.main,
            display:"block",
            fontSize: theme.typography.body2.fontSize,
        },
    }
    useEffect(() => {
        let imgURL;
        const imgIcon = getFromSafeObject(product, 'imageDetails.main');
        if(isNotEmptyObject(imgIcon)){
            const imgPublicId = imgIcon.publicId;
            const assetProvider = new ImgDN(cloudinary);
            imgURL = assetProvider.getAssetUrl(imgPublicId);
        }else{
            imgURL = null;
        }
        setImgURL(imgURL)
    },[product])

    return (
        <Grid className={`checkout-item-main-container`} container direction="row" justifyContent='space-between'>
            <Grid className='checkout-item-description-container' item>
                <Grid container direction="row" alignItems='center' style={{height:"100%"}}>
                    <Grid item className='checkout-item-description-img-container'>
                        {imgURL ? 
                            <img className='checkout-item-description-img' alt='product-img' src={imgURL}/>
                            :
                            <Skeleton className='checkout-item-description-img-skeleton' variant="rectangular"/>
                        }
                    </Grid>
                    <Grid item className='checkout-item-description-text'>
                        <Grid container direction='column' justifyContent='space-between' style={{height: "100%"}}>
                            <Grid item>
                                {isNotEmptyObject(product) ?
                                    <Typography variant="body2" >
                                        {textTruncate(nameToDisplay ? nameToDisplay : getFromSafeObject(product, 'description.nameTranslated'), MAX_NAME_CHAR)}
                                    </Typography>
                                    :
                                    <Skeleton variant='text' width={'50%'}/>
                                }
                            </Grid>
                            <Grid item>
                                <CheckoutItemQuantity 
                                    product={product} 
                                    isCheckoutUpdating={isCheckoutUpdating} 
                                    deleteOrUpdateItemQuantity={deleteOrUpdateItemQuantity} 
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className='checkout-item-price-container'>
                <CheckoutProductAmount amountObject={productAmount} />
            </Grid>
        </Grid>
    )
}

export default CheckoutItem