import { useState, useRef, useEffect } from 'react';
import { useTheme } from '@material-ui/styles';
import { TextField } from '@material-ui/core';
import './searchLocationInput.css'
import { getFromSafeObject, loadScript } from '../../aux/aux';

let autoComplete;

function handleScriptLoad(updateQuery, autoCompleteRef, queryOptions={}) {
    const places = getFromSafeObject(window, 'google.maps.places');
    if(places){
        autoComplete = new places.Autocomplete(
            autoCompleteRef.current.children[0].children[0],
            queryOptions
        );
        autoComplete.setFields(["address_components", "formatted_address"]);
        autoComplete.addListener("place_changed", () =>
            handlePlaceSelect(updateQuery)
        );
    }
}

/**
 * Set the selected asked selected place
 * @param {*} updateQuery 
 */
async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(addressObject);
}

function SearchLocationInput({ callback, placeholder, queryOptions={}, onError=()=>{}, ...props }) {
    const theme = useTheme();
    const [query, setQuery] = useState("");
    const autoCompleteRef = useRef(null);
    const styles = {
        mainContainer: {
            fontFamily: `${theme.typography.body1.fontFamily} !important`
        }
    }
    const updateQuery = (addressObject) => {
        const query = addressObject.formatted_address;
        setQuery(addressObject.formatted_address);
        callback(addressObject)
    }
    const handleLoadScriptError = () => {
        console.info('Google Maps api is no available')
        onError()
    }

    useEffect(() => {
        /*
        This global function is required by googleapis source: https://developers.google.com/maps/documentation/javascript/overview#Loading_the_Maps_API
        We use a dummy function by now since we are dinamically loading our script by our own.
        However, if this causes issues we should take a look at react services https://www.supremetech.vn/a-better-way-to-use-services-in-react-application/
        Some links to react and js google maps packages and videos:
            * https://www.youtube.com/watch?v=9e-5QHpadi0
            * https://www.npmjs.com/package/@react-google-maps/api
            * https://www.npmjs.com/package/@googlemaps/js-api-loader
        */
        const callback = () => handleScriptLoad(updateQuery, autoCompleteRef, queryOptions);
        window.$globalDummyFn = callback
        loadScript(
            'google-maps-api',
            `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&loading=async&callback=$globalDummyFn`,
            callback,
            handleLoadScriptError
        );
        return(() => {
            // Remove google map elements and listeners
            const maps = window.google?.maps;
            if(maps){
                window.google.maps.event.clearInstanceListeners(autoComplete)
                let mapDivList = document.getElementsByClassName('pac-container');
                for(let i = 0; i < mapDivList.length; i++){
                    let element = mapDivList[i];
                    element.remove()
                }
            }
        })
    }, []);

    return (
        <div {...props} className={`search-location-input ${props.className}`}>
            <TextField 
                ref={autoCompleteRef}
                name="newPassword"
                type="text"
                // This is done to prevent chrome autocompletion
                // https://github.com/mui/material-ui/issues/2653
                inputProps={{
                    autoComplete: 'new-password',
                    style:{
                        textOverflow:'ellipsis',
                    },
                    form: {
                    autocomplete: 'off',
                    },
                }}
                onChange={event => setQuery(event.target.value)}
                placeholder={placeholder}
                value={query}
                style={{width:"100%"}}
            />
        </div>
    );
}

export default SearchLocationInput