import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { Typography, Grid } from '@material-ui/core'
import { TextField, InputAdornment, IconButton } from '@mui/material'
import PopUpModal from '../PopUpModal/PopUpModal'
import PopUpModalHeader from '../PopUpModalHeader/PopUpModalHeader'
import SimpleNoModalDialog from '../SimpleNoModalDialog/SimpleNoModalDialog';
import { textTransform } from '../../aux/aux'
import { MAX_PASSWORD_LENGTH } from '../../aux/userHelpers'
import { getCustomErrorOrUndefined } from '../../aux/errorHelpers'
import { requestUpdateUserEmail } from '../../services/userServices'
import { aesDecipher } from '../../aux/cryptoHelpers';
import './popUpUserEmailUpdate.css'

const PopUpUserEmailUpdate = ({ show, onClickClose, userId }) => {
    const POP_UP_WIDTH = 380;
    const ERROR_TIMEOUT_MS = 4000;
    const errorTypes = ['password', 'newEmail'];
    const { t } = useTranslation('common', { keyPrefix: "popUpUserEmailUpdate" });
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [formState, setFormState] = useState({ form:{}, formErrorMap:{} });
    const [unknownError, setUnknownError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRequestedToEmail, setIsRequestedToEmail] = useState(null);
    const abortControllerRef = useRef(null);

    const styles = {
        email: {
            fontStyle: 'italic'
        }
    }

    const resetFormState = () => {
        setIsRequestedToEmail(prev => null);
        setFormState({ form:{}, formErrorMap:{} })
    }

    const onChangeForm = (e) => {
        const value = e.target.value;
        const field = e.target.name;
        setFormState(prev => {
            return { form: { ...prev.form, [field]:value }, formErrorMap: { ...prev.formErrorMap, [field]:false }}
        });
    }

    const requestSetNewEmail = async(e, uId=userId, signal=abortControllerRef.current.signal) => {
        try{
            e.preventDefault();
            setIsLoading(true);
            const password = formState.form.password;
            const newEmail = formState.form.newEmail;
            const res = await requestUpdateUserEmail(uId, password, newEmail, 0, signal);
            const {
                sendTo
            } = res.data;
            if(!signal.aborted){
                const email = aesDecipher(sendTo, process.env.REACT_APP_CRYPTO_HASH_KEY);
                setIsRequestedToEmail(prev => email);
                setIsLoading(false);
            }
        }catch(error){
            const customError = getCustomErrorOrUndefined(error);
            let type = null;
            if(customError){
                switch(customError.cause){
                    case 'db_101':
                        // duplicate email
                        type = errorTypes[1]
                        break;
                    case 'sr_002':
                        // wrong password
                        type = errorTypes[0]
                        break;
                    default:
                        // unknown error
                }
            }
            if(!signal.aborted){
                setFormState(prev => ({ ...prev, formErrorMap:{ ...prev.formErrorMap, [type]: true }}));
                if(type === null){
                    setUnknownError(true);
                }
                setIsLoading(false);
            }
        }
    }

    useEffect(() => {
        if(unknownError){
            const timeoutId = setTimeout(() => setUnknownError(false), ERROR_TIMEOUT_MS);
            return(() => { clearTimeout(timeoutId) })
        }
    },[unknownError])

    useEffect(() => {
        if(show === true){
            const abortController = new AbortController();
            abortControllerRef.current = abortController;
            return(() => { 
                abortController.abort();
            })
        }else{
            resetFormState()
        }
    },[show])

    return (
        <PopUpModal showModal={show} onClickClose={onClickClose} width={POP_UP_WIDTH}>
            <PopUpModalHeader
                title={t("accountEmail")}
            />
            {isRequestedToEmail ? 
                    <SimpleNoModalDialog
                        className='pop-up-user-email-update-main-container'
                        onAgree={onClickClose}
                    >
                        <Typography variant='body1' align='center' paragraph>
                            {t('weSentYouAConfirmationEmailToYourCurrentAccount')}
                        </Typography>
                        <Typography variant='body1' align='center' style={styles.email}>
                            {isRequestedToEmail}
                        </Typography>
                    </SimpleNoModalDialog>
                :
                    <form onSubmit={requestSetNewEmail}>
                        <SimpleNoModalDialog
                            className='pop-up-user-email-update-main-container'
                            onDisagree={ onClickClose }
                            isLoading={ isLoading }
                            isError={ unknownError }
                            errorMsg={ `${textTransform('title', t("errorUpdatingEmail"))}.\n${textTransform('title', t("tryItLater"))}` }
                            agreeButtonType='submit'

                        >
                            <TextField 
                                className='pop-up-user-email-update-form-item'
                                disabled={isLoading}
                                required 
                                type="email" 
                                name='newEmail' 
                                value={formState.form.newEmail || ''} 
                                onChange={onChangeForm}
                                label={textTransform('title', t("newEmail"))} 
                                error={formState.formErrorMap[errorTypes[1]] === true}
                                helperText={ formState.formErrorMap[errorTypes[1]] === true ? textTransform('title', t("emailBelongsToAnotherUser")) : undefined}
                                variant='standard'
                                color="primary" 
                                fullWidth 
                            />
                            <TextField 
                                className='pop-up-user-email-update-form-item'
                                type={isPasswordVisible ? "text" : "password"} 
                                name="password" 
                                label={textTransform('title', t("password"))} 
                                value={formState.form.password || ''}
                                disabled={isLoading}
                                onChange={onChangeForm} 
                                required 
                                error={ formState.formErrorMap[errorTypes[0]] === true }
                                helperText={ formState.formErrorMap[errorTypes[0]] === true ? textTransform('title', t("wrongPassword")) : undefined}
                                variant='standard'
                                inputProps={{ maxLength: MAX_PASSWORD_LENGTH}}
                                InputProps={{endAdornment:(
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={(e) => setIsPasswordVisible(prev => (!prev))}
                                        size="small"
                                        edge="end"
                                        >
                                        {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )}}
                                fullWidth
                            />
                        </SimpleNoModalDialog>
                    </form>
            }
        </PopUpModal>
    )
}

export default PopUpUserEmailUpdate