import { Box } from '@material-ui/core'
import { LinearProgress, CircularProgress } from '@material-ui/core'

// Style
import './loadingComponent.css'

const LoadingComponent = ({visibleElements='both', circleSize=50, barStyle={}, circleStyle={}, bothStyle={}}) => {
    const showCircle = visibleElements === 'both' || visibleElements === 'circle'
    const showBar = visibleElements === 'both' || visibleElements === 'bar'
    return (
        <div className='loading-component-main-container'>
            { showBar &&
                <Box className='loading-component-bar-progress'>
                    <LinearProgress style={{...bothStyle, ...barStyle}}/>
                </Box>
            }
            {showCircle &&
                <Box className='loading-component-circular-progress'>
                    <CircularProgress size={circleSize} style={{...bothStyle, ...circleStyle}}/>
                </Box>
            }
        </div>
    )
}

export default LoadingComponent
