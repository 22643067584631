import { useTranslation } from 'react-i18next';
import { Typography, Grid, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { textTransform } from '../../aux/aux';
import LigthArrow from '../../img/ligth-arrow.svg';
import QuexEdLogo from '../../img/quex-ed-logo.svg';
import QuexSLogo from '../../img/quex-s-logo.svg';
import EductorLogo from '../../img/eductor-logo.svg';
import ScioLogo from '../../img/scio-logo.svg';
import IndigoLogo from '../../img/indigo-logo.svg';
import NucleusLogo from '../../img/nucleus-logo.svg';
import EDXLogo from '../../img/edx-logo.svg';
import './landingBrands.css'
import { useEffect } from 'react';

const LandingBrands = () => {
    const theme = useTheme();
    const [ t, i18n] = useTranslation('landing', { keyPrefix: 'landingBrands'});
    const matchDesktop = useMediaQuery('(min-width:1120px)');
    const matchMobile = useMediaQuery('(max-width:680px)');
    const styles = {
        wrapper:{
            backgroundColor: theme.palette.background.dark,
            color: theme.palette.text.contrast
        },
        icon:{
            display: 'block',
            fill: theme.palette.grey[400],
        }
    }
    return (
        <div className='landing-brands-wrapper' style={styles.wrapper}>
            {matchDesktop ?
                <Grid container className='landing-brands-main-container' alignItems='center'>
                    <Grid item className='landing-brands-arrow-container'>
                        <LigthArrow className='landing-brands-arrow' style={styles.icon}/>
                    </Grid>
                    <Grid item className='landing-brands-text-container'>
                        <Typography variant='body1'>
                            {textTransform('title', t('chosenByHundredsOfExpertsIn'))}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Grid container direction='column' className='landing-brands-brands-container'>
                            <Grid 
                                item 
                                container 
                                className='landing-brands-brands-first-row-container' 
                                justifyContent='space-between' 
                                alignItems='center'
                            >
                                <Grid item>
                                    <QuexEdLogo style={styles.icon}/>
                                </Grid>
                                <Grid item>
                                    <QuexSLogo style={styles.icon}/>
                                </Grid>
                                <Grid item>
                                    <EductorLogo style={styles.icon}/>
                                </Grid>
                                <Grid item>
                                    <ScioLogo style={styles.icon}/>
                                </Grid>
                            </Grid>
                            <Grid
                                item 
                                container 
                                className='landing-brands-brands-second-row-container' 
                                justifyContent='space-between' 
                                alignItems='center'
                            >
                                <Grid item xs={1}/>
                                <Grid item >
                                    <IndigoLogo style={styles.icon}/>
                                </Grid>
                                <Grid item >
                                    <NucleusLogo style={styles.icon}/>
                                </Grid>
                                <Grid item >
                                    <EDXLogo style={styles.icon}/>
                                </Grid>
                                <Grid item xs={1}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                :
                <Grid container direction='column' className='landing-brands-main-container' justifyContent='center'>
                    <Grid item className='landing-brands-text-container' align='center'>
                        <Typography variant='body1'>
                            {textTransform('title', t('chosenByHundredsOfExpertsIn'))}
                        </Typography>
                    </Grid>
                    <Grid item container alignItems='center'>
                        <Grid item className='landing-brands-arrow-container'>
                                <LigthArrow className='landing-brands-arrow' style={styles.icon}/>
                        </Grid>
                        <Grid item xs>
                            <Grid container direction='column' className='landing-brands-brands-container'>
                                { !matchMobile ?
                                    <>
                                        <Grid 
                                            item 
                                            container 
                                            className='landing-brands-brands-first-row-container' 
                                            justifyContent='space-between' 
                                            alignItems='center'
                                        >
                                            <Grid item>
                                                <QuexEdLogo style={styles.icon}/>
                                            </Grid>
                                            <Grid item>
                                                <QuexSLogo style={styles.icon}/>
                                            </Grid>
                                            <Grid item>
                                                <EductorLogo style={styles.icon}/>
                                            </Grid>
                                            <Grid item>
                                                <ScioLogo style={styles.icon}/>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item 
                                            container 
                                            className='landing-brands-brands-second-row-container' 
                                            justifyContent='space-between' 
                                            alignItems='center'
                                        >
                                            <Grid item xs={1}/>
                                            <Grid item >
                                                <IndigoLogo style={styles.icon}/>
                                            </Grid>
                                            <Grid item >
                                                <NucleusLogo style={styles.icon}/>
                                            </Grid>
                                            <Grid item >
                                                <EDXLogo style={styles.icon}/>
                                            </Grid>
                                            <Grid item xs={1}/>
                                        </Grid>
                                    </>
                                :
                                    <>
                                        <Grid 
                                        item 
                                        container 
                                        className='landing-brands-brands-first-row-container' 
                                        justifyContent='space-around' 
                                        alignItems='center'
                                        >
                                            <Grid item>
                                                <QuexEdLogo style={styles.icon}/>
                                            </Grid>
                                            <Grid item>
                                                <QuexSLogo style={styles.icon}/>
                                            </Grid>
                                        </Grid>
                                        <Grid 
                                        item 
                                        container 
                                        className='landing-brands-brands-second-row-container' 
                                        justifyContent='space-around' 
                                        alignItems='center'
                                        >
                                            <Grid item>
                                                <EductorLogo style={styles.icon}/>
                                            </Grid>
                                            <Grid item>
                                                <ScioLogo style={styles.icon}/>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item 
                                            container 
                                            className='landing-brands-brands-third-row-container' 
                                            justifyContent='space-around' 
                                            alignItems='center'
                                        >
                                            <Grid item >
                                                <EDXLogo style={styles.icon}/>
                                            </Grid>
                                            <Grid item >
                                                <NucleusLogo style={styles.icon}/>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item 
                                            container 
                                            className='landing-brands-brands-fourth-row-container' 
                                            justifyContent='space-around' 
                                            alignItems='center'
                                        >
                                            <Grid item >
                                                <IndigoLogo style={styles.icon}/>
                                            </Grid>
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </div>
    )
}

export default LandingBrands