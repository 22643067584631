import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { Box, Typography, Grid, Checkbox, FormControlLabel } from '@material-ui/core'
import { TextField, MenuItem, InputAdornment, IconButton } from '@mui/material'

import { ipSessionVariables, getReliableLocale, isUserLogged } from '../../aux/sessionHelpers';

import CustomPopover from '../CustomPopover/CustomPopover';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import ButtonLoading from '../ButtonLoading/ButtonLoading';
import ToggleMsg from '../ToggleMsg/ToggleMsg';

import { textTransform } from '../../aux/aux'
import { getProfessions } from '../../services/staticContentServices';
import { getCustomErrorOrUndefined } from '../../aux/errorHelpers';
import { isStrongUserPassword, MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from '../../aux/userHelpers'
import { useLocaleCtx } from '../../customHooks/useLocaleCtx';
import { useCountryAndCurrencyCtx } from '../../customHooks/useCountryAndCurrencyCtx';

// Style
import './createAccountForm.css';
import { createUserAccount } from '../../services/userServices';
import klaviyoWrapper from '../../aux/klaviyoHelpers';


const CreateAccountForm = ({ onCreate=()=>{}, onCreateError=()=>{}, onChange=()=>{}, onRender=()=>{}}) => {
    const MAX_REQUEST_ATTEMPT = 10; 
    const { t } = useTranslation('common', { keyPrefix: 'createAccountForm' }) ;
    const localeCtx = useLocaleCtx();
    const countryAndCurrencyCtx = useCountryAndCurrencyCtx()
    const createAccountStatusTypes = ['idle', 'loading']
    const createAccountErrorTypes = ['unknown', 'existingEmail'];
    const [professionArray, setProfessionArray] = useState(null);
    const [visibleFieldMap, setVisibleFieldMap] = useState({})
    const [focusOnPassword, setFocusOnPassword] = useState(false);
    const [requestErrorCount, setRequestErrorCount] = useState(0);
    const [errorCreatingAccount, setErrorCreatingAccount] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const passwordTextFieldRef = useRef(null);
    const abortControllerRef = useRef(null);
    const [createAccountState, setCreateAccountState] = useState({
        status: createAccountStatusTypes[0],
        form:{
            firstName:'',
            lastName:'',
            email:'',
            password:'',
            confirmPassword:'',
            professionName:'',
            newsLetterAndMarketingConsent: true,
        },
        formErrorMap:{}
    })

    const requestFormValues = async(signal) => {
        requestProfessions(signal);
    }

    const isServerTired = (attempt) => {
        return attempt >= MAX_REQUEST_ATTEMPT
    }

    const requestProfessions = async(signal) => {
        try{
            const res = await getProfessions()
            let {
                professionArray
            } = res.data;
            if(!signal.aborted){
                const otherIndex = professionArray.findIndex( prof => prof.name === 'other');
                professionArray = professionArray.sort((a, b) => {
                    let sortValue = 0;
                    if(a.name === 'other'){
                        sortValue = 1
                    }else{
                        if(b.name === 'other'){
                            sortValue = -1
                        }
                    }
                    return sortValue
                })
                setProfessionArray(professionArray);
            }
        }catch(error){
            console.log(``)
            setProfessionArray([])
        }
    }
    
    const onChangeForm = (e) => {
        const inputType = e.target.type;
        const field = e.target.name;
        const value = inputType === 'checkbox' ? e.target.checked : e.target.value;
        setCreateAccountState(prev => {
            const tempForm = { 
                ...prev.form, 
                [field]: field === 'professionName' ? value.toLowerCase() : value,
            };
            const fieldError = {
                'password': tempForm.password && !isStrongUserPassword(tempForm.password),
                'confirmPassword': tempForm.confirmPassword && tempForm.confirmPassword !== tempForm.password,
            }
            return { ...prev, form: tempForm, formErrorMap:fieldError }
        })
        onChange(field, value);
    }

    const createNewAccount = async (e, createAccountState, signal=abortControllerRef.current.signal) => {
        try{
            e.preventDefault();
            setCreateAccountState(prev => ({ ...prev, status:createAccountStatusTypes[1] }))
            const {
                confirmPassword,
                newsLetterAndMarketingConsent,
                ...user
            } = createAccountState.form;
            user.localeId = localeCtx.localeId;
            const {
                countryCode,
                stateCode,
            } = countryAndCurrencyCtx;
            const consentArray = [
                ...(newsLetterAndMarketingConsent ? [ 'newsletter', 'marketing' ] : []),
                'user'
            ];
            const res = await createUserAccount(user, consentArray, {countryCode, stateCode}, 0, signal);
            user.id = res.data.id;
            setCreateAccountState(prev => ({ ...prev, status:createAccountStatusTypes[0] }))
            onCreate({ userId:user.id, email:user.email, password:user.password });
        }catch(error){
            const customError = getCustomErrorOrUndefined(error);
            let creatingAccountError= null;
            if(customError){
                switch(customError.cause){
                    case 'db_101':
                        // existing email
                        creatingAccountError = createAccountErrorTypes[1];
                        break;
                    case 'sr_005':
                        // user was not provided
                    default:
                }
            }
            if(creatingAccountError == null ){
                creatingAccountError = createAccountErrorTypes[0];
            }
            setErrorCreatingAccount(creatingAccountError);
            setRequestErrorCount(prev => {return prev + 1});
            onCreateError(creatingAccountError);
            setCreateAccountState(prev => ({ ...prev, status:createAccountStatusTypes[0] }))
        }
    };

    const isLoading = () => {
        return createAccountState.status === createAccountStatusTypes[1];
    }

    const getErrorCreatingAccountMsg = () => {
        let msg = '';
        switch(errorCreatingAccount){
            case createAccountErrorTypes[1]:
                msg = `${textTransform('title', t("existingEmailError"))}`
                    +`\n${textTransform('title', t("loginInstead"))}`
                break;
            case createAccountErrorTypes[0]:
                msg = `${textTransform('title', t("requestError"))}`
                    +`\n${textTransform('title', t("tryItLater"))}`
            default:
        }
        return msg
    }


    useEffect(() => {
        const isTired = isServerTired(requestErrorCount);
        const isError = Object.values(createAccountState.formErrorMap).find(error => error === true) != null;
        setIsDisabled(isError || isTired)
    }, [createAccountState, requestErrorCount])

    useEffect(()=>{
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        requestFormValues(abortController.signal);
        return(() => {
            abortController.abort()
        })
    },[])

    useEffect(() => {
        onRender();
    })

    return (
        professionArray === null ?
            <Box height={200}>
                <LoadingComponent visibleElements='circle'/>
            </Box>
        :
            <form onSubmit={ e => createNewAccount( e, createAccountState)}>
                <Grid container direction="column">
                    <Grid item>
                        <Grid container justifyContent='space-between' className='create-account-form-input'>
                            <Grid item className="create-account-form-parallel-inputs">
                                <TextField type="text" required name="firstName" label={textTransform('title', t("name"))}
                                value={createAccountState.form.firstName} onChange={onChangeForm} 
                                disabled={isLoading()} variant='standard' fullWidth />
                            </Grid>
                            <Grid item className="create-account-form-parallel-inputs">
                                <TextField type="text" name="lastName" label={textTransform('title', t("lastName"))}
                                value={createAccountState.form.lastName} onChange={onChangeForm}
                                disabled={isLoading()} variant='standard' fullWidth />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className='create-account-form-input'>
                        <TextField required type="email" name="email" label={textTransform('title', t("email"))} 
                        value={createAccountState.form.email} onChange={onChangeForm}
                        disabled={isLoading()} variant='standard' fullWidth />
                    </Grid>
                    <Grid item className='create-account-form-input'>
                        <CustomPopover 
                            anchorEl={passwordTextFieldRef}
                            open={focusOnPassword}
                            anchorOrigin={{vertical:'bottom', horizontal:'center'}} 
                            transformOrigin={{vertical:'top', horizontal:'center'}}
                        >
                            <Typography variant='body2' style={{whiteSpace: 'pre-line'}}>
                                {
                                    `${textTransform('title', t("lengthBetween", {min: MIN_PASSWORD_LENGTH, max: MAX_PASSWORD_LENGTH}))}`
                                    +`\n${textTransform('title', t("mustContainAtLeast"))}:`
                                    +`\n - ${t("oneNumber")}`
                                    +`\n - ${t("oneCapitalLetter")}`
                                    +`\n - ${t("oneLowerLetter")}`
                                    +`\n - ${t("oneSpecialChar")}`
                                }
                            </Typography>
                        </CustomPopover>
                        <TextField 
                            ref={passwordTextFieldRef}
                            type={visibleFieldMap.password ? "text" : "password"} 
                            name="password" 
                            label={textTransform('title', t("password"))} 
                            value={createAccountState.form.password}
                            disabled={isLoading()}
                            error={createAccountState.formErrorMap.password === true}
                            helperText={createAccountState.formErrorMap.password === true ? textTransform('title', t("tooWeakPassword")) : undefined}
                            onChange={onChangeForm} 
                            onFocus={() => setFocusOnPassword(true)}
                            onBlur={() => setFocusOnPassword(false)}
                            required 
                            variant='standard'
                            inputProps={{ maxLength: MAX_PASSWORD_LENGTH}}
                            InputProps={{endAdornment:(
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={(e) => setVisibleFieldMap(prev => ({...prev, ['password']: !prev.password}))}
                                    size="small"
                                    edge="end"
                                    >
                                    {visibleFieldMap.password ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )}}
                            fullWidth
                        />
                    </Grid>
                    <Grid item className='create-account-form-input'>
                        <TextField 
                            type={visibleFieldMap.confirmPassword ? "text" : "password"}
                            name="confirmPassword" 
                            label={textTransform('title', t("confirmPassword"))} 
                            value={createAccountState.form.confirmPassword}
                            disabled={isLoading() }
                            error={createAccountState.formErrorMap.confirmPassword === true}
                            helperText={createAccountState.formErrorMap.confirmPassword === true ? textTransform('title', t("passwordUnmatch")) : undefined}
                            onChange={onChangeForm} 
                            required 
                            inputProps={{ maxLength: MAX_PASSWORD_LENGTH}}
                            InputProps={{
                                endAdornment:(
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={(e) => setVisibleFieldMap(prev => ({...prev, ['confirmPassword']: !prev.confirmPassword}))}
                                        size="small"
                                        edge="end"
                                        >
                                        {visibleFieldMap.confirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            variant='standard'
                            fullWidth
                        />
                    </Grid>
                    {professionArray.length > 0 &&
                        <>
                            <Grid item className='create-account-form-section'>
                                <Typography variant='body1'>
                                    <Box fontWeight='bold'>
                                        {textTransform('title', t("yourSpeciality"))}
                                    </Box>
                                </Typography>
                            </Grid>
                            <Grid item className='create-account-form-input' >
                                <TextField 
                                    name="professionName" 
                                    label={textTransform('title', t("speciality"))}
                                    value={createAccountState.form.professionName} 
                                    onChange={onChangeForm} 
                                    select
                                    disabled={isLoading()} 
                                    variant='standard' 
                                    fullWidth
                                >
                                    {professionArray.map((specialization) => {
                                        return(
                                            <MenuItem  key={specialization.name} value={specialization.name}>
                                                {textTransform('title', specialization.description.nameTranslated || specialization.name)}
                                            </MenuItem>
                                        )
                                    })}
                                    <MenuItem key={'none'} value={''}>
                                        {textTransform('title', t("none"))}
                                    </MenuItem>
                                </TextField>
                            </Grid>
                        </>
                    }
                    <Grid item className='create-account-form-section'>
                        <FormControlLabel 
                            style={{alignItems:'flex-start'}}
                            label={
                                <Typography variant='body1'>
                                    {textTransform('title', t("newsLetterAndMarketingConsent"))}
                                </Typography>
                            }
                            control={
                                <Checkbox 
                                style={{paddingTop:'0px'}}
                                name='newsLetterAndMarketingConsent'
                                checked={createAccountState.form.newsLetterAndMarketingConsent}
                                onChange={onChangeForm}
                                color='primary'
                                disabled={isLoading()}
                                />
                            }
                        />
                    </Grid>
                    <Grid item className='create-account-form-button' align="center">
                        <ButtonLoading 
                            isLoading={isLoading()}
                            disabled={isDisabled}
                            type='submit' 
                            label={t("createAccount")} 
                            color="primary" 
                            variant="outlined" 
                        />
                    </Grid>
                    <Grid item>
                        <ToggleMsg
                            className={`create-account-form-error-container ${errorCreatingAccount != null ? 'show': ''} `}
                            isShown={errorCreatingAccount != null} 
                            onShowTime={() => {
                                const isTired = isServerTired(requestErrorCount);
                                setErrorCreatingAccount(isTired ? 'unknown' : null)
                            }}
                            msg={ getErrorCreatingAccountMsg() } 
                        />
                    </Grid>
                </Grid>
            </form>
    )
}

export default CreateAccountForm
