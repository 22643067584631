import { Button, Grid, Typography, useTheme } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { isNotEmptyObject, textTransform } from '../../aux/aux';
import './defaultPaymentMethodSelect.css'

const DefaultPaymentMethodSelect = ({ selected, onSelect, defaultPaymentMethod }) => {
    const theme = useTheme();
    const { t } = useTranslation('common', { keyPrefix: "defaultPaymentMethodSelect" })
    const styles = {
        mainContainer: {
            borderRadius: "5px",
            backgroundColor: theme.palette.common.white,
            ...(selected ? 
                {
                    // boxShadow: `0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 1px ${theme.palette.primary.main}`
                    border: `solid 2px ${theme.palette.primary.main}`,
                    margin: '0px'
                }
                :
                {
                    border: "solid 1px #e6e6e6",
                    margin: "1px"
                })
        },
        selectedFont:{
            color: theme.palette.primary.main,
        },
        icon:{ 
            display: 'block'
        }
    }
    const handleOnClick = (e) => {
        e.preventDefault();
        onSelect();
    } 

    return(
        <div onClick={handleOnClick} 
            className='payment-method-select-main-container'
            style={styles.mainContainer}
        >
            <Grid container direction='column'>
                <Grid item style={selected === true ? styles.selectedFont : {}} >
                    <CreditCardIcon style={styles.icon} height={0.8}/>
                </Grid>
                <Grid item>
                    {defaultPaymentMethod && isNotEmptyObject(defaultPaymentMethod) ?
                        <Grid container justifyContent='space-between'>
                            <Grid item style={selected === true ? styles.selectedFont : {}} >
                                <Typography className='default-payment-method-label' >
                                    {textTransform('title', t(defaultPaymentMethod.type))}
                                </Typography>
                            </Grid>
                            <Grid item> 
                                <Grid container >
                                    <Grid item>
                                        <Typography className='default-payment-method-details' style={{padding:'0px 5px'}}>
                                            {defaultPaymentMethod.brand.toUpperCase()}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography className='default-payment-method-details'>
                                            {`${t("endsWith")} ${defaultPaymentMethod.last4}`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        :
                        <Typography variant='caption'>
                            {textTransform('title', t("thereIsNoDefaultMethod"))}
                        </Typography>
                    }
                </Grid>
            </Grid>
        </div>
    )
}

export default DefaultPaymentMethodSelect