import { useTheme } from '@material-ui/styles'
import FAQItem from '../FAQItem/FAQItem'
import './faqItemList.css';

const FAQItemList = ({ faqArray, faqItemProps={} }) => {
    const theme = useTheme()
    const styles = {
        wrapper:{
        },
        item:{
            borderColor: theme.palette.divider
        }
    }
    return (
        faqArray ?
            faqArray.map((faqItem, index) => {
                return(
                    <div key={index} className='faq-item-list-item' style={styles.item}>
                        <FAQItem item={faqItem} {...faqItemProps} />
                    </div>
                )
            })
            :
            null
  )
}

export default FAQItemList