import { useEffect, useState } from "react";
import { Skeleton } from '@mui/material'
import { Box, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { useTheme } from '@material-ui/styles'
import { useTranslation } from "react-i18next";
import { textTransform } from "../../aux/aux";
import ImgDN from "../../cdn/imgDN";
import cloudinary from "../../cdn/providers/cloudinary";
import './customerServiceBadge.css'


const CustomerServiceBadge = (props) =>{
    const matchMobile = useMediaQuery('(max-width:680px)');
    const BADGE_IMG_SIZE_PX = matchMobile ? 150 : 150;
    const DEFAULT_EMPLOYEE = {
        fullName: 'Olivia Nogueira',
        position: 'customerSupport',
        publicId: 'qxi/employee/employee-olivia_nogueira-p4inri'
    }
    const { t } = useTranslation('common');
    const theme = useTheme();
    const [employee, setEmployee] = useState(null);
    const styles = {
        badgeImg:{
            width: `${BADGE_IMG_SIZE_PX}px`,
            height: `${BADGE_IMG_SIZE_PX}px`
        },
        fullName:{
            // lineHeight: '1rem',
            fontWeight: 'bold'
        },
        position:{
            color: theme.palette.grey[400],
        }
    }
    const requestEmployee = async (signal=null)=>{
        let employ
        try{
            // TODO : implement
            throw new Error('debug');
        }catch(error){
            if(!signal.aborted){
                employ = DEFAULT_EMPLOYEE;
            }
        }finally{
            let imgURL;
            try{
                const assetProvider = new ImgDN(cloudinary);
                if(assetProvider){
                    const imgPublicId = employ.publicId;
                    imgURL = assetProvider.getAssetUrl(imgPublicId);
                }else{
                    throw new Error('no asset provider available');
                }
            }catch(err){
                imgURL = null;
            }finally{
                employ.imgURL = imgURL;
            }
            setEmployee(employ);
        }
    }
    useEffect(() => {
        const abortController = new AbortController();
        requestEmployee(abortController.signal);
        return(() => {
            abortController.abort()
        })
    }, [])

    return(
        <div className="customer-service-badge-main" >
            {employee ? 
                <Grid container direction="column" justifyContent="center" {...props}>
                    <Grid item style={{paddingBottom:"10px"}}>
                        {employee.imgURL ?
                            <img alt="customer service img" className="customer-service-img" src={employee.imgURL} style={styles.badgeImg}/>
                            :
                            <Skeleton className="customer-service-img" variant="rectangular" width={BADGE_IMG_SIZE_PX} height={BADGE_IMG_SIZE_PX} />
                        }
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" style={styles.fullName} >
                            {employee.fullName}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2" style={styles.position}>
                            {textTransform('title', t(`businessRoles.${employee.position}`))}
                        </Typography>
                    </Grid>
                </Grid> 
                :
                <>
                    <Skeleton className="customer-service-img" variant="rectangular" width={BADGE_IMG_SIZE_PX} height={BADGE_IMG_SIZE_PX} />
                    <Skeleton variant="text" width={'75%'}/>
                </>
            }
        </div>
    );
}

export default CustomerServiceBadge;