import { useTheme } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { HeroMessage } from '../HeroMessage/HeroMessage'
import './landingHeroMessage.css'

const LandingHeroMessage = () => {
    const { t } = useTranslation('landing', { keyPrefix:'landingHeroMessage'})
    const theme = useTheme();
    const styles = {
        wrapper: {
            // backgroundColor: theme.palette.primary.main
        }
    }
    return (
        <div className='landing-hero-message-wrapper' style={styles.wrapper}>
            <HeroMessage 
                className='landing-hero-message-main-container'
                title={t('moreThanLearnProfessionalizeThisDiscipline')}
            />
        </div>
    )
}

export default LandingHeroMessage