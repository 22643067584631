import { useLayoutEffect, useState, useRef } from 'react'
import { IconButton, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles';
import NextIcon from '../../img/next-icon.svg';
import Slider from 'react-slick'
// import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext} from 'pure-react-carousel'
import ContentSliderDots from '../ContentSliderDots/ContentSliderDots';

// Import css files
// import 'pure-react-carousel/dist/react-carousel.es.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './sliderCustom.css'

const SliderCustom = ({ settings, ...props}) => {
    const theme = useTheme();
    const slideShowRef = useRef();
    const matchDesktop = useMediaQuery('(min-width:1120px)');
    const matchMobile = useMediaQuery('(max-width:680px)');
    const slidesToScroll = 1;
    const INITIAL_SLIDE_INDEX = 0
    const [numSteps, setNumSteps] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(INITIAL_SLIDE_INDEX);
    const styles = {
        leftHandlerContainer:{
            backgroundImage: `linear-gradient(270deg, rgba(0,0,0,0), ${theme.palette.surface.light['1']} 20%)`
        },
        rightHandlerContainer:{
            backgroundImage: `linear-gradient(90deg, rgba(0,0,0,0), ${theme.palette.surface.light['1']} ${matchDesktop ? '10%' : matchMobile ? '' : '30%'})`
        },
        icon:{
            diplay:'block',
            width: '20px',
            color: theme.palette.secondaryLowContrast.main
        },
        previousIcon:{
            transform: 'rotate(180deg)',
        },
        nextIcon:{
        }
    }

    const onClickNext = (e) => {
        e.preventDefault();
        if(slideShowRef.current){
            slideShowRef.current.slickNext()
        }
    }

    const onClickPrevious = (e) => {
        e.preventDefault();
        if(slideShowRef.current){
            slideShowRef.current.slickPrev()
        }
    } 

    const onChangeSlide = ( oldIndex, newIndex) => {
        setCurrentIndex(newIndex);
    }

    const onGoToStep = (selectedStep) => {
        if(slideShowRef.current){
            slideShowRef.current.slickGoTo(selectedStep)
        }
    }

    useLayoutEffect(() => {
        if(props.children.length > 0){
            setNumSteps(Math.ceil(props.children.length / slidesToScroll))
        }else{
            setNumSteps(0);
        }
    }, [props.children, slidesToScroll])

    return (
        <div className='content-slider-wrapper'>
            <div className='content-slider-main-container'>
                <Slider ref={slideShowRef} className={props.className} {...settings} beforeChange={onChangeSlide} >
                    {props.children}
                </Slider>
                <div className='content-slider-left-handler-container' style={styles.leftHandlerContainer}>
                    <div className='content-slider-left-handler-button'>
                        {numSteps > 1 &&
                            <IconButton onClick={onClickPrevious}>
                                <NextIcon style={{...styles.previousIcon, ...styles.icon}}/>
                            </IconButton>
                        }
                    </div>
                </div>
                <div className='content-slider-right-handler-container' style={styles.rightHandlerContainer}>
                    <div className='content-slider-right-handler-button'>
                        {numSteps > 1 &&
                            <IconButton onClick={onClickNext}>
                                <NextIcon style={{...styles.nextIcon, ...styles.icon}}/>
                            </IconButton>
                        }
                    </div>
                </div>
            </div>
            <div className='content-slider-dots-container'>
                <ContentSliderDots currentIndex={currentIndex} onGoToStep={onGoToStep} numSteps={numSteps} stepSize={slidesToScroll}/>
            </div>
        </div>
    )
}

export default SliderCustom