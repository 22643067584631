import { useContext } from "react";
import { LocaleContext } from "../customContext/localeProvider";

export function useLocaleCtx(){
    const ctx = useContext(LocaleContext);
    if(!ctx){
        throw new Error("useLocaleCtx must be used within the LocaleProvider context")
    }
    return ctx
}
