import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box, Divider } from '@material-ui/core'
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove'; 
import { useTheme } from '@material-ui/styles';
import {Alert} from '@mui/material';
import ButtonLoading from '../ButtonLoading/ButtonLoading';
import { Skeleton } from '@mui/material'
import ImgDN from '../../cdn/imgDN';
import cloudinary from '../../cdn/providers/cloudinary';
import PopUpModal from '../PopUpModal/PopUpModal';
import { getCustomErrorOrUndefined } from '../../aux/errorHelpers';
import { isSubscriptionProductTypeName } from '../../aux/productHelpers';
import { getFromSafeObject, isNotEmptyObject, textTruncate } from '../../aux/aux';
import './checkoutItemQuantityPopUp.css';

const CheckoutItemQuantityPopUp = ({showPopUp, setShowPopUp, product, callBack, isLoading}) => {
    const MAX_NAME_CHAR = 23;
    const theme = useTheme();
    const { t } = useTranslation('common', { keyPrefix: 'checkoutItemQuantityPopUp'})
    const [quantity, setQuantity] = useState(product.quantity); 
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);
    const [showWrongInputError, setShowWrongInputError] = useState(false);
    const [errorUpdating, setErrorUpdating] = useState({show:false, isPersistent:undefined, msg:undefined});
    const [imgURL, setImgURL] = useState(null);
    const abortControllerSignalRef = useRef(null);
    const style={
        inputBorder:{
            border: `0.5px solid ${theme.palette.primary.light}`,
            borderRadius: '5px'
        },
        error:{
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main,
        }
    };
    const onClick = async (e, signal=abortControllerSignalRef.current) => {
        try{
            e.preventDefault();
            await callBack(quantity);
            if(!signal || !signal.aborted){
                setShowPopUp(false);
            }
        }catch(error){
            // Errors will show an updating problem
            const customError = getCustomErrorOrUndefined(error);
            let errorMsg = t('errorUpdating');
            let isPersistentError = false;
            if(customError){
                const { checkoutProductPurchasability } = customError.payload
                if(checkoutProductPurchasability && checkoutProductPurchasability.isPurchasable === false){
                    const {
                        productPurchasabilityDetails,
                        userProductPurchasabilityDetails
                    } = checkoutProductPurchasability.details;
                    if(
                        productPurchasabilityDetails && 
                        productPurchasabilityDetails.isPurchasable === false &&
                        productPurchasabilityDetails.details.noPurchasabilityType === 'noAvailUnits'    
                    ){
                        // No avail units
                        errorMsg = t('noAvailUnits', {availUnits: productPurchasabilityDetails.details.availUnits});
                        isPersistentError = true
                    }else if (
                        userProductPurchasabilityDetails && 
                        userProductPurchasabilityDetails.isPurchasable === false &&
                        userProductPurchasabilityDetails.details.noPurchasabilityType === 'maxQuantity'
                    ){
                        // Max units than allowed: subscription or purchased
                        errorMsg = t('maxQuantity', {maxQuantity: userProductPurchasabilityDetails.details.maxQuantity});
                        isPersistentError = true;
                    }
                }
            }
            if(!signal || !signal.aborted){
                setErrorUpdating({show:true, msg:errorMsg, isPersistent: isPersistentError})
            }
        }
    }

    const onChange = (e, offset=0) => {
        let newQuantity;
        if(offset !== 0){
            newQuantity = quantity + offset
        }else{
            newQuantity =  e.target.value === '' ? 0 : parseInt(e.target.value);
        }
        if(isNaN(newQuantity) || newQuantity < 0){
            setShowWrongInputError(true);
        }else{
            setShowWrongInputError(false);
            setErrorUpdating(prev => ({...prev, show:false, isPersistent:undefined}))
            setQuantity(newQuantity);
        }
    }

    useEffect(() => {
        if(quantity == 0){
            setShowDeleteWarning(true);
        }else{
            setShowDeleteWarning(false);
        }
    }, [quantity])

    useEffect(() => {
        if(errorUpdating.show && !errorUpdating.isPersistent){
            setTimeout(() => {setErrorUpdating(prev => ({...prev, show:false, isPersistent:undefined}))}, 3000);
            return (() => {setErrorUpdating({show:false, isPersistent:undefined, msg:undefined})});
        }
    },[errorUpdating])
    
    useEffect(() => {
        let imgURL;
        const imgIcon = getFromSafeObject(product, 'imageDetails.main');
        if(isNotEmptyObject(imgIcon)){
            const imgPublicId = imgIcon.publicId;
            const assetProvider = new ImgDN(cloudinary);
            imgURL = assetProvider.getAssetUrl(imgPublicId);
        }else{
            imgURL = null;
        }
        setImgURL(imgURL)
    },[product])

    useEffect(() => {
        if(showPopUp){
            const abortController = new AbortController();
            abortControllerSignalRef.current = abortController.signal;
            return(() => {
                console.log('cleaning pop up')
                abortController.abort()
            })
        }
    },[showPopUp])


    return (
        <PopUpModal showModal={showPopUp} onClickClose={() => {setShowPopUp(false)}} width="380px">
            <Grid container direction="column">
                <Grid item >
                    <Grid container direction='row' className='checkout-item-quantity-pop-up-title-main-container'>
                        <Grid item className='checkout-item-quantity-pop-up-title-img-container'>
                            {imgURL ? 
                                <img className='checkout-item-quantity-pop-up-title-img' alt='product-img' src={imgURL}/>
                                :
                                <Skeleton className='checkout-item-quantity-pop-up-title-img-skeleton' variant="rectangular"/>
                            }
                        </Grid>
                        <Grid item className='checkout-item-quantity-pop-up-title-description'>
                            <Grid container direction='column' justifyContent='space-between' style={{height:"100%"}}>
                                <Grid item>
                                    <Typography variant="body1" style={{fontWeight:"bold", lineHeight:'1em'}}>
                                        {t("updateQuantity")}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" >
                                        {textTruncate(getFromSafeObject(product, 'description.nameTranslated'), MAX_NAME_CHAR)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider />
                <Grid item className={`checkout-item-quantity-pop-up-warning-toggle ${showDeleteWarning ? 'open': ''}`}>
                    <Alert severity='warning'>
                        {t("deleteWarning")}
                    </Alert>
                </Grid>
                <Grid item className={`checkout-item-quantity-pop-up-warning-toggle ${showWrongInputError ? 'open': ''}`}>
                    <Alert severity='error'>
                        {t("wrongInput")}
                    </Alert>
                </Grid>
                <Grid item className={`checkout-item-quantity-pop-up-warning-toggle ${isSubscriptionProductTypeName(product.productTypeName) && !showDeleteWarning && !showWrongInputError ? 'open': ''}`}>
                    <Alert severity='info'>
                        {t("eachUnitCorrespondsToAConsequentPeriod")}
                    </Alert>
                </Grid>
                <Grid item className='checkout-item-quantity-pop-up-set-container' >
                    <Grid container direction="row" justifyContent='center' alignItems='center' style={{height:"100%"}}>
                        <Grid item >
                            <IconButton disabled={isLoading} onClick={e => {onChange(e, -1)}}>
                                <RemoveIcon />
                            </IconButton>
                        </Grid>
                        <Grid item align="center" style={{padding:"0 10px"}}>
                            <div className='checkout-item-quantity-pop-up-set-input' style={style.inputBorder}>
                                <input className="pop-up-check-out-item-quantity-input" value={quantity} 
                                disabled={isLoading} onChange={onChange} type="text" maxLength={2} style={{fontSize:"1em"}}/>
                            </div>
                        </Grid>
                        <Grid item>
                            <IconButton disabled={isLoading} onClick={ e => {onChange(e, 1)}}>
                                <AddIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className='checkout-item-quantity-pop-up-set-button' >
                    <ButtonLoading id='submit' disabled={errorUpdating.show} isLoading={isLoading} label={t("common:update")} 
                    variant="outlined" color="primary" onClick={onClick} fullWidth style={errorUpdating.show ? style.error : {}} />
                    <div item className={`checkout-item-quantity-pop-up-error-toogle ${errorUpdating.show ? 'show' : ''}`}>
                        <Typography variant='body2' style={style.error}>
                            {errorUpdating.msg}
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </PopUpModal>
  )
}

export default CheckoutItemQuantityPopUp