import { Typography, useMediaQuery } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { getFromSafeObject, isEmptyObject, isNotEmptyObject, textTransform } from '../../aux/aux'
import ContentFeaturedFeatures from '../ContentFeaturedFeatures/ContentFeaturedFeatures'
import ContentWorkguideVsProtocol from '../ContentWorkguideVsProtocol/ContentWorkguideVsProtocol'
import './contentFeatures.css'
import ContentMultiSystem from '../ContentMultiSystem/ContentMultiSystem'
import { useMemo } from 'react'
import { getLessonTypeCountMap } from '../../aux/contentProductHelpers'

const ContentFeatures = ({ contentPage, contentStructure, contentDeviceVersionArray, contentCompatibleDeviceArray }) => {
    const { t } = useTranslation('contentProduct');
    const matchMobile = useMediaQuery('(max-width:680px)');
    const [featureKeyArray, featureFigureMap] = useMemo(() => {
        const contentPageFeaturesKeyArray = getFromSafeObject(contentPage, 'contentFeaturedFeatures.featuresKeyArray') || [];
        const contentPageFeaturesFigureMap= getFromSafeObject(contentPage, 'contentFeaturedFeatures.featuresMap') || {};
        const contentProductLessonSummaryMap = getFromSafeObject(contentStructure, 'lessonSummaryMap') || {};
        const [auxContentProductTypeLessonCountMap, numLesson] = getLessonTypeCountMap(contentProductLessonSummaryMap);
        const featuresFiguresMap = {};
        contentPageFeaturesKeyArray.forEach((featureKey, index) => {
            let featureFigure;
            if(contentPageFeaturesFigureMap.hasOwnProperty(featureKey)){
               featureFigure = contentPageFeaturesFigureMap[featureKey]; 
            }else{
                switch(featureKey){
                    case 'numDevice':
                        featureFigure = contentCompatibleDeviceArray instanceof Array ? contentCompatibleDeviceArray.length : undefined;
                        break;
                    case 'numLesson':
                        featureFigure = isNotEmptyObject(auxContentProductTypeLessonCountMap) ? numLesson : undefined ;
                        break;
                    case 'numLiveLesson':
                        featureFigure = isNotEmptyObject(auxContentProductTypeLessonCountMap) ? auxContentProductTypeLessonCountMap.live || 0 : undefined;
                        break;
                    case 'numDownloadLesson':
                        featureFigure = isNotEmptyObject(auxContentProductTypeLessonCountMap) ? auxContentProductTypeLessonCountMap.download || 0 : undefined;
                        break
                    case 'numVideoLesson':
                        featureFigure = isNotEmptyObject(auxContentProductTypeLessonCountMap) ? auxContentProductTypeLessonCountMap.video || 0 : undefined;
                        break
                }
            }
            if(featureFigure != null){
                featuresFiguresMap[featureKey] = featureFigure;
            }
        })
        return [contentPageFeaturesKeyArray, featuresFiguresMap]
    },[contentPage, contentCompatibleDeviceArray, contentStructure])

    const isContentDesignedForSpecificDevices = contentDeviceVersionArray instanceof Array && contentCompatibleDeviceArray.length > 0;

    const styles = {
        title: {
            fontWeight: 'bold',
            textAlign: 'center'
        }
    };

    return (
        getFromSafeObject(contentPage, 'contentFeaturedFeatures') ? 
            <div className='content-features-wrapper'>
                <div className='content-features-title-container'>
                    <Typography variant={matchMobile ? 'h5' :'h4'} style={styles.title} >
                        {textTransform('title',t("contentFeatures.nextLevelContent"))}
                    </Typography>
                </div>
                <div className='content-features-main-container'>
                    <ContentFeaturedFeatures description={contentPage.contentFeaturedFeatures.description} featuresKeyArray={featureKeyArray} featureFigureMap={featureFigureMap} noVerticalPadding={true} />
                    {contentPage.contentFeaturedFeatures.hasWorkguide &&
                        <ContentWorkguideVsProtocol noVerticalPadding={true} />
                    }
                    <ContentMultiSystem 
                        description={isContentDesignedForSpecificDevices ? getFromSafeObject(contentPage,'contentMultiSystem.description') || t("contentMultiSystem.contentAdaptedToYourSystem") : t("contentMultiSystem.contentCompatibleWithSystem")}
                        contentCompatibleDeviceArray={contentCompatibleDeviceArray} 
                        contentDeviceVersionArray={contentDeviceVersionArray}
                        subtitle={textTransform('title', isContentDesignedForSpecificDevices ? getFromSafeObject(contentPage,'contentMultiSystem.subtitle') || t("contentFeatures.adaptedToYourSystem") :  t("contentFeatures.compatibleWithYourSystem"))}
                        noVerticalPadding={true} />
                </div>
            </div>
            :
            null
    )
}

export default ContentFeatures