/**
 * This Module contains a set of functions to facilitate the REST API queries related to the Athorization Process
 */
import { publicRequest, createAuthRequest } from "./requestMethods";

const loginUserRequestFn = async (params, signal=null) => {
    const {
        email,
        password
    } = params;
    const res =  await publicRequest.post(`/auth/login-user`, { email, password }, { signal });
    return res;
}

const recoveryUserRequestFn = async () => {
    const userRequest = createAuthRequest();
    const res = await userRequest.get(`/auth/recovery-user`);
    return res;
}

const createUserAccountFn = async (params, signal=null) => {
    const user = {
        firstName : params.user.firstName,
        lastName : params.user.lastName,
        email : params.user.email,
        localeId : params.user.localeId,
        password : params.user.password,
        professionName :params.user.professionName || null,
    };
    const countryCode = params.countryCode;
    const stateCode = params.stateCode;
    const consentArray = params.consentArray;
    return publicRequest.post('/auth/create-user', 
        { user, consentArray, countryCode, stateCode }, 
        { signal } 
    )
};


const requestResetPasswordFn = async (params, signal=null) => {
    const data = { cipheredEmail: params.cipheredEmail }
    return publicRequest.post(`/auth/request-reset-user-password`, data, { signal })
}

const resetPasswordFn = async (params, signal=null) => {
    const data = { password: params.password };
    const config = {
        headers: {authorization : `Bearer ${params.resetPasswordToken}`},
        signal,
    }
    return publicRequest.post(`/auth/reset-user-password`, data, config )
}

const isUserInPassiveVerificationListFn = async (params, signal=null) => {
    const { 
        cipheredEmail
    } = params;
    return publicRequest.get(`/auth/in-passive-verification-list`, { params, signal })
}

const requestVerifyAccountFn = async (params, signal=null) => {
    const data = { cipheredEmail: params.cipheredEmail, passiveVerification: params.passiveVerification }
    return publicRequest.post(`/auth/request-verify-user-account`, data, { signal })
}

const joinToVerifyAccountListFn = async (params, signal=null) => {
    return publicRequest.post(`/auth/join-to-passive-verify-user-account`, { cipheredEmail: params.cipheredEmail }, {signal} )
}

const verifyAccountFn = async (params, signal=null) => {
    const data = { 
        code: params.code
    };
    const config = {
        headers: {authorization : `Bearer ${params.verificationToken}`},
        signal,
    }
    return publicRequest.post(`/auth/verify-user-account`, data, config )
}

const verifyAccountPassiveFn = async (params, signal=null) => {
    const config = {
        headers: {authorization : `Bearer ${params.verificationToken}`},
        signal,
    }
    return publicRequest.post(`/auth/verify-passive-user-account`, {}, config )
}

const setNewUserPasswordFn = async (params, signal=null) => {
    const {
        userId,
        body    
    } = params
    const userRequest = createAuthRequest()
    return userRequest.post(`/auth/set-new-user-password/${userId}`, body, { signal } )
}

const logoutUserRequestFn = async (params) => {
    const userId = params.userId
    const authRequest = createAuthRequest();
    await authRequest.delete(`/auth/logout-user/${userId}`);
}

// const checkPaymentIntentFn = async (params) => {
//     const {
//         paymentIntentId,
//         requestParam
//     } = params;
//     const userRequest = createAuthRequest();
//     const res = await userRequest.post('checkout/check-payment-intent',{ paymentIntentId, requestParam });
//     return res;
// }

export {
    loginUserRequestFn, createUserAccountFn,
    logoutUserRequestFn, recoveryUserRequestFn, requestVerifyAccountFn,
    requestResetPasswordFn, resetPasswordFn, setNewUserPasswordFn, verifyAccountFn,
    verifyAccountPassiveFn, joinToVerifyAccountListFn, isUserInPassiveVerificationListFn
}