import { useEffect, useState, useRef } from 'react'
import { useTheme } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { 
    Divider, Grid, Typography, TextField, InputAdornment, 
    IconButton, Button, Paper
} from '@material-ui/core'

import ButtonLoading from '../../components/ButtonLoading/ButtonLoading'
import ToggleMsg from '../../components/ToggleMsg/ToggleMsg'
import CustomPopover from '../../components/CustomPopover/CustomPopover'

import { textTransform } from '../../aux/aux'
import { isTokenActive } from '../../aux/authHelpers'
import { isStrongUserPassword, MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from '../../aux/userHelpers'

// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import warning from '../../lotties/warning.json';
import error from '../../lotties/error.json';
import success from '../../lotties/success.json';
import { Visibility, VisibilityOff } from '@material-ui/icons'
import './resetPassword.css'
import { resetPassword } from '../../services/userServices'
import { getCustomErrorOrUndefined } from '../../aux/errorHelpers'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import SmallFooter from '../../components/SmallFooter/SmallFooter'

const ResetPassword = ({ resetPasswordToken }) => {
    const MAX_REQUEST_ATTEMPT = 3; 
    const PATH_TO_HOME= '/';
    const PATH_TO_LOGIN= '/login';
    const history = useHistory();
    const theme = useTheme()
    const { t } = useTranslation('resetPassword');
    const passwordFormStatusTypeArray = ['idle', 'loading']; 
    const abortControllerRef = useRef(null);
    const passwordTextFieldRef = useRef(null);
    const [focusOnPassword, setFocusOnPassword] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(null);
    const [isWellFormedToken, setIsWellFormedToken] = useState(null);
    const [isActiveToken, setIsActiveToken] = useState(null);
    const [requestError, setRequestError] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [visibleFieldMap, setVisibleFieldMap] = useState({});
    const [animationContainer, setAnimationContainerRef] = useState(null);
    const [requestErrorCount, setRequestErrorCount] = useState(0);
    const [passwordFormStatus, setPasswordFormStatus] = useState({
        status: passwordFormStatusTypeArray[0],
        form:{
            password:'',
            confirmPassword:''
        },
        formErrorMap:{},
    })
    const styles = {
        wrapper: {
            // backgroundColor: theme.palette.surface.light[1],
        }
    }
    const animationRef = useRef(null);

    const setNewPassword = async(e, signal=abortControllerRef.current.signal) => {
        try{
            e.preventDefault();
            setPasswordFormStatus(prev => ({ ...prev, status:passwordFormStatusTypeArray[1] }))
            const password = passwordFormStatus.form.password;
            await resetPassword(resetPasswordToken, password, 1000, signal);
            if(!signal.aborted){
                setIsSuccessful(true);
                setPasswordFormStatus(prev => ({ ...prev, status:passwordFormStatusTypeArray[0] }))
            }
        }catch(error){
            if(!signal.aborted){
                // Error: expiredToken, wrongToken, unknown
                const customError = getCustomErrorOrUndefined(error);
                let isRequestError = false;
                if(customError){
                    switch(customError.cause){
                        case 'sr_103':
                            // token expired or wrong
                            setIsActiveToken(false);
                            break;
                        case 'sr_001':
                            // unknown user
                            setIsWellFormedToken(false);
                            break;
                        case 'sr_006':
                            // weak password was sent. reinforced on server
                            setPasswordFormStatus(prev => ({ ...prev, formErrorMap: { ...prev.formErrorMap, 'password': true } }))
                        case 'sr_005':
                            // password was not received on the client
                        default:
                            isRequestError = true;
                    }
                }else{
                    isRequestError = true;
                }
                setRequestError(isRequestError);
                setRequestErrorCount(prev => {return prev + 1});
                setPasswordFormStatus(prev => ({ ...prev, status:passwordFormStatusTypeArray[0] }))
            }
        }
    }
    const isServerTired = (attempt) => {
        return attempt >= MAX_REQUEST_ATTEMPT
    }

    const getBadTokenErrorString = () => {
        let msg = ''
        if(isActiveToken === false){
            msg = `${textTransform('title', t("thisLinkIsExpired"))}`
                +`.\n${textTransform('title', t("tryItAgain"))}`
        }else{
            msg = `${textTransform('title', t("thisIsAwrongLink"))}`
                +`.\n${textTransform('title', t("askANewOne"))}`
        }
        return msg
    }

    const getBadTokenAnimation = () => {
        return isActiveToken === false ? warning : error
    }

    const redirectToRoute = (routePath, query={}, replaceRoute=false) => {
        const queryParams = new URLSearchParams(query).toString();
        const path = `${routePath}${queryParams ? `?${queryParams}`:''}`;
        const redirect = replaceRoute ? history.replace : history.push;
        redirect(path);
    }
    
    const onChangeForm = (e) => {
        const field = e.target.name;
        const value = e.target.value;
        setPasswordFormStatus(prev => {
            const tempForm = { ...prev.form, [field]: value };
            const fieldError = {
                'password': tempForm.password && !isStrongUserPassword(tempForm.password),
                'confirmPassword': tempForm.confirmPassword && tempForm.confirmPassword !== tempForm.password,
            }
            return { ...prev, form: tempForm, formErrorMap: fieldError }
        })
    }

    useEffect(() => {
        const isTired = isServerTired(requestErrorCount);
        const isError = Object.values(passwordFormStatus.formErrorMap).find(error => error === true) != null;
        setIsDisabled(isError || isTired)
    }, [passwordFormStatus, requestErrorCount])

    useEffect(() => {
        if(animationContainer != null){
            let animation = null
            if(isSuccessful){
                animation = success;
            }else{
                animation = getBadTokenAnimation();
            }
            animationRef.current = lottie.loadAnimation({
                container: animationContainer,
                renderer: 'svg',
                autoplay: true,
                animationData: animation,
                loop: false,
                name: 'animation'
            })
            return(() => {
                if(animationRef.current != null){
                    animationRef.current.stop();
                    animationRef.current?.destroy();
                }
            })
        }
    },[animationContainer])

    useEffect(() => {
        try{
            const isActiveToken = isTokenActive(resetPasswordToken)
            setIsWellFormedToken(true);
            setIsActiveToken(isActiveToken);
        }catch(error){
            // A falsy or malformed token will throw an InvalidTokenError error.
            setIsWellFormedToken(false);
        }
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        return(() => {
            abortController.abort()
        })
    },[])

    return (
        <div style={styles.wrapper}>
            <Grid container className='reset-password-main-container'>
                {isWellFormedToken === null || isActiveToken === null ?
                    <LoadingComponent visibleElements='circle' />
                    :
                    <Grid item className='reset-password-modal-container'>
                        <Paper >
                            <Grid container direction='column' className='reset-password-modal-inner-container'>
                                <Grid item>
                                    <Typography variant='h5'>
                                        {textTransform('title', t("restablishingPassword"))}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant='body1'>
                                        {textTransform('title', t("setANewPassword"))}
                                    </Typography>
                                </Grid>
                                <Divider style={{margin:"10px 0px"}} />
                                    {isWellFormedToken === true && isActiveToken === true ?
                                        isSuccessful ?
                                            <>
                                                <Grid item align='center' className='reset-password-message-item'>
                                                    <div ref={setAnimationContainerRef} className='reset-password-animation-container'/>
                                                </Grid>
                                                <Grid item align='center' className='reset-password-message-item'>
                                                    <Typography variant='body1' style={{whiteSpace:'pre-line'}}>
                                                        {textTransform('title',t("passwordWasSuccessfullyReset"))}
                                                    </Typography>
                                                </Grid>
                                                <Grid item align='center' className='reset-password-message-item'>
                                                    <Button
                                                        color='primary'
                                                        variant='outlined'
                                                        onClick={() => redirectToRoute(PATH_TO_LOGIN)}
                                                    >
                                                        {t("goToLogIn")}    
                                                    </Button>
                                                </Grid>
                                            </>
                                        :
                                            <form onSubmit={setNewPassword}>
                                                <Grid item className='reset-password-input-wrapper'>
                                                    <CustomPopover 
                                                        anchorEl={passwordTextFieldRef}
                                                        open={focusOnPassword}
                                                        anchorOrigin={{vertical:'top', horizontal:'center'}} 
                                                        transformOrigin={{vertical:'bottom', horizontal:'center'}}
                                                    >
                                                        <Typography variant='body2' style={{whiteSpace: 'pre-line'}}>
                                                            {
                                                                `${textTransform('title', t("lengthBetween", {max: MAX_PASSWORD_LENGTH, min: MIN_PASSWORD_LENGTH}))}`
                                                                +`\n${textTransform('title', t("mustContainAtLeast"))}:`
                                                                +`\n - ${t("oneNumber")}`
                                                                +`\n - ${t("oneCapitalLetter")}`
                                                                +`\n - ${t("oneLowerLetter")}`
                                                                +`\n - ${t("oneSpecialChar")}`
                                                            }
                                                        </Typography>
                                                    </CustomPopover>
                                                    <TextField 
                                                        ref={passwordTextFieldRef}
                                                        type={visibleFieldMap.password ? "text" : "password"} 
                                                        name="password" 
                                                        label={textTransform('title', t("password"))} 
                                                        value={passwordFormStatus.form.password}
                                                        disabled={passwordFormStatus.status === passwordFormStatusTypeArray[1]}
                                                        error={passwordFormStatus.formErrorMap.password === true}
                                                        helperText={passwordFormStatus.formErrorMap.password === true ? textTransform('title', t("tooWeakPassword")) : undefined}
                                                        onChange={onChangeForm} 
                                                        onFocus={() => setFocusOnPassword(true)}
                                                        onBlur={() => setFocusOnPassword(false)}
                                                        required 
                                                        inputProps={{ maxLength: MAX_PASSWORD_LENGTH}}
                                                        InputProps={{endAdornment:(
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={(e) => setVisibleFieldMap(prev => ({...prev, ['password']: !prev.password}))}
                                                                size="small"
                                                                edge="end"
                                                                >
                                                                {visibleFieldMap.password ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )}}
                                                        fullWidth/>
                                                </Grid>
                                                <Grid item className='reset-password-input-wrapper'>
                                                    <TextField 
                                                        type={visibleFieldMap.confirmPassword ? "text" : "password"}
                                                        name="confirmPassword" 
                                                        label={textTransform('title', t("confirmPassword"))} 
                                                        value={passwordFormStatus.form.confirmPassword}
                                                        disabled={passwordFormStatus.status === passwordFormStatusTypeArray[1] }
                                                        error={passwordFormStatus.formErrorMap.confirmPassword === true}
                                                        helperText={passwordFormStatus.formErrorMap.confirmPassword === true ? textTransform('title', t("passwordUnmatch")) : undefined}
                                                        onChange={onChangeForm} 
                                                        required 
                                                        inputProps={{ maxLength: MAX_PASSWORD_LENGTH}}
                                                        InputProps={{endAdornment:(
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={(e) => setVisibleFieldMap(prev => ({...prev, ['confirmPassword']: !prev.confirmPassword}))}
                                                                size="small"
                                                                edge="end"
                                                                >
                                                                {visibleFieldMap.confirmPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )}}
                                                        fullWidth/>
                                                </Grid>
                                                <Grid item className='reset-password-button' align='center'>
                                                    <ButtonLoading 
                                                        variant="outlined" 
                                                        color="primary" 
                                                        disabled={isDisabled}
                                                        isLoading={passwordFormStatus.status === passwordFormStatusTypeArray[1]} 
                                                        type='submit' 
                                                        label={t("restablishingPassword")} 
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <ToggleMsg
                                                        className={`reset-password-error-container ${requestError === true ? 'show': ''} `}
                                                        isShown={requestError} 
                                                        onShowTime={() => {
                                                            const isTired = isServerTired(requestErrorCount)
                                                            setRequestError(isTired)
                                                        }}
                                                        msg={
                                                            `${textTransform('title', t("requestError"))}.\n${textTransform('title', t("tryItLater"))}`
                                                        } 
                                                    />
                                                </Grid>
                                            </form>
                                    :
                                        <>
                                            <Grid item align='center' className='reset-password-message-item'>
                                                <div ref={setAnimationContainerRef} className='reset-password-animation-container'/>
                                            </Grid>
                                            <Grid item className='reset-password-message-item' align='center'>
                                                <Typography variant='body1' style={{whiteSpace:'pre-line'}}>
                                                    {
                                                        getBadTokenErrorString()
                                                    }
                                                </Typography>
                                            </Grid>
                                            <Grid item align='center' className='reset-password-message-item'>
                                                <Button
                                                    color='primary'
                                                    variant='outlined'
                                                    onClick={() => redirectToRoute(PATH_TO_HOME)}
                                                >
                                                    {t("goHome")}    
                                                </Button>
                                            </Grid>
                                        </>
                                    }
                            </Grid>
                        </Paper>
                    </Grid>

                }
            </Grid>
            <SmallFooter onLightBackground={true} hideSocialLinks={true} />
        </div>
    )
}

export default ResetPassword