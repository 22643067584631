/**
 * This module contains helper functions related to custom events
 */

const onEventListener = (eventType, listener, element=undefined) => {
    element = element || document;
    element.addEventListener(eventType, listener);
}

const offEventListener = (eventType, listener, element=undefined) => {
    element = element || document;
    element.removeEventListener(eventType, listener);
}

const onOnceEventListener = (eventType, listener, element=undefined) => {
    const handleEventOnce = (e) => {
        listener(e);
        offEventListener(eventType, handleEventOnce, element);
    }
    onEventListener(eventType, handleEventOnce, element);
}

/**
 * 
 * @param {*} eventType 
 * @param {*} data 
 * @param {*} opt {} = defaultOpt = {bubbles:false, cancelable:false, composed:false}
 * @param {*} element 
 */
const triggerEventWithData = (eventType, data={}, opt={}, element=undefined) => {
    opt.detail = data;
    const e = new CustomEvent(eventType, opt);
    element = element || document;
    element.dispatchEvent(e);
}
/**
 * 
 * @param {*} eventType 
 * @param {*} opt {} = defaultOpt = {bubbles:false, cancelable:false, composed:false}
 * @param {*} element 
 */
const triggerEvent = (eventType, opt={}, element=undefined) => {
    const e = new Event(eventType, opt);
    element = element || document;
    element.dispatchEvent(e);
}

/**
 * 
 * @param {*} elementType example userMeeting
 * @param {*} id example 
 * @returns 
 */
const getElementIdToEvent = (elementType, id) => {
    return `${elementType}-${id}`
}

export {
    onEventListener,
    offEventListener,
    onOnceEventListener,
    triggerEventWithData,
    triggerEvent,
    getElementIdToEvent,
}