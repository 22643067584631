import { Typography } from '@material-ui/core'
import { getSubscriptionProductTypeName, getSubscriptionUpgradeProductTypeName } from '../../aux/productHelpers'
import './productConsumptionTypeOptionDescription.css'
import { useTranslation } from 'react-i18next'

const ProductConsumptionTypeOptionDescription = ({productConsumptionTypeName, variant}) => {
    const { t } = useTranslation('common', {keyPrefix: 'productConsumptionTypeOptionDescription'});
    const DescriptionComponent = (productConsumptionTypeName => {
        let Component
        switch(productConsumptionTypeName){
            case getSubscriptionProductTypeName():
                Component = 
                    <div className='product-consumption-type-option-description-main-container'>
                        <Typography variant={variant}>
                            {t(`learnWithNoLimits`)}
                        </Typography>
                    </div>
                break
            case getSubscriptionUpgradeProductTypeName():
                Component =                     
                    <div className='product-consumption-type-option-description-main-container'>
                        <Typography variant={variant}>
                            {t('takeYourSubscriptionToTheNextLevel')}
                        </Typography>
                    </div>
                break
            default:
                Component = null
        }
        return Component
    })(productConsumptionTypeName)
    return (
        DescriptionComponent ? 
            <div className='product-consumption-type-option-description-wrapper'>
                {DescriptionComponent}
            </div>
            :
            null
    )
}

export default ProductConsumptionTypeOptionDescription