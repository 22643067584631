import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/styles'
import { Divider, Grid, Typography, Box, TextField, Button, useMediaQuery } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { textTransform } from '../../aux/aux'

//Style

// Img & Art
// import Logo from '../../img/logo-alpha.svg'; // Logo black name
// import Logo from '../../img/logo-alpha-f3f3f3-name.svg'; // Logo f3f3f3 name

import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import Logo from '../../img/logo-x-fill.svg';

// Style
import './bigFooterNoLinks.css'
import { fontWeight } from '@mui/system';
import LanguageMenu from '../LanguageMenu/LanguageMenu';

const BigFooterNoLinks = ({ user }) => {
    const year = new Date().getFullYear();
    const theme = useTheme();
    const { t } = useTranslation('common', { keyPrefix: 'bigFooter' });
    const matchMobile = useMediaQuery('(max-width:680px)');
    const linksMap = {
        home:'',
        courses:'',
        masterclass: '',
        protocols: '',
        specialities: '',
        specialist:'',
        events:'',
        programs:'',
        blog:'',
        aboutUs:'',
        facebook:'https://www.facebook.com/qxinstitute',
        youtube:'https://www.youtube.com/@QxInstitute',
        instagram:'https://www.instagram.com/qxinstitute',
        termsAndCondition: '/terms-of-use',
        privacityPolicy: '/privacy-policy',
        cookieConsent: '/cookies-policy',
    };
    const iconsMap = {
        youtube: YouTubeIcon,
        facebook: FacebookIcon ,
        instagram: InstagramIcon
    }
    const footerStructure = {
        columnsArray : [ 'followUs' ],
        socialMediaArray: ['youtube', 'facebook', 'instagram'],
        legalRowArray : [ 'termsAndCondition', 'privacityPolicy', 'cookieConsent']
    };

    const styles = {
        icon:{
            display: 'block',
        },
        socialMedia:{
            color: theme.palette.text.contrast
        },
        footerWrapper:{
            color: theme.palette.text.contrast,
            backgroundColor: theme.palette.background.dark,
        },
        footerLink:{
            textDecoration: 'none',
            color: theme.palette.text.contrast,
        },
        footerHighlight:{
            color: theme.palette.primary.light, 
            fontWeight: 'bold'
        },
        divider:{
            backgroundColor: theme.palette.dividerOnDark
        }
    }

    return (
        <div className='big-footer-no-links-wrapper' style={styles.footerWrapper}>
            <div className='big-footer-no-links-main-container'>
                <div item className='big-footer-no-links-logo-container'>
                    <Link to={linksMap.home}>
                        <Logo height={30} alt="logo" style={styles.icon} />
                    </Link>
                </div>
                <Grid container direction='column' className='big-footer-no-links-slogan-container'>
                    <Grid item className='big-footer-no-links-slogan-item'>
                        <Typography variant='body1' style={styles.footerHighlight}>
                            {textTransform('title', t('rediscoverYourQuantumSystem'))}
                        </Typography>
                    </Grid>
                    <Grid item className='big-footer-no-links-slogan-item'>
                        <Typography variant='body1'>
                            {textTransform('title', t('catchyText'))}
                        </Typography>
                    </Grid>
                </Grid>
                {matchMobile &&
                    <Grid container direction='column' className='big-footer-no-links-social-and-language-container'>
                        <Grid item>
                            <Grid container className='big-footer-no-links-social-media-container'>
                                {footerStructure.socialMediaArray.map((socialElemName, index) => {
                                    let SocialElemIcon = iconsMap[socialElemName];
                                    let socialElemPath = linksMap[socialElemName];
                                    return(
                                        <Grid item className='big-footer-no-links-social-media-item' key={index}>
                                            <a href={socialElemPath} target="_blank" aria-label={`${socialElemName} channel`}>
                                                <SocialElemIcon 
                                                    style={{ ...styles.icon, ...styles.socialMedia }} 
                                                />
                                            </a>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>
                        <Grid item className='big-footer-no-links-language-item'>
                            <LanguageMenu iconStyle={{...styles.icon, ...styles.socialMedia}} user={user} />
                        </Grid>
                    </Grid>
                }
                <Divider className='big-footer-no-links-horizontal-divider' style={styles.divider}/>
                {!matchMobile ?
                    <Grid container className='big-footer-no-links-low-container'>
                        <Grid item>
                            <Grid container className='big-footer-no-links-conditions-and-legal-container'>
                            {footerStructure.legalRowArray.map(( rowElemName, index) => {
                                let rowElemText = textTransform('capitalize', t(rowElemName));
                                let rowElemPath = linksMap[rowElemName];
                                let isLast = index === footerStructure.legalRowArray.length - 1;
                                return(
                                    <Grid item key={index}>
                                        <Grid container>
                                            <Grid item>
                                                <Typography variant='body2' component={Link} to={rowElemPath} style={styles.footerLink}>
                                                    {rowElemText}
                                                </Typography>
                                            </Grid>
                                            {!isLast && !matchMobile &&
                                                <Divider orientation="vertical" variant="middle" flexItem  style={styles.divider} />
                                            }
                                        </Grid>
                                    </Grid>
                                )
                            })}
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container className='big-footer-no-links-social-and-language-container'>
                                <Grid item className='big-footer-no-links-language-item'>
                                    <LanguageMenu iconStyle={{...styles.icon, ...styles.socialMedia}} user={user} />
                                </Grid>
                                <Grid item>
                                    <Grid container className='big-footer-no-links-social-media-container'>
                                        {footerStructure.socialMediaArray.map((socialElemName, index) => {
                                            let SocialElemIcon = iconsMap[socialElemName];
                                            let socialElemPath = linksMap[socialElemName];
                                            return(
                                                <Grid item className='big-footer-no-links-social-media-item' key={index} >
                                                    <a href={socialElemPath} target="_blank" aria-label={`${socialElemName} channel`}>
                                                        <SocialElemIcon 
                                                            style={{ ...styles.icon, ...styles.socialMedia }} 
                                                        />
                                                    </a>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    <Grid container className='big-footer-no-links-conditions-and-legal-container'>
                        {footerStructure.legalRowArray.map(( rowElemName, index) => {
                            let rowElemText = textTransform('capitalize', t(rowElemName));
                            let rowElemPath = linksMap[rowElemName];
                            let isLast = index === footerStructure.legalRowArray.length - 1;
                            return(
                                <Grid item key={index}>
                                    <Grid container>
                                        <Grid item>
                                            <Typography variant='body2' component={Link} to={rowElemPath} style={styles.footerLink}>
                                                {rowElemText}
                                            </Typography>
                                        </Grid>
                                        {!isLast && !matchMobile &&
                                            <Divider orientation="vertical" variant="middle" flexItem  style={styles.divider} />
                                        }
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                }
                <div >
                    <Typography variant= "body2">
                        &copy; {`${year} Quamtum Xrroid Institute`}
                    </Typography>
                </div>
            </div>
        </div>
    )
}

export default BigFooterNoLinks
