import { Typography, Box, Grid, Paper } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'


import UncheckedCircleIcon from '../../img/circle.svg';
import CheckedCircleIcon from '../../img/checkCircleOutline.svg';

import './contentDescriptionActions.css'
import { useEffect, useLayoutEffect, useState } from 'react';
import LoadingComponent from '../LoadingComponent/LoadingComponent';

 
const ContentDescriptionActions = ({ actionIdsStructure, actionDetailsMap, userActionProgressMap, onActionUpdate, title, className }) => {
    const ICON_SIZE_PX = 25 
    const theme = useTheme();
    const [actionStatusMap, setActionStatusMap] = useState(null);
    const styles = {
        title:{
            fontWeight: 'bold'
        },
        actionItemContainer:{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[1]
        },
        icon:{
            display:'block',
            width: `${ICON_SIZE_PX}px`,
        },
        circleChecked:{
            fill: theme.palette.success.main
        },
        circleUnchecked:{
            fill: theme.palette.background.paper,
            strokeWidth: '2px',
            stroke: theme.palette.primary.main
        }
    };
    const onCheckboxUpdate = async (actionId) => {
        try{
            setActionStatusMap(actionMap => ({...actionMap, [actionId]: 'loading'}))
            const now = new Date();
            const userActionProgress = userActionProgressMap[actionId];
            const updatedValue = {
                markAsCompleted: !userActionProgress.markAsCompleted,
                lastUpdatedDate: now,
                ...(userActionProgress.completionDate == null && !userActionProgress.markAsCompleted ? { completionDate: now} : {})
            }
            await onActionUpdate('checkbox', actionId, updatedValue);
        }catch(error){
            //
        }finally{
            setActionStatusMap(actionMap => ({...actionMap, [actionId]: 'idle'}))
        }
    }

    const handleOnClick = (actionTypeName, actionId) => {
        switch(actionTypeName){
            case 'checkbox':
                onCheckboxUpdate(actionId);
                break;
        }
    }

    const getActionComponent = (actionId, action) => {
        let actionTypeName = action.actionTypeName;
        let userActionProgress = userActionProgressMap[actionId]; 
        let actionComponent;
        switch(actionTypeName){
            case 'checkbox':
                actionComponent = (
                    <div className="content-description-actions-content">
                        <div className="content-description-actions-button" onClick={(e) => {handleOnClick(actionTypeName, actionId)}}>
                            {actionStatusMap[actionId] === 'loading' ?
                                    <LoadingComponent visibleElements='circle' circleSize={ICON_SIZE_PX} />
                                :
                                    userActionProgress && userActionProgress.markAsCompleted ? 
                                        <CheckedCircleIcon  style={{...styles.icon, ...styles.circleChecked}} />
                                    :
                                        <UncheckedCircleIcon style={{...styles.icon, ...styles.circleUnchecked}} />
                            }
                        </div>
                        <Typography variant='body1'>
                            {action.description.title}
                        </Typography>
                    </div >
                    )
                break;
        }
        return actionComponent;
    }
    useLayoutEffect(() => {
        if(actionIdsStructure instanceof Array){
            setActionStatusMap(actionIdsStructure.reduce((actionIdMap, actionId) => {
                actionIdMap[actionId] = 'idle';
                return actionIdMap
            },{}))
        }else{
            setActionStatusMap(null);
        }
    },[actionIdsStructure])

    return (
        actionStatusMap ? 
            <div className={className}>
                <Typography variant="h6" style={styles.title}>
                    {title}
                </Typography>
                <div className='content-description-actions'container direction="column">
                    {actionIdsStructure.map((actionId) => {
                        let action = actionDetailsMap[actionId];
                        let actionComponent = getActionComponent(actionId, action);
                        return(
                            <div key={action.description.orderIndex} item className='content-description-actions-item' style={styles.actionItemContainer}>
                                {actionComponent}
                            </div>
                        )
                    })}
                </div>
            </div>
            :
            null
    )
}

export default ContentDescriptionActions
