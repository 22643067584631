import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { textTransform } from '../../aux/aux';
import CustomPopover from '../CustomPopover/CustomPopover';
import ImgDN from '../../cdn/imgDN';
import cloudinary from '../../cdn/providers/cloudinary';
// TODO: implement default img
// import defaultPanelistImg from '../../img/default/example.jpg'
import SocialProfileBadge  from '../SocialProfileBadge/SocialProfileBadge';
import './socialProfileBadgeWithPopover.css';

const SocialProfileBadgeWithPopover = ({ imgId, imgUrl, label, popOverDiameterPx=60, diameterPx=30, style, className, palette }) => {
  const IMG_RESOLUTION_SCALE_FACTOR = 3;
  const badgeRef = useRef();
  const [isHover, setIsHover] = useState(false);
  const [imgPanelistUrl, setImgPanelistUrl] = useState(null);

  const handleOnMouseEnter = (e)=>{
    setIsHover(true);
  }

  const handleOnMouseLeave = (e) => {
    setIsHover(false);
  }


  useEffect(() => {
    if(imgId || imgUrl){
      let imgPanelistUrl;
      let auxImgUrl;
      if(imgUrl){
        imgPanelistUrl = imgUrl
      }else if(imgId){
        const assetProvider = new ImgDN(cloudinary);
        auxImgUrl = assetProvider.getAssetUrl(imgId,{height: diameterPx * IMG_RESOLUTION_SCALE_FACTOR});
      }
      imgPanelistUrl = imgPanelistUrl || auxImgUrl;
      setImgPanelistUrl(imgPanelistUrl);
    }
  },[imgId, imgUrl])

  return (
    <div 
      ref={badgeRef} 
      className='social-profile-badge-with-popover-main-wrapper' 
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      {label ? 
        <CustomPopover 
        open={isHover} 
        anchorEl={badgeRef} 
        anchorOrigin={{vertical:'bottom', horizontal:'center'}} 
        transformOrigin={{vertical:'top', horizontal:'center'}}
        >
          <div className='social-profile-badge-with-popover-popover'>
            <SocialProfileBadge 
              className={className}
              diameterPx={popOverDiameterPx} 
              url={imgPanelistUrl} 
              charToDisplay={label ? label[0] : null}
              palette={palette}
              style={{...style, marginRight: '10px'}}
            />
            <Typography variant='body2'>
              {textTransform('title', label)}
            </Typography>
          </div>
        </CustomPopover>
        :
        null
      }
      <SocialProfileBadge className={className} diameterPx={diameterPx} url={imgPanelistUrl} charToDisplay={label ? label[0] : null} palette={palette} style={style}/>
    </div>
  ) 
}

export default SocialProfileBadgeWithPopover