import { Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import FeaturedFeaturesWidget from '../FeaturedFeaturesWidget/FeaturedFeaturesWidget';
import DevicesIcon from '../../img/deviceIcon.svg';
import LessonsIcon from '../../img/lessons.svg';
import LiveLectureIcon from '../../img/live.svg';
import WorkguideIcon from '../../img/workguide.svg';
import DownloadLectureIcon from '../../img/download-lecture-icon.svg';
import './contentFeaturedFeatures.css'
import { useTranslation } from 'react-i18next';
import { isNotEmptyObject, textTransform } from '../../aux/aux';
import { useMemo } from 'react';
import StyledTypography from '../StyledTypography/StyledTypography';

const ContentFeaturedFeatures = ({ description=undefined, featuresKeyArray=[], featureFigureMap={}, title=undefined, subtitle=undefined, noVerticalPadding=false}) => {
    const { t } = useTranslation('contentProduct');
    const matchMobile = useMediaQuery('(max-width: 680px)');
    const theme = useTheme();
    const featuredFeaturesArray = useMemo(() => {
        const featuresArray = [];
        featuresKeyArray.forEach((featureKey, index) => {
            const featureFigure = featureFigureMap[featureKey]
            if(Number.isFinite(featureFigure)){
                let Icon;
                let label;
                let labelPopOverMsg;
                switch(featureKey){
                    case 'numDevice':
                        Icon = DevicesIcon;
                        label = t('features.device.label', {count: featureFigure});
                        labelPopOverMsg = t('features.device.popOverMsg', '')
                        break;
                    case 'numLesson':
                        Icon = LessonsIcon;
                        label = t('features.lesson.label', {count: featureFigure});
                        labelPopOverMsg = t('features.lesson.popOverMsg', '')
                        break;
                    case 'numLiveLesson':
                        Icon = LiveLectureIcon;
                        label = t('features.liveLesson.label', {count: featureFigure});
                        labelPopOverMsg = t('features.liveLesson.popOverMsg', '')
                        break;
                    case 'numWorkguide':
                        Icon = WorkguideIcon;
                        label = t('features.guide.label', {count: featureFigure});
                        labelPopOverMsg = t('features.guide.popOverMsg', '')
                        break;
                    case 'numDownloadLesson':
                        Icon = DownloadLectureIcon;
                        label = t('features.downloadLesson.label', {count: featureFigure});
                        labelPopOverMsg = t('features.downloadLesson.popOverMsg', '');
                }
                featuresArray.push({
                    Icon,
                    figure : featureFigure,
                    label,
                    labelPopOverMsg
                })
            }
        });
        return featuresArray;
    },[featureFigureMap])
    const styles = {
        title:{
            fontWeight: 'bold',
            textAlign: 'center'
        },
        subtitle:{
            fontWeight: 'bold'
        },
        imgContainer:{
            backgroundColor: theme.palette.surface.light[1]
        },
        description:{
            whiteSpace:'pre-line'
        }
    }

    return (
        <div className='content-featured-features-wrapper'>
            <div className={`content-featured-features-main-container${noVerticalPadding ? ' no-vertical-padding' : ''}`}>
                {title &&
                    <div className='content-featured-features-title-container'>
                        <Typography variant={matchMobile ? 'h5':'h4'} style={styles.title}>
                            {textTransform('title', title)}
                        </Typography>
                    </div>
                }
                {subtitle &&
                    <div className='content-featured-features-subtitle-container'>
                        <Typography variant={matchMobile ? 'h6':'h5'} style={styles.subtitle}>
                            {textTransform('title', subtitle)}
                        </Typography>
                    </div>
                }
                <div className='content-featured-features-content-container'>
                    {description &&
                        <div className='content-featured-features-content-description-container'>
                            <StyledTypography variant='body1' style={styles.description} >
                                {description}
                            </StyledTypography>
                        </div>
                    }
                    {isNotEmptyObject(featureFigureMap) &&
                        <div className='content-featured-features-content-img-container' style={styles.imgContainer}>
                            <FeaturedFeaturesWidget featuresArray={featuredFeaturesArray} />
                        </div>
                    }
                </div>
            </div>
        </div>        
    )
}

export default ContentFeaturedFeatures