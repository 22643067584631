import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Tooltip, Popover, Typography, Box, Grid, TextField, InputAdornment, IconButton, useMediaQuery } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import ButtonLoading from '../ButtonLoading/ButtonLoading';
import { useUpdateCountryAndCurrencyCtx } from '../../customHooks/useUpdateCountryAndCurrencyCtx';
import { useLocaleCtx } from '../../customHooks/useLocaleCtx';

import PopUpCreateAccount from '../PopUpCreateAccount/PopUpCreateAccount';
import PopUpForgetPassword from '../PopUpForgetPassword/PopUpForgetPassword';

// Style
import './popUpLogInMenu.css'
import { textTransform } from '../../aux/aux';
import LogInAndVerifyForm from '../LogInAndVerifyForm/LogInAndVerifyForm';
import { useUpdateLocaleCtx } from '../../customHooks/useUpdateLocaleCtx';
import { justSetUser } from '../../services/sessionServices';


const PopUpLogInMenu = ({ setUser, user, addMessages, onShowPopUp=null, onShowMenu=null, passiveVerification=true }) => {
    const theme = useTheme();
    const { t } = useTranslation('common', { keyPrefix: 'popUpLogInMenu'}); 
    const updateCountryAndCurrencyCtx = useUpdateCountryAndCurrencyCtx();
    const updateLocaleCtx = useUpdateLocaleCtx()
    const matchDesktop = useMediaQuery('(min-width:1120px)');
    const [anchorEl, setAnchorEl] = useState(null);
    const accountMenuOpen = Boolean(anchorEl);
    const [isVerifyAccount, setIsVerifyAccount] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);

    const onMouseEnter = (e) => {
        e.target.style.textDecoration= `underline ${theme.palette.primary.main}`;
    }
    const onMouseLeave = (e) => {
        e.target.style.textDecoration= "none";
    }

    const handleOnLogIn = async (userData) => {
        justSetUser(userData, setUser)
        updateCountryAndCurrencyCtx.setCountryAndCurrency(userData.countryAndCurrency)
        await updateLocaleCtx(userData.locale)
        handleClose()
    }

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleOnClickForgotPassword = (e) => {
        handleClose();
        setShowForgotPassword(true);
    }

    const handleOnClickCreateUser = (e) => {
        handleClose();
        setShowCreateAccountModal(true);
    };

    const handleOnCloseForgotPassword = (e) => {
        setShowForgotPassword(false);
    }

    useEffect(() => {
        if(typeof onShowPopUp === 'function'){
            let showPopup = showCreateAccountModal || showForgotPassword;
            onShowPopUp(showPopup)
        }
    }, [showCreateAccountModal, showForgotPassword])

    useEffect(() => {
        if(typeof onShowMenu === 'function'){
            onShowMenu(anchorEl != null);
        }
    }, [anchorEl])

    return (
        <>  
            {showForgotPassword &&
                <PopUpForgetPassword show={showForgotPassword} onClose={handleOnCloseForgotPassword} />
            }
            {showCreateAccountModal &&
                <PopUpCreateAccount showModal={showCreateAccountModal} setShowModal={setShowCreateAccountModal} setUser={setUser} user={user} passiveVerification={passiveVerification}/>
            }
            <Tooltip title={textTransform('title', t("loginOrSignUp"))}>
                <Typography className="login-text-link login-bar-nav-elem login-text-cickable"
                    variant= "body2"
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onClick={handleClick}
                >
                    {textTransform('title', t("login"))}
                </Typography>
            </Tooltip>
            <Popover
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                disableScrollLock={true}
                id= "log-in-menu"
                open={accountMenuOpen}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    className:"login-menu-popover"
                }}
                anchorOrigin={{vertical:"bottom", horizontal:"right"}}
                transformOrigin={{vertical:"top", horizontal:"right"}}
            >
                <Box className="login-menu-popover-main">
                    <Grid container direction="column" alignItems='center'>
                        <Grid item className="login-menu-popover-item">
                            <Typography>
                                <Box fontWeight="bold">
                                    {textTransform('title', !isVerifyAccount ? t("login") : t("verifyAccount"))}
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item style={{width:'100%'}}>
                            <LogInAndVerifyForm 
                                onLogin={handleOnLogIn}
                                onFormTypeChange={(formName) => setIsVerifyAccount(formName === 'verify')}
                                passiveVerification={passiveVerification}
                            />   
                        </Grid>
                        {!isVerifyAccount &&
                            <Grid className="login-menu-popover-item" item>
                                <Typography className="login-text-cickable"
                                    variant='body2'
                                    onMouseEnter={onMouseEnter}
                                    onMouseLeave={onMouseLeave}
                                    onClick={handleOnClickForgotPassword}
                                    style={{color:theme.palette.primary.main}}
                                >
                                    {`¿${textTransform('title', t("forgetYourPass"))}?`}
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                    {!isVerifyAccount &&
                        <>
                            <Divider variant="middle" />
                            <Grid container direction="column" alignItems="center">
                                <Grid item style={{paddingTop:'20px'}}>
                                    <Button variant="outlined" color="secondary" onClick={handleOnClickCreateUser}>
                                        {textTransform('capitalize', t("signUp"))}
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    }
                </Box>
            </Popover>
        </>
    )
}

export default PopUpLogInMenu
