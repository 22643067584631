/**
 * This module contains helper functions to deal with displayable promoCodes
 */

import { getFromSafeObject, isEmptyObject, isNotEmptyObject } from "./aux"

const isPromoCodeDisplayableInProductPage = (productId, productTypeName, promoCode) => {
    const exclusiveProductIdMap = getFromSafeObject(promoCode, 'details.exclusiveProductIdMap') || {};
    const exclusiveProductTypeMap = getFromSafeObject(promoCode, 'details.exclusiveProductTypeMap') || {};
    return isPromoCodeDisplayableEveryWhere(promoCode) || exclusiveProductIdMap.hasOwnProperty(productId) || exclusiveProductTypeMap.hasOwnProperty(productTypeName)
}

const isPromoCodeDisplayableInProductType = (productTypeName, promoCode) => {
    const exclusiveProductTypeMap = getFromSafeObject(promoCode, 'details.exclusiveProductTypeMap') || {};
    return isPromoCodeDisplayableEveryWhere(promoCode) || exclusiveProductTypeMap.hasOwnProperty(productTypeName)
}

const isPromoCodeDisplayableEveryWhere = (promoCode) => {
    const exclusiveProductIdMap = getFromSafeObject(promoCode, 'details.exclusiveProductIdMap') || {};
    const exclusiveProductTypeMap = getFromSafeObject(promoCode, 'details.exclusiveProductTypeMap') || {};
    return isEmptyObject(exclusiveProductIdMap) && isEmptyObject(exclusiveProductTypeMap);
}

export{
    isPromoCodeDisplayableInProductPage,
    isPromoCodeDisplayableInProductType,
    isPromoCodeDisplayableEveryWhere
}