import { sessionVariables } from "./sessionHelpers";

const BASE_URL = `${process.env.REACT_APP_API_BASEPATH}:${process.env.REACT_APP_API_PORT}/${process.env.REACT_APP_API_SUBDOMAIN}/`; 

function getAPIEventSource(path, onmessage, onerror=null){
    const url = `${BASE_URL}${path}`;
    const eventSource = new EventSource(url);
    eventSource.onmessage = onmessage;
    eventSource.onerror = typeof onerror === 'function' ? onerror : (event) => {eventSource.close();}
    return eventSource;
}
// TODO: Untested
// function getAPIEventSource(path, onmessage, reconnectAttemps=0, reconnectingFrequencySec=1){
//     console.log('getting eventSource')
//     const url = `${BASE_URL}${path}`;
//     out = new EventSource(url);
//     out.onmessage = onmessage;
//     out.onerror = (event) => {
//         console.log('error')
//         out.close();
//         const newAttempts = reconnectAttemps - 1;
//         if(newAttempts >= 0){
//             const newReconnectingFrequency = reconnectingFrequencySec >= 64 ? 64 : reconnectingFrequencySec * 2;
//             setTimeout(() => getAPIEventSource(out, path, onmessage, newAttempts, newReconnectingFrequency), newReconnectingFrequency * 1000);
//         }
//     }
// }

export {
    getAPIEventSource
}