import {useTranslation} from 'react-i18next';
import { Avatar, Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { textTransform } from '../../aux/aux';
import './purchaseProcessRoadMap.css';
import React from 'react';

const PurchaseProcessRoadMap = ({ purchaseProcessSteps, currentStep }) => {
    const theme = useTheme();
    const { t } = useTranslation('common', { keyPrefix: 'purchaseProcessRoadMap' })
    const stepCircleSize= 40;
    const styles ={
        stepUnfilled:{
            opacity: theme.palette.action.disabledOpacity,
            color: theme.palette.grey[500],
            // backgroundColor:'#f3f3f3',
            // color:theme.palette.primary.main
        },
        stepFilled:{
            color: theme.palette.grey[500],
            // color:theme.palette.primary.main,
            // backgroundColor:theme.palette.primary.main,
            // color: 'white'
        },
        lineUnfilled:{
            borderTop: `solid 1px ${theme.palette.grey[400]}`,
            opacity: theme.palette.action.disabledOpacity,
        },
        lineFilled:{
            borderTop: `solid 1px ${theme.palette.grey[400]}`
        },
        avatar:{
            height:stepCircleSize,
            width:stepCircleSize
        }

    }
    return (
        <div className='purchase-process-roadmap-main'>
            {/* <Grid container justifyContent="space-evenly">
                {
                    purchaseProcessSteps.map( (step, stepNum) => {
                        return(
                            <Grid item key={stepNum} xs={stepNum !== purchaseProcessSteps.length -1 ? true : false}>
                                <Grid container>
                                    <Grid item style={{width:stepCircleSize}}>
                                        <Avatar className='' style={stepNum <= currentStep ? {...style.stepFilled, ...style.avatar} : {...style.stepUnfilled, ...style.avatar}} >
                                            <Typography variant='body2'>
                                                {stepNum+1}
                                            </Typography>
                                        </Avatar>
                                    </Grid>
                                    {stepNum !== purchaseProcessSteps.length -1 &&
                                    <Grid item xs>
                                        <Grid container direction="column" style={{height:'100%'}}>
                                            <Grid item style={{height:stepCircleSize}}>
                                                <Grid container direction='column' justifyContent='center' style={{height:'100%'}}>
                                                    <Grid item>
                                                        <div className='purchase-process-roadmap-line' style={stepNum < currentStep ? style.lineFilled : style.lineUnfilled}/>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    }
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </Grid>
            <Grid container justifyContent='space-evenly'>
                {
                    purchaseProcessSteps.map( (step, stepNum) => {
                        return(
                            <Grid item xs={stepNum !== purchaseProcessSteps.length -1 ? true : false}>
                                <Typography variant='body2'>
                                    {textTransform('title', t(step))}
                                </Typography>
                            </Grid>
                        )
                    })
                }
            </Grid> */}
            <Grid container justifyContent="space-evenly" alignItems='center'>
                {
                    purchaseProcessSteps.map( (step, stepNum) => {
                        return(
                            <React.Fragment key={stepNum}>
                                    <Grid item>
                                        <div className='purchase-process-roadmap-step-container' style={stepNum <= currentStep ? styles.stepFilled : styles.stepUnfilled}>
                                            <Typography variant='body2'>
                                                {textTransform('title', t(step))}
                                            </Typography>
                                        </div>
                                    </Grid>
                                    {stepNum !== purchaseProcessSteps.length -1 &&
                                    <Grid item xs>
                                        <div className='purchase-process-roadmap-line' style={stepNum < currentStep ? styles.lineFilled : styles.lineUnfilled}/>
                                    </Grid>
                                    }
                            </React.Fragment>
                        )
                    })
                }
            </Grid>
        </div>
    )
}

export default PurchaseProcessRoadMap
