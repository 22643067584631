import { Typography, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/styles'
import { textAlign } from '@mui/system';
import './landingValueInFigures.css'

const LandingValueInFigures = () => {
    const theme = useTheme();
    const { t } = useTranslation(['landing','common'], { keyPrefix: 'landingValueInFigures' });
    const backgroungImgURL = '/img/landing-in-figures-background.jpeg';
    const figuresArray = [
        {
            figure: '20',
            unit: ` ${t('common:year', { count: 20})}`,
            variable: t('goingAlongWithSpecialist')
        },
        {
            figure: '1.000',
            unit: '+',
            variable: t('trainedColleages')
        },
        {
            figure: '98.000',
            unit: `+`,
            variable: t('assitedCases')
        },
    ]
    const styles = {
        wrapper:{
            backgroundColor: theme.palette.primary.main,
            backgroundSize: 'cover',
            backgroundImage: `linear-gradient(rgba(36,36,36,0.72), rgba(36,36,36,0.72)), url(${backgroungImgURL})`,
            backgroundPosition: 'center'
        },
        mainContainer:{
            // backgroundColor: 'rgba(0,0,0,0.8)',
        },
        figure:{
            textAlign: 'center',
            color: theme.palette.text.contrast
        },
        value:{
            display:'inline',
            fontWeight: 'bold',
        },
        unit:{
            display:'inline',
            fontWeight: 'bold'
        },
        variable:{
            fontWeight: 'bold',
            whiteSpace: 'pre-line',
            textAlign: 'center'
        }
    }

    return (
        <div className='landing-value-in-figures-wrapper' style={styles.wrapper}>
            <Grid container className='landing-value-in-figures-main-container' justifyContent='center' style={styles.mainContainer}>
                {figuresArray.map((figure, index) => {
                    const lastItem = figuresArray.length - 1 === index;
                    return(
                        <Grid key={index} item className={`landing-value-in-figures-item ${lastItem ? 'last-item' : ''}`} style={styles.figure}>
                            <Grid container direction='column'>
                                <Grid item className='landing-value-in-figures-item-figure'>
                                    <Typography variant={'h4'} style={styles.value}>
                                        {figure.figure}
                                    </Typography>
                                    <Typography variant={'h4'} style={styles.unit}>
                                        {figure.unit}
                                    </Typography>
                                </Grid>
                                <Grid item className='landing-value-in-figures-item-description'>
                                    <Typography variant='body1' style={styles.variable}>
                                        {figure.variable}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        </div>
    )
}

export default LandingValueInFigures