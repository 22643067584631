import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Stripe
import {
    PaymentElement,
    useStripe,
    useElements
} from '@stripe/react-stripe-js';
// QUERIES
import { validateCheckoutAmountFn } from '../../aux/purchaseQueries';
// HELPERS
import { getCheckoutContentPurchasabilityDetails, priceToString } from '../../aux/purchaseHelpers';
// MUI
import { Box, Divider, Grid, Typography } from '@material-ui/core';
// Components
import ButtonLoading from '../ButtonLoading/ButtonLoading';
import DefaultPaymentMethodSelect from '../DefaultPaymentMethodSelect/DefaultPaymentMethodSelect';
// Style
// Img
import NextIcon from '../../img/next-icon.svg';
import './checkoutPaymentMethod.css';
import { useLocation } from 'react-router-dom';
import { requestMinimumTimeoutWrapper } from '../../aux/requestMethods';
import { isEmptyObject, isNotEmptyObject, textTransform } from '../../aux/aux';
import ToggleMsg from '../ToggleMsg/ToggleMsg';
import { getCustomErrorOrUndefined } from '../../aux/errorHelpers';

const CheckoutPaymentMethod = ({ checkoutId, checkoutProductsMap, addMessages, billingAddress, redirectUrl, setPaymentIntentId, setIsReady, isReady, finalAmountObject, handleIsCheckoutUpdating, updateCheckoutStatus, checkoutSyncAfterPIFailureCallback, contactData={}, updatePaymentIntentWithPaymentMethod=()=>{}, defaultPaymentMethod=null }) => {
    const { t } = useTranslation('checkout' , {keyPrefix: "checkoutPaymentMethod" })
    const location = useLocation();
    const stripe = useStripe();
    const elements = useElements();
    const [isCheckoutUpdating, setIsCheckoutUpdating] = handleIsCheckoutUpdating;
    const [isDefaultMethodSelected, setIsDefaultMethodSelected] = useState(defaultPaymentMethod !== null && isNotEmptyObject(defaultPaymentMethod));
    const [isLoading, setIsLoading] = useState(false);
    const [isNewMethodOpen, setIsNewMethodOpen] = useState(false);
    const abortControllerSignalRef = useRef(null);
    const billingDetails = {
        ...(contactData.hasOwnProperty('firstName') ? { name: `${textTransform('title', contactData.firstName)} ${textTransform('title', contactData.lastName)}`} : {}), // required by Klarna 
        ...(contactData.hasOwnProperty('email') ? { email: contactData.email } : {}), // required by Klarna 
        address: {
            country: billingAddress.countryCode,
            postal_code: billingAddress.zipCode, 
        }
    }
    const PAYMENT_ELEMENT_OPTIONS = {
        layout:{
            type: 'tabs',
            defaultCollapsed:true
        },
        fields: {
            billingDetails: {
                ...(contactData.hasOwnProperty('firstName') ? { name: 'never' } : {}), // required by Klarna 
                ...(contactData.hasOwnProperty('email') ? { email: 'never' } : {}), // required by Klarna 
                address: {
                    country: 'never',
                    postalCode: 'never'
                }
            }
        }
    }
    const styles = {
        icon:{ display:'block'}
    }

    const handleOnSubmit = async (e, signal=abortControllerSignalRef.current) => {
        const confirmIntentWithDefaultPaymentMethod = async (confirmationType) => {
            const getStripeConfirmDefaultMethod = (methodType, isSetupIntent) => {
                // TODO: get supported payment methods from server
                if(!['card', 'klarna'].includes(methodType)){
                    throw new Error(`unsuported stripe payment method. type: ${methodType}`)
                }
                const methodAndIntentType = `${textTransform('title',methodType)}${isSetupIntent ? 'Setup' : 'Payment'}`
                return stripe[`confirm${methodAndIntentType}`] 
            }
            try{
                const res = await updatePaymentIntentWithPaymentMethod(defaultPaymentMethod.id)
                const paymentIntentClientSecret = res.data.paymentIntentClientSecret;
                const isSetupIntent = confirmationType !== 'regularPurchase';
                const stripeRes = await getStripeConfirmDefaultMethod(defaultPaymentMethod.type, isSetupIntent)(paymentIntentClientSecret);
                return isSetupIntent ? stripeRes.setupIntent : stripeRes.paymentIntent; 
            }catch(error){
                // Ensures PI does not have any pm attached
                await updatePaymentIntentWithPaymentMethod(null);
                throw error
            }
        }

        const confirmIntentWithNewPaymentMethod = async (confirmationType) => {
            let intent;
            if(confirmationType === 'regularPurchase'){
                intent = await confirmPaymentIntent();
            }else{
                intent = await confirmSetupIntent()
            }
            return intent
        }
        const confirmIntent = async (confirmationType, isDefaultMethodSelected) => {
            try{
                let intent;
                if(isDefaultMethodSelected === true){
                    intent = await confirmIntentWithDefaultPaymentMethod(confirmationType);
                }else{
                    intent = await confirmIntentWithNewPaymentMethod(confirmationType);
                }
                if(intent){
                    // Ready to show feedback
                    setPaymentIntentId(intent.id);
                }else{
                    throw new Error('intent could not be confirmed');
                }
            }catch(error){
                // Update status to open and remove any linked PromoCode if needed
                await checkoutSyncAfterPIFailureCallback();
                throw error
            }
        }
        try{
            e.preventDefault();
            setIsCheckoutUpdating(true)
            setIsLoading(true);
            const isPurchasable = await validateCheckoutContentPurchasability(checkoutId, signal);
            if(isPurchasable){
                const confirmationType = await validateCheckoutFinalAmount(finalAmountObject, signal);
                if(confirmationType){
                    // Update status and release promocode linked timeouts if needed
                    await updateCheckoutStatus('confirming'); 
                    await confirmIntent(confirmationType, isDefaultMethodSelected);
                }
            }
        }catch(error){
            if(!signal || !signal.aborted){
                addMessages([{
                    message: `${textTransform('title',t("purchaseCouldNotBeConfirmed"))}.`
                        +` ${textTransform('title',t("tryItAgainWithADifferentPaymentMethod"))}`,
                    severity:'error'
                }]);
            }
        }finally{
            if(!signal || !signal.aborted){
                setIsLoading(false);
                setIsCheckoutUpdating(false)
            }
        }
    };

    const validateCheckoutFinalAmount = async (finalAmountObject, signal=null) => {
        try{
            const minimumTimeOutInMilliseconds = 0;
            const requestParams = {
                hashFinalAmount: finalAmountObject.hashFinalAmount,
            };
            const res = await requestMinimumTimeoutWrapper(validateCheckoutAmountFn, requestParams, minimumTimeOutInMilliseconds, signal);
            return res.data.status;
        }catch(error){
            let message, severity;
            switch(error.response.data.cause){
                case 'wrongHash':
                    message = `${t("wrongHashFinalAmountError")}`
                    severity = 'error'
                    break;
                case 'underMinAmount':
                    message = `${t("underMinAmountWarning")} ${priceToString(finalAmountObject.currencyId, error.response.data.minAmountInCents)}`;                    
                    severity = 'warning';
                    break;
            };
            if(message){
                if(!signal || !signal.aborted){
                    addMessages([{
                        message,
                        severity
                    }]);
                }
                return undefined
            }else{
                throw error;
            }
        };
    }

    const validateCheckoutContentPurchasability = async (checkoutId, signal=null) => {
        try{
            const res = await getCheckoutContentPurchasabilityDetails(checkoutId, signal)
            const {
                isPurchasable,
                checkoutProductPurchasabilityArray,
                noPurchasableProductToAddIndexArray
            } = res.data;
            if(!isPurchasable){
                const errorMsgArray = [];
                for(let index of noPurchasableProductToAddIndexArray){
                    const checkoutProductPurchasability = checkoutProductPurchasabilityArray[index];
                    if(checkoutProductPurchasability){
                        const checkoutProduct = checkoutProductsMap[checkoutProductPurchasability.productId]
                        if(checkoutProduct){
                            errorMsgArray.push({
                                severity:'error',
                                message: textTransform('title', t("cannotPurchaseThisProduct", {productName: checkoutProduct.description.nameTranslated})),
                            })
                        }
                    }
                }
                if(!signal || !signal.aborted){
                    addMessages(errorMsgArray)
                }
            }
            return isPurchasable;
        }catch(error){
            const customError = getCustomErrorOrUndefined(error)
            if(customError && customError.cause === 'sr_203'){
                addMessages([{
                    severity:'warning',
                    message: textTransform('title', t("checkoutIsEmpty")),
                }])
                return false
            }else{
                throw error;
            }
        }
    }
    
    const confirmPaymentIntent = async () => {
        try{
            let paymentIntent;
            if(!stripe || ! elements){
               // Stripe.js has not yet loaded.
            }else{
                const res = await stripe.confirmPayment({
                    elements,
                    confirmParams: { 
                        return_url: redirectUrl,
                        payment_method_data: {
                            billing_details: billingDetails
                        } 
                    },
                    redirect:'if_required'
                })
                if(res.error){
                    if(res.error.payment_intent){
                        if(res.error.payment_intent.status === 'requires_payment_method'){
                            addMessages([
                                {
                                    message:res.error.message,
                                    severity: 'error'
                                }
                            ])
                        }else{
                            paymentIntent = res.error.payment_intent;
                        }
                    }else{
                        // Errors related & handled by the form
                        console.log('INFO: Form error handling')
                    }
                }else{
                    paymentIntent= res.paymentIntent;
                }
            }
            return paymentIntent;
        }catch(error){
            // Warning: Potential connetivity error
            addMessages([{
                message: t("unExpectedError") ,
                severity:'error'
            }]);
            throw error
        }
    };

    const confirmSetupIntent = async () => {
        try{
            let setupIntent;
            if(!stripe || ! elements){
               // Stripe.js has not yet loaded.
            }else{
                const res = await stripe.confirmSetup({
                    elements,
                    confirmParams: { 
                        return_url: redirectUrl,
                        payment_method_data: {
                            billing_details: billingDetails
                        } 
                    },
                    redirect:'if_required'
                })
                if(res.error){
                    if(res.error.setup_intent){
                        if(res.error.setup_intent.status === 'requires_payment_method'){
                            addMessages([
                                {
                                    message:res.error.message,
                                    severity: 'error'
                                }
                            ])
                        }else{
                            setupIntent = res.error.setup_intent;
                        }
                    }else{
                        // Errors related & handled by the form
                    }
                }else{
                    setupIntent= res.setupIntent;
                }
            }
            return setupIntent;
        }catch(error){
            // Warning: Potential connetivity error
            addMessages([{
                message: t("unExpectedError") ,
                severity:'error'
            }]);
            throw error
        }
    };
    
    const toggleIsNewMethodOpen = (e) => {
        setIsNewMethodOpen(prev => (!prev))
    }

    const isDisabled = () => {
        return (isLoading || !isReady || isCheckoutUpdating || !checkoutProductsMap || isEmptyObject(checkoutProductsMap));
    }

    const handlePaymentElementOnReady = (paymentElement) => {
        paymentElement.collapse();
        paymentElement.clear();
        setIsReady(true);
    }

    const handleIsDefaultPaymentSelected = (isSelected) => {
        try{
            if(isSelected && elements){
                const paymentElement = elements.getElement('payment');
                paymentElement.collapse();
            }
            setIsDefaultMethodSelected(isSelected)
        }catch(error){
            console.log(error)
        }
    }

    useEffect(() => {
        const abortController = new AbortController();
        abortControllerSignalRef.current = abortController.signal;
        return(() => {
            abortController.abort();
        })
    },[])
    
    return (
        <div className='checkout-payment-method-main'>
            <form className={`checkout-payment-method-form ${isReady ? '': 'checkout-payment-method-hidden'}`} onSubmit={ handleOnSubmit } >  
                <Grid container direction='column'>
                    <Grid item className={`checkout-payment-method-container ${defaultPaymentMethod ? 'show checkout-payment-method-item' : ''}`}>
                        <Grid container direction='column'>
                            <Grid item className='checkout-payment-method-item'>
                                <DefaultPaymentMethodSelect selected={isDefaultMethodSelected} onSelect={() => handleIsDefaultPaymentSelected(true)} defaultPaymentMethod={defaultPaymentMethod} />
                            </Grid>
                            <Grid item container justifyContent='space-between' alignItems='center'>
                                <Grid item className='checkout-payment-method-line'/>
                                <Grid item >
                                    <Box 
                                        className='checkout-payment-method-new-toggle-button-container'
                                        onClick={toggleIsNewMethodOpen}
                                    >
                                        <Typography variant='body2' style={{paddingRight:'5px'}}>
                                            {textTransform('title',t("common:new"))}
                                        </Typography>
                                        <NextIcon 
                                            className='checkout-payment-method-new-toggle-button-next-icon' 
                                            style={styles.icon} 
                                            transform={isNewMethodOpen ? 'rotate(-90)' : 'rotate(90)'}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item className='checkout-payment-method-line'/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={`checkout-payment-method-container ${isNewMethodOpen || !defaultPaymentMethod ? 'show checkout-payment-method-item' : ''}`}>
                        <PaymentElement id='payment-element' onFocus={(e) => handleIsDefaultPaymentSelected(false)} onReady={handlePaymentElementOnReady} options={{ readOnly: isDisabled(), ...PAYMENT_ELEMENT_OPTIONS }}/>
                    </Grid>
                    <Grid item className='checkout-payment-method-button-container'>
                        <ButtonLoading id='submit' disabled={isDisabled()} isLoading={isLoading} label={t("payNow")} 
                        variant="outlined" color="primary" type='submit' fullWidth/>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

export default CheckoutPaymentMethod