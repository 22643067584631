import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/styles';
import { Grid, TextField, Typography } from '@material-ui/core';
// Style
import './findSpecialistForm.css'
import { textTransform } from '../../aux/aux';

const FindSpecialistForm = ({ resetForm, isDisabled, onUpdateForm=()=>{} }) => {
    const MAX_MESSAGE_CHAR_LENGTH = 255;
    const theme = useTheme();
    const { t } = useTranslation('common', { keyPrefix: 'findSpecialistForm' });
    const [form, setForm] = useState({})
    const styles = {
        groupTitle: {
            fontWeight:'bold',
            paddingBottom:'8px'
        },
        remainingCounter: {
            color: theme.palette.grey[500], //'#888',
            textAlign: 'right',
            padding: '0px 10px'
        }
    }

    const clearForm = () => {
        setForm({})
    }

    const handleOnChange = (e) => {
        const field = e.target.name;
        const value = e.target.value;
        if(field !== 'visitReason' || value.length <= MAX_MESSAGE_CHAR_LENGTH){
            setForm(prev => {
                const newForm = { ...prev }
                if(value === '' || value == null){
                    delete newForm[field];
                }else{
                    newForm[field] = value;
                }
                return { ...newForm }
            })
        }
    }

    useEffect(() => {
        if(resetForm){
            clearForm();
        }
    },[resetForm])

    useEffect(() => {
        onUpdateForm({...form})
    },[form])

    return (
        <div className='find-specialist-form-main-container'>
            <div className='find-specialist-form-fields-group'>
                <Typography variant='body1' style={styles.groupTitle}>
                    {textTransform('title', t('contactData'))}
                </Typography>
                <div className='find-specialist-form-field'>
                    <TextField 
                        name='name'
                        label={textTransform('title', t('name'))}
                        value={form.name || ''}
                        onChange={handleOnChange}
                        type="text"
                        inputProps={{style:{textOverflow:'ellipsis'}}}
                        InputLabelProps={{ shrink: form.name !== undefined && form.name !== '' }}
                        variant='standard'
                        color='primary'
                        fullWidth
                        disabled={isDisabled}
                        required
                    />
                </div>
                <div className='find-specialist-form-field'>
                    <TextField 
                        name='email'
                        label={textTransform('title', t('email'))}
                        value={form.email || ''}
                        onChange={handleOnChange}
                        type="email"
                        inputProps={{style:{textOverflow:'ellipsis'}}}
                        InputLabelProps={{ shrink: form.email !== undefined && form.email !== '' }}
                        variant='standard'
                        color='primary'
                        fullWidth
                        disabled={isDisabled}
                        required
                    />
                </div>
            </div>
            <div className='find-specialist-form-fields-group'>
                <Typography variant='body1' style={styles.groupTitle}>
                    {textTransform('title', t('location'))}
                </Typography>
                <div className='find-specialist-form-field'>
                    <TextField 
                        name='country'
                        label={textTransform('title', t('country'))}
                        value={form.country || ''}
                        onChange={handleOnChange}
                        type="text"
                        inputProps={{style:{textOverflow:'ellipsis'}}}
                        InputLabelProps={{ shrink: form.country !== undefined && form.country !== '' }}
                        variant='standard'
                        color='primary'
                        fullWidth
                        disabled={isDisabled}
                        required
                    />
                </div>
                <div className='find-specialist-form-field'>
                    <TextField 
                        name='city'
                        label={textTransform('title', t('city'))}
                        value={form.city || ''}
                        onChange={handleOnChange}
                        type="text"
                        inputProps={{style:{textOverflow:'ellipsis'}}}
                        InputLabelProps={{ shrink: form.city !== undefined && form.city !== '' }}
                        variant='standard'
                        color='primary'
                        fullWidth
                        disabled={isDisabled}
                        required
                    />
                </div>
            </div>
            <div className='find-specialist-form-fields-group'>
                <Typography variant='body1' style={styles.groupTitle}>
                    {textTransform('title', t('visitReason'))}
                </Typography>
                <div className='find-specialist-form-field'>
                    <Typography variant='body2' style={ styles.remainingCounter}>
                        {`${MAX_MESSAGE_CHAR_LENGTH - (form.visitReason?.length || 0)}`}
                    </Typography>
                    <TextField 
                        name='visitReason'
                        value={form.visitReason || ''}
                        placeholder={`${textTransform('title', t('detailYourVisitReason'))} ...`}
                        onChange={handleOnChange}
                        type="text"
                        multiline
                        minRows={4}
                        maxRows={4}
                        variant='outlined'
                        color='primary'
                        required
                        disabled={isDisabled}
                        fullWidth
                    />
                </div>
            </div>
        </div>
    )
}

export default FindSpecialistForm