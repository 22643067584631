import { PixelCrop } from 'react-image-crop'

const TO_RADIANS = Math.PI / 180

export function calculateSize(image, maxSize){
  let w = image.width,
    h = image.height;
  if (w > h) {
    if (w > maxSize.width) {
      h = Math.round((h * maxSize.width) / w);
      w = maxSize.width;
    }
  } else {
    if (h > maxSize.height) {
      w = Math.round((w * maxSize.height) / h);
      h = maxSize.height;
    }
  }
  return [w, h];
};

export function calculateScale(size, maxSize){
  let w = size.width ;
  let h = size.height;
  let scale;
  if(w > h){
    scale = maxSize.width / w;
  }else{
    scale = maxSize.height / h;
  }
  return scale
}

/**
 * 
 * @param {HTMLImageElement} image 
 * @param {HTMLCanvasElement} canvas 
 * @param {PixelCrop} crop 
 * @param {Number} scale 
 * @param {Number} rotate
 * @param {String} backgroudColor 
 */
export async function canvasPreview( image, canvas, crop, scale = 1, rotate = 0, backgroudColor='white'){
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context')
  }
  const imgNaturalWidth = image.naturalWidth;
  const imgNaturalHeight = image.naturalHeight;
  const scaleX = imgNaturalWidth / image.width
  const scaleY = imgNaturalHeight / image.height
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

  ctx.scale(pixelRatio, pixelRatio)
  ctx.imageSmoothingQuality = 'high'

  const cropX = crop.x * scaleX
  const cropY = crop.y * scaleY

  const rotateRads = rotate * TO_RADIANS
  const centerX =  imgNaturalWidth / 2
  const centerY = imgNaturalWidth / 2

  ctx.save()

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY)
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY)
  // 3) Rotate around the origin
  ctx.rotate(rotateRads)
  // 2) Scale the image
  ctx.scale(scale, scale)
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY)
  
  if(backgroudColor){
    ctx.fillStyle = backgroudColor;
    ctx.fillRect(0, 0, image.naturalWidth, image.naturalHeight)
  }

  ctx.drawImage(
    image,
    0,
    0,
    imgNaturalWidth,
    imgNaturalHeight,
    0,
    0,
    imgNaturalWidth,
    imgNaturalHeight,
  )

  ctx.restore()
}

/**
 * 
 * @param {HTMLImageElement} image 
 * @param {HTMLCanvasElement} canvas 
 * @param {PixelCrop} crop 
 * @param {Number} scale 
 * @param {Number} rotate
 * @param {String} backgroudColor 
 */
export async function canvasPreviewAndResize( image, canvas, crop, resizeScale = 1, pixelRatio = 1, scale=1, rotate = 0, backgroudColor='white'){
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context')
  }
  const imgNaturalWidth = image.naturalWidth * resizeScale
  const imgNaturalHeight = image.naturalHeight * resizeScale;
  const scaleX = imgNaturalWidth / image.width
  const scaleY = imgNaturalHeight / image.height
  
// devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  // const pixelRatio = window.devicePixelRatio

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio)



  ctx.scale(pixelRatio, pixelRatio)
  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS
  const centerX =  imgNaturalWidth / 2
  const centerY = imgNaturalHeight / 2

  ctx.save()

  // // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY)
  // // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY)
  // // 3) Rotate around the origin
  ctx.rotate(rotateRads)
  // // 2) Scale the image
  ctx.scale(scale, scale)
  // // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY)
  
  if(backgroudColor){
    ctx.fillStyle = backgroudColor;
    ctx.fillRect(0, 0, image.naturalWidth, image.naturalHeight)
  }

  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    imgNaturalWidth,
    imgNaturalHeight,
  )

  ctx.restore()
}
