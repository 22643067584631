import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Divider } from '@material-ui/core';
// Components
import PopUpModal from '../PopUpModal/PopUpModal'
import PopUpModalHeader from '../PopUpModalHeader/PopUpModalHeader';
import ClassContentUserSystemSelect from '../ClassContentUserSystemSelect/ClassContentUserSystemSelect';

import CableIcon from '@mui/icons-material/Cable';
import './popUpClassContentFirstUserConfig.css'

const PopUpClassContentFirstUserConfig = ({ userConfig, contentSummary, showModal, onClickClose, onUpdate, isDialog, title='' }) => {
    const POP_UP_WIDTH = 380;
    const [innerContainerHeight, setInnerContainerHeight] = useState(null);
    const innerContainerRef = useRef(null);
    const { t } = useTranslation( 'common', {keyPrefix: 'popUpClassContentFirstUserConfig'})
    const handleCloseModal = () => {
        onClickClose()
    }

    const updateInnerContainerHeight = () => {
        if(innerContainerRef.current){
            setInnerContainerHeight(innerContainerRef.current.offsetHeight)
        }
    }
    
    useEffect(() => {
        updateInnerContainerHeight()
    });


    return (
        <PopUpModal showModal={showModal} onClickClose={handleCloseModal} width={`${POP_UP_WIDTH}px`} height={innerContainerHeight} isDialog={isDialog} >
            <Grid ref={innerContainerRef} container direction='column' className='pop-up-class-content-user-system-select-main'>
                <Grid item >
                    <PopUpModalHeader title={title || t("personalizeYourContent")} Icon={CableIcon} />
                </Grid>
                <Divider />
                <Grid item className='pop-up-class-content-first-user-config-main-container'>
                    <ClassContentUserSystemSelect userConfig={userConfig} contentSummary={contentSummary} onUpdate={onUpdate} onFinish={handleCloseModal} onRender={updateInnerContainerHeight}/>
                </Grid>
            </Grid>
        </PopUpModal>
    )
}

export default PopUpClassContentFirstUserConfig