import axios from 'axios';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { Tooltip, Box, IconButton, Divider, Typography } from '@material-ui/core';
import { MenuItem } from '@mui/material'

import LoadingComponent from '../LoadingComponent/LoadingComponent';

import ImgDN from "../../cdn/imgDN";
import cloudinary from "../../cdn/providers/cloudinary";

import SchoolIcon from "../../img/school-icon.svg";
import LogoutIcon from "../../img/logo-out-icon.svg";
import AccountIcon from "../../img/account-icon.svg";

import './userMenuItemList.css'
import { getFromSafeObject, textTransform } from '../../aux/aux';

const UserMenuItemList = ({ user, onLogout }) => {
    const history = useHistory();
    const { t } = useTranslation('common', { keyPrefix: 'userMenuItemList' });
    const appSubdomain = process.env.REACT_APP_CLASS_SUBDOMAIN;
    const [isLoading, setIsLoading] = useState(false);
    const assetProvider = new ImgDN(cloudinary);
    const styles = {
        icon:{ 
            display: 'block',
            height: '25px',
            paddingRight: '10px'
        }
    }

    const getImgProfileComponent = () => {
        let imgProfileComponent;
        if(getFromSafeObject(user, 'basicData.profileImgURL')){
            const profileImgURL = assetProvider.getAssetUrl(user.basicData.profileImgURL);
            imgProfileComponent = <Box className="bar-user-profile-img" component="img" alt="profile-img" src={profileImgURL} />
        }else{
            imgProfileComponent = <AccountIcon style={styles.icon} height={30} viewBox="0 0 23 23"/>
        }
        return imgProfileComponent
    }

    const itemArray = [
        {
            path: `/${appSubdomain}/account/profile`,
            Icon: AccountIcon,
            name: t('myProfile'),
            onClick: null,
            followedByDivider: false
        },
        {
            path: `/${appSubdomain}/my-content`,
            Icon: SchoolIcon,
            name: t('myContent'),
            onClick: null,
            followedByDivider: true
        },
        {
            path: null,
            Icon: LogoutIcon,
            name: t('signOut'),
            onClick: onLogout,
            followedByDivider: false
        },
    ]

    return(
        <>
            {itemArray.map(( menuItem, index ) => {
                const Icon = menuItem.Icon;
                return(
                    <div key={index}>
                        <MenuItem
                            {...menuItem.path ? { to: menuItem.path, component: Link } : {onClick: menuItem.onClick}}
                        >
                            {Icon ? <Icon  style={styles.icon} /> : null}
                            <Typography variant='body1'>
                                {textTransform('capitalize', menuItem.name)}
                            </Typography>
                        </MenuItem>
                        {menuItem.followedByDivider &&
                            <Divider />
                        }
                    </div>
                )
            })}
        </>
    )
}

export default UserMenuItemList