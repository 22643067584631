import { useEffect, useRef, useState } from 'react';

const ImgLazyLoad = ({ src, placeholderSrc, errorSrc, threshold='100px', onError, ...props }) => {
    const imgRef = useRef(null);
    const [imgSrc, setImgSrc] = useState(placeholderSrc);
    
    const handleOnError = (e) => {
        if(e.target.src === src && errorSrc){
            setImgSrc(errorSrc);
        }
        if(onError){
            onError(e);
        }
    }

    useEffect(() => {
        if(src && imgRef && imgRef.current){
            if(
                'IntersectionObserver' in window ||
                'IntersectionObserverEntry' in window ||
                'intersetionRatio' in window.IntersectionObserverEntry.prototype ||
                'isIntersecting' in window.IntersectionObserverEntry.prototype
            ){
                const handleIntersection = (entries) => {
                    entries.forEach( entry => {
                        if(entry.isIntersecting){
                            setImgSrc(src);
                            observer.unobserve(entry.target);
                        }
                    })
                }
                const observer = new IntersectionObserver(handleIntersection, { rootMargin: threshold });
                observer.observe(imgRef.current);
                return(() => {
                    observer.disconnect();
                })
            }else{
                // load directly the img
                setImgSrc(src)
            }
        }
    },[src])
    
    return (
        <img {...props} ref={imgRef} src={imgSrc} onError={handleOnError}/>
    )
}

export default ImgLazyLoad