import { useEffect, useState, useRef } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/styles';
// Custom component
import LogInSignIn from '../../components/LogInSignIn/LogInSignIn';
// Helpers
import { isUserLogged } from '../../aux/sessionHelpers';
// Material UI
import { Grid, Button, Paper } from '@material-ui/core';
// Style
import './logAndSignIn.css';
import BigFooterNoLinks from '../../components/BigFooterNoLinks/BigFooterNoLinks';
import { justSetUser } from '../../services/sessionServices';
import { useUpdateLocaleCtx } from '../../customHooks/useUpdateLocaleCtx';
import { useUpdateCountryAndCurrencyCtx } from '../../customHooks/useUpdateCountryAndCurrencyCtx';


const LogAndSignIn = ({ user, setUser, addMessages, passiveVerification=true }) => {
    const history = useHistory()
    const theme = useTheme()
    const location = history.location
    const query = new URLSearchParams(location.search);
    const redirectPathParam = 'from'
    const defaultRedirectPath = '/' // '/app/my-content'
    const updateLocaleCtx = useUpdateLocaleCtx();
    const updateCountryAndCurrencyCtx = useUpdateCountryAndCurrencyCtx();
    const [redirectPath, setRedirectPath] = useState(defaultRedirectPath);
    const [processStep, setProcessStep] =useState('');
    const [innerContainerHeight, setInnerContainerHeight] = useState(null);
    const mainContainerRef = useRef(null);
    const innerContainerRef = useRef(null);
    const styles = {
        wrapper: {
            // backgroundColor: theme.palette.surface.light[0]
        }
    }

    const handleOnLogIn = async(userData) => {
        justSetUser(userData, setUser);
        updateCountryAndCurrencyCtx.setCountryAndCurrency(userData.countryAndCurrency);
        await updateLocaleCtx(userData.locale);
    }

    useEffect(() => {
        if(query.get(redirectPathParam)){
            const path = query.get(redirectPathParam);
            setRedirectPath(path);
            
            // if(query.toString().length > 0){
            //     history.replace({
            //         search:'',
            //     })
            // }

            // TODO: show message only if session has expired
            // adding an extra query param
            // addMessages([
            //     {
            //         message:logAndSignInTxt.expiredSession,
            //         severity:"warning"
            //     }
            // ])
        }
        const observer = new ResizeObserver((entries) => {
            const entry = entries[0];
            setInnerContainerHeight(entry.borderBoxSize[0].blockSize);
        })
        if(innerContainerRef.current){
            observer.observe(innerContainerRef.current);
        }
        return(() => {
            observer.disconnect();
        })
    },[])
    
    return (
        <div className='log-and-sign-in-wrapper' ref={mainContainerRef} style={styles.wrapper}>
            {isUserLogged(user) ?
                <Redirect to={redirectPath} />
            :
                <Grid container justifyContent="center" className='log-and-sign-in-main-container'>
                    <Grid item className='log-and-sign-in-modal-container'>
                        <Paper className='log-and-sign-in-modal' style={{height:`${innerContainerHeight}px`}}>
                            <div ref={innerContainerRef} className='log-in-sign-in-modal-inner-container'>
                                <LogInSignIn 
                                    onLogin={handleOnLogIn} 
                                    addMessages={addMessages} 
                                    setProcessStep={setProcessStep} 
                                    passiveVerification={passiveVerification}
                                />
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            }
            <BigFooterNoLinks user={user} />
        </div>
    )
}

export default LogAndSignIn
