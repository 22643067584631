import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import './timer.css'

const Timer = ({ finalDate, inSeconds=false, variant='body2',  ...props }) => {
    const { t } = useTranslation('common');
    const [clockTime, setClockTime] = useState(undefined);
    const [intervalId, setIntervalId] = useState(undefined)
    const clearTimer = () => {
        console.log('INFO: test here')
        clearInterval(intervalId);
        setIntervalId(undefined);
        setClockTime(undefined);
    }

    const fromMillisecondsToTimer= (milliseconds, inSeconds) => {
        let timer;
        let seconds = Math.max(0, Math.floor(milliseconds / 1000));
        if(inSeconds){
            timer = seconds.toString();
        }else{
            let min = seconds / 60 | 0; // truncate and cast the float
            seconds = seconds % 60 | 0; // truncate and cast the float
            let formatedSeconds = `${seconds >= 10 ? '': '0'}${seconds}`;
            timer = `${min}:${formatedSeconds}`;
        }
        return timer 
    }

    useEffect(() => {
        if(finalDate != null && finalDate instanceof Date && !intervalId){
            const id = setInterval(() => {
                const now = new Date();
                const remainingTime = finalDate.getTime() - now.getTime();
                setClockTime(fromMillisecondsToTimer(remainingTime, inSeconds)) 
            }, 1000)
            setIntervalId(id)
            return(()=>{
                console.log('INFO: return')
                clearTimer();
            })
        }
    }, [finalDate])

    return (
        <div {...props} >
            {clockTime ?
                    <Typography variant={variant}>
                        {`${clockTime} ${inSeconds ? t("secondAbbr") : t("minuteAbbr")}`}
                    </Typography>
                :
                    <Skeleton variant='text' width={"40px"}/>
            }   
        </div>
    )
}

export default Timer