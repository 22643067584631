import axios from 'axios';
import {
    isTokenActive, getUserIdFromAccessToken,
    refreshAndSetAuthTokens,
    revokeAuthTokens
} from './authHelpers';
import { until } from './aux';
import { sessionVariables } from './sessionHelpers';

const BASE_URL = `${process.env.REACT_APP_API_BASEPATH}:${process.env.REACT_APP_API_PORT}/${process.env.REACT_APP_API_SUBDOMAIN}/`; 
const TIMEOUT_MS = 10000

/**
 * Creates a blank axios instance
 */
const publicThirdPartyRequest = axios.create({
    timeout: TIMEOUT_MS
});

/**
 * Creates an axios instance pointing out our API
 */
const publicRequest = axios.create({
    baseURL : BASE_URL,
    timeout: TIMEOUT_MS
});

let interceptorResponseErrorHandler,
    forceUserToLogin,
    history,
    addMessages;
const setHistoryForInterceptors = (historyFromHook) => {history = historyFromHook};
const setUpInterceptorsAPIResponseError = (callback) => { interceptorResponseErrorHandler = callback};
const setClientNotificiationsForInterceptors = (fn) => {addMessages = fn};
const setForceUserToLoginForInterceptors = (fn) => {forceUserToLogin = fn};
/**
 * Creates an axios instance pointing out our API with an user configuration to refresh her access
 */
function createAuthRefreshRequest(){
    const userRefreshRequest = axios.create({
        baseURL: BASE_URL,
        headers: {
            refresh_authorization : `Bearer_Refresh ${sessionVariables.get('refreshToken')}`
        },
        timeout: TIMEOUT_MS
    });
    userRefreshRequest.interceptors.request.use(
        async (config) => {
            const refreshToken = config.headers.refresh_authorization;
            if(!isTokenActive(refreshToken)){
                // await revokeAuthTokens(); DEPRECATED on 09/24/22
                forceUserToLogin();
                return Promise.reject('Invalid Auth')
            }
            return config
        }, (error) => {
            return Promise.reject(error);
        }
    )

    userRefreshRequest.interceptors.response.use(
        (response) => { 
            return response
        }, (error) => {
            // ON 10/24/22 absorved by 
            // if(error.response.data?.cause === 'sr_500'){
            //     console.debug("Refresh Tokens revoked")
            //     // ON 09/24/22 absorved by forceUserToLoginFn
            //     // sessionVariables.remove('accessToken');
            //     // sessionVariables.remove('refreshToken');
            //     forceUserToLoginFn();
            //     return Promise.reject('Invalid Auth')
            // }else{
            //     return Promise.reject(error);
            // }
            interceptorResponseErrorHandler(error);
            return Promise.reject(error);
        }
    )
    return userRefreshRequest;
}

/**
 * Lock simultaneous request while auth tokens are being refreshed
 */
let refreshingAuthTokens = false;


/**
 * Creates an axios instance with an user configuration
 */
function createAuthRequest(timeOut=TIMEOUT_MS){
    const userRequest = axios.create({
        baseURL: BASE_URL,
        headers: {
            authorization : `Bearer ${sessionVariables.get('accessToken')}`,
            refresh_authorization : `Bearer_Refresh ${sessionVariables.get('refreshToken')}`
        },
        timeout: timeOut
    });
    userRequest.interceptors.request.use(
        async (config) => {
            const accessToken = config.headers.authorization;
            if(!isTokenActive(accessToken)){
                if(refreshingAuthTokens){
                    try{
                        await until(()=> {
                            const accessToken = sessionVariables.get('accessToken');
                            return isTokenActive(accessToken);
                        });
                    }catch(error){
                        return Promise.reject('Queued query expires after waiting for refreshing auth tokens')
                    }
                }else{
                    try{
                        refreshingAuthTokens = true;
                        const userId = getUserIdFromAccessToken(accessToken);
                        await refreshAndSetAuthTokens(userId);
                    }catch(error){
                        // sessionVariable: accessToken and refreshToken
                        // are removed on revokeAuthTokens
                        return Promise.reject(new Error('Auth tokens could not be refreshed'))
                    }finally{
                        refreshingAuthTokens = false;
                    }
                }
                config.headers.authorization = `Bearer ${sessionVariables.get('accessToken')}`; 
                config.headers.refresh_authorization = `Bearer_Refresh ${sessionVariables.get('refreshToken')}`;
            }
            return config
        }, (error) => {
            return Promise.reject(error);
        }
    )
    // Added ON 09/25/22
    userRequest.interceptors.response.use(
        (response) => { 
            return response
        }, (error) => {
            interceptorResponseErrorHandler(error);
            return Promise.reject(error);
        }
    )
    return userRequest;
}


/**
 * Perform a request and stop the thread at least a minimum set
 */
const minimumLoadingTime = 2000; //ms
async function requestMinimumTimeoutWrapper( request, requestParameters={}, minimumTimeout = minimumLoadingTime, signal=null){
    try{
        const date = new Date()
        const startLoadingTime = date.getUTCMilliseconds()
        const res = await request(requestParameters, signal);
        const pendingTime = minimumTimeout - (date.getUTCMilliseconds() - startLoadingTime)
        if(pendingTime > 0 ) await new Promise((resolve) => { setTimeout(resolve, pendingTime)});
        return res;
    }catch(error){
        throw error
    }
}

export { publicThirdPartyRequest, publicRequest, createAuthRequest, createAuthRefreshRequest,
     requestMinimumTimeoutWrapper, setHistoryForInterceptors, setUpInterceptorsAPIResponseError,
     setClientNotificiationsForInterceptors, setForceUserToLoginForInterceptors }