import { useTheme } from '@material-ui/styles';
import { Typography, useMediaQuery } from '@material-ui/core'
import SocialCommentItem from '../SocialCommentItem/SocialCommentItem';
import './socialCommentSection.css'
import StyledTypography from '../StyledTypography/StyledTypography';

const SocialCommentSection = ({ title, wrapperStyle, socialCommentArray=[]}) => {
    const theme = useTheme();
    const matchMobile = useMediaQuery('(max-width: 680px)')
    const styles = {
        wrapper: wrapperStyle ? wrapperStyle : {
            backgroundColor: theme.palette.surface.light['1'],
        },
        title:{
            whiteSpace:'pre-line',
            fontWeight: 'bold'
        },
        recommendationAmount: {
            color: theme.palette.primary.main,
            fontSize: theme.typography[matchMobile ? 'h5' : 'h4'].fontSize,
            fontWeight: 'bold',
        },
        commentItem: {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[1],
        }
    }
    return (
        <div className='social-comment-section-wrapper' style={styles.wrapper}>
            <div className='social-comment-section-main-container'>
                {title &&
                    <StyledTypography variant={ matchMobile ? 'h6' : 'h5' } style={styles.title} className='social-comment-section-title'>
                        {title}
                    </StyledTypography>
                }
                <div className='social-comment-section-comments-container'>
                    {socialCommentArray.map(( comment, index ) => {
                        return(
                            <div key={index} className='social-comment-section-comment-item' style={styles.commentItem}>
                                <SocialCommentItem comment={comment} />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default SocialCommentSection