import { useTranslation } from 'react-i18next';
// MUI
import { useTheme } from '@material-ui/styles'
import { Divider, Grid, Typography } from '@material-ui/core'
// Components
import CheckoutPromoCode from '../CheckoutPromoCode/CheckoutPromoCode';
import CheckoutSalesTax from '../CheckoutSalesTax/CheckoutSalesTax';
// Helpers
import { fromCentTo2dec, priceToString } from '../../aux/purchaseHelpers';
import { Skeleton } from '@mui/material'
// Style
import './checkoutDetails.css'
import { isNotEmptyObject, textTransform } from '../../aux/aux';

const CheckoutDetails = ({ applyPromoCode, takeOutPromoCode, checkoutContent, appliedPromoCode, isCheckoutUpdating }) => {
    const theme = useTheme();
    const { t } = useTranslation('checkout');
    const styles = {
        middleAmountItem:{
            color: theme.palette.primary.light,
        }
    }
    return (
        <Grid container direction="column" className="checkout-details-main-container">
            {isNotEmptyObject(checkoutContent)?
                <>
                    <Grid item className="checkout-details-main-amount checkout-details-first-item">
                        <Grid container direction="row" justifyContent='space-between'>
                            <Grid item>
                                <Typography variant='body1'>
                                    {textTransform('title', t("subtotal"))}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body1'>
                                    {t('{{val, currency}}',{ val:fromCentTo2dec(checkoutContent.orderAmounts.finalAmountBeforeTaxesAndOrderDiscounts + checkoutContent.orderAmounts.totalProductPromoCodeAmountOff), currency: checkoutContent.orderAmounts.currencyId, trailingZeroDisplay:'stripIfInteger'})}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider className="checkout-details-divider" style={{marginTop: "5px" }} />
                    </Grid>
                    <Grid item className='checkout-details-middle-amount-item'>
                        <CheckoutPromoCode applyPromoCode={applyPromoCode} takeOutPromoCode={takeOutPromoCode} checkoutContent={checkoutContent} appliedPromoCode={appliedPromoCode} isCheckoutUpdating={isCheckoutUpdating} />
                    </Grid>
                    <Grid item className='checkout-details-middle-amount-item'>
                        <CheckoutSalesTax isLoading={isCheckoutUpdating} orderAmounts={checkoutContent.orderAmounts}/>
                    </Grid>
                    <Grid item className="checkout-details-main-amount checkout-details-last-item">
                        <Divider className="checkout-details-divider" style={{marginBottom: "5px" }}/>
                        <Grid container direction="row" justifyContent='space-between'>
                            <Grid item>
                                <Typography variant="body1">
                                    {textTransform('title', t("total"))}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1">
                                    {t('{{val, currency}}',{ val:fromCentTo2dec(checkoutContent.orderAmounts.finalAmount), currency: checkoutContent.orderAmounts.currencyId, trailingZeroDisplay:'stripIfInteger'})}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
                :
                <Grid item>
                    <Grid container justifyContent='space-between'>
                        <Typography variant='body1' style={{fontWeight:'bold'}}>
                            {textTransform('title', t("subtotal"))}
                        </Typography>
                        <Skeleton variant='text' width="10%"/>
                    </Grid>
                    <Divider className="checkout-details-divider" style={{marginTop: "5px" }} />
                    <Grid item style={{height:"100px"}}/>
                    <Divider className="checkout-details-divider" style={{marginBottom: "5px" }} />
                    <Grid container justifyContent='space-between'>
                        <Typography variant="body1" style={{fontWeight:'bold'}}>
                            {textTransform('title', t("total"))}
                        </Typography>
                        <Skeleton variant='text' width="10%"/>
                    </Grid>
                </Grid>
            }
        </Grid>
    )
}

export default CheckoutDetails