import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, Grid, hexToRgb, Typography, Button, useMediaQuery } from '@material-ui/core';

import TextEditor from '../TextEditor/TextEditor';
import PopUpModal from '../PopUpModal/PopUpModal';
import UserNoteFullscreen from '../UserNoteFullscreen/UserNoteFullscreen';
import ButtonLoading from '../ButtonLoading/ButtonLoading';

import { getReliableLocale } from '../../aux/sessionHelpers';
import { textTransform, rgbToRgbaOrUndefined } from '../../aux/aux';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { useTheme } from '@material-ui/styles';
import './userNote.css';

const UserNote = ({ note, readOnlyConfig, onUpdate, onRemove, maxNoteLength=1000, ...props }) => {
    const DEFAULT_POP_UP_WIDTH = 380
    const locale = getReliableLocale();
    const { t } = useTranslation('common');
    const theme = useTheme();
    const {
        errorMsg = 'error',
        errorTimeoutMs = 1000,
        errorStyle = {
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main,
        }
    } = props;
    const matchMobile = useMediaQuery('(max-width:680px)');
    const [isHover, setIsHover] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isExpandable, setIsExpandable] = useState(false);
    const [preventTruncation, setPreventTruncation] = useState(false);
    const [readOnly, setReadOnly] = useState(true);
    const [fullscreenMode, setFullscreenMode] = useState(false);
    const [exportableEditorState, setExportableEditorState] = useState(null);
    const textEditorRef = useRef(null);
    const userNoteWrapperRef = useRef(null);
    const styles = {
        userNoteWrapper: {
            backgroundColor: '#fff',
            border: 'solid 1px #f3f3f3',
            borderRadius: '5px'
        },
        userNoteDate: {
            color: '#888',
        },
        noteTypeChip: {
            color: theme.palette.primary.main, //'#C3E0FF',
            borderColor: theme.palette.primary.main, // '#C3E0FF',
            backgroundColor: rgbToRgbaOrUndefined(hexToRgb(theme.palette.primary.main), 0.1),
        },
        expandIcon: {
            transform: `rotate(${preventTruncation ? 180 : 0}deg)`
        },
        fullScreenWrapper: {
            ...(fullscreenMode || isHover ? { opacity: 1 } : {})
        },
        fullScreenIcon: {
            ...(fullscreenMode || isHover ? { cursor: 'pointer' } : {})
        },
        textAreaWrapperStyle: {
            ...( readOnly ? 
                {
                }
            :
                {
                    padding: '10px'
                }
            )
        }

    }

    const handleOnUserNoteClick = (e) => {
        const isDoubleClick = e.detail === 2;
        if(isDoubleClick){
            handleOnEditClick(e)
        }
    }

    const handleOnUpdateEditor = (exportableEditorState) => {
        setExportableEditorState(exportableEditorState);
    }
    const handleOnRemoveClick = (e) => {
        onRemove(note)
    }

    const handleOnEditClick = (e) => {
        setReadOnly( readOnly => !readOnly)
    }

    const handleOnExpandClick = (e) => {
        setPreventTruncation( preventTruncation => !preventTruncation );
    }

    const handleOnUpdateClick = async (e) => {
        e.preventDefault();
        setIsUpdating(true);
        const updatedValues = {
            plainText: exportableEditorState.plainText,
            jsonStyledText: JSON.stringify(exportableEditorState.rawBlocks),
        }
        const isUpdated = await onUpdate(note, updatedValues);
        setIsUpdating(false);
        if(isUpdated){
            handleOnEditClick();
        }else{
            setIsError(true);
        }
    };

    const handleOnCancelClick = (e) => {
        const event = new CustomEvent('resetTextEditor', { 
            detail:{
                rawJsonContentBlocks: note.jsonStyledText,
                textEditorId: note.id
            }
        });
        window.dispatchEvent(event);
        handleOnEditClick(e);
    }

    const handleOnFullscreenClick = (e) => {
        if(fullscreenMode){
            setIsHover(false);
        }
        setFullscreenMode(fullScreenMode => !fullScreenMode);
    }

    useEffect(() => {
        if(isError){
            const timeoutId = setTimeout(() => setIsError(false), errorTimeoutMs);
            return(() => { clearTimeout(timeoutId) })
        }
    },[isError])

    useEffect(() => {
        if(exportableEditorState && readOnly){
            const truncateLength = readOnlyConfig.truncateLength;
            let expandable;
            if(truncateLength){
                const editorLength = exportableEditorState.plainText.length;
                expandable = editorLength > truncateLength;
            }
            setIsExpandable(expandable);
        }
    }, [exportableEditorState, readOnly, readOnlyConfig, preventTruncation])

    return (
        <div ref={userNoteWrapperRef} className='user-note-wrapper' style={ styles.userNoteWrapper }>
            {fullscreenMode &&
                <PopUpModal 
                    showModal={fullscreenMode} 
                    onClickClose={handleOnFullscreenClick} 
                    onOutsideClick={handleOnFullscreenClick}
                    isDialog={true} 
                    width={matchMobile ? `${DEFAULT_POP_UP_WIDTH}px`:'50vw'}  
                    style={{
                        overflowY: 'hidden'
                    }} 
                >
                    <UserNoteFullscreen 
                        note={note} 
                        onLeave={handleOnFullscreenClick} 
                        onRemove={onRemove}
                        onUpdate={onUpdate}
                        maxNoteLength={maxNoteLength}
                        {...props}
                    />
                </PopUpModal>
            }
            <Grid container direction='column' className='user-note-container' onClick={handleOnUserNoteClick} onMouseEnter={()=>{setIsHover(true)}} onMouseLeave={()=>{setIsHover(false)}}>
                { readOnly &&
                    <Grid item>
                        <Grid container className='user-note-read-upper-info-container'>
                            <Grid item>
                                <Typography variant='body2' style={ styles.userNoteDate }>
                                    {new Date(note.createdAt).toLocaleDateString(locale.localeCode)}    
                                </Typography>
                            </Grid>
                            <Grid item className='user-note-button-wrapper user-note-full-screen-button-wrapper' style={ styles.fullScreenWrapper }>
                                <OpenInFullIcon className='user-note-full-screen-button-icon' style={ styles.fullScreenIcon } onClick={handleOnFullscreenClick} />
                            </Grid>
                        </Grid>
                    </Grid>
                }
                <Grid item className='user-note-editor'>
                    <TextEditor
                        textEditorId={note.id}
                        ref={textEditorRef} 
                        readOnly={readOnly} 
                        readOnlyConfig={{ ...readOnlyConfig, preventTruncation }} 
                        rawJsonContentBlocks={note.jsonStyledText} 
                        onUpdateEditor={handleOnUpdateEditor}
                        onShiftAndEnter={handleOnUpdateClick}
                        maxTextLength={maxNoteLength}
                        textAreaWrapperStyle={ styles.textAreaWrapperStyle }
                    />
                </Grid>
                { readOnly ?
                    <Grid item className={`user-note-read-bottom-info-wrapper ${isHover ? 'show' : ''}`}>
                        <Grid container className='user-note-read-bottom-info-container'>
                            <Grid item>
                                <Chip 
                                    variant='outlined' 
                                    label={textTransform('title', note.noteTypeName)} 
                                    size="small" 
                                    style={ styles.noteTypeChip }    
                                />
                            </Grid>
                            <Grid item>
                                {isExpandable &&
                                    <Grid item className='user-note-button-wrapper'>
                                        <div className='user-note-expand-button-wrapper' style={ styles.expandIcon }>
                                            <ExpandMoreIcon 
                                                className='user-note-button-icon' 
                                                onClick={ handleOnExpandClick } />
                                    </div>
                                    </Grid>
                                }
                            </Grid>
                            <Grid item>
                                <Grid container >
                                    <Grid item className='user-note-button-wrapper'>
                                        <EditIcon className='user-note-button-icon user-note-button-icon' onClick={ handleOnEditClick } />
                                    </Grid>
                                    <Grid item className='user-note-button-wrapper'>
                                        <DeleteIcon className='user-note-button-icon' onClick={ handleOnRemoveClick } />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    <Grid container direction='column'>
                        <Grid item container className='user-note-edit-bottom-info-container'>
                            <Grid item xs>
                                <Button 
                                    onClick={handleOnCancelClick} 
                                    color='primary'
                                    size='small'
                                    style={{height:'100%'}}
                                    fullWidth
                                >
                                    { t('cancel') }
                                </Button>
                            </Grid>
                            <Grid item xs>
                                <ButtonLoading
                                    isLoading={isUpdating}
                                    disabled={isUpdating}
                                    onClick={handleOnUpdateClick} 
                                    variant='contained' 
                                    color='primary' 
                                    size='small'
                                    fullWidth 
                                    label={ t('update') }
                                />
                            </Grid>
                        </Grid>
                        <Grid item className={`user-note-edit-error-toogle ${isError ? 'show' : ''}`} style={ errorStyle }>
                            <Typography variant='body2'>
                                { errorMsg }
                            </Typography>
                        </Grid>
                    </Grid>
                }
            </Grid> 
        </div>
  )
}

export default UserNote