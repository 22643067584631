/*
    Checkout simple feedback shows the given payment intent status without any product view
*/
import { useEffect, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography, Divider, Button } from '@material-ui/core';
// API Queries
import { checkPaymentIntentAuthVerificationFn, cancelPaymentIntentFn } from '../../aux/purchaseQueries';
import { requestMinimumTimeoutWrapper } from '../../aux/requestMethods';
// Session Helpers
import { useLocaleCtx } from '../../customHooks/useLocaleCtx';
// Local storage
import { sessionVariables } from '../../aux/sessionHelpers'
// My components
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import Emoji from '../Emoji/Emoji';
// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import successAnimation from '../../lotties/success.json';
import warningAnimation from '../../lotties/warning.json';
import errorAnimation from '../../lotties/error.json';
// Images
import CircleIcon from "../../img/circle.svg";
// Styles
import { useTheme } from '@material-ui/styles';
import './checkoutPaymentIntentFeedback.css'
import { getCheckoutContent, getProcessOrderFromCheckoutStatusEventSource } from '../../aux/purchaseHelpers';
import { getUserSubscription } from '../../services/userServices';
import { justSetUser, refreshUser } from '../../services/sessionServices';
import { useRedirect } from '../../customHooks/useRedirect';
import { isNotEmptyObject } from '../../aux/aux';


const CheckoutPaymentIntentFeedback = ({ user, setUser, checkoutContent, handlePaymentIntentId, checkoutSyncAfterPIFailureCallback, callbackToPurchase, onSuccessfullCheckoutProcess=()=>{} }) => {
    const INTENT_DESIRED_STATUS = 'succeeded';
    const theme = useTheme();
    const { t } = useTranslation('checkout', { keyPrefix: 'checkoutSimpleFeedback'});
    const redirectApi = useRedirect();
    const localeCtx = useLocaleCtx();
    const routerLinks = ['/app/my-content', '/app/my-content', '/app/my-content']; //[afterSuccessfulSubscriptionPurchase, afterSuccessfulContentPurchase, afterWarning]
    const checkoutFeedbackStateTypes = ['succeeded', 'warning', 'error'];
    const [paymentIntentId, resetPaymentIntent ] = handlePaymentIntentId;
    const [checkoutFeedbackState, setCheckoutFeedbackState] = useState('');
    const [includesContentOrSpecializationProduct, setIncludesContentOrSpecializationProduct] = useState(false);
    const [orderNum, setOrderNum] = useState('')
    const [purchaseTrakingToken, setPurchaseTrakingToken] = useState(null);
    const [checkoutWasProcessed, setCheckoutWasProcessed] = useState(false);
    const forceToRefresh = useRef(false);
    const animationContainer = useRef(null);
    const animation = useRef(null);
    const styles = {
        highlightText:{
            color:theme.palette.primary.main,
            display:'inline'
        }
    }
    const checkoutFeedbackTxt = {
        state:{
            succeeded:{
                title: t('thanks'),
                symbol: t('common:symbolHearth'),
                subtitle: t('purchaseWasSuccessful'),
                productIncluded: t('productIncluded'),
                message: t('subscriptionIsAutomaticallyRenewed'),
                subscriptionDontWorry: t('subscriptionDontWorry'),
                subscriptionConfiguration: t('subscriptionConfiguration'),
                orderNum: t('orderNum'),
                buttonLabel: [t('goToMyContent'), t('learnSomethingNew')]
            },
            warning:{
                title: t('uups'),
                symbol: t('common:symbolUFO'),
                subtitle: t('connectionIssue'),
                messageTitle: t('weAreProcessingYourPurchase'),
                message: t('sendYouAnEmailWhenConfirmed'),
                messageEnd: t('thanks'),
                buttonLabel: t('goToMyContent'),
            },
            error:{
                title: t('uups'),
                symbol: t('common:symbolNoSeeMonkey'),
                subtitle: t('purchaseWasNotSuccessful'),
                messageTitle: t('noChargeWasApplied'),
                message: t('repeatThePurchaseWithAnotherPaymentMethod'),
                buttonLabel: t('tryThePurchaseAgain')

            }
        }
    };

    const getPaymentIntentStatus = useCallback( async ( userId, checkoutId, paymentIntentId, signal=null ) => {
        try{
            const params = {
                userId,
                checkoutId,
                paymentIntentId,
                requestParam: 'metadata',
            };
            const res = await requestMinimumTimeoutWrapper(checkPaymentIntentAuthVerificationFn, params, 0, signal);
            const {
                paymentIntentStatus,
                purchaseTrakingToken,
                requestParam
            } = res.data
            if(paymentIntentStatus !== INTENT_DESIRED_STATUS){
                try{
                    // Cancel the intent is optional as long as a new intent was set
                    await requestMinimumTimeoutWrapper(cancelPaymentIntentFn, params, 0);
                }catch(error){
                    // If the intent cannot be canceled nothing is needed to de done
                }finally{
                    if(!signal || !signal.aborted){
                        setCheckoutFeedbackState(checkoutFeedbackStateTypes[2]);
                    }
                }
            }else{
                if(!signal || !signal.aborted){
                    const pyOrderNum = requestParam?.orderNum;
                    if(pyOrderNum){
                        setOrderNum(pyOrderNum);
                        setPurchaseTrakingToken(purchaseTrakingToken);
                    } else{
                        setCheckoutFeedbackState(checkoutFeedbackStateTypes[1]);
                    }
                }
            }
        }catch(error){
            if(!signal || !signal.aborted){
                setCheckoutFeedbackState(checkoutFeedbackStateTypes[1]);
            }
        }
    });

    const getReadyAndShowSuccessfullState = async(signal=null) => {
        const setRefreshedUser = async (setUser) => {
            const user = await refreshUser();
            if(!signal || !signal.aborted){
                justSetUser(user, setUser);
            }
        }   
        try{
            if(typeof setUser === 'function'){
                await setRefreshedUser(setUser)
            }else{
                throw new Error();
            }
        }catch(error){
            // request needed to show a successful processed checkout fail
            // but checkout is successfull processed
            forceToRefresh.current = true;
        }finally{
            if(!signal || !signal.aborted){
                setCheckoutFeedbackState(checkoutFeedbackStateTypes[0])
            }
        }
    }
    
    /**
     * Process product and subscribed product to find and set required user feedback
     * Notes: Assumes only one subscription product type in each checkout 
     * @param {*} productArray 
     * @param {*} subscribedProductArray 
     */
    const processProductFeedback = (productArray, subscribedProductArray ) => {
        productArray.forEach( product => {
            // loop through all products included in checkout
            switch(product.productTypeName){
                case "content":
                case "specialization":
                    if(!includesContentOrSpecializationProduct){
                        setIncludesContentOrSpecializationProduct( true );
                    }
                    break;
                default:
                    break;
            }
        })
        if(!includesContentOrSpecializationProduct){
            for(let i = 0; i < subscribedProductArray.length; i++){
                // loop through all subscribed products included in checkout
                let subscribedProduct = subscribedProductArray[i];
                switch(subscribedProduct.productTypeName){
                    case "content":
                    case "specialization":
                        if(!includesContentOrSpecializationProduct){
                            setIncludesContentOrSpecializationProduct( true );
                        }
                        break;
                    default:
                        break;
                }
                if(includesContentOrSpecializationProduct){
                    // Stop loop after finding one content/specialization product
                    break;
                }
            }
        }
    }


    useEffect(() => {
        if(paymentIntentId && isNotEmptyObject(checkoutContent)){
            const abortController = new AbortController();
            getPaymentIntentStatus(checkoutContent.userId, checkoutContent.id, paymentIntentId, abortController.signal);
            return(() => {
                abortController.abort();
            })
        }
    },[paymentIntentId, checkoutContent])

    useEffect(() => {
        if(purchaseTrakingToken){
            function onmessage(event){
                const {
                    checkoutStatus,
                    status
                } = JSON.parse(event.data)
                let closeConnection = false;
                let checkoutWasProcessed;
                let checkoutFeedbackState;
                if(status === 'expiredToken'){
                    closeConnection = true;
                    checkoutFeedbackState = checkoutFeedbackStateTypes[1];
                }else if(status === 'maxAttempt'){
                    closeConnection = true;
                    checkoutFeedbackState = checkoutFeedbackStateTypes[1];
                }else{
                    if(checkoutStatus === 'succeeded'){
                        closeConnection = true;
                        checkoutWasProcessed = true;
                    }
                }
                if(closeConnection) this.close();
                if(checkoutFeedbackState) setCheckoutFeedbackState(checkoutFeedbackState);
                if(checkoutWasProcessed) setCheckoutWasProcessed(true);
            }
            function onerror(event){
                setCheckoutFeedbackState(checkoutFeedbackStateTypes[1])
                this.close();
            }
            const eventSource = getProcessOrderFromCheckoutStatusEventSource(user.basicData.id, purchaseTrakingToken, onmessage, onerror);
            return(() => {
                eventSource.close();
            })
        }
    }, [purchaseTrakingToken])

    useEffect(() => {
        if(checkoutContent){
            processProductFeedback(Object.values(checkoutContent.productsAndCouponsMap), Object.values(checkoutContent.subscribedProductsAndCouponsMap));
        }
    }, [checkoutContent])

    useEffect(() => {
        let animation;
        switch(checkoutFeedbackState){
            case(checkoutFeedbackStateTypes[0]):
                animation= successAnimation;
                break;
            case(checkoutFeedbackStateTypes[1]):
                animation= warningAnimation;
                break;
            case(checkoutFeedbackStateTypes[2]):
                animation= errorAnimation;
                break;
        }

        if(animationContainer.current){
            animation.current = lottie.loadAnimation({
                container: animationContainer.current,
                renderer: 'svg',
                loop: false,
                autoplay: true,
                animationData: animation
            });
            return(() => {animation.current.stop(); animation.current?.destroy()})
        }

    },[checkoutFeedbackState])

    useEffect(() => {
        if(checkoutWasProcessed){
            // send event to Update User
            // request new Checkout content if you will
            const abortController = new AbortController();
            getReadyAndShowSuccessfullState(abortController.signal);
            return(() => {
                abortController.abort();
            })
        }
    },[checkoutWasProcessed])

    const handleOnClick = async ( e ) => {
        if(checkoutFeedbackState === checkoutFeedbackStateTypes[0]){
            let path;
            if(includesContentOrSpecializationProduct){
                path = routerLinks[1]
            } else {
                path = routerLinks[0]
            }
            const redirectFn = forceToRefresh.current ? redirectApi.redirectForcingRefresh : redirectApi.redirectToRoute
            if(forceToRefresh.current){
                const host = window.location.origin
                path = `${host}${path}`
            }
            redirectFn(path);
        }else if(checkoutFeedbackState === checkoutFeedbackStateTypes[1]){
            const path = routerLinks[2];
            redirectApi.redirectToRoute(path);
        } else if(checkoutFeedbackState === checkoutFeedbackStateTypes[2]){
            // Get ready for a new payment intent and clear query
            resetPaymentIntent();
            await checkoutSyncAfterPIFailureCallback();
            callbackToPurchase();
        }
    }

    const getLogic = ( state, stateTypes ) => {
        const text = checkoutFeedbackTxt.state[state]
        let Component;
        switch(state){
            case(stateTypes[0]):
                // Succeeded
                Component = 
                <div className='checkout-payment-intent-feedback-main-container'>
                    <div className='checkout-payment-intent-feedback-title-container'>
                        <Typography variant="h5">
                            <Box fontWeight="bold" style={{ display: 'inline'}}>
                                {`${text.title} `}
                            </Box>
                            <Emoji symbol={text.symbol}/>
                        </Typography>
                        <Typography variant='body2'>
                            {text.subtitle}
                        </Typography>
                        <Divider style={{marginTop:"10px"}} />
                    </div>
                    <div className='checkout-payment-intent-feedback-animation-main-container'>
                        <div className='checkout-payment-intent-feedback-animation-container' ref={animationContainer}>
                            { animation.current?.play() }
                        </div>
                        <div>
                            <Typography variant='body1' display='inline'>
                                { `${text.orderNum}: ` } 
                            </Typography>
                            <Typography variant='body1' display='inline' style={{color:theme.palette.primary.main, fontWeight:'bold'}}>
                                { orderNum }
                            </Typography>
                        </div>
                    </div>
                    {Object.values(checkoutContent.subscriptionProductMap).length > 0 &&
                        <div className='checkout-payment-intent-feedback-subscription-items-container'>
                            <Typography variant='body2'>
                                {`${text.message} `}
                            </Typography>
                            {
                                Object.entries(checkoutContent.subscriptionProductMap).map(([id,subscriptionProduct], index) => {
                                    return(
                                        <div className='checkout-payment-intent-feedback-subscription-item-container' key={id}>
                                            <div>
                                                <CircleIcon fill={theme.palette.primary.main} height={10} alt="circleIcon" /> {/* fill to change the color */}
                                            </div>
                                            <Typography variant='body2'>
                                                {`${subscriptionProduct.description.nameTranslated}: `}
                                            </Typography>
                                            <Typography variant='body2' style={{color:theme.palette.primary.main}}>
                                                {new Date(subscriptionProduct.term.dates.endsDate).toLocaleDateString(localeCtx.localeCode)}
                                            </Typography>
                                        </div>
                                    )
                                })
                            }
                            <Typography variant='body2'>
                                {`${text.subscriptionConfiguration}`}
                            </Typography>
                        </div>
                    }               
                    <div className='checkout-payment-intent-feedback-button'>
                        <Button variant='outlined' color='primary' onClick={ handleOnClick }>
                            {includesContentOrSpecializationProduct ?
                                text.buttonLabel[0]
                                :
                                text.buttonLabel[1]
                            }
                        </Button>
                    </div>
                </div>
                break;

            case(stateTypes[1]):
                // Warning
                Component = 
                    <div className='checkout-payment-intent-feedback-main-container'>
                        <div className='checkout-payment-intent-feedback-title-container'>
                            <Typography variant="h5">
                                <Box fontWeight="bold" style={{ display: 'inline'}}>
                                    {`${text.title} `}
                                </Box>
                                <Emoji symbol={text.symbol}/>
                            </Typography>
                            <Typography variant='body2'>
                                {text.subtitle}
                            </Typography>
                            <Divider style={{marginTop:"10px"}} />
                        </div>
                        <div className='checkout-payment-intent-feedback-animation-main-container'>
                            <div className='checkout-payment-intent-feedback-animation-container' ref={animationContainer}>
                                { animation.current?.play() }
                            </div>
                            <Typography variant='body1'>
                                { text.messageTitle } 
                            </Typography>
                        </div>
                        <div className='checkout-payment-intent-feedback-message'>
                            <Typography variant='body2'>
                                { text.message } 
                            </Typography>
                            <Typography variant='body2'>
                                { text.messageEnd } 
                            </Typography>
                        </div>
                        <div className='checkout-payment-intent-feedback-button'>
                            <Button variant='outlined' color='primary' onClick={ handleOnClick }>
                                {text.buttonLabel}
                            </Button>
                        </div>
                    </div>
                break;
            case(stateTypes[2]):
                // Error
                Component = 
                    <div className='checkout-payment-intent-feedback-main-container'>
                        <div className='checkout-payment-intent-feedback-title-container'>
                            <Typography variant="h5">
                                <Box fontWeight="bold" style={{ display: 'inline'}}>
                                    {`${text.title} `}
                                </Box>
                                <Emoji symbol={text.symbol}/>
                            </Typography>
                            <Typography variant='body2'>
                                {text.subtitle}
                            </Typography>
                            <Divider style={{marginTop:"10px"}} />
                        </div>
                        <div className='checkout-payment-intent-feedback-animation-main-container'>
                            <div className='checkout-payment-intent-feedback-animation-container' ref={animationContainer}>
                                { animation.current?.play() }
                            </div>
                            <Typography variant='body1'>
                                { text.messageTitle } 
                            </Typography>
                        </div>
                        <div className='checkout-payment-intent-feedback-message'>
                            <Typography variant='body2'>
                                { text.message } 
                            </Typography>
                            <Typography variant='body2'>
                                { text.messageEnd } 
                            </Typography>
                        </div>
                        <div className='checkout-payment-intent-feedback-button'>
                            <Button variant='outlined' color='primary' onClick={ handleOnClick }>
                                {text.buttonLabel}
                            </Button>
                        </div>
                    </div>
                break;
        }
        return Component
    }   

    return (
        <div className='checkout-payment-intent-feedback-wrapper'>
            {checkoutFeedbackState ?
                    getLogic(checkoutFeedbackState, checkoutFeedbackStateTypes)
                :
                    <div className='checkout-payment-intent-feedback-loading-container'>
                        <LoadingComponent visibleElements='circle' />
                    </div>
            }
        </div>
    )
}

export default CheckoutPaymentIntentFeedback
