/*
    This module encompasses services available to all visitors
    No auth check is required
*/

import { requestMinimumTimeoutWrapper } from "../aux/requestMethods";
import { getVimeoThumbnailFn, getYoutubeThumbnailFn } from "../aux/videoContentProviderQueries";
import { 
    getProductSummaryFn, getContentProductInfoFn, getProductPageFn, getContentProductStructurePreviewFn, 
    getContentProductOpenLessonFn, getProductConsumptionOptionMapFn, getProductConsumptionDetailsCollectionFn,
    getContentProductTrailerFn
} from "../aux/productQueries";
import { arrayToQueryParam } from "../aux/aux";
import { sendSupportRequestFn } from "../aux/supportQueries";
import { createUpdateMktProfileFn, registerEventInMktProfileFn, subscriptionListSubscriptionFn } from "../aux/emailListQueries";
import { ipSessionVariables } from "../aux/sessionHelpers";
import i18n from '../../i18n';
import { checkAPIHealthFn } from "../aux/apiHealthQueries";
// const { getProductSummaryFn } = require("../aux/productQueries");

async function getProductSummary(productId, localeId, minimumTimeOut=0, signal=null){
    const params = {
        productId,
        localeId
    };
    return requestMinimumTimeoutWrapper(getProductSummaryFn, params, minimumTimeOut, signal);
}

async function getProductConsumptionDetailsCollection(productDetailsArray, {countryCode, stateCode, currencyId}, {asMap=undefined, effectiveAt=undefined}, minimumTimeOut=0, signal=null){
    const params = {
        productDetailsArray,
        currencyId,
        countryCode,
        stateCode,
        effectiveAt,
        asMap
    };
    return requestMinimumTimeoutWrapper(getProductConsumptionDetailsCollectionFn, params, minimumTimeOut, signal);
}

async function getProductConsumptionOptionMap(productName, {countryCode, stateCode, currencyId}, localeId, minimumTimeOut=0, signal=null){
    const params = {
        productName,
        currencyId,
        countryCode,
        stateCode,
        localeId,
    };
    return requestMinimumTimeoutWrapper(getProductConsumptionOptionMapFn, params, minimumTimeOut, signal);
}

async function getProductPage(productName, localeId, minimumTimeOut=0, signal=null){
    const params = {
        productName,
        localeId
    };
    return requestMinimumTimeoutWrapper(getProductPageFn, params, minimumTimeOut, signal);
}

async function getContentProductStructurePreview(productIdOrName, localeId, system='', query={}, minimumTimeOut=0, signal=null){
    const requestParameters = {
        productIdOrName,
        localeId,
        system,
        query
    };
    return requestMinimumTimeoutWrapper( getContentProductStructurePreviewFn, requestParameters, minimumTimeOut, signal);
}

async function getContentProductOpenLesson(productIdOrName, lessonId, localeId, lessonVersionDecisor, minimumTimeOut=1000, signal=null){
    const requestParameters = {
        productIdOrName,
        lessonId,
        localeId,
        lessonVersionDecisor
    };
    return requestMinimumTimeoutWrapper( getContentProductOpenLessonFn, requestParameters, minimumTimeOut, signal);
}

async function getContentProductInfo(contentProductIdArray, opt, minimumTimeOut=0, signal=null){
    const params = {
        productIdArray: arrayToQueryParam(contentProductIdArray),
        ...opt,
        ...(opt.hasOwnProperty('exp') ? { exp: arrayToQueryParam(opt.exp) } : {}),
        ...(opt.hasOwnProperty('imageFilter') ? { imageFilter: arrayToQueryParam(opt.imageFilter) } : {})
    };
    return requestMinimumTimeoutWrapper(getContentProductInfoFn, params, minimumTimeOut, signal);
}

async function getContentProductTrailer(productIdOrName, localeId, minimumTimeOut=0, signal=null){
    const params = {
        productIdOrName,
        localeId
    };
    return requestMinimumTimeoutWrapper( getContentProductTrailerFn, params, minimumTimeOut, signal);
}

async function getVideoThumbnail(videoSourceName, videoSourceId, opt={}, minimumTimeOut=0, signal=null){
    const params = {
        videoSourceId,
        opt
    }
    let requestFn;
    switch(videoSourceName){
        case 'vimeo':
            requestFn = getVimeoThumbnailFn;
            break;
        case 'youtube':
            requestFn = getYoutubeThumbnailFn;
    }
    return requestMinimumTimeoutWrapper(requestFn, params, minimumTimeOut, signal);
}

async function sendSupportRequest(type, request, minimumTimeOut=0, signal=null){
    const params = {
        type,
        ...request
    };
    return requestMinimumTimeoutWrapper(sendSupportRequestFn, params, minimumTimeOut, signal);
}

async function subscriptionListSubscription(op, listArray, subscriptionArray, minimumTimeOut, signal=null){
    const params = {
        op,
        listArray,
        subscriptionArray
    };
    return requestMinimumTimeoutWrapper(subscriptionListSubscriptionFn, params, minimumTimeOut, signal);
}

async function getSubscriptionList(provider, subscriptionArray, minimumTimeOut, signal=null){
    const params = {
        op:'get',
        provider,
        subscriptionArray
    };
    return requestMinimumTimeoutWrapper(subscriptionListSubscriptionFn, params, minimumTimeOut, signal);
}

async function createUpdateMktProfile(provider, profileData, customProperties, minimumTimeOut, signal=null){
    const params = {
        provider,
        profileData,
        customProperties
    };
    return requestMinimumTimeoutWrapper(createUpdateMktProfileFn, params, minimumTimeOut, signal);
}

async function registerEventInMktProfile(provider, profileData, event, minimumTimeOut, signal=null){
    const params = {
        provider,
        profileData,
        event
    };
    return requestMinimumTimeoutWrapper(registerEventInMktProfileFn, params, minimumTimeOut, signal);
}

async function updateSessionLocale({localeId, localeCode}){
    await i18n.changeLanguage(localeCode);
    ipSessionVariables.set('localeId', localeId);
    ipSessionVariables.set('localeCode', localeCode);
}

function updateSessionCountryAndCurrency({countryCode, stateCode, currencyId}){
    ipSessionVariables.set('countryCode', countryCode);
    ipSessionVariables.set('stateCode', stateCode);
    ipSessionVariables.set('currencyId', currencyId);
}

async function checkAPIHealth(signal){
    return requestMinimumTimeoutWrapper(checkAPIHealthFn, {}, 0, signal);
}

export{
    getProductSummary,
    getVideoThumbnail,
    getContentProductInfo,
    getContentProductTrailer,
    getProductPage,
    getProductConsumptionOptionMap,
    getProductConsumptionDetailsCollection,
    getContentProductStructurePreview,
    getContentProductOpenLesson,
    sendSupportRequest,
    subscriptionListSubscription,
    getSubscriptionList,
    createUpdateMktProfile,
    registerEventInMktProfile,
    updateSessionLocale,
    updateSessionCountryAndCurrency,
    checkAPIHealth
}