import FeaturedContent from '../FeaturedContent/FeaturedContent'
import './contentPreview.css'

const ContentPreview = ({contentPage, wrapperStyle, noVerticalPadding=false, imgLazyLoadThreshold='600px' }) => {
    const styles = {
        wrapper: wrapperStyle ? 
            wrapperStyle
            :
            {
                backgroundImage: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0,0,0,0.2))',
                backgroundColor: contentPage.palette.primary.main
            }
    }
    return (
        <div className='content-preview-wrapper' style={styles.wrapper}>
            <div className={`content-preview-main-container${noVerticalPadding ? ' no-vertical-padding' : ''}`}>
                <FeaturedContent featuredContentTrailer={contentPage.contentTrailer} imgLazyLoadThreshold={imgLazyLoadThreshold} />
            </div>
        </div>
    )
}

export default ContentPreview