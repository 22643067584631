import { createContext, useState } from "react";
import { getReliableCountryCode, getReliableCurrencyId, getReliableStateCode, ipSessionVariables } from "../aux/sessionHelpers";
import { someEmptyPropertyObject } from "../aux/aux";
import { updateSessionCountryAndCurrency } from "../services/visitorServices";

const CountryAndCurrencyContext = createContext(null);
const CountryAndCurrencyUpdateContext = createContext(null);

const CountryAndCurrencyProvider = ({ children }) => {
    const initialCountryAndCurrency = {
        countryCode: getReliableCountryCode(),
        currencyId: getReliableCurrencyId(),
    }
    if(someEmptyPropertyObject(initialCountryAndCurrency)){
        throw new Error('CountryAndCurrencyProvider needs countryCode and currencyId to be set in localeStorage');
    }
    initialCountryAndCurrency['stateCode'] = getReliableStateCode();
    
    const [countryAndCurrency, _setCountryAndCurrency] = useState(initialCountryAndCurrency);
    
    const resetCountryAndCurrencyToSessionValues = () => {
        const countryCode = ipSessionVariables.get('countryCode');
        const stateCode = ipSessionVariables.get('stateCode');
        const currencyId = ipSessionVariables.get('currencyId');
        _setCountryAndCurrency({ countryCode, stateCode, currencyId })
    }

    const setCountryAndCurrency = ({countryCode, stateCode, currencyId}, updateSessionVariables=false) => {
        _setCountryAndCurrency({countryCode, stateCode, currencyId});
        if(updateSessionVariables){
            updateSessionCountryAndCurrency({countryCode, stateCode, currencyId});
        }
    }
    return(
        <CountryAndCurrencyContext.Provider value={countryAndCurrency}>
            <CountryAndCurrencyUpdateContext.Provider value={{setCountryAndCurrency, resetCountryAndCurrencyToSessionValues}}>
                { children}
            </CountryAndCurrencyUpdateContext.Provider>
        </CountryAndCurrencyContext.Provider>
    )
}

export { CountryAndCurrencyProvider, CountryAndCurrencyContext, CountryAndCurrencyUpdateContext }