import React from 'react';
import ReactDOM from 'react-dom';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import LoadingComponent from "./App/components/LoadingComponent/LoadingComponent";
import ResourcePageError from './App/components/PageError/ResourcePageError';
import ErrorBoundary from "./App/components/ErrorBoundary/ErrorBoundary";
import './i18n'
import './index.css';
import RefreshBrowserError from './App/pages/refreshBrowserError/RefreshBrowserError';

const handleOnError = (error,errorInfo) => {
	if(process.env.REACT_APP_TARGET_ENV !== 'production'){
		console.log(error, errorInfo);
	}
}


ReactDOM.render(
  	<React.StrictMode>
		<React.Suspense fallback={<div className="index-loading-container"><LoadingComponent /></div>}>
			<ErrorBoundary onError={handleOnError} Fallback={<RefreshBrowserError />}>
					<App />
			</ErrorBoundary>
		</React.Suspense>
  	</React.StrictMode>,
  	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
