import { arrayToQueryParam } from "./aux";
import { createAuthRequest, publicRequest } from "./requestMethods"

/**
 * This module contains meeting-related queries
 */
const getMeetingSignatureFn = async ( params, signal ) => {
    const {
        userId,
        meetingId
    } = params
    const userRequest = createAuthRequest();
    return userRequest.get(`/meeting/signature/${userId}/${meetingId}`, {signal: signal})
}

const getMeetingFn = async (params, signal) => {
    const {
        meetingId,
        query
    } = params
    return publicRequest.get(`/meeting/${meetingId}`, { params:query, signal: signal});    
}

const getMeetingOcurrenciesFn = async (params, signal) => {
    const {
        meetingId,
        query
    } = params
    if(query.hasOwnProperty('filterConditionArray')){
        query.filterConditionArray = arrayToQueryParam(query.filterConditionArray);
    }
    return publicRequest.get(`/meeting/occurrencies/${meetingId}`, { params:query, signal: signal});    
}

export{
    getMeetingSignatureFn,
    getMeetingFn,
    getMeetingOcurrenciesFn
}