import { useEffect, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/styles';
import { Divider, Grid, Typography, TextField, MenuItem } from '@material-ui/core';
import { requestMinimumTimeoutWrapper } from '../../aux/requestMethods';
import { getCountriesFn, getStatesFn } from '../../aux/staticContentQueries';
import PopUpModal from '../PopUpModal/PopUpModal'
import BillingAddressEditForm from '../BillingAddressEditForm/BillingAddressEditForm';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import ButtonLoading from '../ButtonLoading/ButtonLoading';
import { isEmptyObject, emptyPropertiesObject, textTransform } from '../../aux/aux';
import './checkoutBillingAddressEdit.css';
import { getReliableLocale } from '../../aux/sessionHelpers';
import BackButton from '../BackButton/BackButton';

const CheckoutBillingAddressEdit = ({ updateBillingAddress, callbackOnUpdate=undefined }) => {
    const theme = useTheme();
    const { t } = useTranslation('common', { keyPrefix: "checkoutBillingAddressEditPopUp" });
    const [errorUpdating, setErrorUpdating] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [countries, setCountries] = useState({});
    const [states, setStates] = useState(undefined);
    const [selectedCountryCode, setSelectedCountryCode] = useState('');
    const [formValues, setFormValues] = useState({});
    const style = {
        error:{
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main,
        }
    }
    const onUpdate = async (e) => {
        try{
            e.preventDefault();
            setIsLoading(true)
            if(!isEmptyObject(formValues)){
                const billingAddress = {
                    ...formValues,
                    countryCode:selectedCountryCode
                };
                // stateCode must be null or an alpha-2 code
                billingAddress.stateCode = billingAddress.stateCode === '' ? null : billingAddress.stateCode;
                const data = await updateBillingAddress(billingAddress)
                if(isEmptyObject(data)){
                    // TODO: implement
                    throw new Error();
                }else{
                    if(callbackOnUpdate) callbackOnUpdate();
                }
            }
        }catch(error){
            // TODO: implement
            setErrorUpdating(true);
        }finally{
            setIsLoading(false)
        }
    }

    const getCountries = useCallback(async () => {
        try{
            setIsLoading(true);
            const requestParam = {
                localeId: getReliableLocale().localeId,
            }
            const res = await requestMinimumTimeoutWrapper(getCountriesFn, requestParam, 0);
            setCountries(res.data);
        }catch(error){

        }finally{
            setIsLoading(false);
        }
    });

    const getStates = useCallback(async (selectedCountryCode) => {
        try{
            setIsLoading(true);
            const requestParam = {
                countryCode: selectedCountryCode
            };
            const res = await requestMinimumTimeoutWrapper(getStatesFn, requestParam, 0);
            setStates(res.data);
        }catch(error){

        }finally{
            setIsLoading(false);
        }
    },[selectedCountryCode])

    const resetCountryAndStates = () => {
        setSelectedCountryCode('')
        setStates(undefined)
    }

    useEffect(()=>{
        if(isEmptyObject(countries)){
            getCountries();
        }
    },[])

    useEffect(() => {
        if(selectedCountryCode){
            getStates(selectedCountryCode);
        }
    },[selectedCountryCode])

    useEffect(() => {
        if(errorUpdating){
            setTimeout(() => {setErrorUpdating(false)}, 3000);
            return (() => {setErrorUpdating(false)});
        }
    },[errorUpdating])
    return (
        <Grid container direction="column" className='checkout-billing-address-edit-main-container'>
            <Grid item className='checkout-billing-address-edit-form-container'>
                <Grid container direction="column" style={{minHeight:"inherit"}} justifyContent='center'>
                    <Grid item className={`checkout-billing-address-edit-form-country ${selectedCountryCode && states ? 'hidden' : ''}`}>
                        {isLoading ?
                                <LoadingComponent visibleElements='circle' />
                            :
                                <TextField label={t("selectBillingCountry")} name="countryCode" value={selectedCountryCode} 
                                onChange={(e) => {setSelectedCountryCode(e.target.value)}} select fullWidth inputProps={{style:{
                                    textOverflow:'ellipsis'}}}>
                                    {Object.values(countries).map( country => {
                                        return(
                                        <MenuItem key={country.code} value={country.code}>
                                            {country.description.name}
                                        </MenuItem>
                                        )
                                    })}
                                </TextField>
                        }
                    </Grid>
                    <Grid item className={`checkout-billing-address-edit-form-address ${selectedCountryCode && states ? 'show' : ''}`}>
                        <Grid container direction="column">
                            {selectedCountryCode && states &&
                                <form onSubmit={onUpdate}>
                                    <Grid item>
                                        <BackButton isDisabled={isLoading} onClick={resetCountryAndStates} />
                                    </Grid>
                                    <Grid item>
                                        <BillingAddressEditForm selectedCountryCode={selectedCountryCode} states={states} setFormValues={setFormValues} />
                                    </Grid>
                                    <Grid item className='checkout-billing-address-edit-form-button'>
                                        <ButtonLoading type="submit" disabled={emptyPropertiesObject(formValues)} isLoading={isLoading} label={textTransform('title', t('common:update'))} 
                                        variant="outlined" color="primary" fullWidth style={errorUpdating ? style.error : {}}  />
                                    </Grid>
                                    <Grid item className={`checkout-billing-address-edit-feedback-toogle ${errorUpdating ? 'show' : ''}`}>
                                        <Typography variant='body2' style={style.error}>
                                            {t('errorUpdating')}
                                        </Typography>
                                    </Grid>
                                </form>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CheckoutBillingAddressEdit