import { useState, useEffect, useRef } from 'react';
import { Typography } from '@material-ui/core';
import CustomPopover from '../CustomPopover/CustomPopover';
import { useUpdateScrollableDocumentCtx } from '../../customHooks/useUpdateScrollableDocumentCtx';
import PreventUnderZLayerInteraction from '../PreventUnderZLayerInteraction/PreventUnderZLayerInteraction';
import './showMoreStringPopOver.css'

const ShowMoreStringPopOver = ({ elemArray, mapFunction, anchorOrigin, transformOrigin, label='more', style={}, innerStyles={popOverContainer:{}} }) => {
    const [isClick, setIsClick] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const setIsScrollableDocument = useUpdateScrollableDocumentCtx()
    const stringRef = useRef(null);
    const popOverRef = useRef(null);
    
    useEffect(() => {        
        if(isClick){
            setIsScrollableDocument(false);
            const handleClickOutside = (e) => {
                if(popOverRef && !popOverRef.current.contains(e.target)){
                    setIsClick(false);
                }
            }
            document.addEventListener('pointerdown', handleClickOutside);
            return(() => {
                setIsScrollableDocument(true);
                document.removeEventListener('pointerdown', handleClickOutside);
            })
        }
    },[isClick])

    return (
        elemArray && elemArray.length > 0 ?
            <>
                {isClick &&
                    <PreventUnderZLayerInteraction/>
                }
                <CustomPopover 
                open={isClick || isHover} 
                anchorEl={stringRef} 
                timeTransform={null}
                anchorOrigin={ anchorOrigin || {vertical:'top', horizontal:'center'} } 
                transformOrigin={ transformOrigin || {vertical:'bottom', horizontal:'center'} }
                >
                    <div ref={popOverRef} className='show-more-string-pop-over-container' style={innerStyles.popOverContainer}>
                        {elemArray.map(mapFunction)}
                    </div>
                </CustomPopover>
                <Typography
                    variant='body2'
                    ref={stringRef}
                    onMouseEnter={() => {setIsHover(true)}}
                    onMouseLeave={() => {setIsHover(false)}}
                    onPointerDown={(e) => {
                        e.stopPropagation();
                        setIsClick(true);
                    }}
                    className='show-more-string-pop-over-label'
                    style={style}
                >
                    {label}
                </Typography>
            </>
            :
            null
    )
}

export default ShowMoreStringPopOver