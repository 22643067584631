import { Grid, Typography, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { textTransform } from '../../aux/aux';
import ButtonLoading from '../ButtonLoading/ButtonLoading';
import UserSystemSelectFormItem from '../UserSystemSelectFormItem/UserSystemSelectFormItem';
import ToggleMsg from '../ToggleMsg/ToggleMsg';
import './defaultUserSystemSelect.css';

const DefaultUserSystemSelect = ({ defaultSystem, isAValidSystem, onAgree, onDisagree, onFinish, onRender }) => {
    const { t } = useTranslation('common', { keyPrefix: "defaultUserSystemSelect" });
    const [isUpdating, setIsUpdating] = useState(false);
    const abortControllerRef = useRef(null);
    const [error, setError] = useState({isError:false, msg:undefined});
    const handleOnYes = async(e) => {
        e.preventDefault();
        try{
            setIsUpdating(true);
            const updatedValues = {
                selectedDeviceName : defaultSystem.deviceName,
                selectedSoftwareName : defaultSystem.softwareName,
                selectedUserSystemId : defaultSystem.id
            };
            await onAgree(updatedValues);
            onFinish()
        }catch(error){
            if(!abortControllerRef.current.signal.aborted){
                setError({isError:true, msg:`${textTransform('title', t('errorSettingDefaultUserSystem'))}. ${textTransform('title', t('selectAnotherAndYouCanUpdateLater'))}` })
            }
        }finally{
            if(!abortControllerRef.current.signal.aborted){
                setIsUpdating(false)
            }
        }
    };

    useEffect(() => {
        abortControllerRef.current = new AbortController();
        return(() => {
            abortControllerRef.current.abort();
        })
    },[])

    useEffect(() => {
        const timeoutId = setTimeout(onRender, 300);
        return(() => clearTimeout(timeoutId))
    })

    return (
        <Grid container direction='column'>
            <Grid item>
                <Typography variant='body1'>
                    {textTransform('title', t("thisIsYourDefaultSystem"))}
                </Typography>
            </Grid>
            <Grid item className='default-user-system-select-item'>
                <div style={{border:"solid 1px #888888", borderRadius:"5px", padding:"5px"}}>
                    <UserSystemSelectFormItem system={defaultSystem} onlyDeviceAndSoftware={false} />
                </div>
            </Grid>
            <Grid item className='default-user-system-select-item'>
                <Typography variant='body1'>
                    {isAValidSystem ? 
                            textTransform('title', t("doYouwantToSelectDefaultUserSystem"))
                        :
                            textTransform('title', t("thisContentIsNotAdapted"))
                    }
                </Typography>
            </Grid>
            <Grid item className='default-user-system-select-item'>
                <Grid container justifyContent='flex-end'>
                    <Grid item className='default-user-system-select-button'>
                        <Button disabled={isUpdating} onClick={onDisagree} color='primary'>
                            {t("selectAnother")}
                        </Button>
                    </Grid>
                    {isAValidSystem &&
                        <Grid item className='default-user-system-select-button'>
                            <ButtonLoading onClick={handleOnYes} disabled={isUpdating || error.isError} isLoading={isUpdating} label={t("common:yes")} 
                            color="primary" fullWidth/>
                        </Grid>
                    }
                </Grid>
            </Grid>
            <ToggleMsg 
                isShown={error.isError} 
                msg={error.msg} 
                type='error' 
            />
        </Grid>
    )
}

export default DefaultUserSystemSelect