import { useState, useEffect } from 'react'

import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ReplayIcon from '@mui/icons-material/Replay';
import { useTheme } from '@material-ui/styles';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import { isEmptyObject, textTransform } from '../../aux/aux';
import { getVideoThumbnail } from '../../services/visitorServices';
import './videoThumbnailPreview.css'

const VideoThumbnailPreview = ({ isPlayerReady, videoSourceName, videoSourceId, onPlayCallback, onReplayCallback, imgUrl, imgOpt={}, showReplay=false, playIconComponent=null, replayIconComponent=null, stylePlayIcon={}, styleReplayIcon={} }) => {
    const theme = useTheme()
    const PlayIconComponent = playIconComponent == null ? PlayCircleOutlineIcon : playIconComponent;
    const ReplayIconComponent = replayIconComponent == null ? ReplayIcon : replayIconComponent;
    const [thumbnailUrl, setThumbnailUrl] = useState(imgUrl);
    const [isReady, setIsReady] = useState(false);
    const [hover, setHover] = useState(false);
    const style = {
        stylePlayIcon: isEmptyObject(stylePlayIcon) ? {color: theme.palette.primary.light} : stylePlayIcon,
        styleReplayIcon: isEmptyObject(styleReplayIcon) ? {color: theme.palette.primary.light} : styleReplayIcon,
    }
    const requestVideoThumbnail = async (videoSourceName, videoSourceId, opt={}, signal=new AbortController().signal) => {
        try{
            setIsReady(false);
            let{
                resolution,
                fileType
            }=opt;
            const res = await getVideoThumbnail(videoSourceName, videoSourceId, opt, 0, signal);
            if(!signal.aborted){
                setThumbnailUrl(res.data.url);
            }
        }catch(err){
            console.log(err)
            setThumbnailUrl(null)
        }
    }

    const onClick = () => {
        if(showReplay){
            onReplayCallback()
        }else{
            onPlayCallback()
        }
    }

    useEffect(() => {
        if(thumbnailUrl === undefined){
            const abortController = new AbortController();
            const signal = abortController.signal;
            requestVideoThumbnail(videoSourceName, videoSourceId, imgOpt, signal);
            return(() => {
                abortController.abort();
            })
        }else{
            setIsReady(true);
        }
    },[thumbnailUrl, videoSourceName, videoSourceId])

    return (
        <div className='video-thumbnail-preview-main-container' onClick={ isPlayerReady ? onClick : null} onPointerEnter={() => setHover(true)} onPointerLeave={() => setHover(false)}>
            <div className={`video-thumbnail-preview-loading`}>
                {!isPlayerReady ? <LoadingComponent visibleElements='circle' /> : null }
            </div>
            <div className={`video-thumbnail-preview-img-container${isReady ? ' visible' : ''}`}>
                <div className='video-thumbnail-preview-overlay'>
                    <div className={`video-thumbnail-preview-overlay-background${isPlayerReady ? ` ready ${hover ? ' hover' : ''}`: ''}`}/>
                    {isPlayerReady ? 
                            <div className='video-thumbnail-preview-icon-container'>
                                {showReplay ?
                                    <ReplayIconComponent className='video-thumbnail-preview-icon' style={style.styleReplayIcon} />
                                :
                                    <PlayIconComponent className='video-thumbnail-preview-icon' style={style.stylePlayIcon} />
                                }
                            </div>
                        :
                            null
                    }
                </div>
                <img src={thumbnailUrl} className='video-thumbnail-preview-img' />
            </div>
        </div>
    )
}

export default VideoThumbnailPreview