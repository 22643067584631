import { useContext } from "react";
import { HeaderPropsUpdateContext } from "../customContext/headerPropsProvider";

export function useUpdateHeaderPropsCtx(){
    const ctx = useContext(HeaderPropsUpdateContext);
    if(ctx == null){
        throw new Error("useUpdateHeaderPropsCtx must be used within the headerPropsProvider context")
    }
    return ctx
}
