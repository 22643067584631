import { forwardRef } from "react";
import { useHeaderPropsCtx } from "../../customHooks/useHeaderPropsCtx"
import { useScrollableDocumentCtx } from "../../customHooks/useScrollableDocumentCtx"
import HeaderLandingNoLinks from "../HeaderLandingNoLinks/HeaderLandingNoLinks";
import HeaderSimple from "../HeaderSimple/HeaderSimple";
import './header.css'

const Header = (props, ref) => {
    const HEADER_CONTAINER_Z_INDEX = 1100;
    const headerProps = useHeaderPropsCtx();
    const {isScrollableDocument, scrollbarWidth} = useScrollableDocumentCtx()
    const styles = {
        mainContainer: {
            zIndex: HEADER_CONTAINER_Z_INDEX,
            position: headerProps.position,
            backgroundColor: headerProps.backgroundColor,
            // if isSticky padding scroll correction is done by the body
            paddingRight: isScrollableDocument || (headerProps.position === 'sticky' || headerProps.position === 'relative') ? null :  document.body.style.paddingRight,
        }
    }
    const getHeaderComponent = () => {
        let Component; 
        if(headerProps.isSimple){
            Component = HeaderSimple 
        }else{
            Component = HeaderLandingNoLinks
        }
        return (<Component {...props}/>)
    }
    return ( 
        <div className='header-main-container' style={styles.mainContainer}>
            <div className="header-promo-container" ref={ref}/>
            {getHeaderComponent()}
        </div>
    )
}

export default forwardRef(Header)