import { useEffect, useState } from 'react'
import HeaderWrapperSection from '../HeaderWrapperSection/HeaderWrapperSection'
import FAQItemList from '../FAQItemList/FAQItemList'
import { getFAQ } from '../../aux/staticContentQueries'
import { useLocaleCtx } from '../../customHooks/useLocaleCtx'
import { getLanguageIdOrNull } from '../../aux/sessionHelpers'
import './contentFAQ.css'

const ContentFAQ = ({ contentPage }) => {
    const localeCtx = useLocaleCtx();
    const [commonContentFaqArray, setCommonContentFaqArray] = useState(null)
    const [faqArray, setFAQArray] = useState(null);
    const requestFAQArray = async(languageId, signal) => {
        try{
            const res = await getFAQ(languageId, signal)
            const commonFaq = res.data;
            if(res.data && !signal.aborted){
                setCommonContentFaqArray(commonFaq.contentFaqIndexArray.map((commonFaqIndex, _) => commonFaq.faqArray[commonFaqIndex]));
            }
        }catch(error){
            if(!signal.aborted){
                console.log(`common FAQ file no accesible`)
            }
        }
    } 
    useEffect(() => {
        const languageId = getLanguageIdOrNull(localeCtx.localeCode);
        const abortController = new AbortController();
        requestFAQArray(languageId, abortController.signal)
        return(() => {
            abortController.abort();
        })
    },[]);

    useEffect(() => {
        if(contentPage.faqArray){
            setFAQArray( prev => {
                prev = commonContentFaqArray ? commonContentFaqArray : [];
                return [ ...contentPage.faqArray, ...prev ];
            })
        }
    },[contentPage.faqArray])

    useEffect(() => {
        if(commonContentFaqArray){
            setFAQArray(prev => {
                prev = prev ? prev : [];
                return [...prev, ...commonContentFaqArray]
            });
        }
    },[commonContentFaqArray])

    return (
        <div>
            <HeaderWrapperSection>
                <FAQItemList faqArray={faqArray} />
            </HeaderWrapperSection>
        </div>
    )
}

export default ContentFAQ