import './carrousel.css'

const Carrousel = ({ Slider, sliderProps, children, noSliderClassName, hideSlider=false }) => {
    return (
        hideSlider ?
            <div className={noSliderClassName ? noSliderClassName : 'carrouser-no-slider'}>
                {children}
            </div>
        :
            <Slider {...sliderProps} >
                {children}
            </Slider>
    )
}

export default Carrousel