
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import ReactCrop, { 
    centerCrop,
    makeAspectCrop
} from 'react-image-crop'
import SimpleNoModalDialog from '../SimpleNoModalDialog/SimpleNoModalDialog'
import { calculateScale, canvasPreview, canvasPreviewAndResize } from '../../aux/canvasPreview'

import ImgDN from "../../cdn/imgDN";
import cloudinary from "../../cdn/providers/cloudinary";

import 'react-image-crop/dist/ReactCrop.css'
import './uploadImgWidget.css'
import { useEffect } from 'react'
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import ToggleMsg from '../ToggleMsg/ToggleMsg';
import { textTransform } from '../../aux/aux';

/**
 * This is to demonstate how to make and center a % aspect crop
 * which is a bit trickier so we use some helper functions.
 * @param {number} mediaWidth 
 * @param {number} mediaHeight 
 * @param {number} aspect 
 * @returns 
 */
function centerAspectCrop(mediaWidth, mediaHeight, aspect=1) {
    return centerCrop(
        makeAspectCrop(
        {
            unit: 'px',
            width: 200,
        },
        1,
        mediaWidth,
        mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
    )
}

const UploadImgWidget = ({ imgFile, imgId, imgType, onUpload=()=>{}, onCancel=()=>{}, ...props}) => {
    const MAX_PROFILE_IMG_SIZE_MB = 2;
    const MAX_IMG_SIZE_PX = 400; 
    const errorTypeArray = ['noSuitable', 'noUpload', 'wrongURL'];
    const assetProvider = new ImgDN(cloudinary);
    const { t } = useTranslation("common", { keyPrefix: "uploadImgWidget" });
    const [imgURL, setImgURL] = useState(null);
    const [crop, setCrop] = useState(null);
    const [completedCrop, setCompletedCrop] = useState(null);
    const [aspect, setAspect] = useState(1);
    const [isUploading, setIsUploading] = useState(false);
    const [errorState, setErrorState] = useState({
        isError: false,
        type: undefined
    })
    const imgRef = useRef(null);
    const abortControllerRef = useRef(null);

    /**
     * @param {File} image - Image File Object
     * @param {Object} crop - pixelCrop Object provided by react-image-crop
     * @param {String} fileType - img file type. Default: image/jpeg
     * @param {Boolean} asBlob - if false returns the img as Base64 string
     * @param {Number} compressQuality - [0,100] blob quality
     */
    const getCroppedImg = async( fileType='image/jpeg', image=imgRef.current, crop=completedCrop, asBlob=true, compressQuality=50) => { 
        try{
            // local drive is considered 'another-domain'
            // this attribute is set directly in the rendered img component
            // image.crossOrigin = 'anonymous';
            const canvas = document.createElement('canvas');
            const resizeScale = calculateScale({height:image.naturalHeight, width:image.naturalWidth}, {height:MAX_IMG_SIZE_PX, width:MAX_IMG_SIZE_PX});
            const pixelRatio = calculateScale(crop, {height:MAX_IMG_SIZE_PX, width:MAX_IMG_SIZE_PX});
            await canvasPreviewAndResize(image, canvas, crop, resizeScale, pixelRatio);
            let result;
            if(asBlob){
                // As a blob
                result = new Promise((resolve, reject) => {
                    canvas.toBlob(file => {
                        resolve(file);
                        }, fileType, compressQuality);
                    ;
                });
            }else{
                // As Base64 string
                result = canvas.toDataURL(fileType);
            }
            return result;
        }catch(error){
            throw error
        }
    }
    
    const handleOnUpload = async (e, signal=abortControllerRef.current.signal) => {
        const isSuitableProfileFileImg = (file) => {
            
            const hasValidFileSize = (size) => {
                const fileSizeMb = (size / 1024 / 1024).toFixed(4);
                return fileSizeMb <= MAX_PROFILE_IMG_SIZE_MB; 
            }
            
            const hasValidImgType = (type) => {
                const VALID_IMG_TYPE = ['image/png', 'image/jpeg'];
                return VALID_IMG_TYPE.includes(type);
            } 

            let result = false;

            if(file instanceof File || file instanceof Blob){
                result = hasValidFileSize(file.size) && hasValidImgType(file.type)
            }
            return result;
        }
        try{
            e.preventDefault();
            setIsUploading(true);
            const croppedImgFile = await getCroppedImg();
            const isSuitable = isSuitableProfileFileImg(croppedImgFile);
            if(isSuitable){
                const uploadAdditionalParams = {};
                if(imgId){
                    uploadAdditionalParams.public_id = imgId
                }
                const uploadParams = assetProvider.getUploadImgParams(imgType, uploadAdditionalParams);
                const res = await assetProvider.upload(croppedImgFile, uploadParams, signal);
                await onUpload(res.data, signal)
            }else{
                setErrorState({ isError:true, type:errorTypeArray[0] })
            }
        }catch(error){
            setErrorState({ isError:true, type:errorTypeArray[1] })
        }finally{
            if(!signal.aborted){
                setIsUploading(false);
            }
        }
    }

    const getErrorMsg = (type) => {
        let msg;
        switch(type){
            case errorTypeArray[0]:
                msg = `${textTransform('title', t("theImgCannotBelargerThan", { size: MAX_PROFILE_IMG_SIZE_MB }))}\n${textTransform('title', t("theImgMustBeJpgOrPng"))}`
                break;
            case errorTypeArray[1]:
                msg = `${textTransform('title', t("theImgCannotBeUpdatedNow"))}\n${textTransform('title', t("tryItLater"))}`
                break;
            case errorTypeArray[2]:
                msg = `${textTransform('title', t("theimgCannotBePreviewed"))}\n${textTransform('title',t("tryItLater"))}`
                break;
            case undefined:
                msg = '';
                break;
            default:
                msg = `${t("AnErrorHasHappened")}\n${t("tryItLater")}`
                break;
        }
        return msg;
    }

    const handleOnCancel = (e) => {
        onCancel()
    }

    const onImageLoad = (e) => {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    const readFileAsURL= (e) => {
        try{
            setImgURL(e.target.result)
        }catch(error){
            setErrorState({ isError:true, type:errorTypeArray[2] })
        }
    }

    useEffect(() => {
        if(imgFile){
            const abortController = new AbortController();
            abortControllerRef.current = abortController;
            const reader = new FileReader();
            reader.addEventListener('load', readFileAsURL);
            reader.readAsDataURL(imgFile)
            return(() => {
                reader.removeEventListener('load', readFileAsURL)
                abortController.abort()
            })
        }else{
            setCrop(null);
            setCompletedCrop(null);
            setImgURL(null);
            setIsUploading(false);
            setErrorState({isError:false, type:undefined});
        }
    },[imgFile])

    useEffect(() => {
        if(errorState.isError){
            if(errorState.type != errorTypeArray[2]){
                const timeoutId = setTimeout(() => setErrorState({isError:false, type:undefined}), 3000);
                return(() => {clearTimeout(timeoutId)})
            }
        }
    },[errorState.isError])

    return (
        <SimpleNoModalDialog
            onAgree={handleOnUpload}
            isDisabled={!imgURL}
            onDisagree={handleOnCancel}
            isLoading={isUploading}
            isError={errorState.isError}
            errorMsg={getErrorMsg(errorState.type)}
            childrenStyle={{textAlign:'center'}}
            {...props}
        >
            {imgURL ?
                <ReactCrop
                    className='upload-img-widget-crop-wrapper'
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(complete) => {
                        setCompletedCrop(complete)
                    }}
                    aspect={aspect}
                    locked={false}
                >
                    <img
                        type='file'
                        className='upload-img-widget-img'
                        ref={imgRef}
                        alt='crop img'
                        src={imgURL}
                        onLoad={onImageLoad}
                    />
                </ReactCrop>
                :
                <LoadingComponent visibleElements='circle' />
            }
        </SimpleNoModalDialog>                   
    )
}

export default UploadImgWidget