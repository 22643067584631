import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { getSubscriptionProductTypeName, getSubscriptionUpgradeProductTypeName } from '../../aux/productHelpers';
import './selectProductConsumptionType.css';
import ProductConsumptionTypeOptionList from '../ProductConsumptionTypeOptionList/ProductConsumptionTypeOptionList';
import ContentConsumptionOptionItem from '../ContentConsumptionOptionItem/ContentConsumptionOptionItem';
import ProductConsumptionTypeOptionGroup from '../ProductConsumptionTypeOptionGroup/ProductConsumptionTypeOptionGroup';
import SubscriptionConsumptionOptionGroupItem from '../SubscriptionConsumptionOptionGroupItem/SubscriptionConsumptionOptionGroupItem';
import { textTransform } from '../../aux/aux';
import { useTranslation } from 'react-i18next';
import SubscriptionUpgradeConsumptionOptionGroupItem from '../SubscriptionUpgradeConsumptionOptionGroupItem/SubscriptionUpgradeConsumptionOptionGroupItem';
import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper';
import ProductConsumptionTypeOptionDescription from '../ProductConsumptionTypeOptionDescription/ProductConsumptionTypeOptionDescription';

const SelectProductConsumptionType = ({
        productConsumptionTypeName, 
        productConsumptionType, 
        isMain,
        userProductPurchasabilityDetailsMap, 
        isSelectedProductConsumptionType, 
        selectedProductConsumptionOption,
        onSelectOptionType=()=>{}, 
        onSelectOption=()=>{},
        hideConsumptionTypeName=false
    }) => {
    const theme = useTheme();
    const { t } = useTranslation('common', { keyPrefix:'selectProductConsumptionType'})
    const styles = {
        mainContainer:{
            borderRadius: '5px',
            backgroundColor: theme.palette.surface.light[1],
        },
        nameContainer:{
            backgroundColor: theme.palette.background.paper,
            borderRadius: '5px',
            ...(isSelectedProductConsumptionType ? 
                {
                    boxShadow: theme.shadows[2],
                }
                :
                {
                    boxShadow: theme.shadows[1],
                    cursor: 'pointer'
                }
            )
        },
        name:{
            ...isSelectedProductConsumptionType ? {
                color: theme.palette.primary.main,
            }
            :
            {}
        }
    }
    const SelectComponent = ((productConsumptionTypeName) => {
        let Component;
        switch(productConsumptionTypeName){
            case 'content':
                Component = 
                    <ProductConsumptionTypeOptionList 
                        productConsumptionType={productConsumptionType} 
                        userProductPurchasabilityDetailsMap={userProductPurchasabilityDetailsMap} 
                        ItemComponent={ContentConsumptionOptionItem} 
                        isSelectedProductConsumptionType={isSelectedProductConsumptionType}
                        selectedOption={selectedProductConsumptionOption}
                        onSelectOption={onSelectOption} 
                    />
                break;
            case getSubscriptionProductTypeName():
                Component = 
                        <ProductConsumptionTypeOptionGroup 
                            productConsumptionType={productConsumptionType} 
                            userProductPurchasabilityDetailsMap={userProductPurchasabilityDetailsMap} 
                            GroupItemComponent={SubscriptionConsumptionOptionGroupItem}
                            propPathStringToGroupBy={'typeProduct.subscriptionTypeId'}
                            isSelectedProductConsumptionType={isSelectedProductConsumptionType}
                            selectedOption={selectedProductConsumptionOption}
                            onSelectOption={onSelectOption}
                        />
                break;
            case getSubscriptionUpgradeProductTypeName():
                Component = 
                        <ProductConsumptionTypeOptionGroup 
                            productConsumptionType={productConsumptionType} 
                            userProductPurchasabilityDetailsMap={userProductPurchasabilityDetailsMap} 
                            GroupItemComponent={SubscriptionUpgradeConsumptionOptionGroupItem}
                            propPathStringToGroupBy={'typeProduct.toSubscriptionProduct.typeProduct.subscriptionTypeId'}
                            isSelectedProductConsumptionType={isSelectedProductConsumptionType}
                            selectedOption={selectedProductConsumptionOption}
                            onSelectOption={onSelectOption}
                        />
                break;
            default:
                Component = null;
        }
        if(Component != null){
            Component = 
                <>
                    <ProductConsumptionTypeOptionDescription productConsumptionTypeName={productConsumptionTypeName} variant={'body2'}/>
                    {Component}
                </>
        }
        return Component
    })(productConsumptionTypeName);

    const getProductConsumptionTypeName = (productConsumptionTypeName, isMain) => {
        let name;
        switch(productConsumptionTypeName){
            case 'content':
                name = t('buyContent')
                break;
            case getSubscriptionProductTypeName():
                name = t(isMain ? 'buySubscription':'accessWithSubscription')
                break;
            case getSubscriptionUpgradeProductTypeName():
                name = t(isMain ? 'improveSubscription': 'upgradeYourSubscription')
                break;
        }
        return name
    }

    const handleOnClick = (e) => {
        e.preventDefault();
        onSelectOptionType(productConsumptionTypeName)
    }

    return(
        SelectComponent ? 
            <div className='select-product-consumption-type-wrapper'>
                <div className='select-product-consumption-type-main-container' style={styles.mainContainer}>
                    {hideConsumptionTypeName ? 
                        null
                        :
                        <div className='select-product-consumption-type-name-container' style={styles.nameContainer} onClick={isSelectedProductConsumptionType ? null : handleOnClick}>
                            <Typography variant='body1' style={styles.name}>
                                {textTransform('title', getProductConsumptionTypeName(productConsumptionTypeName, isMain))}
                            </Typography>
                        </div>
                    }
                    <div className={`select-product-consumption-type-options-wrapper${isSelectedProductConsumptionType ? ' show' : ''}`}>
                        {SelectComponent}
                    </div>
                </div>
            </div>
            :
            null
    )
}

export default SelectProductConsumptionType