import { useState, useEffect, useRef } from 'react'
import PopUpModal from '../PopUpModal/PopUpModal'
import { useUpdateLocaleCtx } from '../../customHooks/useUpdateLocaleCtx';
import { useUpdateCountryAndCurrencyCtx } from '../../customHooks/useUpdateCountryAndCurrencyCtx';

// Style
import './popUpLogIn.css'
import LogInSignIn from '../LogInSignIn/LogInSignIn';
import { justSetUser } from '../../services/sessionServices';

const PopUpLogIn = ({ setShowModal, showModal, setUser, passiveVerification=true }) => {
    const POP_UP_WIDTH = 380; //450px
    const innerDivRef = useRef(null);
    const updateLocaleCtx = useUpdateLocaleCtx();
    const updateCountryAndCurrencyCtx = useUpdateCountryAndCurrencyCtx();
    const [innerDivHeight, setInnerDivHeight] = useState(null);

    const handleLogIn = async (userData) => {
        justSetUser(userData, setUser)
        updateCountryAndCurrencyCtx.setCountryAndCurrency(userData.countryAndCurrency)
        await updateLocaleCtx(userData.locale);
        handleClose()
    }

    const handleClose = () => {
        setShowModal(false);
    };

    useEffect(() => {
        if(innerDivRef.current){
            const observer = new ResizeObserver(elements => {
                const innerContainer = elements[0];
                setInnerDivHeight(innerContainer.borderBoxSize[0].blockSize)
            });
            observer.observe(innerDivRef.current)
            return(() => {
                observer.disconnect();
            })
        }
    },[])

    return (
        <PopUpModal showModal={showModal} onClickClose={handleClose} width={POP_UP_WIDTH} height={innerDivHeight} >
            <div ref={innerDivRef} className='pop-up-log-in-inner'>
                <LogInSignIn
                    passiveVerification={passiveVerification}
                    onLogin={handleLogIn}
                    resetComponent={showModal}
                />
            </div>
        </PopUpModal>
    )
}

export default PopUpLogIn
