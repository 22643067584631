import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@material-ui/core'
import { textTransform, textTruncate } from '../../aux/aux'
import { percentageToString } from '../../aux/purchaseHelpers'

import './classSideBarSection.css'
import CustomPopover from '../CustomPopover/CustomPopover'

const ClassSideBarSection = ({ sectionNum, title, handleVisibility, sectionId, currentSectionId, totalCompletedMin, lengthInMin }) => {
    const MAX_DISPLAYABLE_TITLE_CHAR_LENGTH = 50; 
    const { t } = useTranslation('common');
    const [isOpenPopover, setIsOpenPopover] = useState(false);
    const wrapperRef = useRef(null);

    const getSectionProgress = useCallback((totalCompletedMin, lengthInMin) => {
        return Math.min(totalCompletedMin, lengthInMin) / lengthInMin;
    },[totalCompletedMin, lengthInMin])


    const handleOnMouseEnter = () => {
        if(title.length > MAX_DISPLAYABLE_TITLE_CHAR_LENGTH){
            setIsOpenPopover(true);
        }
    }

    const handleOnMouseLeave = () => {
        setIsOpenPopover(false);
    }


    return (
        <div className='class-side-bar-section-main' onClick={e => {handleVisibility(sectionId)}} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
            <CustomPopover anchorEl={wrapperRef} open={isOpenPopover} timeTransform={null}
            anchorOrigin={{vertical:'center', horizontal:'left'}} 
            transformOrigin={{vertical:'center', horizontal:'right'}}>
                <Typography variant='body2'>
                    {`${textTransform('title', t('section', {count: 1}))} ${sectionNum}: ${textTruncate(textTransform('title', title), MAX_DISPLAYABLE_TITLE_CHAR_LENGTH)}`}
                </Typography>
            </CustomPopover>
            <Grid ref={wrapperRef} container direction='column' className='class-side-bar-section-info-container' justifyContent='center' >
                <Grid item className='class-side-bar-section-name'>
                    <Typography>    
                    {`${textTransform('title', t('section', {count: 1}))} ${sectionNum}: ${textTruncate(textTransform('title', title), MAX_DISPLAYABLE_TITLE_CHAR_LENGTH)}`}
                    </Typography>
                </Grid>
                <Grid item className='class-side-bar-section-progress'>
                    <Typography variant="subtitle2">
                        {`${textTransform('title', t('progress'))}: ${percentageToString(getSectionProgress(totalCompletedMin, lengthInMin))}`}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default ClassSideBarSection
