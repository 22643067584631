import { useContext } from "react";
import { ScrollableDocumentContext } from "../customContext/scrollableDocumentProvider";

export function useScrollableDocumentCtx(){
    const ctx = useContext(ScrollableDocumentContext);
    if(ctx == null){
        throw new Error("useScrollableDocumentCtx must be used within the scrollableDocumentProvider context")
    }
    return ctx
}
