import { useEffect } from "react"
import { useUpdateInfoBannerPropsCtx } from '../../customHooks/useUpdateInfoBannerPropsCtx'
import { isNotEmptyObject } from "../../aux/aux";

const InfoBannerUpdater = ({newProps, children}) => {
    const setInfoBannerProps = useUpdateInfoBannerPropsCtx();
    
    useEffect(() => {
        if(newProps && isNotEmptyObject(newProps)){
            setInfoBannerProps(newProps);
            return(() => setInfoBannerProps({})) // Reset to initial props
        }
    },[newProps])

    return( children )
}

export default InfoBannerUpdater