import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { fromCentTo2dec, priceToString } from '../../aux/purchaseHelpers';
import CheckoutPromoCodeWidget from '../CheckoutPromoCodeWidget/CheckoutPromoCodeWidget';
import Timer from '../Timer/Timer';
import './checkoutTotal.css';
import { useTheme } from '@material-ui/styles';
import CheckoutProductAmount from '../CheckoutProductAmount/CheckoutProductAmount';
import { isEmptyObject, isNotEmptyObject } from '../../aux/aux';

const CheckoutTotal = ({ isCheckoutUpdating, orderAmounts, appliedPromoCode }) => {
    const { t } = useTranslation('checkout');
    const theme = useTheme();
    
    const styles = {
        mainContainer: {
            backgroundColor: theme.palette.grey[100],
            // border: 'solid 1px ',
            borderRadius: '5px'
        },
        total:{
            color: theme.palette.grey[600],
            // fontWeight: 'bold'
        },
        amount:{
            // fontWeight: 'bold'
        }
    }

    return (
        <Grid container direction='column' className='checkout-total-wrapper' style={styles.mainContainer}>
            <Grid item container className='checkout-total-main-container'>
                <Grid item >
                    <Typography variant='body2' style={styles.total}>
                        {t('totalToPay')}
                    </Typography>
                </Grid>
                <Grid item>
                    {!isCheckoutUpdating && isNotEmptyObject(orderAmounts) ?
                        <Typography variant='body1' style={{...styles.total, ...styles.amount}}>
                            {t('{{val, currency}}',{ val:fromCentTo2dec(orderAmounts.finalAmount), currency: orderAmounts.currencyId, trailingZeroDisplay:'stripIfInteger'})}
                        </Typography>
                    :
                        <Skeleton variant="text" width={50} height={`${theme.typography.body1.lineHeight}em`}/>
                    }
                </Grid>
            </Grid>
            {appliedPromoCode ?
                <Grid item >
                    <CheckoutPromoCodeWidget 
                        isCheckoutUpdating={isCheckoutUpdating} 
                        appliedPromoCode={appliedPromoCode} 
                        currencyId={orderAmounts.currencyId} 
                        amountOff={orderAmounts.totalProductPromoCodeAmountOff + orderAmounts.totalOrderPromoCodeAmountOff} 
                        className={'checkout-total-promo-widget-main-container'}
                    />
                </Grid>
                :
                null
            }
        </Grid>
  )
}

export default CheckoutTotal