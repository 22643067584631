import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import UserSystemSelectFormItem from '../UserSystemSelectFormItem/UserSystemSelectFormItem';
import SimpleNoModalDialog from '../SimpleNoModalDialog/SimpleNoModalDialog';

import './modifyContentUserSystemNoModalDialog.css';
import { textTransform } from '../../aux/aux';

const ModifyContentUserSystemNoModalDialog = ({system, onAgree, onDisagree}) => {
    const { t } = useTranslation('common', { keyPrefix: 'modifyContentUserSystemNoModalDialog'});
    
    return (
        <SimpleNoModalDialog contentText={t('modifyContentUserSystemDialog')} onAgree={onAgree} onDisagree={onDisagree}>
            <div style={{border:"solid 1px #888888", borderRadius:"5px", padding:"5px"}}>
                <Typography variant='body1'>
                    {textTransform('title', t('currentSystem'))}
                </Typography>
                <UserSystemSelectFormItem system={system} onlyDeviceAndSoftware={true} />
            </div>
            <Typography variant='body1'>
                {textTransform('title', t('modifyContentUserSystemDialog_2'))}
            </Typography>
        </SimpleNoModalDialog>
    )
    }

export default ModifyContentUserSystemNoModalDialog