import { useRef, useState, useCallback, forwardRef } from 'react'
import { Grid, IconButton, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { 
        Editor, EditorState, ContentBlock, ContentState, CharacterMetadata, RichUtils, getDefaultKeyBinding,
        convertToRaw, convertFromRaw, genKey
} from 'draft-js';
import { 
        getSelectionInlineStyle, getSelectionText, getSelectedBlocksMap,
} from 'draftjs-utils'

import { List } from 'immutable';

import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import 'draft-js/dist/Draft.css';
import './textEditor.css'
import ButtonLoading from '../ButtonLoading/ButtonLoading';
import { useEffect } from 'react';
import { isEmptyObject } from '../../aux/aux';

const TextEditor = ( { 
    textEditorId, rawJsonContentBlocks, rawContentBlocks, onShiftAndEnter, onUpdateEditor, readOnly=false, readOnlyConfig={}, 
    clearEditor=false, maxTextLength=250, placeholder='', textAreaWrapperStyle={}, ...props 
}, ref ) => {
    const theme = useTheme();
    const textEditorIdRef = useRef(textEditorId);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [editorStateBackUp, setEditorStateBackUp] = useState(() => EditorState.createEmpty());
    const [isTruncated, setIsTruncated] = useState(false);
    const {
        truncateDecorator = '...',
        preventTruncation = false,
        truncateLength = maxTextLength, // truncateDecorator not included
    } = readOnlyConfig;
    const [styleControl, setStyleControl] = useState({
        bold: false,
        italic: false,
        numeredList: false,
        bulletedList: false 
    })
    const editorRef = useRef(null);

    const styles = {
        unselectedIcon : {
            color: '#1c1d1f'
        },
        selectedIcon: {
            backgroundColor: '#1c1d1f',
            color: '#fff'
        },
        unSelectedIconContainer: {
            color: '#1c1d1f'
        },
        selectedIconContainer: {
            backgroundColor: '#1c1d1f',
            color: '#fff'
        },
        styleIconsWrapper: {
            borderBottom: 'solid 1px #f3f3f3',
        },
        remainingCounter: {
            color: '#888',
            textAlign: 'right',
            padding: '0px 10px'
        },
        textAreaWrapperStyle: {
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.body2.fontSize,
            ...textAreaWrapperStyle
        }
    }

    const handleOnKeyCommand = (command, newEditorState) => {
        const newState = RichUtils.handleKeyCommand(newEditorState, command);
        let result;
        if(newState){
            handleOnChangeEditorState(newState);
            result = 'handled';
        }else{
            result = 'not-handled'
        }
        return result;
    }


    const handleOnChangeEditorState = (newEditorState) => {
        if(!isTruncated){
            setEditorStateBackUp(newEditorState);
        }
        setEditorState(newEditorState);
    }

    const keepStyleControlConsistency = (newEditorState) => {
        const stylesInSelection = getSelectionInlineStyle(newEditorState);
        const blockTypeInSelection = getSelectionBlockType(newEditorState);
        const newStyleControl = {};
        newStyleControl.bold = stylesInSelection['BOLD']
        newStyleControl.italic = stylesInSelection['ITALIC'];
        newStyleControl.numeredList = blockTypeInSelection === 'ordered-list-item';
        newStyleControl.bulletedList = blockTypeInSelection === 'unordered-list-item';
        setStyleControl(styleControl => ({ ...styleControl, ...newStyleControl }))
    }

    const getSelectionBlockType = (editorState) => {
        const selectedBlocksArray = getSelectedBlocksMap(editorState).toList();
        let reducedBlockType;
        for(let block of selectedBlocksArray){
            let currentBlockType = block.getType()
            if(reducedBlockType === undefined){
                reducedBlockType = currentBlockType;
            }else{
                if(reducedBlockType !== currentBlockType){
                    reducedBlockType = 'unstyled';
                    break;
                }
            }
        }
        return reducedBlockType;
    }

    const getCurrentContentText = (editorState) =>{
        const currentContent = editorState.getCurrentContent();
        const currentContentText = currentContent.getPlainText('');
        return currentContentText;
    }

    const handleOnBeforeInput = () => {
        const currentContentLength = getCurrentContentText(editorState).length;
        const selectedTextLength = getSelectionText(editorState).length;
        if( currentContentLength - selectedTextLength > maxTextLength ){
            return 'handled';
        }
    }

    const handleOnPastedText = ( pastedText ) => {
        const currentContentLength = getCurrentContentText(editorState).length;
        const selectedTextLength = getSelectionText(editorState).length;
        if( currentContentLength + pastedText.length - selectedTextLength > maxTextLength){
            return 'handled'
        }

    }

    const onStyleControlClick = (e) => {
        const styleControlId = e.currentTarget.id.replace('StyleControl','');
        if(styleControl.hasOwnProperty(styleControlId)){
            e.preventDefault();
            setStyleControl(styleControl => ({ ...styleControl, [styleControlId]: !styleControl[styleControlId] }));
            let newEditorState;
            switch(styleControlId){
                case 'bulletedList':
                    newEditorState = RichUtils.toggleBlockType(editorState, 'unordered-list-item')
                    break;
                case 'numeredList':
                    newEditorState = RichUtils.toggleBlockType(editorState, 'ordered-list-item')
                    break;
                default:
                    const editorCommand = styleControlId.toUpperCase();
                    newEditorState = RichUtils.toggleInlineStyle(editorState, editorCommand);
            }
            if(newEditorState){
                handleOnChangeEditorState(newEditorState);
            }
        }
    }

    const mapKeyToEditorCommand = (e) => {
        const keyCode = e.keyCode;
        const shiftKey = e.shiftKey;
        let newEditorState;
        switch(keyCode){
            case 9:
                // TAB
                e.preventDefault();
                newEditorState = RichUtils.onTab(e, editorState, 4)
                break;
            case 13:
                // ENTER
                if(shiftKey){
                    if(onShiftAndEnter){
                        onShiftAndEnter(e);
                        return;
                    }
                }
                break;
        };
        if(newEditorState && newEditorState  !== editorState){
            handleOnChangeEditorState(newEditorState);
            return
        }
        return getDefaultKeyBinding(e)
    }

    const truncateEditorState = (editorState, maxLength, endDecorator) => {
        const contentState = editorState.getCurrentContent();
        const blocksArray = contentState.getBlocksAsArray();

        let newEditorState;
        let blockIndex = 0;
        let currentLenght = 0;
        let isTruncated = false;
        let endLenght = endDecorator.length;
        const truncatedBlocksArray = [];

        while(!isTruncated && blocksArray[blockIndex]){
            const block = blocksArray[blockIndex];
            const length = block.getLength();
            if(currentLenght + length > maxLength){
                isTruncated = true;
                const truncatedText = block.getText().slice(0, maxLength - currentLenght);
                const truncatedAndDecoratorText = `${truncatedText}${endDecorator}`;
                const truncatedCharacterArray = block.getCharacterList().slice(0, maxLength - currentLenght).toArray();
                const decoratorCharacterArray = endDecorator.split('').map( char => new CharacterMetadata.create());
                const truncatedCharacterAndDecoratorList = new List([ ...truncatedCharacterArray, ...decoratorCharacterArray ]) 
                const truncatedContentBlocksArray = new ContentBlock({
                      key: genKey(),
                      type: 'unstyled', 
                      characterList: truncatedCharacterAndDecoratorList,
                      text: truncatedAndDecoratorText
                  });
                truncatedBlocksArray.push(truncatedContentBlocksArray);
                currentLenght += maxLength - currentLenght + endLenght;
            }else{
                truncatedBlocksArray.push(block);
                currentLenght += length;
            };
            blockIndex++;
        }

        if(isTruncated){
            const contentState = ContentState.createFromBlockArray(truncatedBlocksArray);
            newEditorState = EditorState.createWithContent(contentState);
        }else{
            newEditorState = editorState;
        }

        return [newEditorState, isTruncated];
    }


    const checkAndGetTruncationEditorState = (editorStateBackUp, readOnly, truncateLength, preventTruncation, truncateDecorator) => {
        let newEditorState;
        if(readOnly && truncateLength != null && preventTruncation === false){
            const [truncatedEditorState, isTruncated]= truncateEditorState(editorStateBackUp, truncateLength, truncateDecorator);
            setIsTruncated(isTruncated);
            newEditorState = truncatedEditorState;
        }else{
            setIsTruncated(false);
            newEditorState = editorStateBackUp
        }
        return newEditorState
    }

    const setEditorStateFromRawBlocks = useCallback((rawContentBlocks) => {
        if(areRawBlocksImportable(rawContentBlocks)){
            const importedContentState = convertFromRaw(rawContentBlocks);
            const editorState = EditorState.createWithContent(importedContentState);
            const lastEditorState = checkAndGetTruncationEditorState(editorState, readOnly, truncateLength, preventTruncation, truncateDecorator);
            setEditorState(lastEditorState);
            setEditorStateBackUp(editorState);
        }
    },[readOnly, truncateLength, preventTruncation, truncateDecorator])

    const getExportableEditorState = (editorState) => {
        const currentContent = editorState.getCurrentContent();
        const rawBlocks = convertToRaw(currentContent);
        const plainText = currentContent.getPlainText();
        return { rawBlocks, plainText } 
    }

    const areRawBlocksImportable = (rawBlocks) => {
        return typeof(rawBlocks) === 'object' && rawBlocks.hasOwnProperty('entityMap');
    }

    const getEditorTextRemainingLength = (editorState, maxLegth) => {
        return  maxLegth - getCurrentContentText(editorState).length
    }

    useEffect(() => {
        keepStyleControlConsistency(editorStateBackUp);
        if(onUpdateEditor){
            const exportableEditorState = getExportableEditorState(editorStateBackUp);
            onUpdateEditor(exportableEditorState);
        }
    },[ editorStateBackUp ])

    useEffect(() => {
        if(clearEditor){
            const editorState = EditorState.createEmpty();
            handleOnChangeEditorState(editorState)
            // setEditorState(() => EditorState.createEmpty())
        }
    }, [clearEditor])

    useEffect(() => {
        setEditorStateFromRawBlocks(rawContentBlocks)
        // if(areRawBlocksImportable(rawContentBlocks)){
        //     const importedContentState = convertFromRaw(rawContentBlocks);
        //     const editorState = EditorState.createWithContent(importedContentState);
        //     const lastEditorState = checkAndGetTruncationEditorState(editorState, readOnly, readOnlyConfig);
        //     setEditorState(lastEditorState);
        //     setEditorStateBackUp(editorState);
        // }
    }, [rawContentBlocks])

    useEffect(() => {
        if(rawJsonContentBlocks){
            const rawContentBlocks = JSON.parse(rawJsonContentBlocks);
            setEditorStateFromRawBlocks(rawContentBlocks)
            // if(areRawBlocksImportable(rawContentBlocks)){
                // const importedContentState = convertFromRaw(rawContentBlocks);
                // const editorState = EditorState.createWithContent(importedContentState);
                // const lastEditorState = checkAndGetTruncationEditorState(editorState, readOnly, truncateLength, preventTruncation, truncateDecorator);
                // setEditorState(lastEditorState);
                // setEditorStateBackUp(editorState);
            // }

        }
    }, [rawJsonContentBlocks])

    useEffect(() => {
        const hasTextEditorBackUp = editorStateBackUp.getCurrentContent().hasText();
        if(hasTextEditorBackUp){
            const newEditorState = checkAndGetTruncationEditorState(editorStateBackUp, readOnly, truncateLength, preventTruncation, truncateDecorator);
            setEditorState(newEditorState);
        }
    }, [readOnly, truncateLength, preventTruncation, truncateDecorator])

    useEffect(()=>{
        if(!readOnly){
            const resetEditorState = (e) => {
                if(e.detail.textEditorId === textEditorIdRef.current){
                    const rawContentBlocks = JSON.parse(e.detail.rawJsonContentBlocks);
                    setEditorStateFromRawBlocks(rawContentBlocks)
                }
            }
            window.addEventListener('resetTextEditor', resetEditorState);
            return(()=>{
                window.removeEventListener('resetTextEditor', resetEditorState);
            })
        }
    }, [readOnly])

    return (
        <Grid ref={ref} container direction='column' className='text-editor-wrapper'>
            {!readOnly &&
                <Grid item>
                    <Grid container className='text-editor-style-control-wrapper' style={ styles.styleIconsWrapper }>
                        <Grid item id='boldStyleControl' className='text-editor-style-control-icon-container' onMouseDown={onStyleControlClick} style={ styleControl['bold'] === true ? styles.selectedIconContainer : styles.unSelectedIconContainer } >
                            <FormatBoldIcon  className='text-editor-style-control-icon'/>
                        </Grid>
                        <Grid item id='italicStyleControl' className='text-editor-style-control-icon-container' onMouseDown={onStyleControlClick} style={ styleControl['italic'] === true ? styles.selectedIconContainer : styles.unSelectedIconContainer } >
                            <FormatItalicIcon className='text-editor-style-control-icon'/>
                        </Grid>
                        <Grid item  id='numeredListStyleControl' className='text-editor-style-control-icon-container' onMouseDown={onStyleControlClick} style={ styleControl['numeredList'] === true ? styles.selectedIconContainer : styles.unSelectedIconContainer } >
                            <FormatListNumberedIcon className='text-editor-style-control-icon'/>
                        </Grid>
                        <Grid item id='bulletedListStyleControl' className='text-editor-style-control-icon-container' onMouseDown={onStyleControlClick} style={ styleControl['bulletedList'] === true ? styles.selectedIconContainer : styles.unSelectedIconContainer } >
                            <FormatListBulletedIcon className='text-editor-style-control-icon'/>
                        </Grid>
                        <Grid item xs>
                            <Typography variant='body2' style={ styles.remainingCounter } >
                                { getEditorTextRemainingLength(editorState, maxTextLength) }
                            </Typography>
                        </Grid>
                        {/* <Grid item >
                            {props.children}
                        </Grid> */}
                    </Grid>
                </Grid>
            }
            <Grid item xs className={`text-editor-textarea-wrapper`} style={ styles.textAreaWrapperStyle } >
                <Editor
                    ref={editorRef}
                    readOnly={readOnly}
                    placeholder={placeholder}
                    stripPastedStyles={true}
                    editorState={editorState} 
                    onChange={handleOnChangeEditorState} 
                    handleKeyCommand={handleOnKeyCommand}
                    handleBeforeInput={handleOnBeforeInput}
                    handlePastedText={handleOnPastedText}
                    keyBindingFn={mapKeyToEditorCommand}
                />
            </Grid>
            {!readOnly ? 
                <Grid item >
                    {props.children}
                </Grid>
                :
                null
            }      
        </Grid>
    )
}
export default forwardRef(TextEditor)