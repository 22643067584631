
import { IconButton } from '@material-ui/core';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import './favoriteButton.css'

const FavoriteButton = ({ isClick, onClick, style, hover=true, ...props }) => {

    const styles = {
        icon: {
            display: 'block',
            color: isClick ? 'red' : 'black'
        }
    }

    return (
        <IconButton className={`favorite-button ${hover ? 'favorite-button-hover' : ''}`} onClick={(e) => onClick(!isClick)} size={'small'} {...props}>
            {isClick ?
                <FavoriteIcon style={{ ...styles.icon, ...style }}/>
            :
                <FavoriteBorderIcon style={{ ...styles.icon, ...style }}/>
            }
        </IconButton>
    )
}

export default FavoriteButton