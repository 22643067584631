import { useTheme } from "@material-ui/styles"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomChip from "../CustomChip/CustomChip";
import SubscriptionIcon from "../SubscriptionIcon/SubscriptionIcon";

const SelectedProductTypeChip = ({ selectedProductTypeName, size, variant }) => {
    const theme = useTheme();
    const { t } = useTranslation('common', { keyPrefix: 'selectedProductTypeChip' });
    const [labelKey, setLabelKey] = useState(undefined);
    const [Icon, setIcon] = useState(undefined);
    const colors = {
        new: theme.palette.success.main
    }
    const styles = {
        chip:{
            new:{
                backgroundColor: theme.palette.background.default
            },
            default:{
                backgroundColor: theme.palette.common.white
            }
        }
    }
    useEffect(() => {
        if(selectedProductTypeName){
            let labelKey;
            switch(selectedProductTypeName){
                case 'comming':
                    labelKey = 'commingSoon'
                    break;
                case 'new':
                    labelKey = 'new'
                    break;
                case 'popular':
                    labelKey = 'favorite'
                    break;
                case 'staff':
                    labelKey = 'selected'
                    break;
            }
            setLabelKey(labelKey);
        }
    }, [selectedProductTypeName])
    return (
        labelKey !== undefined ?
            <CustomChip
                size={size || 'small'}
                variant={variant || 'outlined'}
                label={t(labelKey)}
                color={colors[labelKey]}
                style={styles.chip[labelKey] || styles.chip.default}
            />
            :
            null
    )
}

export default SelectedProductTypeChip