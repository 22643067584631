import { Grid, Typography } from '@material-ui/core'
import { style } from '@mui/system';
// Helpers
import { isEmptyObject, textTransform } from '../../aux/aux'
// style
import './popUpModalHeader.css'

const PopUpModalHeader = ({ title, Icon=null, subtitle=null, ...props}) => {
    const style = {
        mainContainer:{
            height: "80px",
            alignItems:'center',
            justifyContent:'center'
        }
    };

  return (
    <Grid container direction='column' style={{...style.mainContainer}} >
        <Grid item container {...props} justifyContent='center' style={{...props.style}}>
            {Icon &&
                <Icon className='pop-up-header-icon'/>
            }
            <Typography variant='body1' className='pop-up-header-title'>
                {textTransform('title', title)}
            </Typography>
        </Grid>
        {subtitle && 
            <Grid item>
                <Typography variant='body2' className='pop-up-header-suntitle'>
                    {textTransform('title', subtitle)}
                </Typography>
            </Grid>
        }
    </Grid>
  )
}

export default PopUpModalHeader