import { Divider, Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import './workguideFeature.css'

const WorkguideFeature = ({ feature }) => {
    const theme = useTheme();
    const matchTablet = useMediaQuery('(min-width:681px) and (max-width: 1119px)')
    const styles = {
        icon: {
            display: 'block'
        },
        titleContainer:{
            minHeight: `${theme.typography.body1.lineHeight * 2}rem`,
        }
    }
    return (
        <div className='workguide-feature-main-container'>
            <div className='workguide-feature-img-container'>
                {feature.Icon &&
                    <feature.Icon style={styles.icon} />
                }
            </div>
            <div className='workguide-feature-title-container'>
                <Typography variant='body1' className='workguide-feature-title' style={matchTablet ? styles.titleContainer : {}}>
                    {feature.title}
                </Typography>
                <Divider className='workguide-feature-divider' variant='middle' />
            </div>
            <Typography variant='body1' className='workguide-feature-description'>
                {feature.description}
            </Typography>
        </div>
    )
}

export default WorkguideFeature