import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/styles';
import { isValidDate, textTransform } from '../../aux/aux';
import './meetingOccurrenceDate.css';

const MONTHS = [ 
    'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul',
    'aug', 'sep', 'oct', 'nov', 'dec'
]

const MeetingOccurrenceDate = ({ startsDate, fontDateSize='3em', fontMonthSize='1em', noDateSymbol='!', style={} }) => {
    const theme = useTheme();
    const { t } = useTranslation('common');
    const styles = {
        container: {
            height: '100px',
            width: '100px',
            backgroundColor: theme.palette.surface.light[0],
            ...style
        },
        date: {
            fontSize: fontDateSize,
            lineHeight: '1em',
            color: theme.palette.primary.main,
        },
        month: {
            fontSize: fontMonthSize,
        }
    }
    return (
        <div className='meeting-occurrence-date-main-container' style={styles.container}>
            <Typography style={styles.date}>
                {isValidDate(startsDate) ? 
                    startsDate.getDate()
                    :
                    noDateSymbol
                }
            </Typography>
            <Typography style={styles.month}>
                {isValidDate(startsDate) &&
                    t(MONTHS[startsDate.getMonth()]).toUpperCase()
                }
            </Typography>
        </div>
    )
}

export default MeetingOccurrenceDate