/**
 * Helpers functions related to Stripe
 */
 import { loadStripe } from '@stripe/stripe-js';
import { getReliableLocale } from './sessionHelpers';

 let stripePromise

 const stripeOptions = {
     locale:'es'
 }
 const getStripe = async (options=null) => {
    if(!options){
        const locale = getReliableLocale();
        options = {locale: locale.localeCode}
    }
    if (!stripePromise) {
        try{
            stripePromise = await loadStripe(process.env.REACT_APP_STRIPE_KEY, options);
        } catch(error){
            console.log(error)
        }
    }
    return stripePromise;
 }

 export {
     getStripe
 };