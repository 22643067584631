/**
 * This module contains all user related services
 */

import { requestMinimumTimeoutWrapper } from "../aux/requestMethods";
import { 
    getUserSystemsFn, createNewUserSystemFn, getUserDefaultSystemFn, getUserDefaultPaymentMethodFn,
    getUserProfileFn, updateUserBasicDataFn, updateUserBillingAddressAndCurrencyFn,
    deleteUserPaymentMethodFn, getUserContactDataFn, getUserAccessToProductDetailsCollectionFn, addProductToUserSubscriptionFn, getUserProductPurchasabilityDetailsCollectionFn, getUserProductConsumptionOptionMapFn, getUserSubscriptionFn, getUserProductConsumptionOptionFn, getUserTrakingTokenFn, postUserProductFeedbackFn, postUserProductNotificationFn, getUserEmailPreferencesFn,
    requestUpdateUserEmailFn,
    updateUserEmailByTokenFn
} from "../aux/userQueries";
import {
    createUserAccountFn,
    requestResetPasswordFn, requestVerifyAccountFn, resetPasswordFn, setNewUserPasswordFn, verifyAccountFn,
    verifyAccountPassiveFn, joinToVerifyAccountListFn, isUserInPassiveVerificationListFn 
} from '../aux/authQueries';

async function isUserInPassiveVerificationList(cipheredEmail, minimumTimeOut=0, signal=null){
    const params = {
        cipheredEmail
    };
    return requestMinimumTimeoutWrapper(isUserInPassiveVerificationListFn, params, minimumTimeOut, signal); 
}

async function requestVerifyAccount(cipheredEmail, passiveVerification, minimumTimeOut=0, signal=null){
    const params = {
        cipheredEmail,
        passiveVerification
    };
    return requestMinimumTimeoutWrapper(requestVerifyAccountFn, params, minimumTimeOut, signal);
}

async function verifyAccount(verificationToken, code, minimumTimeOut=0, signal=null){
    const params = {
        code,
        verificationToken
    };
    return requestMinimumTimeoutWrapper(verifyAccountFn, params, minimumTimeOut, signal)
}

async function joinToVerifyAccountList(cipheredEmail, minimumTimeOut=0, signal=null){
    const params = {
        cipheredEmail
    };
    return requestMinimumTimeoutWrapper(joinToVerifyAccountListFn, params, minimumTimeOut, signal)
}

async function verifyAccountPassive(verificationToken, minimumTimeOut=0, signal=null){
    const params = {
        verificationToken
    };
    return requestMinimumTimeoutWrapper(verifyAccountPassiveFn, params, minimumTimeOut, signal)
}

async function requestResetPassword(cipheredEmail, minimumTimeOut=0, signal=null){
    const params = {
        cipheredEmail
    };
    return requestMinimumTimeoutWrapper(requestResetPasswordFn, params, minimumTimeOut, signal);
}

async function resetPassword(resetPasswordToken, password, minimumTimeOut=0, signal=null){
    const params = {
        password,
        resetPasswordToken
    };
    return requestMinimumTimeoutWrapper(resetPasswordFn, params, minimumTimeOut, signal)
}

async function createUserAccount( user, consentArray=[], {countryCode, stateCode, currencyId}, minimumTimeOut=0, signal=null){
    const params = {
        user,
        consentArray,
        countryCode,
        stateCode,
    };
    return requestMinimumTimeoutWrapper(createUserAccountFn, params, minimumTimeOut, signal);
}

async function getUserProfile( userId, localeId=null, minimumTimeOut=0, signal=null){
    const params = {
        userId,
        localeId
    };
    return requestMinimumTimeoutWrapper(getUserProfileFn, params, minimumTimeOut, signal);
}

async function getUserEmailPreferences( userId, minimumTimeOut=0, signal=null){
    const params = {
        userId,
    };
    return requestMinimumTimeoutWrapper(getUserEmailPreferencesFn, params, minimumTimeOut, signal);
}

async function getUserSystems(userId, expand='', minimumTimeOut=0, signal=null){
    const params = {
        userId,
        expand
    };
    return requestMinimumTimeoutWrapper(getUserSystemsFn, params, minimumTimeOut, signal);
}

async function getUserDefaultPaymentMethod(userId, minimumTimeOut=0, signal=null){
    const params = {
        userId,
    };
    return requestMinimumTimeoutWrapper(getUserDefaultPaymentMethodFn, params, minimumTimeOut, signal);
}

async function getUserContactData(userId, minimumTimeOut=0, signal=null){
    const params = {
        userId,
    };
    return requestMinimumTimeoutWrapper(getUserContactDataFn, params, minimumTimeOut, signal);
}

async function deleteUserPaymentMethod(userId, paymentMethodId, minimumTimeOut=0, signal=null){
    const params = {
        userId,
        paymentMethodId
    };
    return requestMinimumTimeoutWrapper(deleteUserPaymentMethodFn, params, minimumTimeOut)
}

async function getUserDefaultSystem(userId, expand='', minimumTimeOut=0){
    const params = {
        userId,
        expand
    };
    return requestMinimumTimeoutWrapper(getUserDefaultSystemFn, params, minimumTimeOut);
}

async function createNewUserSystem(userId, system, minimumTimeOut=0, signal=null){
    const params = {
        userId,
        system
    };
    return requestMinimumTimeoutWrapper(createNewUserSystemFn, params, minimumTimeOut, signal);
};

async function updateUserBasicData(userId, updatedValues, minimumTimeOut=0, signal=null){
    const params = {
        userId,
        updatedValues
    };
    return requestMinimumTimeoutWrapper(updateUserBasicDataFn, params, minimumTimeOut, signal);
};


async function getUserSubscription(userId, localeId, signal=null){
    const requestParameters = {
        userId,
        localeId
    };
    return requestMinimumTimeoutWrapper(getUserSubscriptionFn, requestParameters, 0, signal)
}

async function requestUpdateUserEmail(userId, password, newEmail, minimumTimeOut=0, signal=null){
    const params = {
        userId,
        password,
        newEmail
    };
    return requestMinimumTimeoutWrapper(requestUpdateUserEmailFn, params, minimumTimeOut, signal);
}

async function updateUserEmailByToken(updateEmailToken, minimumTimeOut=0, signal=null){
    const params = {
        updateEmailToken
    };
    return requestMinimumTimeoutWrapper(updateUserEmailByTokenFn, params, minimumTimeOut, signal);
}

async function setNewUserPassword(userId, password, newPassword, minimumTimeOut=0, signal=null){
    const params = {
        userId,
        body:{
            password,
            newPassword
        }
    };
    return requestMinimumTimeoutWrapper(setNewUserPasswordFn, params, minimumTimeOut, signal);
};

// const updateCheckoutBillingAddressAndCurrencyFn = async (params) => {
//     const {
//         userId,
//         checkoutId,
//         billingAddressAndCurrency,
//     } = params
//     if(billingAddressAndCurrency.stateCode === ""){
//         billingAddressAndCurrency.stateCode = null;
//     }
//     const userRequest = createAuthRequest();
//     return userRequest.post(`/checkout/update-checkout-billing-address-and-currency/${userId}&${checkoutId}`, { billingAddressAndCurrency });    
// }
const updateUserBillingAddressAndCurrency = async(userId, billingAddressAndCurrency, minimumTimeout=2000, signal=null) => {
    const requestParams = {
        userId,
        billingAddressAndCurrency,
    }
    return requestMinimumTimeoutWrapper(updateUserBillingAddressAndCurrencyFn, requestParams, minimumTimeout, signal);
}

const getUserProductPurchasabilityDetailsCollection = async(userId, productDetailsArray, asMap=true, minimumTimeout=0, signal=null) => {
    const requestParams = {
        userId,
        productDetailsArray,
        asMap
    };
    return requestMinimumTimeoutWrapper(getUserProductPurchasabilityDetailsCollectionFn, requestParams, minimumTimeout, signal);
}


async function getUserProductConsumptionOptionMap(userId, productDetails, localeId, quantity, minimumTimeOut=0, signal=null){
    const params = {
        userId,
        productDetails:{
            productId: productDetails.productId,
            editionNum: productDetails.editionNum
        },
        localeId,
        quantity
    };
    return requestMinimumTimeoutWrapper(getUserProductConsumptionOptionMapFn, params, minimumTimeOut, signal);
}

async function getUserProductConsumptionOption(userId, productDetails, localeId, quantity, minimumTimeOut=0, signal=null){
    const params = {
        userId,
        productDetails:{
            productId: productDetails.productId,
            editionNum: productDetails.editionNum
        },
        localeId,
        quantity
    };
    return requestMinimumTimeoutWrapper(getUserProductConsumptionOptionFn, params, minimumTimeOut, signal);
}


const getUserAccessToProductDetailsCollection = async(userId, productDetailsArray, asMap=true, minimumTimeout=0, signal=null) => {
    const requestParams = {
        userId,
        productDetailsArray,
        asMap
    };
    return requestMinimumTimeoutWrapper(getUserAccessToProductDetailsCollectionFn, requestParams, minimumTimeout, signal);
}

const addProductToUserSubscription = async(userId, productDetails, addDetails, minimunTimeout=0, signal=null) => {
    const requestParams = {
        userId,
        productDetails,
        ...addDetails
    };
    return requestMinimumTimeoutWrapper(addProductToUserSubscriptionFn, requestParams, minimunTimeout, signal);
}

function getUserTrakingToken(userId, itemIdArray=[], expiresIn=undefined, minimumTimeOut=0, signal=null){
    const requestParameters = {
        userId,
        itemIdArray,
        expiresIn
    }
    return requestMinimumTimeoutWrapper( getUserTrakingTokenFn, requestParameters, minimumTimeOut, signal);
}

function getUserFavoriteProductArray(userId, productIdentifierArray, minimumTimeOut=0, signal=null){
    const requestParameters = {
        opId:'get',
        userId,
        productFeedbackMap: {
            favorite: productIdentifierArray
        }
    }
    return requestMinimumTimeoutWrapper( postUserProductFeedbackFn, requestParameters, minimumTimeOut, signal);
}

function createUserFavoriteProductArray(userId, favoriteProductArray, minimumTimeOut=0, signal=null){
    const requestParameters = {
        opId:'update',
        userId,
        productFeedbackMap: {
            favorite: favoriteProductArray
        },
    }
    return requestMinimumTimeoutWrapper( postUserProductFeedbackFn, requestParameters, minimumTimeOut, signal);
}

function removeUserFavoriteProductArray(userId, favoriteProductArray, minimumTimeOut=0, signal=null){
    const requestParameters = {
        opId:'update',
        userId,
        productFeedbackMap: {
            unFavorite: favoriteProductArray
        }
    }
    return requestMinimumTimeoutWrapper( postUserProductFeedbackFn, requestParameters, minimumTimeOut, signal);
}

function getUserProductNotificationArray(userId, productIdentifierArray, minimumTimeOut=0, signal=null){
    const requestParameters = {
        opId:'get',
        userId,
        productNotificationMap: {
            notify: productIdentifierArray
        }
    }
    return requestMinimumTimeoutWrapper( postUserProductNotificationFn, requestParameters, minimumTimeOut, signal);
}

function createUserProductNotificationArray(userId, productNotificationArray, minimumTimeOut=0, signal=null){
    const requestParameters = {
        opId:'update',
        userId,
        productNotificationMap: {
            notify: productNotificationArray
        },
    }
    return requestMinimumTimeoutWrapper( postUserProductNotificationFn, requestParameters, minimumTimeOut, signal);
}

function removeUserProductNotificationArray(userId, productNotificationArray, minimumTimeOut=0, signal=null){
    const requestParameters = {
        opId:'update',
        userId,
        productNotificationMap: {
            unNotify: productNotificationArray
        }
    }
    return requestMinimumTimeoutWrapper( postUserProductNotificationFn, requestParameters, minimumTimeOut, signal);
}

export{
    createUserAccount,
    getUserProfile,
    getUserSubscription,
    getUserDefaultPaymentMethod,
    getUserContactData,
    deleteUserPaymentMethod,
    updateUserBasicData,
    requestUpdateUserEmail,
    updateUserEmailByToken,
    setNewUserPassword,
    getUserSystems,
    getUserEmailPreferences,
    getUserDefaultSystem,
    createNewUserSystem,
    requestResetPassword,
    resetPassword,
    requestVerifyAccount,
    verifyAccount,
    joinToVerifyAccountList,
    verifyAccountPassive,
    updateUserBillingAddressAndCurrency,
    isUserInPassiveVerificationList,
    getUserAccessToProductDetailsCollection,
    getUserProductPurchasabilityDetailsCollection,
    getUserProductConsumptionOptionMap,
    getUserProductConsumptionOption,
    addProductToUserSubscription,
    getUserTrakingToken,
    getUserFavoriteProductArray,
    createUserFavoriteProductArray,
    removeUserFavoriteProductArray,
    getUserProductNotificationArray,
    createUserProductNotificationArray,
    removeUserProductNotificationArray
}