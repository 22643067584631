import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getFromSafeObject, getSafeDateOrUndefined, isNotEmptyObject, textTransform } from '../../aux/aux'
import BasicProductConsumptionOptionGroupItem from '../BasicProductConsumptionOptionGroupItem/BasicProductConsumptionOptionGroupItem'
import BasicGroupItemConfigSelector from '../BasicGroupItemConfigSelector/BasicGroupItemConfigSelector'
import SubscriptionUpgradeConsumptionConfigSelectorCollapsedItem from '../SubscriptionUpgradeConsumptionConfigSelectorCollapsedItem/SubscriptionUpgradeConsumptionConfigSelectorCollapsedItem'
import './subscriptionUpgradeConsumptionOptionGroupItem.css'
import SubscriptionUpgradeConsumptionConfigSelectorItem from '../SubscriptionUpgradeConsumptionConfigSelectorItem/SubscriptionUpgradeConsumptionConfigSelectorItem'
import { Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { fromCentTo2dec } from '../../aux/purchaseHelpers'

const SubscriptionUpgradeConsumptionOptionGroupItem = ({groupItemIndex, productConsumptionType, userProductPurchasabilityDetailsMap, isSelected, isSelectedProductConsumptionType, isRecommended, isUnique, onSelectedGroup, selectedItem, onSelectedItem, stylesMap=undefined}) => {
    const { t } = useTranslation('common')
    const theme = useTheme()
    const [disableGroup, setDisableGroup] = useState(null);
    const [warningMessage, setWarningMessage] = useState(null);
    const styles = {
        detailsContainer:{
            color:theme.palette.grey[500]
        }
    }
    useEffect(() => {
        const getWarningMessage = (groupSimplifiedPurchasabilityDetails) => {
            const noPurchasabilityCause = getFromSafeObject(groupSimplifiedPurchasabilityDetails, 'details.noPurchasabilityType');
            let warningMessage = null;
            if(noPurchasabilityCause){
                switch(noPurchasabilityCause){
                    case 'wrongSubscriptionUpgradeProduct':
                        warningMessage = textTransform('title', t('subscriptionUpgradeConsumptionConfigSelectorItem.noPurchasabilityCause.incompatibleActiveSubscription'));
                        break;
                    case 'noActiveSubscription':
                        warningMessage = textTransform('title', t('subscriptionUpgradeConsumptionConfigSelectorItem.noPurchasabilityCause.noActiveSubscription'));
                        break;
                    case 'maxQuantity':
                    case 'unknownUserSubscription':
                    default:
                        warningMessage = textTransform('title', t('cannotPurchaseThis'));
                }
            }
            return warningMessage;
        }
        if(productConsumptionType && isNotEmptyObject(userProductPurchasabilityDetailsMap)){
            const productConsumptionOptionArray = productConsumptionType.productConsumptionOptionArray || [];
            let noPurchasableOption = true;
            let noPurchasableFirstUserDetailsByCause= {}
            for(let productConsumptionOption of productConsumptionOptionArray){
                const userProductPurchasabilityDetails = userProductPurchasabilityDetailsMap[productConsumptionOption.id]; 
                if(!userProductPurchasabilityDetails.isPurchasable){
                    const noPurchasabilityCause = getFromSafeObject(userProductPurchasabilityDetails, 'details.noPurchasabilityType');
                    if(!noPurchasableFirstUserDetailsByCause.hasOwnProperty(noPurchasabilityCause)){
                        noPurchasableFirstUserDetailsByCause[noPurchasabilityCause] = userProductPurchasabilityDetails;
                    }
                }else{
                    noPurchasableOption = false;
                    break
                }
            }
            if(noPurchasableOption){
                let groupSimplifiedUserProductPurchasabilityDetails;
                if(Object.keys(noPurchasableFirstUserDetailsByCause).length > 1){
                    groupSimplifiedUserProductPurchasabilityDetails = { details: { noPurchasabilityType: 'noUnifiedCause'} };
                }else{
                    groupSimplifiedUserProductPurchasabilityDetails = (Object.values(noPurchasableFirstUserDetailsByCause))[0]
                }
                const warningMessage = getWarningMessage(groupSimplifiedUserProductPurchasabilityDetails)
                setWarningMessage(warningMessage)
            }
            setDisableGroup(noPurchasableOption);
        }else{
            setDisableGroup(null);
        }
    },[productConsumptionType, userProductPurchasabilityDetailsMap])

    return (
        <BasicProductConsumptionOptionGroupItem
            groupItemIndex={groupItemIndex}
            productConsumptionType={productConsumptionType}
            userProductPurchasabilityDetailsMap={userProductPurchasabilityDetailsMap}
            disabled={disableGroup}
            isSelectedProductConsumptionType={isSelectedProductConsumptionType}
            isSelected={isSelected}
            isRecommended={isRecommended}
            isUnique={isUnique}
            selectedItem={selectedItem}
            onSelectedGroup={onSelectedGroup}
            onSelectedItem={onSelectedItem}
            warningMessage={warningMessage}
            stylesMap={stylesMap}
            ConfigSelectorComponent={BasicGroupItemConfigSelector}
            ConfigSelectorCollapsedItemComponent={SubscriptionUpgradeConsumptionConfigSelectorCollapsedItem}
            ConfigSelectorItemComponent={SubscriptionUpgradeConsumptionConfigSelectorItem}
        >
            {isSelected && selectedItem && !disableGroup ?
                <div className='subscription-upgrade-consumption-option-group-item-details-container' style={styles.detailsContainer}>
                    <Typography variant='caption'>
                        {`* ` + t('subscriptionUpgradeConsumptionOptionGroupItem.upgradesYourActiveTerm', {
                                subscriptionTypeName: getFromSafeObject(selectedItem, 'typeProduct.toSubscriptionProduct.typeProduct.type.name'),
                                currentTermEndDate: getSafeDateOrUndefined(getFromSafeObject(userProductPurchasabilityDetailsMap,`[${selectedItem.id}].details.userSubscription.details.lastActivatedUserSubscriptionProduct.endsDate`)), 
                                interpolation:{escapeValue: false},
                                formatParams:{
                                    currentTermEndDate:{ year: 'numeric', month: 'long', day: 'numeric' }
                                }
                            })
                            + '. ' +
                            t('subscriptionUpgradeConsumptionOptionGroupItem.regularPriceAfterThat', {
                                toSubscriptionFinalAmount: fromCentTo2dec(getFromSafeObject(selectedItem, 'typeProduct.toSubscriptionProduct.consumptionDetails.details.purchasabilityDetails.details.amount.finalAmount')),
                                toSubscriptionTermName: getFromSafeObject(selectedItem, 'typeProduct.toSubscriptionProduct.typeProduct.term.translation.nameTranslated'),
                                formatParams:{
                                    toSubscriptionFinalAmount:{
                                        currency: getFromSafeObject(selectedItem, 'typeProduct.toSubscriptionProduct.consumptionDetails.details.purchasabilityDetails.details.amount.currencyId'),
                                        trailingZeroDisplay:'stripIfInteger'
                                    }
                                },
                            })
                        }
                    </Typography>
                </div>
                :
                null
            }
        </BasicProductConsumptionOptionGroupItem>
    )
}

export default SubscriptionUpgradeConsumptionOptionGroupItem