import { useTheme } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import { getFromSafeObject, isNotEmptyObject, textTransform } from '../../aux/aux'
import './socialCommentItem.css'
import ImgDN from '../../cdn/imgDN'
import cloudinary from '../../cdn/providers/cloudinary'
import { useImgURL } from '../../customHooks/useImgURL'
import SocialProfileBadge from '../SocialProfileBadge/SocialProfileBadge'
import StyledTypography from '../StyledTypography/StyledTypography'

const SocialCommentItem = ({ comment, ...props }) => {
    const BADGE_SIZE_PX = 62;
    const theme = useTheme()
    const styles = {
        title:{
            fontWeight:'bold'
        },
        comment:{
            fontStyle: 'italic'
        },
        name:{
            lineHeight: '1rem'
        },
        position:{
            color: theme.palette.grey[400],
        },
        badgeMainContainer:{
            border: 'none'
        }
    }

    return (
        isNotEmptyObject(comment) ? 
            <div className='social-comment-item-main-container'>
                <div className='social-comment-item-message-container'>
                    <StyledTypography variant='body1' style={styles.title} {...props.title}>
                        {comment.title}
                    </StyledTypography>
                    <StyledTypography variant='body1' style={styles.comment} {...props.comment}>
                        {comment.comment}
                    </StyledTypography>
                </div>
                {isNotEmptyObject(comment.user) ?
                    <div className='social-comment-item-user'>
                        <div className='social-comment-item-user-img-container'>
                            <SocialProfileBadge 
                                uri={comment.user.imgURI} 
                                charToDisplay={comment.user.name[0] || null}
                                diameterPx={BADGE_SIZE_PX}
                                style={styles.badgeMainContainer} 
                                {...props.profileBadge} 
                            />
                        </div>
                        <div className='social-comment-item-user-name-position-container'>
                            <StyledTypography variant='body1' style={styles.name} {...props.name}>
                                {comment.user.name}
                            </StyledTypography>
                            <StyledTypography variant='body2' style={styles.position} {...props.position}>
                                {`${textTransform('title', comment.user.position)}`}
                            </StyledTypography>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
            :
            null
    )
}

export default SocialCommentItem