import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { textTransform } from '../../aux/aux';

import PopUpModal from '../PopUpModal/PopUpModal'
import PopUpModalHeader from '../PopUpModalHeader/PopUpModalHeader';
import SimpleNoModalDialog from '../SimpleNoModalDialog/SimpleNoModalDialog'
import UserMeetingBadge from '../UserMeetingBadge/UserMeetingBadge';

import DeleteIcon from '@mui/icons-material/Delete';
import './popUpMeetingOccurrenceUnregister.css'
import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper';

const PopUpMeetingOccurrenceUnregister = ({ show, userMeeting, userMeetingStatus, userMeetingStatusArray, onUnregister, onClickClose, header, modal=true }) => {
    const POP_UP_WIDTH= 380;
    const { t } = useTranslation('common', { keyPrefix: "popUpMeetingOccurrenceUnregister"});
    const [isUnregistering, setIsUnregistering] = useState(false);
    const [isError, setIsError] = useState(false);
    const abortController = new AbortController();
    const abortControllerRef = useRef(null);
    const errorTimeoutRef = useRef(null);

    const styles = {
        dialogContainer:{
            padding: '20px'
        }
    }
    const handleOnAgree = async (e, signal=abortControllerRef.current.signal) => {
        try{
            setIsUnregistering(true);
            await onUnregister(userMeeting.meetingOccurrenceId, true, signal);
            if(modal){
                onClickClose()
            }
        }catch(err){
            if(!signal.aborted){
                setIsError(true);
            }
        }finally{
            if(!signal.aborted){
                setIsUnregistering(false);
            }
        }
    }

    const clearComponent = () => {
        setIsUnregistering(false);
        setIsError(false);
    }

    useEffect(() => {
        if(isError){
            errorTimeoutRef.current = setTimeout(() => (setIsError(false)), 3000)
        }
    },[isError])

    useEffect(() => {
        if(modal){
            if(show){
                abortControllerRef.current = abortController;
                return(() => {
                    clearTimeout(errorTimeoutRef.current);
                    abortControllerRef.current.abort();
                })
            }else{
                clearComponent()
            }
        }else{
            abortControllerRef.current = abortController;
            return(() => {
                clearTimeout(errorTimeoutRef.current);
                abortControllerRef.current.abort();
            })
        }
    },[show, modal])

    return (
        <ConditionalWrapper
            condition={modal}
            wrapperFn={ children => 
                <PopUpModal 
                    showModal={show} 
                    onClickClose={onClickClose} 
                    width={POP_UP_WIDTH}
                    isDialog={true}
                >
                    {children}
                </PopUpModal>
            }
        >
                {header && 
                    <PopUpModalHeader
                        title={`${textTransform('title', header)}`}
                        Icon={DeleteIcon} 
                    />
                }
                <SimpleNoModalDialog
                    style={styles.dialogContainer}
                    onAgree={handleOnAgree}
                    onDisagree={onClickClose}
                    contentText={`¿${textTransform('title', t('wanToCancelAttendance'))}?`}
                    isLoading={ isUnregistering }
                    isDisabled={ isUnregistering || userMeetingStatus != userMeetingStatusArray[3] }
                    isError={ isError }
                    errorMsg={ 
                        `${textTransform('title', t('unregistrationError'))}.`
                        +`\n${textTransform('title',t('tryLater'))}` 
                    }
                >
                    <UserMeetingBadge
                        userMeeting={userMeeting} 
                        userMeetingStatus={userMeetingStatus}
                        userMeetingStatusArray={userMeetingStatusArray}
                    />

                </SimpleNoModalDialog>
        </ConditionalWrapper>
    )
}

export default PopUpMeetingOccurrenceUnregister