import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Typography } from '@material-ui/core';
import PopUpModal from '../PopUpModal/PopUpModal'
import PopUpModalHeader from '../PopUpModalHeader/PopUpModalHeader'
import FormControlWrapper from '../FormControlWrapper/FormControlWrapper';
import ContactSupportFormFields from '../ContactSupportFormFields/ContactSupportFormFields';
import ToggleMsg from '../ToggleMsg/ToggleMsg';
// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import successAnimation from '../../lotties/success.json';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import './popUpContactSupport.css'
import { sendSupportRequest } from '../../services/visitorServices';
import { getReliableCurrencyId, getReliableLocale } from '../../aux/sessionHelpers';
import { textTransform } from '../../aux/aux';

const PopUpContactSupport = ({ user, showModal, onClickClose, height, width, categoryObject={ defaultKey:undefined, categoryArray:undefined }, supportContext={} }) => {
    const { t } = useTranslation('common');
    const locale = getReliableLocale();
    const currencyId = getReliableCurrencyId();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [submitError, setSubmitError] = useState({});
    const [animationContainer, setAnimationContainer] = useState(null);
    const animationRef = useRef(null);
    const handleOnSubmitForm = async (e, signal) => {
        try{
            e.preventDefault()
            setIsSubmitting(true)
            const request = {
                ...formValues,
                context: {
                    origin: window.location.origin,
                    path: window.location.pathname,
                    localeCode: locale.localeCode,
                    currencyId,
                    ...supportContext 
                }
            }
            await sendSupportRequest(formValues.type, request, 1000, signal);
            if(!signal.aborted){
                setIsSubmitting(false)
                setIsSubmitted(true);
            }
        }catch(error){
            if(!signal.aborted){
                setIsSubmitting(false)
                setSubmitError({
                    isShown: true,
                    type: 'error',
                    msg: `${textTransform('title', t('popUpContactSupport.cannotSubmitTheForm'))}.\n${textTransform('title', t('popUpContactSupport.tryItLater'))}`
                })
            }
        }
    }

    useEffect(() => {
        if(!showModal){
            setSubmitError({})
            setIsSubmitting(false);
            setIsSubmitted(false);
            setAnimationContainer(null);
        }
    },[showModal])

    useEffect(() => {
        if(isSubmitted){
            const timeoutId = setTimeout(() => onClickClose(), 5000);
            return(() => {
                clearTimeout(timeoutId)
            })
        }
    },[isSubmitted])

    useEffect(() => {
        if(animationContainer != null){
            animationRef.current = lottie.loadAnimation({
                container: animationContainer,
                renderer: 'svg',
                loop: false,
                autoplay: true,
                animationData: successAnimation
            });
            return(() => {
                animationRef.current?.stop();
                animationRef.current?.destroy()
            })
        }
    },[animationContainer])

    return (
        <PopUpModal showModal={showModal} onClickClose={onClickClose} height={height} width={width}>
            <PopUpModalHeader 
                Icon={LiveHelpIcon} 
                title={t('popUpContactSupport.askToOutTeam')} 
            />
            <Divider variant='middle'/>
            {isSubmitted ? 
                <div className='pop-up-contact-support-form-submited-container'>
                    <div className='pop-up-contact-support-form-submitted-animation' ref={setAnimationContainer}/>
                    <div className='pop-up-contact-support-form-submitted-msg'>
                        <Typography variant='body1'>
                            {
                                `${textTransform('title', t('popUpContactSupport.yourRequestWasSuccessfullySent'))}. `
                                +`${textTransform('title', t('popUpContactSupport.youWillHearFormUsSoon'))}.\n`
                                +`${textTransform('title', t('thankYou'))}`
                            }
                        </Typography>
                    </div>
                </div>
            :
                <>
                    <FormControlWrapper 
                        className='pop-up-contact-support-form-wrapper' 
                        onSubmit={handleOnSubmitForm}
                        buttonLabel={t("send")}
                        buttonContainerClassName='pop-up-contact-support-form-button-container'
                        buttonProps={{
                            color:'primary', 
                            variant:'contained',
                            isLoading: isSubmitting,
                            size:'large'
                        }}
                    >
                        <ContactSupportFormFields 
                            user={user} 
                            isDisabled={isSubmitting} 
                            resetForm={!showModal} 
                            onUpdateForm={(form) => setFormValues(form)}
                            categoryObject={categoryObject}
                            supportContext={supportContext}
                        />
                    </FormControlWrapper>
                    <ToggleMsg 
                        extraClassName='pop-up-contact-support-toggle-msg'
                        absolutePositon={true}
                        isShown={submitError.isShown === true} 
                        type={submitError.type}
                        onShowTime={() => {
                            setSubmitError(prev => ({...prev, isShown:false}))
                        }}
                        msg={submitError.msg}
                    />
                </>
            }
        </PopUpModal>
    )
}

export default PopUpContactSupport