import { useState, useEffect, forwardRef } from 'react';
import { Button } from '@material-ui/core'
// import Button from '@mui/material/Button'
import { CircularProgress } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

// Style
import './buttonLoading.css';
import { BorderColor } from '@material-ui/icons';


const ButtonLoading = ({isLoading, label, disabled=false, onDark=false, noLabelWhileLoading=false, ...props}, ref) => {
    const theme = useTheme();
    const [buttonLabel, setButtonLabel] = useState(null);
    const styles = {
        circularProgress:{
            color: props.hasOwnProperty("color") ? theme.palette[props.color].main: theme.palette.primary.main,
        },
        button: onDark && (disabled || isLoading) ? 
            {
                ...(props.variant === 'contained' ? 
                        {
                            backgroundColor: theme.palette.action.disabledBackgroundOnDark,
                        }
                    :
                        props.variant === 'outlined' ?
                            {
                                borderColor: theme.palette.action.disabledBackgroundOnDark,
                            }
                        :
                            {}
                ),
                color: theme.palette.action.disabledOnDark
            }
            :
            {}
    };
    useEffect(() => {
        if(noLabelWhileLoading){
            if(isLoading){
                setButtonLabel(null);
            }else{
                const timeoutId = setTimeout(() => setButtonLabel(label), 300);
                return(() => clearTimeout(timeoutId))
            }
        }else{
            setButtonLabel(label);
        }
    },[isLoading, noLabelWhileLoading, label])
    return (
        <Button ref={ref} disabled={disabled || isLoading} style={{...props.style, ...styles.button}} {...props}>
            {buttonLabel}
            <div className={`button-loading-progress-container-hidden ${isLoading && 'button-loading-progress-container-active'}`}>
                <CircularProgress size="2em" style={styles.circularProgress}  />
            </div>
        </Button>
    )
}

export default forwardRef(ButtonLoading)
