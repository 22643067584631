import { useEffect } from 'react';
import { useState } from 'react'
import BackButton from '../BackButton/BackButton';
import LogInForm from '../LogInForm/LogInForm'
import VerifyAccountForm from '../VerifyAccountForm/VerifyAccountForm';
import './logInAndVerifyForm.css'

const LogInAndVerifyForm = ({ onLogin=async()=>{}, onRender=()=>{}, onFormTypeChange=()=>{}, isLoading=()=>{}, formValues={}, autoLogin=false, forceVerification=false, passiveVerification=true }) => {
    const [needsVerification, setNeedsVerification] = useState(forceVerification);
    const [triggerLogin, setTriggerLogin] = useState(autoLogin);
    const [logInForm, setLogInForm] = useState(formValues);

    const handleOnNeedsAccountVerification = (email, password) => {
        setLogInForm({ email, password });
        setNeedsVerification(true);
    }

    const handleOnVerificateUser = () => {
        setNeedsVerification(false);
        setTriggerLogin(true);

    }

    const handleOnCancelVerification = () => {
        setTriggerLogin(false);
        setNeedsVerification(false);
    }

    useEffect(() => {
        onFormTypeChange(needsVerification ? 'verify' : 'login')
    },[needsVerification])

    return (
        needsVerification === true ? 
                <div className='login-and-verify-form-container-animation'>
                    <BackButton isDisabled={false} onClick={handleOnCancelVerification} />
                    <VerifyAccountForm 
                        onRender={onRender} 
                        isLoading={isLoading} 
                        onVerified={handleOnVerificateUser} 
                        account={{ email: logInForm.email }} 
                        passiveVerification={passiveVerification}
                    />
                </div>
            :
                <LogInForm onLogin={onLogin} onRender={onRender} isLoading={isLoading} formValues={logInForm} autoLogin={triggerLogin} onNoVerifiedAccount={handleOnNeedsAccountVerification} />
  )
}

export default LogInAndVerifyForm