import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, TextField, Input, IconButton, Icon, Popover, Typography } from '@material-ui/core'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CustomPopover from '../CustomPopover/CustomPopover';
import { useTheme } from '@material-ui/styles'
import './copyToClipboard.css'
import { textTransform, textTruncate } from '../../aux/aux';
import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper';

const CopyToClipboard = ({ textToCopy, clickInIcon=false, type='url', textTruncateLength=25, inputStyle={}, inputContainerStyle={}, buttonStyle={}, iconStyle={}, customPopoverProps={}, }) => {
  const theme = useTheme();
  const { t } = useTranslation('common', { keyPrefix: 'copyToClipboard' });
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const popoverRef = useRef(null);
  const styles = {
    inputContainer: {
      fontFamily: theme.typography.body1.fontFamily,
      fontSize: theme.typography.body1.fontSize,
      color: theme.palette.primary.main,
      ...inputContainerStyle,
      ...(clickInIcon ? {} : { cursor: 'pointer'}),
    },
    input:{
      color: theme.palette.primary.main,
      ...inputStyle,
      ...(clickInIcon ? {} : { cursor: 'pointer'}),
    },
    icon: {
      ...iconStyle,
      ...(clickInIcon ? {} : { 
        marginLeft: '15px',
      } )
    }
  }
  const handleOnClick = async (e) => {
    e.stopPropagation();
    if(navigator.clipboard && window.isSecureContext){
      await navigator.clipboard.writeText(textToCopy)
      setIsPopoverOpen(true);
    }else{
      const textarea = document.createElement('textarea');
      textarea.name = 'aux-copy';
      textarea.value = textToCopy;
      // Move the textarea outside the viewport to make it invisible
      textarea.style.position = 'absolute';
      textarea.style.left = '-99999999px';

      document.body.prepend(textarea);

      // highlight the content of the textarea element
      textarea.select();
      try{
        document.execCommand('copy');
        setIsPopoverOpen(true);
      }catch(error){
        console.warn("Copy to clipboard failed.", error);
      }finally{
        textarea.remove();
      }
    }
  }

  const handleOnClose = () => {
    setIsPopoverOpen(false);
  }

  return (
    <div 
      className={`copy-to-clipboard-main-container`}
      onClick={ clickInIcon ? undefined : handleOnClick } 
      ref={ clickInIcon ? undefined : popoverRef }
    >
      <CustomPopover 
      open={isPopoverOpen} 
      anchorEl={popoverRef} 
      onClose={handleOnClose} 
      anchorOrigin={{vertical:'top', horizontal:'center'}} 
      transformOrigin={{vertical:'bottom', horizontal:'center'}}
      {...customPopoverProps}
      >
        <Typography variant='body2'>
          {textTransform('title', t('copyIt'))}
        </Typography>
      </CustomPopover>
      <Input
        type={type}
        readOnly
        value={textTruncate(textToCopy, textTruncateLength)}
        variant="outlined"
        style={styles.inputContainer}
        disableUnderline
        fullWidth
        inputProps={{style:styles.input}}
        className='copy-to-clipboard-edit-input'
        endAdornment={
          <ConditionalWrapper 
            condition={clickInIcon}
            wrapperFn={(children) => 
              <IconButton 
                ref={clickInIcon ? popoverRef : undefined} 
                size='small' 
                color='primary' 
                onClick={ clickInIcon ? handleOnClick : undefined } 
                className='copy-to-clipboard-edit-input-button'
                style={buttonStyle}
              >
                {children}
              </IconButton>
            }
          >
            <ContentCopyIcon className='copy-to-clipboard-edit-input-button-icon' style={styles.icon}/>  
          </ConditionalWrapper>
        }
      >
      </Input>
    </div>
  )     
}

export default CopyToClipboard