import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/styles';
import { Divider, Grid, Typography, Box } from '@material-ui/core';
import { isEmptyObject, isNotEmptyObject, textTransform } from '../../aux/aux';
import { 
    getCheckoutBillabilityFn,
} from '../../aux/purchaseQueries';
import {
    getOpenCheckoutId, clearCheckout, createCheckout, updateCheckoutBillingAddressAndCurrency
} from '../../aux/purchaseHelpers';
import {
    getUserProductConsumptionOptionMap,
    updateUserBillingAddressAndCurrency
} from '../../services/userServices';
import { requestMinimumTimeoutWrapper } from '../../aux/requestMethods';
import { sessionVariables } from '../../aux/sessionHelpers';
import CheckoutItems from '../CheckoutItems/CheckoutItems';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import CheckoutDetails from '../CheckoutDetails/CheckoutDetails';
import ButtonLoading from '../ButtonLoading/ButtonLoading';
import BillingAddressEditForm from '../BillingAddressEditForm/BillingAddressEditForm';
import CheckoutBillingAddressEdit from '../CheckoutBillingAddressEdit/CheckoutBillingAddressEdit';
import { useUpdateCountryAndCurrencyCtx } from '../../customHooks/useUpdateCountryAndCurrencyCtx';
import { getProductConsumptionOptionMap } from '../../services/visitorServices';
import { useCountryAndCurrencyCtx } from '../../customHooks/useCountryAndCurrencyCtx';
import { useLocaleCtx } from '../../customHooks/useLocaleCtx';
import { getProductConsumptionStatusDetails, isSubscriptionOrUpgradeSubscriptionProductTypeName } from '../../aux/productHelpers';
import SelectProductConsumptionOption from '../SelectProductConsumptionOption/SelectProductConsumptionOption';
import BackButton from '../BackButton/BackButton';
import { getCustomErrorOrUndefined } from '../../aux/errorHelpers';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import './fastCheckoutConfig.css';
import ToggleMsg from '../ToggleMsg/ToggleMsg';

const FastCheckoutConfig = ({ 
    user, productIdentifier, quantity, handlerFastCheckoutContent, handlerFastCheckoutId, appliedPromoCode, handlerPurchaseStep,
    updateAndGetFastCheckoutContent, isRedirect, addMessages
}) => {
    const theme = useTheme();
    const localeCtx = useLocaleCtx()
    const fastCheckoutConfigStateTypes = ['loading','settingBillingAddress', 'showingCheckoutContent'];
    const { t } = useTranslation('common', { keyPrefix: "fastCheckoutConfig" });
    const updateCountryAndCurrencyCtx = useUpdateCountryAndCurrencyCtx();
    const [fastCheckoutId, setFastCheckoutId] = handlerFastCheckoutId;
    const [fastCheckoutContent, setFastCheckoutContent] = handlerFastCheckoutContent;
    const [purchaseStep, setPurchaseSet] = handlerPurchaseStep;
    const [isLoading, setIsLoading] = useState(false);
    const [isFastCheckoutBillable, setIsFastCheckoutBillable] = useState(null);   
    const countryAndCurrencyCtx = useCountryAndCurrencyCtx();
    const [productConsumptionOptionMap, setProductConsumptionOptionMap] = useState(null);
    const [confirmedSelectedProductOption, setConfirmedSelectedProductOption] = useState(null);
    const [selectedProductConsumptionTypeName, setSelectedProductConsumptionTypeName] = useState(null);
    const [selectedProductConsumptionOption, setSelectedProductConsumptionOption] = useState(null);
    const [userProductPurchasabilityDetailsByIdMap, setUserProductPurchasabilityDetailsByIdMap] = useState(null);
    const [error, setError] = useState({isError:false, type:undefined, msg: undefined});
    const abortSignalRef = useRef(null);
    
    const styles = {
        icon: {
            display: 'block',
            color:theme.palette.warning.main,
        }
    }

    const requestUserProductConsumptionOptionMap = async(userId, productIdentifier, localeId, quantity, signal=abortSignalRef.current) => {
        try{
            setIsLoading(true);
            const res = await getUserProductConsumptionOptionMap(userId, productIdentifier, localeId, quantity , 0, signal);
            const {
                productConsumptionOptionMap,
                userProductPurchasabilityDetailsByIdMap
            } = res.data;
            if(!signal.aborted){
                setUserProductPurchasabilityDetailsByIdMap(userProductPurchasabilityDetailsByIdMap);
                setProductConsumptionOptionMap(productConsumptionOptionMap);
            }
        }catch(error){
            if(!signal.aborted){
                // Show error in SelectProductConsumptionOption component
                setUserProductPurchasabilityDetailsByIdMap({});
                setProductConsumptionOptionMap({});
            }
        }finally{
            setIsLoading(false);
        }
    }

    const getAClearedFastCheckoutId = async (userId, signal=abortSignalRef.current) => {
        try{
            setIsLoading(true);
            const checkoutType= 'fast';
            let checkoutId = await getOpenCheckoutId(userId, checkoutType, 0, signal);
            if(checkoutId){
                checkoutId = await clearCheckout(userId, checkoutId, 0, signal);
            }else{
                checkoutId = await createCheckout(userId, checkoutType, [], 0, signal);
            }
            if(!signal.aborted){
                setFastCheckoutId(checkoutId);
            }
        }catch(error){
            setError( prev => { 
                return({
                    isError: true,
                    type: 'retrieval',
                    msg: `${t('cannotConfigYourPurchaseNow')}. ${t('tryItLater')}`
                })
            });
        }finally{
            if(!signal.aborted){
                setIsLoading(false);
            }
        }
    }

    const getCheckoutBillability = async (checkoutId, userId, signal=abortSignalRef.current) => {
        try{
            setIsLoading(true)
            const requestParams = { checkoutId, userId};
            const res = await requestMinimumTimeoutWrapper(getCheckoutBillabilityFn, requestParams, 0, signal);
            const data = res.data;
            if(!signal.aborted){
                setIsFastCheckoutBillable(data.isBillable);
            }
        }catch(error){
            if(!signal.aborted){
                resetFastCheckoutConfig()
            }
        }finally{
            if(!signal.aborted){
                setIsLoading(false)
            }
        }
    };

    const updateBillingAddress = async (billingAddress, signal=abortSignalRef.current) => {
        try{
            // stateCode must be null or an alpha-2 code
            billingAddress.stateCode = billingAddress.stateCode === '' ? null : billingAddress.stateCode;
            const res = await updateUserBillingAddressAndCurrency(user.basicData.id, billingAddress, 0, signal);
            const {
                updatedValues
            } = res.data
            if(isNotEmptyObject(updatedValues) && fastCheckoutId){
                if(!signal.aborted){
                    const countryCode = updatedValues.countryCode;
                    const stateCode = updatedValues.stateCode;
                    const currencyId = updatedValues.currencyId;
                    sessionVariables.set('userCountryCode', countryCode);
                    sessionVariables.set('userStateCode', stateCode);
                    sessionVariables.set('userCurrencyId', currencyId);
                    updateCountryAndCurrencyCtx.setCountryAndCurrency({countryCode, stateCode, currencyId});
                    setIsFastCheckoutBillable(true);
                }
                // Commented on: 03/09
                // updateUserBillingAddressAndCurrency triggers the updating of all open user checkouts
                // const checkoutUpdateRes = await updateCheckoutBillingAddressAndCurrency(user.basicData.id, fastCheckoutId, billingAddress)
                // updatedData.checkoutRes = checkoutUpdateRes.data.billingAddress;
                // if(!isEmptyObject(updatedData.checkoutRes)){
                //     // TODO
                //     setIsFastCheckoutBillable(true);
                // }else{
                //     // TODO: implement
                //     throw new Error();
                // }
            }else{
                // TODO: implement
                throw new Error();
            }
        }catch(error){
            // It is handle on CheckoutBillingAddress
            throw error;
        }
    }

    const handleOnSelectProductConsumptionOptionType = (selectedTypeName) => {
        // setSelectedProductConsumptionOption(null);
        if(selectedProductConsumptionTypeName  && selectedProductConsumptionTypeName !== selectedTypeName){
            setError({ isError:false, type:undefined, msg:undefined })
        }
        setSelectedProductConsumptionTypeName(selectedTypeName);
    }

    const handleOnSelectProductConsumptionOption = (productOption) => {
        if(selectedProductConsumptionOption && productOption.id !== selectedProductConsumptionOption.id){
            setError({ isError:false, type:undefined, msg:undefined })
        }
        setSelectedProductConsumptionOption(productOption)
    }

    const handleOnConfirmSelectedProductConsumptionOption = (productConsumptionOption) => {
        setConfirmedSelectedProductOption(productConsumptionOption)
    }

    const resetFastCheckoutConfig = () => {
        setIsLoading(true);
        setError({isError:false, type: undefined, msg: undefined})
        setFastCheckoutContent(null);
        setConfirmedSelectedProductOption(null);
        setProductConsumptionOptionMap(null);               
        setUserProductPurchasabilityDetailsByIdMap(null); 
        setIsFastCheckoutBillable(null)
        setFastCheckoutId(null);
        setTimeout(() => setIsLoading(false), 1000)
    }
    const handleAddProductArrayAndGetFastCheckoutContent = async(checkoutProductArray, signal=abortSignalRef.current) => {
        try{
            setIsLoading(true);
            await handleAddProductToFastCheckoutContent(checkoutProductArray);
        }catch(error){
            if(!signal.aborted){
                setConfirmedSelectedProductOption(null);
                setProductConsumptionOptionMap(null);               // Reset available options from server
                setUserProductPurchasabilityDetailsByIdMap(null);   // Reset user purchasability from server
                setError( prev => { 
                    return({
                        isError: true,
                        type: 'add',
                        msg: t('errorAddingProductsToCheckout')
                    })
                });
            }
        }finally{
            if(!signal.aborted){
                setIsLoading(false)
            }
        }
    }

    const handleAddProductToFastCheckoutContent = async(checkoutProductArray) => {
        try{
            await updateAndGetFastCheckoutContent('add', { checkoutProductArray });
        }catch(error){
            const customError = getCustomErrorOrUndefined(error);
            if(customError && customError.payload){
                const {
                    checkoutProductPurchasabilityArray,
                    noPurchasableProductToAddIndexArray
                } = customError.payload;
                const errorMsgArray = [];
                for(let index of noPurchasableProductToAddIndexArray){
                    const checkoutProductPurchasability = checkoutProductPurchasabilityArray[index];
                    if(checkoutProductPurchasability){
                        const productConsumptionOption = productConsumptionOptionMap.all[checkoutProductPurchasability.productId]
                        if(productConsumptionOption){
                            errorMsgArray.push({
                                severity:'error',
                                message: textTransform('title', t("cannotAddToCheckout", {productName: productConsumptionOption.description.nameTranslated})),
                            })
                        }
                    }
                }
                addMessages(errorMsgArray)
            }
            throw error;
        }
    }
    const handleDeleteOrUpdateProductQuantityToFastCheckout = async(product, quantity) => {
        try{
            if(quantity !== product.quantity){
                const checkoutProduct = {
                    quantity,
                    productId: product.productId,
                    productTypeName: product.productTypeName
                };
                let opId, opParam;
                if(quantity == 0){
                    opId = 'delete';
                    opParam = { checkoutProductArray: [checkoutProduct] };
                }else{
                    opId ='productQuantity';
                    opParam = { checkoutProduct };
                }
                const data = await updateAndGetFastCheckoutContent(opId, opParam,);
            }
        }catch(error){
            // handle by the caller function
            throw error;
        }
    }

    const handleSwapProductsToFastCheckout = async(oldProductId, newProductId) => {
        try{
            const opParam = {
                oldProductId,
                newProductId
            }
            await updateAndGetFastCheckoutContent('swap', opParam) 
        }catch(error){
            // handle by the caller function
            throw error
        }
    }

    const handleGetFastCheckoutContent = async(signal=abortSignalRef.current) => {
        try{
            setIsLoading(true)
            await updateAndGetFastCheckoutContent('get', {});
        }catch(error){
            if(!signal.aborted){
                setError( prev => { 
                    return({
                        isError: true,
                        type: 'retrieval',
                        msg: `${t('cannotGetYourPurchaseNow')}. ${t('willReceiveAnEmailWithTheDetails')}`
                    })
                });
            }
        }finally{
            if(!signal.aborted){
                setIsLoading(false)
            }
        }
    }
    
    
    const handleApplyPromoCodeToCheckout = async(promoCodeId) => {
        try{
            const opParam = { promoCodeId }
            return updateAndGetFastCheckoutContent('applyPromoCode', opParam)
        }catch(error){
            throw error
        }
    }

    const handleTakeOutPromoCodeToCheckout = async() => {
        try{
            const opParam = {}
            return updateAndGetFastCheckoutContent('takeOutPromoCode', opParam)
        }catch(error){
            throw error
        }
    }
    
    useEffect(() => {
        const abortController = new AbortController();
        abortSignalRef.current = abortController.signal;
        return(() => abortController.abort())
    },[]);

    useEffect(() => {
        if(!fastCheckoutContent){
            if(!fastCheckoutId){
                getAClearedFastCheckoutId(user.basicData.id);
            }else{
                if(isFastCheckoutBillable == null){
                    getCheckoutBillability(fastCheckoutId, user.basicData.id);
                }else if(isFastCheckoutBillable){
                    if(!productConsumptionOptionMap && !userProductPurchasabilityDetailsByIdMap){
                        requestUserProductConsumptionOptionMap(user.basicData.id, productIdentifier, localeCtx.localeId, quantity);
                    }else{
                        if(confirmedSelectedProductOption){
                            const checkoutProduct = {
                                productId: confirmedSelectedProductOption.id,
                                productTypeName: confirmedSelectedProductOption.productTypeName,
                                quantity
                            }
                            // Add subscriptable product to checkout
                            // if(isSubscriptionOrUpgradeSubscriptionProductTypeName(checkoutProduct.productTypeName)){
                            //     if(checkoutProduct.productId !== productIdentifier.productId){
                            //         const subscriptableProduct = {
                            //             productId: productIdentifier.productId,
                            //             productTypeName: 'content',
                            //             quantity: 1
                            //         }
                            //         const checkoutSubscriptableProductArray = [subscriptableProduct];
                            //         updateAndGetFastCheckoutContent('addSubscriptable', { checkoutSubscriptableProductArray })
                            //     }
                            // }
                            //
                            const checkoutProductArray = [ checkoutProduct ];
                            handleAddProductArrayAndGetFastCheckoutContent(checkoutProductArray);
                        }else if(isRedirect){
                            handleGetFastCheckoutContent()
                        }
                    }
                }
            }
        }
    },[fastCheckoutContent, fastCheckoutId, isFastCheckoutBillable, confirmedSelectedProductOption, productConsumptionOptionMap, userProductPurchasabilityDetailsByIdMap]);

    return (
        <Grid container direction='column' >
             <Grid item >
                <Typography variant="h5">
                    <Box fontWeight="bold">
                        {t('purchaseConfig')}
                    </Box>
                </Typography>
                <Typography variant='body2'>
                    {t('selectHowToPurchase')}
                </Typography>
            </Grid>
            <Divider style={{marginTop:"10px"}}/>
            {isLoading  ?
                <Grid item className='fast-checkout-config-loading-container'>
                    <LoadingComponent />
                </Grid>
                :
                    error.isError && error.type === 'retrieval' ?
                        <Grid item container className='fast-checkout-config-error-container'>
                            <Grid item>
                                <WarningAmberIcon style={styles.icon}/>
                            </Grid>
                            <Grid item xs>
                                <Typography variant='body2'>
                                    {error.msg}
                                </Typography>
                            </Grid>
                        </Grid>
                        :
                        <>
                            {isFastCheckoutBillable == false &&
                                <Grid item>
                                    <CheckoutBillingAddressEdit updateBillingAddress={updateBillingAddress} />
                                </Grid>
                            }
                            {fastCheckoutContent ?
                                    <>
                                        <Grid item>
                                            <BackButton isDisabled={isLoading} onClick={(e) => {e.preventDefault(); resetFastCheckoutConfig()}}/>
                                        </Grid>
                                        <Grid item>
                                            <CheckoutItems 
                                                updateCheckoutContent={updateAndGetFastCheckoutContent} 
                                                deleteOrUpdateItemQuantity={handleDeleteOrUpdateProductQuantityToFastCheckout}
                                                swapItems={handleSwapProductsToFastCheckout}
                                                addItemArray={handleAddProductToFastCheckoutContent}
                                                checkoutContent={fastCheckoutContent} 
                                                isCheckoutUpdating={isLoading} 
                                            />
                                        </Grid>
                                        <Grid item className='fast-checkout-config-details-container'>
                                            <CheckoutDetails 
                                                applyPromoCode={handleApplyPromoCodeToCheckout}
                                                takeOutPromoCode={handleTakeOutPromoCodeToCheckout}
                                                checkoutContent={fastCheckoutContent} 
                                                appliedPromoCode={appliedPromoCode} 
                                                isCheckoutUpdating={isLoading} 
                                            />
                                        </Grid>
                                        <Grid item className='fast-checkout-config-button-container'>
                                            <ButtonLoading onClick={(e) => setPurchaseSet((purchaseStep) => purchaseStep + 1)} disabled={isLoading} isLoading={false} label={'Continuar'} 
                                            variant="outlined" color="primary" type='submit' fullWidth />
                                        </Grid>
                                    </>
                                    :
                                    (confirmedSelectedProductOption == null || isEmptyObject(confirmedSelectedProductOption)) ?
                                        <>
                                            <Grid item className='fast-checkout-config-select-product-container'>
                                                <ToggleMsg 
                                                    isShown={error.isError && error.type === 'add'} 
                                                    type='error' msg={error.msg} 
                                                    showTimeMs={null}
                                                />
                                                <SelectProductConsumptionOption 
                                                    productConsumptionOptionMap={productConsumptionOptionMap} 
                                                    userProductPurchasabilityDetailsMap={userProductPurchasabilityDetailsByIdMap} 
                                                    onSelectOptionType={handleOnSelectProductConsumptionOptionType} 
                                                    onSelectOption={handleOnSelectProductConsumptionOption}
                                                    onConfirm={handleOnConfirmSelectedProductConsumptionOption}
                                                    selectedProductConsumptionTypeName={selectedProductConsumptionTypeName}
                                                    selectedProductConsumptionOption={selectedProductConsumptionOption}
                                                />
                                            </Grid>
                                        </>
                                        :
                                        null
                            }
                        </>
            }
        </Grid>
    )
}

export default FastCheckoutConfig