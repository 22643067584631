import { Typography } from '@material-ui/core';
import { useEffect, useState, forwardRef, useRef } from 'react';
import { getFromSafeObject, isValidDate } from '../../aux/aux';
import { isCustomError } from '../../aux/errorHelpers';
import { getReliableLocale } from '../../aux/sessionHelpers';

import { getUserAccessMeetingToken } from '../../services/meetingServices';

import ButtonLoading from '../ButtonLoading/ButtonLoading';

import './joinMeetingButton.css';
import { useLocaleCtx } from '../../customHooks/useLocaleCtx';

const JoinMeetingButton = ({ userId, userMeetingId, label='join', onOpenMeetingAppWindow=()=>{}, onUserStatusChange=()=>{}, onUserJoinMeetingError=()=>{}, ...props }, ref) => {
    const locale = useLocaleCtx();
    const WINDOW_NAME = `com_qxInstitute`;
    const abortControllerRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [meetingAccessObject, setMeetingAccessObject] = useState({
        meetingAccessToken: null,
        meetingProviderName: null,
        meetingUser: null,
    });
    
    const getMeetingConnectionUrl = (provider) => {
        const query = new URLSearchParams({
            from: window.location.href,
            in: WINDOW_NAME,
            locale: locale.localeCode
        })
        let url =  `${process.env.REACT_APP_MEETING_SUBDOMAIN_BASEPATH}${process.env.REACT_APP_MEETING_SUBDOMAIN_PORT ? `:${process.env.REACT_APP_MEETING_SUBDOMAIN_PORT}`: ''}`;
        url += `/${process.env.REACT_APP_MEETING_SUBDOMAIN_CONNECT_PATH}/${provider}/${userMeetingId}?${query.toString()}`
        return url;
    }
    
    const handleJoinMeeting = async (e, signal=abortControllerRef.current.signal) => {
        try{
            e.preventDefault();
            setIsLoading(true);
            const res = await getUserAccessMeetingToken(userId, userMeetingId, signal);
            const meetingAccessObject = res.data;
            if(!signal.aborted){
                setMeetingAccessObject(meetingAccessObject);
            }
        }catch(error){
            // take cause from server response
            // const errorCause = getFromSafeObject(error, 'response.data.causeDescription');
            // onUserJoinMeetingError(errorCause)
            if(!signal.aborted){
                onUserJoinMeetingError()
            }
        }finally{
            if(!signal.aborted){
                setIsLoading(false);
            }
        }
    }

    useEffect(() => {
        if(meetingAccessObject.meetingAccessToken != null && meetingAccessObject.meetingProviderName != null){
            const meetingAppCommunication = (e) => {
                const meetingAppUrl = `${process.env.REACT_APP_MEETING_SUBDOMAIN_BASEPATH}${process.env.REACT_APP_MEETING_SUBDOMAIN_PORT ? `:${process.env.REACT_APP_MEETING_SUBDOMAIN_PORT}`: ''}`;
                if(e.origin === meetingAppUrl){
                    const data = e.data;
                    if(data.userMeetingId == userMeetingId){
                        if(data.isChildrenReady === true){
                            e.source.postMessage({ 
                                accessMeetingToken: meetingAccessObject.meetingAccessToken,
                            }, meetingAppUrl);
                        };
                        if(data.userMeetingStatus){
                            if(data.userMeetingStatus === 'user_joined'){
                                onUserStatusChange('joinedMeeting');
                            }
                            if(data.userMeetingStatus === 'user_left'){
                                onUserStatusChange('leftMeeting');
                            }
                        };
                        if(data.meetingError){
                            onUserJoinMeetingError()
                        }
                        if(data.closeConnection === true){
                            window.removeEventListener('message', meetingAppCommunication);
                        }
                    }
                }
            }
            window.name = WINDOW_NAME;
            const meetingConnectionUrl = getMeetingConnectionUrl(meetingAccessObject.meetingProviderName);
            const meetingWindowName = `com_qxInstitute_liveMeeting_${userMeetingId}`;
            const windowRef = window.open(meetingConnectionUrl, meetingWindowName, '');
            onOpenMeetingAppWindow(meetingWindowName, windowRef);
            window.addEventListener('message', meetingAppCommunication);
            return(() => {
                window.removeEventListener('message', meetingAppCommunication);
                window.name = '' // reset window name
            })
        }
    }, [meetingAccessObject])

    useEffect(() => {
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        return(() => {
            abortController.abort()
        })
    },[])

    return (
        <ButtonLoading ref={ref} isLoading={isLoading} onClick={ handleJoinMeeting } label={label} {...props} />
    )
}

export default forwardRef(JoinMeetingButton)