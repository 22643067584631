import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { TextField, Typography, Button, Grid } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { isEmptyObject, textTransform } from '../../aux/aux'
import ButtonLoading from '../ButtonLoading/ButtonLoading'
import './verifyAccountForm.css'
import { aesCipher } from '../../aux/cryptoHelpers'
import { getCustomErrorOrUndefined } from '../../aux/errorHelpers'
import { requestVerifyAccount, verifyAccount, joinToVerifyAccountList, isUserInPassiveVerificationList } from '../../services/userServices'
import { CircularProgress } from '@material-ui/core';
import ToggleMsg from '../ToggleMsg/ToggleMsg'
// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import errorAnimation from '../../lotties/error.json';

const VerifyAccountForm = ({account, onVerified, onCancel=null, onRender=()=>{}, onLoading=()=>{},  passiveVerification=true }) => {
    const CODE_LENGTH = 2;
    const CODE_DIVIDER = '-';
    const theme = useTheme();
    const { t } = useTranslation('common', { keyPrefix: 'verifyAccountForm' });
    const [verificationToken, setVerificationToken] = useState(null);
    const [code, setCode] = useState({});
    const [isInPassiveVerificationList, setIsInPassiveVerificationList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [requestError, setRequestError] = useState({});
    const [verifyError, setVerifyError] = useState({})
    const [animationContainer, setAnimationContainer] = useState(null);
    const abortControllerRef = useRef(null);
    const animationRef = useRef(null);
    const styles = {
        code:{
            textAlign:'center',
            fontFamily: 'Monospace',
            fontSize: theme.typography.h6.fontSize
        },
        circularProgress:{
            color: theme.palette.primary.main
        },
        refreshTokenLink:{
            color: theme.palette.primary.main,
        },
        passiveFeedbackMsg:{
            color: isInPassiveVerificationList ? theme.palette.success.main : theme.palette.grey[500] 
        }
    }

    const resetComponent = () => {
        setVerificationToken(null);
        setCode({});
        setRequestError({});
        setVerifyError({})
        setAnimationContainer(null)
        setIsInPassiveVerificationList(null);
    }

    const getCipheredEmailOrNull = (account) => {
        let cipheredEmail;
        try{
            cipheredEmail = account.cipheredEmail || null;
            if(account.cipheredEmail == null){
                cipheredEmail = aesCipher(account.email, process.env.REACT_APP_CRYPTO_HASH_KEY);
            }
        }catch(error){
            cipheredEmail = null;
        }finally{
            return cipheredEmail;
        }
    }

    const requestIsUserInPassiveVerificationList = async (cipheredEmail, minimumTimeout=0, signal=abortControllerRef.current.signal) => {
        try{
            setIsLoading(true)
            const res = await isUserInPassiveVerificationList(cipheredEmail, minimumTimeout, signal);
            const {
                isIncluded
            } = res.data
            setIsInPassiveVerificationList(isIncluded);
        }catch(error){
            setRequestError({ 
                isShown:true, 
                msg: `${textTransform('title', t("uups"))}. `
                    +`${textTransform('title', t("weCannotCheckTheListNow"))}. `
                    +`${textTransform('title', t("tryItLater"))}`
            })
        }finally{
            setIsLoading(false);
        }
    }

    const requestAccountVerificationToken = async (cipheredEmail, minimumTimeout=0, signal=abortControllerRef.current.signal) => {
        try{
            setIsLoading(true)
            resetComponent()
            if(cipheredEmail != null){
                const res = await requestVerifyAccount(cipheredEmail, minimumTimeout, signal);
                const {
                    verifyToken,
                    refCode
                } = res.data;
                if(!signal.aborted){
                    setVerificationToken(verifyToken);
                    setCode(pre => ({ ...pre, refCode }));
                }
            }else{
                throw new Error('Cannot request account verification without a ciphered email')
            }
        }catch(error){
            if(!signal.aborted){
                setRequestError({ 
                    isShown:true, 
                    msg: `${textTransform('title', t("uups"))}. `
                        +`${textTransform('title', t("weCannotVerifyYourEmailNow"))}. `
                        +`${textTransform('title', t("tryToInitSessionLater"))}`
                })
            }
        }finally{
            if(!signal.aborted){
                setIsLoading(false);
                
            }
        }
    }

    const verificationProcess = async (e, signal=abortControllerRef.current.signal) => {
        try{
            e.preventDefault();
            setIsLoading(true);
            await verifyAccount(verificationToken, code.refCode + code.verifyCode, 0, signal);
            if(!signal.aborted){
                onVerified();
            }
        }catch(error){
            const customError = getCustomErrorOrUndefined(error);
            let resolveError = {};
            if(customError){
                switch(customError.cause){
                    case 'sr_007':
                        // wrong code
                        resolveError.type = 'wrongCode';
                        resolveError.msg = textTransform('title',t("wrongCode"));
                        break;
                    case 'sr_103':
                        // wrong or expired token
                        resolveError.type = 'token';
                        resolveError.msg = `${textTransform('title',t("expiredCode"))}. ${textTransform('title', t("tryItAgain"))}`;
                }
            }
            if(isEmptyObject(resolveError)){
                resolveError.type = 'unknown';
                resolveError.msg = `${textTransform('title',t("weCannotVerifyYourEmailNow"))}. ${textTransform('title',t("tryItLater"))}`;
            }
            if(!signal.aborted){
                setVerifyError({ isShown:true, ...resolveError})
            }
        }finally{
            if(!signal.aborted){
                setIsLoading(false);
            }
        }
    }
    const joinToPassiveVerificationProcess = async (e, signal=abortControllerRef.current.signal) => {
        try{
            e.preventDefault();
            setIsLoading(true);
            const cipheredEmail = getCipheredEmailOrNull(account);
            await joinToVerifyAccountList(cipheredEmail, 0, signal);
            if(!signal.aborted){
                setIsInPassiveVerificationList(true);
            }
        }catch(error){
            if(!signal.aborted){
                setVerifyError({ 
                    isShown:true,
                    type: 'unknown',
                    msg :`${textTransform('title', t("youCannotJoinTheListNow"))}. ${textTransform('title',t("tryItLater"))}`,
                })
            }
        }finally{
            if(!signal.aborted){
                setIsLoading(false);
            }
        }
    }

    useEffect(() => {
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        return(() => {
            abortController.abort();
        })
    },[])

    useEffect(() => {
        const cipheredEmail = getCipheredEmailOrNull(account);
        if(passiveVerification){
            requestIsUserInPassiveVerificationList(cipheredEmail);
        }else{
            requestAccountVerificationToken(cipheredEmail);
        }
    },[account, passiveVerification])

    useEffect(() => {
        if(animationContainer != null){
            animationRef.current = lottie.loadAnimation({
                container: animationContainer,
                renderer: 'svg',
                loop: false,
                autoplay:true,
                animationData: errorAnimation
            });
            return(() => {
                animationRef.current.stop(); 
                animationRef.current?.destroy()
            })     
        }
    },[animationContainer])
    
    useEffect(() => {
        onLoading(isLoading)
    }, [isLoading])
    
    useEffect(() => {
        onRender()
    })
    return (
        <form onSubmit={ passiveVerification ? joinToPassiveVerificationProcess  : verificationProcess }>
            <Grid container direction='column' className='verify-account-form-wrapper'>
                <Grid item className="verify-account-form-text-section">
                    <Typography variant="body1">
                        {passiveVerification ? 
                            `${textTransform('title', t("weAreGettingProgressiveAccess"))}. `
                            +`${textTransform('title', t("joinToTheWaitingList"))}. `
                            +`${textTransform('title', t("weNotifyYouWhenYouCanActivateYourAccount"))} `
                            :
                            `${textTransform('title', t("needsToVerifyYourAccount"))}. `
                            +`${textTransform('title', t("weSendYouAnEmailWithACode"))}. `
                            +`${textTransform('title', t("theEmailCanTakeSomeTime"))} `
                            +`${t("orBeConsiderSpam")}.`
                        }
                    </Typography>
                </Grid>
                <Grid item className="verify-account-form-input-section">
                    {!requestError.isShown ?
                        !passiveVerification ?
                            <Grid container className="verify-account-form-input-container">
                                <Grid item className='verify-account-form-input-container-item'>
                                    {code.refCode != null ?
                                        <Typography style={styles.code}>
                                            {code.refCode}
                                        </Typography>
                                        :
                                        <CircularProgress size={styles.code.fontSize} style={styles.circularProgress} />
                                    }
                                </Grid>
                                <Grid item>
                                    <Typography style={styles.code}>
                                        {CODE_DIVIDER}
                                    </Typography>
                                </Grid>
                                <Grid item className='verify-account-form-input-container-item'>
                                    <TextField
                                        type="text"
                                        value={code.verifyCode || ''}
                                        name='verifyCode'
                                        onChange={(e) => setCode(prev => ({...prev, verifyCode: e.target.value }))}
                                        placeholder={'*'.repeat(CODE_LENGTH)}
                                        inputProps={{ maxLength: CODE_LENGTH, minLength: CODE_LENGTH, style: styles.code }}
                                        color="primary"
                                        disabled={isLoading}
                                        required
                                    />      
                                </Grid>
                            </Grid>
                            :
                            <div className='verify-account-form-passive-feedback-container'>
                                {isInPassiveVerificationList != null ?
                                    <Typography variant='body1' className='verify-account-form-passive-feedback-msg' style={styles.passiveFeedbackMsg}>
                                        {isInPassiveVerificationList ? 
                                            `${textTransform('title', t("youAreInTheList"))}`
                                            :
                                            `${textTransform('title', t("youAreNotInTheList"))}`
                                        }
                                    </Typography>
                                    :
                                    <CircularProgress size={styles.code.fontSize} style={styles.circularProgress} />
                                }
                            </div>
                        :
                        <Grid container className='verify-account-form-request-error-container'>
                            <Grid item className='verify-account-form-request-error-animation-container'>
                                <div ref={setAnimationContainer} className='verify-account-form-request-error-animation' />
                            </Grid>
                            <Grid item className='verify-account-form-request-error-msg-container'>
                                <Typography variant='body1'>
                                    {requestError.msg}
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                </Grid>
                <Grid item className="verify-account-form-button-section">
                    <Grid container className='verify-account-form-button-container'>
                        {(onCancel != null && (passiveVerification && isInPassiveVerificationList)) &&
                            <Grid item className="verify-account-form-button" >
                                <Button
                                    variant={passiveVerification ? "contained" : "outlined"}
                                    color="primary"
                                    onClick={onCancel}
                                    size="large"
                                >
                                    {t("common:cancel")}
                                </Button>
                            </Grid>
                        }
                        {(!passiveVerification || !isInPassiveVerificationList) &&
                            <Grid item className="verify-account-form-button">
                                <ButtonLoading 
                                    disabled={(!passiveVerification && verificationToken == null) || (passiveVerification === true && isInPassiveVerificationList == null)}
                                    isLoading={isLoading}
                                    label={passiveVerification ? t("joinTheList") : t("verificate")}
                                    type="submit"
                                    variant='contained'
                                    color="primary"
                                    size="large"
                                />
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid item>
                    <ToggleMsg 
                        extraClassName='verify-account-form-toggle-msg-container'
                        isShown={verifyError.isShown}
                        msg={verifyError.msg}
                        onShowTime={() => setVerifyError(prev => ({ ...prev, isShown:false }))}
                        type='error'
                    />
                </Grid>
                {!passiveVerification &&
                    <Grid item className="verify-account-form-refresh-wrapper">
                        <Typography variant='body2'>
                            {`¿${textTransform('title', t("itIsExpiredOrItDidNotShowUp"))}?`}
                        </Typography>
                        <Typography 
                            variant="body2"
                            className={`verify-account-form-refresh-link ${isLoading ? 'disabled' : ''}`}
                            onClick={e => {requestAccountVerificationToken(getCipheredEmailOrNull(account), 1000)}}
                            style={styles.refreshTokenLink}
                        >
                            {`${textTransform('title', t("resendEmail"))}`}
                        </Typography>
                    </Grid>
                }
            </Grid>
        </form>
    )
}
export default VerifyAccountForm