import { Grid, Typography } from '@mui/material';
import { textTransform } from '../../aux/aux'
import './classSideBarOverview.css';

const ClassSideBarOverview = ({ lessonId, sectionId, title, setContentSelected, contentSelected }) => {
    const updateContentSelected = (e) => {
        setContentSelected(sectionId, lessonId)
    }
    
    return (
        <div className="class-side-bar-overview-main" onClick={updateContentSelected}>
            <Grid container className="class-side-bar-overview-info-container" alignItems='center'>
                <Grid item>
                    <Typography>
                        {textTransform('title',title)}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default ClassSideBarOverview