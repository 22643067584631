import { publicRequest } from "./requestMethods"

/**
 * This module contains api health-realited queries
 */
const checkAPIHealthFn = async(params, signal=null)=>{
   return publicRequest.get('/health/', { signal }); 
};

export {
    checkAPIHealthFn
}