import { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { Typography, Grid, Button, MenuItem } from '@material-ui/core';
import { TextField } from '@mui/material';
import ButtonLoading from '../ButtonLoading/ButtonLoading';
import { textTransform } from '../../aux/aux';
import { createNewUserSystem } from '../../services/userServices';
import { getCustomErrorOrUndefined, isCustomError } from '../../aux/errorHelpers';
// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import error from '../../lotties/error.json';

import './createUserSystemForm.css'
import { getCompatibleSystems } from '../../services/staticContentServices';
import ToggleMsg from '../ToggleMsg/ToggleMsg';
import LoadingComponent from '../LoadingComponent/LoadingComponent';

const CreateUserSystemForm = ({ user, onCreateError=()=>{}, onCreate=()=>{}, onCancel=()=>{}, onRender=()=>{} }) => {
    const SYSTEM_NICKNAME_MAX_LENGTH = 50;
    const MAX_REQUEST_ATTEMPT = 10;
    const { t } = useTranslation('common', { keyPrefix: 'createUserSystemForm' });
    const abortControllerRef = useRef(null);
    const [deviceCompatibilityMap, setDeviceCompatibilityMap] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isCreateError, setIsCreateError] = useState(null);
    const [isRequestError, setIsRequestError] = useState(false);
    const [requestErrorCount, setRequestErrorCount] = useState(0);
    const [animationContainer, setAnimationContainerRef] = useState(null);
    const animationRef = useRef(null)
    const [createUserSystemFormState, setCreateUserFormState] = useState({
        form:{
            deviceName:'',
            softwareName: '',
            systemNickname: `${t("common:my")} ${t("quantumSystem")}`.toUpperCase()
        },
        formErrorType:null
    });

    const requestCompatibleSystems = async(signal) => {
        try{
            const by = 'device'
            const res = await getCompatibleSystems(by);
            const systems = res.data;
            if(!signal.aborted){
                setDeviceCompatibilityMap(systems);
            }
        }catch(error){
            if(!signal.aborted){
                setIsRequestError(true)
            }
        }
    }
    
    const createUserSystem = async (system, abortSignal=abortControllerRef.current.signal, userId=user.id) => {
        try{
            setIsLoading(true)
            const res = await createNewUserSystem( userId, system, 1000, abortSignal);
            const newUserSystem = res.data;
            if(!abortSignal.aborted){
                onCreate(newUserSystem);
            }
        }catch(error){
            const customError = getCustomErrorOrUndefined(error);
            let formErrorType = null;
            let createErrorType = null;
            if(customError){
                switch(customError.cause){
                    case 'db_101':
                        formErrorType = 'duplicateNickname';
                        break;
                    case 'db_104':
                        createErrorType = 'wrongDeviceOrSoftware'
                        break;
                    default:
                        break;
                }
            }
            createErrorType = 'unknown'
            if(!formErrorType && !createErrorType){
                createErrorType = 'unknown'
            }
            if(!abortSignal.aborted){
                setRequestErrorCount(prev => (prev + 1));
                setIsCreateError(createErrorType);
                onCreateError()
                setCreateUserFormState(prev => ({ ...prev, 'formErrorType': formErrorType }));
            }
        }finally{
            if(!abortSignal.aborted){
                setIsLoading(false)
            }
        }
    }

    const getErrorMsg = () => {
        // There is currently no distinction
        let errorMsg = ''
        switch(isCreateError){
            case 'wrongDeviceOrSoftware':
                errorMsg = `${textTransform('title', t("sorryAnError"))}.\n${textTransform('title', t("tryItLater"))}` 
                break;
            case 'unknown':
                errorMsg = `${textTransform('title', t("sorryAnError"))}.\n${textTransform('title', t("tryItLater"))}` 
                break;
            default:
                errorMsg = '';
        }
        return errorMsg;
    }

    const isServerTired = () => {
        return requestErrorCount >= MAX_REQUEST_ATTEMPT
    }

    const onChangeForm = (e) => {
        e.preventDefault();
        const field = e.target.name;
        let value = e.target.value;
        value = field === 'systemNickname' ? value.toLowerCase() : value;
        setCreateUserFormState(prev => {
            const tempForm = { 
                ...prev.form ,
                [field]: value,
                ...(field === 'deviceName' ? { softwareName: '' } : {})
            };
            return { ...prev, form:tempForm,
                formErrorType: field === 'systemNickname' ? null : prev.formErrorType,
            }
        })
    }

    const handleOnSubmit = async (e, signal=abortControllerRef.current.signal) => {
        e.preventDefault();
        const system = {
            softwareName: createUserSystemFormState.form.softwareName,
            deviceName: createUserSystemFormState.form.deviceName,
            nickname: createUserSystemFormState.form.systemNickname,
            isDefault: true
        }
        await createUserSystem(system, signal);
    }
    
    useEffect(() => {
        if(animationContainer != null){
            animationRef.current = lottie.loadAnimation({
                container: animationContainer,
                renderer: 'svg',
                autoplay: true,
                animationData: error,
                loop: false,
                name: 'animation'
            })
            return(() => {
                if(animationRef.current != null){
                    animationRef.current.stop();
                    animationRef.current?.destroy();
                }
            })
        }
    },[animationContainer])

    useEffect(() => {
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        requestCompatibleSystems(abortController.signal);
        return(() => {
            abortController.abort();
        })
    },[])
    
    useEffect(() => {
        onRender()
    })

    return (
        deviceCompatibilityMap === null ?
            isRequestError === true ?
                <Grid container direction='column'>
                    <Grid item align='center' className='create-user-system-error-message-item'>
                        <div ref={setAnimationContainerRef} className='create-user-system-error-animation-container'/>
                    </Grid>
                    <Grid item className='create-user-system-error-message-item' align='center'>
                        <Typography variant='body1' style={{whiteSpace:'pre-line'}}>
                            {
                                `${textTransform('title', t("weCannotRegisterYourSistem"))}`
                                +`. ${textTransform('title', t("tryItLater"))}`
                            }
                        </Typography>
                    </Grid>
                    <Grid item align='center' className='create-user-system-form-button-container'>
                        <Button
                            color='primary'
                            variant='outlined'
                            onClick={onCancel}
                        >
                            {t("common:ok")}    
                        </Button>
                    </Grid>
                </Grid>
            :
                <LoadingComponent visibleElements='circle'/>

        :
            <form onSubmit={handleOnSubmit}>
                <Grid item className='create-user-system-form-item'>
                    <TextField 
                        name="deviceName" 
                        label={textTransform('title', t("quantumSystem"))}
                        value={createUserSystemFormState.form.deviceName} 
                        onChange={onChangeForm} 
                        select
                        disabled={isLoading} 
                        variant='standard' 
                        fullWidth
                        required
                    >
                        {Object.keys(deviceCompatibilityMap).map((deviceName => {
                            return(
                            <MenuItem key={deviceName} value={deviceName}>
                                {deviceName.toUpperCase()}
                            </MenuItem>
                            )
                        }))}
                        <MenuItem key={'None'} value={''}>
                            {textTransform('title', t("common:none"))}
                        </MenuItem>
                    </TextField>
                </Grid>
                <Grid item className='create-user-system-form-item'>
                    <TextField 
                        name="softwareName" 
                        label={textTransform('title', t("software"))}
                        value={createUserSystemFormState.form.softwareName} 
                        onChange={onChangeForm} 
                        select
                        disabled={isLoading || createUserSystemFormState.form.deviceName === ''} 
                        variant='standard' 
                        fullWidth
                        required
                    >
                        {createUserSystemFormState.form.deviceName !== '' ?
                            Object.keys(deviceCompatibilityMap[createUserSystemFormState.form.deviceName]).map((softwareName) => {
                                return(
                                    <MenuItem key={softwareName} value={softwareName}>
                                        {softwareName.toUpperCase()}
                                    </MenuItem>
                                )
                            })
                            :
                            <MenuItem key={1} value={''}>

                            </MenuItem>
                        }
                    </TextField>
                </Grid>
                <Grid item className='create-user-system-form-item'>
                    <TextField
                        type='text'
                        disabled={isLoading}
                        name='systemNickname'
                        value={createUserSystemFormState.form.systemNickname.toUpperCase()} 
                        onChange={onChangeForm} 
                        label={textTransform('title',t("systemName"))} 
                        inputProps={{
                            maxLength: SYSTEM_NICKNAME_MAX_LENGTH,
                        }}
                        error={createUserSystemFormState.formErrorType === 'duplicateNickname'}
                        helperText={createUserSystemFormState.formErrorType === 'duplicateNickname' ? t("duplicateNickname") : ''}
                        required 
                        variant='standard' 
                        fullWidth
                    />
                </Grid>
                <Grid item className='create-user-system-form-button-container'>
                    <Grid container justifyContent='flex-end'>
                        <Grid item className='create-user-system-form-button' >
                            <Button 
                            onClick={onCancel} 
                            name='actionCancel' 
                            value='no' 
                            variant='outlined' 
                            color="primary" 
                            disabled={isLoading}
                            fullWidth>
                                {t("common:notNow")}
                            </Button>
                        </Grid>
                        <Grid item className='create-user-system-form-button'>
                            <ButtonLoading 
                            type='submit' 
                            name='actionSubmit' 
                            value='yes' 
                            variant='contained' 
                            color='primary' 
                            isLoading={isLoading} 
                            label={t("common:save")}
                            disabled={isLoading || createUserSystemFormState.formErrorType != null || isServerTired(requestErrorCount)} 
                            fullWidth/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <ToggleMsg
                        className={`create-user-system-form-feedback-toggle ${isCreateError != null ? 'show' : ''}`}
                        isShown={isCreateError != null} 
                        onShowTime={() => {
                            const isTired = isServerTired(requestErrorCount);
                            setIsCreateError(isTired ? 'unknown' : null);
                        }}
                        msg={ getErrorMsg() } 
                    />
                </Grid>
            </form>
    )
}

export default CreateUserSystemForm
