import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/styles'
import FeatureCard from '../FeatureCard/FeatureCard';
import ProfessorIcon from './../../img/professorIcon.svg';
import LiveIcon from './../../img/cameraIcon.svg';
import CertificateIcon from './../../img/poligonWithTickIcon.svg';
import DeviceIcon from './../../img/deviceIcon.svg';
import HandsOnIcon from './../../img/mouseIcon.svg';
import CalendarIcon from './../../img/calendarIcon.svg';
import GroupIcon from './../../img/groupIcon.svg';
import ProIcon from './../../img/proIcon.svg';
import './landingFeatures.css';
import SubscriptionIcon from '../SubscriptionIcon/SubscriptionIcon';

const LandingFeatures = () => {
    const theme = useTheme();
    const [ t, i18n ] = useTranslation('landing', { keyPrefix: 'landingFeatures' });
    const featuresMap = {
        expertTrainers:{
            icon: ProfessorIcon,
            title:'expertTrainers',
            description: 'theBestTechnologySpecialist'
        },
        liveSupport:{
            icon: LiveIcon,
            title:'liveSupport',
            description: 'receiveLiveSupportFromTheExperts'
        },
        certificate:{
            icon: CertificateIcon,
            title:'certificate',
            description: 'weEndorseYourKnowledgeOnceYouFinishOurContents'
        },
        deviceAdapted:{
            icon: DeviceIcon,
            title:'adaptedToYourDevice',
            description: 'personalizedContentToYourDevice'
        },
        handsOnApproach:{
            icon: HandsOnIcon,
            title:'handsOnApproach',
            description: 'contentReadyToPutItIntoPractice'
        },
        flexible:{
            icon: CalendarIcon,
            title:'flexibleLearning',
            description: 'learnWhenWhereAndHowYouWant'
        },
        privateGroup:{
            icon: GroupIcon,
            title:'privateGroupAccess',
            description: 'shareWithColleaguesAroundTheWorld'
        },
        fitsYourNeeds:{
            icon: SubscriptionIcon,
            title:'adaptedToYourNeeds',
            description: 'differentOptionsToAccessTheContent'
        }
    }
    const styles = {
        wrapper:{
            backgroundColor: theme.palette.surface.light['1']
        }
    }
    return (
        <div className='landing-features-wrapper' style={styles.wrapper}>
            <Grid container className='landing-features-main-container'>
                {Object.values(featuresMap).map((feature, index) => {
                    return(
                        <Grid item key={index} className='landing-features-feature-wrapper'>
                            <FeatureCard title={t(feature.title)} description={t(feature.description)} Icon={feature.icon}/> 
                        </Grid>
                    )
                })}
            </Grid>
        </div>
    )
}

export default LandingFeatures