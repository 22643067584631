import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

import ButtonLoading from '../ButtonLoading/ButtonLoading';

import './userSystemSelect.css';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import UserSystemSelectForm from '../UserSystemSelectForm/UserSystemSelectForm';
import { getUserSystems } from '../../services/userServices';
import { textTransform } from '../../aux/aux';
import ToggleMsg from '../ToggleMsg/ToggleMsg';

const UserSystemSelect = ({ userConfig, contentSummary, onUpdate=()=>{}, onDidUpdate=()=>{}, onFinish=()=>{}, onRender=()=>{}}) => {
    const { t } = useTranslation('common', {keyPrefix:'userSystemSelect'});
    const abortControllerRef = useRef(null)
    const [isRequesting, setIsRequesting] = useState({
        userSystems:false
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [userSystems, setUserSystems] = useState(null);
    const [selectedSystemForm, setSelectedSystemForm] = useState({});
    const [error, setError] = useState({isError:false, msg:undefined, onError:()=>{}});
    const handleOnClick = async (e) => {
        try{
            setIsUpdating(true);
            e.preventDefault();
            await onUpdate(selectedSystemForm);
            onDidUpdate(selectedSystemForm);
            onFinish();
        }catch(error){
            if(!abortControllerRef.current.signal.aborted){
                setError({
                    isError:true, 
                    msg:`${textTransform('title', t('errorSettingTheSelectedSystem'))}. ${textTransform('title', t('selectAnotherAndYouCanUpdateLater'))}`,
                    onError: () => setError((prev) => ({
                        msg:prev.msg,
                        idError:false,
                        onError:()=>{}
                    }))
                })
            }
        }finally{
            if(!abortControllerRef.current.signal.aborted){
                setIsUpdating(false);
            }
        }
    }

    const isOnUpdateDisabled = () => {
        return !selectedSystemForm.selectedDeviceName || !selectedSystemForm.selectedSoftwareName || selectedSystemForm.selectedUserSystemId === userConfig.selectedUserSystemId
    }

    const requestUserSystems = async(userId, abortSignal) => {
        try{
            setIsRequesting((isRequesting) => ({...isRequesting, userSystems:true}))
            const res = await getUserSystems(userId, 'all');
            if(!abortSignal.aborted){
                setUserSystems(res.data);
            }
        }catch(error){
            if(!abortSignal.aborted){
                setError({isError:true, msg:`${textTransform('title', t('errorRequestingYourRegisteredSystems'))}. ${textTransform('title', t('tryItLater'))}` })
            }
        }finally{
            if(!abortSignal.aborted){
                setIsRequesting((isRequesting) => ({...isRequesting, userSystems:false}))
            }
        }
    };

    useEffect(() => {
        const isLoading = Object.values(isRequesting).find( request => request === true ) != null;
        setIsLoading(isLoading);
    },[isRequesting])

    useEffect(() => {
        abortControllerRef.current = new AbortController();
        requestUserSystems(userConfig.userId, abortControllerRef.current.signal);
        return(() => {
            abortControllerRef.current.abort();
        })
    },[])

    useEffect(() => {
        const timeoutId = setTimeout(onRender, 300);
        return(() => clearTimeout(timeoutId))
    })

    return (
        <Grid container direction='column' className='user-system-select-main'>
            <Grid item container direction='column' className='user-system-select-form'>
                {!userSystems || isLoading ?
                    <Grid item xs>
                        <LoadingComponent visibleElements='circle' />
                    </Grid>
                :
                    <>
                        <UserSystemSelectForm 
                        userSystems={userSystems} 
                        availableSystems={contentSummary.summaryDetails.availableConfiguration} 
                        currentUserSystemId={userConfig.selectedUserSystemId} 
                        setFormValues={setSelectedSystemForm} 
                        isDisabled={isUpdating}
                        />
                        <Grid item className='user-system-select-form-button'>
                            <ButtonLoading onClick={handleOnClick} disabled={ isOnUpdateDisabled() || isUpdating} isLoading={isUpdating} label={t("common:select")} 
                            variant="outlined" color="primary" fullWidth/>
                        </Grid>
                    </>
                }
                <Grid item>
                    <ToggleMsg 
                        isShown={error.isError} 
                        msg={error.msg} 
                        onShowTime={error.onError}
                        type='error' 
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default UserSystemSelect