import { useTranslation, Trans } from 'react-i18next'
import { Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import BigFooterNoLinks from '../../components/BigFooterNoLinks/BigFooterNoLinks';
import oneClickRefundStepImg_1 from '../../img/one-click-refund-step-1_400x250.jpg';
import oneClickRefundStepImg_2 from '../../img/one-click-refund-step-2_400x250.jpg'
import oneClickRefundStepImg_3 from '../../img/one-click-refund-step-3_400x250.jpg'
import { textTransform } from '../../aux/aux';
import {Alert} from '@mui/material';
import './refund.css'
import MarkdownDisplay from '../../components/MarkdownDisplay/MarkdownDisplay';
import { useLocaleCtx } from '../../customHooks/useLocaleCtx';
import { getLanguageIdOrNull } from '../../aux/sessionHelpers';
import { getInternationalizedLocaleFileNameOrNull } from '../../aux/internationalizationHelpers';
import { useEffect, useState } from 'react';
import ContactSupportSection from '../../components/ContactSupportSection/ContactSupportSection';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import StyledTypography from '../../components/StyledTypography/StyledTypography';

const Refund = ({ user }) => {
    const FILENAME = 'refund.md';
    const MARKDOWN_FILES_PUBLIC_FOLDER_PATH = './md';
    const { t } = useTranslation('refund');
    const theme = useTheme()
    const matchMobile = useMediaQuery('(max-width:680px)');
    const [filePath, setFilePath] = useState(null);
    const [isMdDisplayReady, setIsMdDisplayReady] = useState(false);
    const localeCtx = useLocaleCtx()
    const oneClickRefundStepArray = [
        {
            key:'oneClickRefundStep_1',
            imgSrc: oneClickRefundStepImg_1
        }, 
        {
            key:'oneClickRefundStep_2',
            imgSrc: oneClickRefundStepImg_2
        }, 
        {
            key:'oneClickRefundStep_3',
            imgSrc: oneClickRefundStepImg_3
        }
    ];
    const styles = {
        title:{
            // color: theme.palette.primary.darker,
            fontWeight: 'bold'
        },
        subtitle:{
            fontWeight: 'normal',
        },
        text:{
            whiteSpace:'pre-line'
        },
        highlightedText:{
            backgroundColor: theme.palette.primary.lighter,
            paddingInline: '4px'
        },
        divider : {
            backgroundColor: '#f3f3f3'
        },
        stepImgContainer:{
            boxShadow: theme.shadows[1]
        },
        stepTitle:{
            color: theme.palette.primary.main
        }
    }
    const getPath = (languageId, filename, folder=MARKDOWN_FILES_PUBLIC_FOLDER_PATH) => {
        return [folder, languageId, filename].join('/');
    }

    useEffect(() => {
        const languageId = getLanguageIdOrNull(localeCtx.localeCode);
        const internationalFilename = getInternationalizedLocaleFileNameOrNull(languageId, FILENAME);
        const path = internationalFilename ? getPath(languageId, internationalFilename) : internationalFilename;
        setFilePath(path);
    }, [localeCtx]);
    
    return (
        <div className='refund-wrapper'>
            <div className='refund-main-container'>
                <div className='refund-title-container'> 
                    <StyledTypography variant={matchMobile ? "h4" : "h3"} style={styles.title}>
                        {textTransform('title', t('safeAndInstantRefund'))}
                    </StyledTypography>
                    <StyledTypography variant={matchMobile ? "h6" : "h5"} align='center' style={styles.subtitle}>
                        {t('oneClickToRefundYourContentIn7Days')}
                    </StyledTypography>
                </div>
                <Alert severity='warning'>
                    <Typography variant='body2' className='refund-alert-msg'>
                        <Trans t={t} i18nKey={'alert_msg'} values={{email: process.env.REACT_APP_REFUND_EMAIL}} components={{ a: <a href={`mailto:${process.env.REACT_APP_REFUND_EMAIL}`} target='_blank'/>, b: <b/> }}/>
                    </Typography>
                </Alert>
                <div className='refund-markdown-wrapper'>
                    {!isMdDisplayReady ?
                            <LoadingComponent visibleElements='circle'/>
                        :
                            null
                    }
                    <MarkdownDisplay path={filePath} onReady={()=>setIsMdDisplayReady(true)}/>
                </div>
                <div className='refund-one-click-container'>
                    <div>
                        <StyledTypography variant={matchMobile ? "h6" : "h5"} style={styles.title}>
                            {t('oneClickRefund')}
                        </StyledTypography>
                        <StyledTypography variant='body1'>
                            {t('followThoseEasyStepsToEnjoyYourOneClickRefund')}
                        </StyledTypography>
                    </div>
                    {oneClickRefundStepArray.map((stepObject, index) => {
                        const stepNum = index + 1;
                        return(
                            <div key={index} className='refund-step-container'>
                                <div className='refund-step-description-container'>
                                    <Typography variant={matchMobile ? "body1" : "h6"} style={{...styles.title, ...styles.stepTitle}}>
                                        {textTransform('title',t('step', {num:stepNum}))}
                                    </Typography>
                                    <StyledTypography variant='body1' style={styles.text}>
                                        {t(stepObject.key)}
                                    </StyledTypography>
                                </div>
                                <div className='refund-step-img-container' style={styles.stepImgContainer}>
                                    <img src={stepObject.imgSrc} className='refund-step-img'/>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <ContactSupportSection 
                user={user}
                categoryObject={{
                    categoryArray: ['refund','contentsAndPrograms', 'subscriptions', 'other'],
                    defaultKey: 'refund'
                }}
                supportContext={{
                    // productId: contentProduct.id,
                    // productType: contentProduct.productTypeName,
                    // editionNum: contentProduct.editionNum
                }} 
            />
            <BigFooterNoLinks user={user}/>
        </div>
    )
}

export default Refund
