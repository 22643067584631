import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import NextIcon from "../../img/next-icon.svg";
import './backButton.css';
import { textTransform } from '../../aux/aux';

const BackButton = ({ isDisabled, onClick=undefined, ...props }) => {
    const history = useHistory();
    const { t } = useTranslation('common');
    const goBack = () => {
        history.goBack();
    }
    return (
        <div component="button" className={`back-button ${isDisabled ? 'disabled' : ''}`}
        onClick ={onClick || goBack} {...props}>
            <NextIcon height='0.8em' transform='rotate(180)' />
            <Typography variant='body2' display='inline' style={{paddingLeft:'5px'}}>
                {textTransform('title', t("back"))}
            </Typography>
        </div>
    )
}

export default BackButton