import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Divider } from "@material-ui/core";
import {Menu, MenuItem} from '@mui/material';
import { Link, useHistory } from "react-router-dom";
import { useUpdateCountryAndCurrencyCtx } from "../../customHooks/useUpdateCountryAndCurrencyCtx";

import { textTransform } from "../../aux/aux";

import './hamburguerMenuWithLogin.css'
import PopUpLogIn from "../PopUpLogIn/PopUpLogIn";
import { getReliableCountryCode, ipSessionVariables, isUserLogged } from "../../aux/sessionHelpers";
import UserMenuItemList from "../UserMenuItemList/UserMenuItemList";
import { justLogoutUser, logoutUser, logoutUserOnClient } from "../../services/sessionServices";
import { useEffect } from "react";
import { useUpdateScrollableDocumentCtx } from "../../customHooks/useUpdateScrollableDocumentCtx";

const HamburguerMenuWithLogin = ({ setUser, user, anchorEl, onClose, includesUserMenu=false, transformOrigin={}, anchorOrigin={}, paperProps={}, onClick=()=>{}, closeOnClick=false, passiveVerification=true, ...props }) => {
    const isMenuOpen =  Boolean(anchorEl !== null);
    const appSubdomain = process.env.REACT_APP_CLASS_SUBDOMAIN;
    const history = useHistory();
    const { t } = useTranslation('common', {keyPrefix: 'hamburguerMenuWithLogin'});
    const updateCountryAndCurrencyCtx = useUpdateCountryAndCurrencyCtx();
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const abortControllerRef = useRef(null);
    const handleOpenLoginPopUp = (e) => {
        e.preventDefault();
        setShowLoginModal(true);
    }


    const logoutProcess = async (e, signal=abortControllerRef.current.signal) => {
        try{
            e.preventDefault();
            setIsLoading(true)
            await logoutUser({ userId: user.basicData.id }, 1000, signal)
        }catch(err){
            if(!signal.aborted){
                logoutUserOnClient(signal)
            }
        }finally{
            if(!signal.aborted){
                updateCountryAndCurrencyCtx.resetCountryAndCurrencyToSessionValues();
                setIsLoading(false)
                justLogoutUser(setUser);
            }
            if(history.location.pathname.split('/')[1] === appSubdomain){
                history.push('/')
            }
        }
    }

    useEffect(() => {
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        return(() => {
            abortController.abort()
        })
    },[])

    const setIsScrollableDocument = useUpdateScrollableDocumentCtx()

    const handleOnClose = (e) => {
        onClose(e);
        setIsScrollableDocument(true)
    }

    const handleOnClick = (e) => {
        onClick(e);
        if(closeOnClick){
            handleOnClose(e);
        }
    }

    useEffect(() => {
        if(isMenuOpen){
            setIsScrollableDocument(!isMenuOpen)
        }
    },[isMenuOpen])

    return (
        <>
            {showLoginModal &&
                <PopUpLogIn showModal={showLoginModal} setShowModal={setShowLoginModal} setUser={setUser} passiveVerification={process.env.REACT_APP_TARGET_ENV === 'staging'} />
            }
            <Menu
                disableScrollLock={true}
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleOnClose}
                onClick={handleOnClick || handleOnClose}
                PaperProps={paperProps}
                transformOrigin={transformOrigin}
                anchorOrigin={anchorOrigin}
            >
                {props.children}
                {!isUserLogged(user) || includesUserMenu ?
                    <Divider/>
                    :
                    undefined
                }
                {!isUserLogged(user) ?
                    <MenuItem>
                        <Typography
                            variant= "body1"
                            onClick={handleOpenLoginPopUp}
                        >
                            {textTransform('title', t('login'))}
                        </Typography>
                    </MenuItem>
                    :
                    includesUserMenu &&
                        <UserMenuItemList user={user} onLogout={logoutProcess} />
                }
            </Menu>
        </>
    )
}

export default HamburguerMenuWithLogin