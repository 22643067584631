import { Typography, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/styles'
import { textTransform } from '../../aux/aux';
import './featureCard.css'

const FeatureCard = ({ title, description, Icon, styles={}}) => {
    const theme = useTheme();
    styles = {
        ...styles,
        paper: {
            background: theme.palette.background.paper,
            boxShadow: theme.shadows[1],
            borderRadius: '5px',
            // border: `solid 1px ${theme.palette.divider}` // Divider #F3F3F3
        },
        title: {
            fontWeight: 'bold',
            color: theme.palette.text.primary
        },
        icon: {
            display: 'block',
            fill: theme.palette.primary.main,
            height:24,
            width:24
        }
    }
    return (
        <div className='feature-card-wrapper' >
            <Grid container direction='column' className='feature-card-main-container' style={styles.paper}>
                <Grid item className='feature-card-icon-container'>
                    <Icon style={styles.icon}/>
                </Grid>
                <Grid item className='feature-card-title-container'>
                    <Typography variant='body1' style={styles.title}>
                        {textTransform('title', title)}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant='body1' style={styles.description}>
                        {textTransform('title', description)}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default FeatureCard