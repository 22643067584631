import PopUpModal from '../PopUpModal/PopUpModal'
import { useTranslation } from 'react-i18next';
import PopUpModalHeader from '../PopUpModalHeader/PopUpModalHeader';
import SimpleNoModalDialog from '../SimpleNoModalDialog/SimpleNoModalDialog';

import { textTransform, toTimescaleFromMilliseconds } from '../../aux/aux';

import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import './popUpLowPriorityRegistrationWarning.css'
import { Typography } from '@material-ui/core';

const PopUpLowPriorityRegistrationWarning = ({show, onAgree, lowPriorityRegistrationWindowMs}) => {
    const POP_UP_WIDTH= 380;
    const styles = {
        remarkedText:{
            fontWeight: 'bold'
        }
    }
    const { t } = useTranslation('common', { keyPrefix:'popUpLowPriorityRegistrationWarning' });

    return (
        <PopUpModal 
            showModal={show}
            width={POP_UP_WIDTH}
            isDialog={true}
        >
            <PopUpModalHeader
                Icon={EventRepeatIcon}
                title={textTransform('title', t('eventReattendance'))} 
            />
            <SimpleNoModalDialog
                className='popup-low-priority-registration-warning-dialog-container'
                onAgree={onAgree}
            >
                <Typography variant='body1'>
                    {textTransform('title', t('reAttendeesRegistrationOpens'))}
                    <Typography  variant='body1' component={'span'} style={styles.remarkedText}>
                        {` ${toTimescaleFromMilliseconds('hour', lowPriorityRegistrationWindowMs)} ${t('common:hour', {count: 2})} ${t('beforeStarts')} `}
                    </Typography>
                    <Typography variant='body1'  component={'span'}>
                        {`${t('toPrioritizeNewAttendees')}.`}
                    </Typography>
                </Typography>
            </SimpleNoModalDialog>
        </PopUpModal>
    )
}

export default PopUpLowPriorityRegistrationWarning