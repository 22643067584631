import { useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@material-ui/core'
import { getFromSafeObject, getInsertableDate, isValidDate, textTransform, toTimescaleFromMilliseconds } from '../../aux/aux'
import { getMeetingOccurrencies } from '../../services/meetingServices'

import PopUpModal from '../PopUpModal/PopUpModal'
import PopUpModalHeader from '../PopUpModalHeader/PopUpModalHeader'
import SimpleNoModalDialog from '../SimpleNoModalDialog/SimpleNoModalDialog'
import LoadingComponent from '../LoadingComponent/LoadingComponent'
import MeetingOccurrenceRegisterForm from '../MeetingOccurrenceRegisterForm/MeetingOccurrenceRegisterForm'
// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import noConnection from '../../lotties/noConnection_200x200.json';
import GroupsIcon from '@mui/icons-material/Groups';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import EventIcon from '@mui/icons-material/Event';
import './popUpMeetingOccurrenceRegister.css'
import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper'
import { getReliableLocale } from '../../aux/sessionHelpers'
import { clearTimeout_, setTimeout_ } from '../../aux/extendedTimeout'
import { getIn } from 'immutable';




const PopUpMeetingOccurrenceRegister = ({ meetingId, show, isLowPriority, lowPriorityRegistrationWindowMs, securityOpenMeetingWindowMs, onRegisterMeetingOccurrence, onClickClose, header, modal=true }) => {
    const POP_UP_WIDTH= 380;
    const MAX_LEFT_REGISTRATIONS_TO_BE_BUSY = 5;
    // DEBUG Starts
    const locale = getReliableLocale();
    // DEBUG Ends
    const { t } = useTranslation('common', { keyPrefix: 'popUpMeetingOccurrenceRegister' });
    const animationRef = useRef(null);
    const animationContainerRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isRegistering, setIsRegistering] = useState(false);
    const [meetingOccurrenceMap, setMeetingOccurrenceMap] = useState(null);
    const [selectedOccurrenceId, setSelectedOccurenceId] = useState(null);
    const [error, setError] = useState({
        isRequestingError:false,
        isRegistrationError:false,
        msg:'',
    });
    const [restablishingRequestDateMap, setRestablishingRequestDateMap] = useState({});
    const abortController = new AbortController()
    const abortControllerRef =  useRef(null);
    const styles = {
        busyIcon:{
            display: 'block',
            paddingRight: '10px',
            fontSize: '1.2em',
        }
    }

    const handleOnMeetingRegister = async(e, signal=abortControllerRef.current.signal) => {
        try{
            setIsRegistering(true);
            await onRegisterMeetingOccurrence(selectedOccurrenceId, true, signal)
            if(modal){
                onClickClose();
            }
        }catch(error){
            if(!signal.aborted){
                const cause = getFromSafeObject(error, 'response.data.cause');
                let msg;
                switch(cause){
                    case 'mp_zoom_401':
                        const restablishingDate = new Date();
                        const today = restablishingDate.getDate();
                        restablishingDate.setUTCHours(0, 0, 0, 0);
                        restablishingDate.setUTCDate(today + 1);
                        setRestablishingRequestDateMap( prevMap => ({...prevMap, [selectedOccurrenceId]: restablishingDate }));
                        msg = `${textTransform('title', t('requestLimitExceeded'))}`
                            +`\n${restablishingDate.toLocaleString(locale.localeCode)}`
                        break;
                    default:
                        msg = `${textTransform('title', t('registrationError'))}`
                            +`\n${textTransform('title', t('tryLater'))}`
                        break;
                }
                setError(prev => ({ 
                    ...prev, 
                    isRegistrationError:true,
                    msg
                }));
            }
        }finally{
            if(!signal.aborted){
                setIsRegistering(false);
            }
        }
    }
    const handleOnUpdateForm = (occurrenceId) => {
        setSelectedOccurenceId(occurrenceId)
    }

    const requestMeetingOccurrencies = async(meetingId, signal=abortControllerRef.current.signal) => {
        try{
            setIsLoading(true);
            const now = new Date();
            const query = {
                filterConditionArray: [
                    `status = open`,
                    `registration_starts_date_time <= ${getInsertableDate(now)}`,
                    `registration_ends_date_time > ${getInsertableDate(now)}`,
                ]
            };
            const res = await getMeetingOccurrencies(meetingId, query, 1000, signal);
            const meetingOccurrenceMap = res.data.meetingOccurrenceMap;
            if(!signal.aborted){
                setMeetingOccurrenceMap(meetingOccurrenceMap);
            }
        }catch(error){
            if(!signal.aborted){
                setError(prev => ({
                    ...prev, 
                    isRequestingError: true,
                    msg: `${textTransform('title', t('requestingError'))}`
                        + `\n${textTransform('title', t('tryLater'))}`
                }));
            }
        }finally{
            if(!signal.aborted){
                setIsLoading(false);
            }
        }
    }

    useEffect(() => {
        if(error.isRegistrationError){
            const resetRegistratonError = () => {
                setError(prev => ({
                    ...prev,
                    isRegistrationError:false,
                }))
            };
            let timeoutId = setTimeout_(resetRegistratonError, 3000);
            return(() => {
                clearTimeout_(timeoutId)
            })
        }
        if(error.isRequestingError){
            if(animationContainerRef.current != null){
                animationRef.current = lottie.loadAnimation({
                    container: animationContainerRef.current,
                    renderer: 'svg',
                    autoplay: true,
                    animationData: noConnection,
                    loop: false,
                    name: 'animation'
                })
            }
            return(() => {
                if(animationRef.current != null){
                    animationRef.current.stop();
                    animationRef.current?.destroy();
                }
            })
        }
    }, [error])

    const resetingComponent = () => {
        setMeetingOccurrenceMap(null);
        setSelectedOccurenceId(null);
        setError(prev => ({ isRegistrationError:false , isRequestingError: false, msg: ''}))
    }

    useEffect(() => {
        if(modal){
            if(show){
                abortControllerRef.current = abortController;
                requestMeetingOccurrencies(meetingId);
                return(() => {
                    abortControllerRef.current.abort();
                })
            }else{
                resetingComponent();
            }
        }else{
            abortControllerRef.current = abortController;
            requestMeetingOccurrencies(meetingId);
            return(() => {
                abortControllerRef.current.abort();
            })
        }
    },[modal,show])

    useEffect(() => {
        const restablishingRequestDate = restablishingRequestDateMap[selectedOccurrenceId];
        if(restablishingRequestDate){
            const now = new Date();
            const timeToRestablishing = restablishingRequestDate.getTime() - now.getTime()
            const timeoutId = setTimeout_(() => {
                setRestablishingRequestDateMap( prev => {
                    const updatedMap = { ...prev };
                    delete updatedMap[selectedOccurrenceId];
                    return { ...updatedMap }
                });
            }, timeToRestablishing)
            return(() => { clearTimeout_(timeoutId) })
        }
    }, [restablishingRequestDateMap])

    return (
        <ConditionalWrapper 
            condition={modal}
            wrapperFn={ children => 
                <PopUpModal 
                    showModal={show} 
                    onClickClose={onClickClose} 
                    width={POP_UP_WIDTH}
                >
                    {children}
                </PopUpModal>
            }
        >
            {meetingOccurrenceMap == null ?
                !error.isRequestingError ?
                        <div className='popup-meeting-occurrence-register-loading-container'>
                            <LoadingComponent visibleElements='circle' />    
                        </div>
                    :
                        <div className='popup-meeting-occurrence-register-requesting-error-container'>
                            <div className='popup-meeting-occurrence-register-requesting-error-animation' ref={animationContainerRef} />
                            <Typography className='popup-meeting-occurrence-register-requesting-error-msg' variant='body1'>
                                {
                                    `${textTransform('title', t('requestingError'))}.`
                                    + `\n${textTransform('title', t('tryLater'))}`
                                }
                            </Typography>
                        </div>
                :
                <>
                    {header && 
                        <PopUpModalHeader 
                            Icon={EventIcon}
                            title={textTransform('title',header)} 
                        />
                    }
                    <SimpleNoModalDialog 
                        isLoading={isRegistering}
                        isDisabled={isRegistering || !selectedOccurrenceId || isValidDate(restablishingRequestDateMap[selectedOccurrenceId])}
                        onDisagree={onClickClose} 
                        onAgree={handleOnMeetingRegister}
                        className='popup-meeting-occurrence-register-meeting-dialog-container'
                        isError={error.isRegistrationError}
                        errorMsg={error.msg}
                    >
                        <MeetingOccurrenceRegisterForm 
                            meetingOccurrenceMap={meetingOccurrenceMap}
                            isDisabled={error.isRequestingError}
                            clearForm={!show}
                            onUpdateForm={handleOnUpdateForm}   
                            maxToBusy={MAX_LEFT_REGISTRATIONS_TO_BE_BUSY}
                            BusyIcon={GroupsIcon}
                            isLowPriority={isLowPriority}
                            lowPriorityRegistrationWindowMs={lowPriorityRegistrationWindowMs}
                            securityOpenMeetingWindowMs={securityOpenMeetingWindowMs}
                        />
                        <Grid container direction='column'>
                            <Grid item container alignItems='center' style={{paddingTop:'5px'}}>
                                <Grid item>
                                    <GroupsIcon style={styles.busyIcon}/>
                                </Grid>
                                <Grid item>
                                    <Typography variant='caption'>
                                        {t('meansFewSeatsAvailable', { max: MAX_LEFT_REGISTRATIONS_TO_BE_BUSY })}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </SimpleNoModalDialog>
                    {restablishingRequestDateMap[selectedOccurrenceId] &&
                        <div className='popup-meeting-occurrence-register-restablishing-request-info'>
                            <Typography variant='caption'>
                                {`* ${textTransform('title', t("requestWillBeRestablished"))}: ${restablishingRequestDateMap[selectedOccurrenceId].toLocaleString(locale.localeCode)}`}
                            </Typography>
                        </div>
                    }
                </>
            }
        </ConditionalWrapper>
    )
}

export default PopUpMeetingOccurrenceRegister