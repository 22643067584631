import { lazy } from 'react';
import { useTranslation } from 'react-i18next'
import WorkguideFeature from '../WorkguideFeature/WorkguideFeature';
// import AdaptableIcon from '../../img/workguide-features-adaptable_300x300.svg';
// import SequenceIcon from '../../img/workguide-features-sequence_300x300.svg';
// import UnderstandingIcon from '../../img/workguide-features-understanding_300x300.svg';
import './workguideFeatures.css'
import { Typography, useMediaQuery } from '@material-ui/core';
import { textTransform } from '../../aux/aux';

const WorkguideFeatures = ({ title, subtitle, noVerticalPadding=false}) => {
    const { t } = useTranslation('contentProduct', { keyPrefix: 'workguideFeatures'});
    const matchMobile = useMediaQuery('(max-width:680px)');
    const workguideFeaturesArray = t('featuresArray', { returnObjects:true, defaultValue:[] }) ;
    const svgDir = require.context('!@svgr/webpack!../../img', true, /^\.\/workguide-features-.+\.svg$/);
    const styles = {
        title:{
            fontWeight: 'bold',
            textAlign: 'center'
        },
        subtitle:{
            fontWeight: 'bold'
        },
    }
    return (
        <div className='workguide-features-wrapper'>
            <div className={`workguide-features-main-container${noVerticalPadding ? ' no-vertical-padding' : ''}`}>
                {title &&
                    <div className='workguide-features-title-container'>
                        <Typography variant={matchMobile ? 'h5':'h4'} style={styles.title}>
                            {textTransform('title', title)}
                        </Typography>
                    </div>
                }
                {subtitle &&
                    <div className='workguide-features-subtitle-container'>
                        <Typography variant={matchMobile ? 'h6':'h5'} style={styles.subtitle}>
                            {textTransform('title', subtitle)}
                        </Typography>
                    </div>
                }
                <div className='workguide-features-content-container'>
                    {workguideFeaturesArray.map((feature, index) => {
                        feature.Icon = svgDir(`./${feature.imgURL}`).default;
                        return(
                            <div className='workguide-features-content-feature'>
                                <WorkguideFeature key={index} feature={feature} />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default WorkguideFeatures