import { createContext, useState } from "react"
import { isNotEmptyObject } from "../aux/aux";

const InfoBannerPropsContext = createContext(null);
const InfoBannerPropsUpdaterContext = createContext(null);

const InfoBannerPropsProvider = ({ children }) => {
    const INITIAL_INFO_BANNER_PROPS = {
        style:{
            position: 'sticky', 
            bottom: '0px', 
            width: '100%',
            zIndex: 1 // Updated 04/18/24 for landing
        }
    }
    const [infoBannerProps, _setInfoBannerProps] = useState(INITIAL_INFO_BANNER_PROPS);

    const setInfoBannerProps = (props) => {
        if(props && isNotEmptyObject(props)){
            _setInfoBannerProps(prev => {
                const newProps = Object.entries(prev).reduce((propsObject, [key, value])=>{
                    let newValue;
                    if(props.hasOwnProperty(key)){
                        if(typeof value === 'object'){
                            newValue = {...value, ...props[key]}
                        }else{
                            newValue = props[key];
                        }
                    }else{
                        newValue = value;
                    }
                    propsObject[key] = newValue;
                    return propsObject
                }, {})
                // {...prev, ...props, style:{...prev.style, ...props.style}}
                return newProps
            })
        }else{
            _setInfoBannerProps(INITIAL_INFO_BANNER_PROPS);
        }
    }

    return(
        <InfoBannerPropsContext.Provider value={infoBannerProps}>
            <InfoBannerPropsUpdaterContext.Provider value={setInfoBannerProps}>
                { children }
            </InfoBannerPropsUpdaterContext.Provider>
        </InfoBannerPropsContext.Provider>
    )
}

export { InfoBannerPropsProvider , InfoBannerPropsContext, InfoBannerPropsUpdaterContext }