import { IconButton } from '@material-ui/core';
// Helpers
import { isEmptyObject, textTransform } from '../../aux/aux';
//Img and Icons
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
// Style
import './classContentNavLinks.css'

const ClassContentNavLinks = ({ color, contentNav, contentNavMoves }) => {
    const styles = {
        icon:{
            display:'block'
        }
    }
    return (
        <div className='class-content-nav-links-main-container'>
            <IconButton size='small' onClick={contentNavMoves.prevElement} color={color} disabled={isEmptyObject(contentNav.prev)}>
                <NavigateBeforeIcon style={styles.icon}/>
            </IconButton>
            <IconButton size='small' onClick={contentNavMoves.nextElement} color={color} disabled={isEmptyObject(contentNav.next)}>
                <NavigateNextIcon style={styles.icon}/>
            </IconButton>
        </div>
    )
}

export default ClassContentNavLinks