import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core'
import {useTheme } from '@material-ui/styles'
import { textTransform } from '../../aux/aux'
import ButtonLoading from '../ButtonLoading/ButtonLoading'
import ToogleMsg from '../ToggleMsg/ToggleMsg'
import './meetingGrantedRepetitionFloatingBadge.css'

const MeetingGrantedRepetitionFloatingBadge = ({ onClaimGrant }) => {
  const { t } = useTranslation('common', { keyPrefix: 'meetingGrantedRepetitionFloatingBadge'});
  const theme = useTheme()
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const styles = {
    mainContainer:{
      color: theme.palette.text.contrast,
    },
    backgroundContainer:{
      background: theme.palette.surface.dark[5],
      opacity:0.9
    }
  }
  const handleOnClaimGrant = async () => {
    try{
      setIsLoading(true);
      await onClaimGrant()
    }catch(error){
      setIsError(true);
    }finally{
      setIsLoading(false);
    }
  }

  return (
    <>
      <div className='meeting-granted-repetition-floating-badge-background'style={styles.backgroundContainer}/>
      <Grid container direction='column' className='meeting-granted-repetition-floating-badge-container' style={styles.mainContainer}>
        <Grid item align='center'>
          <Typography variant='body1'>
            {textTransform('title', t('youAreElegibleToBeGranted'))}
          </Typography>
        </Grid>
        <Grid item className='meeting-granted-repetition-floating-badge-button'>
          <ButtonLoading 
            isLoading={isLoading}
            variant='contained'
            color='primary' 
            label={t('claimGrant')}
            onClick={handleOnClaimGrant}
          />
        </Grid>
        <Grid item className={`meeting-granted-repetition-floating-badge-error-container ${isError ? 'show' : ''}`}>
          <ToogleMsg 
             msg={
              `${textTransform('title', t('requestIsNotGrantedNow'))}`
              +`. ${textTransform('title', t('tryLater'))}`
            }
            isShown={isError}
            onShowTime={() => setIsError(false)} 
          />
        </Grid>
      </Grid>
    </>
  )
}

export default MeetingGrantedRepetitionFloatingBadge