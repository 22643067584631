import { useTheme } from '@material-ui/styles'
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useTranslation } from 'react-i18next';
import CustomChip from '../CustomChip/CustomChip';


const NotIncludedInSubscriptionChip = ({ subscriptionTypeName }) => {
    const { t } = useTranslation('common',{ keyPrefix:'notIncludedInSubscriptionChip'});
    const theme = useTheme()
    const styles = {
        chip:{
            padding:'0px 4px',
        },
        icon:{
            color:theme.palette.warning.main,
            display:"block",
            fontSize: theme.typography.body2.fontSize,
        },
    }
    return (
        <CustomChip
            color={theme.palette.warning.main}
            variant='outlined'
            size='small'
            label={t('notIncludedInYourSubscriptionType', {subscriptionTypeName: subscriptionTypeName})}
            icon={<WarningAmberIcon style={styles.icon} /> }
            style={styles.chip}
        />

    )
}

export default NotIncludedInSubscriptionChip