import { useCallback, useEffect, useRef, useState } from 'react';
import { useTheme } from '@material-ui/styles';
import { useTranslation } from 'react-i18next'
import { checkAPIHealth } from '../../services/visitorServices';
import { Button, Typography } from '@material-ui/core';
import './bannerAPIConnectivity.css'
import LoadingComponent from '../LoadingComponent/LoadingComponent';

const API_CONNECTIVITY_STATUS_ARRAY = ['online', 'waiting', 'offline'];

const BannerAPIConnectivity = ({ showInMs=2000 }) => {
    const { t } = useTranslation('common', { keyPrefix: 'bannerAPIConnectivity' });
    const theme = useTheme();
    const CHECK_CONNECTIVITY_INTERVAL_MS = 2000 // 2s
    const CHECK_MAX_COUNTER = 30 // 2 * 30 = 60s = 1min
    const [apiConnectivityStatus, setAPIConnectivityStatus] = useState(null);
    const [connectivityCheckCounter, setConnectivityCheckCounter] = useState(0);
    const [bannerMsg, setBannerMsg] = useState('');
    const [isShown, setIsShown] = useState(false);
    const abortControllerRef = useRef(null);
    const styles = {
        wraper:{
            backgroundColor: theme.palette.background.dark,
        },
        mainContainer:{
            // backgroundColor: theme.palette.background.dark,
            color: theme.palette.text.contrast
        },
        icon:{
            backgroundColor: apiConnectivityStatus === API_CONNECTIVITY_STATUS_ARRAY[0] ? theme.palette.success.main : apiConnectivityStatus === API_CONNECTIVITY_STATUS_ARRAY[1] ? theme.palette.warning.main : theme.palette.error.main 
        },
        button:{
            backgroundColor: theme.palette.common.white
        }
    }
    const checkAPIConnection = useCallback( async (signal) => {
        try{
            const res = await checkAPIHealth(signal);
            if(!signal.aborted){
                setAPIConnectivityStatus(API_CONNECTIVITY_STATUS_ARRAY[0]);
            }
        }catch(error){
            if(!signal.aborted){
                setConnectivityCheckCounter(prev => { 
                    let updatedCounter = prev + 1;
                    if(updatedCounter < CHECK_MAX_COUNTER){
                        setAPIConnectivityStatus(API_CONNECTIVITY_STATUS_ARRAY[1]);
                    }else{
                        setAPIConnectivityStatus(API_CONNECTIVITY_STATUS_ARRAY[2])
                        updatedCounter = 0;
                    }
                    return updatedCounter;
                })
            }
        }
    },[]);

    const handleOnRefresh = (e) => {
        e.preventDefault();
        window.location.reload();
    }

    useEffect(() => {
        const handleCheckConnectivity = (e) => {
            const {
                status
            } = e.detail;
            if(API_CONNECTIVITY_STATUS_ARRAY.includes(status)){
                setAPIConnectivityStatus(status);
            }
        }
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        checkAPIConnection(abortController.signal);
        document.addEventListener('bannerAPIConnectivity', handleCheckConnectivity);
        return(() => {
            abortController.abort();
            document.removeEventListener('bannerAPICOnnectivity', handleCheckConnectivity);
        })
    }, [])

    useEffect(() => {
        let clearFn;
        switch(apiConnectivityStatus){
            case API_CONNECTIVITY_STATUS_ARRAY[0]:
                setBannerMsg(`${t('connectionRestablished')}. ${t('refreshTheBrowser')}`);
                break;
            case API_CONNECTIVITY_STATUS_ARRAY[1]:
                const checkAPIConnectivityIntervalId = setInterval(() => checkAPIConnection(abortControllerRef.current.signal), CHECK_CONNECTIVITY_INTERVAL_MS)
                setBannerMsg(`${t('waitingForConnection')}. ${t('limitedFunctionality')}`);
                const isShownTimeoutId = setTimeout(() => setIsShown(true), showInMs);
                clearFn = () => {
                    clearInterval(checkAPIConnectivityIntervalId)
                    clearTimeout(isShownTimeoutId);
                }
                break;
            case API_CONNECTIVITY_STATUS_ARRAY[2]:
                setBannerMsg(`${t('lostConnection')}. ${t('limitedFunctionality')}`);
                break;
            default:
                setBannerMsg('');
                setIsShown(false);
                // undefined
        }
        return(clearFn)
    },[apiConnectivityStatus])
    return (
        <div className={`banner-api-connectivity-wrapper${isShown ? ' show' : ''}`} style={styles.wraper}>
            <div className='banner-api-connectivity-main-container' style={styles.mainContainer}>
                <div className='banner-api-connectivity-content'>
                    <div className='banner-api-connectivity-icon-container'>
                        <div className='banner-api-connectivity-icon' style={styles.icon} />
                    </div>
                    <div className='banner-api-connectivity-message-container'>
                        <Typography className='banner-api-connectivity-message' variant='body2' style={styles.message}>
                            {bannerMsg}
                        </Typography>
                    </div>
                </div>
                { apiConnectivityStatus === API_CONNECTIVITY_STATUS_ARRAY[1] &&
                    <div className='banner-api-connectivity-loading-container'>
                        <LoadingComponent visibleElements='circle' circleSize='1.2em'/>
                    </div>
                }
                {apiConnectivityStatus === API_CONNECTIVITY_STATUS_ARRAY[0] &&
                    <div className='banner-api-connectivity-button-container'>
                        <Button color='primary' variant='contained' size='small' onClick={handleOnRefresh}>
                            {t('refresh')}
                        </Button>
                    </div>
                }
            </div>
        </div>
    )
}
export default BannerAPIConnectivity
export { API_CONNECTIVITY_STATUS_ARRAY }