import { useState, useRef, useCallback } from 'react'
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import TextEditor from '../TextEditor/TextEditor'
import AddIcon from '@mui/icons-material/Add';
import ButtonLoading from '../ButtonLoading/ButtonLoading'

import './addUserNote.css'
import { useEffect } from 'react';
import { Height } from '@material-ui/icons';

const AddUserNote = ({ noteTypeName, onCreate, maxNoteLength=1000, isRightPosition=false, ...props }) => {
    const EDITOR_MIN_HEIGHT = 200;
    const theme = useTheme();
    const { t } = useTranslation('common');
    const abortControllerRef = useRef(null);
    const [showEditor, setShowEditor] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isError, setIsError] = useState(false);
    const [exportableEditorState, setExportableEditorState] = useState(null);
    const {
        errorMsg = t('error'),
        errorTimeoutMs = 1000,
        errorStyle = {
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main,
        }
    } = props;
    const styles = {
        addUserNoteContainer: {
            width: `calc(100% - 20px)` // new
            // width: showEditor ? 'calc(100% - 20px)' : '35px',
        },
        addNoteButtonWrapper: {
            backgroundColor: theme.palette.primary.main,
        },
        cancelNoteButtonWrapper: {
            backgroundColor: '#f3f3f3', 
            // backgroundColor: theme.palette.error.main,
            transform: 'rotate(45deg)'
        },
        addNoteButtonIcon: {
            color: '#f3f3f3'
        },
        cancelNoteButtonIcon: {
            color: theme.palette.primary.main,
        }

    }
    const handleOpenEditor = () => {
        setShowEditor( showEditor => !showEditor)
    }

    const handleOnUpdateEditor = ( exportableEditorState) => {
        setExportableEditorState(exportableEditorState);
    }

    const handleOnSaveClick = async (e, signal=abortControllerRef.current.signal) => {
        e.preventDefault();
        setIsSaving(true);
        const newNote = {
            noteTypeName,
            plainText: exportableEditorState.plainText,
            jsonStyledText: JSON.stringify(exportableEditorState.rawBlocks)
        }
        const isSave = await onCreate(newNote, signal);
        if(!signal.aborted){
            setIsSaving(false);
            if(isSave){
                handleOpenEditor();
            }else{
                setIsError(true);
            }
        }
    };

    useEffect(() => {
        if(isError){
            const timeoutId = setTimeout(() => { setIsError(false) }, errorTimeoutMs);
            return(() => { clearTimeout(timeoutId) })
        }
    }, [isError])

    useEffect(() => {
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        return(() => {
            abortController.abort();
        })
    }, [])
    
    return (
        <Grid container direction='column' className={`add-user-note-container${isRightPosition ? ' right': ''}`} style={ styles.addUserNoteContainer }>
            <Grid item container className={`add-user-note-editor-wrapper${ showEditor ? ' open' : '' }${isRightPosition ? ' right': ''}`}>
                <Grid container direction='column' className={`add-user-note-editor ${showEditor ? 'open' : ''}`} >
                    <Grid item xs>
                        <TextEditor 
                            clearEditor={ showEditor } 
                            onUpdateEditor={handleOnUpdateEditor}
                            onShiftAndEnter={handleOnSaveClick}
                            maxTextLength={maxNoteLength}
                            textAreaWrapperStyle={
                                { 
                                    overflowY: 'auto',
                                    padding: '10px',
                                    minHeight: EDITOR_MIN_HEIGHT
                                }}
                            />
                    </Grid>
                    <Grid item className='add-user-note-editor-button-container'>
                        <ButtonLoading
                            isLoading={isSaving}
                            disabled={isSaving}
                            onClick={handleOnSaveClick} 
                            variant='contained' 
                            color='primary' 
                            fullWidth 
                            label={ t('save') }
                        />
                    </Grid>
                    <Grid item className={`add-user-note-editor-error-toogle ${isError ? 'show' : ''}`} style={ errorStyle }>
                        <Typography variant='body2'>
                            { errorMsg }
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <div className='add-user-note-circular-button-container' style={ showEditor ? styles.cancelNoteButtonWrapper : styles.addNoteButtonWrapper }>
                    <AddIcon  
                        className='add-user-note-circular-button-icon' 
                        onClick={handleOpenEditor} 
                        style={ showEditor ? styles.cancelNoteButtonIcon : styles.addNoteButtonIcon }
                        />
                </div>
            </Grid>
        </Grid>
    )
}

export default AddUserNote