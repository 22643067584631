import { Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { getFromSafeObject, isNotEmptyObject, textTransform, toMillisecondsFromTimescale, toTimescaleFromMilliseconds } from '../../aux/aux';
import Fire  from '../../img/fire.svg'; 
import Trophy  from '../../img/trophy.svg'; 
import Workguide  from '../../img/workguide.svg'; 
import Certification  from '../../img/certification.svg'; 
import Insights  from '../../img/insights.svg'; 
import SelfImprovement  from '../../img/selfImprovement.svg'; 
import { useTranslation } from 'react-i18next';
import './contentBasicFeatures.css';
import { lengthInMinToDurationString } from '../../aux/contentProductHelpers';
const DEFAULT_CONTENT_FEATURES = {
    contentTypeNameTranslated:undefined,
    lengthInMin:undefined,
    knowledgeLevelName:undefined,
    outcomeTypeName:undefined
}
const ContentBasicFeatures = ({ contentTypeName, contentFeatures=DEFAULT_CONTENT_FEATURES }) => {
    const ICON_SIZE_PX = 24;
    // Order is relevant. First feature won't be shown if matchTablet
    const FEATURED_FEATURES_ARRAY_BY_CONTENT_TYPE_MAP = {
        course: ['content', 'level', 'length', 'outcome'],
        protocol: ['content', 'level', 'length', 'outcome']
    }
    const { t } = useTranslation(['common', 'contentProduct']);
    const theme = useTheme()
    const matchTablet = useMediaQuery('(min-width:681px) and (max-width:1119px)');
    const matchMobile = useMediaQuery('(max-width:680px)')
    const styles = {
        wrapper: {
            backgroundColor: matchMobile ? theme.palette.background.dark : undefined,
        },
        feature:{
            borderColor: matchMobile ? theme.palette.dividerOnDark : theme.palette.divider 
        },
        featureName:{
            color: theme.palette.grey[400]
        },
        featureValue: {
            color: matchMobile ? theme.palette.text.contrast : undefined
        }
        ,
        featureIcon:{
            display: 'block',
            height: `${ICON_SIZE_PX}px`, // theme.typography.body1.fontSize
            width: `${ICON_SIZE_PX}px`,
            fill: matchMobile ? theme.palette.text.contrast : undefined
        }
    }
    const getFeatureName = (featureKey) => {
        return t(`contentProduct:contentBasicFeatures.${featureKey}`);
    }
    const getFeatureValue = (content, featureKey) => {
        let value;
        switch(featureKey){
            case 'content':
                value = content.contentTypeNameTranslated;
                break;
            case 'length':
                value = lengthInMinToDurationString(content.lengthInMin, t("common:hourAbbr"), t("common:minAbbr"))
                break;
            case 'level':
                let level = content.knowledgeLevelName;
                if(level){
                    value = t(`contentProduct:level.${level}`)
                }
                break;
            case 'outcome':
                let outcome = content.outcomeTypeName;
                if(outcome){
                    value = t(`contentProduct:outcome.${outcome}`)
                }
                break;
        }  
        return value
    }
    const getFeatureIcon = (content, featureKey) => {
        let icon;
        let value = content.outcomeTypeName;
        if(featureKey === 'outcome' && value){
            switch(value){
                case 'deepening':
                    icon = Fire;
                    break;
                case 'confindence_improvement':
                    icon = SelfImprovement;
                    break;
                case 'achievement':
                    icon = Trophy;
                    break;
                case 'career_boost':
                    icon = Insights;
                    break;
                case 'certification':
                    icon = Certification;
            }
        }
        return icon
    }
    return (
        isNotEmptyObject(FEATURED_FEATURES_ARRAY_BY_CONTENT_TYPE_MAP[contentTypeName]) ?
            <div className='content-basic-features-wrapper' style={styles.wrapper}>
                <div className='content-basic-features-main-container'>
                    {FEATURED_FEATURES_ARRAY_BY_CONTENT_TYPE_MAP[contentTypeName].map((featureKey, index) => {
                        const name = getFeatureName(featureKey);
                        const value = getFeatureValue(contentFeatures, featureKey);
                        const Icon = getFeatureIcon(contentFeatures, featureKey);
                        const skip = matchTablet && Object.keys(contentFeatures).length > 3 && index === 0 ? true : false;
                        return(
                            value && name && !skip ?
                                <div key={index} className={`content-basic-feature-container${index === 0 ? ' first' : ''}`} style={ styles.feature }>
                                    <Typography variant='body2' style={styles.featureName}>
                                        {textTransform('title', name)}
                                    </Typography>
                                    <div className='content-basic-feature-value'>
                                        {Icon != null &&
                                            <div className='content-basic-feature-value-icon'>
                                                <Icon style={styles.featureIcon}/>
                                            </div>
                                        }
                                        <div className='content-basic-feature-value-label'>
                                            <Typography variant='body1' style={styles.featureValue}>
                                                {textTransform('title', value)}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            :
                                null
                        )
                    })}
                </div>
            </div>
            :
            null
    )
}

export default ContentBasicFeatures