import ReactDOM from 'react-dom';
import { useEffect, useRef, useState } from 'react'
import './customPopover.css'
import { useLayoutEffect } from 'react';
import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper';
/**
 * Note: timeTransform to 0 or null does not trigger onClose callback. Open must be handled outside this component
 */
const CustomPopover = ({ open, anchorEl, onClose=()=>{}, timeTransform=1000, anchorOrigin={vertical:'top',
horizontal: 'center'}, transformOrigin={vertical:'bottom',
horizontal: 'center'}, styleDecorator={}, styleContainer={}, ...props}) => {
  const timeoutTime = timeTransform;
  const decoratorSize = 10;
  const decoratorCornerPadding = 10;
  const containerMargin = 10;
  const [popoverRef, setPopoverRef] = useState(null);
  const [popoverBoundingBox, setPopoverBoundingBox] = useState({});
  const [originPosition, setOriginPosition] = useState({});
  const [decoratorPosition, setDecoratorPosition] = useState({});
  const [margin, setMargin] = useState({});
  const styles = {
    container:{
      backgroundColor: '#ffffff',
      top: `${originPosition.top}px`,
      left: `${originPosition.left}px`,
      marginTop:`${margin.top}px`,
      marginLeft:`${margin.left}px`,
      marginBottom: `${margin.bottom}px`, 
      marginRight: `${margin.right}px`,
      ...styleContainer
    },
    decorator:{
      backgroundColor: '#ffffff',
      top: `${decoratorPosition.top}px`,
      left: `${decoratorPosition.left}px`,
      width: `${decoratorSize}px`,
      height: `${decoratorSize}px`,
      ...styleDecorator
    }
  }
  const getAnchorOriginVerticalPosition = (verticalOrigin, anchorBoundingPosition) => {
    let topPosition;
    switch(verticalOrigin){
      case 'center':
        topPosition = anchorBoundingPosition.top + (anchorBoundingPosition.height / 2);
        break;
      case 'bottom':
        topPosition = anchorBoundingPosition.bottom;
        break;
      case 'top':
      default:
        topPosition = anchorBoundingPosition.top;
    } 
    return topPosition;
  }

  const getTransformOriginVerticalPosition = (verticalTransform, popoverBoundingBox) => {
    let topPosition;
    switch(verticalTransform){
      case 'center':
        topPosition = - (popoverBoundingBox.height / 2);
        break;
      case 'bottom':
        topPosition = - popoverBoundingBox.height;
        break;
      case 'top':
      default:
        topPosition = 0;
    }
    return topPosition;
  }

  const getAnchorOriginHorizontalPosition = (horizontalOrigin, anchorBoundingBox) => {
    let leftPosition;
    switch(horizontalOrigin){
      case 'right':
        leftPosition = anchorBoundingBox.right;
        break;
      case 'center':
        leftPosition = anchorBoundingBox.left + (anchorBoundingBox.width/2)
        break;
      case 'left':
      default:
        leftPosition = anchorBoundingBox.left;
    } 
    return leftPosition;
  }

  const getTransformOriginHorizontalPosition = (horizontalTransform, popoverBoundingBox) => {
    let leftPosition;
    switch(horizontalTransform){
      case 'right':
        leftPosition =  - popoverBoundingBox.width;
        break;
      case 'center':
        leftPosition = - (popoverBoundingBox.width/2)
        break;
      case 'left':
      default:
        leftPosition = 0;
    } 
    return leftPosition;
  }

  const getDecoratorTransformTopOffset = (verticalTransform, verticalOrigin, popoverBoundingBox, decoratorSize) => {
    let topPosition;
    if(verticalOrigin !== verticalTransform){
      switch(verticalTransform){
        case 'center':
          switch(verticalOrigin){
            case 'bottom':
              topPosition = -decoratorSize/2
              break;
            default:
              topPosition = popoverBoundingBox.height - decoratorSize/2
          }
          break;
        case 'top':
          topPosition = -decoratorSize/2
          break;
        case 'bottom':
        default:
          topPosition = popoverBoundingBox.height - (decoratorSize / 2);
      }
    }else{
      topPosition = popoverBoundingBox.height/2 - decoratorSize/2
    }
    return topPosition;
  }

  const getDecoratorTransformLeftOffset = (horizontalTransform, horizontalOrigin, popoverBoundingBox, decoratorSize) => {
    let leftPosition;
    switch(horizontalTransform){
      case 'center':
        switch(horizontalOrigin){
          case 'right':
            leftPosition = -decoratorSize/2
            break;
          case 'left':
            leftPosition = popoverBoundingBox.width - decoratorSize/2
            break;
          case 'center':
            leftPosition = popoverBoundingBox.width/2 - decoratorSize/2
            break;
        }
        break;
      case 'right':
        leftPosition =  popoverBoundingBox.width - decoratorSize/2
        break;
      case 'left':
      default:
        leftPosition =  - decoratorSize / 2;
    }
    return leftPosition;
  }

  const decoratorOffsetCornerCorrection = ( top, left, popoverBoundingBox, decoratorSize ) => {
    let correction = {left:0, top:0};
    const centerPoint = [popoverBoundingBox.width/2 - decoratorSize/2 , popoverBoundingBox.height/2 - decoratorSize/2]; 
    const topThresholds = [popoverBoundingBox.height - decoratorSize/2, -decoratorSize/2];
    const leftThresholds = [popoverBoundingBox.width - decoratorSize/2, -decoratorSize/2];
    const isTopInThreshold = topThresholds.find( threshold => top === threshold);
    const isLeftInThreshold = leftThresholds.find( threshold => left === threshold);
    // Corner excess correction
    if(isTopInThreshold && isLeftInThreshold){
      if(isTopInThreshold === topThresholds[0]){
        if(isLeftInThreshold === leftThresholds[0]){
          // top-left corner
          correction.left -= decoratorSize/2
        }else{
          // top-right corner
          correction.left += decoratorSize/2
        }
      }else{
        if(isLeftInThreshold === leftThresholds[0]){
          // bottom-left corner
          correction.left -= decoratorSize/2
        }else{
          // bottom-right corner
          correction.left += decoratorSize/2
        }
      }
    }
    // Corner Padding
    if(left !== centerPoint[0] && top !== centerPoint[1]){
      const tempTop = top + correction.top
      const tempLeft = left + correction.left
      if(tempLeft > centerPoint[0]){
        // it is on the right side
        if(tempTop < 0 || tempTop >= topThresholds[0]){
          correction.left -= decoratorCornerPadding
        }else{
          if(tempTop < centerPoint[1]){
            correction.top += decoratorCornerPadding
          }else{
            correction.top -= decoratorCornerPadding
          }
        }
      }else if(tempLeft < centerPoint[0]){
        // it is on the left side
        if(tempTop < 0 || tempTop >= topThresholds[0]){
          correction.left += decoratorCornerPadding
        }else{
          if(tempTop < centerPoint[1]){
            correction.top += decoratorCornerPadding
          }else{
            correction.top -= decoratorCornerPadding
          }
        }
      }
    }
    return correction;
  }

  const getMarginFromAnchorOrigin = (transformOrigin) => {
    const margin = {top:0,right:0,bottom:0,left:0}
    switch(transformOrigin.vertical){
      case 'top':
        margin.top = containerMargin;
        break;
      case 'bottom':
        margin.bottom = containerMargin;
        break;
    }
    switch(transformOrigin.horizontal){
      case 'left':
        margin.left = containerMargin;
        break;
      case 'right':
        margin.right = containerMargin;
        break;
    }
    return margin;
  }
  /**
   * returns the amount of pixeles each position is out of the viewport starting at 0,0 
   * @param {number} leftPosition 
   * @param {number} topPosition 
   * @returns 
   */
  const getViewportIntersectionCorrection = (leftPosition, topPosition) => {
    return { top: Math.min(0, topPosition) * -1, left: Math.min(0, leftPosition) * -1 };
  }

  useEffect(() => {
    if(anchorEl.current && popoverBoundingBox){
      const anchorBoundingBox = anchorEl.current.getBoundingClientRect(); 
      let topPosition = getAnchorOriginVerticalPosition(anchorOrigin.vertical, anchorBoundingBox);
      let leftPosition = getAnchorOriginHorizontalPosition(anchorOrigin.horizontal, anchorBoundingBox);
      topPosition += getTransformOriginVerticalPosition(transformOrigin.vertical, popoverBoundingBox)
      leftPosition += getTransformOriginHorizontalPosition(transformOrigin.horizontal, popoverBoundingBox)
      // const margin = getMarginFromAnchorOrigin(topPosition, leftPosition, anchorBoundingBox);
      const margin = getMarginFromAnchorOrigin(transformOrigin);
      topPosition +=  margin.top - margin.bottom;
      leftPosition += margin.left - margin.right;
      const viewportCorrection = getViewportIntersectionCorrection(leftPosition, topPosition);
      leftPosition += viewportCorrection.left;
      topPosition += viewportCorrection.top;
      let decoratorTopOffset = getDecoratorTransformTopOffset(transformOrigin.vertical, anchorOrigin.vertical, popoverBoundingBox, decoratorSize)
      let decoratorLeftOffset = getDecoratorTransformLeftOffset(transformOrigin.horizontal, anchorOrigin.horizontal, popoverBoundingBox, decoratorSize)
      let decoratorCorrection = decoratorOffsetCornerCorrection(decoratorTopOffset, decoratorLeftOffset, popoverBoundingBox, decoratorSize);
      decoratorTopOffset += decoratorCorrection.top - viewportCorrection.top;
      decoratorLeftOffset += decoratorCorrection.left - viewportCorrection.left;
      setOriginPosition({top: topPosition, left: leftPosition});
      setDecoratorPosition({top: decoratorTopOffset, left: decoratorLeftOffset})
    }
  },[anchorEl, anchorOrigin, popoverBoundingBox])

  useLayoutEffect(() => {
    if(popoverRef != null){
      const boundingBox = popoverRef.getBoundingClientRect();
      setPopoverBoundingBox(boundingBox);
    }
  },[popoverRef])

  useEffect(() => {
    if(open && timeoutTime != null){
      const timeoutId = setTimeout(onClose, timeoutTime);
      const onClickCallback = (e, id=timeoutId) => {
        clearTimeout(id)
        onClose();
      }
      window.addEventListener('click', onClickCallback);
      return(() => {
        window.removeEventListener('click', onClickCallback);
      })
    }
  },[open])

  return ReactDOM.createPortal(
      <div ref={setPopoverRef} className={`custom-popover-container ${open ? 'show' : 'hide'}`} style={styles.container}>
          <div className='custom-popover-container-transform' style={styles.decorator} />
          {props.children}
      </div>
      , document.body
  )
}

export default CustomPopover