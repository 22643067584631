/*
    Contains all the queries related to third-parties video content provider
*/
import { getFromSafeObject } from "./aux";
import { publicThirdPartyRequest } from "./requestMethods";

// https://developer.vimeo.com/api/upload/thumbnails
const getVimeoThumbnailFn = async( params, signal=null ) => {
    const {
        videoSourceId,
        opt
    } = params;
    let{
        resolution,
        fileType
    } = opt;
    const config = {
        signal,
        headers: {
            Authorization: `Bearer ${process.env.REACT_APP_VIMEO_ACCESS_TOKEN}`,
            Accept: 'application/vnd.vimeo.*+json;version=3.4'
        },
    }
    const res = await publicThirdPartyRequest.get(
        `https://api.vimeo.com/videos/${videoSourceId}/pictures`, 
        config
    );
    resolution = resolution ? `_${resolution}` : '_1920x1080';
    fileType = fileType ? fileType : 'webp';
    return { 
        data: {
            url:`${res.data.data[0].base_link}${resolution}.${fileType}` 
        }
    }
};
/**
 * fileType is always jpg
 * @param {*} params 
 * @param {*} signal 
 * @returns 
 */
const getYoutubeThumbnailFn = async( params, signal=null ) => {
    const getResolutionKey = (resolutionString) => {
        const [width, height] = resolutionString.split('x').map((dim, _) => parseInt(dim));
        let resolutionKey;
        if(width < 120){
            resolutionKey = 'default';
        }else if(width <= 320){
            resolutionKey = 'medium';
        }else if(width <= 480){ 
            resolutionKey = 'high';
        }else if(width <= 640){
            resolutionKey = 'standard';
        }else{
            resolutionKey = 'maxres';
        }
        return resolutionKey
    }
    const {
        videoSourceId,
        opt
    } = params;
    let{
        resolution,
    } = opt;
    const res = await publicThirdPartyRequest.get(
        `https://www.googleapis.com/youtube/v3/videos?key=${process.env.REACT_APP_GOOGLE_YOUTUBE_API_KEY}&part=snippet&id=${videoSourceId}`,
        { signal }
    );
    const thumbnailResolutionMap = getFromSafeObject(res, 'data.items[0].snippet.thumbnails');
    resolution = resolution ? resolution : '1280x720';
    const resolutionKey = getResolutionKey(resolution)
    const thumbnail = thumbnailResolutionMap[resolutionKey]
    return { 
        data: {
            url: thumbnail.url 
        }
    }
};

export { 
    getVimeoThumbnailFn,
    getYoutubeThumbnailFn
}