import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/styles';
import { Typography, useMediaQuery } from '@material-ui/core'
import SocialCommentItem from '../SocialCommentItem/SocialCommentItem';
import './landingSocial.css'
import { textTransform } from '../../aux/aux';
import SocialCommentSection from '../SocialCommentSection/SocialCommentSection';

const LandingSocial = ({ socialCommentArray }) => {
    const { t } = useTranslation('landing', { keyPrefix:'landingSocial' });
    const recommendationPerentage = 91;
    socialCommentArray = socialCommentArray || t('common:socialDefaultCommentArray', { returnObjects:true });
   
    return (
        socialCommentArray ?
            <SocialCommentSection 
                title={
                    `${textTransform('title', t("common:the", { context: 'male', count: 1 }))}`+
                    ` <span className='landing-social-recommendation-amount'>${recommendationPerentage}%</span> `+
                    `${t('ofOurStudentsRecommendUs')}`
                } 
                socialCommentArray={socialCommentArray} 
            />
            :
            null
    )
}

export default LandingSocial