import { useTheme } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import Circle from '../../img/circle.svg';
import { useTranslation } from 'react-i18next';
import { textTransform } from '../../aux/aux';
import IconLabel from '../IconLabel/IconLabel';
import { WarningAmber } from '@mui/icons-material';
import StarIcon from '@mui/icons-material/Star';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import './basicConfigSelectorItem.css'

const BasicConfigSelectorItem = ({ isSelected, disabled, isBestPrice, isRecommended, isUnique, warningMessage, ...props}) => {
    const theme = useTheme();
	const { t } = useTranslation('common');
	const styles = {
		icon:{
			display: 'block'
		},
		circle: {
			fill: theme.palette.primary.main,
			height: '0.5em'
		},
		iconWarning: {
			display: 'block',
			fill: theme.palette.primary.main
		},
		disabledMessageContainer:{
            color: theme.palette.grey[500]
        },
	}
	return (
		<div className='basic-config-selector-item-main-container'>
			<div className='basic-config-selector-item-details-container'>
				<div className='basic-config-selector-item-children-container'>
					{props.children}
				</div>
				{isSelected ?
					<div className='basic-config-selector-item-details-selected-icon-container'>
						<Circle style={{...styles.icon, ...styles.iconCircle}} />
					</div>
					:
					null
				}
			</div>
			{!isUnique && (isBestPrice || isRecommended)?
				<div className='basic-config-selector-item-icons-container'>
					{isBestPrice ? 
						<IconLabel Icon={LocalOfferIcon} color={theme.palette.secondary.main}/>
						:
						null
					}
					{isRecommended ? 
						<IconLabel Icon={StarIcon} color={theme.palette.primary.main}/>
						:
						null
					}
				</div>
				:
				null
			}
			{warningMessage ?
				<div className='basic-config-selector-item-warning-message-container' style={styles.disabledMessageContainer}>
					<WarningAmber style={{ ...styles.icon, fontSize: theme.typography.caption.fontSize }}/>
					<Typography variant='caption'>
						{warningMessage}
					</Typography>
				</div>
				:
				null
			}	
		</div>
    )
}

export default BasicConfigSelectorItem