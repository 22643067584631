import { Typography } from '@material-ui/core';
import './contentDescriptionKeyPoints.css'

const ContentDescriptionKeyPoints = ({ keyPoints, title, className }) => {
    // TODO: Enhance the key points -> https://css-tricks.com/almanac/selectors/m/marker/
    const styles = {
        title:{
            fontWeight: 'bold',
        }
    }
    return (
        <div className={`content-description-key-points-main-container ${className || ''}`}>
            <Typography variant="h6" style={styles.title}>
                {title}
            </Typography>
            <ul >
                {keyPoints.map((point, index) => {
                    return(
                        <li key={point.orderIndex} style={styles.li}>
                            <Typography className={`content-description-key-points ${index !== 0 ? 'padding-top' : ''}`}>
                                {point.description.title}
                            </Typography>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default ContentDescriptionKeyPoints
