import { useEffect, useRef } from "react";

const NestSections = ({topClassName, bottomClassName, middleClassName, ...props}) => {
    const nestSectionRef = useRef(null)
    useEffect(() => {
        if(nestSectionRef.current){
            Array.from(nestSectionRef.current.children).forEach((elem, index) => {
                if(index === 0 && topClassName){
                    elem.classList.add(topClassName)
                }else if(index === nestSectionRef.current.children.length - 1 && bottomClassName){
                    elem.classList.add(bottomClassName)
                }else if(middleClassName){
                    elem.classList.add(middleClassName)
                }
            })
        }
    },[])
    return (
        <div ref={nestSectionRef} {...props}>
            {props.children}
        </div>
    )
}

export default NestSections