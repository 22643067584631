/**
 * This Module contains a set of functions to facilitate the REST API queries related to the mailing list Process
 */
import {publicRequest, publicThirdPartyRequest} from "./requestMethods";

// DEPRICATED
// Date: 09/06
const subscribeToEmailListFn = async (params) =>{
    const {
        url,
        timeout,
    } = params;
    const res = await publicThirdPartyRequest.get(url, { timeout });
    return res
};

const subscriptionListSubscriptionFn  = async (params, signal=null) => {
    const {
        op,
        provider,
        ...body
    } = params;
    return publicRequest.post(`/mkt/subscription-list/${op}`, body, { params: { provider }, signal })
};
const createUpdateMktProfileFn  = async (params, signal=null) => {
    const {
        provider,
        ...body
    } = params;
    return publicRequest.post(`/mkt/profile`, body, { params: { provider }, signal })
};

const registerEventInMktProfileFn = async(params, signal=null) => {
    const {
        provider,
        ...body
    } = params;
    return publicRequest.post(`/mkt/event`, body, { params: {provider }, signal })
}

export{
    subscribeToEmailListFn,
    subscriptionListSubscriptionFn,
    createUpdateMktProfileFn,
    registerEventInMktProfileFn
}