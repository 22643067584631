import SocialCommentSection from '../SocialCommentSection/SocialCommentSection'
import { useTranslation } from 'react-i18next'
import './contentSocialComments.css';

const ContentSocialComments = ({ contentPage }) => {
    const { t } = useTranslation(['contentProduct','landing'], { keyPrefix: 'contentSocialComments'});
    const socialCommentArray = contentPage.socialCommentArray || t('common:socialDefaultCommentArray', { returnObjects:true });
    return (
        socialCommentArray ?
            <SocialCommentSection title={t('whatOutStudentsSay')} socialCommentArray={socialCommentArray}/>
            :
            null
    )
}

export default ContentSocialComments