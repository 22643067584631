import { useTranslation } from 'react-i18next';
import { Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import './maximizeDeviceInFigures.css';

const MaximizeDeviceInFigures = ({ className }) => {
    const theme = useTheme()
    const { t } = useTranslation('landing', { keyPrefix: 'maximizeDeviceInFigures'});
    const matchMobile = useMediaQuery('(max-width:680px)');
    const styles = {
        wrapper:{
            
        },
        value:{
            fontWeight: 'bold',
            color: theme.palette.primary.main
        },
        variable:{
            fontWeight: 'bold',
            color: theme.palette.primary.main
        },
        leyend:{
            color: theme.palette.grey[400]
        }
    }
    const figuresArray = [
        {
            value:'63',
            unit: '%',
            changeDirection: '+',
            variableName: 'satisfaction'
        },
        {
            value:'58',
            unit: '%',
            changeDirection: '+',
            variableName: 'amortization'
        },
        {
            value:'49',
            unit: '%',
            changeDirection: '+',
            variableName: 'recurrency'
        },
        {
            value:'42',
            unit: '%',
            changeDirection: '+',
            variableName: 'sessions'
        },
    ]
    
    return(
        <div className={`maximize-device-in-figures-wrapper${className ? ` ${className}` : ''}`} style={styles.wrapper}>
            <div className='maximize-device-in-figures-main-container'> 
                {figuresArray.map((figObject, index) => {
                    return(
                        <div key={index} className='maximize-device-in-figures-figure-container'>
                            <Typography variant={matchMobile ? 'h5' : 'h4'} style={styles.value}>
                                {`${figObject.changeDirection}${figObject.value}${figObject.unit}`}
                            </Typography>
                            <Typography variant={matchMobile ? 'body1' : 'h6'} style={styles.variable} className='maximize-device-in-figures-figure-variable'>
                                {t(figObject.variableName)}
                            </Typography>
                        </div>
                    )
                })}
                <div className='maximize-device-in-figures-legend-container'>
                    <Typography variant='body2' style={styles.leyend}>
                        {`* ${t('withOfTheDevive')}`}
                    </Typography>
                </div>
            </div>
        </div>
    )
}

export default MaximizeDeviceInFigures