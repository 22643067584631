import { createAuthRequest } from "./requestMethods"
import { getSystemRequestParameter } from "./contentProductHelpers";
import { arrayToQueryParam } from "./aux";

/**
 * This module contains userMeeting-related queries
 */

const getUserMeetingFn = async (params, signal) => {
    const {
        userId,
        userMeetingId,
        query
    } = params
    const userRequest = createAuthRequest();
    return userRequest.get(`/user-meeting/${userId}/${userMeetingId}`, { params: query, signal: signal});    
}
const getUserMeetingCollectionByProductFn = async (params, signal) => {
    const {
        productId,
        userId,
        query,
    } = params

    if(query.hasOwnProperty('versionBySystem')){
        query.versionBySystem = getSystemRequestParameter(query.versionBySystem);
    };
    if(query.hasOwnProperty('userIdArray')){
        query.userIdArray = arrayToQueryParam(query.userIdArray)
    };
    if(query.hasOwnProperty('occurrenceStatusArray')){
        query.occurrenceStatusArray = arrayToQueryParam(query.occurrenceStatusArray);
    };
    if(query.hasOwnProperty('occurrenceDateConditionArray')){
        query.occurrenceDateConditionArray = arrayToQueryParam(query.occurrenceDateConditionArray);
    };
    
    const userRequest = createAuthRequest();
    return userRequest.get(`/user-meeting/collection/product/${productId}${userId ? `/user/${userId}` : ''}`, { params: query, signal: signal});    
}

const getUserAccessMeetingTokenFn = async ( params, signal ) => {
    const {
        userId,
        userMeetingId
    } = params
    const userRequest = createAuthRequest();
    return userRequest.get(`/user-meeting/auth/${userId}/${userMeetingId}`, {signal: signal})
}

const registerUserMeetingOccurrenceFn = async (params, signal) => {
    const {
        userId,
        userMeetingId,
        meetingOccurrenceId,
        bodyParams
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.post(`/user-meeting/register/${userId}/${userMeetingId}/${meetingOccurrenceId}`, bodyParams, { signal: signal });
}

const unregisterUserMeetingOccurrenceFn = async (params, signal) => {
    const {
        userId,
        userMeetingId,
        meetingOccurrenceId,
        bodyParams
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.post(`/user-meeting/unregister/${userId}/${userMeetingId}/${meetingOccurrenceId}`, bodyParams, { signal: signal });
}

const unregisterUserMeetingOccurrenceCollectionFn = async (params, signal) => {
    const {
        userId,
        bodyParams
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.post(`/user-meeting/unregister/collection/user/${userId}`, bodyParams, { signal: signal });
}

const changeMeetingInUserMeetingFn = async (params, signal) => {
    const {
        userId,
        userMeetingId,
        meetingId,
        bodyParams
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.post(`/user-meeting/change-meeting/${userId}/${userMeetingId}/${meetingId}`, bodyParams, { signal: signal });
}

const createUserMeetingRepetitionFn = async (params, signal) => {
    const {
        userId,
        userMeetingId,
        bodyParams
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.post(`/user-meeting/create-low-priority/${userId}/${userMeetingId}`, bodyParams, {signal: signal})
}

export{
    getUserMeetingFn,
    getUserMeetingCollectionByProductFn,
    getUserAccessMeetingTokenFn,
    registerUserMeetingOccurrenceFn,
    unregisterUserMeetingOccurrenceFn,
    unregisterUserMeetingOccurrenceCollectionFn,
    changeMeetingInUserMeetingFn,
    createUserMeetingRepetitionFn,
}