import { Typography } from '@material-ui/core'
import { useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { textTransform } from '../../aux/aux'
import PopUpModal from '../PopUpModal/PopUpModal'
import SimpleNoModalDialog from '../SimpleNoModalDialog/SimpleNoModalDialog'
// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import congrats from '../../lotties/congrats.json';
import './popUpLowPriorityGrantedCongrats.css'

const PopUpLowPriorityGrantedCongrats = ({show, onClickClose}) => {
    const POP_UP_WIDTH= 380;
    const { t } = useTranslation('common', { keyPrefix: "popUpLowPriorityGrantedCongrats" });
    const animationContainerRef = useRef(null);
    const animationRef = useRef(null);
    const styles = {
        animationContainer:{
            height: '150px'
        },
        title:{
            fontWeight: 'bold',
            paddingBottom: '20px',
            textAlign: 'center'
        },
        msg:{
            paddingBottom: '20px',
        }
    }

    useEffect(() => {
        if(animationContainerRef.current){
            animationRef.current = lottie.loadAnimation({
                container: animationContainerRef.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: congrats
            });
            return(() => {animationRef.current?.destroy()})
        }
    },[])
    return (
        <PopUpModal
            showModal={show}
            width={POP_UP_WIDTH}
            isDialog={true}
        >
            <SimpleNoModalDialog
                className='popup-low-priority-granted-congrats-dialog-container'
                onAgree={onClickClose}
            >
                <div style={styles.animationContainer} ref={animationContainerRef}/>
                <Typography variant='h5' style={styles.title}>
                    {textTransform('title', t("common:congrats"))}
                </Typography>
                <Typography variant='body1'>
                    {textTransform('title', t("youCanRegisterAgainInEvent"))}
                </Typography>
                <Typography variant='body1' style={styles.msg}>
                    {textTransform('title', t("yourscholarshipWasGranted"))}
                </Typography>
            </SimpleNoModalDialog>
        </PopUpModal>
            
    )
}

export default PopUpLowPriorityGrantedCongrats