/**
 * This Module contains a set of functions to facilitate the REST API queries related to the purchase Process
 */
import { createAuthRequest, publicRequest } from "./requestMethods";

const checkPaymentIntentFn = async (params) => {
    const {
        paymentIntentId,
        requestParam
    } = params;
    const userRequest = createAuthRequest();
    const res = await userRequest.post('checkout/check-payment-intent',{ paymentIntentId, requestParam });
    return res;
}

const checkPaymentIntentAuthVerificationFn = async (params) => {
    const {
        userId,
        checkoutId,
        paymentIntentId,
        hashFinalAmount,
        requestParam,
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.post(`checkout/check-payment-intent-checkout/${userId}&${checkoutId}&${paymentIntentId}`,{ hashFinalAmount, requestParam });
};

const createPaymentIntentFn = async (params) => {
    const {
        userId,
        checkoutId,
        ...bodyParams
    } = params;
    const userRequest = createAuthRequest();
    // TODO: update entry point when all payment intent are created from here
    return userRequest.post(`checkout/create-payment-intent-checkout/${userId}&${checkoutId}`, bodyParams);
}

const updatePaymentIntentFn = async (params, signal=null) => {
    const {
        userId,
        checkoutId,
        ...bodyParams
    } = params;
    const userRequest = createAuthRequest();
    // TODO: update entry point when all payment intent are created from here
    return userRequest.post(`checkout/update-payment-intent-checkout/${userId}&${checkoutId}`, bodyParams, { signal });
}


const cancelPaymentIntentFn = async (params) => {
    const{
        userId,
        checkoutId,
        paymentIntentId
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.post(`/checkout/cancel-payment-intent-checkout/${userId}&${checkoutId}&${paymentIntentId}`);
}

const getReactivateSubscriptionCheckoutIdFn = async (params, signal=null) => {
    const {
        userId
    } = params;
    const userRequest = createAuthRequest();
    const res = await userRequest.get(`checkout/get-reactivate-subscription-checkoutId/${userId}`, { signal });
    return res;
}

const createReactivateSusbcriptionCheckoutFn = async (params, signal=null) => {
    const {
        userId
    } = params;
    const userRequest = createAuthRequest();
    const res = await userRequest.post(`checkout/create-reactivate-subscription-checkout/${userId}`, undefined, { signal });
    return res;
}

const resetReactivateSubscriptionCheckoutFn = async (params, signal=null) => {
    const {
        userId,
        checkoutId
    } = params;
    const userRequest = createAuthRequest();
    const res = await userRequest.post(`checkout/reset-reactivate-subscription-checkout/${userId}&${checkoutId}`, undefined , { signal });
    return res;
}

const clearCheckoutFn = async (params, signal=null) => {
    const {
        userId,
        checkoutId
    } = params;
    const userRequest = createAuthRequest();
    const res = await userRequest.post(`checkout/clear-checkout/${userId}&${checkoutId}`, undefined, { signal });
    return res;  
}

const getOpenCheckoutIdFn = async (params, signal=null) => {
    const {
        userId,
        checkoutType,
    } = params;
    const userRequest = createAuthRequest();
    const res = await userRequest.get(`checkout/get-open-checkoutid/${userId}&${checkoutType}`, { signal });
    return res;
}

const createCheckoutFn = async (params, signal=null) => {
    const {
        userId,
        checkoutType,
        checkoutProductArray,
    } = params;
    const bodyParams = {
        checkoutType,
        checkoutProductArray, // [{producId, quantity, productTypeName}]
    };
    const userRequest = createAuthRequest();
    const res = await userRequest.post(`checkout/create-checkout/${userId}`, bodyParams, { signal });
    return res;
}

const getCheckoutContentFn = async (params) => {
    const {
        checkoutId,
        userId,
        userLocaleId
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.get(`checkout/get-checkout/${userId}&${checkoutId}&${userLocaleId}`);
}

// Unused 06/17
// const getApplicablePromoCodeFn = async (params) => {
//     const {
//         promoCodeId,
//         checkoutId,
//         userId,
//         hashFinalAmount,
//     } = params
//     return publicRequest.post(`/checkout/retrieve-promo-code-checkout/${userId}&${checkoutId}&${promoCodeId}`, { hashFinalAmount })
// }

// Unused 06/17
// const getAndApplyPromoCodeToCheckoutFn = async (params) => {
//     const {
//         promoCodeId,
//         checkoutId,
//         userId,
//     } = params;
//     const userRequest = createAuthRequest();
//     return userRequest.get(`/checkout/get-and-apply-promo-code-to-checkout/${userId}&${checkoutId}&${promoCodeId}`);
// }

const updateAndGetCheckoutFn = async (params, signal=null) => {
    const{
        userId,
        checkoutId,
        userLocaleId,
        opId,
        ...bodyParams
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.post(`/checkout/update-and-get-checkout/${opId}/${userId}&${checkoutId}&${userLocaleId}`, bodyParams, { signal });
}

const updateCheckoutStatusFn = async (params) => {
    const {
        userId,
        checkoutId,
        checkoutStatus,
        paymentIntentClientSecret
    } = params
    const userRequest = createAuthRequest();
    return userRequest.post(`/checkout/update-checkout-status/${userId}&${checkoutId}`, { checkoutStatus, paymentIntentClientSecret });
}

const updateCheckoutBillingAddressAndCurrencyFn = async (params) => {
    const {
        userId,
        checkoutId,
        billingAddressAndCurrency,
    } = params
    if(billingAddressAndCurrency.stateCode === ""){
        billingAddressAndCurrency.stateCode = null;
    }
    const userRequest = createAuthRequest();
    return userRequest.post(`/checkout/update-checkout-billing-address-and-currency/${userId}&${checkoutId}`, { billingAddressAndCurrency });    
}

const getCheckoutBillabilityFn = async (params) => {
    const{
        userId,
        checkoutId
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.get(`/checkout/get-checkout-billability/${userId}&${checkoutId}`);
}

const validateCheckoutAmountFn = async (params) => {
    const{
        hashFinalAmount
    } = params; 
    // hash is encoded in Base64 so this can include / or +
    // encodeURI does not encode /
    const utf8HashFinalAmount = encodeURIComponent(hashFinalAmount);
    return publicRequest.get(`/checkout/validate-checkout-amount/${utf8HashFinalAmount}`);
}

const getCheckoutContentPurchasabilityDetailsFn = async (params) => {
    const{
        checkoutId,
    } = params; 
    const userRequest = createAuthRequest();
    return userRequest.get(`/checkout/content-purchasability-details/${checkoutId}`);
}

const createBasicSetUpIntentFn = async (params, signal=null) => {
    const {
        userId
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.get(`/checkout/create-basic-setup-intent/${userId}`, { signal })
}

const getPaymentMethodFromIntentIdFn = async (params, signal=null) => {
    const {
        intentId
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.get(`/checkout/shareable-payment-method/${intentId}` , { signal })
}

export {
    createBasicSetUpIntentFn,
    checkPaymentIntentFn,
    checkPaymentIntentAuthVerificationFn,
    createPaymentIntentFn,
    updatePaymentIntentFn,
    getReactivateSubscriptionCheckoutIdFn,
    createReactivateSusbcriptionCheckoutFn,
    getOpenCheckoutIdFn,
    createCheckoutFn,
    getCheckoutContentFn,
    updateAndGetCheckoutFn,
    updateCheckoutStatusFn,
    updateCheckoutBillingAddressAndCurrencyFn,
    validateCheckoutAmountFn,
    getCheckoutContentPurchasabilityDetailsFn,
    getCheckoutBillabilityFn,
    cancelPaymentIntentFn,
    resetReactivateSubscriptionCheckoutFn,
    clearCheckoutFn,
    getPaymentMethodFromIntentIdFn,
}