import { useRef } from 'react';
import { Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { textTransform } from '../../aux/aux'
import CustomPopover from '../CustomPopover/CustomPopover'
import './featuredFeaturesWidget.css'
import InfoLabelPopOver from '../InfoLabelPopOver/InfoLabelPopOver';

const FeaturedFeaturesWidget = ({ featuresArray, wrapperClassName, featureClassName }) => {
    const ICON_SIZE_PX = 24;
    const theme = useTheme();
    const styles = {
        icon:{
            width: 'auto',
            height: `${ICON_SIZE_PX}px`,
            display: 'block',
            fill: theme.palette.primary.main,
        },
        labelIcon:{
            width: 'auto',
            height: theme.typography.body2.fontSize,
            display: 'block',
            fill: theme.palette.grey[400],
        },
        label:{
            color: theme.palette.grey[400],
            textAlign: 'center'
        },
        figure:{
            fontWeight: 'bold'
        }
    }
    return (
        <div className={wrapperClassName ? wrapperClassName : 'featured-features-widget-main-container'}>
            {featuresArray.map((featureObj, index) => {
                let {Icon, label, labelPopOverMsg, figure} = featureObj;
                return(
                    <div key={index} className={featureClassName ? featureClassName : 'featured-features-widget-feature-container'}>
                        {Icon &&
                            <Icon className='featured-features-widget-feature-icon' style={styles.icon} />
                        }
                        <InfoLabelPopOver label={label} labelPopOverMsg={labelPopOverMsg} labelClassName='featured-features-widget-feature-label' />
                        <Typography variant='h4' style={styles.figure}>
                            {figure}
                        </Typography>
                    </div>
                )
            })}
        </div>
    )
}

export default FeaturedFeaturesWidget