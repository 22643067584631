import { useEffect, useRef, useState } from 'react'
import './loadingPopUp.css'
import PopUpModal from '../PopUpModal/PopUpModal';
import { Typography } from '@material-ui/core';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import { textTruncate } from '../../aux/aux';
import PreventUnderZLayerInteraction from '../PreventUnderZLayerInteraction/PreventUnderZLayerInteraction';

const LoadingPopUp = () => {
    const POP_UP_WIDTH = 380;
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState(null);
    const styles = {
        modalMainContainer: message ? {} :{
            backgroundColor: 'unset', 
            boxShadow: 'unset',
        },
        modalWrapper:{
            // Exceptional max zIndex intead of default 1101 in PopUpModal
            zIndex: 1102 
        }
    }

    useEffect(() => {
        const handleMessage = (e) => {
            const {
                message,
                show
            } = e.detail;
            setMessage( message || null);
            setShow(show || false);
        }
        document.addEventListener('loadingPopUp', handleMessage);
        return(() => {
            document.removeEventListener('loadingPopUp', handleMessage);
        })
    },[])
    return (
        <PopUpModal showModal={show} isDialog={true} width={POP_UP_WIDTH} style={styles.modalMainContainer} wrapperStyle={styles.modalWrapper} >
            <div className='loading-pop-up-main-container'>
                <div className='loading-pop-up-message-container'>
                    <Typography variant='body1'>
                        {message} 
                    </Typography>
                </div>
                <div className='loading-pop-up-loading-container'>
                    <LoadingComponent visibleElements='circle'/>
                </div>
            </div>
        </PopUpModal>
    )
}

export default LoadingPopUp