import { useEffect, useRef, useState } from 'react';
import { useScrollbarWidth } from './useScrollbarWidth';

function useScrollableDocument(){
    const DEFAULT_USE_SCROLL_DOCUMENT_PROPS = {
        compensateScrollbar:true, 
        fixedPosition:false,
        top:undefined
    }
    const optRef = useRef(DEFAULT_USE_SCROLL_DOCUMENT_PROPS)
    const [isScrollable, _setIsScrollable] = useState(true);
    const scrollbarWidth = useScrollbarWidth();
    const setIsScrollable = (allow, opt={}) => {
        const fn = allow ? () => _allowBackgroundScroll() : () => _preventBackgroundScroll(opt);
        fn();
    }
    const _allowBackgroundScroll = () => {
        _setIsScrollable(prev => {
            if(!prev){
                // does not prevent from touch events
                // source: https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
                if(optRef.current.fixedPosition){
                    const scrollY = optRef.current.top !== undefined ? optRef.current.top : document.body.style.top;
                    const scrollYPos = typeof scrollY === 'string' ? scrollY.replace('px', '') : scrollY
                    // prevents from refresh in mobile browsers
                    document.body.style['overscroll-behavior-y'] = '';
                    document.body.style.top = '';
                    document.body.style.right = ''
                    document.body.style.left = ''
                    document.body.style.position = '';
                    window.scrollTo(0, parseInt(scrollYPos || '0') * -1);
                }else{
                    document.body.style.overflow = 'unset';
                }
                if(optRef.current.compensateScrollbar && scrollbarWidth > 0){
                    document.body.style.paddingRight= 'inherit';
                }
                optRef.current = DEFAULT_USE_SCROLL_DOCUMENT_PROPS;
                return true
            }else{
                return prev
            }
        })
    }

    const _preventBackgroundScroll = (opt={}) => {
        const documentHasScrollbars = (dir) => {
            let overflow;
            if(dir === 'vertical'){ 
                dir = ['scrollHeight','innerHeight']
                overflow = window.getComputedStyle(document.body)['overflow-y']
            }else{
                dir = ['scrollWidth', 'innerWidth']
                overflow = window.getComputedStyle(document.body)['overflow-x']
            }
            return overflow === 'scroll' || overflow !=='hidden' && document.body[dir[0]] > window[dir[1]];
        }
        _setIsScrollable(prev => {
            if(prev){
                // does not prevent from touch events
                // source: https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
                optRef.current = {...optRef.current, ...opt};
                if(optRef.current.compensateScrollbar && scrollbarWidth > 0 && documentHasScrollbars('vertical')){
                    document.body.style.paddingRight= `${scrollbarWidth}px`;
                }
                if(optRef.current.fixedPosition){
                    const scrollY = optRef.current.top !== undefined ? optRef.current.top : window.scrollY;
                    document.body.style.top = `-${scrollY}px`;
                    document.body.style.right = '0px'
                    document.body.style.left = '0px'
                    // prevents from refresh in mobile browsers
                    document.body.style['overscroll-behavior-y'] = 'contain';
                    document.body.style.position = 'fixed';
                }else{
                    document.body.style.overflow = 'hidden'; // does not prevent from touch events
                }
                return false;
            }else{
                return prev
            }
        })
    }

    return ([isScrollable, setIsScrollable])
}

export { useScrollableDocument }