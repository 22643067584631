import {useTheme} from '@material-ui/styles'
import { useTranslation } from 'react-i18next';
import { Button, Paper, Typography } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import { verifyAccount, verifyAccountPassive } from '../../services/userServices'
import './passiveAccountVerification.css'
import { isEmptyObject, textTransform } from '../../aux/aux';
import { getEmailFromVerificationToken } from '../../aux/authHelpers';
import SimpleNoModalDialog from '../../components/SimpleNoModalDialog/SimpleNoModalDialog';
// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import errorAnimation from '../../lotties/error.json';
import successAnimation from '../../lotties/success.json';
import { getCustomErrorOrUndefined } from '../../aux/errorHelpers';
import PopUpContactSupport from '../../components/PopUpContactSupport /PopUpContactSupport';
import { useHistory } from 'react-router-dom';
import SmallFooter from '../../components/SmallFooter/SmallFooter';
import ClickableLoadingText from '../../components/ClickableLoadingText/ClickableLoadingText';

const PassiveAccountVerification = ({ verificationToken }) => {
    const theme = useTheme();
    const { t } = useTranslation('passiveAccountVerification');
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [emailInValidToken, setEmailInValidToken] = useState(null);
    const [feedback, setFeedback] = useState(null);
    const [animationContainer, setAnimationContainer] = useState(null);
    const [showSupportModal, setShowSupportModal] = useState(false);
    const animationRef = useRef(null);
    const styles = {
        wrapper: {
            // backgroundColor: theme.palette.surface.light[1]
        },
        supportLink:{
            color: theme.palette.primary.main
        }
    }

    /**
     * Note: Note: A falsy or malformed token will throw an InvalidTokenError error.
     * @returns 
     */
    const setEmailFromVerificationToken = (verificationToken) => {
        try{
            const email = getEmailFromVerificationToken(verificationToken);
            if(email){
                setEmailInValidToken(email);
            }else{
                throw new Error('wrong token');
            }
        }catch(err){
            // invalid token
            setFeedback({
                type: 'wrongToken',
                animation: errorAnimation,
                title: textTransform('title', t('uups')),
                msg: `${textTransform('title',t('weCannotActivateYourAccountNow'))}.\n${textTransform('title',t('theLinkIsWrong'))}`,
            })
        }
    }

    const redirectToRouterPath = (routerPath) => {
        history.replace(routerPath);
    }

    const verificationProcessNoInteraction = async (verificationToken, signal) => {
        try{
            setIsLoading(true)
            await verifyAccountPassive(verificationToken, 1000, signal);
            if(!signal.aborted){
                setFeedback({
                    type: 'success',
                    animation: successAnimation,
                    title: textTransform('title', t('activated')),
                    msg: `${textTransform('title', t('yourAccount'))} `
                        +`${t('isActivatedAndReadyToLogin')}.`,
                    action: () => redirectToRouterPath('/login'),
                    actionLabel: t('goToLogin')
                })
            }
        }catch(error){
            const customError = getCustomErrorOrUndefined(error);
            let resolveFeedback = {};
            if(customError){
                switch(customError.cause){
                    case 'sr_007':
                        // wrong token
                        resolveFeedback.type = 'wrongToken';
                        resolveFeedback.msg = `${textTransform('title',t('weCannotActivateYourAccountNow'))}.\n${textTransform('title',t('theLinkIsWrong'))}`;
                        break;
                    case 'sr_001':
                        resolveFeedback.type = 'updateUser'
                        resolveFeedback.msg = `${textTransform('title', t('couldNotIdentifyYourEmail'))} `
                        +`${t('common:and')} ${t('weCannotActivateYourAccountNow')}.`
                        break;
                    case 'sr_103':
                        // wrong or expired token
                        resolveFeedback.type = 'expiredToken';
                        resolveFeedback.msg = `${textTransform('title',t('weCannotActivateYourAccountNow'))}.\n${textTransform('title',t('theLinkHasExpired'))}`;
                        break;
                }
            }
            if(isEmptyObject(resolveFeedback)){
                resolveFeedback.type = 'unknown';
                resolveFeedback.msg = `${textTransform('title',t('weCannotActivateYourAccountNow'))}.\n${textTransform('tite', t('tryItLater'))}` 
            }
            if(!signal.aborted){
                setFeedback({
                    ...resolveFeedback,
                    animation: errorAnimation,
                    title: textTransform('title', t('uups')),
                })
            }
        }finally{
            if(!signal.aborted){
                setIsLoading(false);
            }
        }
    }

    useEffect(() => {
        setFeedback(null);
        if(verificationToken){
            setEmailFromVerificationToken(verificationToken);
        }else{
            setEmailInValidToken(null);
        }
    }, [verificationToken])

    useEffect(() => {
        if(emailInValidToken){
            const abortController = new AbortController();
            verificationProcessNoInteraction(verificationToken, abortController.signal);
            return(() => {
                abortController.abort();
            })
        }
    }, [emailInValidToken])

    useEffect(() => {
        if(feedback && feedback.animation && animationContainer){
            animationRef.current = lottie.loadAnimation({
                container: animationContainer,
                renderer: 'svg',
                loop: false,
                autoplay: true,
                animationData: feedback.animation
            });
            return(() => {
                animationRef.current?.stop();
                animationRef.current?.destroy()
            })
        }
    }, [feedback, animationContainer])

    return (
        <div className='passive-account-verification-wrapper' style={styles.wrapper}>
            <div className='passive-account-verification-main-container'>
                {feedback ? 
                    <Paper className='passive-account-verification-paper'>
                        <div className='passive-account-verification-animation' ref={setAnimationContainer}/>
                        {emailInValidToken &&
                            <Typography variant='body1' className='passive-account-verification-email'>
                                {emailInValidToken}
                            </Typography>
                        }
                        <Typography variant='h6' className='passive-account-verification-title'>
                            {feedback.title}
                        </Typography>
                        <Typography variant='body1' className='passive-account-verification-msg'>
                            {feedback.msg}
                        </Typography>
                        <div className='passive-account-verification-button'>
                            <Button color='primary' size='large' variant='contained' onClick={feedback.action ? feedback.action  : () => redirectToRouterPath('/')} >
                                {feedback.actionLabel || t('goToHome')}
                            </Button>
                        </div>
                        {feedback.type != 'success' &&
                            <div className='passive-account-verification-support-container'>
                                <PopUpContactSupport 
                                    showModal={showSupportModal} 
                                    onClickClose={()=>{setShowSupportModal(false)}} 
                                    width={380}
                                    categoryObject={{
                                        categoryArray: ['accountActivation'],
                                        defaultKey: 'accountActivation',
                                    }}

                                />
                                <Typography variant='body2'>
                                    {`¿${textTransform('title', t('doYouNeedHelpToActivateYourAccount'))}?`}
                                </Typography>
                                <ClickableLoadingText 
                                    text={`${textTransform('title', t('contactSupport'))}`} 
                                    isVisible={true} 
                                    disabled={isLoading}
                                    variant='body2' 
                                    onClick={e => {setShowSupportModal(true)}}
                                    underlineOnHover={true}
                                    className={'passive-account-verification-support-link'}
                                />
                            </div>
                        }
                    </Paper>
                :
                    <div className='passive-account-verification-loading-container'>
                        <Typography variant='body1' className='passive-account-verification-loading-msg'>
                            {textTransform('title', t('activatingAccount'))}
                        </Typography>
                        <Typography variant='body1' className='passive-account-verification-email'>
                            {emailInValidToken}
                        </Typography>
                        <div className='passive-account-verification-loading'>
                            <LoadingComponent visibleElements='circle'/>
                        </div>
                    </div>
                }
            </div>
            <SmallFooter hideSocialLinks={true} onLightBackground={true} />
        </div>
    )
}
export default PassiveAccountVerification
