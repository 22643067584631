import { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import ContentSliderDots from '../ContentSliderDots/ContentSliderDots';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './sliderBasic.css'
    
const SliderBasic = ({ settings, className, children, onChangeSlide=()=>{}, includeDots=true}) => {
    const DEFAULT_SETTINGS = {
        dots: false,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1.99,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 1.99,
                },
                breakpoint: 430,
                settings:{
                    slidesToShow: 1
                }
            }
        ]
    }
    const [currentStep, setCurrentStep] = useState(settings?.initialSlide || DEFAULT_SETTINGS.initialSlide);
    const [numSteps, setNumSteps] = useState(null);
    const slideShowRef = useRef(null);
    
    const handleOnChangeSlide = (oldStep, newStepIndex) => {
        setCurrentStep(newStepIndex);
        onChangeSlide(oldStep, newStepIndex);
    }

    const onGoToStep = (selectedStep) => {
        if(slideShowRef.current){
            slideShowRef.current.slickGoTo(selectedStep)
        }
    }

    useEffect(() => {
        let numSteps = 0;
        if(Number.isFinite(children.length)){
            numSteps = children.length / (settings.slidesToScroll || DEFAULT_SETTINGS.slidesToScroll)
        }
        setNumSteps(children.length);
    },[children])

    return (
        <>
            <Slider 
                ref={slideShowRef} 
                className={`slider-basic-inner-container${className ? ` ${className}` : ''}`}
                {...(settings || DEFAULT_SETTINGS)}
                beforeChange={handleOnChangeSlide} 
            >
                {children}
            </Slider>
            {includeDots &&
                <div>
                    <ContentSliderDots currentIndex={currentStep} onGoToStep={onGoToStep} numSteps={numSteps} />
                </div>
            }
        </>
    )
}
    
export default SliderBasic