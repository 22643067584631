/*
    This module encompasses services to populate static content
    No auth check is required
*/

import { requestMinimumTimeoutWrapper } from "../aux/requestMethods";
import { 
    getCompatibleSystemsFn, getAvailCurrencyFn, getProfessionsFn,
    getLocalesFn, getDefaultLocalesFn, getHandSelectedProductFn,
    getFutureProductFn,
    getAvailCountryFn,
    getAvailStateFn,
    getDisplayablePromoCodeFn,
    fetchPublicFileFromApiFn
} from "../aux/staticContentQueries";

async function getCompatibleSystems(by, minimumTimeOut=0, signal=null){
    const params = {
        by,
    };
    return requestMinimumTimeoutWrapper(getCompatibleSystemsFn, params, minimumTimeOut, signal);
};

async function getProfessions(localeId, minimumTimeOut=0, signal=null){
    const params = {
        localeId
    };
    return requestMinimumTimeoutWrapper(getProfessionsFn, params, minimumTimeOut, signal);
}

async function getAvailCurrency(countryCode=null, minimumTimeOut=0, signal=null){
    const params = {
        countryCode
    };
    return requestMinimumTimeoutWrapper(getAvailCurrencyFn, params, minimumTimeOut, signal);
}

async function getAvailCountry(localeId, countryCode=null, minimumTimeOut=0, signal=null){
    const params = {
        localeId,
        countryCode
    };
    return requestMinimumTimeoutWrapper(getAvailCountryFn, params, minimumTimeOut, signal);
}

async function getAvailState(countryCode=null, stateCode=null, minimumTimeOut=0, signal=null){
    const params = {
        countryCode,
        stateCode
    };
    return requestMinimumTimeoutWrapper(getAvailStateFn, params, minimumTimeOut, signal);
}

async function getLocales(opt, minimumTimeOut=0, signal=null){
    return requestMinimumTimeoutWrapper(getLocalesFn, opt, minimumTimeOut, signal);
}

async function getDefaultLocale(minimumTimeOut=0, signal=null){
    return requestMinimumTimeoutWrapper(getDefaultLocalesFn, {}, minimumTimeOut, signal);
}

async function getHandSelectedProduct(opt, minimumTimeOut=0, signal=null){
    return requestMinimumTimeoutWrapper(getHandSelectedProductFn, opt, minimumTimeOut, signal)
}

async function getDisplayablePromoCode(localeId, opt={}, minimumTimeOut=0, signal=null){
    const params = {
        localeId,
        ...opt
    }
    return requestMinimumTimeoutWrapper(getDisplayablePromoCodeFn, params, minimumTimeOut, signal);
}

async function fetchPublicFileFromApi(folderType, fileUri, minimumTimeOut=0, signal=null){
    const params = {
        folderType,
        fileUri
    }
    return requestMinimumTimeoutWrapper(fetchPublicFileFromApiFn, params, minimumTimeOut, signal);
}

export{
    getCompatibleSystems,
    getProfessions,
    getAvailCurrency,
    getAvailCountry,
    getAvailState,
    getLocales,
    getDefaultLocale,
    getHandSelectedProduct,
    getDisplayablePromoCode,
    fetchPublicFileFromApi
}