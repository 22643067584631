import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { 
    setHistoryForInterceptors, setUpInterceptorsAPIResponseError,
    setClientNotificiationsForInterceptors, setForceUserToLoginForInterceptors
} from "../../aux/requestMethods";
import { logoutUserOnClient } from "../../services/sessionServices";
import { useRedirect } from "../../customHooks/useRedirect";

const InjectAxiosInterceptors = ({ setUser, addMessages }) => {
    const history = useHistory();
    const API_CUSTOM_ERROR_NAME = 'CustomError';
    const redirectApi = useRedirect();
    
    const forceUserToLogin = () => {
        // Absorved by logoutUserOnClient on 09/24
        // sessionVariables.remove('accessToken');
        // sessionVariables.remove('refreshToken');
        // setUser(null);
        // const redirectPath = window.location.pathname;
        // const redirectPathParam = 'from'
        // history.push(`/login?${redirectPathParam}=${redirectPath}`); // Previously redirected to app/login
        logoutUserOnClient(setUser);
        const redirectPath = window.location.pathname;
        const redirectQuery = { 'from': redirectPath };
        redirectApi.redirectToRoute('/login', redirectQuery)
        
        // Message is shown on loading page to prevent
        // concurrent redundant messages from simultaneous revoked requests
        // addMessages([
        //     {
        //         message:logOutsessionText.message,
        //         severity:"warning"
        //     }
        // ])
    }

    const handleAPIResponseError = (error) => {
        const isAPICustomError = error.response?.data?.name === API_CUSTOM_ERROR_NAME ? true : false;
        if(isAPICustomError){
            // Known cause
            const customError = error.response.data;
            handleAPIResponseCustomError(customError);
        }else{
            // unknown cause
        }
    }

    const handleAPIResponseCustomError = (customError) => {
        let forceLogin = false;
        const cause = customError.cause;
        switch(cause){
            case 'sr_500': // Concurrent active session
                forceLogin = true;
                break;
            case 'sr_100': // Wrong or expired access tokens
            case 'sr_101': // Wrong credentials
                forceLogin = true;
                break;
            case 'sr_404': // Product not found
                // It is handle in the router as needed
                // redirectApi.redirectToRoute('/404', {}, true);
            default:
                // Remaining custom errors are handled on the request caller function
        }
        if(forceLogin){
            forceUserToLogin();
        }
    }

    useEffect(()=>{
        setHistoryForInterceptors(history);
        setClientNotificiationsForInterceptors(addMessages);
        setUpInterceptorsAPIResponseError(handleAPIResponseError);
        setForceUserToLoginForInterceptors(forceUserToLogin)
    }, [])
    
    // Not rendering anything
    return null
}

export default InjectAxiosInterceptors