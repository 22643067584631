import { useEffect, useRef, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useTheme } from '@material-ui/styles'
import { Box, Grid, Switch, Typography } from '@material-ui/core'
import { fromCentTo2dec, priceToString, productAmountHasPromoCodeApplied, getFinalAmountWithoutPromoCode, round } from '../../aux/purchaseHelpers';
import CheckoutInSubscriptionItem from '../CheckoutInSubscriptionItem/CheckoutInSubscriptionItem';
import { getFromSafeObject, isEmptyObject, isNotEmptyObject, textTransform, textTruncate } from '../../aux/aux';
import './checkoutSubscriptionItem.css';
import CheckoutItem from '../CheckoutItem/CheckoutItem';
import { WarningAmber } from '@mui/icons-material';
import ToggleMsg from '../ToggleMsg/ToggleMsg';

const CheckoutSubscriptionItem = ({ swapItems, deleteOrUpdateItemQuantity, product, productAmount, alternativeProduct, isCheckoutUpdating, subscribedProductsAndCouponsMap }) => {
    const theme = useTheme();
    const MAX_NAME_CHAR = 23;
    const { t } = useTranslation('checkout', { keyPrefix: "checkoutSubscriptionItem" });
    const [showTermAlternative, setShowTermAlternative] = useState(false);
    const [checkedUpgradeTerm, setCheckedUpgradeTerm] = useState(undefined);
    const [alternativeTermProductIds, setAlternativeTermProductIds] = useState(undefined);
    const [alternativeTermProduct, setAlternativeTermProduct] = useState(undefined); 
    const isPromoCodeApplied = productAmountHasPromoCodeApplied(productAmount);
    const [error, setError] = useState({isError:false, type:undefined, msg:undefined})
    const abortControllerSignalRef = useRef(null);
    const styles = {
        mainContainer: {
            borderColor: theme.palette.primary.light,
            backgroundColor: theme.palette.common.white,
        },
        termName:{
            fontSize: theme.typography.caption.fontSize,
            color: theme.palette.grey[500]
        },
        switchContainer: {
            backgroundColor: theme.palette.primary.light,
            color: 'white'
        },
        icon:{
            display:"block",
            fontSize: theme.typography.body2.fontSize,
        },
        errorMessageContainer:{
            color:theme.palette.error.main,
        },
        switchAlternativeAmountDiff: {
            // color: theme.palette.primary.dark,
            backgroundColor: theme.palette.secondary.dark,
        },
        notes:{
            color: theme.palette.grey[500]
        }
    }
    const onSwitch = async (e, toggled, signal=abortControllerSignalRef.current) => {
        const alternativeProduct = alternativeTermProduct.subscriptionProduct;
        try{
            e.preventDefault()
            const oldProductId = product.productId;
            const newProductId = alternativeProduct.id;
            await swapItems(oldProductId, newProductId);
        }catch(error){
            // TODO: Disable switch if needed
            if(!signal.aborted){
                setError({
                    isError:true,
                    msg:t('errorSwapping', {selectedSubscriptionTermName: getFromSafeObject(product, 'term.translation.nameTranslated')}),
                    type:'swap'
                })
            }
        }
    };

    useEffect(() => {
        if(alternativeProduct.termAlternativeArray.length > 0){
            let largeTermProductId;
            let shortTermProductId;
            if(!alternativeTermProductIds){
                // Define longest and previousShorterTerm
                const termAlternativeArray = alternativeProduct.termAlternativeArray;
                let longestTermAlternativeIndex;
                let previousShorterTermAlternativeIndex;
                let minMaxLengthDiff = [product.term.length.totalInMilliseconds, 0];
                termAlternativeArray.forEach( (altProduct, index) => {
                    let lengthDiff = product.term.length.totalInMilliseconds - altProduct.typeProduct.term.length.totalInMilliseconds;
                    if(lengthDiff < 0 && lengthDiff < minMaxLengthDiff[1]){
                        longestTermAlternativeIndex = index;
                        minMaxLengthDiff[1] = lengthDiff;
                    }
                    if(lengthDiff > 0 && lengthDiff < minMaxLengthDiff[0]){
                        previousShorterTermAlternativeIndex = index;
                        minMaxLengthDiff[0] = lengthDiff;
                    }
                })
                if(longestTermAlternativeIndex == null){
                    // There no longer subscription alternative
                    largeTermProductId = product.productId;
                    shortTermProductId = alternativeProduct.termAlternativeArray[previousShorterTermAlternativeIndex].id;
                }else{
                    largeTermProductId = alternativeProduct.termAlternativeArray[longestTermAlternativeIndex].id;
                    shortTermProductId = product.productId;
                }
                setAlternativeTermProductIds({
                    largeTermProductId,
                    shortTermProductId
                })
            }else{
                largeTermProductId = alternativeTermProductIds.largeTermProductId;
                shortTermProductId = alternativeTermProductIds.shortTermProductId;
            }
            // Set alternative to product
            let alternativeTermId;
            let isCheckedUpgradeTerm = false;
            if(product.productId === largeTermProductId){
                isCheckedUpgradeTerm = true;
                alternativeTermId = shortTermProductId;
            }else{
                alternativeTermId = largeTermProductId;
            }
            const alternativeTerm = alternativeProduct.termAlternativeArray.find(altProduct => altProduct.id === alternativeTermId);
            let largerTermInMilliseconds = Math.max(alternativeTerm.typeProduct.term.length.totalInMilliseconds, product.term.length.totalInMilliseconds);
            let currentAmountPerMilliSecond = productAmount.finalAmount / product.term.length.totalInMilliseconds;
            let alternativeAmountPerMilliSecond = alternativeTerm.consumptionDetails.details.purchasabilityDetails.details.amount.finalAmount / alternativeTerm.typeProduct.term.length.totalInMilliseconds;
            let alternativeFinalAmountDiffPercent = (alternativeAmountPerMilliSecond - currentAmountPerMilliSecond) / currentAmountPerMilliSecond;
            setAlternativeTermProduct({
                subscriptionProduct: alternativeTerm,
                finalAmountDiff:{
                    percent: alternativeFinalAmountDiffPercent,
                    amount: round(alternativeFinalAmountDiffPercent * currentAmountPerMilliSecond * largerTermInMilliseconds, 0),
                    currencyId: alternativeTerm.consumptionDetails.currencyId,
                }
            });
            setCheckedUpgradeTerm(isCheckedUpgradeTerm);
            setShowTermAlternative(true);
        }else{
            setShowTermAlternative(false);
            setCheckedUpgradeTerm(undefined);
            setAlternativeTermProduct(undefined);
            setAlternativeTermProductIds(undefined);
        }

    },[product])

    useEffect(() => {
        const abortController = new AbortController();
        abortControllerSignalRef.current = abortController.signal;
        return(() => abortController.abort());
    },[])

    return (
        isNotEmptyObject(product) && isNotEmptyObject(productAmount) ?
            <Grid container direction="column" className={`checkout-subscription-item-main-container`}>
                <Grid item>
                    <Grid className='checkout-subscription-item-subcontainer' container direction="column" style={styles.mainContainer}>
                        <Grid item className='checkout-subscription-item-container'>
                            <CheckoutItem 
                                deleteOrUpdateItemQuantity={deleteOrUpdateItemQuantity} 
                                isCheckoutUpdating={isCheckoutUpdating} 
                                product={product} 
                                productAmount={productAmount}
                                nameToDisplay={t('typeAndTerm', {typeName:getFromSafeObject(product, 'type.name') , termName:getFromSafeObject(product, 'term.translation.nameTranslated')})}
                            />
                        </Grid>
                        {!isEmptyObject(subscribedProductsAndCouponsMap) &&
                            <Grid item className='checkout-subscription-subscribed-items-container'>
                                {Object.entries(subscribedProductsAndCouponsMap).map( ([productId, product], index) => {
                                    return(
                                        <CheckoutInSubscriptionItem key={`in_sub_${productId}`} product={product} />
                                    )
                                })}
                            </Grid>
                        }
                        {showTermAlternative && alternativeTermProduct != null ?
                                <Grid item className='checkout-subscription-item-switch-container'  style={styles.switchContainer} >
                                    <Grid container direction="row" className='checkout-subscription-item-switch-subcontainer'>
                                        <Grid item >
                                            <Switch color='primary' disabled={isCheckoutUpdating} checked={checkedUpgradeTerm} onChange={onSwitch} size="small" />
                                        </Grid>
                                        <Grid item xs className='checkout-subscription-item-switch-container-description-container'>
                                            {checkedUpgradeTerm ?
                                                <Typography variant="caption" style={{display:'block'}}>
                                                    {t("changeToTermAndPay", {
                                                        subscriptionTermName: alternativeTermProduct.subscriptionProduct.typeProduct.term.translation.nameTranslated, 
                                                        amount: fromCentTo2dec(alternativeTermProduct.subscriptionProduct.consumptionDetails.details.purchasabilityDetails.details.amount.finalAmount),
                                                        formatParams:{
                                                            amount:{
                                                                currency: alternativeTermProduct.finalAmountDiff.currencyId,
                                                                trailingZeroDisplay:'stripIfInteger'
                                                            }
                                                        },
                                                    })}
                                                </Typography>
                                                :
                                                <Typography variant='caption' style={{display:'block'}}>
                                                    <Trans 
                                                        t={t} 
                                                        i18nKey={'saveWithTermAndPay'}
                                                        values={{
                                                            subscriptionTermName: alternativeTermProduct.subscriptionProduct.typeProduct.term.translation.nameTranslated, 
                                                            savePercent: Math.abs(alternativeTermProduct.finalAmountDiff.percent),
                                                            amount: fromCentTo2dec(alternativeTermProduct.subscriptionProduct.consumptionDetails.details.purchasabilityDetails.details.amount.finalAmount),    
                                                            formatParams:{
                                                                amount:{
                                                                    currency: alternativeTermProduct.finalAmountDiff.currencyId,
                                                                    trailingZeroDisplay:'stripIfInteger'
                                                                },
                                                                savePercent:{
                                                                    style: 'percent',
                                                                    trailingZeroDisplay:'stripIfInteger'
                                                                }
                                                            },
                                                        }} 
                                                        components={{ discountTag: <span className="checkout-subscription-item-switch-alternative-diff" style={styles.switchAlternativeAmountDiff} /> }}
                                                    />
                                                </Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                :
                                null
                        }
                    </Grid>
                </Grid>
                <Grid item>
                    <ToggleMsg
                        extraClassName={"checkout-subscription-item-error-toggle-container"}
                        isShown={error.isError}
                        variant='caption'
                        msg={error.msg}
                        onShowTime={() => setError(prev => ({...prev, isError:false}))}
                    />
                </Grid>
            </Grid>
            :
            null
            
    )
}

export default CheckoutSubscriptionItem