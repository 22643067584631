import { useTranslation } from 'react-i18next'
import { Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles'
import HeaderWrapperSection from '../HeaderWrapperSection/HeaderWrapperSection'
import CustomerServiceBadge from '../CustomerServiceBadge/CustomerServiceBadge';
import './contentPurchasePerks.css'
import ClickableLoadingText from '../ClickableLoadingText/ClickableLoadingText';
import { getFromSafeObject, textTransform } from '../../aux/aux';
import { useHistory } from 'react-router-dom';

const ContentPurchasePerks = ({ title=undefined, subtitle=undefined, perksToHideMap={ refund:false, discount:false, updates:false, access:false }}) => {
    const REFUND_PATH = '/refund';
    const { t } = useTranslation('contentProduct', { keyPrefix: 'contentPurchasePerks'});
    const history = useHistory();
    const matchMobile = useMediaQuery('(max-width:680px)');
    const theme = useTheme();
    const styles = {
        perkContainer:{
            boxShadow: theme.shadows[1],
            backgroundColor: theme.palette.background.paper,
        },
        title:{
            fontWeight: 'bold',
            color: theme.palette.primary.main
        },
        description:{
            whiteSpace: "pre-line",
        }
    }
    const handleGoToRefundProcessPage = (e) => {
        e.preventDefault();
        history.push(REFUND_PATH);
    }
    return (
        <HeaderWrapperSection
            title={title || t('investInYourQuantumCareer')}
            subtitle={subtitle}
            wrapperClassName={'content-purchase-perks-wrapper'}

        >
            <div className='content-purchase-perks-main-container'>
                {!(perksToHideMap && perksToHideMap.refund) &&
                    <div className='content-purchase-perks-risk-free-container' style={styles.perkContainer}>
                        <CustomerServiceBadge className='content-purchase-perks-customer-service-badge-container' />
                        <div className='content-purchase-perks-risk-free-content'>
                            <Typography variant={matchMobile ? 'h6':'h5'} style={styles.title}>
                                {t('riskFree-title')}
                            </Typography>
                            <Typography varaint='body1' style={styles.description}>
                                {t('riskFree-description')}
                            </Typography>
                            <ClickableLoadingText 
                                isLoading={false} 
                                isVisible={true} 
                                underlineOnHover={true} 
                                text={textTransform('title', t('seeRefundProcess'))} 
                                variant="body2"
                                onClick={handleGoToRefundProcessPage}
                            />
                        </div>
                    </div>
                }
                <div className='content-purchase-perks-three-perks-container'>
                    {!(perksToHideMap && perksToHideMap.access) &&
                        <div className='content-purchase-perks-perk-container' style={styles.perkContainer}>
                            <Typography variant={matchMobile ? 'body1':'h6'} style={styles.title}>
                                {textTransform('capitalize', t('unlimitedAccess-title'))}
                            </Typography>
                            <Typography variant='body1' style={styles.description}>
                                {textTransform('title', t('unlimitedAccess-description'))}
                            </Typography>
                        </div>
                    }
                    {!(perksToHideMap && perksToHideMap.updates) &&
                        <div className='content-purchase-perks-perk-container' style={styles.perkContainer}>
                            <Typography variant={matchMobile ? 'body1':'h6'} style={styles.title}>
                                {textTransform('capitalize', t('alwaysUpdated-title'))}
                            </Typography>
                            <Typography variant='body1' style={styles.description}>
                                {textTransform('title', t('alwaysUpdated-description'))}
                            </Typography>
                        </div>
                    }
                    {!(perksToHideMap && perksToHideMap.discount) || true &&
                        <div className='content-purchase-perks-perk-container' style={styles.perkContainer}>
                            <Typography variant={matchMobile ? 'body1':'h6'} style={styles.title}>
                                {textTransform('capitalize', t('limitedDiscount-title'))}
                            </Typography>
                            <Typography variant='body1' style={styles.description}>
                                {textTransform('title', t('limitedDiscount-description'))}
                            </Typography>
                        </div>
                    }
                </div>  
            </div>
        </HeaderWrapperSection>
    )
}

export default ContentPurchasePerks