/*
    This component only has development purposes
    This file contains debug functions that must be shared across different components
*/

function promisingTimeout( time, callback=null ){
    let promise
    if(callback){
        promise = new Promise((resolve) => { 
            setTimeout(() => {
                callback();
                resolve()
            }, time);
        });
    }else{
        promise = new Promise((resolve) => { setTimeout(resolve, time)});
    }
    return promise;
}

// Loop asynchonously until condition is met
async function until(conditionFunction) {
    const poll = (resolve, reject) => {
        if(conditionFunction()) resolve();
        else setTimeout(_ => poll(resolve), 400);
    }
    return new Promise(poll);
}

function fromTermNameToTimePeriod(termName){
    const termToTime = {
        'annual': 'year',
        'monthly': 'month',
        'dayly':'day',
        'everyMinute': 'minute'
    };
    return termToTime.hasOwnProperty(termName) ? termToTime[termName] : termName
}

/**
 * Note: It cannot be used to identify not Empty objects. Use isNotEmptyObject instead
 * @param {*} object 
 * @returns true if the given object is Object and empty, false otherwise
 */
function isEmptyObject(object){
    return object instanceof Object && Object.keys(object).length === 0;
}
/**
 * Note: It cannot be used to identify Empty objects. Use isEmptyObject instead
 * @param {*} object 
 * @returns true if the given object is Object and not empty, false otherwise
 */
function isNotEmptyObject(object){
    return object instanceof Object && Object.keys(object).length > 0;
}

function getInsertableDate(date){
    // return date.toJSON().slice(0, 19).replace('T', ' '); // Problems parsing comparison terms in sql query because they rely on ' '
    return date.toJSON().slice(0, 19); // toJSON returns UTC time 
}

function isValidDate(date){
    return date instanceof Date && !isNaN(date);
}
function getStartDayDate(date){
    const startDateDate = new Date(date.getTime());
    startDateDate.setHours(0);
    startDateDate.setMinutes(0);
    startDateDate.setSeconds(0);
    return startDateDate
}
function isFloat(value) {
    let result = false;
    if(typeof value == 'number' && !isNaN(value)){
        result = ! Number.isInteger(value);
    }
    return result
}

function someEmptyPropertyObject(object){
    const isObject = object instanceof Object;
    return isObject && Object.values(object).some( propValue => propValue === '' || propValue == null);
}

function emptyPropertiesObject(object){
    const isObject = object instanceof Object;
    let hasEmptyProperties = true; // Assumption
    let hasFilledProperties = true; // Assumption
    if(isObject){
        Object.values(object).forEach( propValue => hasEmptyProperties &&= propValue === '' || propValue == null);
    }
    return isObject && hasEmptyProperties;
}

function textTransform(option, text){
    let transformedText;
    if(typeof(text) === 'string'){
        let textArray = text.split(' ')
        switch(option){
            case 'capitalize':
                transformedText = textArray.map( word => {
                    return word.charAt(0).toUpperCase() + word.slice(1);
                }).join(' ');
                break;
            case 'title':
                transformedText = text.charAt(0).toUpperCase() + text.slice(1);
                break;
            default:
                transformedText = text;
        }
    }
    return transformedText
}

function toTimescaleFromMilliseconds(timescale, milliseconds){
    let time;
    switch(timescale){
        case('second'):
        case('sec'):
            time = milliseconds / 1000;
            break;
        case('minute'):
        case('min'):
            time = milliseconds / (60 * 1000);
            break;
        case('hour'):
            time = milliseconds / (60 * 60 * 1000);
            break;
        case('day'):
            time = milliseconds / (24 * 60 * 60 * 1000);
            break;
        case('week'):
            time = milliseconds / (7 * 24 * 60 * 60 * 1000);
            break;
        default:
            throw new Error('timescale is unknown');
    }
    return time;
}

function toMillisecondsFromTimescale(timescale, time){
    let milliseconds;
    switch(timescale){
        case('second'):
        case('sec'):
            milliseconds = time * 1000;
            break;
        case('minute'):
        case('min'):
            milliseconds = time * (60 * 1000);
            break;
        case('hour'):
            milliseconds = time * (60 * 60 * 1000);
            break;
        case('day'):
            milliseconds = time * (24 * 60 * 60 * 1000);
            break;
        case('week'):
            milliseconds = time * (7 * 24 * 60 * 60 * 1000);
            break;
        default:
            throw new Error('timescale is unknown');
    }
    return milliseconds;
}

function urlFromBlob(blob){
    return URL.createObjectURL(blob);
}

function downloadFileFromBlob( blob, fileName){
    // Create blob link to download
    // const url = window.URL.createObjectURL(
    //     new Blob([data]),
    // );
    const url = window.URL.createObjectURL(new Blob([blob]),);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
        'download',
        fileName,
    );
    // Append to html link element page
    document.body.appendChild(link);
    // Start download
    link.click();
    // Clean up and remove the link
    link.parentNode.removeChild(link);
}

function textTruncate(string, length, ending='...'){
    let truncatedText;
    if(typeof(string) === 'string'){
        if(string.length > length){
            truncatedText = string.substring(0, length - ending.length) + ending;
        }else{
            truncatedText = string;
        }
    }
    return truncatedText
}

function isEmptyString(string){
    return typeof(string) === 'string' && string.trim().length === 0;
}
const RGB_REGEX = /^rgb\(([0-9]{1,3}), ?([0-9]{1,3}), ?([0-9]{1,3})\)$/;
const RGBA_REGEX = /^rgba\(([0-9]{1,3}, ?){3}(0(\.[0-9]{1,3})?|1)\)$/;

function isRgbColor(rgbString){
    return rgbString.match(RGB_REGEX) !== null;
}

function isRgbaColor(rgbaString){
    return rgbaString.match(RGBA_REGEX) !== null;
}

function rgbToRgbaOrUndefined(rgbString='', alpha){
    let rgba;
    if(rgbString.match(RGB_REGEX) !== null){
        rgba = rgbString.replace('rgb', 'rgba').replace(')', `, ${alpha})`);
    }
    return rgba
}

function rgbStringToArray(string=''){
    const rgbMatch = RGB_REGEX.exec(string, /g/);
    let result = [];
    if(rgbMatch !== null){
        result = rgbMatch.slice(1,4).map(rgbComp => parseInt(rgbComp));   
    }
    return result;
}

function rgbArrayToString(array){
    let string = '';
    if(array instanceof Array && array.length === 3){
        string = `rgb(${array[0]}, ${array[1]}, ${array[2]})`
    }
    return string
}

function randomRgbColor(rgbString1='', rgbString2='', fallbackValues=[0,255]){
    const rgb1 = rgbStringToArray(rgbString1);
    const rgb2 = rgbStringToArray(rgbString2);
    const randomRgb = [];
    for(var i=0; i<3; i++){
        const baseValue = rgb1[i] || fallbackValues[0];
        const topValue = rgb2[i] || fallbackValues[1];
        randomRgb[i] = generateRandomInt(baseValue, topValue);
    };
    return rgbArrayToString(randomRgb)
}
function rgbLightnessModifyOrUndefined(rgbString='', lightnessModifier=-0.1){
    const rgb = rgbStringToArray(rgbString);
    let modifyRgb = rgb.map(value => parseInt(value + value * lightnessModifier));
    return rgbArrayToString(modifyRgb) || undefined;
}
/**
 * Safely returns a property value from a nested object based on a propPathString given 
 * @param {*} nestedObject Example {address:{city:{cp:['28002', '28882']}}}
 * @param {string} propPathString string of object props nested by '.' Example: 'address.city.cp[0]'
 *      if '' returns the whole object
 *      if the property does not exist it returns defaultPropValue
 * @param {*} defaultPropValue this values is returned if propPath is not found
 * @returns 
 */
function getFromSafeObject(nestedObject, propPathString, defaultPropValue=undefined){
    const pathArray = [];
    propPathString.replaceAll(/[\[\]]/g, '.').split('.').forEach(prop => {if(prop) pathArray.push(prop.trim())});
    return pathArray.reduce((obj, key) => (obj && obj[key] !== undefined) ? obj[key] : defaultPropValue, nestedObject)
}

function isToday(date){
    const today = new Date();
    return date.getUTCDate() === today.getUTCDate() &&
        date.getUTCMonth() === today.getUTCMonth() &&
        date.getUTCFullYear() === today.getUTCFullYear();
}
function isHappening(startDate, endDate){
    const now = new Date();
    return startDate.getTime() <= now.getTime() && now.getTime() < endDate.getTime()
};

function arrayToQueryParam(array){
    if(array instanceof Array){
        return array.join(',');
    }else{
        throw new Error(`${array} must be an array`);
    }
};

function isStringifyJSON(string){
    try{
        JSON.parse(string);
        return true;
    }catch(error){
        return false;
    }
}

function removeScriptBySrc(src, all=false, checkBy='starts'){
    const scriptArray = document.scripts;
    for( let script of scriptArray ){
        let meetCondition
        switch(checkBy){
            case 'starts':
                meetCondition = script.src.startsWith(src);
                break;
            case 'ends':
                meetCondition = script.src.endsWith(src);
                break;
            case 'includes':
                meetCondition = script.src.includes(src);
                break;
            default:
                meetCondition = script.src === src;
        } 
        if(meetCondition){
            script.remove();
            if(!all){
                break;
            }
        }
    }
} 

function reloadScript(id, callback=()=>{}, errorCallback=()=>{}){
    const script = document.getElementById(id);
    if(script){
        const timestamp = new Date().getTime();
        const oldName = id + `-${timestamp}`
        script.id = oldName;
        const onSuccess = () => {
            script.remove();
            callback();
        }
        const onError = () => {
            const reloadedScript = document.getElementById(id);
            if(reloadedScript){
                reloadedScript.remove();
            }
            script.id = id;
            errorCallback();
        }
        let [href, search] = script.src.split('?');
        if(search){
            search = search.replace(/&?preventCache=\d*/g, '');
            search += search.match('=') ? '&' : '';
        }
        const preventCacheUrl = href + '?' + search + `preventCache=${timestamp}`;
        loadScript(id, preventCacheUrl, onSuccess, onError);
    }
}

function loadScript(id, url, callback=()=>{}, errorCallback=()=>{}){
    let script = document.getElementById(id);
    if (!script) {
        script = document.createElement("script");
        script.type = "text/javascript";
        script.id = id;
        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
        if (script.readyState) {
            script.onreadystatechange = function(){
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    callback();
                    script.onreadystatechange = null;
                }
            };
        } else {
            script.onload = function(){
                callback();
                script.onload = null;
            }
        }
        script.onerror = function(){
            errorCallback();
            script.onerror = null;
        }
    } else {
        callback();
    }
};

function getSafeDateOrUndefined(potencialDate){
    let date;
    if(potencialDate instanceof Date){
        date = potencialDate
    }else if(typeof potencialDate === 'string'){
        const isParseableDate = Date.parse(potencialDate);
        if(isParseableDate){
            date = new Date(potencialDate);
        }
    }
    return date;
}


function fromArrayOfObjectsToMap(array, keyPropertyString='', map={}){
    if(array.length > 0){
        array.forEach((elem,index) => {
            if(elem instanceof Object){
                if(keyPropertyString){
                    if(elem.hasOwnProperty(keyPropertyString)){
                        map[elem[keyPropertyString]] = elem;
                    }else{
                        throw new Error(`Objects in array must have \'${keyPropertyString}\' property`);
                    }
                }else{
                    map[index] = elem;
                }
            }else{
                throw new Error('Needs an array of Objects');
            }
        })
    }
    return map
}

function generateRandomInt(min=0, max=10){
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export {
    until,
    promisingTimeout,
    isEmptyObject,
    isNotEmptyObject,
    getInsertableDate,
    someEmptyPropertyObject,
    emptyPropertiesObject,
    fromTermNameToTimePeriod,
    isValidDate,
    getStartDayDate,
    textTransform,
    textTruncate,
    isFloat,
    toTimescaleFromMilliseconds,
    toMillisecondsFromTimescale,
    isEmptyString,
    downloadFileFromBlob,
    isRgbColor,
    isRgbaColor,
    rgbToRgbaOrUndefined,
    rgbStringToArray,
    rgbLightnessModifyOrUndefined,
    randomRgbColor,
    getFromSafeObject,
    isToday,
    isHappening,
    arrayToQueryParam,
    urlFromBlob,
    isStringifyJSON,
    loadScript,
    reloadScript,
    removeScriptBySrc,
    generateRandomInt,
    fromArrayOfObjectsToMap,
    getSafeDateOrUndefined
}