import { useEffect, useRef, useState } from 'react'
import LoadingComponent from '../LoadingComponent/LoadingComponent'
import SimpleNoModalDialog from '../SimpleNoModalDialog/SimpleNoModalDialog'
import { useTranslation } from 'react-i18next'
import {Alert} from '@mui/material';
import { justSetUser, refreshUser } from '../../services/sessionServices'
import './addToSubscriptionNoModalDialog.css'
import { getFromSafeObject, getInsertableDate, isEmptyObject, isNotEmptyObject, textTransform } from '../../aux/aux'
import { addProductToUserSubscription, getUserProductConsumptionOption } from '../../services/userServices'
import { getCustomErrorOrUndefined } from '../../aux/errorHelpers'
import { useRedirect } from '../../customHooks/useRedirect'
import { useLocaleCtx } from '../../customHooks/useLocaleCtx'
import AddToSubscriptionItem from '../AddToSubscriptionItem/AddToSubscriptionItem'

const DEFAULT_PRODUCT_IDENTIFIER = {
    productId:undefined,
    editionNum:undefined
}

const AddToSubscriptionNoModalDialog = ({ user, setUser, productIdentifier=DEFAULT_PRODUCT_IDENTIFIER, onClose, onSuccess }) => {
    const DEFAULT_QUANTITY_TO_SUBSCRIBE = 1;
    const COMPONENT_STATES_ARRAY = ['loading', 'add', 'feedback'];
    const FEEDBACK_STATES_ARRAY = ['successNeedsRefresh', 'success', 'error', 'unknown'];
    const { t } = useTranslation('common', { keyPrefix: 'addToSubscriptionNoModalDialog'});
    const localeCtx = useLocaleCtx();
    const [quantity, setQuantity] = useState(DEFAULT_QUANTITY_TO_SUBSCRIBE);
    const [productConsumptionOption, setProductConsumptionOption] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState(COMPONENT_STATES_ARRAY[0]);
    const [feedbackState, setFeedbackState] = useState(null);
    const [error, setError] = useState({message:undefined, severity:undefined, isError:false});
    const abortControllerSignalRef = useRef(null);
    const redirectApi = useRedirect()

    const requestAddProductToUserSubscription = async(e, signal=abortControllerSignalRef.current) => {
        const setRefreshedUser = async (setUser, signal) => {
            const user = await refreshUser(signal);
            if(!signal || !signal.aborted){
                justSetUser(user, setUser);
            }
        }   
        try{
            e.preventDefault();
            setIsLoading(true);
            const productDetails = {
                productId: productConsumptionOption.id
            };
            const addDetails = {
                quantity,
                effectiveAt: getInsertableDate(new Date())
            };
            await addProductToUserSubscription(user.basicData.id, productDetails, addDetails, 1000, signal);
            try{
                if(!signal.aborted){
                    await setRefreshedUser(setUser, signal);
                    setIsLoading(false);
                    // Close or feedback
                    if(typeof onSuccess === 'function') onSuccess([productConsumptionOption]);
                    handleOnClose()
                }
            }catch(error){
                if(!signal.aborted){
                    setIsLoading(false)
                    setError({
                        severity:'success', 
                        message: textTransform('title', t('feedback.success.selectedProductsAddedToSubscription')), 
                        isError:true
                    })
                    setState('feedback');
                    setFeedbackState(FEEDBACK_STATES_ARRAY[0]);
                }
            }
        }catch(error){
            console.log(error)
            let message = textTransform('title', t('feedback.error.selectedProductsCannotBeAddedToSubscription'))  
            let severity = 'error';
            const customError = getCustomErrorOrUndefined(error);
            if(customError){
                const userProductSubscriptabilityDetails = getFromSafeObject(customError, 'payload.userProductSubscriptabilityDetails');
                // TODO: implement filter error message based on noSubscriptabilityType
            }
            if(!signal.aborted){
                setIsLoading(false);
                setError({severity, message, isError:true})
            }
        }
    }

    const requestProductConsumptionOption = async(userId, productIdentifier, localeId, signal) => {
        try{
            setState('loading');
            const res = await getUserProductConsumptionOption(userId, productIdentifier, localeId, quantity, 0, signal);
            const {
                productConsumptionOption,
                userProductPurchasabilityDetails,
                userProductSubscriptabilityDetails
            } = res.data
            if(!signal.aborted){
                setProductConsumptionOption(productConsumptionOption)
                setState('add');
            }
        }catch(err){
            if(!signal.aborted){
                setState('feedback');
                setFeedbackState(FEEDBACK_STATES_ARRAY[2]);
            }
        }
    }

    const handleOnClose = () => {
        setError({isError:false, message:undefined, severity:undefined});
        setIsLoading(false);
        setFeedbackState(null);
        onClose();
    }
    
    useEffect(() => {
        if(productIdentifier.productId && isNotEmptyObject(user)){
            const abortController = new AbortController();
            setError({isError:false, message:undefined, severity:undefined})
            requestProductConsumptionOption(user.basicData.id, productIdentifier, localeCtx.localeId, abortController.signal);
            abortControllerSignalRef.current = abortController.signal;
            return (() => abortController.abort())
        }else{
            const message = user == null || isEmptyObject(user) ? t('feedback.error.sessionWasNotFound'): t('feedback.error.productToAddWasNotFound')
            setError({isError:true, message: textTransform('title', message), severity:'error'})
            setFeedbackState('error');
            setState('feedback');
        }
    },[productIdentifier, user, localeCtx.localeId])

    const getComponentToRender = () => {
        const getFeedbackComponentToRender = () => {
            let feedbackComponent;
            switch(feedbackState){
                case FEEDBACK_STATES_ARRAY[0]:
                    // successNeedsRefresh
                    feedbackComponent = 
                        <SimpleNoModalDialog
                            contentText={`${textTransform('title',t('feedback.success.needRefeshToUpdateChanges'))}. ${textTransform('title',t('feedback.success.wantToDoItNow'))}`}
                            onAgree={redirectApi.refreshRoute}
                            onDisagree={handleOnClose}
                        />
                    break;
                case FEEDBACK_STATES_ARRAY[1]:
                    // success
                    // Unused
                    break;
                case FEEDBACK_STATES_ARRAY[2]:
                    // error
                    feedbackComponent = 
                        <SimpleNoModalDialog
                            contentText={`${textTransform('title', t('feedback.error.uups'))}\n${textTransform('title',t('feedback.error.selectedProductsCannotBeAddedToSubscription'))}`}
                            onAgree={handleOnClose}
                        />
                    break;
                default:
                    feedbackComponent = 
                        <SimpleNoModalDialog
                            contentText={`${textTransform('title',t('feedback.error.logInASessionWithActiveSubscription'))}.`}
                            onAgree={handleOnClose}
                        />
                    break;
            }   
            return feedbackComponent
        }
        let component;
        switch(state){
            case 'loading':
                component = <LoadingComponent visibleElements='circle'/>
                break;
            case 'add':
                component = 
                    <SimpleNoModalDialog    
                        contentText={textTransform('title', t('addToSubscriptionSelectedProducts'))}
                        onAgree={requestAddProductToUserSubscription}
                        onDisagree= {handleOnClose}
                        isLoading={isLoading}
                        isDisabled={error.isError}
                    >
                        <div className='add-to-subscription-no-modal-dialog-add-items-container'>
                            <AddToSubscriptionItem productOption={productConsumptionOption} />
                        </div>
                    </SimpleNoModalDialog>
                break;
            case 'feedback':
                component = getFeedbackComponentToRender();
                break;
        }
        return component;
    }
    return (
        <div className='add-to-subscription-no-modal-dialog-wrapper'>
            <div className={`add-to-subscription-no-modal-dialog-warning-toggle${error.isError ? ' open': ''}`}>
                <Alert severity={error.severity}>
                    {error.message}
                </Alert>
            </div>
            <div className='add-to-subscription-no-modal-dialog-main-container'>
                {getComponentToRender()}
            </div>
        </div>
    )
}

export default AddToSubscriptionNoModalDialog