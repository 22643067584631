import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, Grid, Divider, Button } from '@material-ui/core'

import { useUpdateLocaleCtx } from '../../customHooks/useUpdateLocaleCtx';
import { useUpdateCountryAndCurrencyCtx } from '../../customHooks/useUpdateCountryAndCurrencyCtx';
import { justLogoutUser, justSetUser } from '../../services/sessionServices';

import CreateAccountForm from '../CreateAccountForm/CreateAccountForm'
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import PopUpModal from '../PopUpModal/PopUpModal'
import VerifyAccountForm from '../VerifyAccountForm/VerifyAccountForm';

// Style
import './popUpCreateAccount.css'
import { textTransform, isNotEmptyObject } from '../../aux/aux';
import CreateUserSystemForm from '../CreateUserSystemForm/CreateUserSystemForm';
import LogInForm from '../LogInForm/LogInForm';
import BackButton from '../BackButton/BackButton';
import { isUserLogged } from '../../aux/sessionHelpers';
import LogOutPreviousSessionModal from '../LogOutPreviousSessionModal/LogOutPreviousSessionModal';

const PopUpCreateAccount = ({ setShowModal, showModal, setUser, user, passiveVerification=true }) => {
    const POP_UP_WIDTH = 380; //450px
    const innerDivRef = useRef(null);
    const { t } = useTranslation('common', {keyPrefix: 'popUpCreateAccount'});
    const updateLocaleCtx = useUpdateLocaleCtx();
    const updateCountryAndCurrencyCtx = useUpdateCountryAndCurrencyCtx();
    const [valuesToLogin, setValuesToLogin] = useState(null);
    const [needsVerification, setNeedsVerification] = useState(false);
    const [valuesFromLogin, setValuesFromLogin] = useState(null);
    const statusTypes = ['signUp', 'logIn', 'verifyAccount', 'createUserSystem', 'logout'];
    const [status, setStatus] = useState(statusTypes[0]);
    const [innerDivHeight, setInnerDivHeight] = useState(null);
    const [showLoginAlternative, setShowLoginAlternative] = useState(false);

    const handleClose = async (e) => {
        setShowModal(false);
        if(valuesFromLogin){
            justSetUser(valuesFromLogin, setUser);
            updateCountryAndCurrencyCtx.setCountryAndCurrency(valuesFromLogin.countryAndCurrency);
            await updateLocaleCtx(valuesFromLogin.locale);
        }
    };

    const handleOnLogOut = () => {
        justLogoutUser(setUser);
        updateCountryAndCurrencyCtx.resetCountryAndCurrencyToSessionValues();
    }

    const areCompleteValuesToLogIn = (valuesToLogin) => {
        return isNotEmptyObject(valuesToLogin) && valuesToLogin.email != null && valuesToLogin.password != null;
    }

    const handleFormError = (createAccountErrorType) => {
        setShowLoginAlternative(createAccountErrorType === 'existingEmail')
    };

    const handleOnChangeForm = (field, value) => {
        setShowLoginAlternative(prev => (field === 'email' ? false : prev))
    }

    const handleClickOnLogin = (e) => {
        e.preventDefault();
        setStatus(statusTypes[1]);
    }

    const handleOnVerifyAccount = (email, password) => {
        setNeedsVerification(true);
        setValuesToLogin({email, password})
    }

    const handleOnVerifiedUserAccount = () => {
        setNeedsVerification(false);
    }

    const handleOnLogOutPreviousUser = () => {
        // It is handle by an useEffect linked to user
        // setIsAnotherUserLogged(false);
    }

    const setPopUpHeight = () => {
        // preventing to be triggered before css transitions
        if(innerDivRef.current != null){
            setTimeout(() => {
                if(innerDivRef.current != null){
                    setInnerDivHeight(innerDivRef.current.offsetHeight)
                }
            }, 300)
        }
    }

    const handleOnCreateUser = async (valuesToLogin) => {
        setValuesToLogin(valuesToLogin)
    }

    const handleOnLoginUser = (loginUserValues) => {
        setValuesFromLogin(loginUserValues);
    }

    const handleOnBack = (e) => {
        let nextStatusIndex;
        if(status === statusTypes[2]){
            setNeedsVerification(false);
            setValuesToLogin(prev => ({ email:prev.email }))
            nextStatusIndex = 1;
        }else if(status === statusTypes[1]){
            setValuesToLogin(null);
            nextStatusIndex = 0
        }
        if(nextStatusIndex != null){
            setStatus(statusTypes[nextStatusIndex]);
        }
    }

    const getTitleOrSubtitleFromStatus = (isSubtitle=false) => {
        let string = '';
        switch (status) {
            case statusTypes[0]:
                string = isSubtitle === true ? t('itIsEasyAndFast') : t('createYourAccount');
                break;
            case statusTypes[1]:
                if(areCompleteValuesToLogIn(valuesToLogin)){
                    string = isSubtitle === true ? t('gettingYourSessionReady') : t('initiatingSession');
                }else{
                    string = isSubtitle === true ? t('welcome') : t('initializeSession');
                }
                break;
            case statusTypes[2]:
                string = isSubtitle === true ? t('validateYourEmail') : t('verifyAccount');
                break;
            case statusTypes[3]:
                string = isSubtitle === true ? t('selectTheSystemYouUse') : t('yourQuantumSystem');
                break;
            case statusTypes[4]: 
                string = isSubtitle === true ? t('isThereAnOpenSession') : t('logout');
                break;
            default:
                break;
        }
        return textTransform('title', string);
    }

    const getComponentToRender = () => {
        let component
        switch (status) {
            case statusTypes[0]:
                component = 
                    <Grid container direction='column' className='pop-up-create-account-container-animation'>
                        <Grid item>
                            <CreateAccountForm onCreate={handleOnCreateUser} onCreateError={handleFormError} onChange={handleOnChangeForm} onRender={setPopUpHeight} />
                        </Grid>
                        {showLoginAlternative &&
                            <>
                                <Divider style={{marginBottom:'20px'}} />
                                <Grid item align='center' style={{paddingBottom:'20px'}}>
                                    <Button 
                                        variant='contained'
                                        color='primary'
                                        onClick={handleClickOnLogin}
                                    >
                                        {t('login')}
                                    </Button>
                                </Grid>
                            </>
                        }
                    </Grid>
                break;
            case statusTypes[1]:
                component = 
                    <div className='pop-up-create-account-container-animation'>
                        <BackButton isDisabled={false} onClick={handleOnBack} />
                        <LogInForm  
                            formValues={valuesToLogin} 
                            autoLogin={areCompleteValuesToLogIn(valuesToLogin)} 
                            onLogin={handleOnLoginUser} 
                            onRender={setPopUpHeight}
                            onNoVerifiedAccount={handleOnVerifyAccount}
                        />
                    </div>
                break;
            case statusTypes[2]:
                component = 
                    <div className='pop-up-create-account-container-animation'>
                        <BackButton isDisabled={false} onClick={handleOnBack} />
                        <VerifyAccountForm 
                            account={valuesToLogin} 
                            onRender={setPopUpHeight} 
                            onVerified={handleOnVerifiedUserAccount}
                            onCancel={handleClose}
                            passiveVerification={passiveVerification}
                        />
                    </div>;
                break;
            case statusTypes[3]:
                component = 
                    <div className='pop-up-create-account-container-animation'>
                        {valuesFromLogin != null ?
                            <CreateUserSystemForm user={valuesFromLogin.basicData} onCancel={handleClose} onCreate={handleClose} onRender={setPopUpHeight}/>
                        :
                            <Box height={200}>
                                <LoadingComponent visibleElements='circle'/>
                            </Box>
                        }
                    </div>
                break;
            case statusTypes[4]:
                component = 
                <div className='pop-up-create-account-container-animation'>
                    {/* <BackButton isDisabled={false} onClick={handleOnBack} /> */}
                    <LogOutPreviousSessionModal
                        onLogoutProcess={handleOnLogOut}
                        user={user}
                        onCancel={handleClose}
                    />
                </div>;
            default:
                break;
        }
        return component;
    }

    useEffect(() => {
        if(isUserLogged(user)){
            setStatus(statusTypes[4])
        }else if(areCompleteValuesToLogIn(valuesToLogin)){
            if(valuesFromLogin != null){
                setStatus(statusTypes[3]);
            }else{
                let nextStatus;
                if(needsVerification){
                    nextStatus = statusTypes[2];
                }else{
                    nextStatus = statusTypes[1];
                }
                setStatus(nextStatus);
            }
        }else if(valuesFromLogin != null){
            handleClose();
        }else{
            setStatus(statusTypes[0])
        }
    },[valuesToLogin, valuesFromLogin, needsVerification, user])

    useEffect(() => {
        setPopUpHeight();
    }, [status])

    return (
        <PopUpModal showModal={showModal} onClickClose={handleClose} width={POP_UP_WIDTH} height={innerDivHeight} >
            <Grid ref={innerDivRef} className='pop-up-create-account-inner' container direction="column">
                <Grid  item className='pop-up-create-account-title'>
                    <Typography variant="h5">
                        <Box fontWeight="bold">
                            { getTitleOrSubtitleFromStatus() }
                        </Box>
                    </Typography>
                    <Typography variant='body2'>
                        { getTitleOrSubtitleFromStatus(true) }
                    </Typography>
                </Grid>
                <Divider style={{margin:"10px 0px"}} />
                <Grid item>
                    { getComponentToRender() }
                </Grid>
            </Grid>
        </PopUpModal>
    )
}

export default PopUpCreateAccount
