/**
 * React in v17 still recommends a Class Component as ErrorBoundary
 *  */ 
import React from "react";

class ErrorBoundary extends React.Component {
    constructor(props){
        super(props);
        this.state = { error: null }
    }

    static getDerivedStateFromError(error){
        return {error};
    }

    componentDidCatch(error, errorInfo){
        this.props.onError(error, errorInfo)
    };

    render(){
        return this.state.error === null ? this.props.children : this.props.Fallback
    }
}

export default ErrorBoundary