import { useState, useEffect, useRef } from 'react'

import { 
    getContentProductLesson, getUserLessonActionProgress, getUserLessonProgress, getUserLessonTypeProgress,
    getUserSectionProgress, setUpUserLessonActionProgress, setUpUserLessonTypeProgress, setUpUserLessonProgress, 
    setUpUserSectionProgress, backUpUserLessonActionProgress, backUpUserLessonTypeProgress
 } from '../../services/contentServices';

import {Alert} from '@mui/material';

import {getFromSafeObject, getInsertableDate, isEmptyObject, isFloat, isNotEmptyObject, isValidDate, textTransform} from '../../aux/aux'

import ClassCongratsModal from '../ClassCongratsModal/ClassCongratsModal';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import ClassContentLecture from '../ClassContentLecture/ClassContentLecture';
import ClassContentOverview from '../ClassContentOverview/ClassContentOverview';

// Style
import './classContent.css'
import { round2dec } from '../../aux/purchaseHelpers';
import ResourcePageError from '../PageError/ResourcePageError';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';


const ClassContent = ({ userAccessDetails, contentSummary, userConfig, updateUserConfig, userProgress, updateUserProgress, onFetchUserProgress, contentSelected, contentNavMoves}) => {
    const { t } = useTranslation('classroom', { keyPrefix: 'classContent' })
    const [showCongrats, setShowCongrats] = useState(false);
    const requestAbortControllerRef = useRef(null);
    const updateAbortControllerRef = useRef(null);
    const [requestingDataStatus, setRequestingDataStatus] = useState({
        details:{
            lesson:false, 
            lessonProgress:false, 
            lessonTypeProgress:false,
            lessonActionProgress:false,
            sectionProgress:false
        },
        errorDetails:{}
    });
    const [classContentState, setClassContentState] = useState({
        state:'mounting', // ['mounting', 'requestingData', 'ready',  'error']
        details:{
            isRequestingData:false,
            isClassContentReady:false,
            isError:false
        },
        errorDetails:{
            requestingDataError:false,
            generalError:false
        }
    })
    const [lessonContent, setLessonContent] = useState(null);
    const [userLessonActionProgressMap, setUserLessonActionProgressMap] = useState(null);
    const [userLessonTypeProgress, setUserLessonTypeProgress] = useState(null);
    const [alert, setAlert] = useState({show:undefined, severity:undefined, msg:undefined});


    const clearLesson = () => {
        setLessonContent(null);
        setUserLessonActionProgressMap(null);
        setUserLessonTypeProgress(null);
        setRequestingDataStatus({
            details:{
                lesson:false, 
                lessonProgress:false, 
                lessonTypeProgress:false,
                lessonActionProgress:false,
                sectionProgress:false
            },
            errorDetails:{}
        });
        setClassContentState({
            state:'mounting',
            details:{
                isRequestingData:false,
                isClassrommReady:false,
                isError:false
            },
            errorDetails:{
                requestingDataError:false,
                generalError:false
            }
        });
        setAlert({show:undefined, msg:undefined});
    }

    const requestClassContent = async (userId, productId, contentSelected, localeId, signal) => {
        if(startsNewSection(contentSelected.currentSectionId)){
            requestUserSectionProgress(userId, productId, contentSelected.currentSectionId, {}, signal);
        }
        if(startsNewLesson(contentSelected.currentLessonId)){
            requestUserLessonProgress(userId, productId, contentSelected.currentSectionId, contentSelected.currentLessonId, contentSelected.currentLessonVersionId, signal);   
        }
        requestUserLessonSpecificProgress(userId, productId, contentSelected.currentLessonId, contentSelected.currentLessonVersionId, contentSelected.currentLessonTypeName, signal);
        requestLesson(userId, productId, contentSelected.currentLessonId, localeId, contentSelected.currentLessonVersionId, signal);
    };

    const setOverviewLessonContent = (setLessonContent) => {
        // TODO: implement a best way to display the overview component
        setLessonContent({
            id: contentSelected.currentLessonId,
            sectionId: contentSelected.currentSectionId,
            lessonTypeName: contentSelected.currentLessonTypeName
        })
    };

    const requestLesson = async (userId, productId, lessonId, localeId, lessonVersionId, signal) => {
        try{
            setRequestingDataStatus((fetchingData) => ({...fetchingData, details:{ ...fetchingData.details, lesson:true}}));
            const lessonVersionDecisor = { lessonVersionId };
            const res = await getContentProductLesson(userId, productId, lessonId, localeId, lessonVersionDecisor, 1000, signal);
            const data = res.data;
            if(!isEmptyObject(data.lesson)){
                if(!signal.aborted){
                    setLessonContent(data.lesson);
                }
            }else{
                throw new Error('empty requested lesson')
            }
        }catch(error){
            // wrong credentials or wrong content product are caught on the interceptor
            if(!signal.aborted){
                setRequestingDataStatus(requestingDataStatus => ({ ...requestingDataStatus, errorDetails:{ ...requestingDataStatus.errorDetails, lesson:true}}))
            }
        }finally{
            if(!signal.aborted){
                setRequestingDataStatus((fetchingData) => ({...fetchingData, details:{ ...fetchingData.details, lesson:false}}));
            }
        }
    };


    const requestUserSectionProgress = async (userId, productId, sectionId, system={}, signal) => {
        try{
            setRequestingDataStatus((fetchingData) => ({...fetchingData, details:{ ...fetchingData.details, sectionProgress:true}}));
            let res = await getUserSectionProgress(userId, productId, sectionId, system, {}, 0, signal);
            let data = res.data;
            const noPreviousUserProgress = isEmptyObject(data.userSectionProgress);
            if(noPreviousUserProgress){
                await setUpUserSectionProgress(userId, productId, sectionId, 0, signal);
                res = await getUserSectionProgress(userId, productId, sectionId, system, {}, 0, signal);
                data = res.data;
            }
            if(isNotEmptyObject(data.userSectionProgress)){
                onFetchUserProgress('section', sectionId, data.userSectionProgress);
            }else{
                throw new Error('empty requested section progress');
            }
        }catch(error){
            // wrong credentials or wrong content product are caught on the interceptor
            if(!signal.aborted){
                setRequestingDataStatus((requestingDataStatus) => ({...requestingDataStatus, errorDetails:{ ...requestingDataStatus.errorDetails, sectionProgress: true }}))
            }
        }finally{
            if(!signal.aborted){
                setRequestingDataStatus((fetchingData) => ({...fetchingData, details:{ ...fetchingData.details, sectionProgress:false}}));
            }
        }
    }

    const requestUserLessonProgress = async (userId, productId, sectionId, lessonId, lessonVersionId, signal) => {
        try{
            setRequestingDataStatus((fetchingData) => ({...fetchingData, details:{ ...fetchingData.details, lessonProgress:true}}));
            const lessonVersionDecisor = { lessonVersionId };
            let res = await getUserLessonProgress(userId, productId, sectionId, lessonId, lessonVersionDecisor, {}, 0, signal);
            let data = res.data;
            const noPreviousUserProgress = isEmptyObject(data.userLessonProgress);
            if(noPreviousUserProgress){
                await setUpUserLessonProgress(userId, productId, sectionId, lessonId, lessonVersionId, 0, signal);
                res =  await getUserLessonProgress(userId, productId, sectionId, lessonId, lessonVersionDecisor, {}, 0, signal);
                data = res.data;
            }
            if(isNotEmptyObject(data.userLessonProgress)){
                onFetchUserProgress('lesson', lessonId, data.userLessonProgress);
            }else{
                throw new Error('empty requested lesson progress');
            }
        }catch(error){
            // wrong credentials or wrong content product are caught on the interceptor
            // TODO: implement
            if(!signal.aborted){
                setRequestingDataStatus((requestingDataStatus) => ({...requestingDataStatus, errorDetails:{ ...requestingDataStatus.errorDetails, lessonProgress: true }}))
            }
        }finally{
            if(!signal.aborted){
                setRequestingDataStatus((fetchingData) => ({...fetchingData, details:{ ...fetchingData.details, lessonProgress:false}}));
            }
        }
    }

    const requestUserLessonSpecificProgress = async (userId, productId, lessonId, lessonVersionId, lessonTypeName, signal) => {
        requestUserLessonTypeProgress(userId, productId, lessonId, lessonVersionId, lessonTypeName, signal);
        requestUserLessonActionProgress(userId, productId, lessonId, lessonVersionId, signal);
    }

    const requestUserLessonTypeProgress = async (userId, productId, lessonId, lessonVersionId, lessonTypeName, signal) => {
        try{
            setRequestingDataStatus((fetchingData) => ({...fetchingData, details:{ ...fetchingData.details, lessonTypeProgress:true}}));
            let res = await getUserLessonTypeProgress(userId, productId, lessonId, lessonVersionId, lessonTypeName, 0, signal);
            let data = res.data;
            if(data.lessonTypeKeepsUserProgress){
                const noPreviousUserProgress = Object.values(data.userLessonTypeProgress).reduce((accumulated, current) => (accumulated && isEmptyObject(current)), true)
                if(noPreviousUserProgress){
                    await setUpUserLessonTypeProgress(userId, productId, lessonId, lessonVersionId, lessonTypeName, 0, signal);
                    res = await getUserLessonTypeProgress(userId, productId, lessonId, lessonVersionId, lessonTypeName, 0, signal);
                    data = res.data;
                }
                if(!isEmptyObject(data.userLessonTypeProgress)){
                    if(!signal.aborted){
                        setUserLessonTypeProgress(data.userLessonTypeProgress);
                    }
                }else{
                    throw new Error('empty requested lesson type progress')
                }
            }
        }catch(error){
            // wrong credentials or wrong content product are caught on the interceptor
            if(!signal.aborted){
                setRequestingDataStatus(requestingDataStatus => ({ ...requestingDataStatus, errorDetails:{ ...requestingDataStatus.errorDetails, lessonTypeProgress:true}}))
            }
        }finally{
            if(!signal.aborted){
                setRequestingDataStatus((fetchingData) => ({...fetchingData, details:{ ...fetchingData.details, lessonTypeProgress:false}}));
            }
        }
    }

    const requestUserLessonActionProgress = async (userId, productId, lessonId, lessonVersionId, signal) => {
        try{
            setRequestingDataStatus((fetchingData) => ({...fetchingData, details:{ ...fetchingData.details, lessonActionProgress:true}}));
            let res = await getUserLessonActionProgress(userId, productId, lessonId, lessonVersionId, 0, signal);
            let data = res.data;
            if(data.lessonHasActions){
                const noPreviousUserProgress = isEmptyObject(data.userLessonActionProgressMap);
                if(noPreviousUserProgress){
                    await setUpUserLessonActionProgress(userId, productId, lessonId, lessonVersionId, 0, signal);
                    res = await getUserLessonActionProgress(userId, productId, lessonId, lessonVersionId, 0, signal);
                    data = res.data;
                }
                if(!isEmptyObject(data.userLessonActionProgressMap)){
                    if(!signal.aborted){
                        setUserLessonActionProgressMap(data.userLessonActionProgressMap);
                    }
                }else{
                    throw new Error('empty requested lesson action progress')
                }
            }
        }catch(error){
            // wrong credentials or wrong content product are caught on the interceptor
            if(!signal.aborted){
                setRequestingDataStatus(requestingDataStatus => ({ ...requestingDataStatus, errorDetails:{ ...requestingDataStatus.errorDetails, lessonActionProgress:true}}))
            }
        }finally{
            if(!signal.aborted){
                setRequestingDataStatus((fetchingData) => ({...fetchingData, details:{ ...fetchingData.details, lessonActionProgress:false}}));
            }
        }
    }
    
    const updateUserLessonProgress = (lessonId, lessonVersionId, lessonUpdatedValues, signal=updateAbortControllerRef.current.signal) => {
        updateUserProgress('lesson', lessonUpdatedValues, {lessonId, lessonVersionId, sectionId:lessonContent.sectionId}, signal)
    }

    const updateCurrentLesson = (lessonId, signal=updateAbortControllerRef.current.signal) => {
        const updatedValues = {
            currentLessonId: lessonId !== 0 ? lessonId : null,
            currentSectionId: lessonContent.sectionId !== 0 ? lessonContent.sectionId : null,
            currentLessonVersionId: lessonContent.version != null ? lessonContent.version.lessonVersionId : null
        };
        updateUserProgress('contentProduct', updatedValues, {}, signal);
    }

    const updateUserVideoConfig = (videoId, settingUpdatedValues, signal=updateAbortControllerRef.current.signal) => {
        const requestContent = false;
        const requestUpdatedConfig = true
        updateUserConfig(settingUpdatedValues, requestContent, requestUpdatedConfig, signal);
    }

    const updateUserLessonActionProgress = (actionType, actionId, updatedValues, signal=updateAbortControllerRef.current.signal, userId=userProgress.userId, contentProductId=contentSummary.id) => {
        try{
            if(userLessonActionProgressMap && userAccessDetails.hasAccess){
                // Normalized date format in updated params on the client
                Object.entries(updatedValues).forEach(([key, updatedValue]) => {
                    if(isValidDate(updatedValue)){
                        updatedValues[key] = getInsertableDate(updatedValue);
                    }
                })
                let updatedActionProgress = {
                    ...userLessonActionProgressMap[actionId],
                    ...updatedValues
                }
                const actionIdToUpdate = {
                    lessonId:updatedActionProgress.lessonId,
                    lessonVersionId:updatedActionProgress.lessonVersionId,
                    orderIndex:updatedActionProgress.orderIndex,
                }
                backUpUserLessonActionProgress(userId, contentProductId, actionIdToUpdate, updatedValues);
                if(!signal.aborted){
                    setUserLessonActionProgressMap(userLessonActionProgressMap => {
                        return {
                            ...userLessonActionProgressMap,
                            [actionId]: updatedActionProgress
                        }
                    })
                }
            }
        }catch(error){
            // TODO: Only synchronous errors. 
            // BackUp exceptions are handled in each function
        }
    }

    const updateUserLessonTypeProgress = (lessonTypeName, lessonTypeId, updatedValues, backup=true, signal=updateAbortControllerRef.current.signal, userId=userProgress.userId, contentProductId=contentSummary.id) => {
        try{
            if(userLessonTypeProgress && userAccessDetails.hasAccess){
                // Normalized date format in updated params on the client
                Object.entries(updatedValues).forEach(([key, updatedValue]) => {
                    if(isValidDate(updatedValue)){
                        updatedValues[key] = getInsertableDate(updatedValue);
                    }
                    if(isFloat(updatedValue)){
                        updatedValues[key] =  round2dec(updatedValue);
                    }
                })
                //
                const updatedElementMap = `${lessonTypeName}ProgressDetailsMap`;
                const updatedLessontypeProgress = {
                    ...userLessonTypeProgress[updatedElementMap][lessonTypeId],
                    ...updatedValues
                }
                const lessonIdsToUpdate = {
                    lessonId: updatedLessontypeProgress.lessonId,
                    lessonVersionId: updatedLessontypeProgress.lessonVersionId,
                    orderIndex: updatedLessontypeProgress.orderIndex,
                }
                if(backup){
                    backUpUserLessonTypeProgress(userId, contentProductId, lessonTypeName, lessonIdsToUpdate, updatedValues);
                }
                if(!signal.aborted){
                    setUserLessonTypeProgress(userLessonTypeProgress => {
                        return {
                            ...userLessonTypeProgress,
                            [updatedElementMap]: {
                                ...userLessonTypeProgress[updatedElementMap],
                                [lessonTypeId]: updatedLessontypeProgress
                            }
                        }
                    })
                }
            }
        }catch(error){
            // TODO: Only synchronous errors. 
            // BackUp exceptions are handled in each function
        }
    }

    const startsNewSection = (sectionId) => {
        return userProgress.sectionProgressMap[sectionId] == null
    };

    const startsNewLesson = (lessonId) => {
        return userProgress.lessonProgressMap[lessonId] == null
    };

    useEffect(() => {
        const abortController = new AbortController();
        requestAbortControllerRef.current = abortController;
        if(contentSelected.currentLessonTypeName != 'overview'){
            const signal = abortController.signal;
            requestClassContent(userProgress.userId, contentSummary.id, contentSelected, userConfig.localeId, signal)
        }else{
            setOverviewLessonContent(setLessonContent)
        }
        return(() => {
            abortController.abort();
            clearLesson()
            // console.log('unmounting lesson');
        })
    }, [contentSelected, userConfig.localeId])

    // const getMeetingIdToUpdateInUserLessonLiveProgress = (liveProgressDetailsMap, liveDetailsMap) => {
    //     const userLessonLiveProgressWithoutMeeting = {};
    //     if(liveProgressDetailsMap && liveDetailsMap){
    //         Object.entries(liveDetailsMap).forEach(([liveContentId, liveContent]) => {
    //             let liveContentDetails = liveContent.description;
    //             let userLiveContentProgress = liveProgressDetailsMap[liveContentId];
    //             if(liveContentDetails.meetingId !== null && userLiveContentProgress.userMeetingId === null){
    //                 userLessonLiveProgressWithoutMeeting[meetingId] = liveContentId;
    //             }
    //         })
    //     }
    //     return userLessonLiveProgressWithoutMeeting;
    // }

    // const checkAndUpdateUserMeetingInUserLessonLiveProgress = async( meetingIdToCheck ) => {
    //     try{
    //         const meetingIdArray = Object.keys(meetingIdToCheck);
    //         const res = await getUserMeetingFromMeetingIdArray(userAccessDetails.userId, meetingIdToCheck);
    //         let {
    //             userMeetingMap,
    //             notFound,
    //         } = res.data;
    //         if(!isEmptyObject(notFound)){
    //             const userMeetingToCreate = 
    //             const res = await createUserMeeting();
    //             userMeetingMap = { ...userMeetingMap, ...res.data.userMeetingMap} 
    //         };
    //         for(let userMeeting in userMeetingMap){
    //             let lessonTypeId = meetingIdToCheck[userMeeting.meetingId];
    //             await updateUserLessonTypeProgress(lessonContent.lessonTypeName, lessonTypeId, { userMeetingId: userMeeting.id });
    //         };  
    //     }catch(error){
    //         // TODO: implement
    //     }
    // }

    // useEffect(() => {
    //     if(userLessonTypeProgress !== null && lessonContent !== null){
    //         if(userLessonTypeProgress.hasOwnProperty('liveProgressDetailsMap') && lessonContent.lessonTypeName === 'live'){
    //             // Live lesson
    //             const meetingIdToUpdateInUserLessonLiveProgress = getMeetingIdToUpdateInUserLessonLiveProgress(userLessonTypeProgress.liveProgressDetailsMap , lessonContent.liveDetailsMap);
    //             checkAndUpdateUserMeetingInUserLessonLiveProgress(meetingIdToUpdateInUserLessonLiveProgress);
    //         }
    //     }
    // }, [userLessonTypeProgress, lessonContent])

    useEffect(() => {
        if(lessonContent){
            const abortController = new AbortController();
            updateAbortControllerRef.current = abortController;
            const signal = abortController.signal;
            if(lessonContent.lessonTypeName != 'overview'){
                // console.log('updating lastAccess')
                updateUserLessonProgress(contentSelected.currentLessonId, contentSelected.currentLessonVersionId, {lastAccessDate: new Date()}, signal)
            }else{
                updateCurrentLesson(contentSelected.currentLessonId, signal);
            }
            return(() => { abortController.abort() })
        }
    }, [lessonContent])

    useEffect(() => {
        const isRequestingData = Object.values(requestingDataStatus.details).filter( prop => prop == true ).length >= 1;
        let isClassContentReady = false;
        let newState;
        if(isRequestingData){
            // set classContent state: requestingData
            newState = 'requestingData';
        }else{
            if(lessonContent){
                // set classContent state: ready
                newState = 'ready';
                isClassContentReady = true;

            }
        }
        setClassContentState(classContentState => ({ ...classContentState, details:{ ...classContentState.details, isRequestingData, isClassContentReady }, state: classContentState.state !== 'error' ? newState || classContentState.state : classContentState.state }));
    },[requestingDataStatus.details, lessonContent])

    useEffect(() => {
        const isRequestingError = Object.values(requestingDataStatus.errorDetails).filter( prop => prop == true).length >= 1;
        let newState;
        if(isRequestingError){
            if(requestingDataStatus.errorDetails.lesson){
                // set classContent state: error
                newState = 'error';
            }else{
                if(userAccessDetails.hasAccess){
                    // No update or action is allowed in the class content
                    // progress-related request error exists
                    // TODO: show low priority message to the user
                }else{
                    // guest cannot perform any update
                    // notification is not needed
                }
                if(alert.show !== false){
                    setAlert(prev => ({show:true, severity:'warning', msg: `${textTransform('title', t('limitedFunctionality'))}. ${textTransform('title', t('yourProgressCouldNotBeRetrievedOrUpdated'))}`}))
                }
            }
        }
        setClassContentState(classContentState => ({ ...classContentState, state: newState || classContentState.state, details:{ ...classContentState.details, isError: newState === 'error' }, errorDetails:{ ...classContentState.errorDetails, requestingDataError:isRequestingError}}));
    }, [requestingDataStatus.errorDetails]);

    const getContentComponent = (contentType, lessonId) => {
        let contentTypeComponent = null;
        switch(contentType){
            case "overview":
                contentTypeComponent =  <ClassContentOverview contentSummary={contentSummary} userProgress={userProgress} />
                break;
            case "test":
                // TODO: implement
                break;
            default:
                // Regular lesson
                let lessonVersionId = lessonContent.version.lessonVersionId;
                const userLessonProgress = getFromSafeObject(userProgress.lessonProgressMap, `[${lessonId}].[${lessonVersionId}]`);
                if(userLessonProgress != null){
                    contentTypeComponent = <ClassContentLecture userId={userProgress.userId} lessonContent={lessonContent} 
                    userLessonProgress={userLessonProgress} userLessonTypeProgress={userLessonTypeProgress} userLessonActionProgressMap={userLessonActionProgressMap}
                    updateUserLessonProgressMap={{updateUserLessonProgress, updateUserLessonTypeProgress, updateUserLessonActionProgress}} userConfig={userConfig} updateUserVideoConfig={updateUserVideoConfig} setShowCongrats={setShowCongrats}/>
                }
                break;
        }
        return contentTypeComponent

    }
    
    const getComponentToRender = () => {
        let ComponentToRender;
        switch(classContentState.state){
            case 'mounting':
            case 'requestingData':
                ComponentToRender = <LoadingComponent visibleElements='circle'/>
                break;
            case 'ready':
                if(lessonContent){
                    ComponentToRender =
                    <>
                        <ClassCongratsModal showModal={showCongrats} setShowModal={setShowCongrats} contentNavMoves={contentNavMoves} title={lessonContent.hasOwnProperty('description') ? lessonContent.description.title : ''} />
                        {alert.show ?
                            <Alert 
                                className='class-content-alert'
                                severity={alert.severity} 
                                onClose={()=>setAlert(false)} 
                            >
                                <Typography variant='body2'>
                                    {alert.msg}
                                </Typography>
                            </Alert>
                            :
                            null
                        }
                        {getContentComponent(lessonContent.lessonTypeName, lessonContent.id)}
                    </>
                    break;
                }
            case 'error':
            default:
                ComponentToRender = <ResourcePageError errorDetails={classContentState.errorDetails} resourceType={'content'} />
        }
        return ComponentToRender;
    }

    return (
        <>
            <div className='class-content-main'>
                {getComponentToRender()}
            </div>
        </>
    )
}

export default ClassContent
