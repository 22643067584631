import PopUpModal from '../PopUpModal/PopUpModal'
// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import successAnimation from '../../lotties/success.json';
import errorAnimation from '../../lotties/error.json';
import './popUpSimpleFeedback.css'
import { useEffect, useRef, useState } from 'react';

const PopUpSimpleFeedback = ({ show, onClickClose, animationType, children, ...props}) => {
    const POP_UP_WIDTH = 380
    const [animationContainer, setAnimationContainer] = useState(null);
    const animationRef = useRef(null);

    useEffect(() => {
        if(animationContainer != null){
            animationRef.current = lottie.loadAnimation({
                container: animationContainer,
                renderer: 'svg',
                loop: false,
                autoplay: true,
                animationData: animationType === 'success' ? successAnimation : errorAnimation
            });
            return(() => {
                animationRef.current?.stop();
                animationRef.current?.destroy()
            })
        }
    },[animationContainer, animationType])

    return (
        <PopUpModal showModal={show} onClickClose={onClickClose} onOutsideClick={onClickClose} width={POP_UP_WIDTH} {...props} >
            <div className='pop-up-simple-feedback-container'>
                <div className='pop-up-simple-feedback-animation' ref={setAnimationContainer}/>
                <div className='pop-up-simple-feedback-msg'>
                    {children}
                </div>
            </div>
        </PopUpModal>
    )
}

export default PopUpSimpleFeedback