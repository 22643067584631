import { Cloudinary, URLConfig,  } from '@cloudinary/url-gen';
import { enc } from 'crypto-js';
import { sha1Signature } from '../../aux/cryptoHelpers';
import { publicThirdPartyRequest, createAuthRequest, publicRequest } from '../../aux/requestMethods';
// Import required actions and qualifiers.

const API_CLOUDINARY_PATH = '/cloudinary'; // server-side entry point
const UPLOAD_ENDPOINT = 'https://api.cloudinary.com/v1_1';
const cld = new Cloudinary({
    cloud:{
        cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
        apiSecret: process.env.REACT_APP_CLOUDINARY_API_SECRET,
    },

});


function sign(payload, encoder=enc.Base64url){
    return sha1Signature(`${payload}${process.env.REACT_APP_CLOUDINARY_API_SECRET}`, encoder);
}
function _transformationToString(transformation){
    const result = Object.entries(transformation).reduce((accum, [key, value]) => {
        let separator = '';
        if(accum){
            separator += ','
        }
        let property = '';
        if(key === 'quality'){
            property += 'q'
        }
        if(key === 'crop'){
            property += 'c'
        }
        if(key === 'width'){
            property += 'w'
        }
        if(key === 'height'){
            property += 'h'
        }
        return accum + `${separator}${property}_${value}`
    },'')
    return result;
}

function getAssetUrl(id, transformation={}){
    const tranformationString = _transformationToString(transformation)
    const toSign = tranformationString ? [tranformationString, id].join("/") : id;
    const signature = sign(toSign);
    const slicedSignature = signature.slice(0,8);
    const img = cld.image(id).setSignature(slicedSignature).addTransformation(tranformationString);
    return img.toURL();
}
// TODO: implement fallback as a default img
async function get(id, transformation={}, fallbackId=''){
    try{
        const url = getAssetUrl(id, transformation);
        let objectURL = undefined;
        if(url){
            const res = await fetch(url);
            const imgBlob = await res.blob();
            objectURL = URL.createObjectURL(imgBlob);
        }
        return objectURL;
    }catch(err){
        throw err
    }
}
// Client-side is not supported
/**
 * 
 * @param {array} by [ condition:[string],]
 *  condition: A comparisonOperator B [AND|OR]
 *  comparisonOperator: :, =, >, <
 * @param {*} resourceType 
 * @param {*} maxResult 
 * @param {*} signal 
 * @returns 
 */
async function getResourcesTypeArray(conditionArray, resourceType='image', maxResult, signal=null){
    const formatExpression = (conditionArray, resourceType) => {
        const END_WITH_LOGIC_CONDITION_RE = /\s(AND|OR)(\s)?$/;
        const COMPARISON_OPERATOR_RE = /(=|:|>|<)/;
        let expression = conditionArray.reduce((conditionExpression, condition ) => {
            condition = condition.trim();
            const matchCompOperator = COMPARISON_OPERATOR_RE.exec(condition);
            let conditionString = '';
            if(matchCompOperator){
                const compOperator = matchCompOperator[0].trim();
                const matchNextLogic = END_WITH_LOGIC_CONDITION_RE.exec(condition);
                const nextLogicStartIndex = matchNextLogic ? matchNextLogic.index : condition.length;
                const nextLogic = condition.substring(nextLogicStartIndex).trim();
                const field = condition.substring(0,matchCompOperator.index);
                const value = condition.substring(matchCompOperator.index + compOperator.length, nextLogicStartIndex);
                conditionString += `${field}${compOperator}${value}${nextLogic ? ` ${nextLogic}` : ''}`
            }
            return conditionExpression ? `${conditionExpression} ${conditionString}` : conditionString
        },'')
        expression = resourceType ? `resource_type:${resourceType}${expression ?  ` AND ${expression}` : ''}` : expression;
        return expression
    }
    try{
        const expression = formatExpression(conditionArray, resourceType);
        const params = { expression, maxResult };
        return publicRequest.get(`${API_CLOUDINARY_PATH}/get`, { params, signal });
    }catch(err){
        throw err
    }
}

async function getAllImageIdInFolder(folder, maxResult=10, exp={}, signal=null){
    try{
        const {data} = await getResourcesTypeArray([`folder=${folder}`], 'image', maxResult, signal);
        const resourceArray = data.resources;
        return resourceArray.map((resource, index) => {
            return {
                imgId: resource['public_id'],
                fileName: resource['filename'],
                ...(exp.tags ? {tags: resource.tags} : {}),
                ...(exp.context ? {context: resource.context} : {}),
            }
        })
    }catch(err){
        throw err
    }
}
/**
 *  Cloudinary Reference https://cloudinary.com/documentation/image_upload_api_reference#upload
 * @param {*} imgType 
 * @returns 
 */
function getUploadImgParams(imgType, additionalParams={}){
    let result;
    let path;
    switch(imgType){
        case 'profile':
            path = process.env.REACT_APP_TARGET_ENV === 'production' ? 'user' : process.env.REACT_APP_TARGET_ENV === 'staging' ? 'user_dev' : 'user_local' 
            result = {
                folder: `qxi/${path}`,
                access_mode: 'authenticated',
                invalidate: true,
                overwrite: true,
                tags: [imgType],
            }
            break;
        default:
            throw new Error(`unknown upload img type: ${imgType}`)
    }
    Object.assign(result, additionalParams);
    return result
}
function getUploadSignature(payload, encoder=enc.Hex){
    let sortedPayloadString = Object.keys(payload).sort().reduce((accumulated, key) => { 
        if(accumulated){
            accumulated += '&';
        }
        return accumulated += `${key}=${payload[key]}`;
    }, '');
    return sign(sortedPayloadString, encoder);
}
async function upload(file, params, signal=null){
    try{
        const apiEndpoint = `${UPLOAD_ENDPOINT}/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`;
        const timestamp = new Date().getTime();
        let data = {
            timestamp,
            ...params
        }
        const signature = getUploadSignature(data);
        data = {
            ...data,
            signature,
            file,
            ...params,
            api_key: process.env.REACT_APP_CLOUDINARY_API_KEY,
        }
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => (formData.append(key, value)));
        return publicThirdPartyRequest.post(apiEndpoint, formData, { signal });
    }catch(err){
        throw err
    }
}
// Client-side is not supported
async function remove(id, signal=null){
    try{
        const params = {
            id
        };
        const userRequest = createAuthRequest();
        return userRequest.delete(`${API_CLOUDINARY_PATH}/remove`, { params, signal })
    }catch(err){
        throw err
    }
}


const cloudinary = {
    getAssetUrl,
    get,   
    getAllImageIdInFolder,
    getUploadImgParams,
    upload,
    remove
};

export default cloudinary ;