import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/styles';
import { Grid, Typography, IconButton, TextField, InputAdornment, Checkbox } from '@material-ui/core'
// import { Checkbox, FormControlLabel } from '@material-ui/core';
import { loginUser } from '../../services/sessionServices';
import ToggleMsg from '../ToggleMsg/ToggleMsg'
import ButtonLoading from '../ButtonLoading/ButtonLoading';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { isNotEmptyObject, textTransform } from '../../aux/aux';
import { triggerEvent } from '../../aux/internalCommunicationHelpers';
import { getCustomErrorOrUndefined } from '../../aux/errorHelpers';

// Style
// import Checkbox from '../../img/checkBox.svg';
// import Checkedbox from '../../img/checkedBox.svg';
import './logInForm.css'

const LogInForm = ({ onLogin=async()=>{}, onRender=()=>{}, isLoading=()=>{}, formValues={}, autoLogin=false, onNoVerifiedAccount=()=>{} }) => {
    const logInStatusTypes = ['idle', 'loading'];
    const errorTypes = ['password', 'email', 'noVerifyAccount'];
    const { t } = useTranslation('common', { keyPrefix: 'logInForm' });
    const [unknownError, setUnknownError] = useState(false);
    const [logInState, setLogInState] = useState({
        email: '',
        password: '',
        showPassword: false,
        status: logInStatusTypes[0],
        errorType: null
    })
    const [rememberSession, setRememberSession] = useState(true);
    const [submitButton, submitButtonRef] = useState(null);
    const abortControllerRef = useRef(null);

    const handleCheckbox = (e) => {
        setRememberSession(!rememberSession);
    };

    const onChangeTextField = (e) => {
        const field = e.target.name;
        const value = e.target.value;
        setLogInState(prev => ({ 
            ...prev ,
            [field]: value, 
            errorType: prev.errorType === field ? null : prev.errorType
        }))
    };
 
    const loginProcess = async (e, signal=abortControllerRef.current.signal) => {
        try{
            e.preventDefault();
            setLogInState({...logInState, status:logInStatusTypes[1]});
            const {
                email,
                password
            } = logInState;
            const userData = await loginUser({ email, password }, rememberSession, signal);
            if(!signal.aborted){
                setLogInState(prev => ({...prev, status:logInStatusTypes[0]}));
                // resetState();
                await onLogin(userData);
            }
        }catch(error){
            console.log('catching error')
            const customError = getCustomErrorOrUndefined(error);
            let type = null;
            if(customError){
                switch(customError.cause){
                    case 'sr_008':
                        type= errorTypes[2];
                        break;
                    case 'sr_001':
                        // unknown user
                        type = errorTypes[1]
                        break;
                    case 'sr_002':
                        // wrong password
                        type = errorTypes[0]
                        break;
                    default:
                        // unknown error
                }
            }
            if(!signal.aborted){
                setLogInState(prev => ({...prev, status: logInStatusTypes[0], errorType: type}));
                if(type === null){
                    setUnknownError(true);
                }
            }
        }
    };

    useEffect(() => {
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        return(() => {
            abortController.abort()
        })
    },[])

    useEffect(() => {
        if(isNotEmptyObject(formValues)){
            setLogInState(prev => ({ 
                ...prev,
                email: formValues.email ? formValues.email : prev.email,
                password: formValues.password ? formValues.password : prev.password
            }))
        }
    },[formValues])

    useEffect(() => {
        if(autoLogin === true){
            if(submitButton != null && logInState.email != '' && logInState.password != '' ){
                triggerEvent('submit', {bubbles:true, cancelable:true}, submitButton);
            }
        }
    },[autoLogin, submitButton, logInState.email, logInState.password]);

    useEffect(() => {
        isLoading(logInState.status === logInStatusTypes[1]);
    },[logInState.status])

    useEffect(() => {
        if(logInState.errorType === errorTypes[2]){
            onNoVerifiedAccount(logInState.email, logInState.password)
        }
    },[logInState.errorType])

    useEffect(() => {
        onRender()
    })

    return (
        <form ref={submitButtonRef} onSubmit={ loginProcess }>
            <Grid container direction="column" alignItems='center'  style={{width:'100%'}} >
                <Grid className="login-form-menu-item" item style={{width:'inherit'}}>
                    <TextField 
                        disabled={logInState.status === logInStatusTypes[1]}
                        required 
                        type="email" 
                        name='email' 
                        value={logInState.email} 
                        onChange={onChangeTextField}
                        label={textTransform('title', t("email"))} 
                        error={logInState.errorType === errorTypes[1]}
                        helperText={ logInState.errorType === errorTypes[1] ? textTransform('title', t("wrongUser")) : undefined}
                        color="primary" 
                        fullWidth 
                    />
                </Grid>
                <Grid className="login-form-menu-item" item style={{width:'inherit'}}>
                    <TextField 
                        disabled={logInState.status === logInStatusTypes[1]}
                        required 
                        type={logInState.showPassword ? "text":"password"} 
                        name="password" 
                        value={logInState.password} 
                        onChange={onChangeTextField}
                        label={textTransform('title', t("password"))} 
                        error={logInState.errorType === errorTypes[0]}
                        helperText={ logInState.errorType === errorTypes[0] ? textTransform('title', t("wrongPassword")) : undefined}
                        color="primary" 
                        InputProps={{
                            endAdornment:(
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={(e) => setLogInState({...logInState, showPassword: !logInState.showPassword})}
                                    size="small"
                                    edge="end"
                                    >
                                        {logInState.showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }} 
                        fullWidth/>
                </Grid>
                <Grid item className="login-form-menu-item">
                    <Grid container alignItems='center'>
                        <Grid item className='login-form-checkbox'>
                            <Checkbox 
                                disabled={logInState.status === logInStatusTypes[1]}
                                size='small' 
                                color='primary' 
                                onChange={handleCheckbox} 
                                checked={rememberSession} 
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">
                                { textTransform('title', t("rememberSession")) }
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className="login-form-menu-button" >
                    <ButtonLoading 
                        isLoading={logInState.status === logInStatusTypes[1]} 
                        label={t("logIn")} 
                        type="submit" 
                        variant="outlined" 
                        color="primary"
                    />
                </Grid>
                <Grid item style={{width:'100%'}}>
                    <ToggleMsg
                        className={`login-form-unknwon-error-container ${unknownError ? 'show': ''} `}
                        isShown={unknownError} 
                        onShowTime={() => setUnknownError(false)}
                        msg={
                            `${textTransform('title', t("errorLogIn"))}.\n${textTransform('title', t("tryItLater"))}`
                        } 
                    />
                </Grid>
            </Grid>
        </form>
    )
}

export default LogInForm
