import { useTranslation } from 'react-i18next'
import { fromCentTo2dec } from '../../aux/purchaseHelpers'
import BasicProductConsumptionOptionItem from '../BasicProductConsumptionOptionItem/BasicProductConsumptionOptionItem'
import { Typography } from '@material-ui/core'
import { getFromSafeObject, textTransform } from '../../aux/aux'
import { useEffect, useState } from 'react'
import './contentConsumptionOptionItem.css'


const ContentConsumptionOptionItem = ({ index, productConsumptionOption, userProductPurchasabilityDetails, isSelected, isBestPrice, isRecommended, isUnique, onSelected, stylesMap }) => {
    const { t } = useTranslation('common');
    const [disableMessage, setDisableMessage] = useState('');
    const productOptionAmount = getFromSafeObject(productConsumptionOption, 'consumptionDetails.details.purchasabilityDetails.details.amount') || {};
    useEffect(() => {
        let disableMessage = ''
        if(userProductPurchasabilityDetails && !userProductPurchasabilityDetails.isPurchasable){
            const noPurchasabilityCause = getFromSafeObject(userProductPurchasabilityDetails, 'details.noPurchasabilityType');
            const noPurchasabilityCauseTxt = t('contentConsumptionOptionItem.noPurchasabilityCause', {returnObjects:true})
            switch(noPurchasabilityCause){
                case 'isOwner':
                    disableMessage = textTransform('title', noPurchasabilityCauseTxt.youAlreadyOwnThisContent);
                    break;
                case 'maxQuantity':
                    disableMessage = textTransform('title', noPurchasabilityCauseTxt.maxQuantity);
                    break;
                default:
                    disableMessage = textTransform('title', t('cannotPurchaseThis'));
            }
        }
        setDisableMessage(disableMessage)
    },[userProductPurchasabilityDetails])
    return (
        productOptionAmount.finalAmount != null ?
            <BasicProductConsumptionOptionItem 
                productConsumptionOption={productConsumptionOption}
                disabled={!(userProductPurchasabilityDetails && userProductPurchasabilityDetails.isPurchasable)}
                isSelected={isSelected}
                isBestPrice={isBestPrice}
                isRecommended={isRecommended}
                isUnique={isUnique}
                onSelected={onSelected}
                stylesMap={stylesMap}
                warningMessage={disableMessage}
                ConfigSelectorComponent={
                    <div className='content-consumption-option-item-config-container'>
                        <Typography variant="body2" >
                                {t('{{val, currency}}',{ val:fromCentTo2dec(productOptionAmount.finalAmount), currency: productOptionAmount.currencyId, trailingZeroDisplay:'stripIfInteger'})}
                        </Typography>
                    </div>
                }
            />
            :
            null
    )
}

export default ContentConsumptionOptionItem