import { Grid, Typography, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import NextIcon from "../../img/next-icon.svg";
import CheckoutItemQuantityPopUp from '../CheckoutItemQuantityPopUp/CheckoutItemQuantityPopUp';
import './checkoutItemQuantity.css'
import { useTheme } from '@material-ui/styles';
import { getCustomErrorOrUndefined } from '../../aux/errorHelpers';


const CheckoutItemQuantity = ({ product, isCheckoutUpdating, deleteOrUpdateItemQuantity }) => {
    const theme = useTheme();
    const { t } = useTranslation('checkout');
    const [showPopUp, setShowPopUp] = useState(false);

    const updateQuantity = async (quantity) => {
        try{
            await deleteOrUpdateItemQuantity(product, quantity);
        }catch(error){
            // Passing it to the caller function
            throw error;
        }
    }

    return (
        <>            
            {showPopUp &&
                <CheckoutItemQuantityPopUp showPopUp={showPopUp} setShowPopUp={setShowPopUp} product={product} callBack={updateQuantity} isLoading={isCheckoutUpdating} />
            }
            <Grid className='checkout-item-quantity-main-container' container direction='row'>
                <Grid item className="checkout-item-quantity-label">
                    <Typography variant="body2" style={{opacity:"50%"}}>
                        {t('unitAbbr', { count: product.quantity })}
                    </Typography>
                </Grid>
                <Grid item>
                    <div className={`checkout-item-quantity-button ${isCheckoutUpdating ? 'loading' : ''}`}  onClick={ e => {setShowPopUp(true)}} 
                    >
                        <Typography variant="body2">
                            {product.quantity}
                        </Typography>
                        <NextIcon height='0.8em' transform='rotate(90)' />
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default CheckoutItemQuantity