import { useEffect, useState, useRef } from "react"
import { onEventListener, offEventListener } from "../aux/internalCommunicationHelpers";

export const useDetectDrag = ( preventClickAfterDrag ) => {
    const [isDragged, setIsDragged] = useState(false);
    const [dragRef, setDragRef] = useState(null);
    
    useEffect(() => {
        if(dragRef){
            let dragTarget;
            let dragging;
            const captureClick = (e) => {
                e.stopPropagation();
                offEventListener('click', captureClick, dragRef);
            }
            const dragMove = (e) => {
                if(dragTarget){
                    e.stopPropagation();
                    setIsDragged(true);
                    dragging = true;
                }
            };
            const dragStop = (e) => {
                if(dragging){
                    e.stopPropagation();
                    if(preventClickAfterDrag){
                        onEventListener('click', captureClick, dragRef);
                    }
                }
                offEventListener('pointerup', dragStop, dragRef);
                offEventListener('pointermove', dragMove, dragRef);
                dragTarget = null;
                setIsDragged(false);
                dragging = false;
            };
            const dragStart = (e) => {
                dragTarget = e.currentTarget;
                onEventListener('pointerup', dragStop, dragRef);
                onEventListener('pointermove', dragMove, dragRef);
            }
            onEventListener('pointerdown', dragStart, dragRef);
            return(() => {
                offEventListener('pointerdown', dragStart, dragRef)
            })
        }
    },[dragRef])

    return [isDragged, setDragRef]
}