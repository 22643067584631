import axios from 'axios';

import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { Box, IconButton, useMediaQuery } from '@material-ui/core';
import { Menu } from '@mui/material';


import ImgDN from "../../cdn/imgDN";
import cloudinary from "../../cdn/providers/cloudinary";

import SchoolIcon from "../../img/school-icon.svg";
import LogoutIcon from "../../img/logo-out-icon.svg";
import AccountIcon from "../../img/account-icon.svg";

import LoadingComponent from '../LoadingComponent/LoadingComponent';
import { justLogoutUser, logoutUser, logoutUserOnClient } from '../../services/sessionServices';

import './popUpUserMenu.css'
import UserMenuItemList from '../UserMenuItemList/UserMenuItemList';
import { isUserLogged } from '../../aux/sessionHelpers';
import { getFromSafeObject } from '../../aux/aux';
import UserProfileBadge from '../UserProfileBadge/UserProfileBadge';
import { useUpdateCountryAndCurrencyCtx } from '../../customHooks/useUpdateCountryAndCurrencyCtx';

const PopUpUserMenu = ({ user, setUser }) => {
    const history = useHistory();
    const avatarHeight = 30
    const menuIconHeight = 25;
    const matchDesktop = useMediaQuery('(min-width:1120px)')
    const appSubdomain = process.env.REACT_APP_CLASS_SUBDOMAIN;
    const assetProvider = new ImgDN(cloudinary);
    const updateCountryAndCurrencyCtx = useUpdateCountryAndCurrencyCtx();
    const [isLoading, setIsLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const abortControllerRef = useRef(null);
    const accountMenuOpen = Boolean(anchorEl);
    const styles = {
        button:{
            borderRadius: '5px'
        },
        avatarIcon: {
            display: 'block',
            height: avatarHeight
        },
        menuIcon:{ 
            display: 'block',
            height: menuIconHeight
        }
    }

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const logoutProcess = async (e, signal=abortControllerRef.current.signal) => {
        try{
            e.preventDefault();
            setIsLoading(true)
            await logoutUser({ userId: user.basicData.id }, 1000, signal);
        }catch(err){
            if(!signal.aborted){
                logoutUserOnClient(signal);
            }
        }finally{
            if(!signal.aborted){
                justLogoutUser(setUser);
                setIsLoading(false)
                updateCountryAndCurrencyCtx.resetCountryAndCurrencyToSessionValues();
            }
            if(history.location.pathname.split('/')[1] === appSubdomain){
                history.push('/')
            }
        }
    }
    
    useEffect(() => {
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        return(() => {
            abortController.abort()
        })
    },[])

    return(
        <>
            <IconButton size="small"
                onClick={handleClick}
                disabled={isLoading}
                style={styles.button}
            >
                <UserProfileBadge user={user} isLoading={isLoading} isBadgeExpanded={matchDesktop} />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                id= "account-menu"
                open={accountMenuOpen}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    className:"user-menu-popover"
                }}
                transformOrigin={{ vertical: 'top', horizontal: 'right'}}
                anchorOrigin={{ vertical:'bottom', horizontal:'right'}}
            >
                <UserMenuItemList user={user} onLogout={logoutProcess} />
            </Menu>
        </>
    )
}

export default PopUpUserMenu
