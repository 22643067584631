import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography } from '@material-ui/core';
import { Grid, Button, Divider } from '@material-ui/core';
// Components
import PopUpModal from '../PopUpModal/PopUpModal'
import PopUpModalHeader from '../PopUpModalHeader/PopUpModalHeader';
import ModifyContentUserSystemNoModalDialog from '../ModifyContentUserSystemNoModalDialog/ModifyContentUserSystemNoModalDialog';
import ClassContentUserSystemSelect from '../ClassContentUserSystemSelect/ClassContentUserSystemSelect';
// Helper functions
import { getFromSafeObject, isNotEmptyObject, textTransform } from '../../aux/aux';
import { getSystemFromUserConfig } from '../../aux/userHelpers';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CableIcon from '@mui/icons-material/Cable';
import './popUpClassContentUserSystemSelect.css'
import CancelOpenLiveLessonRegistrationNoModalDialog from '../CancelOpenLiveLessonRegistrationNoModalDialog/CancelOpenLiveLessonRegistrationNoModalDialog';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import { getUserMeetingCollectionByProduct } from '../../services/meetingServices';
import { getReliableLocale } from '../../aux/sessionHelpers';

const PopUpClassContentUserSystemSelect = ({ userConfig, contentSummary, showModal, onClickClose, onUpdate, isDialog, title='' }) => {
    const { t } = useTranslation('common', { keyPrefix: "popUpClassContentUserSystemSelect" });
    const [innerContainerHeight, setInnerContainerHeight] = useState(null)
    const [isRequesting, setIsRequesting] = useState(false);
    const [showWarmingDialog, setShowWarmingDialog] = useState(userConfig.selectedDeviceName != null || userConfig.selectedSoftwareName != null);
    const [noRegisteredLiveLesson, setNoRegisteredLiveLesson] = useState(null);
    const [isErrorCancellingRegisteredEvents, setIsErrorCancellingRegisteredEvents] = useState(false);
    const innerContainerRef = useRef(null);
    const handleCloseModal = () => {
        onClickClose()
    }

    const handleOnAgreeWarningDialog = () => {
        setShowWarmingDialog(false);
    }

    const handleOnNoRegisteredLiveLesson = () => {
        setNoRegisteredLiveLesson(true);
    }

    const handleOnErrorCancellingRegisteredLiveLesson = (errorType) => {
        setIsErrorCancellingRegisteredEvents(true);
    }

    const updateInnerContainerHeight = () => {
        if(innerContainerRef.current){
            setInnerContainerHeight(innerContainerRef.current.offsetHeight)
        }
    }
    
    useEffect(() => {
        updateInnerContainerHeight()
    });


    return (
        <PopUpModal showModal={showModal} onClickClose={handleCloseModal} width={"380px"} height={innerContainerHeight} isDialog={isDialog} >
            <Grid ref={innerContainerRef} container direction='column' className='pop-up-class-content-user-system-select-main'>
                {showWarmingDialog === true ?
                    <>
                        <Grid item>
                            <PopUpModalHeader title={textTransform('title', t('modifyContentUserSystemDialogTitle')) } Icon={WarningAmberIcon}/> 
                        </Grid>
                        <Grid item className='pop-up-class-content-user-system-select-container'>
                            <ModifyContentUserSystemNoModalDialog system={getSystemFromUserConfig(userConfig)} onAgree={handleOnAgreeWarningDialog} onDisagree={handleCloseModal}/>
                        </Grid>
                    </>
                :
                    noRegisteredLiveLesson !== true ?
                        <>  
                            <Grid item>
                                <PopUpModalHeader 
                                    title={textTransform('title', t('cancellingRegisteredEvents')) } 
                                /> 
                            </Grid>
                            <Grid item className='pop-up-class-content-user-system-select-container'>
                                <CancelOpenLiveLessonRegistrationNoModalDialog 
                                    userConfig={userConfig} 
                                    onRender={updateInnerContainerHeight} 
                                    onNoRegisteredUserMeeting={handleOnNoRegisteredLiveLesson} 
                                    onFinish={handleCloseModal}
                                    onError={handleOnErrorCancellingRegisteredLiveLesson}
                                />
                            </Grid>
                        </>
                    :
                        <>
                            <Grid item >
                                <PopUpModalHeader title={title || t('personalizeYourContent')} Icon={CableIcon} />
                            </Grid>
                            <Divider />
                            <Grid item className='pop-up-class-content-user-system-select-container'>
                                <ClassContentUserSystemSelect userConfig={userConfig} contentSummary={contentSummary} onUpdate={onUpdate} onFinish={handleCloseModal} onRender={updateInnerContainerHeight}/>
                            </Grid>
                        </>
                }
            </Grid>
        </PopUpModal>
    )
}

export default PopUpClassContentUserSystemSelect