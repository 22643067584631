import { useEffect, useRef, useState } from 'react';
import { Grid, TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next';

import ButtonLoading from '../ButtonLoading/ButtonLoading';
import ToggleMsg from '../ToggleMsg/ToggleMsg';

import { textTransform } from '../../aux/aux';

import './forgetPasswordForm.css'
import { requestResetPassword } from '../../services/userServices';
import { aesCipher } from '../../aux/cryptoHelpers';
import { getCustomErrorOrUndefined } from '../../aux/errorHelpers';

const ForgetPasswordForm = ({ onSuccess=null }) => {
    const { t } = useTranslation('common', { keyPrefix: 'forgetPasswordForm' })
    const recoveryPassStateTypes = ['idle', 'loading'];
    const formErrorTypes = ['email'];
    const abortControllerRef = useRef(null);
    const [sendingError, setSendingError] = useState(false);
    const [recoveryPassState, setRecoveryPassState] = useState({
        form:{
            email:''
        },
        status:recoveryPassStateTypes[0],
        formErrorType: null // email        
    })

    const handleOnChange = (e) => {
        const field = e.target.name;
        const value = e.target.value
        setRecoveryPassState(prev => ({ 
            ...prev, 
            form:{ ...prev.form, [field]: value }, 
            formErrorType: prev.formErrorType === field ? null : prev.formErrorType 
        }))
    }

    const handleOnSubmit = async (e, signal=abortControllerRef.current.signal) => {
        try{
            e.preventDefault();
            setRecoveryPassState(prev => ({...prev, status:recoveryPassStateTypes[1]}))
            const email = recoveryPassState.form.email; 
            const cipheredEmail = aesCipher(email, process.env.REACT_APP_CRYPTO_HASH_KEY);
            await requestResetPassword(cipheredEmail, 1000, signal);
            setRecoveryPassState(prev => ({...prev, status:recoveryPassStateTypes[0]}))
            if(onSuccess){
                onSuccess()
            }
        }catch(error){
            const customError = getCustomErrorOrUndefined(error);
            let errorType = null;
            if(customError){
                switch(customError.cause){
                    case 'sr_001':
                        errorType = formErrorTypes[0];
                        break;
                }
            }
            setRecoveryPassState(prev => ({ ...prev, status:recoveryPassStateTypes[0], formErrorType: errorType }));
            if(errorType == null){
                setSendingError(true);
            }
        }
    }

    useEffect(() => {
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        return(() => {
            abortController.abort();
        })
    },[])

    return (
        <form onSubmit={handleOnSubmit}>
            <Grid container direction='column'>
                <Grid item className='forget-password-input-wrapper'>
                    <TextField 
                        type='email' 
                        name="email" 
                        label={textTransform('title', t("email"))} 
                        value={recoveryPassState.form.email}
                        error={recoveryPassState.formErrorType === formErrorTypes[0]}
                        disabled={recoveryPassState.status === recoveryPassStateTypes[1]}
                        helperText={recoveryPassState.formErrorType === formErrorTypes[0] ? t("unknownEmail") : undefined}
                        onChange={handleOnChange} 
                        required 
                        fullWidth/>
                </Grid>
                <Grid item className='forget-password-button' align='center'>
                    <ButtonLoading variant="outlined" color="primary" isLoading={recoveryPassState.status === recoveryPassStateTypes[1]} type='submit' label={t("sendEmail")} />
                </Grid>
                <Grid item>
                    <ToggleMsg
                        className={`forget-password-error-container ${sendingError === true ? 'show': ''} `}
                        isShown={sendingError} 
                        onShowTime={() => setSendingError(false)}
                        msg={
                            `${textTransform('title', t("sendingRequestError"))}.\n${textTransform('title', t("tryItLater"))}`
                        } 
                    />
                </Grid>
            </Grid>
        </form>
    )
}

export default ForgetPasswordForm
