/**
 * This module contains session related final user services
 */
 import { requestMinimumTimeoutWrapper } from '../aux/requestMethods';
 import { sessionVariables, setStorage, clearUserSessionVariables } from '../aux/sessionHelpers';
 import { loginUserRequestFn, logoutUserRequestFn, recoveryUserRequestFn } from '../aux/authQueries';
import klaviyoWrapper from '../aux/klaviyoHelpers';
import { registerEventInMktProfile, updateSessionLocale } from './visitorServices';
import { reloadScript } from '../aux/aux';

async function recoveryUser(minimumTimeOut=0, signal=null){
    const res = await requestMinimumTimeoutWrapper(recoveryUserRequestFn, {}, minimumTimeOut, signal);
    const {
        basicData,
        subscription,
        email,
        userLocaleCode,
        userLocaleId,
        userCountryCode,
        userStateCode,
        userCurrencyId,
     } = res.data;
    sessionVariables.set('userCountryCode', userCountryCode);  // This variable should be set from the previous session
    sessionVariables.set('userStateCode', userStateCode);  // This variable should be set from the previous session
    sessionVariables.set('userCurrencyId', userCurrencyId);    // This variable should be set from the previous session
    sessionVariables.set('userId', basicData.id);          // This variable should be set from the previous session
    sessionVariables.set('userLocaleCode', userLocaleCode);    // This variable should be set from the previous session
    sessionVariables.set('userLocaleId', userLocaleId);        // This variable should be set from the previous session
    klaviyoWrapper.idenfifyProfile(email, {})
        .then(res => {
            klaviyoWrapper.trackEvent('recoverySession', { time: new Date() })
        })
        .catch(error => {
            registerEventInMktProfile('klaviyo', { email }, { name: klaviyoWrapper.eventNameMap['recoverySession'], time: new Date() })
                .catch(error => {
                    console.error('Mkt provider unable register event from server-side')
                })
        });
    return {
        basicData,
        subscription,
        locale: { localeId:userLocaleId, localeCode:userLocaleCode },
        countryAndCurrency: { countryCode:userCountryCode, stateCode:userStateCode, currencyId:userCurrencyId }
    };
}

async function refreshUser(signal=null){
    const res = await requestMinimumTimeoutWrapper(recoveryUserRequestFn, {}, 0, signal);
    const {
        basicData,
        subscription,
        userLocaleCode,
        userLocaleId,
        userCountryCode,
        userStateCode,
        userCurrencyId,
    } = res.data;
    return {
        basicData,
        subscription,
        locale: { localeId:userLocaleId, localeCode:userLocaleCode },
        countryAndCurrency: { countryCode:userCountryCode, stateCode:userStateCode, currencyId:userCurrencyId }
    };
}

/**
 * 
 * @param {*} userParams 
 * @param {*} rememberSession 
 * @param {function} setUser if it is not given user state is not assigned but session variables are requested and stored. 
 * @param {*} signal 
 * @returns 
 */
async function loginUser(userParams, rememberSession, signal=null){
    const res = await requestMinimumTimeoutWrapper(loginUserRequestFn, userParams, 2000, signal);
    const {
        basicData,
        subscription,
        accessToken,
        refreshToken,
        userLocaleId,
        userLocaleCode,
        userCountryCode,
        userStateCode,
        userCurrencyId,
    } = res.data;
    if(rememberSession){
        setStorage('permanent');
    }else{
        setStorage('session');
    }
    sessionVariables.set('accessToken', accessToken);
    sessionVariables.set('refreshToken', refreshToken);
    sessionVariables.set('userCountryCode', userCountryCode);
    sessionVariables.set('userStateCode', userStateCode);
    sessionVariables.set('userCurrencyId', userCurrencyId);
    sessionVariables.set('userId', basicData.id);
    sessionVariables.set('userLocaleCode', userLocaleCode);
    sessionVariables.set('userLocaleId', userLocaleId);
    klaviyoWrapper.idenfifyProfile(userParams.email, {})
        .then(res => {
            klaviyoWrapper.trackEvent('loggedIn', { time: new Date() })
        })
        .catch(error => {
            registerEventInMktProfile('klaviyo', { email:userParams.email }, { name: klaviyoWrapper.eventNameMap['loggedIn'], time: new Date() })
            .catch(error => {
                console.error('Mkt provider unable register event from server-side')
            })
        });
    return {
        basicData, 
        subscription,
        locale: { localeId:userLocaleId, localeCode:userLocaleCode },
        countryAndCurrency: { countryCode:userCountryCode, stateCode:userStateCode, currencyId:userCurrencyId }
    };
}

function justSetUser(user, setUser){
    setUser({ 
        basicData: user.basicData,
        subscription: user.subscription
    });
}

function justLogoutUser(setUser){
    setUser(null);
}

async function logoutUser(userParams, minimumTimeout=0, signal=null){
    try{
        await requestMinimumTimeoutWrapper(logoutUserRequestFn, userParams, minimumTimeout, signal);
        clearUserSessionVariables();
        klaviyoWrapper.trackEvent('loggedOut', { time: new Date() })
            .catch(error => {
                registerEventInMktProfile('klaviyo', { externalId: userParams.userId }, { name: klaviyoWrapper.eventNameMap['loggedOut'], time: new Date() })
                    .catch(error => {
                        console.error('Mkt provider unable register event from server-side')
                    })
            })
            .finally(res => {
                reloadScript('klaviyo-api',
                    ()=>{console.log('klaviyo refresh')}, 
                    ()=>{console.log('klaviyo could not refresh')}
                )
            })
    }catch(error){
        // TODO: Implement handle Error from logout
        throw error
    }
}

function logoutUserOnClient(signal=null){
    const userId = sessionVariables.get('userId');
    clearUserSessionVariables();
    if(userId){
        klaviyoWrapper.trackEvent('loggedOut', { time: new Date() })
            .catch(error => {
                registerEventInMktProfile('klaviyo', { externalId: userId }, { name: klaviyoWrapper.eventNameMap['loggedOut'], time: new Date() })
                .catch(error => {
                    console.error('Mkt provider unable register event from server-side')
                })
            })
            .finally(res => {
                reloadScript('klaviyo-api',
                    ()=>{console.log('klaviyo refresh')}, 
                    ()=>{console.log('klaviyo could not refresh')}
                )
            })
    }
}

export{
    recoveryUser,
    refreshUser,
    loginUser,
    justSetUser,
    logoutUser,
    logoutUserOnClient,
    justLogoutUser,
}