import { useContext } from "react";
import { InfoBannerPropsContext } from "../customContext/infoBannerPropsProvider";

export function useInfoBannerPropsCtx(){
    const ctx = useContext(InfoBannerPropsContext);
    if(ctx == null){
        throw new Error("useInfoBannerPropsCtx must be used within the InfoBannerPropsProvider context")
    }
    return ctx
}
