import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/styles';
import { textTransform } from '../../aux/aux';
import './userProfileMenu.css';

const UserProfileMenu = ({ selectedMenuItem, onChangeMenuItem }) => {
    const theme = useTheme();
    const { t } = useTranslation('userProfile', { keyPrefix: 'userProfileSideMenu'})
    const menuElements = [ 'general', 'profile', 'password', 'billing']
    const styles = {
        item: { 
            color: theme.palette.primary.main,
            background: 'rgba(63, 81, 181, 0.1)',
            opacity:'1'
        },
        marker: {
            backgroundColor: theme.palette.primary.main
        }
    }

    const handleOnChangeMenuItem = (e, menuItem) => {
        e.preventDefault();
        onChangeMenuItem(menuItem)
    }


    return (
        <Grid className='user-profile-menu-main' container direction="column">
            {menuElements.map((menuItem, index) => {
                return(
                    <div key={index} >
                        <Grid 
                            item
                            key={index} 
                            className='user-profile-menu-item' 
                            onClick={(e) => handleOnChangeMenuItem(e, menuItem)}
                            style={selectedMenuItem == menuItem ? styles.item : {}}
                        >
                            {/* <div 
                                className={`user-profile-menu-item-marker ${(selectedMenuItem == menuItem)&& 'user-profile-menu-item-marker-active' }`}
                                style={styles.marker}
                            /> */}
                                <Typography display="inline">
                                    {textTransform('title', t(menuItem))}
                                </Typography>
                        </Grid>
                        {/* {index < (menuElements.length -1) && <Divider />} */}
                    </div>
                )
            })}
        </Grid>
    )
}

export default UserProfileMenu


