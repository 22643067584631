import { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Grid, Typography } from '@material-ui/core'
import ButtonLoading from '../ButtonLoading/ButtonLoading'
import { useTranslation } from 'react-i18next'
import { logoutUser, logoutUserOnClient } from '../../services/sessionServices'
import './logOutPreviousSessionModal.css'
import UserProfileBadge from '../UserProfileBadge/UserProfileBadge'

const LogOutPreviousSessionModal = ({ user, onLogoutProcess=()=>{}, onCancel=null, onRender=null, redirectToSecurePath=true, securePath='/' }) => {
    const APP_SUBDOMAIN = 'app';
    const { t } = useTranslation('common', { keyPrefix: 'logOutPreviousSessionModal' });
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const abortControllerRef = useRef
    
    const logoutProcess = async (e, signal=abortControllerRef.current.signal) => {
        try{
            e.preventDefault();
            setIsLoading(true)
            await logoutUser({ userId: user.basicData.id }, 1000, signal);
            // if(!signal.aborted && typeof onLogoutProcess === 'function'){
            //     onLogoutProcess()
            // }
        }catch(err){
            // Ensuring user is able to logout on client
            if(!signal.aborted){
                logoutUserOnClient(signal)
            }
        }finally{
            if(!signal.aborted){
                onLogoutProcess();
                setIsLoading(false);
            }
            if(redirectToSecurePath && history.location.pathname.split('/')[1] === APP_SUBDOMAIN){
                history.push(securePath)
            }
        }
    }

    useEffect(() => {
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        return(() => {
            abortController.abort()
        })
    },[])
    
    useEffect(() => {
        if(typeof onRender === 'function'){
            onRender();
        }
    })
    return (
        <Grid container direction='column' className='log-out-previous-session-modal-main-container'>
            <Grid item className='log-out-previous-session-badge-container'>
                <UserProfileBadge user={user} />
            </Grid>
            <Grid item className='log-out-previous-session-modal-text'>
                <Typography variant='body1'>
                    {`${t('thisSessionIsOpen')}.\n${t('doYouWantToCloseIt')}`}
                </Typography>
            </Grid>
            <Grid item container className='log-out-previous-session-modal-button-container'>
                {typeof onCancel === 'function' &&
                    <Grid item className='log-out-previous-session-modal-button'>
                        <Button
                            // variant='outlined'
                            color='primary'
                            onClick={onCancel}
                            size='large'
                        >
                            {t('common:cancel')}
                        </Button>
                    </Grid>
                }
                <Grid item className='log-out-previous-session-modal-button'>
                    <ButtonLoading 
                        isLoading={isLoading}
                        onClick={logoutProcess}
                        label={t('common:ok')}
                        variant="contained"
                        color="primary"
                        size="large"
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default LogOutPreviousSessionModal