import { Typography, Box } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { useEffect, useRef, useState } from 'react'
import { downloadFileFromBlob, textTransform } from '../../aux/aux'
import { getContentProductLessonDownload, getFileFromCDN } from '../../services/contentServices'

// Style
import './contentDownloadElements.css'
import ToggleMsg from '../ToggleMsg/ToggleMsg'
import { useTranslation } from 'react-i18next'

const ContentDownloadElements = ( { userId, contentProductId, downloadIdsStructure, downloadDetailsMap, title, className } ) => {
    const theme = useTheme();
    const { t } = useTranslation('classroom', { keyPrefix: 'contentDownloadElements'})
    const abortControllerRef = useRef(null);
    const [errorMap, setErrorMap] = useState({})
    const linkStyle = {
        color: theme.palette.primary.main,
        cursor: "pointer",
        textDecoration: "underline" 
    }

    const requestFileFromCDN = async ( requestParams, signal ) => {
        try{
            const {
                contentType,
                url,
            } = requestParams;
            return getFileFromCDN(url, contentType, 0, signal);
        }catch(err){
            // TODO:implement
        }
    }

    const requestLessonFileFromApi = async ( requestParams, signal ) => {
        try{
            const {
                lessonId,
                lessonVersionId,
                localeId,
                contentFileUri,
                contentFileType,
            } = requestParams;
            return getContentProductLessonDownload(userId, contentProductId, lessonId, lessonVersionId, localeId, contentFileUri, contentFileType, 0, signal);
        }catch(err){
            // TODO: implement
        }
    }

    const requestLessonFile = async (itemId, downloadItem, signal=abortControllerRef.current.signal) => {
        try{
            setErrorMap(prev => ({...prev, [itemId]: { ...prev[itemId], show:false }}))
            const requestParams = {
                lessonId : downloadItem.lessonId,
                lessonVersionId : downloadItem.lessonVersionId,
                localeId : downloadItem.description.localeId,
                contentFileUri : downloadItem.description.contentFileUri,
                contentFileType : downloadItem.description.contentFileType,
            };
            let res;
            if(downloadItem.description.isLocalUri){
                res = await requestLessonFileFromApi( requestParams, signal );
            }else{
                res = await requestFileFromCDN({ url: requestParams.contentFileUri, contentType: requestParams.contentFileType }, signal );
            }
            const data = res.data
            if(data instanceof Blob){
                const fileName = downloadItem.description.contentFileName;
                if(!signal.aborted){
                    downloadFileFromBlob(data, fileName);
                }
            }
        }catch(err){
            setErrorMap(prev => ({...prev, [itemId]: { show: true, msg: textTransform('title', t('errorDownloadingFile')), type: 'error'}}))
        }
    }

    useEffect(() => {
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        return(() => {
            abortController.abort();
        })
    }, [])

    useEffect(() => {
        if(downloadIdsStructure){
            const defaultErrorMap = downloadIdsStructure.reduce((map, itemId) => {
                map[itemId] = { show:false, msg:undefined, type:undefined };
                return map
            }, {})
            setErrorMap(defaultErrorMap)
        }
    },[downloadIdsStructure])

    return (
        <div className={className}>
            <Typography variant="h6">
                <Box fontWeight="bold">
                    {title}
                </Box>
            </Typography>
            <ul>
                {downloadIdsStructure.map((itemId) => {
                    let downloadItem = downloadDetailsMap[itemId];
                    return(
                        <li key={itemId}>
                            {/* <a href={downloadItem.url} target="_blank" download={downloadItem.title}>{downloadItem.title}</a> */}
                            <Typography component="span" onClick={()=>{requestLessonFile( itemId, downloadItem )}} style={linkStyle}>
                                {textTransform('title', downloadItem.description.contentFileName.split('.')[0])}
                            </Typography>
                            {errorMap[itemId] ? 
                                <ToggleMsg
                                    isShown={errorMap[itemId].show}
                                    msg={errorMap[itemId].msg}
                                    onShowTime={() => setErrorMap(prev => ({...prev, [itemId]:{ ...prev[itemId], show:false }}))}
                                    type={errorMap[itemId].type}
                                    
                                />
                                :
                                null
                            }
                        </li>  
                    )  
                })}
            </ul>
        </div>
    )
}

export default ContentDownloadElements
