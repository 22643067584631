import { useState, useRef,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, Grid, hexToRgb, Typography, Button, Divider } from '@material-ui/core';

import TextEditor from '../TextEditor/TextEditor';

import { getReliableLocale } from '../../aux/sessionHelpers';
import { textTransform, rgbToRgbaOrUndefined } from '../../aux/aux';

import { useTheme } from '@material-ui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ButtonLoading from '../ButtonLoading/ButtonLoading';

import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import './userNoteFullscreen.css';

const UserNoteFullscreen = ({ note, readOnlyConfig, onUpdate, onRemove, onLeave, maxNoteLength=1000, ...props }) => {
    const locale = getReliableLocale();
    const { t } = useTranslation('common');
    const theme = useTheme();
    const {
        errorMsg = 'error',
        errorTimeoutMs = 1000,
        errorStyle = {
            color: theme.palette.error.main,
            backgroundColor: '#fff', 
            borderColor: theme.palette.error.main,
        }
    } = props;
    const [isUpdating, setIsUpdating] = useState(false);
    const [isError, setIsError] = useState(false);
    const [readOnly, setReadOnly] = useState(true);
    const [exportableEditorState, setExportableEditorState] = useState(null);
    const textEditorRef = useRef(null);
    const styles = {
        userNoteWrapper: {
            padding: '10px',
            backgroundColor: '#fff',
            ...(readOnly ? {} : {height: 'calc(90vh - 20px)'})
        },
        userNoteTextEditor: {
            ...(readOnly ? 
                {
                    padding: '10px',
                    maxHeight: 'calc(90vh - 60px)',
                } 
            : 
                {
                    maxHeight: 'calc(90vh - 20px)',
                }
            ),
        },
        textAreaWrapperStyle: {
                ...(readOnly ? {} 
                :   { 
                        overflowY: 'auto',
                        padding: '10px',
                    }
                ),
        },
        userNoteDate: {
            color: '#888',
        },
        noteTypeChip: {
            color: theme.palette.primary.main, //'#C3E0FF',
            borderColor: theme.palette.primary.main, // '#C3E0FF',
            backgroundColor: rgbToRgbaOrUndefined(hexToRgb(theme.palette.primary.main), 0.1),
        },

    }

    const handleOnUpdateEditor = (exportableEditorState) => {
        setExportableEditorState(exportableEditorState);
    }
    const handleOnRemoveClick = (e) => {
        handleOnLeaveClick();
        onRemove(note);
    }

    const handleOnEditClick = (e) => {
        setReadOnly( readOnly => !readOnly)
    }

    const handleOnUpdateClick = async (e) => {
        e.preventDefault();
        setIsUpdating(true);
        const updatedValues = {
            plainText: exportableEditorState.plainText,
            jsonStyledText: JSON.stringify(exportableEditorState.rawBlocks),
        }
        const isUpdated = await onUpdate(note, updatedValues);
        setIsUpdating(false);
        if(isUpdated){
            handleOnEditClick();
        }else{
            setIsError(true);
        }
    };

    const handleOnCancelClick = (e) => {
        const event = new CustomEvent('resetTextEditor', { 
            detail:{
                rawJsonContentBlocks: note.jsonStyledText,
                textEditorId: note.id
            }
        });
        window.dispatchEvent(event);
        handleOnEditClick(e);
    }

    const handleOnLeaveClick = (e) => {
        if(!readOnly){
            handleOnCancelClick(e);
        }
        onLeave()
    }

    useEffect(() => {
        if(isError){
            const timeoutId = setTimeout(() => setIsError(false), errorTimeoutMs);
            return(() => { clearTimeout(timeoutId) })
        }
    },[isError])

    return (
        <div className='user-note-fullscreen-wrapper' style={ styles.userNoteWrapper } >
            <Grid container direction='column' className='user-note-fullscreen-container' >
                { readOnly &&
                    <Grid item>
                        <Grid container className='user-note-fullscreen-read-nav-container'>
                            <Grid item xs className='user-note-fullscreen-nav-elem-wrapper'>
                                <Typography variant='body2' style={ styles.userNoteDate }>
                                    {new Date(note.createdAt).toLocaleDateString(locale.localeCode)}    
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container >
                                    <Grid item  className='user-note-fullscreen-nav-elem-wrapper'>
                                        <Chip 
                                            variant='outlined' 
                                            label={textTransform('title', note.noteTypeName)} 
                                            size="small" 
                                            style={ styles.noteTypeChip }    
                                        />
                                    </Grid>
                                    <Divider flexItem orientation='vertical' variant='middle' />
                                    <Grid item className='user-note-fullscreen-nav-elem-wrapper'>
                                        <CloseFullscreenIcon className='user-note-fullscreen-button-icon' onClick={handleOnLeaveClick} />
                                    </Grid>
                                    <Grid item className='user-note-fullscreen-nav-elem-wrapper'>
                                        <EditIcon className='user-note-fullscreen-button-icon' onClick={ handleOnEditClick } />
                                    </Grid>
                                    <Grid item className='user-note-fullscreen-button-wrapper'>
                                        <DeleteIcon className='user-note-fullscreen-button-icon' onClick={ handleOnRemoveClick } />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                <Grid item className='user-note-fullscreen-editor' xs style={ styles.userNoteTextEditor }>
                    <TextEditor
                        textEditorId={note.id}
                        ref={textEditorRef} 
                        readOnly={readOnly} 
                        readOnlyConfig={{ ...readOnlyConfig }} 
                        rawJsonContentBlocks={note.jsonStyledText} 
                        onUpdateEditor={handleOnUpdateEditor}
                        onShiftAndEnter={handleOnUpdateClick}
                        maxTextLength={maxNoteLength}
                        textAreaWrapperStyle={ styles.textAreaWrapperStyle }
                    >
                        <Grid container className='user-note-fullscreen-edit-button-container'>
                            <Grid item xs>
                                <Button 
                                    onClick={handleOnCancelClick} 
                                    color='primary'
                                    size='small'
                                    style={{height:'100%'}}
                                    fullWidth
                                >
                                    { t('cancel') }
                                </Button>
                            </Grid>
                            <Grid item xs>
                                <ButtonLoading
                                    isLoading={isUpdating}
                                    disabled={isUpdating}
                                    onClick={handleOnUpdateClick} 
                                    variant='contained' 
                                    color='primary' 
                                    size='small'
                                    fullWidth 
                                    label={ t('update') }
                                />
                            </Grid>
                        </Grid>
                    </TextEditor>
                </Grid>
                { !readOnly &&
                    <Grid item className={`user-note-fullscreen-edit-error-toogle ${isError ? 'show' : ''}`} style={ errorStyle }>
                        <Typography variant='body2'>
                            { errorMsg }
                        </Typography>
                    </Grid>
                }
            </Grid> 
        </div>
    )
}

export default UserNoteFullscreen