import { useEffect, useState, useRef } from 'react';
import { Checkbox, FormControlLabel, Typography, TextField } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import ButtonLoading from '../ButtonLoading/ButtonLoading';
// import TextField from '@mui/material/TextField';
import klaviyoWrapper from '../../aux/klaviyoHelpers';
import { createUpdateMktProfile, getSubscriptionList, subscriptionListSubscription } from '../../services/visitorServices';
import { fromArrayOfObjectsToMap, getFromSafeObject, isEmptyObject, isNotEmptyObject, textTransform } from '../../aux/aux';
import { getUserContactData } from '../../services/userServices';
import { isUserLogged } from '../../aux/sessionHelpers';
// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import successLottie from '../../lotties/success.json';
import errorLottie from '../../lotties/error.json';
import congratsLottie from '../../lotties/congrats-success-blue.json';
import './mailingListSubscription.css'
import { useTranslation, Trans } from 'react-i18next';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import { Link } from 'react-router-dom';
import { useLocaleCtx } from '../../customHooks/useLocaleCtx';

const DEFAULT_SUBSCRIPTION_LIST_ARRAY = [];
const DEFAULT_SUBSCRIPTION_LIST_TRANSLATION_MAP = {}
const MailingListSubscription = ({ 
        user, 
        inputPlaceholder='Insert your Email', 
        buttonLabel='send', 
        buttonProps={},
        subscriptionListArray=DEFAULT_SUBSCRIPTION_LIST_ARRAY, // [{name:string, customSource:String, consentLabel:String, isRequired:boolean}]
        subscriptionListTranslationMap=DEFAULT_SUBSCRIPTION_LIST_TRANSLATION_MAP
    }) => {
    const { t } = useTranslation('common', { keyPrefix: 'mailingListSubscription' })
    const PATH_TO_PROFILE_GENERAL_SETTINGS = `/${process.env.REACT_APP_CLASS_SUBDOMAIN}/account/general`
    const theme = useTheme();
    const localeCtx = useLocaleCtx();
    const [form, setForm] = useState({});
    const [userContactData, setUserContactData] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [feedback, setFeedback] = useState({});
    const [isRequestingData, setIsRequestingData] = useState(false);
    const [subscribedListMap, setSubscribedListMap] = useState(null);
    const [isSubscribedUser, setIsSubscribedUser] = useState(false); // user is subscribed to all list in subscripitionListArray
    const animationContainerRef = useRef(null);
    const animationRef = useRef(null);
    const animationContainerArrayRef = useRef([]);
    const animationArrayRef = useRef([]);
    const abortControllerRef = useRef(null);
    const styles = {
        isSubscribedContainer:{
            backgroundColor: theme.palette.surface.light[1]
        },
        isSubscribedCaption:{
            color: theme.palette.grey[400],
        },
        label: {
            // color: '#b3b3b3', //grey or secondary ligth/key/secondary
        }
    }

    const onChangeForm = (e) => {
        const inputType = e.target.type;
        const fieldName = e.target.name;
        const value =  inputType === 'checkbox' ? e.target.checked : e.target.value;
        setForm(prev => ({ ...prev, [fieldName]:value }))
    }

    const onSubmit = async (e, signal=abortControllerRef.current.signal) => {
        let listArray = []
        try{
            e.preventDefault();
            setIsSubmitting(true);
            const {
                email,
                ...consentIdsMap
            } = form;
            Object.entries(consentIdsMap).forEach(([consentId, checked], ) => {
                if(checked){
                    const subscriptionList = subscriptionListArray[consentId];
                    listArray.push({name: subscriptionList.name, customSource: subscriptionList.customSource});            
                }
            })
            if(listArray.length > 0){
                if(!isUserLogged(user)){
                    // New profile is only created if user is not logged
                    // Profile is auto created when is subscribed to a list
                    // This ensures to append to it locale_code as customProperty and info related to location
                    await klaviyoWrapper.idenfifyProfile(email, { locale_code: localeCtx.localeCode })
                        .then(res => {
                            return new Promise(resolve => resolve({ email }));
                        })
                        .catch(async error => {
                            try{
                                // Cannot identify profile from client-side
                                const res = await createUpdateMktProfile('klaviyo', { email }, { locale_code: localeCtx.localeCode }, 0, signal);
                                return new Promise(resolve => resolve({ email }));
                            }catch(error){
                                return new Promise((resolve, reject) => reject(error));
                            }
                        })
                }
                const res = await subscriptionListSubscription('subscribe', listArray, [ { email } ], 0, signal);
                const {
                    successListArray,
                    errorListArray 
                }= res.data;
                if(!signal.aborted){
                    setFeedback(prev => {
                        const subscriptionListArray = []
                        successListArray.forEach(successList => subscriptionListArray.push({type:'success', msg: textTransform('title', t('youAresuccessfullySubscribedToListName', {listName: subscriptionListTranslationMap[successList.name.toLowerCase()]}))}))
                        errorListArray.forEach(errorList => subscriptionListArray.push({type:'error', msg: textTransform('title', t('cannotSubscribeYouIntoListNameNow', {listName: subscriptionListTranslationMap[errorList.name.toLowerCase()]}))}))
                        return({...prev, isShown:true, subscriptionListArray})
                    })
                    if(subscribedListMap){
                        setSubscribedListMap(prev => {
                            const newMap = { ...prev };
                            successListArray.forEach(successList => {
                                const { emailArray, ...list } = successList;
                                newMap[list.name] = list
                            });
                            return newMap;
                        })
                    }
                }
            }
        }catch(error){
            if(!signal.aborted){
                setFeedback(prev => {
                    const subscriptionListArray = []
                    listArray.forEach(errorList => subscriptionListArray.push({type:'error', msg: textTransform('title',t('cannotSubscribeYouIntoListNameNow', {listName: subscriptionListTranslationMap[errorList.name.toLowerCase()]}))}));
                    return({...prev, isShown:true, subscriptionListArray})
                })
            }
        }finally{
            if(!signal.aborted){
                setIsSubmitting(false);
            }
        }
    }

    const requestUserContactData = async(userId, signal=abortControllerRef.current.signal) => {
        try{
            setIsRequestingData(true);
            const res = await getUserContactData(userId, 0, signal);
            const contactData = res.data;
            if(!signal.aborted){
                setForm(prev => ({ ...prev, ...(contactData.email ? { email: contactData.email } : {}) }));
                setUserContactData(contactData);
            }
        }catch(error){
            if(!signal.aborted){
                setUserContactData({});
            }
        }finally{
            if(!signal.aborted){
                setIsRequestingData(false);
            }
        }
    }


    const requestEmailSubscriptionList = async(email, signal) => {
		try{
			setIsRequestingData(true);
			const res = await getSubscriptionList('klaviyo', [ { email } ], 0, signal);
			const {
				profileSubscriptionListMap
			} = res.data
            const profileSubscriptionList = profileSubscriptionListMap[email];
            const subscribedListArray = profileSubscriptionList.subscribedListArray || [];
            const subscribedListMap = subscribedListArray.reduce((subscribedMap, subscribedList) => {
                const list = subscriptionListArray.find(list => list.name === subscribedList.name)
                if(list){
                    subscribedMap[subscribedList.name] = subscribedList;
                }
                return subscribedMap
            }, {})
			setSubscribedListMap( subscribedListMap );
		}catch(error){
            // If there is a requesting error
            // Allow the user to subscribe the list again 
		}finally{
			setIsRequestingData(false);
		}
    }

    useEffect(() => {
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        return(() => {
            abortController.abort();
        })
    },[])

    useEffect(() => {
        const userId = getFromSafeObject(user, 'basicData.id');
        if(userId){
            const abortController = new AbortController();
            requestUserContactData(userId, abortController.signal);
            return(() => abortController.abort());
        }else{
            setUserContactData({});
            setForm({})
        }
    },[user])

    useEffect(() => {
        const email = userContactData?.email;
        if(email){
            const abortController = new AbortController();
            requestEmailSubscriptionList(email, abortController.signal);
            return(() => abortController.abort());
        }else{
            setSubscribedListMap(null);
            setIsSubscribedUser(false);
        }
    },[userContactData])

    useEffect(() => {
        if(feedback.isShown && animationContainerArrayRef.current){
            for(let index = 0; index < feedback.subscriptionListArray.length; index++){
                const listFeedback = feedback.subscriptionListArray[index];
                animationArrayRef.current[index] = lottie.loadAnimation({
                    container: animationContainerArrayRef.current[index],
                    renderer: 'svg',
                    autoplay: true,
                    animationData: listFeedback.type === 'success' ? successLottie : errorLottie,
                    loop: false
                })
            }
            const timeoutId = setTimeout(() => {
                setForm(prev => ({ email: userContactData.email || ''}))
                setFeedback(prev => ({ ...prev, isShown:false}))
            }, 3000)
            return(() => {
                clearTimeout(timeoutId);
                for(let index = 0; index < feedback.subscriptionListArray.length; index++){
                    animationArrayRef.current?.[index]?.stop();
                    animationArrayRef.current?.[index]?.destroy();
                }
            })
        }
    },[feedback])

    useEffect(() => {
        let isSubscribed = false;
        if(isNotEmptyObject(subscribedListMap) && subscriptionListArray instanceof Array) {
            isSubscribed = subscriptionListArray.length === Object.values(subscribedListMap).length
        }
        setIsSubscribedUser( isSubscribed )
    },[subscribedListMap, subscriptionListArray])

    useEffect(() => {
        if(isSubscribedUser && animationContainerRef.current){
            animationRef.current= lottie.loadAnimation({
                container: animationContainerRef.current,
                renderer: 'svg',
                autoplay: true,
                animationData: congratsLottie,
                loop: false
            })
            return(() => {
                animationRef.current?.stop();
                animationRef.current?.destroy();
            })
        }
    },[isSubscribedUser])

    return (
        isSubscribedUser === true ?
                <>
                    <div className='mailing-list-subscription-is-subscribed-container' style={styles.isSubscribedContainer}>
                        <div ref={animationContainerRef} className='mailing-list-subscription-is-subscribed-animation'/>
                        <Typography variant={'body2'} style={{whiteSpace:'pre-line'}}>
                            <Trans t={t} values={{userName:userContactData.firstName}} i18nKey={'userNameIsSubscribedToListNames'}  />
                            {`.\n${t("weWillKeepYouInTheLoop")}`}
                        </Typography>
                    </div>
                    <div style={{textAlign:'center'}}>
                        <Typography variant='caption' style={styles.isSubscribedCaption}>
                            <Trans t={t} components={{Link:<Link to={PATH_TO_PROFILE_GENERAL_SETTINGS} style={styles.isSubscribedCaption}></Link>}} i18nKey={'updateYourPrefenrecesInYourProfile'}/>
                        </Typography>
                    </div>
                </>
            :   
                <form onSubmit={onSubmit}>
                    <div className='mailing-list-subscription-main-container'>
                        <div className='mailing-list-subscription-form-container'>
                            <div className={`mailing-list-subscription-feedback-container ${feedback.isShown ? 'show' : ''}`}>
                                {(feedback.subscriptionListArray || []).map((listFeedback, index) => {
                                    return(
                                        <div key={index} className='mailing-list-subscription-feedback-item-container'>
                                            <div ref={(elem) => animationContainerArrayRef.current[index] = elem} className='mailing-list-subscription-feedback-animation'/>
                                            <Typography variant='body2' className='mailing-list-subscription-feedback-msg'>
                                                {listFeedback.msg}
                                            </Typography>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className={`mailing-list-subscription-form-input-container ${feedback.isShown ? 'hide' : ''}`}>
                                <div className='mailing-list-subscription-email-container'>
                                    <TextField 
                                        placeholder={inputPlaceholder}
                                        name='email'
                                        type={'email'}
                                        value={form.email || ''}
                                        onChange={onChangeForm} 
                                        inputProps={{style:{textOverflow:'ellipsis'}}}
                                        fullWidth
                                        variant='standard'
                                        disabled={userContactData.hasOwnProperty('email') || isSubmitting || isRequestingData}
                                        required
                                    />
                                </div>
                                <div className='mailing-list-subscription-consent-checkbox-container'>
                                    {isRequestingData ?
                                        <div className='mailing-list-subscription-consent-loading-container'>
                                            <LoadingComponent visibleElements="circle" circleSize={theme.typography.body1.fontSize}/>
                                        </div>
                                        :
                                        subscriptionListArray.map((subscriptionList, index) => {
                                            const {
                                                name,
                                                isRequired,
                                            } = subscriptionList;
                                            return(
                                                !getFromSafeObject(subscribedListMap, name) ? 
                                                    <FormControlLabel
                                                        key={index}
                                                        label={
                                                            <Typography variant='body2' style={styles.label}>
                                                                {`${t('WantToReceive', {listName: subscriptionListTranslationMap[name.toLowerCase()]})}${isRequired ? ' *' : ''}`}
                                                            </Typography>
                                                        }
                                                        control={
                                                            <Checkbox 
                                                                className='mailing-list-subscription-consent-checkbox'
                                                                name={`${index}`}
                                                                size='small'
                                                                color='primary'
                                                                checked={form[index] || false}
                                                                onChange={onChangeForm}
                                                                disabled={isSubmitting || isRequestingData}
                                                                required={isRequired || false}
                                                            />
                                                        }
                                                    />
                                                    :
                                                    null
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='mailing-list-subscription-button-container'>
                            <ButtonLoading
                                {...buttonProps}
                                isLoading={isSubmitting}
                                disabled={feedback.isShown}
                                variant='contained'
                                color='primary'
                                type='submit'
                                size='large'
                                label={buttonLabel}
                            />
                        </div>
                    </div>
                </form>
    )
}

export default MailingListSubscription