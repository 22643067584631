import { Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { Skeleton } from '@mui/material' 
import { getFromSafeObject, isNotEmptyObject } from '../../aux/aux'
import { useEffect } from 'react'
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { fromCentTo2dec, getFinalAmountWithoutDiscountCoupon, getFinalAmountWithoutPromoCode, productAmountHasDiscountCouponApplied, productAmountHasPromoCodeApplied } from '../../aux/purchaseHelpers'
import { useTranslation } from 'react-i18next'
import './productAmount.css'

const ProductAmount = ({ amountObject, typoFinalVariant, typoInitialVariant, typoInitialStyle, typoFinalStyle, className, showAmountBeforePromoCode=false, showAmountBeforeDiscount=false }) => {
    const theme = useTheme();
    const matchMobile = useMediaQuery('(max-width:680px');
    const { t } = useTranslation('common');
    const styles = {
        initialAmount:{
            textDecoration:'line-through',
            fontWeight: 'regular',
            ...typoInitialStyle
        },
        finalAmount:{
            fontWeight: 'bold',
            ...typoFinalStyle
        },
        skeleton:{
            fontSize: theme.typography[typoFinalVariant ? typoFinalVariant : matchMobile ? 'h4' : 'h4'].fontSize,
            width: `calc(${theme.typography[typoFinalVariant ? typoFinalVariant : matchMobile ? 'h4' : 'h4'].fontSize} * 3)`
        },
        icon:{
            color:theme.palette.success.main,
            display:"block",
            fontSize: theme.typography[typoFinalVariant ? typoFinalVariant : matchMobile ? 'h4' : 'h4'].fontSize,
        },
    }
    return (
        isNotEmptyObject(amountObject) ?
            <div className={className ? className : 'product-amount-main-container'} >
                {showAmountBeforeDiscount && productAmountHasDiscountCouponApplied(amountObject) &&
                    <Typography variant={typoInitialVariant ? typoInitialVariant : matchMobile ? 'h6':'h6'} style={styles.initialAmount}>
                        {t('{{val, currency}}',{ val:fromCentTo2dec(getFinalAmountWithoutDiscountCoupon(amountObject)), currency: amountObject.currencyId, trailingZeroDisplay:'stripIfInteger' })}
                    </Typography>
                }
                {/* <Typography variant={typoFinalVariant ? typoFinalVariant : matchMobile ? 'h4':'h4'} style={styles.finalAmount} >
                    {t('{{val, currency}}',{ val:fromCentTo2dec(amountObject.finalAmount), currency: amountObject.currencyId, trailingZeroDisplay:'stripIfInteger'  })}
                </Typography> */}
                {showAmountBeforePromoCode && productAmountHasPromoCodeApplied(amountObject) ?
                    <>
                        <div className='product-amount-final-with-promo-container'>
                            <LocalOfferOutlinedIcon style={styles.icon} />
                            <Typography variant={typoFinalVariant ? typoFinalVariant : matchMobile ? 'h4':'h4'} style={styles.finalAmount} >
                                {t('{{val, currency}}',{ val:fromCentTo2dec(amountObject.finalAmount), currency: amountObject.currencyId, trailingZeroDisplay:'stripIfInteger'})}
                            </Typography>
                        </div>
                        <Typography variant={typoInitialVariant ? typoInitialVariant : matchMobile ? 'h6':'h6'} style={{...styles.initialAmount, textAlign:'end'}}>
                            {t('{{val, currency}}',{ val:fromCentTo2dec(getFinalAmountWithoutPromoCode(amountObject)), currency: amountObject.currencyId, trailingZeroDisplay:'stripIfInteger'})}
                        </Typography> 
                    </>
                    :
                    <Typography variant={typoFinalVariant ? typoFinalVariant : matchMobile ? 'h4':'h4'} style={styles.finalAmount} >
                        {t('{{val, currency}}',{ val:fromCentTo2dec(amountObject.finalAmount), currency: amountObject.currencyId, trailingZeroDisplay:'stripIfInteger'  })}
                    </Typography>
                }
            </div>
            :
            <Skeleton variant='text' style={styles.skeleton}/>
    )
}

export default ProductAmount