import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@material-ui/core';
import { getInsertableDate, isNotEmptyObject, textTransform } from '../../aux/aux';
import { getReliableLocale } from '../../aux/sessionHelpers';
import { getSystemFromUserConfig } from '../../aux/userHelpers';
import { getUserMeetingCollectionByProduct, unregisterUserMeetingOccurrenceCollection } from '../../services/meetingServices';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import SimpleNoModalDialog from '../SimpleNoModalDialog/SimpleNoModalDialog';
import UserMeetingBadge from '../UserMeetingBadge/UserMeetingBadge';
// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import noConnection from '../../lotties/noConnection_200x200.json';
import './cancelOpenLiveLessonRegistrationNoModalDialog.css'
import { getElementIdToEvent, triggerEventWithData } from '../../aux/internalCommunicationHelpers';


const CancelOpenLiveLessonRegistrationNoModalDialog = ({ userConfig, onNoRegisteredUserMeeting,  onFinish, onError=()=>{}, onRender=()=>{} }) => {
    // DEBUG: starts
    const locale = getReliableLocale();
    // DEBUG: ends
    const { t } = useTranslation('common', { keyPrefix: 'cancelOpenLiveLessonRegistrationNoModalDialog'})
    const [isLoading, setIsLoading] = useState(true);
    const [isErrorRequesting, setIsErrorRequesting] = useState(false);
    const [isErrorCancellingUserMeeting, setIsErrorCancellingUserMeeting] = useState(false);
    const [registeredUserMeetingMap, setRegisteredUserMeetingMap] = useState(null);
    const [noUnregisteredUserMeetingDetailsArray, setNoUnregisteredUserMeetingDetailsArray] = useState([]);
    const [animationContainer, setAnimationContainerRef] = useState(null);
    const animationRef = useRef(null);
    const abortControllerRef = useRef(null);
    const numRegisteredUserMeeting = registeredUserMeetingMap ? Object.values(registeredUserMeetingMap).length : undefined;

    const handleOnCancelRegisteredUserMeeting = async(uId=userConfig.userId, signal=abortControllerRef.current.signal) => {
        try{
            setIsLoading(true);
            const bodyParams = {
                translationLocaleId: locale.localeId,
                userMeetingIdArray: Object.keys(registeredUserMeetingMap),
                // retrieveUpdate: true,
            }
            const res = await unregisterUserMeetingOccurrenceCollection(uId, bodyParams, 0, signal)
            const {
                updatedUserMeetingArray,
                unregisteredUserMeetingIdArray,
                noUnregisteredUserMeetingDetailsArray,
            } = res.data;
            if(!signal.aborted){
                if(noUnregisteredUserMeetingDetailsArray && noUnregisteredUserMeetingDetailsArray.length > 0){
                    setNoUnregisteredUserMeetingDetailsArray(noUnregisteredUserMeetingDetailsArray);
                    throw new Error('no unregistrable user meetings');    
                }else{
                    onNoRegisteredUserMeeting();
                }
            }
            
            // TODO: push status update from server
            // now userMeeting widget is updated from a client event with the updated userMeeting
            const eventDetails = {
                opType: 'refresh_userMeeting',
                userMeetingIdArray: unregisteredUserMeetingIdArray
            }
            triggerEventWithData('meeting', eventDetails, {});
        }catch(error){
            if(!signal.aborted){
                setIsErrorCancellingUserMeeting(true);
            }
        }finally{
            if(!signal.aborted){
                setIsLoading(false);
            }
        }
    }

    const requestOpenRegistedLiveLessonMap = async(userId, contentProductId, localeId=null, system=null, signal=abortControllerRef.current.signal) => {
        try{
            setIsLoading(true);
            const query = {
                versionBySystem: system,
                translationLocaleId: localeId,
                occurrenceStatusArray: ['open', 'in_progress'],
                // occurrenceDateConditionArray: [`registrationEndsDateTime >= ${getInsertableDate(new Date())}`],
                meetingStatus: 'available',
                collectionType: 'map',
                expand: 'all',
            }
            const res = await getUserMeetingCollectionByProduct(contentProductId, userId, query, 1000, signal);
            const {
                userMeetingCollection,
                noAccessibleUserMeetingArray,
                unknownAccessibilityUserMeetingArray
            } = res.data;
            if(!signal.aborted){
                if(isNotEmptyObject(userMeetingCollection)){
                    setRegisteredUserMeetingMap(userMeetingCollection);
                }else{
                    onNoRegisteredUserMeeting();
                }
            }
        }catch(error){
            if(!signal.aborted){
                setIsErrorRequesting(true);
            }
        }finally{
            if(!signal.aborted){
                setIsLoading(false);
            }
        }
    }
    
    useEffect(() => {
        if(isErrorCancellingUserMeeting || isErrorRequesting){
            const type = isErrorRequesting ? 'requesting' : 'cancelling'
            onError(type);
        }
    }, [isErrorCancellingUserMeeting, isErrorRequesting])
    
    useEffect(() => {
        if(animationContainer != null){
            animationRef.current = lottie.loadAnimation({
                container: animationContainer,
                renderer: 'svg',
                autoplay: true,
                animationData: noConnection,
                loop: false,
                name: 'animation'
            })
            return(() => {
                if(animationRef.current != null){
                    animationRef.current.stop();
                    animationRef.current?.destroy();
                }
            })
        }
    },[animationContainer])
    
    useEffect(() => {
        abortControllerRef.current = new AbortController();
        const system = getSystemFromUserConfig(userConfig);
        requestOpenRegistedLiveLessonMap(userConfig.userId, userConfig.contentProductId, locale.localeId, system);
        return(() => {
            abortControllerRef.current.abort();
        })
    },[]);

    useEffect(() => {
        onRender();
    })

    return (
        <div className='cancel-registered-user-meeting-no-modal-dialog-wrapper'>
            {isLoading ?
                <LoadingComponent visibleElements='circle' />
            :
                registeredUserMeetingMap === null || isErrorRequesting ?
                    <div>
                        <div ref={setAnimationContainerRef} className='cancel-registered-user-meeting-no-modal-dialog-animation-container' />
                        <Typography variant='h5' align='center' className='cancel-registered-user-meeting-no-modal-dialog-error-title'>
                            {textTransform('title', t('uups'))}
                        </Typography>
                        <Typography variant='body1' className='cancel-registered-user-meeting-no-modal-dialog-error-msg'>
                            {`${textTransform('title', t('requestingError'))}.`}
                        </Typography>
                        <Typography variant='body1' className='cancel-registered-user-meeting-no-modal-dialog-error-msg'>
                            {`${textTransform('title', t('please'))}, ${t('tryItLater')}`}
                        </Typography>
                    </div>
                :
                    <>
                        { isErrorCancellingUserMeeting ?
                            noUnregisteredUserMeetingDetailsArray && noUnregisteredUserMeetingDetailsArray.length > 0 ?
                                <SimpleNoModalDialog 
                                    contentText={textTransform('title', t('yourAttendanceCouldNotBeCancelledNow'))} 
                                    onAgree={onFinish}
                                >
                                    <div className='cancel-registered-user-meeting-no-modal-dialog-meeting-badge-wrapper'>
                                        <div className='cancel-registered-user-meeting-no-modal-dialog-meeting-badge-container-overlay-top' />
                                        <div className='cancel-registered-user-meeting-no-modal-dialog-meeting-badge-container'>
                                            {noUnregisteredUserMeetingDetailsArray.map((noUnregisteredDetails, index) => {
                                                const userMeeting = registeredUserMeetingMap[noUnregisteredDetails.userMeetingId];
                                                return(
                                                    <div key={index} style={{paddingTop: index !== 0 ? '10px': ''}}>
                                                        <UserMeetingBadge userMeeting={userMeeting} userMeetingStatus={'occurrence'} userMeetingStatusArray={['mounting', 'requesting', 'noOccurrence', 'occurrence', 'error']} />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className='cancel-registered-user-meeting-no-modal-dialog-meeting-badge-container-overlay-bottom' />
                                    </div>
                                    <Typography variant='body1'>
                                        {`${textTransform('title', t('please'))}, ${t('tryItLater')}`}
                                    </Typography>
                                </SimpleNoModalDialog>
                            :
                                <div>
                                    <div ref={setAnimationContainerRef} className='cancel-registered-user-meeting-no-modal-dialog-animation-container' />
                                    <Typography variant='h5' align='center' className='cancel-registered-user-meeting-no-modal-dialog-error-title'>
                                        {textTransform('title', t('uups'))}
                                    </Typography>
                                    <Typography variant='body1' className='cancel-registered-user-meeting-no-modal-dialog-error-msg'>
                                        {`${textTransform('title', t('cancellingError'))}.`}
                                    </Typography>
                                    <Typography variant='body1' className='cancel-registered-user-meeting-no-modal-dialog-error-msg'>
                                        {`${textTransform('title', t('please'))}, ${t('tryItLater')}`}
                                    </Typography>
                                </div>
                        :
                            <SimpleNoModalDialog 
                                contentText={textTransform('title', t('youAreRegisteredToNEventsWithTheCurrentSystem', { count: numRegisteredUserMeeting}))} 
                                agreeLabel={t('common:yes')}
                                disagreeLabel={t('common:no')} 
                                onAgree={(e) => {e.preventDefault(); handleOnCancelRegisteredUserMeeting()}}
                                onDisagree={onFinish}
                            >
                                <div className='cancel-registered-user-meeting-no-modal-dialog-meeting-badge-wrapper'>
                                    <div className='cancel-registered-user-meeting-no-modal-dialog-meeting-badge-container-overlay-top' />
                                    <div className='cancel-registered-user-meeting-no-modal-dialog-meeting-badge-container'>
                                        {Object.values(registeredUserMeetingMap).map((userMeeting, index) => {
                                            return(
                                                <div key={index} style={{paddingTop: index !== 0 ? '10px': ''}}>
                                                    <UserMeetingBadge userMeeting={userMeeting} userMeetingStatus={'occurrence'} userMeetingStatusArray={['mounting', 'requesting', 'noOccurrence', 'occurrence', 'error']} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className='cancel-registered-user-meeting-no-modal-dialog-meeting-badge-container-overlay-bottom' />
                                </div>
                                <Typography variant='body1'>
                                    {textTransform('title', t('cannotChangeIfYouDoNotUnregister'))}
                                </Typography>
                                <Typography variant='body1'>
                                    {textTransform('title', t('areYouSureYouWantToCancelYourAttendance'))}
                                </Typography>
                            </SimpleNoModalDialog>
                        }
                    </>
            }
        </div>
    )
}
export default CancelOpenLiveLessonRegistrationNoModalDialog