import { useRef, forwardRef, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import ButtonLoading from '../ButtonLoading/ButtonLoading'
import './formControlWrapper.css'

const FormControlWrapper = ({ onSubmit, buttonContainerClassName, buttonProps={}, buttonLabel='submit', ...props }, ref) => {
    const abortControllerRef = useRef(null);
    const handleOnSubmit = (e) => {
        onSubmit(e, abortControllerRef.current.signal);
    }
    useEffect(() => {
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        return(() => {
            abortController.abort()
        })
    },[])
    return (
        <div className={`form-control-wrapper-wrapper ${props.className ? props.className : ''}`}>
            <form ref={ref} onSubmit={handleOnSubmit}>
                {props.children}
                <div className={`form-control-wrapper-button-container ${buttonContainerClassName ? buttonContainerClassName : ''}`}>
                    <ButtonLoading type={'submit'} label={buttonLabel} {...buttonProps}/>
                </div>
            </form>
        </div>
    )
}

export default forwardRef(FormControlWrapper)