import { useContext } from "react";
import { InfoBannerPropsUpdaterContext } from "../customContext/infoBannerPropsProvider";

export function useUpdateInfoBannerPropsCtx(){
    const ctx = useContext(InfoBannerPropsUpdaterContext);
    if(ctx == null){
        throw new Error("InfoBannerPropsUpdaterContext must be used within the InfoBannerPropsProvider context")
    }
    return ctx
}
