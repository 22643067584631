import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box, Divider, IconButton, useMediaQuery, Switch} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { TextField, MenuItem } from '@mui/material'
import { fromArrayOfObjectsToMap, getFromSafeObject, isNotEmptyObject, textTransform } from '../../aux/aux';
import ButtonLoading from '../ButtonLoading/ButtonLoading';
import { getLocales } from '../../services/staticContentServices';
import { localeToFormatedString } from '../../aux/staticContentHelpers';
import { getLocaleCode, getReliableLocale } from '../../aux/sessionHelpers';
import { getUserEmailPreferences, updateUserBasicData } from '../../services/userServices';
import ToggleMsg from '../ToggleMsg/ToggleMsg';
import PopUpUserEmailUpdate from '../PopUpUserEmailUpdate/PopUpUserEmailUpdate';
import { useUpdateLocaleCtx } from '../../customHooks/useUpdateLocaleCtx';
import EditIcon from '@mui/icons-material/Edit';
import {Alert} from '@mui/material';
import { useLocaleCtx } from '../../customHooks/useLocaleCtx';
import { subscriptionListSubscription } from '../../services/visitorServices';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import { offEventListener, onEventListener } from '../../aux/internalCommunicationHelpers';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { aesDecipher } from '../../aux/cryptoHelpers';
import PopUpSimpleFeedback from '../PopUpSimpleFeedback/PopUpSimpleFeedback';
import './userGeneralMenu.css'

const UserGeneralMenu = ({ userProfile, onUpdateStatus, onUpdateUserGeneralData, onUpdateUserSessionVariables }) => {
    const ERROR_TIMEOUT_MS = 4000;
    const { t } = useTranslation('userProfile', { keyPrefix: 'userGeneralMenu'});
    const theme = useTheme();
    const localeCtx = useLocaleCtx();
    const updateLocaleCtx = useUpdateLocaleCtx();
    const matchDesktop = useMediaQuery('(min-width:1120px)');
    const [showUserEmailUpdatePopUp, setShowUserEmailUpdatePopUp] = useState(false);
    const [localeIdMap, setLocaleIdMap] = useState({});
    const [isLocaleLoading, setIsLocaleLoading] = useState(false);
    const [selectedLocaleId, setSelectedLocaleId] = useState('');
    const [requestingLocalesError, setRequestingLocalesError] = useState(false);
    const [isUserEmailPreferencesLoading, setIsUserEmailPreferencesLoading] = useState(false);
    const [updateUserEmailSubscriptionListMap, setUpdateUserEmailSubscriptionListMap] = useState({Marketing:false, Newsletter:false });
    const [isRequestingUserEmailPreferencesError, setIsRequestingUserEmailPreferencesError] = useState(false);
    const [updateUserEmailPreferencesError, setUpdateUserEmailPreferencesError] = useState(false);
    const [userEmailPreferences, setUserEmailPreferences] = useState(null);
    const [updateLocaleError, setUpdateLocaleError] = useState(false);
    const [newEmailDataConfirmation, setNewEmailDataConfirmation] = useState(null);
    const abortControllerRef = useRef(null);
    const styles = {
        value: {
            color: theme.palette.action.disabled
        },
        icon: {
            display: 'block',
            fontSize: '1.2em'// theme.typography.body1.fontSize,
        },
        oldEmail:{
            color:theme.palette.grey[400]
        }
    }

    const requestLocales = async(signal) => {
        try{
            setRequestingLocalesError(false);
            const opt = { expand: true, localeId: localeCtx.localeId };
            const res = await getLocales(opt, 0, signal);
            const {
                localeArrayByLanguageIdMap
            } = res.data;
            if(!signal.aborted){
                setLocaleIdMap(() => Object.values(localeArrayByLanguageIdMap).reduce((accumulated, subArray) => {
                    subArray.forEach(locale => accumulated[locale.id] = locale);
                    return accumulated
                }, {}))
            }
        }catch(error){
            setRequestingLocalesError(true);
        }
    }

    const requestUserEmailPreferences = async(userId, signal) => {
        try{
            setIsUserEmailPreferencesLoading(true);
            setIsRequestingUserEmailPreferencesError(false);
            setUpdateUserEmailPreferencesError(false);
            const res = await getUserEmailPreferences(userId, 0, signal)
            const {
                subscribedListArray,
                ...userEmailPreferences
            } = res.data;
            const subscribedListMap = fromArrayOfObjectsToMap(subscribedListArray, 'name');
            setUserEmailPreferences({...userEmailPreferences, subscribedListMap});
        }catch(error){
            setIsRequestingUserEmailPreferencesError(true);
        }finally{
            setIsUserEmailPreferencesLoading(false);
        }
    }

    const onChangeLocaleField = (e) => {
       const value = e.target.value;
       const newLocaleId = value !== userProfile.localeId ? value : '';
       setSelectedLocaleId(newLocaleId);
    }

    const onUpdateUserEmail = (email) => {
        onUpdateUserGeneralData({ email });
    }

    const onUpdateUserLocale = async(userId=userProfile.id, signal=abortControllerRef.current.signal) => {
        try{
            setIsLocaleLoading(true);
            const newLocale = localeIdMap[selectedLocaleId];
            const updatedValues = {
                localeId: newLocale.id,
            };
            await updateUserBasicData(userId, updatedValues, 0, signal);
            if(!signal.aborted){
                const updatedUserProfileValues = {
                    ...updatedValues,
                    details: { locale: newLocale }
                }
                onUpdateUserGeneralData(updatedUserProfileValues);
                const updatedSessionVariables = {
                    'userLocaleCode': getLocaleCode(newLocale),
                    'userLocaleId': newLocale.id
                }
                onUpdateUserSessionVariables(updatedSessionVariables);
                await updateLocaleCtx({ localeId:updatedSessionVariables.userLocaleId, localeCode:updatedSessionVariables.userLocaleCode })
                setSelectedLocaleId('');
            }
        }catch(error){
            if(!signal.aborted){
                setUpdateLocaleError(true);
            }
        }finally{
            if(!signal.aborted){
                setIsLocaleLoading(false);
            }
        }
    }
    const updateUserEmailPreferences = (prop, updatedValues) => {
        setUserEmailPreferences(prev => ({...prev, [prop]:{...prev[prop], ...updatedValues}}))
    }

    const onUpdateUserEmailSubscriptionList = async(listName, subscribe, signal=abortControllerRef.current.signal) => {
        try{
            setUpdateUserEmailSubscriptionListMap(prev => ({...prev, [listName]: true}))
            const op = subscribe ? 'subscribe' : 'unsubscribe';
            let listArray = [{
				name:listName,
				...subscribe ? { custom_source: 'mail-preferences' } : {}
			}]
            const res = await subscriptionListSubscription(op, listArray, [ { email: userProfile.email } ], 0, signal)
            const {
				successListArray,
				errorListArray 
			}= res.data;
			const list = successListArray[0];
            if(list.name === listName && list.emailArray.includes(userProfile.email)){
                updateUserEmailPreferences('subscribedListMap', {[list.name]: op === 'subscribe' ? {name:list.name, id:list.id} : null})
            }else{
                throw new Error('invalid updated list');
            }
        }catch(err){
            setUpdateUserEmailPreferencesError(true);
        }finally{
            setUpdateUserEmailSubscriptionListMap(prev => ({...prev, [listName]: false}))
        }
    }

    useEffect(() => {
        if(updateLocaleError){
            const timeoutId = setTimeout(() => setUpdateLocaleError(false), ERROR_TIMEOUT_MS);
            return(() => {clearTimeout(timeoutId)})
        }
    },[updateLocaleError])

    useEffect(() => {
        onUpdateStatus('general', selectedLocaleId !== '')
    },[selectedLocaleId])

    useEffect(() => {
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        const handleUpdateUserEmailConfirmation = (e) => {
            const data = e.detail;
            if(data.hasOwnProperty('cipheredEmail') && data.hasOwnProperty('oldCipheredEmail')){
                const email = aesDecipher(data.cipheredEmail, process.env.REACT_APP_CRYPTO_HASH_KEY);
                const oldEmail = aesDecipher(data.oldCipheredEmail, process.env.REACT_APP_CRYPTO_HASH_KEY); 
                setNewEmailDataConfirmation({email, oldEmail});
                onUpdateUserEmail(email)
            }
        }
        onEventListener('updateUserEmail', handleUpdateUserEmailConfirmation);
        return(() => {
            offEventListener('updateUserEmail',handleUpdateUserEmailConfirmation);
            abortController.abort();
        })
    },[])

    useEffect(() => {
        const abortController = new AbortController();
        requestLocales(abortController.signal);
        return(() => { abortController.abort() })
    },[localeCtx])

    useEffect(() => {
        const abortController = new AbortController();
        requestUserEmailPreferences(userProfile.id, abortController.signal);
        return(() => { abortController.abort() })
    },[userProfile])
    
    return (
        <div className="user-general-menu-main-container">
            {newEmailDataConfirmation ?
                <PopUpSimpleFeedback 
                    animationType={'success'}
                    show={newEmailDataConfirmation !== null}
                    onClickClose={() => setNewEmailDataConfirmation(null)}
                >
                    <div className='user-general-menu-update-email-confirmation-popup-email-container'>
                        <div>
                            <SwapVertIcon style={styles.icon}/>
                        </div>
                        <div>
                            <Typography variant='body1' className='user-general-menu-update-email-confirmation-popup-email'>
                                {newEmailDataConfirmation.email}
                            </Typography>
                            <Typography variant='body1' className='user-general-menu-update-email-confirmation-popup-email' style={styles.oldEmail}>
                                {newEmailDataConfirmation.oldEmail}
                            </Typography>
                        </div>
                    </div>
                     <Typography variant='body1'>
                        {textTransform('title', t('yourEmailWasSuccessfullyUpdated'))}
                    </Typography>
                </PopUpSimpleFeedback>
                :
                null
            }
            <PopUpUserEmailUpdate 
                show={showUserEmailUpdatePopUp } 
                onClickClose={() => setShowUserEmailUpdatePopUp(false)}
                userId={userProfile.id}
            />
            <Typography variant="h5" className='user-general-menu-title'>
                { textTransform('title', t("general")) }
            </Typography>
            <div className='user-general-menu-content-container'>
                <div className='user-general-menu-inline-field-group-container'>
                    <Typography variant={matchDesktop ? 'body1' : 'caption'}>
                        {textTransform('title', t("accountEmail"))}
                    </Typography>
                    <div className='user-general-menu-field-value-button-container'>
                        <Typography variant='body1' className='user-general-menu-field-value' style={styles.value}>
                            {userProfile.email}
                        </Typography>
                        {matchDesktop ? 
                            <ButtonLoading
                                color='primary'
                                label={t("common:update")}
                                disabled={ showUserEmailUpdatePopUp }
                                onClick={(e) => setShowUserEmailUpdatePopUp(true)}
                            />
                            :
                            <IconButton
                                color='primary'
                                label={t("common:update")}
                                size='small'
                                disabled={ showUserEmailUpdatePopUp }
                                onClick={(e) => setShowUserEmailUpdatePopUp(true)}
                            >
                                <EditIcon style={styles.icon} />
                            </IconButton>
                        }
                    </div>
                </div>
                <Divider variant="fullWidth" />
                <div className='user-general-menu-group-container'>
                    <Typography className='user-general-menu-group-title'>
                        {textTransform('capitalize', t("emailPreferences"))}
                    </Typography>
                    {isRequestingUserEmailPreferencesError || updateUserEmailPreferencesError ?
                        <Alert severity='error'>
                            {textTransform('title',t('cannotUpdateYourEmailPreferencesNow'))}
                        </Alert>
                        :
                        null
                    }
                    <div className='user-general-menu-inline-field-group-container'>
                        <div className=''>
                            <Typography variant={matchDesktop ? 'body1' : 'body2'}>
                                {textTransform('title', t("promotions"))}
                            </Typography>
                            <Typography variant={'caption'}>
                                {textTransform('title', t("receiveOffersDiscountsAndExclusiveContents"))}
                            </Typography>
                        </div>
                        <div className='user-general-menu-field-value-button-container'>
                            <div className='user-general-menu-field-load-button-container'>
                                {updateUserEmailSubscriptionListMap.Marketing ?
                                    <LoadingComponent visibleElements="circle" circleSize={theme.typography.body1.fontSize}/>
                                    :
                                    null
                                }
                                <Switch color='primary' disabled={isUserEmailPreferencesLoading || isRequestingUserEmailPreferencesError || updateUserEmailSubscriptionListMap.Marketing} checked={isNotEmptyObject(getFromSafeObject(userEmailPreferences,'subscribedListMap.Marketing'))} onChange={(e, checked) => onUpdateUserEmailSubscriptionList('Marketing', checked)} size={matchDesktop ? "medium" : "small"} />
                            </div>
                        </div>
                    </div>
                    <div className='user-general-menu-inline-field-group-container'>
                        <div className=''>
                            <Typography variant={matchDesktop ? 'body1' : 'body2'}>
                                {textTransform('title', t("newsletter"))}
                            </Typography>
                            <Typography variant={'caption'}>
                                {textTransform('title', t("receiveOurNewsLetter"))}
                            </Typography>
                        </div>
                        <div className='user-general-menu-field-value-button-container'>
                            <div className='user-general-menu-field-load-button-container'>
                                {updateUserEmailSubscriptionListMap.Newsletter ?
                                    <LoadingComponent visibleElements="circle" circleSize={theme.typography.body1.fontSize}/>
                                    :
                                    null
                                }
                                <Switch color='primary' disabled={isUserEmailPreferencesLoading || isRequestingUserEmailPreferencesError || updateUserEmailSubscriptionListMap.Newsletter} checked={isNotEmptyObject(getFromSafeObject(userEmailPreferences,'subscribedListMap.Newsletter'))} onChange={(e, checked) => onUpdateUserEmailSubscriptionList('Newsletter', checked)} size={matchDesktop ? "medium" : "small"}/>
                            </div>
                        </div>
                    </div>
                </div>
                <Divider variant="fullWidth" />
                <div className="user-general-menu-group-container">
                    <Typography className='user-general-menu-group-title'>
                        {textTransform('capitalize', t("language"))}
                    </Typography>
                    <TextField 
                        color='primary'
                        name="locale" 
                        value={selectedLocaleId || userProfile.localeId} 
                        onChange={ onChangeLocaleField }
                        select
                        disabled={requestingLocalesError || isLocaleLoading} 
                        variant='standard'
                        fullWidth
                    >
                        {Object.values(localeIdMap).map((locale, index) => {
                        return(
                            <MenuItem key={locale.id} value={locale.id}>
                                {textTransform('title', localeToFormatedString(locale))}
                            </MenuItem>
                        )
                        })}
                        { Object.values(localeIdMap).length === 0 && userProfile.localeId &&
                            <MenuItem key={userProfile.localeId} value={userProfile.localeId} >
                                {textTransform('title', localeToFormatedString(userProfile.details.locale))}
                            </MenuItem>
                        }
                    </TextField>
                    <ToggleMsg 
                        type='warning' 
                        isShown={requestingLocalesError} 
                        msg={
                            `${textTransform('title', t("cannotUpdateYourLanguageNow"))}. ${textTransform('title', t("tryItLater"))}`
                        } 
                    />
                </div>
                <div className='user-general-menu-button-save-locale'>
                    <ButtonLoading 
                        color='primary'
                        variant='outlined'
                        label={t('common:save')}
                        isLoading={isLocaleLoading}
                        disabled={!selectedLocaleId}
                        onClick={(e) => onUpdateUserLocale()}
                        fullWidth
                    />
                    <ToggleMsg 
                        type='error' 
                        isShown={updateLocaleError} 
                        msg={
                            `${textTransform('title', t("languageCannotBeUpdated"))} ${textTransform('title', t("tryItLater"))}`
                        } 
                    />
                </div>
            </div>
        </div>
    )
}

export default UserGeneralMenu