import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Typography } from '@material-ui/core';
import PopUpModal from '../PopUpModal/PopUpModal'
import PopUpModalHeader from '../PopUpModalHeader/PopUpModalHeader'
import FormControlWrapper from '../FormControlWrapper/FormControlWrapper';
import ToggleMsg from '../ToggleMsg/ToggleMsg';
// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import successAnimation from '../../lotties/success.json';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import './popUpFindSpecialist.css'
import { sendSupportRequest } from '../../services/visitorServices';
import { getReliableLocale } from '../../aux/sessionHelpers';
import { textTransform } from '../../aux/aux';
import FindSpecialistForm from '../FindSpecialistForm/FindSpecialistForm';
import { useLocaleCtx } from '../../customHooks/useLocaleCtx';

const PopUpFindSpecialist = ({ showModal, onClickClose, height, width }) => {
    const localeCtx = useLocaleCtx();
    const { t } = useTranslation('common', { keyPrefix: 'popUpContactSupport'});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [submitError, setSubmitError] = useState({});
    const [animationContainer, setAnimationContainer] = useState(null);
    const animationRef = useRef(null);
    const styles = {
        title: {
            fontWeight: 'bold !important'
        }
    }
    const handleOnSubmitForm = async (e, signal) => {
        try{
            e.preventDefault()
            setIsSubmitting(true)
            const request = {
                ...formValues,
                type: 'searchingForSpecialist',
                context: {
                    origin: window.location.origin,
                    path: window.location.pathname,
                    localeCode: localeCtx.localeCode,
                }
            }
            await sendSupportRequest(formValues.type, request, 1000, signal);
            if(!signal.aborted){
                setIsSubmitting(false)
                setIsSubmitted(true);
            }
        }catch(error){
            if(!signal.aborted){
                setIsSubmitting(false)
                setSubmitError({
                    isShown: true,
                    type: 'error',
                    msg: `${textTransform('title', t("cannotSubmitTheForm"))}.\n${textTransform('title', t("tryItLater"))}`
                })
            }
        }
    }

    useEffect(() => {
        if(!showModal){
            setSubmitError({})
            setIsSubmitting(false);
            setIsSubmitted(false);
            setAnimationContainer(null);
        }
    },[showModal])

    useEffect(() => {
        if(isSubmitted){
            const timeoutId = setTimeout(() => onClickClose(), 5000);
            return(() => {
                clearTimeout(timeoutId)
            })
        }
    },[isSubmitted])

    useEffect(() => {
        if(animationContainer != null){
            animationRef.current = lottie.loadAnimation({
                container: animationContainer,
                renderer: 'svg',
                loop: false,
                autoplay: true,
                animationData: successAnimation
            });
            return(() => {
                animationRef.current?.stop();
                animationRef.current?.destroy()
            })
        }
    },[animationContainer])

    return (
        <PopUpModal showModal={showModal} onClickClose={onClickClose} height={height} width={width}>
            {/* <PopUpModalHeader 
                title={`¿${popUpContactSupportTxt.lookingForASpecialist}?`} 
                subtitle={popUpContactSupportTxt.wePutYouInTouchWithTheMoreSuitableQuantumSpecialist}

            /> */}
            <div className='pop-up-find-specialist-title'>
                <Typography variant="h5" style={styles.title}>
                    {textTransform('title', t("areYoulookingForASpecialist"))}
                </Typography>
                <Typography variant='body2'>
                    {textTransform('title', t("wePutYouInTouchWithTheMoreSuitableQuantumSpecialist"))}
                </Typography>
            </div>
            <Divider variant='middle' style={{marginTop:"10px", marginBottom:"10px"}}/>
            {isSubmitted ? 
                <div className='pop-up-find-specialist-form-submited-container'>
                    <div className='pop-up-find-specialist-form-submitted-animation' ref={setAnimationContainer}/>
                    <div className='pop-up-find-specialist-form-submitted-msg'>
                        <Typography variant='body1'>
                            {
                                `${textTransform('title', t("yourRequestWasSuccessfullySent"))}. `
                                +`${textTransform('title', t("youWillHearFormUsSoon"))}.\n`
                                +`${textTransform('title', t("common:thankYou"))}`
                            }
                        </Typography>
                    </div>
                </div>
            :
                <>
                    <FormControlWrapper 
                        className='pop-up-find-specialist-form-wrapper' 
                        onSubmit={handleOnSubmitForm}
                        buttonLabel={t("common:send")}
                        buttonContainerClassName='pop-up-find-specialist-form-button-container'
                        buttonProps={{
                            color:'primary', 
                            variant:'contained',
                            isLoading: isSubmitting,
                            size:'large'
                        }}
                    >
                        <FindSpecialistForm 
                            isDisabled={isSubmitting}
                            resetForm={!showModal}
                            onUpdateForm={(form) => setFormValues(form)}
                        />
                    </FormControlWrapper>
                    <ToggleMsg 
                        extraClassName='pop-up-find-specialist-toggle-msg'
                        absolutePositon={true}
                        isShown={submitError.isShown === true} 
                        type={submitError.type}
                        onShowTime={() => {
                            setSubmitError(prev => ({...prev, isShown:false}))
                        }}
                        msg={submitError.msg}
                    />
                </>
            }
        </PopUpModal>
    )
}

export default PopUpFindSpecialist