import { useRef, useCallback, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TextField, MenuItem } from '@mui/material'
import PopUpModal from '../PopUpModal/PopUpModal'
import PopUpModalHeader from '../PopUpModalHeader/PopUpModalHeader'
import LanguageIcon from '@mui/icons-material/Language';
import { getFromSafeObject, isNotEmptyObject, textTransform } from '../../aux/aux';
import './popUpEditLanguage.css'
import { ipSessionVariables, getLocaleCode, isUserLogged, sessionVariables } from '../../aux/sessionHelpers';
import ButtonLoading from '../ButtonLoading/ButtonLoading';
import SimpleNoModalDialog from '../SimpleNoModalDialog/SimpleNoModalDialog';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import { updateUserBasicData } from '../../services/userServices';
import ToggleMsg from '../ToggleMsg/ToggleMsg';
import { useUpdateLocaleCtx } from '../../customHooks/useUpdateLocaleCtx';

const PopUpEditLanguage = ({ showModal, onClickClose, user=null }) => {

    const POP_UP_WIDTH = 380;
    const STATE_ARRAY = ['updateLocale', 'updateUserLocale'];
    const {t, i18n} = useTranslation('common');
    const updateLocaleCtx = useUpdateLocaleCtx();
    const [isLoading, setIsLoading] = useState(false);
    const [localeIdMap, setLocaleIdMap] = useState(null);
    const [selectedLocaleId, setSelectedLocaleId] = useState(null);
    const [currentLocaleId, setCurrentLocaleId] = useState(null);
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [state, setState] = useState(STATE_ARRAY[0])
    const abortControllerRef = useRef(null);

    const onChangeLocaleField = (e) => {
        const value = e.target.value;
        setSelectedLocaleId(value);
    };

    const updateLocale = async (e, signal=abortControllerRef.current.signal) => {
        try{
            setIsLoading(true);
            const selectedLocale = localeIdMap[selectedLocaleId];
            const selectedLocaleCode = getLocaleCode(selectedLocale);
            await updateLocaleCtx({ localeId:selectedLocale.id, localeCode:selectedLocaleCode });
            if(!signal.aborted){
                setCurrentLocaleId(selectedLocaleId);
                if(isUserLogged(user) && parseInt(sessionVariables.get('userLocaleId')) !== selectedLocale.id ){
                    setState(STATE_ARRAY[1]);
                }else{
                    onClickClose();
                }
            }
        }catch(error){
            if(!signal.aborted){
                setIsError(true);
                setErrorMsg(`${t('popUpEditLanguage.cannotChangeTheLanguageNow')}.\n${t('popUpEditLanguage.tryItLater')}`);
            }
        }finally{
            if(!signal.aborted){
                setIsLoading(false);
            }
        }
    }

    const onUpdateUserLocale = async(e, userId=getFromSafeObject(user, 'basicData.id'), signal=abortControllerRef.current.signal) => {
        try{
            setIsLoading(true);
            const selectedLocale = localeIdMap[selectedLocaleId];
            const updatedValues = {
                localeId: selectedLocale.id,
            };
            await updateUserBasicData(userId, updatedValues, 0, signal);
            if(!signal.aborted){
                sessionVariables.set('userLocaleId', selectedLocale.id);
                sessionVariables.set('userLocaleCode', getLocaleCode(selectedLocale));
                onClickClose();
            }
            
        }catch(error){
            if(!signal.aborted){
                setIsError(true);
                setErrorMsg(`${t('popUpEditLanguage.cannotUpdateProfileLanguage')}.\n${t('popUpEditLanguage.tryItLater')}`);
            }
        }finally{
            if(!signal.aborted){
                setIsLoading(false);
            }
        }
    }

    const getLocaleIdMap = (availLocaleMap) => {
        try{
            return Object.values(availLocaleMap).reduce((accumulated, localeArray) => {
                localeArray.forEach(locale => accumulated[locale.id] = locale);
                return accumulated
            },{})
        }catch(error){
            setIsError(true);
            setErrorMsg(`${t('popUpEditLanguage.cannotChangeTheLanguageNow')}.\n${t('popUpEditLanguage.tryItLater')}`);
        }
    } 

    useEffect(() => {
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        return(() => { abortController.abort() })
    },[]);

    useEffect(() => {
        const sessionLocaleId =  ipSessionVariables.get('localeId');
        const availLocaleMap = ipSessionVariables.get('availLocaleMap', true);
        if(showModal){
            if(sessionLocaleId && availLocaleMap){
                if(!localeIdMap){
                    setLocaleIdMap(getLocaleIdMap(availLocaleMap));
                }
            }else{
                const timeoutId = setTimeout(() => {
                    setIsLoading(false);
                    setLocaleIdMap({})
                    setSelectedLocaleId('');
                    setCurrentLocaleId('');
                    setIsError(true);
                    setErrorMsg(`${t('popUpEditLanguage.connectivityError')}.\n${t('popUpEditLanguage.tryItLater')}`);
                }, 3000);
                return(() => { clearTimeout(timeoutId) })
            }
        }else{
            setState(STATE_ARRAY[0]);
            setSelectedLocaleId(sessionLocaleId ? parseInt(sessionLocaleId) : null);
            setCurrentLocaleId( sessionLocaleId ? parseInt(sessionLocaleId) : null);
            setLocaleIdMap(prev => isNotEmptyObject(prev) ? prev : null); 
            setIsError(false);
            setErrorMsg('');
        }
    },[showModal])

    return (
        <PopUpModal showModal={showModal} width={POP_UP_WIDTH} isDialog={true}>
            <PopUpModalHeader title={t('popUpEditLanguage.changeLanguage')} Icon={LanguageIcon} />
            { localeIdMap != null && selectedLocaleId != null && currentLocaleId != null ?
                <SimpleNoModalDialog 
                    className='pop-up-edit-language-main-container'
                    onDisagree={onClickClose}
                    isLoading={isLoading}
                    isDisabled={isLoading || isError || (state === STATE_ARRAY[0] ? selectedLocaleId === currentLocaleId : false)}
                    isDisagreeDisabled={isLoading}
                    onAgree={state === STATE_ARRAY[0] ? updateLocale : onUpdateUserLocale }
                    contentText={state === STATE_ARRAY[0] ? null : t('popUpEditLanguage.doYouWantToUpdateYourLanguageProfileAsWell')}
                    errorMsg={errorMsg}
                    isError={isError}
                >
                    {state === STATE_ARRAY[0] &&
                        <TextField 
                            color='primary'
                            name="locale" 
                            value={selectedLocaleId} 
                            onChange={ onChangeLocaleField }
                            select
                            disabled={isError || isLoading} 
                            variant='standard'
                            fullWidth
                        >
                            {Object.values(localeIdMap).map((locale, index) => {
                            return(
                                <MenuItem key={locale.id} value={locale.id}>
                                    {`${textTransform('title',t(locale.languageId))} (${textTransform('title',t(locale.countryCode))})`}
                                </MenuItem>
                            )
                            })}
                        </TextField>
                    }
                </SimpleNoModalDialog>
                :
                <div className='pop-up-edit-language-loading-container' >
                    <LoadingComponent visibleElements='circle'/>
                </div>
            }
        </PopUpModal>
    )
}

export default PopUpEditLanguage