import { useEffect, useState } from 'react';
import { Chip, hexToRgb } from '@material-ui/core';
import { useTheme } from '@material-ui/styles'
import { isValidDate, rgbToRgbaOrUndefined, textTransform } from '../../aux/aux';
import { isProductNew } from '../../aux/productHelpers';
import { useTranslation } from 'react-i18next';
import CustomChip from '../CustomChip/CustomChip';

const ConsumableDateChip = ({ hasConsumableDate, consumableDateStatus, releaseDate, size, variant }) => {
    const { t } = useTranslation('common', { keyPrefix: 'consumableDateChip' })
    const theme = useTheme()
    const [labelKey, setLabelKey] = useState(null);
    const styles = {
        new:{
            backgroundColor: theme.palette.common.white
        },
        comingSoon:{
            backgroundColor: theme.palette.common.white,
        }
    }
    useEffect(() => {
        if(hasConsumableDate){
            let labelKey;
            switch(consumableDateStatus){
                case 'noDate':
                    labelKey = 'comingSoon';
                    break;
                case 'released':
                    const isNew = isProductNew(releaseDate);
                    if(!isNew){
                        break;
                    }
                case 'noReleased':
                    labelKey = 'new';
                    break;
            }
            setLabelKey(labelKey)
        }
    },[hasConsumableDate, consumableDateStatus, releaseDate])
    return (
        labelKey ?
            <CustomChip 
                size={size ||'small'}
                variant={variant || 'outlined'}
                label={t(labelKey)}
                style={styles[labelKey]}
            />
            :
            null
    )
}

export default ConsumableDateChip