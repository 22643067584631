import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Divider, Grid, Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import { getUserProfile } from "../../services/userServices";
import { getLocaleCode, getReliableLocale, sessionVariables } from "../../aux/sessionHelpers";

import UserProfileMenu from '../../components/UserProfileMenu/UserProfileMenu'
import UserBasicDataMenu from '../../components/UserBasicDataMenu/UserBasicDataMenu'
import UserPasswordMenu from '../../components/UserPasswordMenu/UserPasswordMenu'
import UserBillingDataMenu from '../../components/UserBillingDataMenu/UserBillingDataMenu'

import BigFooter from '../../components/BigFooter/BigFooter'

// Style
// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import noConnection from '../../lotties/noConnection_200x200.json';
import { WarningAmber } from '@mui/icons-material';
import './userProfile.css'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import PopUpModal from '../../components/PopUpModal/PopUpModal';
import SimpleNoModalDialog from '../../components/SimpleNoModalDialog/SimpleNoModalDialog';
import { isNotEmptyObject, textTransform } from '../../aux/aux';
import PopUpModalHeader from '../../components/PopUpModalHeader/PopUpModalHeader';
import UserGeneralMenu from '../../components/UserGeneralMenu/UserGeneralMenu';
import { useUpdateCountryAndCurrencyCtx } from '../../customHooks/useUpdateCountryAndCurrencyCtx';
import { useLocaleCtx } from '../../customHooks/useLocaleCtx';
import BigFooterNoLinks from '../../components/BigFooterNoLinks/BigFooterNoLinks';

const UserProfile = ( { user, setUser, initialItem='profile' }) => {
    const { t } = useTranslation('userProfile');
    const locale = useLocaleCtx();
    const theme = useTheme()
    const matchMobile = useMediaQuery('(max-width:680px)')
    const ROUTE_MAIN_PATH = `/${process.env.REACT_APP_CLASS_SUBDOMAIN}/${'account'}`;
    const POP_UP_WIDTH = 380;
    const history = useHistory();
    const updateCountryAndCurrencyCtx = useUpdateCountryAndCurrencyCtx(); 
    const [menuItem, setMenuItem] = useState(null);
    const [userProfile, setUserProfile] = useState({});
    const [requestingError, setRequestingError] = useState(false);
    const [menuItemPendingToSave, setMenuItemPendingToSave] = useState('');
    const [showNotSavedWarning, setShowNotSavedWarning] = useState(false);
    const [animationContainer, setAnimationContainerRef] = useState(null);
    const animationRef = useRef(null);
    const menuItemPendingToTransitRef = useRef('');
    const abortControllerRef = useRef(null);

    const styles = {
        wrapperContainer:{
            backgroundColor :theme.palette.surface.light[2]
        },
        mainContainer:{
        },
        profileMenuContainer: {
            backgroundColor: theme.palette.surface.light[2]
        },
        contentContainer:{
            boxShadow: theme.shadows[2],
            backgroundColor: theme.palette.background.paper
        }
    }

    const updateUserSessionVariables = ( updatedValues ) => {
        Object.entries(updatedValues).forEach(([key, value], ) => {
            if(sessionVariables.hasOwnProperty(key)){
                sessionVariables.set(key, value)
            }
        });
    }

    const onUpdateUserGeneralData = ( updatedValues ) => {
        setUserProfile(prev => {
            const updatedUserProfileValues = {
                ...prev,
                ...(updatedValues.hasOwnProperty('email') ? { email:updatedValues.email } : {}),
                ...(updatedValues.hasOwnProperty('localeId') ? { localeId:updatedValues.localeId } : {}),
                ...(updatedValues.hasOwnProperty('details') ? {
                    details: { ...prev.details, ...updatedValues.details}
                } : {})
            }
             return updatedUserProfileValues
        });
    }

    const onUpdateUserBillingData = ( updatedValues ) => {
        setUserProfile(prev => ({...prev, billingAddressAndCurrency: {...prev.billingAddressAndCurrency, ...updatedValues} }));
    } 

    const onUpdateUserBasicData = ( updatedValues ) => {
        setUserProfile(prev => ({...prev, ...updatedValues}));
    }

    const requestUserProfile = async(userId, localeId, signal) => {
        try{
            const res = await getUserProfile(userId, localeId, 0, signal);
            const { userProfile } = res.data
            if(!signal.aborted){
                setUserProfile(userProfile)
            }
        }catch(error){
            setRequestingError(true);
        }
    }

    const handleOnChangeMenuItem = (newMenuItem) => {
        if(menuItemPendingToSave){
            menuItemPendingToTransitRef.current = newMenuItem;
            setShowNotSavedWarning(true);
        }else{
            history.push(`${ROUTE_MAIN_PATH}/${newMenuItem}`);
        }
    }

    const handleOnUpdateMenuItemStatus = (menuItem, isPendingToSave) => {
        let result;
        if(isPendingToSave){
            result = menuItem
        }else{
            result =''
        }
        setMenuItemPendingToSave(result);
    }

    const getComponentToRender = () => {
        let componentToRender;
        switch(menuItem){
            case 'billing' : 
                componentToRender = <UserBillingDataMenu userProfile={userProfile} onUpdateUserBillingData={onUpdateUserBillingData} />;
                break;
            case 'general':
                componentToRender = <UserGeneralMenu userProfile={userProfile} onUpdateStatus={handleOnUpdateMenuItemStatus} onUpdateUserGeneralData={onUpdateUserGeneralData} onUpdateUserSessionVariables={updateUserSessionVariables} />
                break;
            case 'password':
                componentToRender = <UserPasswordMenu userId={userProfile.id} onUpdateStatus={handleOnUpdateMenuItemStatus}/>
                break;
            case 'profile':
            default:
                componentToRender = <UserBasicDataMenu userProfile={userProfile} onUpdateUserBasicData={onUpdateUserBasicData} onUpdateStatus={handleOnUpdateMenuItemStatus}/>
                break;
        }
        return componentToRender;
    }

    useEffect(() => {
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        requestUserProfile(user.basicData.id, locale.localeId, abortController.signal);
        return(() => {
            abortController.abort()
        })
    },[locale])

    useEffect(() => {
        if(animationContainer){
            animationRef.current = lottie.loadAnimation({
                container: animationContainer,
                renderer: 'svg',
                loop: false,
                autoplay: true,
                animationData: noConnection
            });
            return(() => {animationRef.current.stop(); animationRef.current?.destroy()})
        }
    },[animationContainer])

    useEffect(() => {
        setMenuItem(initialItem);
    },[initialItem])

    useEffect(() => {
        if(isNotEmptyObject(userProfile)){
            setUser((prev) => ({
                ...prev,
                basicData:{
                    ...prev.basicData,
                    ...(userProfile.hasOwnProperty('firstName') ? { firstName: userProfile.firstName } : {}),
                    ...(userProfile.hasOwnProperty('profileImgURL') ? { profileImgURL: userProfile.profileImgURL } : {}),
                }
            }))
        }
    },[userProfile.firstName, userProfile.profileImgURL])

    useEffect(() => {
        if(isNotEmptyObject(userProfile) && isNotEmptyObject(userProfile.billingAddressAndCurrency)){
            const updatedCountryAndCurrency = {
                countryCode: userProfile.billingAddressAndCurrency.countryCode,
                currencyId: userProfile.billingAddressAndCurrency.currencyId,
                stateCode: userProfile.billingAddressAndCurrency.stateCode,
            }
            sessionVariables.set('userCountryCode', userProfile.billingAddressAndCurrency.countryCode);
            sessionVariables.set('userStateCode', userProfile.billingAddressAndCurrency.stateCode);
            sessionVariables.set('userCurrencyId', userProfile.billingAddressAndCurrency.currencyId);
            updateCountryAndCurrencyCtx.setCountryAndCurrency(updatedCountryAndCurrency)
        }
    },[userProfile.billingAddressAndCurrency])

    return (
        <div className='user-profile-wrapper' style={styles.wrapperContainer}>
            <div className='user-profile-main-container' style={styles.mainContainer}>
                <PopUpModal 
                    showModal={showNotSavedWarning} 
                    isDialog={true} 
                    width={POP_UP_WIDTH}
                >
                    <PopUpModalHeader title={t('loseChanges')} Icon={WarningAmber} />
                    <SimpleNoModalDialog
                        className='user-profile-warning-popup-wrapper'
                        contentText={
                            `${textTransform('title', t('notSavedChangesWillBeLost'))}`
                            +`. ${textTransform('title', t('doYouWantToContinue'))}`
                        }
                        onDisagree={() => {
                            setShowNotSavedWarning(false);
                            menuItemPendingToTransitRef.current = '';
                        }}
                        onAgree={() => {
                            setShowNotSavedWarning(false);
                            setMenuItemPendingToSave('');
                            const newMenuItem = menuItemPendingToTransitRef.current;
                            menuItemPendingToTransitRef.current = '';
                            history.push(`${ROUTE_MAIN_PATH}/${newMenuItem}`);
                        }}
                    />
                </PopUpModal>

                <div className='user-profile-menu-container' style={styles.profileMenuContainer}>
                    <UserProfileMenu selectedMenuItem={menuItem} onChangeMenuItem={handleOnChangeMenuItem} />
                </div>
                {!matchMobile ? <Divider orientation='vertical' flexItem/> : null}
                <div className='user-profile-content-container' style={styles.contentContainer}>
                    {isNotEmptyObject(userProfile) ?
                            getComponentToRender()
                        :
                            requestingError ?
                                    <div className='user-profile-error-container'>
                                        <div
                                            className='user-profile-error-animation-container'
                                            ref={setAnimationContainerRef} 
                                        />
                                        <Typography variant='body1' style={{whiteSpace:'pre-line'}} >
                                            {
                                                `${textTransform('title', t('requestingError'))}.`
                                                + `\n${textTransform('title', t('tryLater'))}`
                                            }
                                        </Typography>
                                    </div>
                                :
                                    <LoadingComponent visibleElements='circle' />
                    }
                </div>
            </div>
            <BigFooterNoLinks user={user} />
        </div>
    )
}

export default UserProfile
