import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import TextField from '@mui/material/TextField';
// import MenuItem from '@mui/material/MenuItem';
import { MenuItem } from '@material-ui/core';
import './userSystemSelectForm.css'
import { useTheme } from '@material-ui/styles';
import UserSystemSelectFormItem from '../UserSystemSelectFormItem/UserSystemSelectFormItem';

const UserSystemSelectForm = ({ userSystems, availableSystems, currentUserSystemId, isDisabled=false, setFormValues=()=>{} }) => {
    const { t } = useTranslation('common', { keyPrefix: 'userSystemSelectForm'})
    const [contentSystemEditForm, setContentSystemEditForm] = useState({
        selectedSystemIndex: '',
    });

    const onChange = (e) => {
        e.preventDefault();
        setContentSystemEditForm((contentSystemEditForm) => ({...contentSystemEditForm, [e.target.name]: e.target.value}))
    }

    useEffect(() => {
        if(contentSystemEditForm.selectedSystemIndex !== ''){
            const system = userSystems[contentSystemEditForm.selectedSystemIndex]
            const updatedFormValues = {
                selectedDeviceName : system.deviceName,
                selectedSoftwareName : system.softwareName,
                selectedUserSystemId : system.id,
            };
            setFormValues(updatedFormValues)
        }
    },[contentSystemEditForm])

    return (
        <>
            <Grid item className='class-content-system-select-form-item'>
                <TextField 
                label={t("selectYourSystem")}
                name='selectedSystemIndex'
                value={contentSystemEditForm.selectedSystemIndex}
                onChange={onChange} 
                inputProps={{style:{textOverflow:'ellipsis'}}}
                select 
                fullWidth
                variant='standard'
                disabled= {isDisabled}
                required
                >
                    {userSystems.map( (system, index) => {
                        let softwareAvail = availableSystems.softwareArray.find(software => software.softwareName.toLowerCase() === system.softwareName.toLowerCase()) != null;
                        let deviceAvail = availableSystems.deviceArray.find(device => device.deviceName.toLowerCase() === system.deviceName.toLowerCase()) != null;
                        const isSystemAvail = softwareAvail && deviceAvail;
                        const isSelected = currentUserSystemId && system.id === currentUserSystemId ;
                        return(
                            <MenuItem key={index} value={index} disabled={!isSystemAvail}>
                                <UserSystemSelectFormItem isIncompatible={!isSystemAvail} isSelected={isSelected} system={system} />
                            </MenuItem>
                        )
                    })}
                </TextField>
            </Grid>
        </>
    )
}

export default UserSystemSelectForm