import { useCallback, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import VideoPlayer from '../VideoPlayer/VideoPlayer';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import { defaultSchema } from 'rehype-sanitize';
import remarkGfm from 'remark-gfm'; // Could be removable
import remarkUnwrapImages from 'remark-unwrap-images'
import ContentDescriptionKeyPoints from '../ContentDescriptionKeyPoints/ContentDescriptionKeyPoints';
import ContentDescriptionActions from '../ContentDescriptionActions/ContentDescriptionActions';
import ContentDownloadElements from '../ContentDownloadElements/ContentDownloadElements';
import ButtonContentComplete from '../ButtonContentComplete/ButtonContentComplete';
import MeetingWidget from '../MeetingWidget/MeetingWidget';

// Style
import './classContentLecture.css'
import { getFromSafeObject, isNotEmptyObject, textTransform } from '../../aux/aux';
import { getReliableLocale } from '../../aux/sessionHelpers';
import MarkdownDisplay from '../MarkdownDisplay/MarkdownDisplay';
import { useLocaleCtx } from '../../customHooks/useLocaleCtx';
import { useMemo } from 'react';

const customSanitizeSchema = {
      ...defaultSchema,
      attributes: {
       ...defaultSchema.attributes,
        p: [
          ...(defaultSchema.attributes.p || []),
          ['className']
        ]
    }
}

const ClassContentLecture = ({ userId, lessonContent, userLessonProgress, userLessonTypeProgress, userLessonActionProgressMap, updateUserLessonProgressMap, userConfig, updateUserVideoConfig, setShowCongrats }) => {
    const localeCtx = useLocaleCtx();
    const LESSON_TYPE_WITH_COMPLETION_AFTER_DISPLAY = ['reading', 'download']
    const theme = useTheme();
    const { t } = useTranslation('classroom', { keyPrefix: 'classContentLecture'});
    const abortControllerRef = useRef(null);
    const meetingWidgetRefMap = useRef({});
    
    const VIDEO_PLAYER_CONFIG = {
        updatingThresholdSec: 5,
        minimumViewing: 0.9,
        heightString: '100%' // Prevent from proportional padding-top in the player
    };
    const styles = {
        divider : {
            backgroundColor: theme.palette.grey[100]
        },
    }
    const markdownComponentsMapping = {
        h1: (node, ...props) => <><Typography variant="h5" {...node.node.properties} children={node.children} /> <Divider style={ styles.divider }/> </>,
        h2: (node, ...props) => <Typography variant="h6" {...node.node.properties} children={node.children} />,
        p:  (node, ...props) => <Typography variant="body1" {...node.node.properties} children={node.children}/>,
        li: (node, ...props) => <li><Typography variant="body1" {...node.node.properties} children={node.children}/></li>,
        hr: (node, ...props) => <Divider style={ styles.divider } />
    };
    
    const isAvailCompleteButton = useMemo(() => {
        let showIt = false;
        if(isNotEmptyObject(lessonContent)){
            if(isNotEmptyObject(userLessonTypeProgress)){
                let isVideoContentNotCompleted;
                const videoIdsStructure =  getFromSafeObject(lessonContent, 'contentStructure.videoIdsStructure');
                const hasVideoContent = videoIdsStructure != null && videoIdsStructure.length > 0;
                if(hasVideoContent){
                    const videoIdWithMinTimeRequired = []; 
                    Object.entries(lessonContent.contentStructure.videoDetailsMap).forEach(([videoId, videoDetails]) => {
                        if(videoDetails.minimumViewedFraction > 0.0){
                            videoIdWithMinTimeRequired.push(videoId)
                        }
                    })
                    const minTimeRequired = videoIdWithMinTimeRequired.length !== 0;
                    if(minTimeRequired){
                        isVideoContentNotCompleted = videoIdWithMinTimeRequired.find( videoId => {
                            let videoProgress = userLessonTypeProgress.videoProgressDetailsMap?.[videoId];
                            let videoDetails = lessonContent.contentStructure.videoDetailsMap[videoId];
                            if(isNotEmptyObject(videoProgress) && isNotEmptyObject(videoDetails)){
                                let noMinimumViewed = videoProgress.viewedFraction < videoDetails.minimumViewedFraction;
                                let noMilestoneReached = videoProgress.milestoneReached === false;
                                return noMilestoneReached || noMinimumViewed
                            }else{
                                return true
                            }
                        }) != null
                    }
                }
                let isLiveContentNotCompleted;
                const liveIdsStructure = getFromSafeObject(lessonContent, 'contentStructure.liveIdsStructure');
                const hasLiveContent = liveIdsStructure != null && liveIdsStructure.length > 0;
                if(hasLiveContent){
                    isLiveContentNotCompleted = Object.entries(userLessonTypeProgress.liveProgressDetailsMap).find(([liveId, liveProgress]) => {
                        return liveProgress.completionDate === null
                    }) != null
                }
                showIt = (hasVideoContent ? !isVideoContentNotCompleted : true) &&  (hasLiveContent ? !isLiveContentNotCompleted : true)
            }else{
                if(LESSON_TYPE_WITH_COMPLETION_AFTER_DISPLAY.includes(lessonContent.lessonTypeName)){
                    showIt = true;
                }
            }
        }
        return showIt
    },[lessonContent, userLessonTypeProgress]);
    
    const markCompletionDate = async (date, signal) => {
        try{
            const updatedValues = {
                completionDate: date
            }
            updateUserLessonProgressMap.updateUserLessonProgress(lessonContent.id, lessonContent.version.lessonVersionId, updatedValues, signal);
        }catch(err){
            console.log(err)
        }
    }

    const onCompleteLecture = async (markAsCompleted, signal=abortControllerRef.current.signal) => {
        const now = new Date();
        const updatedValues = {
            lastAccessDate: now,
            markAsCompleted,
        };
        return updateUserLessonProgressMap.updateUserLessonProgress(lessonContent.id, lessonContent.version.lessonVersionId, updatedValues, signal);
    };

    const onViewingUpdate = (videoId, updatedVideoViewingState, signal=abortControllerRef.current.signal, backup=true) => {
        updateUserLessonProgressMap.updateUserLessonTypeProgress('video', videoId, updatedVideoViewingState, backup, signal);
    }

    const onUserMeetingProgressUpdate = (liveId, updatedUserMeetingProgressValues, signal=abortControllerRef.current.signal, backup=true) => {
        updateUserLessonProgressMap.updateUserLessonTypeProgress('live', liveId, updatedUserMeetingProgressValues, backup, signal);
    }

    const onActionUpdate = (actionType, actionId, actionState, signal=abortControllerRef.current.signal) => {
        updateUserLessonProgressMap.updateUserLessonActionProgress(actionType, actionId, actionState, signal);
    }

    const onPlayerConfigUpdate = (videoId, playerState, signal=abortControllerRef.current.signal) => {
        updateUserVideoConfig(videoId, playerState, signal);
    }

    useEffect(() => {
        if(isAvailCompleteButton && userLessonProgress.completionDate == null){
            const abortController = new AbortController();
            markCompletionDate(new Date(), abortController.signal);
            return(() => abortController.abort())
        }
    },[isAvailCompleteButton, userLessonProgress?.completionDate])

    useEffect(()=>{
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        const updateMeetingWidget = (e) => {
            const data = e.detail;
            if(data.hasOwnProperty('opType')){
                const [op, component] = data.opType.split('_');
                switch(op){
                    case 'refresh':
                        const userMeetingIdArray = data.userMeetingIdArray || [];
                        userMeetingIdArray.forEach( userMeetingId  => {
                            const meetingWidgetRef = meetingWidgetRefMap.current[userMeetingId];
                            if(meetingWidgetRef){
                                meetingWidgetRef.refresh(component);
                            }
                        })
                        break;
                    default:
                        break;
                }
            }
        }
        document.addEventListener('meeting', updateMeetingWidget)
        return(() => {
            abortController.abort();
            document.removeEventListener('meeting', updateMeetingWidget);
            // console.log('unmounting lesson')
        })
    },[]);
    return (
        <>
            <div className="class-content-lecture-main-container">
                {/* Video Container */}
                {lessonContent.contentStructure.hasOwnProperty('videoIdsStructure') && lessonContent.contentStructure.videoIdsStructure.length > 0 &&
                    <div className='class-content-lecture-video-container'>
                        {lessonContent.contentStructure.videoIdsStructure.map( videoId => {
                            let video = getFromSafeObject(lessonContent,`contentStructure.videoDetailsMap.${videoId}`);
                            let userVideoProgress = getFromSafeObject(userLessonTypeProgress, `videoProgressDetailsMap.${videoId}`) || {};
                            if(isNotEmptyObject(video)){
                                return(
                                    <div key={videoId} className='class-content-lecture-video class-content-lecture-centered-item'>
                                        <VideoPlayer videoId={videoId} video={video} userVideoConfig={userConfig} userVideoProgress={userVideoProgress} 
                                        onPlayerConfigUpdate={onPlayerConfigUpdate} onViewingUpdate={onViewingUpdate} config={{...VIDEO_PLAYER_CONFIG, ...(video.minimumViewedFraction ? {minimumViewing: video.minimumViewedFraction}:{})}} />
                                    </div>
                                )    
                            }
                        })}
                    </div>
                }
                {/* Live Container */}
                {lessonContent.contentStructure.hasOwnProperty('liveIdsStructure') && lessonContent.contentStructure.liveIdsStructure.length > 0 && 
                    <div className='class-content-lecture-live-container'>
                    {lessonContent.contentStructure.liveIdsStructure.map( liveId => {
                        let live = getFromSafeObject(lessonContent,`contentStructure.liveDetailsMap.${liveId}`);
                        let userMeetingProgress = getFromSafeObject(userLessonTypeProgress, `liveProgressDetailsMap.${liveId}`) // userLessonTypeProgress?.liveProgressDetailsMap[liveId];
                        if(isNotEmptyObject(live)){
                            let meetingId = live.description?.meetingId;
                            let userMeetingId = userMeetingProgress?.userMeetingId;
                            return(
                                <div key={liveId} className='class-content-lecture-live class-content-lecture-centered-item'>
                                    <MeetingWidget
                                        ref={ element => meetingWidgetRefMap.current[userMeetingId] = element }
                                        id={liveId} 
                                        userId={userId} 
                                        meetingId={meetingId} 
                                        userMeetingId={userMeetingId}
                                        translationLocaleId={userConfig.localeId}
                                        isAlwaysOpen={live.alwaysOpen}
                                        onUserMeetingProductProgressUpdate={onUserMeetingProgressUpdate}    
                                    />
                                </div>
                            )    
                        }
                    })}
                </div>
                }
                {/* Title Container */}
                <div className='class-content-lecture-title-container'>
                    {/* Content Title */}
                    {lessonContent.description.hasOwnProperty('title') &&
                        <Typography variant="h5" className='class-content-lecture-title'>
                            {textTransform('title', lessonContent.description.title)}
                        </Typography>
                    }

                    {/* Content Description */}
                    {lessonContent.description.hasOwnProperty('description') &&
                        <Typography variant="body1" className='class-content-lecture-description'>
                            {textTransform('title', lessonContent.description.description)}
                        </Typography>
                    }
                </div>
                <Divider variant='middle'/>
                {/* Lecture except video Container */}
                <div className='class-content-lecture-except-video-and-title-container'>
                    {/* Reading Content */}
                    <ReactMarkdown children={lessonContent.description.text} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, [rehypeSanitize, customSanitizeSchema]]} 
                        className='class-content-lecture-reading-container-main' components={markdownComponentsMapping} includeElementIndex={true}
                    />

                    {/* Downloadable Content */}
                    {lessonContent.contentStructure.hasOwnProperty('downloadIdsStructure') && lessonContent.contentStructure.downloadIdsStructure.length > 0 &&
                        <ContentDownloadElements userId={userId} contentProductId={lessonContent.contentProductId} downloadIdsStructure={lessonContent.contentStructure.downloadIdsStructure}
                        downloadDetailsMap={lessonContent.contentStructure.downloadDetailsMap} title={textTransform('title', t("filesToDownload"))} 
                        className='class-content-lecture-download-container'/>
                    }

                    {/* Content Key Points */}
                    {lessonContent.contentStructure.hasOwnProperty('keyPointDetailsArray') && lessonContent.contentStructure.keyPointDetailsArray.length > 0 && 
                        <ContentDescriptionKeyPoints keyPoints={lessonContent.contentStructure.keyPointDetailsArray} title={textTransform('title', t("common:keyPoint", { count: 2}))} 
                        className='class-content-lecture-key-points-container'/>
                    }

                    {/* Content Actions */}
                    {lessonContent.contentStructure.hasOwnProperty('actionIdsStructure') && lessonContent.contentStructure.actionIdsStructure.length > 0 && 
                        <ContentDescriptionActions actionIdsStructure={lessonContent.contentStructure.actionIdsStructure} actionDetailsMap={lessonContent.contentStructure.actionDetailsMap}
                        userActionProgressMap={userLessonActionProgressMap} onActionUpdate={onActionUpdate} title={textTransform('title',t("common:suggestedAction", { count:2 }))} 
                        className='class-content-lecture-actions-container'/>
                    }
                    
                    {/* Button to complete */}
                    <ButtonContentComplete className='class-content-lecture-complete-button class-content-lecture-centered-item' 
                    isDisabled={!isAvailCompleteButton} isCompleted={userLessonProgress.markAsCompleted} onCompleteLecture={onCompleteLecture} setShowModal={setShowCongrats} />
                </div>
            </div>
        </>
    )
}

export default ClassContentLecture
