import PopUpModal from "../PopUpModal/PopUpModal";
import PopUpModalHeader from "../PopUpModalHeader/PopUpModalHeader";
import { WarningAmber } from "@mui/icons-material";
import SimpleNoModalDialog from "../SimpleNoModalDialog/SimpleNoModalDialog";
import './popUpDeleteSimpleDialog.css'

const PopUpDeleteSimpleDialog = ({ show, title, warningMsg, onDelete, onCancel, isLoading, isError, errorMsg }) => {
    const POP_UP_WIDTH = 380;
    return (
        <PopUpModal
        showModal={show}
        isDialog={true}
        width={POP_UP_WIDTH}
        > 
            <PopUpModalHeader title={title} Icon={WarningAmber} />
            <SimpleNoModalDialog
                style={{padding:'20px'}}
                contentText={warningMsg}
                onDisagree={onCancel}
                onAgree={onDelete}
                isLoading={isLoading}
                isError={isError}
                errorMsg={errorMsg}
            />
        </PopUpModal>
    )
}

export default PopUpDeleteSimpleDialog