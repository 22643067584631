/**
 * This module contains useful helpers to deal with session variables on the browser
 */
import axios from 'axios';
import { publicRequest } from './requestMethods';
import tzCityToCountry from '../geoInfo/tzCityToCountry.json'
import { getAvailCountry, getAvailCurrency, getAvailState, getDefaultLocale, getLocales } from '../services/staticContentServices';
import { isNotEmptyObject } from './aux';
//
import { I18N_AVAIL_LOCALECODE_ARRAY, I18N_DEFAULT_LOCALECODE } from '../../i18n';
import { triggerEventWithData } from './internalCommunicationHelpers';

let storage = localStorage;
const ipAndBrowserVariablesStorage = sessionStorage;
const HOST_PREFIX = 'qxi';
const DEFAULT_CURRENCY_ID = 'eur';
const DEFAULT_COUNTRY_CODE = 'ES';
const DEFAULT_LANGUAGE_ID = 'es';
const DEFAULT_LOCALE_ID = 1;
const DEFAULT_STATE_CODE = null;
const DEFAULT_AVAIL_LOCALE_MAP = {
    [DEFAULT_LANGUAGE_ID]:[{
        id: DEFAULT_LOCALE_ID,
        languageId: DEFAULT_LANGUAGE_ID,
        countryCode: DEFAULT_COUNTRY_CODE,
        isDefault: true
    }]
}

function setStorage(type){
    switch(type){
        case('session'):
            storage = sessionStorage;
            break;
        default:
            storage = localStorage;
    }
};

const variableNames = {
    paymentIntentId: 'paymentIntentId',
    purchaseOption: 'purchaseOption',
    userPurchaseSession: 'userPurchaseSession',
    accessToken: 'token',
    refreshToken: 'refreshToken',
    userLocaleId: 'userLocaleId',
    userLocaleCode: 'userLocaleCode',
    userId: 'userId', // Added 02/24
    userCountryCode: 'userCountryCode', // Added 05/12
    userStateCode: 'userStateCode', // Added 12/29
    userCurrencyId: 'userCurrencyId', // Added 05/12
};

const ipVariableNames = {
    currencyId: 'currencyId',
    localeCode: 'localeCode',
    localeId: 'localeId',
    availLocaleMap: 'availLocaleMap', // Added 06/20
    countryCode: 'countryCode',
    stateCode: 'stateCode' // Added 12/29
    // Removed 06/20
    // inEU: 'inEU',
    // languageId: 'languageId', 
};

// const browserVariableNames = {
//     browserLocale: 'browserLocale'
// };

// const ipVariableNames = {
//     ... ipVariableNames,
//     ... browserVariableNames
// };

function sessionVariableReviver(key, value){
    let regExp;
    if(typeof value === 'string'){
        if(value.toLowerCase() === 'null'){
            return null;
        }
    }
    return value;
}

function setHostPrefix(name){
    return `${HOST_PREFIX}_${name}`;
}

function get(name){
    const key = setHostPrefix(variableNames[name]);
    const value = storage.getItem(key);
    return sessionVariableReviver(key, value);
};

function set(name, value){
    if(name in variableNames){
        const key = setHostPrefix(variableNames[name]);
        storage.setItem(key, value);
    }
};

function remove(name){
    if(name in variableNames){
        const key = setHostPrefix(variableNames[name]);
        storage.removeItem(key);
    }
};

function hasOwnProperty(name){
    if(name in variableNames){
        const key = setHostPrefix(variableNames[name]);
        return storage.hasOwnProperty(key);
    }else{
        return false;   
    }
};

const sessionVariables = {
    get,
    set,
    remove,
    hasOwnProperty
};

const getIPAndBrowserVariable = (name, isObject=false) => {
    const key = setHostPrefix(ipVariableNames[name]);
    const value = ipAndBrowserVariablesStorage.getItem(key);
    return isObject ? JSON.parse(value) : sessionVariableReviver(key, value);
};

const setIPAndBrowserVariable = (name, value, isObject=false) => {
    if( name in ipVariableNames){
        const key = setHostPrefix(ipVariableNames[name]);
        const valueToKeep = isObject ? JSON.stringify(value) : value;
        ipAndBrowserVariablesStorage.setItem(key, valueToKeep);
    }
};

function removeIPSessionVariable(name){
    if(name in ipVariableNames){
        const key = setHostPrefix(ipVariableNames[name]);
        ipAndBrowserVariablesStorage.removeItem(key);
    }
};

function hasOwnPropertyIPSessionVariable(name){
    if(name in ipVariableNames){
        const key = setHostPrefix(ipVariableNames[name]);
        return ipAndBrowserVariablesStorage.hasOwnProperty(key);
    }else{
        return false;   
    }
};

const ipSessionVariables = {
    get: getIPAndBrowserVariable,
    set: setIPAndBrowserVariable,
    remove: removeIPSessionVariable,
    hasOwnProperty: hasOwnPropertyIPSessionVariable
}

// storage event callback
const onSessionUpdate = (e) => {
    const {
        key, 
        newValue,
        oldValue,
        storageArea
    } = e;
    if(key.startsWith(HOST_PREFIX)){
        if(key.endsWith('tabMessage')){
            // spread Event passed by tabMessage 
            // from a different tab
            const { eventType, data } = JSON.parse(newValue) || {}; 
            if(eventType && data){
                triggerEventWithData(eventType, data);
            }
        }else{
            // Show popup to suggest reload to
            // keep state consistency between tabs
            window.location.reload();
        }
    }
};

const getSessionGeoInfoFromServiceOrNull = async (signal=null) => {
    try{
        const REQUEST_TIMEOUT = 2000
        // hardcoded since geolocationAPI allows request from localhost
        const usingCORSAnywhereProxy = false;
        const sessionGeoInfo = {}
        const anywhereCORSProxy = process.env.REACT_APP_CORS_PROXY;
        const geolocationAPI = process.env.REACT_APP_GEOLOCATION_API;
        let geoLocationAPIRequestURL;
        if(usingCORSAnywhereProxy){
            // we need client ip to get the proper location since
            // the request is passing through an CORS proxy
            const url = new URL (process.env.REACT_APP_GETIP_API);
            url.searchParams.append('format', 'json');
            const res = await axios.get(
                url, 
                {
                    timeout:REQUEST_TIMEOUT
                }
            );
            const ip = res.data.ip;
            geoLocationAPIRequestURL = anywhereCORSProxy + geolocationAPI + `${ip}/`;
        }else{
            geoLocationAPIRequestURL = geolocationAPI;
        }
        geoLocationAPIRequestURL += 'json/';
        const url = new URL(geoLocationAPIRequestURL);
        const res = await axios.get(
            url, 
            {
                timeout:REQUEST_TIMEOUT,
                signal
            }
        );
        const data = res.data
        if(data){
            const geoInfoVariables = {
                countryCode: 'country_code',
                stateCode: 'region_code',
                inEU: 'in_eu',
                languageId: 'languages',
            };
            Object.entries(geoInfoVariables).forEach( ([key, resKey], ) => {
                let value 
                if(resKey === 'languages'){
                    value = data[resKey].split(',')[0];
                }else if(resKey === 'region_code'){
                    value = data[resKey] || null;
                }else{
                    value = data[resKey];
                }
                sessionGeoInfo[key] = value 
            })
        }
        return sessionGeoInfo;
    }catch(error){
        console.info(`Geo info api no available`);
        return null
    }
};

const getSessionGeoInfoFromBrowserOrNull = () => {
    try{
        const browserDateTimeFormat = Intl.DateTimeFormat().resolvedOptions()
        const localeCode = browserDateTimeFormat.locale;
        const languageId = localeCode.split(/-|_/)[0]
        const userTimeZone = browserDateTimeFormat.timeZone;
        const tzArr = userTimeZone.split("/");
        const userRegion = tzArr[0];
        const userCity = tzArr[tzArr.length - 1];
        const userCountry = tzCityToCountry[userCity];
        const inEU = userRegion.toLocaleLowerCase() === 'europe';
        return { 
            countryCode: userCountry.countryCode, 
            stateCode: userCountry.stateCode, 
            inEU, 
            languageId 
        }
    }catch(error){
        console.log(`Geo info from browser no available`);
        return null
    }
}

const getBrowserLocalesOrUndefined = (options = {}) => {
    let locales = undefined;
    const defaultOptions = {
      languageCodeOnly: false,
    };
    const opt = {
      ...defaultOptions,
      ...options,
    };
    const browserLocales = navigator.languages === undefined ? [navigator.language] : navigator.languages;
    if (browserLocales) {
        locales = browserLocales.map(locale => {
            const trimmedLocale = locale.trim();
            return opt.languageCodeOnly
                ? trimmedLocale.split(/-|_/)[0]
                : trimmedLocale;
        });
    }
    return locales
};

function getReliableLocale(){
    let localeId, localeCode;
    if(sessionVariables.hasOwnProperty('userLocaleId') && sessionVariables.hasOwnProperty('userLocaleCode')){
    // User defined
        localeId = sessionVariables.get('userLocaleId');
        localeCode = sessionVariables.get('userLocaleCode');
    }else{
        localeId = ipSessionVariables.get('localeId');
        localeCode = ipSessionVariables.get('localeCode');
    }
    return {localeId, localeCode};
};

function getReliableCurrencyId(){
    let currencyId;
    if(sessionVariables.hasOwnProperty('userCurrencyId')){
        currencyId = sessionVariables.get('userCurrencyId');
    }else{
        currencyId = ipSessionVariables.get('currencyId');
    }
    return currencyId;
}

function getReliableCountryCode(){
    let countryCode;
    if(sessionVariables.hasOwnProperty('userCountryCode')){
        countryCode = sessionVariables.get('userCountryCode');
    }else{
        countryCode = ipSessionVariables.get('countryCode');
    }
    return countryCode;
}

function getReliableStateCode(){
    let stateCode;
    if(sessionVariables.hasOwnProperty('userStateCode')){
        stateCode = sessionVariables.get('userStateCode');
    }else{
        stateCode = ipSessionVariables.get('stateCode');
    }
    return stateCode;
}

const LOCALECODE_SEPARATOR = '-';
const getLocaleCode = ({ languageId, countryCode }) => {
    return `${languageId}${countryCode != null ? `${LOCALECODE_SEPARATOR}${countryCode}` : ''}`
}

const getLanguageIdOrNull= (localeCode) => {
    return localeCode ? localeCode.split(LOCALECODE_SEPARATOR)[0] : null;
}


const getCountryCodeOrNull = (localeCode) => {
    let countryCode = null
    if(typeof localeCode === 'string'){
        const localeComponents = localeCode.split(LOCALECODE_SEPARATOR);
        countryCode = localeComponents.length === 2 ? localeComponents[1] : null
    }
    return countryCode
}

// const getAPIAvailLocale = async (localeCandidatesList, signal=null) => {
//     try{
//         let apiAvailLocale;
//         const res = await getLocales({}, 0, signal);
//         const {
//             localeArrayByLanguageIdMap
//         } = res.data;
//         for(let localeIndex = 0; localeIndex < localeCandidatesList.length; localeIndex++){
//             let defaultLocaleForLanguage;
//             const [ languageId, countryCode ]= localeCandidatesList[localeIndex].split(LOCALECODE_SEPARATOR);
//             const localeArray = localeArrayByLanguageIdMap[languageId];
//             if(localeArray){
//                 for(let index = 0; index < localeArray.length; index++){
//                     const locale = localeArray[index];
//                     if(locale.countryCode.toUpperCase() === countryCode){
//                         apiAvailLocale = {
//                             localeId: locale.id,
//                             localeCode: getLocaleCode(locale)
//                         };
//                         break;
//                     }else if(locale.isDefault){
//                         defaultLocaleForLanguage = locale;
//                     }
//                 }
//             }else{
//                 break;
//             }
//             if(apiAvailLocale){
//                 break;
//             }else if(defaultLocaleForLanguage){
//                 apiAvailLocale = {
//                     localeId : defaultLocaleForLanguage.id,
//                     localeCode : getLocaleCode(defaultLocaleForLanguage)
//                 };
//                 break;
//             }
//         }
//         if(!apiAvailLocale){
//             const res = await getDefaultLocale(0, signal)
//             const defaultLocale = res.data.locale;
//             apiAvailLocale = {
//                 localeId : defaultLocale.id,
//                 localeCode: getLocaleCode(defaultLocale)
//             }
//         }
//         return apiAvailLocale;
//     }catch(error){
//         // TODO: API is not available
//         console.info(error)
//     }
// }

const getAvailLocaleMap = (apiAvailLocaleMap, i18nAvailLocaleCodeArray) => {
    const availLocaleMap = {};
    for( let i18nlocaleCode of i18nAvailLocaleCodeArray ){
        const i18nLanguageId = getLanguageIdOrNull(i18nlocaleCode);
        const i18nCountryCode = getCountryCodeOrNull(i18nlocaleCode);
        if(i18nLanguageId != null){
            const apiLocaleArray = apiAvailLocaleMap[i18nLanguageId];
            const appAvailLocale = apiLocaleArray.find( locale => locale.countryCode === i18nCountryCode );
            if(appAvailLocale){
                if(availLocaleMap.hasOwnProperty(i18nLanguageId)){
                    availLocaleMap[i18nLanguageId].push(appAvailLocale);
                }else{
                    availLocaleMap[i18nLanguageId] = [ appAvailLocale ];
                }
            }
        }
    }
    return availLocaleMap;
}

const getAvailDefaultLocale = (apiDefaultLocale, i18nDefaultLocaleCode) => {
    const sameLocale = getLocaleCode(apiDefaultLocale) === i18nDefaultLocaleCode;
    if(!sameLocale){
        console.info('API and client does not have same default locale. API default is set and client is expected to rely on fallbackLng')
    }
    return apiDefaultLocale;
}

const getLocaleOrNull = (localeCandidatesList, availLocaleMap) => {
    let apiAvailLocale = null;
    for(let localeIndex = 0; localeIndex < localeCandidatesList.length; localeIndex++){
        const [ languageId, countryCode ]= localeCandidatesList[localeIndex].split(LOCALECODE_SEPARATOR);
        const localeArray = availLocaleMap[languageId];
        if(localeArray){
            for(let index = 0; index < localeArray.length; index++){
                const locale = localeArray[index];
                if(locale.countryCode === countryCode){
                    apiAvailLocale = {
                        localeId: locale.id,
                        localeCode: getLocaleCode(locale)
                    };
                    break;
                }else if(locale.isDefault){
                    apiAvailLocale = {
                        localeId : locale.id,
                        localeCode : getLocaleCode(locale)
                    };
                    break;
                }
            }
        }else{
            continue;
        }
    }
    return apiAvailLocale;
}

const getSessionGeoInfoOrNull = async (signal) => {
    let sessionGeoInfo = await getSessionGeoInfoFromServiceOrNull(signal) || getSessionGeoInfoFromBrowserOrNull();
    return sessionGeoInfo
}

const getAPIAvailLocaleMap = async (signal) => {
    try{
        const res = await getLocales({}, 0, signal)
        const {
            localeArrayByLanguageIdMap
        } = res.data;
        return localeArrayByLanguageIdMap;
    }catch(error){
        // TODO: Custom Error
        // API is not available
        throw new Error('API not reachable to get avail locale map')
    }
}

const getAPIDefaultAvailLocale = async (signal) => {
    try{
        const res = await getDefaultLocale(0, signal);
        const {
            locale
        } = res.data;
        return locale;
    }catch(error){
        // TODO: Custom Error
        // API is not available
        throw new Error('API not reachable to get avail default locale')
    }
}
const getAPIAvailCurrency = async (countryCode=null, signal) => {
    try{
        const res = await getAvailCurrency(countryCode, 0, signal);
        return res.data.currency || res.data.defaultCurrency; 
    }catch(error){
        // TODO: Custom error
        // API is not available
        throw new Error('API not reachable to get avail currency')
    }
}
const getAPIAvailCountry = async (localeId, countryCode=null, signal) => {
    try{
        const res = await getAvailCountry(localeId, countryCode,  0, signal);
        return res.data.country || res.data.defaultCountry;
    }catch(error){
        // TODO: Custom error
        // API is not available
        throw new Error('API not reachable to get avail country')
    }
}
const getAPIAvailState = async (countryCode, stateCode, signal) => {
    try{
        const res = await getAvailState(countryCode, stateCode, 0, signal);
        return res.data.state || res.data.defaultState
    }catch(error){
        // TODO: Custom error
        // API is not available
        throw new Error('API not reachable to get avail state')
    }
}


// const setVisitorLocaleAndCurrency = async (signal=null) => {
//     try{
//         const sessionGeoInfo = await getSessionGeoInfoOrNull(signal);
//         const browserLocales = getBrowserLocalesOrUndefined();
//         if(sessionGeoInfo){
//             browserLocales.push(sessionGeoInfo.languageId)
//         }
//         const api await getAPIAvailLocaleMap(signal);
//         const res = await getAvailCurrency(browserLocales.countryCode, 0, signal);
//         const currency = res.data.currency || res.data.defaultCurrency;  
//         const locale = await getAPIAvailLocale(browserLocales, signal);
//         if(locale && currency){
//             setIPAndBrowserVariable('currencyId', currency.id);
//             setIPAndBrowserVariable('localeId', locale.localeId);
//             setIPAndBrowserVariable('localeCode', locale.localeCode);
//             Object.entries(sessionGeoInfo).forEach( ([key, value], ) => {
//                 setIPAndBrowserVariable(key, value);
//             });
//         }else{
//             // TODO: Custum Error
//             // API is not available
//             throw new Error();
//         }
//     }catch(error){
//         // TODO: implement error handling
//     }

// }

const setVisitorLocaleAndCurrencyAndCountry = async (signal=null) => {
    try{
        const sessionGeoInfo = await getSessionGeoInfoOrNull(signal);
        const browserLocales = getBrowserLocalesOrUndefined();
        // if(sessionGeoInfo){
        //     browserLocales.push(sessionGeoInfo.languageId);
        // }
        const apiAvailLocaleMap = await getAPIAvailLocaleMap(signal);
        const availLocaleMap = getAvailLocaleMap(apiAvailLocaleMap, I18N_AVAIL_LOCALECODE_ARRAY);
        let locale = getLocaleOrNull(browserLocales, availLocaleMap);
        if(!locale){
            const apiDefaultLocale = await getAPIDefaultAvailLocale(signal);
            const defaultLocale = getAvailDefaultLocale(apiDefaultLocale, I18N_DEFAULT_LOCALECODE);
            locale = {
                localeId : defaultLocale.id,
                localeCode: getLocaleCode(defaultLocale)
            };
        }
        const country = await getAPIAvailCountry(locale.localeId, sessionGeoInfo.countryCode, signal);
        const state = await getAPIAvailState(country.code,  sessionGeoInfo.stateCode, signal);
        const currency =  await getAPIAvailCurrency(sessionGeoInfo.countryCode, signal);
        if(locale && currency && country && state){
            ipSessionVariables.set('currencyId', currency.id);
            ipSessionVariables.set('localeId', locale.localeId);
            ipSessionVariables.set('countryCode', country.code);
            ipSessionVariables.set('stateCode', state.stateCode);
            ipSessionVariables.set('localeCode', locale.localeCode);
            ipSessionVariables.set('availLocaleMap', availLocaleMap, true);
        }else{
            // TODO: Custum Error
            // API is not available
            throw new Error('API not reachable to set visitor locale, country and currency')
        }
    }catch(error){
        // TODO: implement error handling
        throw error
    }
}

const setVisitorFallbackLocaleAndCurrencyAndCountry = () => {
    ipSessionVariables.set('countryCode', DEFAULT_COUNTRY_CODE);
    ipSessionVariables.set('stateCode', DEFAULT_STATE_CODE);
    ipSessionVariables.set('currencyId', DEFAULT_CURRENCY_ID);
    ipSessionVariables.set('localeCode', I18N_DEFAULT_LOCALECODE);
    ipSessionVariables.set('localeId', DEFAULT_LOCALE_ID);
    ipSessionVariables.set('availLocaleMap', DEFAULT_AVAIL_LOCALE_MAP, true);
}

const isReadyForVisitors = () => {
    let existVariable
    for(let name in ipVariableNames){
        existVariable = ipSessionVariables.hasOwnProperty(name);
        if(!existVariable){
            break
        }
    };
    return existVariable;
}

const hasUserSessionTokens = () => {
    return sessionVariables.hasOwnProperty('accessToken') && sessionVariables.hasOwnProperty('refreshToken')
}


const clearUserSessionVariables = () => {
    Object.keys(variableNames).forEach( name => remove(name));
}

const clearSessionVariables = () => {
    storage.clear();
}
// Implemented: 05/05
// Replace isLogged()
// Note: It is not totally checked
const isUserLogged = (user) => {
    const hasTokens = hasUserSessionTokens();
    const hasUserId = sessionVariables.hasOwnProperty('userId');
    const hasUserData = user !== null && isNotEmptyObject(user);
    return hasTokens && hasUserId && hasUserData
}

const getCookieByNameOrUndefined = (cookieName) => {
    const cookieRegEx = new RegExp(`${cookieName}\s*\=\s*([^;]*)`)
    const result = document.cookie.match(cookieRegEx)
    return result ? result[1] : undefined;
}

const setTabMessage = (eventType, data, timestamp=new Date()) => {
    const key = setHostPrefix('tabMessage');
    const value = JSON.stringify({data, eventType, timestamp});
    localStorage.setItem(key, value);
}

const getTabMessage = () => {
    const key = setHostPrefix('tabMessage');
    const value = localStorage.getItem(key);
    return JSON.parse(value) || undefined;
}

const tabMessageApi = {
    set: setTabMessage,
    get: getTabMessage
}

export {
    setStorage,
    sessionVariables,
    ipSessionVariables,
    tabMessageApi,
    onSessionUpdate,
    isReadyForVisitors,
    hasUserSessionTokens,
    setVisitorLocaleAndCurrencyAndCountry,
    setVisitorFallbackLocaleAndCurrencyAndCountry,
    getReliableLocale,
    getReliableCurrencyId,
    getReliableStateCode,
    clearSessionVariables,
    clearUserSessionVariables,
    isUserLogged,
    getCookieByNameOrUndefined,
    getLanguageIdOrNull,
    getCountryCodeOrNull,
    getReliableCountryCode,
    getLocaleCode
};