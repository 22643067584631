import { Grid, Typography, Box, Button, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/styles';
import { useEffect, useRef, useState } from 'react';
import Ribbon from '../../img/ribbon_24x42.svg'
import { fromCentTo2dec, priceToString } from '../../aux/purchaseHelpers';
import SubscriptionIcon from "../SubscriptionIcon/SubscriptionIcon";
import { getFromSafeObject, isNotEmptyObject } from '../../aux/aux';
import './checkoutAddSubscription.css';
import ToggleMsg from '../ToggleMsg/ToggleMsg';

const CheckoutAddSubscription = ({ isCheckoutUpdating, addItemArray, recommendedSubscriptionProduct}) => {
    const theme = useTheme();
    const { t } = useTranslation('checkout', { keyPrefix: "checkoutAddSubscription"});
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState({isError:false, type:undefined, msg:undefined})
    const abortControllerSignalRef = useRef(null);
    const styles = {
        mainContainer: {
            borderColor: theme.palette.primary.light,
            backgroundColor: theme.palette.common.white
        },
        switchContainer: {
            backgroundColor: theme.palette.primary.light,
            borderColor: theme.palette.primary.light,
            color: theme.palette.common.white
        },
        ribbon:{
            display:'block',
            fill: theme.palette.primary.light
        },
        caption:{
            display:'block',
            color:theme.palette.grey[500]
        },
        circularProgress:{
            color: theme.palette.common.white
        }
    }

    const getSubscriptionTypeTitleString = (subscriptionTypeId, subscriptionTypeName ) => {
        let hook = '';
        switch(subscriptionTypeId){
            case 1:
            case 2:
            default:
                hook = t("becomeASubscriber", {subscriptionTypeName});

        }
        return hook;
    }

    const getSubscriptionTypeHookString = (subscriptionTypeId) => {
        let hook = '';
        switch(subscriptionTypeId){
            case 1:
                hook = t("learnWithNoLimits");
                break;
            case 2:
                hook = t("takeAdvantageFromAllWeCanOffer")
                break;
        }
        return hook;
    }

    const getSubscriptionTypeKeyFeaturesStringArray = (subscriptionTypeId) => {
        const featuresStringArray = [];
        switch(subscriptionTypeId){
            case 1:
                featuresStringArray.push(...[
                    t('accessToOurBestContent'),
                    t('exclusiveSessionsAccess'),
                    t('mastersAndEventsBenefits')
                ])   
                break;
            case 2: 
                featuresStringArray.push(...[
                    t('allBasicAdvatages')
                ])   
                break;
        }
        return featuresStringArray
    }

    const addSubscription = async (e, signal=abortControllerSignalRef.current) => {
        try{
            setIsLoading(true);
            e.preventDefault()
            const checkoutProductArray = [{
                productId:recommendedSubscriptionProduct.id,
                quantity: 1,
                productTypeName: recommendedSubscriptionProduct.productTypeName,
                productTranslatedName: getFromSafeObject(recommendedSubscriptionProduct, 'description.nameTranslated') // to show name in case error happens
            }]
            await addItemArray(checkoutProductArray);
        }catch(error){
            if(!signal.aborted){
                setError({
                    isError:true,
                    msg:t('errorAdding', {subscriptionTypeName: getFromSafeObject(recommendedSubscriptionProduct, 'typeProduct.type.name')}),
                    type:'add'
                })
            }
        }finally{
            if(!signal.aborted){
                setIsLoading(false);
            }
        }
    }

    useEffect(() => {
        const abortController = new AbortController();
        abortControllerSignalRef.current = abortController.signal;
        return(() => abortController.abort())
    },[])

    return (
        isNotEmptyObject(recommendedSubscriptionProduct) ?
            <div>
                <Typography variant='caption' style={styles.caption}>
                    {t('ourSuggestion')}
                </Typography>
                <Grid container className='checkout-add-subscription-main-container' direction='column' style={styles.mainContainer}>
                    <div className='checkout-add-subscription-ribbon-container'>
                        <Ribbon style={styles.ribbon}/>
                    </div>
                    <Grid item>
                        <Grid container direction="column" className='checkout-add-subscription-subcontainer'>
                            <Grid item container className='checkout-add-subscription-title-container'>
                                <Typography variant="body2">
                                    {getSubscriptionTypeTitleString(getFromSafeObject(recommendedSubscriptionProduct,'typeProduct.type.id'), getFromSafeObject(recommendedSubscriptionProduct,'typeProduct.type.name'))}
                                </Typography>
                                < SubscriptionIcon isFilled={true} subscriptionType={getFromSafeObject(recommendedSubscriptionProduct,'typeProduct.type')}/>
                            </Grid>
                            <Grid item >
                                <Typography variant="caption">
                                    {getSubscriptionTypeHookString(getFromSafeObject(recommendedSubscriptionProduct,'typeProduct.type.id'))}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <ul >
                                    {getSubscriptionTypeKeyFeaturesStringArray(
                                        getFromSafeObject(recommendedSubscriptionProduct,'typeProduct.type.id')
                                        ).map((keyPoint, index) => {
                                        return(
                                            <li key={index}>
                                                <Typography variant="body2">
                                                    {keyPoint}
                                                </Typography>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container className='checkout-add-subscription-button-container' style={styles.switchContainer}>
                        <Grid item>
                            <Box className={`checkout-add-subscription-button ${isCheckoutUpdating || isLoading ? 'disabled' : ''}`}
                            onClick={addSubscription}>
                                {isLoading ?
                                    <CircularProgress className={`checkout-add-subscription-button-circle-progress`} size='1em' style={styles.circularProgress}/>
                                    :
                                    <Typography className={`checkout-add-subscribe-button-text ${isCheckoutUpdating ? 'disabled' : ''}`} variant='body2' display='inline'>
                                        {t("addToPurchase")}
                                    </Typography>
                                }
                            </Box>
                        </Grid>
                        <Grid item>
                            <Typography variant='body2'>
                                {`${t('common:{{val, currency}}',{ val:fromCentTo2dec(getFromSafeObject(recommendedSubscriptionProduct,'consumptionDetails.details.purchasabilityDetails.details.amount.finalAmount')), currency: getFromSafeObject(recommendedSubscriptionProduct,'consumptionDetails.details.purchasabilityDetails.details.amount.currencyId'), trailingZeroDisplay:'stripIfInteger'})} / ${getFromSafeObject(recommendedSubscriptionProduct,'typeProduct.term.translation.nameTranslated')}`}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <ToggleMsg
                    extraClassName={"checkout-subscription-item-error-toggle-container"}
                    isShown={error.isError}
                    variant='caption'
                    msg={error.msg}
                    onShowTime={() => setError(prev => ({...prev, isError:false}))}
                />
            </div>
            :
            null
    )
}

export default CheckoutAddSubscription;