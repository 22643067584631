import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Chip, hexToRgb, Typography, useMediaQuery } from '@material-ui/core';
import { getFromSafeObject, getStartDayDate, isHappening, isNotEmptyObject, isToday, isValidDate, rgbToRgbaOrUndefined, textTransform } from '../../aux/aux';
import { setTimeout_, clearTimeout_ } from '../../aux/extendedTimeout';
import PersonIcon from '@mui/icons-material/Person';
import { useTheme } from '@material-ui/styles'
import './userMeetingStatusWidget.css'
import CustomPopover from '../CustomPopover/CustomPopover';
import { getUserMeetingOccurrenceFromEventSource } from '../../services/meetingServices';
import { sessionVariables } from '../../aux/sessionHelpers';

const UserMeetingStatusWidget = ({ userMeeting, userMeetingOccurrenceStatusArray, userStatus, userStatusArray, className, ...props }) => {
    const theme = useTheme();
    const { t } = useTranslation('common', { keyPrefix: 'userMeetingStatusWidget' });
    const matchMobile = useMediaQuery('(max-width:680px)');
    const ANCHOR_ORIGIN = { vertical: 'top', horizontal: 'center'};
    const TRANSFORM_ORIGIN = { vertical: 'bottom', horizontal: 'center'};
    const userInMeeting = userStatus === userStatusArray[1];
    // const userMeetingOccurrenceStatusArray = ['empty' ,'unregistered', 'registered', 'in_progress', 'ended', 'canceled'];
    // const [userMeetingOccurrenceStatus, setUserMeetingStatus] = useState(null);
    // const [isTodayMeeting, setIsTodayMeeting] = useState(false);
    const lowPriorityChipRef = useRef(null);
    const statusChipRef = useRef(null);
    const userInMeetingRef = useRef(null);
    const [showPopoverMap, setShowPopoverMap] = useState({
        lowPriority: false,
        status: false,
        userInMeeting: false
    });

    const getStatusChipStyle = (userMeetingOccurrenceStatus) => {
        let statusChipStyle;
        let colorKey;
        switch(userMeetingOccurrenceStatus){
            case userMeetingOccurrenceStatusArray[2]:
                colorKey = 'secondary';
                break;
            case userMeetingOccurrenceStatusArray[3]:
                colorKey = 'error';
                break;
            case userMeetingOccurrenceStatusArray[1]:
                colorKey = 'success';
                break;
        }
        if(colorKey){
            statusChipStyle = {
                color: theme.palette[colorKey].main,
                borderColor: theme.palette[colorKey].main,
                backgroundColor: rgbToRgbaOrUndefined(hexToRgb(theme.palette[colorKey].main), 0.1),
            };
        }else{
            statusChipStyle = {};
        }
        return statusChipStyle
    }

    const styles = {
        lowPriority: {
            // color: theme.palette.warning.main,
            // borderColor: theme.palette.warning.main,
            // backgroundColor: rgbToRgbaOrUndefined(hexToRgb(theme.palette.warning.main), 0.1),
        },
        icon: {
            display: 'block',
        },
        connectedIcon: {
            opacity: userInMeeting ? '1' : 0 
        }
    }

    const handleMouseEnter = (e, id) => {
        e.preventDefault();
        setShowPopoverMap(prev => ({ ...prev, [id]:true }))
    }

    const handleMouseLeave = (e, id) => {
        e.preventDefault();
        setShowPopoverMap(prev => ({ ...prev, [id]: false}))
    }

    const getUserMeetingStatusLabel = (userMeetingOccurrenceStatus) => {
        let label;
        switch(userMeetingOccurrenceStatus){
            case userMeetingOccurrenceStatusArray[2]:
                label = t('common:completed');
                break
            case userMeetingOccurrenceStatusArray[3]:
                label = t('common:canceled');
                break
            default:
                label = t('common:live');
        }
        return label
    }

    const getUserMeetingStatusPopoverMsg = (userMeetingOccurrenceStatus) => {
        let popoverMsg;
        switch(userMeetingOccurrenceStatus){
            case userMeetingOccurrenceStatusArray[2]:
                popoverMsg = t('thisEventFinished')
                break
            case userMeetingOccurrenceStatusArray[3]:
                popoverMsg = t('thisEventWasCanceled')
                break
            default:
                popoverMsg = t('thisIsALiveEvent');
        }
        return popoverMsg
    }


    // useEffect(() => {
    //     if(isNotEmptyObject(userMeeting) && userMeeting.id){
    //         function onmessage(event){
    //             const {
    //                 meetingOccurrence,
    //             } = JSON.parse(event.data);
    //             let userMeetingStatus;
    //             if(isNotEmptyObject(meetingOccurrence) && meetingOccurrence.id){
    //                 const status = meetingOccurrence.status;
    //                 switch(status){
    //                     case 'open': 
    //                         userMeetingStatus = userMeetingOccurrenceStatusArray[2];
    //                         break;
    //                     case 'in_progress':
    //                         userMeetingStatus = userMeetingOccurrenceStatusArray[3];
    //                         break;
    //                     case 'ended':
    //                         userMeetingStatus = userMeetingOccurrenceStatusArray[4];
    //                         break;
    //                     case 'canceled':
    //                         userMeetingStatus = userMeetingOccurrenceStatusArray[5];
    //                         break;
    //                 }
    //                 const userMeetingStartsTime = new Date(meetingOccurrence.startsDateTime);
    //                 if(isValidDate(userMeetingStartsTime)){
    //                     setIsTodayMeeting(isToday(userMeetingStartsTime));
    //                 }
    //             }else{
    //                 // set unregistered userMeeting status
    //                 userMeetingStatus = userMeetingOccurrenceStatusArray[1];
    //                 this.close();
    //             }
    //             setUserMeetingStatus(userMeetingStatus);

    //         }
    //         function onerror(event){
    //             // setCheckoutFeedbackState(checkoutFeedbackStateTypes[1])
    //             this.close();
    //         }
    //         const eventSource = getUserMeetingOccurrenceFromEventSource(userMeeting.userId, userMeeting.id, sessionVariables.get('accessToken'), onmessage, onerror);
    //         return(() => {
    //             eventSource.close();
    //         })
    //         // Start: set userMeetingStatus by timeouts
    //         // const meetingOccurrence = getFromSafeObject(userMeeting, 'details.occurrence');
    //         // if(isNotEmptyObject(meetingOccurrence) && meetingOccurrence.id){
    //         //     if(meetingOccurrence.status === 'canceled'){
    //         //         // set canceled userMeeting status
    //         //         setUserMeetingStatus(userMeetingStatusArray[5]);
    //         //     }else{
    //         //         // TODO: push status changes to 'in_progress' and 'ended' from server
    //         //         // now the status transits according to meeting occurrence startsDateTime and endsDateTime 
    //         //         const now = new Date();
    //         //         const userMeetingStartsTime = new Date(meetingOccurrence.startsDateTime);
    //         //         const userMeetingEndsTime = new Date(meetingOccurrence.endsDateTime);
    //         //         if(isValidDate(userMeetingStartsTime) && isValidDate(userMeetingEndsTime)){
    //         //             const meetingStartDayTime = getStartDayDate(userMeetingStartsTime);
    //         //             if(now.getTime() < meetingStartDayTime.getTime()){
    //         //                 const timeToStartDayOfUserMeetingMs = meetingStartDayTime.getTime() - now.getTime(); 
    //         //                 const activateTodayMeetingTimeoutId = setTimeout_(() => { setIsTodayMeeting(true) }, timeToStartDayOfUserMeetingMs);
    //         //                 // set registered  userMeetingStatus
    //         //                 setUserMeetingStatus(userMeetingStatusArray[2]);
    //         //                 const timeToStartUserMeetingMs = userMeetingStartsTime.getTime() - now.getTime();
    //         //                 const activateInProgressStatusTimeoutId = setTimeout_(() => { setUserMeetingStatus(userMeetingStatusArray[3]) }, timeToStartUserMeetingMs)
    //         //                 return(() => {
    //         //                     clearTimeout_(activateTodayMeetingTimeoutId);
    //         //                     clearInterval(activateInProgressStatusTimeoutId);
    //         //                 })
    //         //             }else{
    //         //                 const startNextDayToMeetingTime = new Date( meetingStartDayTime );
    //         //                 startNextDayToMeetingTime.setDate(startNextDayToMeetingTime.getDate() + 1);
    //         //                 if(now.getTime() < startNextDayToMeetingTime.getTime()){
    //         //                     setIsTodayMeeting(true);
    //         //                     if(now.getTime() < userMeetingEndsTime.getTime()){
    //         //                         // set in_progress userMeetingStatus
    //         //                         setUserMeetingStatus(userMeetingStatusArray[3]);
    //         //                     }else{
    //         //                         // set ended userMeetingStatus
    //         //                         setUserMeetingStatus(userMeetingStatusArray[4]);
    //         //                     }
    //         //                 }else{
    //         //                     setIsTodayMeeting(false);
    //         //                 }
    //         //             }
    //         //         }
    //         //     }
    //         // }else{
    //         //     // set unregistered userMeeting status
    //         //     setUserMeetingStatus(userMeetingStatusArray[1]);
    //         // }
    //         // End: set userMeetingStatus by timeouts
    //     }else{
    //         // set empty userMeeting status
    //         setUserMeetingStatus(userMeetingOccurrenceStatusArray[0])
    //     }        
    // },[userMeeting])

    // useEffect(() => {
    //     if(userMeetingStatus === userMeetingStatusArray[3]){
    //         const now = new Date();
    //         const userMeetingEndsTime = new Date(getFromSafeObject(userMeeting, 'details.occurrence.endsDateTime'));
    //         if(isValidDate(userMeetingEndsTime)){
    //             const timeToEndMeetingMs = userMeetingEndsTime.getTime() - now.getTime();
    //             let activateEndStatusTimeoutId = setTimeout_(() => { setUserMeetingStatus(userMeetingStatusArray[4]) }, timeToEndMeetingMs);
    //             return(() => {
    //                 clearTimeout_(activateEndStatusTimeoutId);
    //             })
    //         }
    //     }
    // },[userMeetingStatus])

    // useEffect(() => {
    //     if(isTodayMeeting){
    //         const now = new Date();
    //         const userMeetingStartsTime = new Date(getFromSafeObject(userMeeting, 'details.occurrence.startsDateTime'));
    //         if(isValidDate(userMeetingStartsTime)){
    //             const tomorrow = new Date();
    //             tomorrow.setDate( now.getDate() + 1);
    //             const startTomorrowTime = getStartDayDate(tomorrow);
    //             const timeToStartTomorrowMs = startTomorrowTime.getTime() - now.getTime();
    //             let deactivateTodayMeetingTimeoutId = setTimeout_(() => { setIsTodayMeeting(false) }, timeToStartTomorrowMs);
    //             return(() => {
    //                 clearTimeout_(deactivateTodayMeetingTimeoutId);
    //             })
    //         }
    //     }
    // },[isTodayMeeting])

    // useEffect(()=>{
    //     const timeoutMap = {}
    //     Object.entries(showPopoverMap).forEach(([id, isShown], index) => {
    //         if(isShown){
    //             const timeoutId = setTimeout_( () => setShowPopoverMap(prev => ({ ...prev, [id]: false })), 2000)
    //             timeoutMap
    //         }
    //     })
    // }, [showPopoverMap])

    return (
        <div className={`user-meeting-status-widget-main-container${className ? ` ${className}` : ''}`} {...props} >
            {getFromSafeObject(userMeeting, 'details.occurrence.status') &&
                <div style={ styles.icon } ref={statusChipRef}
                    onMouseEnter={(e) => handleMouseEnter(e, 'status')}
                    onMouseLeave={(e) => handleMouseLeave(e, 'status')}
                >
                    <CustomPopover
                        anchorEl={statusChipRef}
                        open={showPopoverMap['status']}
                        anchorOrigin={ANCHOR_ORIGIN}
                        transformOrigin={TRANSFORM_ORIGIN}
                    >
                        <Typography variant='body2'>
                            {textTransform('title', getUserMeetingStatusPopoverMsg(getFromSafeObject(userMeeting, 'details.occurrence.status')))}
                        </Typography>
                    </CustomPopover>
                    <Chip 
                        className={`${getFromSafeObject(userMeeting, 'details.occurrence.status') === userMeetingOccurrenceStatusArray[1] ? 'user-meeting-status-widget-intermittent' : ''}`}
                        variant='outlined' 
                        label={textTransform('title', getUserMeetingStatusLabel(getFromSafeObject(userMeeting, 'details.occurrence.status')))} 
                        size='small'
                        style={ getStatusChipStyle(getFromSafeObject(userMeeting, 'details.occurrence.status')) }    
                    />
                </div>
            }
            {userMeeting.lowPriorityMeeting && 
                <div style={ styles.icon } ref={lowPriorityChipRef}
                onMouseEnter={(e) => handleMouseEnter(e, 'lowPriority')}
                onMouseLeave={(e) => handleMouseLeave(e, 'lowPriority')}
                >
                    <CustomPopover
                        anchorEl={lowPriorityChipRef}
                        open={showPopoverMap['lowPriority']}
                        anchorOrigin={ANCHOR_ORIGIN}
                        transformOrigin={TRANSFORM_ORIGIN}
                    >
                        <Typography variant='body2'>
                            {textTransform('title', t('repeatAttendance'))}
                        </Typography>
                    </CustomPopover>
                    <Chip 
                        variant='outlined' 
                        label={textTransform('title', t('previouslyAttended'))} 
                        size='small'
                        style={ styles.lowPriority }    
                    />
                </div>
            }
            {userInMeeting && !matchMobile ? 
                    <div 
                        style={ styles.connectedIcon }
                        ref={userInMeetingRef}
                        onMouseEnter={(e) => handleMouseEnter(e, 'userInMeeting')}
                        onMouseLeave={(e) => handleMouseLeave(e, 'userInMeeting')}
                    >
                            <CustomPopover
                                anchorEl={userInMeetingRef}
                                open={showPopoverMap['userInMeeting']}
                                anchorOrigin={ANCHOR_ORIGIN}
                                transformOrigin={TRANSFORM_ORIGIN}
                            >
                            <Typography variant='body2'>
                                {textTransform('title', t('userInMeeting'))}
                            </Typography>
                        </CustomPopover>
                        <PersonIcon style={ styles.icon } />
                    </div>
                :
                    null
            }
        </div>
    )
}

export default UserMeetingStatusWidget