import { createContext, useEffect, useState } from "react"
import { getReliableLocale } from "../aux/sessionHelpers";
import { someEmptyPropertyObject } from "../aux/aux";
import { updateSessionLocale } from "../services/visitorServices";

const LocaleContext = createContext(null);
const LocaleUpdateContext = createContext(null);

const LocaleProvider = ({ children }) => {
    const initialLocale = getReliableLocale();
    if(someEmptyPropertyObject(initialLocale)){
        throw new Error('LocaleProvider needs localeId and localeCode to be set in localeStorage')
    }
    const [locale, _setLocale] = useState(initialLocale);
    
    const setLocale = async({localeId, localeCode}) => {
        _setLocale({localeId, localeCode });
        await updateSessionLocale({ localeId, localeCode })
    }
    return(
        <LocaleContext.Provider value={locale}>
            <LocaleUpdateContext.Provider value={setLocale}>
                { children }
            </LocaleUpdateContext.Provider>
        </LocaleContext.Provider>
    )
}

export { LocaleProvider, LocaleContext, LocaleUpdateContext }