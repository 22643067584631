import { useTheme } from "@material-ui/styles"
import { useTranslation } from "react-i18next";
import CustomChip from "../CustomChip/CustomChip";
import { textTransform } from "../../aux/aux";

const ContentTypeChip = ({ contentTypeName, contentTypeNameTranslated, size, variant }) => {
    const theme = useTheme();
    const { t } = useTranslation('common', { keyPrefix: 'contentTypeChip' });
    const colors = {
        protocol: theme.palette.qxi.green,
        course: theme.palette.qxi.blue,
        masterclass: theme.palette.qxi.yellow,
        workshop: theme.palette.qxi.pink
    }
    return (
        contentTypeName && contentTypeNameTranslated ?
            <CustomChip
                size={size || 'small'}
                variant={variant || 'outlined'}
                label={textTransform('title', contentTypeNameTranslated)}
                color={colors[contentTypeName]}
            />
            :
            null
    )
}

export default ContentTypeChip