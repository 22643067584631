import { useEffect, useRef, useState, forwardRef } from 'react';

const DivLazyLoad = ({ backgroundImage, backgroundPlaceholderImage, backgroundErrorImage, threshold='100px', onError, ...props }, ref) => {
    const divRef = useRef(null);
    const [imgSrc, setImgSrc] = useState(backgroundPlaceholderImage);

    const styles = {
        divImg: {
            backgroundImage:`${props.style?.backgroundImage ? `${props.style.backgroundImage}${imgSrc ? ', ': ''}` : ''}${imgSrc ? `url(${imgSrc})`:''}`,
        }
    }
    
    const handleOnError = (e) => {
        if(e.target.src === backgroundImage && backgroundErrorImage){
            setImgSrc(backgroundErrorImage);
        }
        if(onError){
            onError(e);
        }
    }

    useEffect(() => {
        if(divRef && divRef.current && backgroundImage){
            if(
                'IntersectionObserver' in window ||
                'IntersectionObserverEntry' in window ||
                'intersetionRatio' in window.IntersectionObserverEntry.prototype ||
                'isIntersecting' in window.IntersectionObserverEntry.prototype
            ){
                const handleIntersection = (entries) => {
                    entries.forEach( entry => {
                        if(entry.isIntersecting){
                            setImgSrc(backgroundImage);
                            observer.unobserve(entry.target);
                        }
                    })
                }
                const observer = new IntersectionObserver(handleIntersection, { rootMargin: threshold });
                observer.observe(divRef.current);
                return(() => {
                    observer.disconnect();
                })
            }else{
                // load directly the img
                setImgSrc(backgroundImage)
            }
        }
    },[backgroundImage])

    return (
        <div {...props} ref={(elem) => {divRef.current = elem; ref(elem)}} onError={handleOnError} style={{ ...props.style, ...styles.divImg }}>
            {props.children}
        </div>
    )
}

export default forwardRef(DivLazyLoad)