import { Grid } from '@material-ui/core'
import { 
    EmailShareButton, FacebookIcon, FacebookShareButton, TwitterIcon,
    TwitterShareButton, WhatsappShareButton, WhatsappIcon, EmailIcon
 } from 'react-share'

import './sharingButtonGroup.css'

const SharingButtonGroup = ({url, noIncludes={}, buttonProps={}, iconStyle={}, buttonStyle={} }) => {
  const styles = {
    button:{
      paddingLeft:'5px',
      paddingRight:'5px',
      ...buttonStyle
    },
    icon:{
      width: '32px',
      height: '32px',
      borderRadius: '100%'
    }
  };
  const ICON_SIZE_PX = 32;  
  return (
    <Grid container className='sharing-button-group-container'>
      {buttonProps.facebook &&
        <Grid item>
            <FacebookShareButton {...buttonProps.facebook} url={url} style={styles.button}>
                <FacebookIcon style={styles.icon}/>
            </FacebookShareButton>
        </Grid>
      }
      {buttonProps.twitter &&
        <Grid item>
            <TwitterShareButton {...buttonProps.twitter} url={url} style={styles.button}>
                <TwitterIcon style={styles.icon} />
            </TwitterShareButton>
        </Grid>
      }
      {buttonProps.whatsapp &&
        <Grid item>
            <WhatsappShareButton {...buttonProps.whatsapp} url={url} style={styles.button}>
                <WhatsappIcon style={styles.icon} />
            </WhatsappShareButton>
        </Grid>
      }
      {buttonProps.email &&
        <Grid item>
            <EmailShareButton {...buttonProps.email} url={url} style={styles.button} >
                <EmailIcon style={styles.icon} />
            </EmailShareButton>
        </Grid>
      }
    </Grid>
  )
}

export default SharingButtonGroup