import { useTranslation, Trans } from 'react-i18next'
import { Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { textTransform } from '../../aux/aux';
import WorkguideVSProtocolImg from '../../img/workguide-vs-protocol_450x300.svg'
import './workguideBetterThanAProtocol.css'
import StyledTypography from '../StyledTypography/StyledTypography';

const WorkguideBetterThanAProtocol = ({title, contentWrapperStyle, noVerticalPadding=false}) => {
    const { t } = useTranslation('contentProduct', { keyPrefix: 'workguideBetterThanAProtocol'});
    const theme = useTheme();
    const matchDesktop = useMediaQuery('(min-width: 1120px)');
    const matchMobile = useMediaQuery('(max-width: 680px)');
    const styles = {
        title:{
            fontWeight: 'bold',
            textAlign: 'center'
        },
        header:{
            fontWeight: 'bold',
        },
    }
    return (
        <div className='workguide-better-than-a-protocol-wrapper'>
            <div className={`workguide-better-than-a-protocol-main-container${noVerticalPadding ? ' no-vertical-padding' : ''}`} >
                {title &&
                    <div className='workguide-better-than-a-protocol-title-container'>
                        <Typography variant={matchMobile ? 'h5':'h4'} style={styles.title}>
                            {textTransform('title', title)}
                        </Typography>
                    </div>
                }
                <div className='workguide-better-than-a-protocol-content-wrapper' style={contentWrapperStyle} >
                    { !matchDesktop &&
                        <Typography variant={matchMobile ? 'body1': 'h6'} style={styles.header} className='workguide-better-than-a-protocol-header'>
                            {t('header')}
                        </Typography>
                    }
                    <div className='workguide-better-than-a-protocol-content-container'>
                        <div className='workguide-better-than-a-protocol-content-description-container'>
                            { matchDesktop &&
                                <Typography variant='h6' style={styles.header} className='workguide-better-than-a-protocol-header'>
                                    {t('header')}
                                </Typography>
                            }
                            <StyledTypography variant='body1'>
                                {t('whyWorkguideIsBetterThanAProtocol')}
                            </StyledTypography>
                        </div>
                        <div className='workguide-better-than-a-protocol-content-img-container'>
                            <WorkguideVSProtocolImg className='workguide-better-than-a-protocol-content-img'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WorkguideBetterThanAProtocol