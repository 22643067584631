import { Grid, Typography } from '@material-ui/core'
import { Skeleton } from '@mui/material'

import './purchaseProductSkeleton.css'

const PurchaseProductSkeleton = () => {
    return (
        <Grid container direction='column' style={{padding:'10px 0px'}}>
            <Grid item>
                <Grid container>
                    <Grid item style={{paddingRight:'10px'}}>
                        <Skeleton variant='square' height={40} width={40} style={{borderRadius:"5px"}}/>
                    </Grid>
                    <Grid item xs>
                        <Skeleton variant='text' width="75%"/>
                        <Skeleton variant='text' width="25%"/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>        
    )
}

export default PurchaseProductSkeleton
