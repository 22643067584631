/*
    This module contains a set of functions to handle API queries related to user support
*/
import { createAuthRequest, publicRequest } from "./requestMethods";

const sendSupportRequestFn = async ( params, signal=null) => {
    let promise;
    if(params.hasOwnProperty('userId')){
        const userRequest = createAuthRequest();
        promise = userRequest.post(`/support/user-request/${params.type}/${params.userId}`, { request:params }, { signal })
    }else{
        promise = publicRequest.post(`/support/public-request/${params.type}`, { request:params }, { signal });
    }
    return promise
}

export{
    sendSupportRequestFn
}