import { Typography } from '@material-ui/core';
import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { textTransform, textTruncate } from '../../aux/aux';
import PopUpModal from '../PopUpModal/PopUpModal';
import SimpleNoModalDialog from '../SimpleNoModalDialog/SimpleNoModalDialog';
// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import ErrorIcon from '@mui/icons-material/Error';
import noConnection from '../../lotties/noConnection_200x200.json';
import './popUpJoiningMeetingError.css';
import PopUpModalHeader from '../PopUpModalHeader/PopUpModalHeader';

const PopUpJoiningMeetingError = ({ showModal, onClickClose, cause='' }) => {
    const POP_UP_WIDTH = 380;
    const { t } = useTranslation('common', { keyPrefix: 'popUpJoiningMeetingError' });
    const animationContainerRef = useRef(null);
    const animationRef = useRef(null)
    const styles = {
        modal:{
            padding:'20px',
            boxSizing:'border-box'
        },
        animationContainer:{
            width: '150px',
            margin: '0px auto'
        }
    }

    useEffect(() => {
        if(animationContainerRef.current){
            animationRef.current = lottie.loadAnimation({
                container: animationContainerRef.current,
                renderer: 'svg',
                loop: false,
                autoplay: true,
                animationData: noConnection
            });
            return(() => {animationRef.current.stop(); animationRef.current?.destroy()})
        }
    },[])
  return (
    <PopUpModal 
        width={POP_UP_WIDTH}
        showModal={showModal} 
        onClickClose={onClickClose} 
        onOutsideClick={onClickClose} 
        isDialog={true}
        style={styles.modal}
    >
        <PopUpModalHeader
            Icon={ErrorIcon}
            title={t('thereWasAProblem')}
        />
        <SimpleNoModalDialog 
            onAgree={onClickClose}
        >
            <div ref={animationContainerRef} style={styles.animationContainer}/>
            {cause &&
                <div className='popup-joining-meeting-error-cause-wrapper'>
                    <Typography className='popup-joining-meeting-error-cause' variant='body2'>
                        { textTruncate(textTransform('title', cause), 100) }
                    </Typography>
                </div>
            }
            <div>
                <Typography variant='body1'>
                    {`${textTransform('title', t('cannotJoinMeeting'))}.\n`
                    +`${textTransform('title', t('tryItLatter'))}`}
                </Typography>
            </div>
        </SimpleNoModalDialog>
    </PopUpModal>
  )
}

export default PopUpJoiningMeetingError