import DeviceLogo from '../DeviceLogo/DeviceLogo'
import './deviceNamesLogos.css'

const DeviceNamesLogos = ({ deviceArray, className, svgStyle={} }) => {
    return (
        <div className={className ? className : 'device-names-logos-main-container'}>
            {deviceArray.map((device, index) => {
                return(
                    <DeviceLogo key={index} deviceName={device.deviceName} svgStyle={svgStyle}/>
                )
            })}
        </div>
    )
}

export default DeviceNamesLogos