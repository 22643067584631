/**
 * 
 */
import { mode, pad, enc, AES, SHA1 } from 'crypto-js';

const sha1Signature = (payload, encoder=enc.Hex) => {
    return SHA1(payload).toString(encoder);
}

const aesCipher = (string, key) => {
    const iv =  process.env.REACT_APP_CRYPTO_16B_INIT_VECTOR;
    const cipher = AES.encrypt(string, enc.Base64.parse(key), {   
        iv: enc.Base64.parse(iv),
        mode: mode.CBC,
        padding: pad.Pkcs7,
        keySize: 256
    })
    return cipher.toString()
}

const aesDecipher = (string, key) => {
    const iv =  process.env.REACT_APP_CRYPTO_16B_INIT_VECTOR;
    const decipher = AES.decrypt(string, enc.Base64.parse(key), {   
        iv: enc.Base64.parse(iv),
        mode: mode.CBC,
        padding: pad.Pkcs7,
        keySize: 256
    })
    return decipher.toString(enc.Utf8);
}

export{
    sha1Signature,
    aesCipher,
    aesDecipher
}