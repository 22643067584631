/**
 * This module contains helper functions to manipulate static content requested from the API 
 */

import { getFromSafeObject } from "./aux"

const localeToFormatedString = (localeWithTranslationAndDetails) => {
    const languageTranslatedName = getFromSafeObject(localeWithTranslationAndDetails, 'details.language.description.name') || '';
    const countryTranslatedName = getFromSafeObject(localeWithTranslationAndDetails, 'details.country.description.name') || '';
    return `${languageTranslatedName} ${countryTranslatedName ? `( ${countryTranslatedName} )` : ''}`
}

export{ 
    localeToFormatedString
}