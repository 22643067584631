import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Helpers
import { priceToString, percentageToString, fromCentTo2dec } from '../../aux/purchaseHelpers';
// MUI
import { Box, Grid, Typography  } from '@material-ui/core';
// Img
import NextIcon from '../../img/next-icon.svg';
// Style
import './checkoutSalesTax.css';
import { textTransform } from '../../aux/aux';

const CheckoutSalesTax = ({ isLoading, orderAmounts }) => {
    const { t } = useTranslation('checkout');
    const [isOpen, setIsOpen] = useState(false);
    const [focusOnDetails, setFocusOnDetails] = useState(false);
    const toggleDetails = (e) => {
        setIsOpen(!isOpen)
    }
    return (
        <Grid container direction="column" className="checkout-sales-tax-main-container">
            <Grid item>
                <Grid container direction="row" justifyContent='space-between' alignItems="center">
                    <Grid item>
                        <Typography variant="body1">
                            {textTransform('title',t("taxes"))}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Box className='checkout-sales-tax-details-button' onClick={toggleDetails}
                            onMouseEnter={ e => {setFocusOnDetails(true)}} onMouseLeave={ e => {setFocusOnDetails(false)}}
                        >
                            <NextIcon transform={focusOnDetails || isOpen ? 'rotate(90)' : ''} height="0.8em" />
                            <Typography variant="body2" display='inline' style={{paddingLeft: '5px'}}>
                                {textTransform('title', t("seeDetails"))}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                            {t('{{val, currency}}',{ val:fromCentTo2dec(orderAmounts.totalSalesTaxAmount), currency: orderAmounts.currencyId, trailingZeroDisplay:'stripIfInteger'})}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item >
                <Grid container direction="column" className={`checkout-sales-tax-details-toggle ${isOpen ? 'open' : ''}`}>
                    {Object.values(orderAmounts.salesTaxRateAmounts).map((salesTaxRate, index) => {
                        return(
                            <Grid key={salesTaxRate.salesTaxRate} className="checkout-sales-tax-details-toggle-item" container direction="row" justifyContent='space-between'>
                                <Grid item>
                                    <Typography variant="body2">
                                        {`${salesTaxRate.salesTaxType} (${percentageToString(salesTaxRate.salesTaxRate)})`}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2">
                                        {t('{{val, currency}}',{ val:fromCentTo2dec(salesTaxRate.salesTaxAmount), currency: orderAmounts.currencyId, trailingZeroDisplay:'stripIfInteger'})}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CheckoutSalesTax