
import { textTransform, textTruncate } from '../../aux/aux';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import DevicesIcon from '@mui/icons-material/Devices';
import WebIcon from '@mui/icons-material/Web';
import Circle from '../../img/circle.svg';
import './userSystemSelectFormItem.css'

const UserSystemSelectFormItem = ({system, isSelected, isIncompatible, onlyDeviceAndSoftware=false}) => {
    const TEXT_TRUNCATE_LENGTH_LONG = 35;
    const TEXT_TRUNCATE_LENGTH_MEDIUM = 20;
    const TEXT_TRUNCATE_LENGTH_SHORT = 10;
    const TEXT_TRUNCATE_END = '...';
    const theme = useTheme();
    const { t } = useTranslation('common', { keyPrefix: 'userSystemSelectFormItem' });

    return (
        <Grid container direction='column'>
            {!onlyDeviceAndSoftware &&
                <Grid item>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item>
                            <Grid container alignItems='center'>
                                <Grid item>
                                    <Typography variant='body1'>
                                        {textTransform('title', textTruncate(system.nickname, TEXT_TRUNCATE_LENGTH_LONG, TEXT_TRUNCATE_END))}
                                    </Typography>
                                </Grid>
                                <Grid item className='user-system-select-form-item-icon'>
                                    {isSelected &&
                                        <Circle height={'0.5em'} fill={theme.palette.primary.main} display={"block"}/>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            { isIncompatible &&
                                <Typography variant='body2' className='user-system-select-form-item-label'>
                                    {textTruncate(t('noCompatible'), TEXT_TRUNCATE_LENGTH_MEDIUM, TEXT_TRUNCATE_END)}
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            }
            <Grid item>
                <Grid container justifyContent='space-evenly'>
                    <Grid item >
                        <Grid container alignContent='center'>
                            <Grid item className='user-system-select-form-item-icon'>
                                <Typography variant='body1'>
                                    <DevicesIcon fontSize='inherit'/>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body2'>
                                    {textTruncate(system.deviceName, TEXT_TRUNCATE_LENGTH_SHORT, TEXT_TRUNCATE_END).toUpperCase()}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid> 
                    <Grid item >
                        <Grid container alignContent='center'>
                            <Grid item className='user-system-select-form-item-icon'>
                                <Typography variant='body1'>
                                    <WebIcon fontSize='inherit'/>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant='body2'>
                                    {textTruncate(system.softwareName, TEXT_TRUNCATE_LENGTH_SHORT, TEXT_TRUNCATE_END).toUpperCase()}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid> 
                </Grid>
            </Grid>
        </Grid>
  )
}

export default UserSystemSelectFormItem