import { Button, Grid, Typography } from '@material-ui/core';
import CheckoutInSubscriptionItem from '../CheckoutInSubscriptionItem/CheckoutInSubscriptionItem';
import { isEmptyObject, isNotEmptyObject } from '../../aux/aux';
import CheckoutItem from '../CheckoutItem/CheckoutItem';
import CheckoutSubscriptionItem from '../CheckoutSubscriptionItem/CheckoutSubscriptionItem';
import CheckoutAddSubscription from '../CheckoutAddSubscription/CheckoutAddSubscription';
// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import emptyBox from '../../lotties/emptyBox.json';
// Style
import './checkoutItems.css';
import { useEffect, useRef, useState } from 'react';
import PurchaseProductSkeleton from '../PurchaseProductSkeleton/PurchaseProductSkeleton';

const CheckoutItems = ({ addItemArray, swapItems, deleteOrUpdateItemQuantity, checkoutContent, isCheckoutUpdating }) => {
    const animationRef = useRef(null);
    const [animationContainer, setAnimationContainer] = useState(null);
    useEffect(() => {
        if(animationContainer){
            animationRef.current = lottie.loadAnimation({
                container: animationContainer,
                renderer: 'svg',
                autoplay: true,
                loop: 3,
                animationData: emptyBox
            });
            return(() => {animationRef.current.stop(); animationRef.current?.destroy()})
        }
    }, [animationContainer])
    return (
        <Grid className='checkout-items-main-container' container direction="column">
            {isNotEmptyObject(checkoutContent) ?
                <>
                    {isNotEmptyObject(checkoutContent.recommendedSubscriptionProduct) &&
                        <Grid item className='checkout-items-subscription-add-subscription-container'>
                            <CheckoutAddSubscription isCheckoutUpdating={isCheckoutUpdating} addItemArray={addItemArray} recommendedSubscriptionProduct={checkoutContent.recommendedSubscriptionProduct} />
                        </Grid>
                    }
                    {isNotEmptyObject(checkoutContent.recommendedSubscriptionUpgradeProduct) &&
                        null
                    }
                    {isNotEmptyObject(checkoutContent.subscriptionProductMap) ? 
                        <Grid item container className='checkout-items-subscription-items-container'>
                            {Object.entries(checkoutContent.subscriptionProductMap).map(([productId, product], index) => {
                                return(
                                    <CheckoutSubscriptionItem 
                                        key={index} 
                                        index={index} 
                                        product={product} 
                                        productAmount={checkoutContent.orderAmounts.productAmounts[productId]}
                                        alternativeProduct={checkoutContent.subscriptionProductAlternativesMap[product.productId]} 
                                        isCheckoutUpdating={isCheckoutUpdating} 
                                        subscribedProductsAndCouponsMap={checkoutContent.subscribedProductsAndCouponsMap}
                                        swapItems={swapItems} 
                                        deleteOrUpdateItemQuantity={deleteOrUpdateItemQuantity}
                                    />
                                )
                            })}
                        </Grid>
                        :
                        null
                    }
                    <Grid item container className='checkout-items-container'>
                        {isNotEmptyObject(checkoutContent.productsAndCouponsMap) ? 
                            Object.entries(checkoutContent.productsAndCouponsMap).map(([productId, product], index) => {
                                if(!(productId in checkoutContent.subscriptionProductMap)){
                                    return(
                                        <CheckoutItem 
                                            key={index} 
                                            product={product} 
                                            productAmount={checkoutContent.orderAmounts.productAmounts[productId]} 
                                            isCheckoutUpdating={isCheckoutUpdating} 
                                            deleteOrUpdateItemQuantity={deleteOrUpdateItemQuantity} 
                                        />
                                    )
                                }
                            })
                            :
                            <div className='checkout-items-animation-container' ref={setAnimationContainer}>
                                { animationRef.current?.play() }
                            </div>
                        }
                    </Grid>
                </>
                :
                <PurchaseProductSkeleton />
            }
        </Grid>
    )
}

export default CheckoutItems