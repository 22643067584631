import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/styles';
import { Box, Grid, Paper, Typography } from '@material-ui/core'
import { textTransform } from '../../aux/aux'
import './pageNotFound.css'
import BigFooterNoLinks from '../../components/BigFooterNoLinks/BigFooterNoLinks';

const PageNotFound = ({ user }) => {
    const { t } = useTranslation('pageNotFound');
    const theme = useTheme();
    const errorCode = 404;
    const styles = {
        wrapper: {
            // backgroundColor: theme.palette.surface.light[1],
        }
    }
    return (
        <div className='page-not-found-wrapper' style={styles.wrapper} >
            <div className='page-not-found-main-container'>
            {/* <Grid container className='page-not-found-main-container'> */}
                {/* <Grid item> */}
                    <Paper className='page-not-found-paper-container'>
                        <Grid container direction='column'>
                            <Grid item className='page-not-found-title-container'>
                                <Typography variant='h5'>
                                    {textTransform('title', t("title"))}
                                </Typography>
                            </Grid>
                            <Grid item>
                                {t('notFoundMsg').split('\n').map((p, index) => {
                                    return(
                                    <Typography key={index} className='page-not-found-msg'>
                                        {p}
                                    </Typography>
                                    )
                                })}
                            </Grid>
                            <Grid item className='page-not-found-error-container'>
                                <Typography className='page-not-found-error-msg' variant='body2' component='div'>
                                    {`${textTransform('capitalize', t('errorCode'))}: `}
                                    <Box className='page-not-found-error-code'>
                                        {errorCode}
                                    </Box>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                {/* </Grid> */}
            {/* </Grid> */}
            </div>
            <BigFooterNoLinks user={user} />
        </div>
    )
}

export default PageNotFound