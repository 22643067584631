import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/styles';
import { Grid, Divider, TextField, MenuItem, Typography } from '@material-ui/core';
import BackButton from '../../components/BackButton/BackButton';
import ButtonLoading from '../../components/ButtonLoading/ButtonLoading';
import BillingAddressEditForm from '../../components/BillingAddressEditForm/BillingAddressEditForm';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import { sessionVariables } from '../../aux/sessionHelpers';
import { getCountriesFn, getStatesFn } from '../../aux/staticContentQueries';
import { requestMinimumTimeoutWrapper } from '../../aux/requestMethods';
import { emptyPropertiesObject, isEmptyObject, isNotEmptyObject } from '../../aux/aux';
import './setBillingAddress.css';
import { useHistory, useLocation } from 'react-router-dom';
import { updateCheckoutBillingAddressAndCurrency } from '../../aux/purchaseHelpers';
import { updateUserBillingAddressAndCurrency } from '../../services/userServices';import { useUpdateCountryAndCurrencyCtx } from '../../customHooks/useUpdateCountryAndCurrencyCtx';
import { useRedirect } from '../../customHooks/useRedirect';
import { useLocaleCtx } from '../../customHooks/useLocaleCtx';
import SmallFooter from '../../components/SmallFooter/SmallFooter';

const SetBillingAddress = ({ user}) => {
    const POP_UP_WIDTH = 380;
    const theme = useTheme();
    const location = useLocation()
    const localeCtx = useLocaleCtx();
    const updateCountryAndCurrencyCtx = useUpdateCountryAndCurrencyCtx();
    const { t } = useTranslation('setBillingAddress');
    const query = new URLSearchParams(location.search);
    const redirectApi = useRedirect();
    const [redirectPath, setRedirectPath] = useState(query.get('from'));
    const [checkoutId, setCheckoutId] = useState(query.get('checkout-to-update'));
    const [errorUpdating, setErrorUpdating] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [countries, setCountries] = useState({});
    const [states, setStates] = useState(undefined);
    const [selectedCountryCode, setSelectedCountryCode] = useState('');
    const [formValues, setFormValues] = useState({});

    const styles = {
        contentContainer:{
            backgroundColor: theme.palette.background.paper,
            borderRadius: '4px',
            width: `${POP_UP_WIDTH}px`,
            boxShadow: theme.shadows[2]
        },
        error:{
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main,
        }
    }

    const onUpdate = async (e) => {
        try{
            e.preventDefault();
            setIsLoading(true)
            if(!isEmptyObject(formValues)){
                const billingAddress = {
                    ...formValues,
                    countryCode:selectedCountryCode
                };
                // stateCode must be null or an alpha-2 code
                billingAddress.stateCode = billingAddress.stateCode === '' ? null : billingAddress.stateCode;
                const res = await updateUserBillingAddressAndCurrency(user.basicData.id, billingAddress);
                const {
                    updatedValues
                } = res.data;
                if(isNotEmptyObject(updatedValues)){
                    const countryCode = updatedValues.countryCode;
                    const stateCode = updatedValues.stateCode;
                    const currencyId = updatedValues.currencyId;
                    sessionVariables.set('userCountryCode', countryCode);
                    sessionVariables.set('userStateCode', stateCode);
                    sessionVariables.set('userCurrencyId', currencyId);
                    updateCountryAndCurrencyCtx.setCountryAndCurrency({countryCode, stateCode, currencyId});
                    if(redirectPath){
                        const query = new URLSearchParams();
                        query.set('is_billable', true);
                        // history.replace(redirectPath, {
                        //     search: `?${query.toString()}`
                        // })
                        redirectApi.redirectToRoute(redirectPath, query, true)
                    }else{
                        redirectApi.redirectToRoute('/')
                    }
                    // Commented on: 03/09
                    // updateUserBillingAddressAndCurrency triggers the updating of all open user checkouts
                    // const checkoutUpdateRes = await updateCheckoutBillingAddressAndCurrency(user.basicData.id, checkoutId, billingAddress)
                    // updateData.checkoutRes = checkoutUpdateRes.data.billingAddress;
                    // if(!isEmptyObject(updateData.checkoutRes)){
                    //     // TODO
                    //     const query = new URLSearchParams();
                    //     query.set('is_billable', true)
                    //     history.replace(redirectPath, {
                    //         search: `?${query.toString()}`
                    //     })
                    // }else{
                    //     // TODO: implement
                    //     throw new Error();
                    // }
                }else{
                    throw new Error();
                }
                
            }
        }catch(error){
            setErrorUpdating(true);
            setIsLoading(false)
        }
    }

    const getCountries = async (signal) => {
        try{
            setIsLoading(true);
            const requestParam = {
                localeId: localeCtx.localeId,
            }
            const res = await requestMinimumTimeoutWrapper(getCountriesFn, requestParam, 0, signal);
            if(!signal.aborted){
                setCountries(res.data);
            }
        }catch(error){
            // TODO:
        }finally{
            if(!signal.aborted){
                setIsLoading(false);
            }
        }
    }

    const getStates = async (selectedCountryCode, signal) => {
        try{
            setIsLoading(true);
            const requestParam = {
                countryCode: selectedCountryCode
            };
            const res = await requestMinimumTimeoutWrapper(getStatesFn, requestParam, 0, signal);
            if(!signal.aborted){
                setStates(res.data);
            }
        }catch(error){
            // TODO
        }finally{
            if(!signal.aborted){
                setIsLoading(false);
            }
        }
    }

    const resetCountryAndStates = () => {
        setSelectedCountryCode('')
        setStates(undefined)
    }

    useEffect(()=>{
        if(isEmptyObject(countries)){
            const abortController = new AbortController();
            getCountries(abortController.signal);
            return(() => abortController.abort())
        }
    },[])

    useEffect(() => {
        if(selectedCountryCode){
            const abortController = new AbortController();
            getStates(selectedCountryCode, abortController.signal);
            return(() => abortController.abort())
        }
    },[selectedCountryCode])

    useEffect(() => {
        if(errorUpdating){
            setTimeout(() => {setErrorUpdating(false)}, 3000);
            return (() => {setErrorUpdating(false)});
        }
    },[errorUpdating])

    return (
        <>
        <Grid container className='set-billing-address-main-container' justifyContent='center' alignItems='center'>
            <Grid item className='set-billing-address-content-container' style={styles.contentContainer}>
                <Grid container direction="column">
                    <Grid item container className='checkout-billing-address-edit-pop-up-header'>
                        <Typography variant="body1" style={{fontWeight:"bold"}}>
                            {t("billingAddress")}
                        </Typography>
                    </Grid>
                    <Divider />
                    <Grid item className='checkout-billing-address-edit-pop-up-form-container'>
                        <Grid container direction="column" style={{minHeight:"inherit"}} justifyContent='center'>
                            <Grid item className={`checkout-billing-address-edit-pop-up-form-country ${selectedCountryCode && states ? 'hidden' : ''}`}>
                                {isLoading ?
                                        <LoadingComponent visibleElements='circle' />
                                    :
                                        <TextField label={t("selectBillingCountry")} name="countryCode" value={selectedCountryCode} 
                                        onChange={(e) => {setSelectedCountryCode(e.target.value)}} select fullWidth inputProps={{style:{
                                            textOverflow:'ellipsis'}}}>
                                            {Object.values(countries).map( country => {
                                                return(
                                                <MenuItem key={country.code} value={country.code}>
                                                    {country.description.name}
                                                </MenuItem>
                                                )
                                            })}
                                        </TextField>
                                }
                            </Grid>
                            <Grid item className={`checkout-billing-address-edit-pop-up-form-address ${selectedCountryCode && states ? 'show' : ''}`}>
                                <Grid container direction="column">
                                    {selectedCountryCode && states &&
                                        <form onSubmit={onUpdate}>
                                            <Grid item>
                                                <BackButton isDisabled={isLoading} onClick={resetCountryAndStates} />
                                            </Grid>
                                            <Grid item>
                                                <BillingAddressEditForm selectedCountryCode={selectedCountryCode} states={states} setFormValues={setFormValues} />
                                            </Grid>
                                            <Grid item className='checkout-billing-address-edit-pop-up-form-button'>
                                                <ButtonLoading type="submit" disabled={emptyPropertiesObject(formValues)} isLoading={isLoading} label={t("common:update")} 
                                                variant="outlined" color="primary" fullWidth style={errorUpdating ? styles.error : {}}  />
                                            </Grid>
                                            <Grid item className={`checkout-billing-address-edit-pop-up-feedback-toogle ${errorUpdating ? 'show' : ''}`}>
                                                <Typography variant='body2' style={styles.error}>
                                                    {t("errorUpdating")}
                                                </Typography>
                                            </Grid>
                                        </form>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <SmallFooter onLightBackground={true} hideSocialLinks={true}/>
        </>
    )
}

export default SetBillingAddress