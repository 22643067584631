import { useEffect, useRef, useState } from "react"
import { useTranslation } from 'react-i18next';
import { Grid, Typography, InputAdornment, IconButton } from "@material-ui/core"
import { TextField } from '@mui/material'
import { Visibility, VisibilityOff } from  '@material-ui/icons'
import { isNotEmptyObject, promisingTimeout, textTransform } from '../../aux/aux'
import { isStrongUserPassword, MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from '../../aux/userHelpers'

import ButtonLoading from "../ButtonLoading/ButtonLoading"
import ToggleMsg from "../ToggleMsg/ToggleMsg"
// Style
import './userPasswordMenu.css'
import { setNewUserPassword } from "../../services/userServices"
import { getCustomErrorOrUndefined } from "../../aux/errorHelpers"
import CustomPopover from "../CustomPopover/CustomPopover"
import PopUpSimpleFeedback from "../PopUpSimpleFeedback/PopUpSimpleFeedback";


const UserPasswordMenu = ({ userId, onUpdateStatus }) => {
    const { t } = useTranslation('userProfile', { keyPrefix:'userPasswordMenu' });
    const formFieldsNameArray = ['newPassword', 'newPasswordConfirmation', 'currentPassword']
    const [isLoading, setIsLoading] = useState(false)
    const [newPasswordConfirmationError, setNewPasswordConfirmationError] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const [currentPasswordError, setCurrentPasswordError] = useState(false);
    const [weakPasswordError, setWeakPasswordError] = useState(false);
    const [showTextField, setShowTextField] = useState({});
    const [isFormCompleted, setIsFormCompleted] = useState(false);
    const [formState, setFormState] = useState({});
    const [focusOnNewPassword, setFocusOnNewPassword] = useState(false);
    const [feedbackPopUp, setFeedbackPopUp] = useState({show:false, msg:undefined, type:undefined })
    const abortControllerRef = useRef(null);
    const passwordTextFieldRef = useRef(null);

    const handleOnChange = (e) =>{
        e.preventDefault();
        const field = e.target.name;
        const value = e.target.value;
        const newFormState = { ...formState, [field]: value };
        let passwordConfirmationError = newPasswordConfirmationError;
        let passwordError = weakPasswordError;
        if(field === 'newPassword' || field === 'newPasswordConfirmation'){
            const hasNewPasswordConfirmation = newFormState.hasOwnProperty('newPasswordConfirmation') && newFormState.newPasswordConfirmation !== '';
            const isWrongNewPasswordConfirmation = newFormState.newPassword !== newFormState.newPasswordConfirmation;
            passwordConfirmationError = hasNewPasswordConfirmation && isWrongNewPasswordConfirmation;
            passwordError = newFormState.newPassword && !isStrongUserPassword(newFormState.newPassword)
            setNewPasswordConfirmationError( passwordConfirmationError );
            setWeakPasswordError(passwordError);
        }else{
            setCurrentPasswordError(false);
        }
        // setIsFormCompleted(() => formFieldsNameArray.reduce((isCompleted, fieldName) => { return isCompleted && newFormState[fieldName] }, true) && !passwordConfirmationError && !passwordError)
        setFormState(newFormState);
    }

    const handleVisibility = (e, field) =>{
        e.preventDefault();
        setShowTextField(prev => ({ ...prev, [field]: prev[field] ? !prev[field] : true }))
    }

    const updatePassword = async (e, signal=abortControllerRef.current.signal) => {
        try{
            e.preventDefault();
            setIsLoading(true);
            await setNewUserPassword(userId, formState.currentPassword, formState.newPassword, 0, signal);
            setFeedbackPopUp(prev => ({...prev, show:true, type:'success', msg: textTransform('title', t('passwordSuccessfullyUpdated'))}))
            setFormState({})
        }catch(error){
            const customError = getCustomErrorOrUndefined(error);
            if(customError){
                switch(customError.cause){
                    case 'sr_002':
                        setCurrentPasswordError(true);
                        break;
                    default:
                        setUploadError(true);
                }    
            }else{
                setUploadError(true);
            }
        }finally{
            setIsLoading(false);
        }
    }

    useEffect(() => {
        setIsFormCompleted(() => formFieldsNameArray.reduce((isCompleted, fieldName) => { return isCompleted && formState[fieldName] }, true))
        onUpdateStatus('password', isNotEmptyObject(formState))
    },[formState])


    useEffect(() => {
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        return(() => {
            abortController.abort();
        })
    },[])

    return (
        <div className="user-password-menu-main-container">
            <PopUpSimpleFeedback show={feedbackPopUp.show} onClickClose={()=> setFeedbackPopUp(prev => ({...prev, show:false}))} animationType={feedbackPopUp.type}>
                <Typography variant="body1">
                    {feedbackPopUp.msg}
                </Typography>
            </PopUpSimpleFeedback>
            <Typography variant="h5" className='user-password-menu-main-title'>
                { textTransform('title', t("changePassword")) }
            </Typography>
            <form onSubmit={updatePassword}>
                <div className="user-password-menu-content-container">
                    <div className="user-password-menu-password-group-container">
                        <div className="user-password-menu-password-group-item-container">
                            <CustomPopover 
                                anchorEl={passwordTextFieldRef}
                                open={focusOnNewPassword}
                                anchorOrigin={{vertical:'bottom', horizontal:'center'}} 
                                transformOrigin={{vertical:'top', horizontal:'center'}}
                            >
                                <Typography variant='body2' style={{whiteSpace: 'pre-line'}}>
                                    {
                                        `${textTransform('title', t("lengthBetween", {min: MIN_PASSWORD_LENGTH, max: MAX_PASSWORD_LENGTH}))}`
                                        +`\n${textTransform('title', t("mustContainAtLeast"))}:`
                                        +`\n - ${t("oneNumber")}`
                                        +`\n - ${t("oneCapitalLetter")}`
                                        +`\n - ${t("oneLowerLetter")}`
                                        +`\n - ${t("oneSpecialChar")}`
                                    }
                                </Typography>
                            </CustomPopover>
                            <TextField
                                ref={passwordTextFieldRef} 
                                required 
                                type={showTextField.newPassword ? "text":"password"} 
                                label={textTransform('title', t("newPassword"))}
                                value={formState.newPassword || ''} 
                                name='newPassword'
                                onChange={handleOnChange}
                                onFocus={() => setFocusOnNewPassword(true)}
                                onBlur={() => setFocusOnNewPassword(false)}
                                error={weakPasswordError}
                                helperText={weakPasswordError ? textTransform('title', t("tooWeakPassword")) : undefined}
                                InputLabelProps={{style:{paddingRight:"30px" , boxSizing:"border-box"}}}
                                inputProps={{maxLength: MAX_PASSWORD_LENGTH, textOverflow:'ellipsis'}}
                                InputProps={{endAdornment:(
                                    <InputAdornment >
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={(e) => handleVisibility(e, 'newPassword')}
                                            size="small"
                                            edge="end"
                                        >
                                        {showTextField.newPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )}}
                                fullWidth
                                variant="standard"
                            />
                        </div>
                        <div className="user-password-menu-password-group-item-container">
                            <TextField 
                                required 
                                type={showTextField.newPasswordConfirmation ? "text":"password"} 
                                label={textTransform('title', t("confirmationNewPassword"))}
                                value={formState.newPasswordConfirmation || ''} 
                                name='newPasswordConfirmation'
                                onChange={handleOnChange}
                                error={newPasswordConfirmationError}
                                helperText={newPasswordConfirmationError ? textTransform('title', t("wrongConfirmation")) : undefined}
                                InputLabelProps={{style:{paddingRight:"30px" , boxSizing:"border-box"}}}
                                inputProps={{maxLength: MAX_PASSWORD_LENGTH, textOverflow:'ellipsis'}}
                                InputProps={{endAdornment:(
                                    <InputAdornment >
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={(e) => handleVisibility(e, 'newPasswordConfirmation')}
                                            size="small"
                                            edge="end"
                                        >
                                        {showTextField.newPasswordConfirmation ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )}}
                                fullWidth
                                variant="standard"
                            />
                        </div>
                        <div className="user-password-menu-password-group-item-container">
                            <TextField 
                                required 
                                type={showTextField.currentPassword ? "text":"password"}
                                name='currentPassword' 
                                label={textTransform('title', t("currentPassword"))}
                                value={formState.currentPassword || ''} 
                                onChange={handleOnChange}
                                error={currentPasswordError}
                                helperText={currentPasswordError ? textTransform('title', t("wrongPassword")) : undefined}
                                InputLabelProps={{style:{paddingRight:"30px" , boxSizing:"border-box"}}}
                                inputProps={{maxLength: MAX_PASSWORD_LENGTH, textOverflow:'ellipsis'}}
                                InputProps={{endAdornment:(
                                    <InputAdornment >
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={(e) => handleVisibility(e, 'currentPassword')}
                                            size="small"
                                            edge="end"
                                        >
                                        {showTextField.currentPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )}}
                                fullWidth
                                variant="standard"
                            />
                        </div>
                    </div>
                    <div className="user-password-menu-button-container">
                        <ButtonLoading 
                            isLoading={isLoading}
                            disabled={!isFormCompleted || newPasswordConfirmationError || weakPasswordError}
                            type="submit" 
                            variant="outlined" 
                            color="primary" 
                            label={textTransform('title', t("updatePassword"))}
                            fullWidth
                            />
                        <ToggleMsg
                            isShown={uploadError} 
                            onShowTime={() => {setUploadError(false)}}
                            msg={
                                `${textTransform('title', t("cannotUpdatePassword"))}`
                                +`\n${textTransform('title', t("tryItLater"))}`
                            } 
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}

export default UserPasswordMenu
