import { useEffect, useState, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
// Stripe
import { getStripe } from '../../aux/stripeHelpers';
import { Elements } from '@stripe/react-stripe-js';
// Helpers
import { fromArrayOfObjectsToMap, getFromSafeObject, isEmptyObject, isNotEmptyObject } from '../../aux/aux';
import { clearTimeout_, setTimeout_ } from '../../aux/extendedTimeout';
// API Queries
import { requestMinimumTimeoutWrapper } from '../../aux/requestMethods';
import { 
    getCheckoutContentFn, createPaymentIntentFn,
    updateAndGetCheckoutFn, updateCheckoutStatusFn,
    updatePaymentIntentFn, getCheckoutBillabilityFn
} from '../../aux/purchaseQueries';
// Local Storage
import { getReliableLocale } from '../../aux/sessionHelpers';
// Components
import BackButton from '../../components/BackButton/BackButton';
import CheckoutPaymentIntentFeedback from '../../components/CheckoutPaymentIntentFeedback/CheckoutPaymentIntentFeedback';
import CheckoutOverview from '../../components/CheckoutOverview/CheckoutOverview';
import CheckoutPaymentMethod from '../../components/CheckoutPaymentMethod/CheckoutPaymentMethod';
// MUI
import { rgbToHex } from '@material-ui/core/styles';
import { Grid, Box, Typography, useMediaQuery, Button } from '@material-ui/core'
import { Skeleton } from '@mui/material'
// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import emptyBox from '../../lotties/emptyBox.json';
// SVg & img
import StripeLogo from '../../img/stripe-logo.svg'
// Style
import './checkout.css'
import PurchaseProductSkeleton from '../../components/PurchaseProductSkeleton/PurchaseProductSkeleton'
import { useTheme } from '@material-ui/styles';
import CheckoutPaymentMethodSkeleton from '../../components/CheckoutPaymentMethodSkeleton/CheckoutPaymentMethodSkeleton';
import CheckoutSimpleFeedbackSkeleton from '../../components/CheckoutSimpleFeedbackSkeleton/CheckoutSimpleFeedbackSkeleton';
import CheckoutBillingAddressEditPopUp from '../../components/CheckoutBillingAddressEditPopUp/CheckoutBillingAddressEditPopUp';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import { getUserContactData, getUserDefaultPaymentMethod } from '../../services/userServices';
import { useRedirect } from '../../customHooks/useRedirect';
import { updateAndGetCheckout } from '../../aux/purchaseHelpers';
import CheckoutPay from '../../components/CheckoutPay/CheckoutPay';
import BigFooter from '../../components/BigFooter/BigFooter';
import { getCustomErrorOrUndefined } from '../../aux/errorHelpers';
import BigFooterNoLinks from '../../components/BigFooterNoLinks/BigFooterNoLinks';


const Checkout = ({ checkoutId, user, setUser, addMessages }) => {
    const updateBillingAddressPath = `/${process.env.REACT_APP_CLASS_SUBDOMAIN}/set-billing-address`;
    const locale = getReliableLocale();
    const { t } = useTranslation('checkout');
    const theme = useTheme();
    const history = useHistory();
    const redirectApi = useRedirect();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const matchDesktop = useMediaQuery('(min-width: 1120px)');
    const [checkingBillability, setCheckingBillability] = useState(query.get('is_billable') ? false : true);
    const [isCheckoutBillable, setIsCheckoutBillable] = useState(query.get('is_billable'));
    const [paymentIntentId, setPaymentIntentId] = useState(query.get('payment_intent')); // To show status after purchase
    const [paymentIntentClientSecret, setPaymentIntentClientSecret] = useState('');
    const [checkoutContent, setCheckoutContent] = useState(undefined);
    const [isCheckoutUpdating, setIsCheckoutUpdating] = useState(false);
    const [isCheckoutShowingFeedback, setIsCheckoutShowingFeedback] = useState(false);
    const [appliedPromoCode, setAppliedPromoCode] = useState(undefined);
    const [isCheckoutPaymentMethodReady, setIsCheckoutPaymentMethodReady] = useState(false);
    const [redirectUrl, setRedirectUrl] = useState('');
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
    const [userContactData, setUserContactData] = useState(null);
    const componentNoDesktopStateArray = ['overview', 'payment', 'feedback'];
    const [componentNoDesktopState, setComponentNoDesktopState] = useState(componentNoDesktopStateArray[0]);
    const abortControllerRef = useRef(null);
    const stripeCheckoutAppearance = {
        theme:'stripe',
        variables:{
            fontFamily: theme.typography.body1.fontFamily,
            fontSizeBase: theme.typography.fontSize,
            colorText: rgbToHex(theme.palette.text.primary),
            colorPrimary: theme.palette.primary.main,
        }
    };
    const stripeCheckoutOptions = {
        appearance: stripeCheckoutAppearance,
        clientSecret: paymentIntentClientSecret,
    }

    const getCheckoutBillability = async (checkoutId, userId, signal=abortControllerRef.current.signal) => {
        try{
            const requestParams = { checkoutId, userId};
            const res = await requestMinimumTimeoutWrapper(getCheckoutBillabilityFn, requestParams, 0, signal);
            const data = res.data;
            if(!signal || !signal.aborted){
                if(data.isBillable){
                    setIsCheckoutBillable(true);
                    setCheckingBillability(false);
                }else{
                    const query = new URLSearchParams();
                    query.append('from', location.pathname);
                    query.append('checkout-to-update', checkoutId);
                    history.replace({ 
                        pathname: updateBillingAddressPath,
                        search: `?${query.toString()}` })
                }
            }
        }catch(error){
            if(error.response.data.name === 'CustomError'){
                const data = error.response.data;
                switch(data.cause){
                    case 'db_102':
                        // Checkout was not found or not belong to user          
                        // GO TO: PAGE 404
                        redirectApi.redirectToRoute('/404', {}, true);
                        break;
                }
            }
        }finally{
            if(!signal || !signal.aborted){
                setCheckingBillability(false);
            }
        }

    };

    const setPromoCodeLockTimeout = (promoCode, minimumTimeoutMilliseconds=2000) => {
        const lockDetails = promoCode.lockDetails;
        let lockTimeoutId;
        if(lockDetails.releaseJobId && lockDetails.releaseDate){
            const serverUnlockDate = new Date(lockDetails.releaseDate);
            const remainingMilliseconds = Math.max(minimumTimeoutMilliseconds, serverUnlockDate.getTime() - new Date().getTime());
            lockTimeoutId = setTimeout_(() => {
                try{
                    updateCheckoutContent('takeOutPromoCode', {});
                }catch(error){
                    // TODO: implement
                }
            }, remainingMilliseconds);
        }else{
            lockTimeoutId = null;
        }
        return lockTimeoutId;
    }

    const setPromoCode = (promoCode) => {
        if(appliedPromoCode){
            clearTimeout_(appliedPromoCode.lockDetails.clientReleaseTimeoutId);
        }
        if(promoCode){
            const timeoutId = setPromoCodeLockTimeout(promoCode);
            promoCode.lockDetails['clientReleaseTimeoutId'] = timeoutId;
        }
        setAppliedPromoCode(promoCode); // undefined if op !== 'applyPromoCode'
    };

    // const getCheckoutContent = async (checkoutId, userId, userLocaleId, signal=abortControllerRef.current.signal) => {
    //     try{
    //         const requestParams = { checkoutId, userId, userLocaleId };
    //         const res = await requestMinimumTimeoutWrapper(getCheckoutContentFn, requestParams, 0, signal);
    //         const data = res.data;
    //         if(!isEmptyObject(data)){
    //             const {
    //                 checkout,
    //                 promoCode
    //             } = data;
    //             const {
    //                 productsAndCouponsList,
    //                 subscribedProductsAndCouponsList,
    //                 ...checkoutContent
    //             } = checkout
    //             checkoutContent.productsAndCouponsMap = fromArrayOfObjectsToMap(productsAndCouponsList, 'productId');
    //             checkoutContent.subscribedProductsAndCouponsMap = fromArrayOfObjectsToMap(subscribedProductsAndCouponsList, 'productId');
    //             if(!signal || !signal.aborted){
    //                 setCheckoutContent(checkoutContent);
    //                 setPromoCode(promoCode, signal);
    //                 if(checkoutContent.status !== 'open' && checkoutContent.stripePaymentIntentId){
    //                     setPaymentIntentId(checkoutContent.stripePaymentIntentId);
    //                 }
    //             }
    //         }else{
    //             // Checkout was not found.
    //         }
    //     }catch(error){
    //         if(error.response.data.name === "CustomError"){
    //             const data = error.response.data;
    //             switch(data.cause){
    //                 case 'sr_202':
    //                     // Checkout is not billable
    //             }
    //         }
    //         console.log(error.response)
    //     }
    // };

    const getCheckoutContent = async() => {
        try{
            const data = await updateCheckoutContent('get', {});
            const checkoutContent = data?.updatedCheckoutContent;
            if(checkoutContent && checkoutContent.status !== 'open' && checkoutContent.stripePaymentIntentId){
                setPaymentIntentId(checkoutContent.stripePaymentIntentId);
            }
        }catch(error){
            const customError = getCustomErrorOrUndefined(error);
            if(customError){
                switch(customError.cause){
                    case 'sr_202':
                        // Checkout is not billable
                }
            }
        }
    }


    const requestDefaultPaymentMethod = async (userId, signal=abortControllerRef.current.signal) => {
        let result = {};
        try{
            const res = await getUserDefaultPaymentMethod(userId, 0, signal);
            result = res.data;
        }catch(error){
            console.log('Default payment method no available');
        }finally{
            if(!signal.aborted){
                setDefaultPaymentMethod(result);
            }
        }
    }

    const requestUserContactData = async(userId, signal=abortControllerRef.current.signal) => {
        let result = {};
        try{
            const res = await getUserContactData(userId, 0, signal);
            result = res.data;
        }catch(error){
            // Contact data is collected in the payment element
        }finally{
            if(!signal.aborted){
                setUserContactData(result);
            }
        }
    }

    const getPaymentIntentClientSecret = async (userId, checkoutContent, userLocaleCode, signal=abortControllerRef.current.signal) => {
        try{
            const requestParams = {
                userId,
                checkoutId: checkoutContent.id,
                userLocaleCode,
                paymentMethodUsage : isEmptyObject(checkoutContent.subscriptionProductMap) ? 'on_session' : 'off_session',
                hashFinalAmount: checkoutContent.orderAmounts.hashFinalAmount,
                currencyId: checkoutContent.currencyId,
            };
            const res = await requestMinimumTimeoutWrapper(createPaymentIntentFn, requestParams, 0, signal);
            const redirectUrl = window.location.origin + location.pathname;
            if(!signal || !signal.aborted){
                setRedirectUrl(redirectUrl);
                setPaymentIntentClientSecret(res.data.paymentIntentClientSecret);
            }
        }catch(error){
            // TODO: implement;
        };
    };

    const resetPaymentIntent= () => {
        history.replace({search: ''});
        setIsCheckoutPaymentMethodReady(false);
        setPaymentIntentClientSecret('')
        setPaymentIntentId('')
    }

    // const updatePaymentIntentWithPaymentMethod = async(paymentMethodId, signal=null) => {
    //     const updateIntentParams = {
    //         paymentMethodId,
    //         userId: checkoutContent.userId, 
    //         checkoutId: checkoutContent.id,
    //         paymentIntentClientSecret,
    //     }
    //     return updatePaymentIntent(updateIntentParams, signal)
    // }

    const updatePaymentIntent = async (updateIntentParams, signal=abortControllerRef.current.signal) => {
        try{
            const res = await requestMinimumTimeoutWrapper(updatePaymentIntentFn, updateIntentParams, 0, signal);
            return res
        }catch(error){
            if(!signal || !signal.aborted){
                if(error.response.data.hasOwnProperty('paymentIntentId')){
                    // payment intent was successful
                    setPaymentIntentId(error.response.data.paymentIntentId);
                }else{
                    // Error updating PI on Stripe
                    resetPaymentIntent()
                }
            }

        }
    }

    const updateCheckoutStatus = async ( checkoutStatus, signal=abortControllerRef.current.signal, piClientSecret=paymentIntentClientSecret, userId=checkoutContent.userId, id=checkoutId) => {
        try{
            const requestParams = {
                userId,
                checkoutId:id,
                checkoutStatus,
                paymentIntentClientSecret:piClientSecret,
            };
            const res = await requestMinimumTimeoutWrapper(updateCheckoutStatusFn, requestParams, 0, signal);
            const {
                checkout,
                promoCode
            } = res.data;
            if(!signal || !signal.aborted){
                if(checkout.status !== 'open' && promoCode){
                    if(appliedPromoCode && isNotEmptyObject(appliedPromoCode.lockDetails)){
                        clearTimeout_(appliedPromoCode.lockDetails.clientReleaseTimeoutId);
                        setAppliedPromoCode(prev => ({...prev, lockDetails:promoCode.lockDetails}))
                    }
                }
                setCheckoutContent(prev => ({ ...prev, status: checkout.status }));
            }
            return res.data;
        }catch(error){
            throw error
        }
    }

    const updateCheckoutContent = async (opId, opParams, signal=abortControllerRef.current.signal) => {
        setIsCheckoutUpdating(true);
        try{
            const requestParams = {
                userId: user.basicData.id,
                checkoutId,
                userLocaleId: locale.localeId,
                ...(checkoutContent && checkoutContent.orderAmounts ? {hashFinalAmount: checkoutContent.orderAmounts.hashFinalAmount} : {}),
            }
            const updateIntentParams = {
                userId: user.basicData.id,
                checkoutId,
                paymentIntentClientSecret,
            };
            const res = await updateAndGetCheckout(opId, opParams, requestParams, 0, signal);
            // const res = await requestMinimumTimeoutWrapper(updateAndGetCheckoutFn, requestParams, minTimeoutRequest);
            const data = res.data;
            const {
                productsAndCouponsList,
                subscribedProductsAndCouponsList,
                ...updatedCheckoutContent
            } = data.updatedCheckoutContent
            updatedCheckoutContent.productsAndCouponsMap = fromArrayOfObjectsToMap(productsAndCouponsList, 'productId');
            updatedCheckoutContent.subscribedProductsAndCouponsMap = fromArrayOfObjectsToMap(subscribedProductsAndCouponsList, 'productId');
            if(!signal || !signal.aborted){
                setPromoCode(data.promoCode);
                setCheckoutContent(updatedCheckoutContent);
                if(paymentIntentClientSecret){
                    if(data.needsResetIntent){
                        resetPaymentIntent();
                    }else{
                        updateIntentParams.currencyId = updatedCheckoutContent.currencyId;
                        updateIntentParams.hashFinalAmount = updatedCheckoutContent.orderAmounts.hashFinalAmount;
                        updateIntentParams.paymentMethodUsage = isEmptyObject(updatedCheckoutContent.subscriptionProductMap) ? 'on_session' : 'off_session';
                        await updatePaymentIntent(updateIntentParams);
                    }
                }
            }
            return { ...data, updatedCheckoutContent } ;
        }catch(error){
            // Error handling occurs where updateCheckoutContent was called
            throw error 
        }finally{
            setIsCheckoutUpdating(false);
        }
    }

    const checkoutSyncAfterPIFailureCallback = async () => {
        try{
            if(checkoutContent){
                await updateCheckoutStatus('open');
            }
            if(appliedPromoCode){
                await updateCheckoutContent('takeOutPromoCode', {})
            }
        }catch(error){
            // TODO
            console.log(error)
        }
    } 

    const onClickOnBackTo = (e, checkoutType) => {
        if(checkoutType === 'auto'){
            redirectApi.redirectToRoute('/')
        }else{
            history.goBack();
        }
    };

    const getComponentToRenderInNoDesktop = () => {
        const scrollToTop = () => { window.scrollTo(0,0) }
        let Component;
        switch(componentNoDesktopState){
            case 'overview':
                Component = 
                    <>
                        <div className='checkout-back-button-container'>
                            <BackButton isDisabled={isCheckoutUpdating} onClick={(e, checkoutType=checkoutContent.type) => onClickOnBackTo(e, checkoutType)} />
                        </div>
                        <CheckoutOverview 
                            updateCheckoutContent={updateCheckoutContent} 
                            checkoutContent={checkoutContent} 
                            appliedPromoCode={appliedPromoCode} 
                            isCheckoutUpdating={isCheckoutUpdating}
                            isCheckoutShowingFeedback={isCheckoutShowingFeedback}
                            addMessages={addMessages}
                        />
                        <div className='checkout-next-button-container'>
                            <Button 
                                variant='outlined' 
                                color='primary' 
                                onClick={(e) => setComponentNoDesktopState(componentNoDesktopStateArray[1])}
                                disabled={isCheckoutUpdating || isEmptyObject(checkoutContent?.productsAndCouponsMap)}
                                fullWidth
                            >
                                {t('goToPay')}
                            </Button>
                        </div>
                    </>
                break;
            case 'payment':
                Component = 
                    <>
                        <div className='checkout-back-button-container'>
                            <BackButton isDisabled={isCheckoutUpdating} onClick={(e) => setComponentNoDesktopState(componentNoDesktopStateArray[0])} />
                        </div>
                        <CheckoutPay  
                            checkoutContent={checkoutContent}
                            checkoutSyncAfterPIFailureCallback={checkoutSyncAfterPIFailureCallback}
                            handlerPaymentIntentClientSecret={[paymentIntentClientSecret, setPaymentIntentClientSecret]}
                            setPaymentIntentId={setPaymentIntentId}
                            updateCheckoutStatus={updateCheckoutStatus}
                            updatePaymentIntent={updatePaymentIntent}
                            handlerIsCheckoutPaymentMethodReady={[isCheckoutPaymentMethodReady, setIsCheckoutPaymentMethodReady]}
                            appliedPromoCode={appliedPromoCode}
                            addMessages={addMessages}
                        />
                    </>
                break;
            case 'feedback':
                Component =
                    <CheckoutPaymentIntentFeedback 
                        user={user} 
                        setUser={setUser} 
                        checkoutContent={checkoutContent} 
                        handlePaymentIntentId={[paymentIntentId, resetPaymentIntent]} 
                        checkoutSyncAfterPIFailureCallback={checkoutSyncAfterPIFailureCallback} 
                        callbackToPurchase={() => setComponentNoDesktopState(componentNoDesktopStateArray[1])}
                    />
                break;
        }
        scrollToTop()
        return (
            <div className='checkout-details-container'>
                {Component}
            </div>
        )
    }
    
    useEffect(() => {
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        return(() => {
            abortController.abort();
        })
    },[])
    
    useEffect(() => {
        if(checkoutContent){
            if(!isEmptyObject(checkoutContent.productsAndCouponsMap)){
                // setIsEmptyCheckout(false);
                if(defaultPaymentMethod === null){
                    requestDefaultPaymentMethod(user.basicData.id);
                }
                if(!userContactData){
                    requestUserContactData(user.basicData.id);
                }
                if(!paymentIntentClientSecret && !paymentIntentId){
                    // console.log('INFO: getting paymentIntent')
                    getPaymentIntentClientSecret(user.basicData.id, checkoutContent, locale.localeCode);
                }
            }else{
                // setIsEmptyCheckout(true);
            }
        }else{
            if(isCheckoutBillable){
                // getCheckoutContent(checkoutId, user.basicData.id, locale.localeId);
                getCheckoutContent();
            }else{
                getCheckoutBillability(checkoutId, user.basicData.id);
            }
        }
    },[checkoutId, isCheckoutBillable, checkoutContent, paymentIntentClientSecret, paymentIntentId])
    
    
    useEffect(() => {
        if(paymentIntentId && !matchDesktop){
            setComponentNoDesktopState(componentNoDesktopStateArray[2]);
        }
    }, [paymentIntentId, matchDesktop])
    

    return (
        <div className='checkout-wrapper'>
            {checkingBillability ?
                <div className='checkout-loading-container' >
                    <LoadingComponent visibleElements='circle'/>
                </div>
            :
                matchDesktop ? 
                    <div className='checkout-main-container'>
                        <div className='checkout-main-background'/>
                        <div className='checkout-back-button-container'>
                            <BackButton isDisabled={isCheckoutUpdating} onClick={(e, checkoutType=checkoutContent.type) => onClickOnBackTo(e, checkoutType)} />
                        </div>
                        <div className='checkout-details-container'>
                            <div className='checkout-items-overview-wrapper'>
                                <div className='checkout-side-to-side-container'>
                                    <CheckoutOverview 
                                        updateCheckoutContent={updateCheckoutContent} 
                                        checkoutContent={checkoutContent} 
                                        appliedPromoCode={appliedPromoCode} 
                                        isCheckoutUpdating={isCheckoutUpdating}
                                        isCheckoutShowingFeedback={isCheckoutShowingFeedback}
                                        addMessages={addMessages}
                                    />
                                </div>
                            </div>
                            <div className='checkout-payment-wrapper'>
                                <div className='checkout-side-sticky-container'>
                                    {paymentIntentId ?
                                        // checkoutContent ?
                                            <CheckoutPaymentIntentFeedback user={user} setUser={setUser} checkoutContent={checkoutContent} handlePaymentIntentId={[paymentIntentId, resetPaymentIntent]} 
                                            checkoutSyncAfterPIFailureCallback={checkoutSyncAfterPIFailureCallback} callbackToPurchase={() => setIsCheckoutShowingFeedback(false)}/>
                                        // :
                                            // <CheckoutSimpleFeedbackSkeleton />
                                    :
                                        <CheckoutPay  
                                            checkoutContent={checkoutContent}
                                            checkoutSyncAfterPIFailureCallback={checkoutSyncAfterPIFailureCallback}
                                            handlerPaymentIntentClientSecret={[paymentIntentClientSecret, setPaymentIntentClientSecret]}
                                            setPaymentIntentId={setPaymentIntentId}
                                            updateCheckoutStatus={updateCheckoutStatus}
                                            updatePaymentIntent={updatePaymentIntent}
                                            handlerIsCheckoutPaymentMethodReady={[isCheckoutPaymentMethodReady, setIsCheckoutPaymentMethodReady]}
                                            appliedPromoCode={appliedPromoCode}
                                            addMessages={addMessages}
                                        />
                                        // <Grid container direction='column' justifyContent='center' alignContent='center' style={{height:"100%"}}>
                                            // isEmptyCheckout ?
                                            //     <>
                                            //         <Grid item align='center'>
                                            //             <Typography variant='h5'>
                                            //                 {t("cartEmpty")}
                                            //             </Typography>
                                            //         </Grid>
                                            //         <Grid item>
                                            //             <Box className='checkout-payment-methods-animation-container' ref={animationContainerRef}>
                                            //                 { animationRef.current?.play() }
                                            //             </Box>
                                            //         </Grid>
                                            //     </>
                                            // :
                                                    /* {paymentIntentClientSecret && redirectUrl && defaultPaymentMethod && userContactData &&
                                                        <Grid item style={{width:"100%"}}>
                                                            <Elements options={stripeCheckoutOptions} stripe={getStripe()} >
                                                                <CheckoutPaymentMethod
                                                                    checkoutId={checkoutId}
                                                                    checkoutProductsMap={checkoutContent.productsAndCouponsMap} 
                                                                    addMessages={addMessages} 
                                                                    contactData={userContactData}
                                                                    billingAddress={checkoutContent} 
                                                                    redirectUrl={redirectUrl} 
                                                                    setPaymentIntentId={setPaymentIntentId} 
                                                                    setIsReady={setIsCheckoutPaymentMethodReady} 
                                                                    isReady={isCheckoutPaymentMethodReady} 
                                                                    defaultPaymentMethod={isEmptyObject(defaultPaymentMethod) ? null : defaultPaymentMethod}
                                                                    finalAmountObject={ {hashFinalAmount: checkoutContent.orderAmounts.hashFinalAmount, currencyId: checkoutContent.currencyId} } 
                                                                    handleIsCheckoutUpdating={[isCheckoutUpdating, setIsCheckoutUpdating]} 
                                                                    updateCheckoutStatus={updateCheckoutStatus}
                                                                    checkoutSyncAfterPIFailureCallback={checkoutSyncAfterPIFailureCallback}
                                                                    updatePaymentIntentWithPaymentMethod={updatePaymentIntentWithPaymentMethod} 
                                                                />
                                                            </Elements>
                                                        </Grid>
                                                    }
                                                    {!isCheckoutPaymentMethodReady &&
                                                        <Grid item style={{width:"100%"}}>
                                                            <CheckoutPaymentMethodSkeleton />
                                                        </Grid>
                                                    }
                                                    <Grid item className="checkout-payment-methods-stripe-logo" align="right">
                                                        <StripeLogo height={20} />
                                                    </Grid> */
                                        // </Grid>
                                    }   
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='checkout-main-container'>
                        {getComponentToRenderInNoDesktop()}
                    </div>
                
            }
            <BigFooterNoLinks user={user} />
            {/* <BigFooter /> */}
        </div>
    )
}

export default Checkout