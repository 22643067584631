import { useState } from 'react'
import { Grid, Typography, Divider } from '@material-ui/core'
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import remarkUnwrapImages from 'remark-unwrap-images'
import { defaultSchema } from 'rehype-sanitize';
import { useTheme } from '@material-ui/styles'
import { AddCircleOutline } from '@material-ui/icons'
import './faqItem.css'
import { display } from '@mui/system'
import MarkdownDisplay from '../MarkdownDisplay/MarkdownDisplay';
import { Link } from 'react-router-dom';

const customSanitizeSchema = {
      ...defaultSchema,
      attributes: {
       ...defaultSchema.attributes,
        p: [
          ...(defaultSchema.attributes.p || []),
          ['className']
        ]
    }
}

const FAQItem = ({ item, style, innerStyles={questionContainer:{}, answerContainer:{}, verticalLine:{}, icon:{}} }) => {
    const theme = useTheme()
    const [isOpen, setIsOpen] = useState(false);
    const styles = {
        wrapper: style,
        questionContainer:innerStyles.questionContainer,
        answerContainer:innerStyles.answerContainer,
        icon: {
            display:'block',
            height: '24px',
            transform: `rotate( ${isOpen ? '45deg' : '0deg'})`,
            ...innerStyles.icon,
        },
        question:{
            fontWeight: 'bold',
        },
        question:{
            fontWeight: 'bold',
        },
        verticalLine:{
            backgroundColor: theme.palette.primary.lighter,
            ...innerStyles.verticalLine
        }
    }
    const markdownComponentsMapping = {
        h1: (node, ...props) => <><Typography variant="h5" {...node.node.properties} children={node.children} /> <Divider style={ innerStyles.divider }/> </>,
        h2: (node, ...props) => <Typography variant="h6" {...node.node.properties} children={node.children} />,
        p:  (node, ...props) => <Typography variant="body1" {...node.node.properties} children={node.children}/>,
        li: (node, ...props) => <li><Typography variant="body1" {...node.node.properties} children={node.children}/></li>,
        a: (node, ...props) => {
            if(node.type === 'local'){
                return <Link to={node.href} children={node.children}/> 
            }else{
                return <a {...node.node.properties} children={node.children} />
            }
        }
    };
    const onClick = (e) => {
        e.preventDefault();
        setIsOpen(prev => !prev);
    }
    return (
        item ? 
            <Grid container className='faq-item-main-container' style={styles.wrapper}>
                <Grid item container className='faq-item-question-container' onClick={onClick}>
                    <Grid item xs>
                        <Typography variant='body1' style={styles.question}>
                            {item.q}
                        </Typography>
                    </Grid>
                    <Grid item className='faq-item-question-icon-container'>
                        <AddCircleOutline className='faq-item-question-icon' style={styles.icon}/>
                    </Grid>
                </Grid>
                <Grid item container className={`faq-item-answer-container ${isOpen ? 'show' : ''}`} style={styles.answerContainer}>
                    <div className='faq-item-answer-vertical-line' style={styles.verticalLine}/>
                    <Grid item xs>
                        <ReactMarkdown children={item.a} remarkPlugins={[remarkUnwrapImages]} rehypePlugins={[rehypeRaw, [rehypeSanitize, customSanitizeSchema]]} 
                        className='faq-item-answer-text' components={markdownComponentsMapping} includeElementIndex={true}
                        />
                    </Grid>
                </Grid>
            </Grid>
            :
            null
    )
}

export default FAQItem