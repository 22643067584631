// import TextField from '@mui/material/TextField';
import { TextField } from '@material-ui/core';
import './basicGroupItemConfigSelector.css'
const BasicGroupItemConfigSelector = ({itemArray, selectedItemIndex, onSelectItem, disabled, defaultItemIndex=null, CollapsedItemComponent=null, ...props}) => {
    
    const handleOnchange = (e) => {
        e.preventDefault();
        const selectedIndex = e.target.value;
        const selectedOption = itemArray[selectedIndex]
        if(typeof onSelectItem === 'function'){
            onSelectItem(selectedIndex, selectedOption)
        }
    }

    const getCollapsedItemComponentToRender = (selectedIndex) => {
        const productConsumptionOption = itemArray[selectedIndex]
        return(
            <CollapsedItemComponent productConsumptionOption={productConsumptionOption} />
        )
    }

    return(
        <div className='class-basic-group-item-config-selector'>
            <TextField 
                name='configSelector'
                value={selectedItemIndex}
                defaultValue={defaultItemIndex}
                onChange={handleOnchange} 
                inputProps={{style:{textOverflow:'ellipsis'}}}
                select 
                fullWidth
                variant='standard'
                disabled= {disabled}
                required
                SelectProps={
                    CollapsedItemComponent ? {
                        renderValue: getCollapsedItemComponentToRender
                    }
                    :
                    null
                }
                >
                    {props.children}
            </TextField>
        </div>
    )
}

export default BasicGroupItemConfigSelector