import { Grid } from '@material-ui/core'
import { Skeleton } from '@mui/material'
import './checkoutPaymentMethodSkeleton.css'

const CheckoutPaymentMethodSkeleton = () => {
  return (
    <Grid className='checkout-payment-method-skeleton-main-container' container direction="column" justifyContent='center' alignContent='center' style={{height:"100%"}}>
        <Grid item className='checkout-skeleton-item'>
            <Skeleton className='checkout-skeleton-item' variant="rectangular" height={70} width="100%" />
        </Grid>
        <Grid item className='checkout-skeleton-item'>
            <Skeleton className='checkout-skeleton-item' variant="rectangular" height={40} width="100%" />
        </Grid> 
    </Grid> 
  )
}

export default CheckoutPaymentMethodSkeleton