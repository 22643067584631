// Treat the function as a function expression instead of a function declartion
// This allow us to immediately invoke the function 
// Note: '!' Changes the way the runtime parses the function
// !function(){if(!window.klaviyo){window._klOnsite=window._klOnsite||[];try{window.klaviyo=new Proxy({},{get:function(n,i){return"push"===i?function(){var n;(n=window._klOnsite).push.apply(n,arguments)}:function(){for(var n=arguments.length,o=new Array(n),w=0;w<n;w++)o[w]=arguments[w];var t="function"==typeof o[o.length-1]?o.pop():void 0,e=new Promise((function(n){window._klOnsite.push([i].concat(o,[function(i){t&&t(i),n(i)}]))}));return e}}})}catch(n){window.klaviyo=window.klaviyo||[],window.klaviyo.push=function(){var n;(n=window._klOnsite).push.apply(n,arguments)}}}};
import { Buffer } from "buffer";
import { getCookieByNameOrUndefined, isUserLogged } from "./sessionHelpers";

// const KLAVIYO_TRACK_COOKIE_NAME = '__kla_id';

// const getKlaviyo = () => {
//     return window.klaviyo
// }

const REQUEST_TIMEOUT_MS = 3000;

const klaviyoWrapper = {
    getKlaviyo: function(){return window.klaviyo},
    eventNameMap:{
        viewedPage: 'Viewed Page',
        loggedIn: 'Logged in',
        loggedOut: 'Logged out',
        recoverySession: 'Recovery Session'
    },
    KLAVIYO_TRACK_COOKIE_NAME: '__kla_id',
    getDecodedCookieOrUndefined : function(){
        const cookie = getCookieByNameOrUndefined(this.KLAVIYO_TRACK_COOKIE_NAME);
        return cookie ? JSON.parse(Buffer.from(cookie.split('.')[0], 'base64')) : undefined;
    },
    /**
     * It is browser/user cookied or identified
     */
    isCookied : async function(){
        let result;
        const klaviyo = this.getKlaviyo();
        if(klaviyo != null){
            const promise = klaviyo.isIdentified(); // isCookied
            if(process.env.REACT_APP_TARGET_ENV === 'development'){
                // Handle ProgressEvent error when CORS and HTTP request instead of HTTPS
                result = Promise.race([promise, new Promise((resolve, reject) => setTimeout(reject(new Error('timed out')), REQUEST_TIMEOUT_MS))])
            }else{
                result = promise;
            }
        }else{
            result = new Promise((resolve, reject) => reject(new Error('Mkt provider unavailable from client-side')))
        }
        return result
            .catch(error => {
                console.error('Mkt provider not accessible from client-side')
                return new Promise((resolve, reject) => reject(error))
            })
    },
    /**
     * identify email with cookied browser/user and its further activity
     */
    idenfifyProfile: async function(email, properties={}){
        let result;
        const klaviyo = klaviyoWrapper.getKlaviyo();
        if(klaviyo != null){
            const promise =  klaviyo.identify({ email, ...properties });
            if(process.env.REACT_APP_TARGET_ENV === 'development'){
                // Handle ProgressEvent error when CORS and HTTP request instead of HTTPS
                result = Promise.race([promise, new Promise((resolve, reject) => setTimeout(reject(new Error('timed out')), REQUEST_TIMEOUT_MS))])
            }else{
                result = promise
            }
        }else{
            result = new Promise((resolve, reject) => reject(new Error('Mkt provider unavailable from client-side')))
        }
        return result
            .catch(error => {
                console.error('Mkt provider unable to identify profile from client-side')
                return new Promise((resolve, reject) => reject(error))
            })
    },
    /**
     * Track event on cookied browser/user
     * @param {*} eventName 
     * @param {*} eventDetails 
     */
    trackEvent: async function(eventName, eventDetails={}){
        let result;
        const klaviyo = this.getKlaviyo();
        if(klaviyo != null){
            if(this.eventNameMap.hasOwnProperty(eventName)){
                const promise = klaviyo.track(this.eventNameMap[eventName], eventDetails)
                if(process.env.REACT_APP_TARGET_ENV === 'development'){
                    // Handle ProgressEvent error when CORS and HTTP request instead of HTTPS
                    result = Promise.race([promise, new Promise((resolve, reject) => setTimeout(reject(new Error('timed out')), REQUEST_TIMEOUT_MS))])
                }else{
                    result = promise;
                }
            }
        }else{
            result = new Promise((resolve, reject) => reject(new Error('Mkt provider unavailable from client-side')))
        }
        return result
            .catch(error => {
                console.error('Mkt provider unable to track path from client-side')
                return new Promise((resolve, reject) => reject(error))
            })
    }
}


export default klaviyoWrapper