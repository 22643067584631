import { createContext, useEffect, useState } from "react"
import { getFromSafeObject, getSafeDateOrUndefined, isEmptyObject, isNotEmptyObject } from "../aux/aux";
import { useLocaleCtx } from "../customHooks/useLocaleCtx";
import { getDisplayablePromoCode } from "../services/staticContentServices";
import { useRef } from "react";

const DisplayablePromoCodeContext = createContext(null);

const DisplayablePromoCodeProvider = ({ children }) => {
    const REQUEST_INTERVAL_MS = 120000 // 2 min
    const MAX_REQUESTED_TRIALS = 5;
    const localeCtx = useLocaleCtx();
    const [displayablePromoCode, _setDisplayablePromoCode] = useState({});
    const [updateDisplayDate, setUpdateDisplayDate] = useState(null);
    const requestAttemptsRef = useRef(0);

    const setDisplayablePromoCode = (displayablePromoCode) => {
        let updatedPromoCode;
        if(isNotEmptyObject(displayablePromoCode)){
            updatedPromoCode = displayablePromoCode;
            const displayability = getFromSafeObject(updatedPromoCode,'details.occurrence.details.displayability')
            const displayEndsDate = getSafeDateOrUndefined(displayability.displayEndsDate); 
            if(displayEndsDate){
                setUpdateDisplayDate(displayEndsDate);
            }
        }else{
            updatedPromoCode = {};
            const now = new Date();
            now.setMilliseconds(now.getMilliseconds() + REQUEST_INTERVAL_MS);
            const displayEndsDate = now;
            if(displayEndsDate){
                setUpdateDisplayDate(displayEndsDate);
            }
        }
        _setDisplayablePromoCode(updatedPromoCode);
    }
    
    const requestDisplayablePromoCode = async(localeId, abortSignal) => {
        try{
            const res = await getDisplayablePromoCode(localeId, {}, 0, abortSignal);
            const displayablePromoCode = res.data;
            if(!abortSignal.aborted){    
                requestAttemptsRef.current = 0;
                setDisplayablePromoCode(displayablePromoCode);
            }
        }catch(error){ 
            if(requestAttemptsRef.current < MAX_REQUESTED_TRIALS){
                setDisplayablePromoCode(null)
            }
            // STOP REQUESTING
        }
    }
    
    useEffect(() => {
        if(updateDisplayDate instanceof Date){
            const now = new Date();
            const timeoutMs = updateDisplayDate.getTime() - now.getTime();
            const abortController = new AbortController()
            const timeoutId = setTimeout(() => requestDisplayablePromoCode(localeCtx.localeId, abortController.signal), timeoutMs);
            return(() => {
                abortController.abort();
                clearTimeout(timeoutId)
            });
        }
    }, [updateDisplayDate])

    useEffect(() => {
        const abortController = new AbortController()
        requestDisplayablePromoCode(localeCtx.localeId, abortController.signal);
        return(() => {
            abortController.abort();
        })
    },[])
    return(
        <DisplayablePromoCodeContext.Provider value={displayablePromoCode}>
            { children }
        </DisplayablePromoCodeContext.Provider>
    )
}

export { DisplayablePromoCodeProvider, DisplayablePromoCodeContext }