import { useContext } from "react";
import { DisplayablePromoCodeContext } from "../customContext/displayablePromoCodeProvider";

export function useDisplayablePromoCodeCtx(){
    const ctx = useContext(DisplayablePromoCodeContext);
    if(!ctx){
        throw new Error("useDisplayablePromoCodeCtx must be used within the DisplayablePromoCode context provider")
    }
    return ctx
}
