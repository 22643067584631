import { textTransform } from '../../aux/aux'
import AddToSubscriptionNoModalDialog from '../AddToSubscriptionNoModalDialog/AddToSubscriptionNoModalDialog'
import PopUpModal from '../PopUpModal/PopUpModal'
import PopUpModalHeader from '../PopUpModalHeader/PopUpModalHeader'
import CableIcon from '@mui/icons-material/Cable';
import { Divider } from '@material-ui/core';
import './addToSubscriptionModal.css'
import { useTranslation } from 'react-i18next';

const AddToSubscriptionModal = ({ showModal, onClickClose, onSuccess, productIdentifier, setUser, user, height=null, width=null}) => {
    const { t } = useTranslation('common', { keyPrefix: 'addToSubscriptionNoModalDialog'})
    return (
        <PopUpModal 
            showModal={showModal} 
            height={height} 
            width={width}
            isDialog={true}
        >
            <PopUpModalHeader
                title={textTransform('title', t('addToSubscription'))}
                Icon={CableIcon}
            />
            <Divider/>
            <AddToSubscriptionNoModalDialog
                user={user} 
                setUser={setUser}
                productIdentifier={productIdentifier}
                onClose={onClickClose}
                onSuccess={onSuccess}
            />
        </PopUpModal>
    )
}

export default AddToSubscriptionModal