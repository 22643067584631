import { useTranslation } from 'react-i18next'
import Notes from '@mui/icons-material/Notes';
import List from '@mui/icons-material/List';
import './classSideBarMenu.css'
import { Button, Grid, IconButton } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { textTransform } from '../../aux/aux';
import { useState } from 'react';

const ClassSideBarMenu = ({ selectedItemId, onSelectItem, itemList=[] }) => {
    const { t } = useTranslation('common'); 
    const theme = useTheme();
    const styles = {
        mainContainer: {
        },
        itemWrapper:{
        },
        selectedItemWrapper:{
            border: `1px 1px 0px 1px solid ${theme.palette.grey[100]}`,
            borderRadius: '4px 4px 0px 0px',
            backgroundColor: theme.palette.background.paper
        }
    };

    const handleOnClickMenuItem = (e) => {
        const targetId = e.currentTarget.id;
        const itemId = targetId.replace('Button', '');
        onSelectItem(itemId);
    }

    return (
        <div className='class-side-bar-menu-main-container' style={styles.mainContainer}>
            {itemList.map((itemObject, index) => {
                const Icon = itemObject.Icon;
                const disabled = itemObject.isDisabled;
                const id = itemObject.id;
                const label = itemObject.label
                const isSelected = selectedItemId === id;
                return(
                    <div key={index} className='class-side-bar-menu-item-wrapper' style={{...styles.itemWrapper, ...(isSelected ? styles.selectedItemWrapper : {})}}>
                        {label ?
                            <Button 
                                className='class-content-config-hub-button' 
                                id={id}
                                fullWidth
                                startIcon={<Icon />} 
                                disabled={disabled}
                                onClick={handleOnClickMenuItem}
                            >
                                {label}
                            </Button>
                            :
                            <IconButton
                                className='class-content-config-hub-button' 
                                id={id}
                                disabled={disabled}
                                onClick={handleOnClickMenuItem}
                            >
                                <Icon />
                            </IconButton>
                        }
                    </div>
                )
            })}
        </div>
    )
}

export default ClassSideBarMenu