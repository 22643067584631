import { useState } from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core';
import CheckoutBillingAddressEditPopUp from '../CheckoutBillingAddressEditPopUp/CheckoutBillingAddressEditPopUp';
import { LocationOn } from '@material-ui/icons';
import { Edit } from '@material-ui/icons';
import { useTheme } from '@material-ui/styles';
import { Skeleton } from '@mui/material'
import { isNotEmptyObject } from '../../aux/aux';
import './checkoutBillingAddress.css'

const CheckoutBillingAddress = ({ updateBillingAddress, checkoutContent, isCheckoutUpdating }) => {
    const theme = useTheme()
    const [showEditPopUp, setShowEditPopUp] = useState(false);
    const styles = {
        mainContainer:{
            // borderColor: theme.palette.grey[200], //'#eeeeee',
            backgroundColor: theme.palette.grey[100],
        },
        addressInfoContainer:{
            color: theme.palette.grey[600],
        }
    }
    return (
        <>
            { showEditPopUp &&
                <CheckoutBillingAddressEditPopUp handleShowPopUp={[showEditPopUp, setShowEditPopUp]} updateBillingAddress={updateBillingAddress} />
            }
            <div className='checkout-billing-address-main-container' style={styles.mainContainer}>
                <Grid container direction='row' alignItems='center'>
                    <Grid item >
                        <LocationOn className='checkout-billing-address-location-icon' htmlColor={theme.palette.grey[400]} />
                    </Grid>
                    {isNotEmptyObject(checkoutContent)? 
                        <>
                            <Grid item >
                                <Grid container direction='column' className='checkout-billing-address-address-container' style={styles.addressInfoContainer}>
                                    { checkoutContent.line &&
                                        <Grid item>
                                            <Typography variant='body2'>
                                                {`${checkoutContent.line.substring(0,30)}${checkoutContent.line.length > 30 ? '...' : ''}`}
                                            </Typography>
                                        </Grid>
                                    }
                                    <Grid item>
                                        <Grid container direction='row'>
                                            { checkoutContent.city &&
                                                <Grid item>
                                                    <Typography variant='body2'>
                                                        {`${checkoutContent.city.substring(0,10)}${checkoutContent.city.length > 10 ? '...' : ''}, `}
                                                    </Typography>
                                                </Grid>
                                            }
                                            { checkoutContent.stateCode &&
                                                <Grid item>
                                                    <Typography variant='body2'>
                                                        {`\u00A0${checkoutContent.stateCode.substring(0,5)}${checkoutContent.stateCode.length > 5 ? '...' : ''}`}
                                                    </Typography>
                                                </Grid>
                                            }
                                            { checkoutContent.zipCode &&
                                                <Grid item>
                                                    <Typography variant='body2'>
                                                        {`\u00A0${checkoutContent.zipCode.substring(0,10)}${checkoutContent.zipCode.length > 10 ? '...' : ''}`}
                                                    </Typography>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                    { checkoutContent.countryCode &&
                                        <Grid item>
                                            <Typography variant='body2'>
                                                {`${checkoutContent.countryCode.substring(0,30)}${checkoutContent.countryCode.length > 30 ? '...' : ''}`}
                                            </Typography>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs align='right'>
                                <IconButton onClick={e => {setShowEditPopUp(true)}} disabled={isCheckoutUpdating} >
                                    <Edit htmlColor={isCheckoutUpdating ? theme.palette.grey[400] : theme.palette.grey[700]}/>
                                </IconButton>
                            </Grid>
                        </>
                        :
                        <Grid item xs>
                            <Skeleton variant='text' width={"75%"} />
                            <Skeleton variant='text' width={"50%"} />
                            <Skeleton variant='text' width={"25%"} />
                        </Grid>
                    }
                </Grid>
            </div>
        </>
    )
}

export default CheckoutBillingAddress