import { Grid } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import './contentSliderDots.css'

const ContentSliderDots = ({ currentIndex, onGoToStep, numSteps, stepSize=1 }) => {
    const theme = useTheme()
    const styles = {
        dot: {
            backgroundColor: theme.palette.grey[400],
        },
        selectedDot:{
            backgroundColor: theme.palette.secondaryLowContrast.main,
        }
    }
    const handleOnClick = (e) => {
        e.preventDefault();
        const stepSelected =  e.currentTarget.id;
        onGoToStep(stepSelected);
    }
    return (
        <div className='content-slider-dots-wrapper'>
            <Grid container className='content-slider-dots-main-container' justifyContent='center'>
                {new Array(numSteps).fill(0).map(( _, index ) => {
                    const step = stepSize * index  
                    const isSelected = currentIndex === step;
                    return(
                        <div key={index} id={step} className='content-slider-dots-dot-wrapper' onClick={handleOnClick}>
                            <div className={`content-slider-dots-dot ${isSelected ? 'selected' : ''}`} style={ isSelected ? styles.selectedDot : styles.dot }/>
                        </div>  
                    )
                })}
            </Grid>
        </div>
     )
}

export default ContentSliderDots