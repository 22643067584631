import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core'
import { textTransform } from '../../aux/aux'
import './buttonContentComplete.css'
import ButtonLoading from '../ButtonLoading/ButtonLoading';
import ToggleMsg from '../ToggleMsg/ToggleMsg';

const ButtonContentComplete = ({ isDisabled, isCompleted, onCompleteLecture, setShowModal, className }) => {
    const { t } = useTranslation('classroom', { keyPrefix: 'buttonContentComplete'})
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleOnClick = async (newIsCompletedValue) => {
        try{
            setIsLoading(true);
            await onCompleteLecture(newIsCompletedValue)
            if(newIsCompletedValue){
                setShowModal(true);
            }
        }catch(error){
            setError(true);
        }finally{
            setIsLoading(false)
        }
    }

    return (
        <div className={className}>
            <div className='button-content-complete-container'>
                <ButtonLoading 
                    variant={isCompleted ? 'contained': 'outlined'} 
                    color='primary' 
                    onClick={(e) => {handleOnClick(!isCompleted)}} 
                    disabled={isDisabled} 
                    label={isCompleted ? t('leaveNoCompleted') : t('markAsCompleted') }
                    isLoading={isLoading}
                />
                <ToggleMsg msg={t("cannotMarkAsStatus", {status: isCompleted ? t('completed') : t('uncompleted')})} isShown={error} onShowTime={() => setError(false)}/>
            </div>
        </div>
    )
}

export default ButtonContentComplete
