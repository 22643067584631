import { useEffect, useMemo, useRef } from 'react';
import { getFromSafeObject, isEmptyObject, isNotEmptyObject, textTransform } from '../../aux/aux';
import { useState } from 'react';
import './selectProductConsumptionOption.css';
import SelectProductConsumptionType from '../SelectProductConsumptionType/SelectProductConsumptionType';
import { isSubscriptionOrUpgradeSubscriptionProductTypeName } from '../../aux/productHelpers';
import ButtonLoading from '../ButtonLoading/ButtonLoading';
import { useTranslation } from 'react-i18next';
// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import emptyBox from '../../lotties/emptyBox.json';
import { Typography } from '@material-ui/core';

const SelectProductConsumptionOption = ({ 
    productConsumptionOptionMap, 
    userProductPurchasabilityDetailsMap, 
    onSelectOptionType, 
    onSelectOption, 
    onConfirm, 
    selectedProductConsumptionTypeName, 
    selectedProductConsumptionOption 
}) => {
    const {t} = useTranslation('common');
    const [animationContainer, setAnimationContainerRef] = useState(null);
    const animationRef = useRef(null);
    const [selectedUserPurchasabilityDetails, setSelectedUserPurchasabilityDetails] = useState(null);
    const mainProductConsumptionOptionRef = useRef(null);
    const userProductTypeConsumptionMap = useMemo(() => {
        let result = null;
        if(productConsumptionOptionMap && userProductPurchasabilityDetailsMap){
            result = {};
            let justMainOption;
            if(isNotEmptyObject(productConsumptionOptionMap.main)){
                const mainProductConsumptionOption = productConsumptionOptionMap.main; 
                mainProductConsumptionOptionRef.current = mainProductConsumptionOption
                const productConsumptionTypeName = mainProductConsumptionOption.productTypeName;
                justMainOption = userProductPurchasabilityDetailsMap[mainProductConsumptionOption.id].isPurchasable === false;
                // is a groupable productConsumptionType => Group with alt productConsumptioType and delete it from there
                // is not groupable => create productConsumptionType
                const productConsumptionType = {
                    productConsumptionOptionArray: [productConsumptionOptionMap.main],
                    productConsumptionOptionBestPriceIndex: 0,
                    productConsumptionOptionDefaultIndex: 0
                };
                if(isNotEmptyObject(productConsumptionOptionMap.alt) && productConsumptionOptionMap.alt[productConsumptionTypeName]){
                    const groupableProductConsumptionType = productConsumptionOptionMap.alt[productConsumptionTypeName];
                    const groupableProductConsumptionOptionArray = groupableProductConsumptionType.productConsumptionOptionArray;
                    if(groupableProductConsumptionOptionArray.length > 0){
                        const groupableProductConsumptionOptionWithBestPrice =  groupableProductConsumptionOptionArray[groupableProductConsumptionType.productConsumptionOptionBestPriceIndex];
                        const groupableBestPice = getFromSafeObject(groupableProductConsumptionOptionWithBestPrice, 'consumptionDetails.details.purchasabilityDetails.details.amount');
                        const mainProductConsumptionOptionPrice = getFromSafeObject(mainProductConsumptionOption, 'consumptionDetails.details.purchasabilityDetails.details.amount');
                        if(groupableBestPice > mainProductConsumptionOptionPrice){
                            productConsumptionType.productConsumptionOptionBestPriceIndex = groupableProductConsumptionType.productConsumptionOptionBestPriceIndex;
                        }
                        if(groupableProductConsumptionType.productConsumptionOptionDefaultIndex !== undefined){
                            productConsumptionType.productConsumptionOptionDefaultIndex = groupableProductConsumptionType.productConsumptionOptionDefaultIndex;
                        }
                        productConsumptionType.productConsumptionOptionArray.push(...groupableProductConsumptionOptionArray);
                    }
                }
                result[productConsumptionTypeName] = productConsumptionType;
            }else{
                justMainOption = false;
            }
            if(!justMainOption && isNotEmptyObject(productConsumptionOptionMap.alt)){
                const altProductConsumptionOptionMap = productConsumptionOptionMap.alt;
                const filterProductConsumptionOptionMap = {};
                Object.entries(altProductConsumptionOptionMap).forEach(([productConsumptionTypeName, productConsumptionType]) => {
                    if(!result.hasOwnProperty(productConsumptionTypeName)){
                        const altProductConsumptionOptionDefaultIndex = productConsumptionType.productConsumptionOptionDefaultIndex
                        const filterProductConsumptionOptionArray = [];
                        let auxBestPrice; 
                        let filterProductConsumptionOptionDefaultIndex;
                        let filterProductConsumptionOptionBestPriceIndex;
                        Object.values(productConsumptionType.productConsumptionOptionArray).forEach((productConsumptionOption, index) => {
                            const userProductPurchasabilityDetails = userProductPurchasabilityDetailsMap[productConsumptionOption.id];
                            if(userProductPurchasabilityDetails.isPurchasable){
                                const amount = getFromSafeObject(productConsumptionOption, 'consumptionDetails.details.purchasabilityDetails.details.amount');
                                if(!auxBestPrice || amount < auxBestPrice){
                                    auxBestPrice = amount;
                                    filterProductConsumptionOptionBestPriceIndex = index;
                                }
                                if(altProductConsumptionOptionDefaultIndex === index){
                                    filterProductConsumptionOptionDefaultIndex = index;
                                }
                                filterProductConsumptionOptionArray.push(productConsumptionOption);
                            }
                        })
                        if(filterProductConsumptionOptionArray.length > 0){
                            result[productConsumptionTypeName] = {
                                productConsumptionOptionArray: filterProductConsumptionOptionArray,
                                productConsumptionOptionBestPriceIndex: filterProductConsumptionOptionBestPriceIndex,
                                productConsumptionOptionDefaultIndex: filterProductConsumptionOptionDefaultIndex
                            }
                        }
                    }
                })
            }
        }
        return result
    },[productConsumptionOptionMap, userProductPurchasabilityDetailsMap])

    const handleOnContinue = (e) => {
        e.preventDefault();
        if(typeof onConfirm === 'function'){
            onConfirm(selectedProductConsumptionOption);
        }
    }

    useEffect(() => {
        if(selectedProductConsumptionTypeName == null && mainProductConsumptionOptionRef.current != null){
            const defaultOption = mainProductConsumptionOptionRef.current || null
            if(defaultOption){
                onSelectOption(defaultOption);
                onSelectOptionType(defaultOption.productTypeName);
            }
        }
    },[userProductTypeConsumptionMap])

    useEffect(() => {
        if(selectedProductConsumptionOption && userProductPurchasabilityDetailsMap){
            const userProductPurchasabilityDetails = userProductPurchasabilityDetailsMap[selectedProductConsumptionOption.id];
            setSelectedUserPurchasabilityDetails(userProductPurchasabilityDetails)
        }
    },[selectedProductConsumptionOption, userProductPurchasabilityDetailsMap])

    useEffect(() => {
        if(animationContainer != null){
            animationRef.current = lottie.loadAnimation({
                container: animationContainer,
                renderer: 'svg',
                autoplay: true,
                animationData: emptyBox,
                loop: false,
                name: 'animation'
            })
            return(() => {
                if(animationRef.current != null){
                    animationRef.current.stop();
                    animationRef.current?.destroy();
                }
            })
        }
    },[animationContainer])
    
    return (
        userProductTypeConsumptionMap ? 
                <div className='select-product-consumption-option-types-main-container'>
                        {Object.values(userProductTypeConsumptionMap).length > 0 ?
                            <>
                                {Object.entries(userProductTypeConsumptionMap).map(( [ productConsumptionTypeName, productConsumptionType ], index ) => {
                                    return(
                                            <SelectProductConsumptionType 
                                                key={index} 
                                                productConsumptionTypeName={productConsumptionTypeName}
                                                productConsumptionType={productConsumptionType} 
                                                isMain={getFromSafeObject(productConsumptionOptionMap, 'main.productTypeName') === productConsumptionTypeName}
                                                userProductPurchasabilityDetailsMap={userProductPurchasabilityDetailsMap}
                                                isSelectedProductConsumptionType={selectedProductConsumptionTypeName === productConsumptionTypeName}
                                                selectedProductConsumptionOption={selectedProductConsumptionOption} 
                                                onSelectOptionType={onSelectOptionType} 
                                                onSelectOption={onSelectOption}
                                            />
                                    )
                                })}
                                <ButtonLoading 
                                    variant="outlined" 
                                    color="primary" 
                                    label={t('continue')}
                                    onClick={handleOnContinue}
                                    disabled={!selectedUserPurchasabilityDetails || selectedUserPurchasabilityDetails.isPurchasable === false }
                                />
                            </>
                            :
                            <div>
                                <div ref={setAnimationContainerRef} className='select-product-consumption-option-animation-container' />
                                <div className='select-product-consumption-option-no-option-description-container'>
                                    <Typography variant='h6'>
                                        {textTransform('title', t('uups'))}
                                    </Typography>
                                    <Typography variant='body2'>
                                        {`${t('selectProductConsumptionOption.notFoundAPurchasableOption')}. ${t('selectProductConsumptionOption.sorryYouCannotPurchaseNow')}`}
                                    </Typography>
                                </div>
                            </div>
                        }
                </div>
            :
            null
    )
}

export default SelectProductConsumptionOption