import { Box, Typography } from '@material-ui/core'
import parse, { domToReact, attributesToProps } from 'html-react-parser'

const StyledTypography = ({children, ...props}) => {
    const defaultStyle = {
        whiteSpace: 'pre-line'
    }
    const options = {
        replace: (domNode, index) => {
            if(domNode.type === 'tag'){
                const elemProps = attributesToProps(domNode.attribs); 
                switch(domNode.name){
                    case 'b':
                        elemProps.fontWeight = "bold"
                        break;
                    case 'i':
                        elemProps.fontStyle = "italic"
                        break;
                    case 'u':
                        elemProps.style={ textDecoration: "underline" }
                        break;
                    case 's':
                        elemProps.style={ textDecoration: "line-through" }
                        break;
                    case 'h':
                        elemProps.className = `highlight${elemProps.className ? ` ${elemProps.className}` : ''}`
                    case 'span':
                        break;
                }
                return <Box component={'span'} {...elemProps}>{domToReact(domNode.children, options)}</Box>
            }
        }
    }
    return (
        typeof children === "string" ? <Typography {...props} style={{...props.style, ...defaultStyle}}>{parse(children, options)}</Typography> : null
    )
}

export default StyledTypography