import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Grid, Typography, Box } from '@material-ui/core';
// import { MenuItem, TextField } from '@material-ui/core';
// It is set from v5 material ui instead of v4 because 
// MenuItem does not cover the TextField
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import ButtonLoading from '../ButtonLoading/ButtonLoading';

import { getFromSafeObject, textTransform, textTruncate } from '../../aux/aux';

import ClosedCaptionOffIcon from '@mui/icons-material/ClosedCaptionOff';
import Circle from '../../img/circle.svg';

import './classContentCaptionsEdit.css';
import { useTheme } from '@material-ui/styles';
import ToggleMsg from '../ToggleMsg/ToggleMsg';

const ClassContentCaptionsEdit = ({ userConfig, contentSummary, onUpdate=async()=>{}, autoCloseModal=()=>{}}) => {
    const TEXT_TRUNCATE_LENGTH = 35;
    const TEXT_TRUNCATE_END = '...';
    const DEACTIVATED_LOCALE_ID = 0;
    const { t } = useTranslation('common', { keyPrefix: 'classContentCaptionsEdit' });
    const theme = useTheme();
    const abortControllerRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedLocaleId, setSelectedLocaleId] = useState(userConfig.captionsLocaleId ? userConfig.captionsLocaleId : null );
    const [error, setError] = useState({isError:false, msg:undefined, onError:undefined});
    
    const handleOnUpdate = async (e, signal=abortControllerRef.current.signal) => {
        e.preventDefault();
        try{
            setIsLoading(true);
            const captionsEnabled=  selectedLocaleId !== DEACTIVATED_LOCALE_ID;
            const updatedValues = {
                captionsLocaleId: captionsEnabled ? selectedLocaleId : null,
                captionsEnabled,
            };
            await onUpdate(updatedValues, false, true)
            if(!signal.aborted){
                autoCloseModal()
            }
        }catch(error){
            if(!signal.aborted){
                setError({
                    isError:true, 
                    msg:`${textTransform('title', t('cannotUpdateTheSelectedCaptions'))}. ${textTransform('title', t('tryItLater'))}`,
                    onError: () => setError(prev => ({...prev, isError:false, onError:undefined}))
                })
            }
        }finally{
            if(!signal.aborted){
                setIsLoading(false);
            }
        }
    }

    const getLocaleNameFromAPILocaleObject = (APIlocaleObject) => {
        const languageName = APIlocaleObject.details.language.description.name;
        const countryName =  APIlocaleObject.details.country.description.name;
        return textTransform('capitalize', `${languageName} ${countryName ? `(${countryName})`: ''}`)
    }

    useEffect(() => {
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        return(() => {
            abortController.abort();
        })
    },[])

    return (
        <Grid container direction='column' className='class-content-captions-edit-main'>
            <Grid item container className='class-content-captions-edit-header'>
                <ClosedCaptionOffIcon className='class-content-captions-edit-header-icon'/>
                <Typography variant='body1' style={{fontWeight:'bold'}}>
                    {textTransform('capitalize', t('common:captions'))}
                </Typography>
            </Grid>
            <Divider />
            <Grid item container direction='column' className='class-content-captions-edit-form'>
                <form onSubmit={handleOnUpdate}>
                    <Grid item>
                        <TextField 
                        disabled={isLoading}
                        label={t('selectLanguage')}
                        name='laguageCode'
                        value={selectedLocaleId === null ? DEACTIVATED_LOCALE_ID : selectedLocaleId}
                        onChange={(e) => setSelectedLocaleId(e.target.value)} 
                        inputProps={{style:{textOverflow:'ellipsis'}}}
                        select 
                        fullWidth
                        variant='standard'
                        >
                            <MenuItem key={getFromSafeObject(contentSummary, 'summaryDetails.availableConfiguration.captionsLocaleArray',[]).length} value={DEACTIVATED_LOCALE_ID}>
                                <Grid container >
                                    <Grid item>
                                        <Typography display='inline' variant='body1'>
                                            {textTruncate(textTransform('title', t('common:none')), TEXT_TRUNCATE_LENGTH, TEXT_TRUNCATE_END)}
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{padding: '0px 10px'}}>
                                        {userConfig.captionsLocaleId === DEACTIVATED_LOCALE_ID &&
                                            <Circle height={'0.5em'} fill={theme.palette.primary.main}/>
                                        }
                                    </Grid>
                                </Grid>
                            </MenuItem>
                            {getFromSafeObject(contentSummary, 'summaryDetails.availableConfiguration.captionsLocaleArray',[]).map( (locale, index) => {
                                return(
                                    <MenuItem key={index} value={locale.localeId}>
                                        <Grid container >
                                            <Grid item>
                                                <Typography display='inline' variant='body1'>
                                                    {textTruncate(getLocaleNameFromAPILocaleObject(locale.details.locale), TEXT_TRUNCATE_LENGTH, TEXT_TRUNCATE_END)}
                                                </Typography>
                                            </Grid>
                                            <Grid item style={{padding: '0px 10px'}}>
                                                {userConfig.captionsLocaleId === locale.localeId &&
                                                    <Circle height={'0.5em'} fill={theme.palette.primary.main}/>
                                                }
                                            </Grid>
                                        </Grid>
                                    </MenuItem>
                                )
                            })}
                        </TextField>
                    </Grid>
                    <Grid item className='class-content-captions-edit-form-button'>
                        <ButtonLoading type="submit" disabled={selectedLocaleId === null || selectedLocaleId === userConfig.captionsLocaleId || isLoading} isLoading={isLoading} label={t('common:select')} 
                        variant="outlined" color="primary" fullWidth/>
                    </Grid>
                </form>
                <ToggleMsg 
                    isShown={error.isError} 
                    msg={error.msg} 
                    onShowTime={error.onError}
                    type='error' 
                />
            </Grid>
        </Grid>
    )
}

export default ClassContentCaptionsEdit