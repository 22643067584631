import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Grid, Typography, Box } from '@material-ui/core';

import ButtonLoading from '../ButtonLoading/ButtonLoading';

import { textTransform, textTruncate } from '../../aux/aux';

import CableIcon from '@mui/icons-material/Cable';
import Circle from '../../img/circle.svg';

import './classContentCompatibleSystemSelect.css';
import { useTheme } from '@material-ui/styles';
import { getCompatibleSystems } from '../../services/staticContentServices';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import ClassContentSystemSelectForm from '../ClassContentSystemSelectForm/ClassContentSystemSelectForm';
import { useRef } from 'react';
import ToggleMsg from '../ToggleMsg/ToggleMsg';

const ClassContentCompatibleSystemSelect = ({ userConfig, contentSummary, onUpdate=async()=>{}, onDidUpdate=()=>{}, onRender=()=>{}}) => {
    // const TEXT_TRUNCATE_LENGTH = 35;
    // const TEXT_TRUNCATE_END = '...';
    // const theme = useTheme();
    const { t } = useTranslation('common', {keyPrefix:"classContentCompatibleSystemSelect"})
    const abortControllerRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [allSystems, setAllSystems] = useState(null);
    const [selectedSystemForm, setSelectedSystemForm] = useState({});
    const [error, setError] = useState({isError:false, msg:undefined, onError:()=>{}});
    const handleOnUpdate = async (e) => {
        // TODO: Needs to be implemented
        e.preventDefault();
        try{
            setIsUpdating(true);
            const updatedValues = selectedSystemForm;
            await onUpdate(updatedValues);
            onDidUpdate(updatedValues);
        }catch(error){
            if(!abortControllerRef.current.signal.aborted){
                setError({
                    isError:true, 
                    msg:`${textTransform('title', t('errorSettingTheSelectedSystem'))}. ${textTransform('title', t('selectAnotherAndYouCanUpdateLater'))}`,
                    onError: () => setError((prev) => ({
                        msg:prev.msg,
                        idError:false,
                        onError:()=>{}
                    }))
                })
            }
        }finally{
            if(!abortControllerRef.current.signal.aborted){
                setIsUpdating(false);
            }
        }

    }

    const requestAvailableSystems = async(abortSignal) => {
        try{
            setIsLoading(true);
            const res = await getCompatibleSystems('software', 0, abortSignal);
            const systems = res.data;
            if(!abortSignal.aborted){
                setAllSystems(systems)
            }
        }catch(error){
            if(!abortControllerRef.current.signal.aborted){
                setError({isError:true, msg:`${textTransform('title', t('errorRequestingTheCompatibleSystems'))}. ${textTransform('title', t('tryItLater'))}` })
            }
        }finally{
            if(!abortSignal.aborted){
                setIsLoading(false);
            }
        }
    };

    const isOnUpdateDisabled = () => {
        return !selectedSystemForm.selectedDeviceName || !selectedSystemForm.selectedSoftwareName || (selectedSystemForm.selectedDeviceName === userConfig.selectedDeviceName && selectedSystemForm.selectedSoftwareName === userConfig.selectedSoftwareName)
    }


    useEffect(() => {
        abortControllerRef.current = new AbortController();
        requestAvailableSystems(abortControllerRef.current.signal)
        return(() => {
            abortControllerRef.current.abort();
        })
    },[])

    useEffect(() => {
        const timeoutId = setTimeout(onRender, 300);
        return(() => clearTimeout(timeoutId))
    })

    return (
        <Grid container direction='column' className='class-content-compatible-system-select-main'>
            <Grid item container direction='column' className='class-content-compatible-system-select-form'>
                {!allSystems || isLoading ?
                    <Grid item xs>
                        <LoadingComponent visibleElements='circle' />
                    </Grid>
                :
                    <>
                        <ClassContentSystemSelectForm 
                        allSystems={allSystems} 
                        availableSystems={contentSummary.summaryDetails.availableConfiguration} 
                        currentUserSystem={{ deviceName:userConfig.selectedDeviceName, softwareName: userConfig.selectedSoftwareName }} 
                        setFormValues={setSelectedSystemForm} 
                        isDisabled={isUpdating}
                        />
                        <Grid item className='class-content-compatible-system-select-form-button'>
                            <ButtonLoading onClick={handleOnUpdate} disabled={ isOnUpdateDisabled() || isUpdating} isLoading={isUpdating} label={t("common:select")} 
                            variant="outlined" color="primary" fullWidth/>
                        </Grid>
                    </>
                }
                <Grid item>
                    <ToggleMsg 
                        isShown={error.isError} 
                        msg={error.msg} 
                        onShowTime={error.onError}
                        type='error' 
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ClassContentCompatibleSystemSelect