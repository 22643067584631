import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, useMediaQuery } from '@material-ui/core';
import { getLanguageIdOrNull, getReliableLocale } from '../../aux/sessionHelpers';
import BigFooterNoLinks from '../../components/BigFooterNoLinks/BigFooterNoLinks';
import { textTransform } from '../../aux/aux';
import { getInternationalizedLocaleFileNameOrNull } from '../../aux/internationalizationHelpers';
import MarkdownDisplay from '../../components/MarkdownDisplay/MarkdownDisplay';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import './terms.css'
import { useLocaleCtx } from '../../customHooks/useLocaleCtx';

const Terms = ({ user }) => {
    const FILENAME = 'terms.md';
    const MARKDOWN_FILES_PUBLIC_FOLDER_PATH = './md';
    const { t } = useTranslation('common');
    const matchMobile = useMediaQuery('(max-width: 680px)');
    const [filePath, setFilePath] = useState(null);
    const [loadingDisplay, setLoadingDisplay] = useState(true);
    const localeCtx = useLocaleCtx()

    const styles = {
        title:{
            // color: theme.palette.primary.darker,
            fontWeight: 'bold'
        },
        divider : {
            backgroundColor: '#f3f3f3'
        }
    }

    const getPath = (languageId, filename, folder=MARKDOWN_FILES_PUBLIC_FOLDER_PATH) => {
        return [folder, languageId, filename].join('/');
    }

    useEffect(() => {
        const languageId = getLanguageIdOrNull(localeCtx.localeCode);
        const internationalFilename = getInternationalizedLocaleFileNameOrNull(languageId, FILENAME);
        const path = internationalFilename ? getPath(languageId, internationalFilename) : internationalFilename;
        setFilePath(path);
    }, [localeCtx]);

    return (
        <div className='terms-wrapper'>
            <div className='terms-content-wrapper'>
                <Typography className='terms-content-title' variant={matchMobile ? "h4" : "h3"} style={styles.title}>
                    {textTransform('title', t('jsonPagesTitle.termsAndConditions'))}
                </Typography>
                {loadingDisplay &&
                    <LoadingComponent visibleElements='bar'/>
                }
                <MarkdownDisplay 
                    wrapperClassName='terms-main-content-wrapper'
                    path={filePath} 
                    onReady={() => setLoadingDisplay(false)}
                />
            </div>
            <BigFooterNoLinks user={user}/>
        </div>
    )
}

export default Terms