import { createContext, useState } from "react"
import { isNotEmptyObject } from "../aux/aux";

const HeaderPropsContext = createContext(null);
const HeaderPropsUpdateContext = createContext(null);

const HeaderPropsProvider = ({ children }) => {
    const INITIAL_HEADER_PROPS = {
        isSimple:false, 
        position:'fixed',
        backgroundColor:undefined 
    }
    const [headerProps, _setHeaderProps] = useState(INITIAL_HEADER_PROPS);

    const setHeaderProps = (props) => {
        if(props && isNotEmptyObject(props)){
            _setHeaderProps(prev => ({...prev, ...props}))
        }else{
            _setHeaderProps(INITIAL_HEADER_PROPS);
        }
    }

    return(
        <HeaderPropsContext.Provider value={headerProps}>
            <HeaderPropsUpdateContext.Provider value={setHeaderProps}>
                { children }
            </HeaderPropsUpdateContext.Provider>
        </HeaderPropsContext.Provider>
    )
}

export { HeaderPropsProvider, HeaderPropsContext, HeaderPropsUpdateContext }