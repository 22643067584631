import { Grid } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import FAQItem from '../FAQItem/FAQItem'
import './landingFAQ.css'
import { useEffect, useState } from 'react'
import { getLanguageIdOrNull } from '../../aux/sessionHelpers'
import { getFAQ } from '../../aux/staticContentQueries'
import { useLocaleCtx } from '../../customHooks/useLocaleCtx'

const LandingFAQ = () => {
    const theme = useTheme()
    const localeCtx = useLocaleCtx();
    const [faq, setFaq] = useState(null)
    const styles = {
        wrapper:{
        },
        item:{
            borderColor: theme.palette.divider
        }
    }
    
    const getJSONContent = async (languageId, signal) => {
        try{
            const res = await getFAQ(languageId, signal);
            const json = res.data;
            if(!signal.aborted){
                setFaq(json);
            }
        }catch(error){
            // do not display component
            if(!signal.aborted){
                console.log(`Landing FAQ file no accesible`)
            }
        }
    }
    useEffect(() => {
        const languageId = getLanguageIdOrNull(localeCtx.localeCode);
        const abortController = new AbortController();
        getJSONContent(languageId, abortController.signal);
        return(() => {
            abortController.abort();
        })
    },[localeCtx])
    return (
        <div className='landing-faq-wrapper' style={styles.wrapper}>
            {faq ?
                <Grid container direction='column' className='landing-faq-main-container'>
                    {faq.landingFaqIndexArray.map((faqIndex, index) => {
                        const faqItem = faq.faqArray[faqIndex];
                        return(
                            <Grid item key={index} className='landing-faq-item' style={styles.item}>
                                <FAQItem item={faqItem} />
                            </Grid>
                        )
                    })

                    }
                </Grid>
                :
                null
            }
        </div>
    )
}

export default LandingFAQ