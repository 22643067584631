import { useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { getFromSafeObject, loadScript, reloadScript, removeScriptBySrc } from '../../aux/aux';
import klaviyoWrapper from '../../aux/klaviyoHelpers';
import { registerEventInMktProfile } from '../../services/visitorServices';

const RouterLocationUpdate = () => {
    const location = useLocation();

    const scrollToTop = () => { window.scrollTo(0,0) }
    const trackLocationForMktPurposes = async () => {
        const locationToTrackMap = {
            // set a property for each pathname you wanna track for mkt purposes
            // for instance, '/app/account/billing':true will keep track of the billing page
        }
        if(locationToTrackMap.hasOwnProperty(location.pathname)){
            klaviyoWrapper.isCookied()
                .then(isCookied => {
                    klaviyoWrapper.trackEvent('viewedPage', { time: new Date(), pathname: location.pathname })
                        .catch(error => {
                            const klaviyoCookie = klaviyoWrapper.getDecodedCookieOrUndefined();
                            const exchangeId = getFromSafeObject(klaviyoCookie,"$exchange_id");
                            if(exchangeId){
                                registerEventInMktProfile('klaviyo', { exchangeId }, { name: klaviyoWrapper.eventNameMap['loggedOut'], time: new Date() })
                                    .catch(error => {
                                        console.error('Mkt provider unable register event from server-side')
                                    })
                            }
                        })
                })
        }
    }

    const reloadKlaviyoScrip = () => {
        reloadScript(
            'klaviyo-api', 
            ()=>{console.log('klaviyo refresh')}, 
            ()=>{console.log('klaviyo could not refresh')}
        );
    }

    useEffect(() => {
        // Remember: useEffect on children components are called 
        // before the parent component. Children components renders before its parent
        // reloadKlaviyoScrip();
        scrollToTop();
        trackLocationForMktPurposes();
    }, [location.pathname, location.search])

    return null
}

export default RouterLocationUpdate