import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
// import { MenuItem, TextField } from '@material-ui/core';
// It is set from v5 material ui instead of v4 because 
// MenuItem does not cover the TextField
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Circle from '../../img/circle.svg';
import GroupsIcon from '@mui/icons-material/Groups';
import EventIcon from '@mui/icons-material/Event';
import ScheduleIcon from '@mui/icons-material/Schedule';
import './meetingOccurrenceRegisterForm.css'
import CustomPopover from '../CustomPopover/CustomPopover';
import { isNotEmptyObject, isValidDate, textTransform, toTimescaleFromMilliseconds } from '../../aux/aux';
import { clearTimeout_, setTimeout_ } from '../../aux/extendedTimeout';
import { useLocaleCtx } from '../../customHooks/useLocaleCtx';

const MeetingOccurrenceRegisterForm = ({ meetingOccurrenceMap, clearForm, onUpdateForm, isLowPriority, lowPriorityRegistrationWindowMs, securityOpenMeetingWindowMs, maxToBusy=5, isDisabled=false, BusyIcon=GroupsIcon }) => {
    const locale = useLocaleCtx();
    const { t } = useTranslation('common', { keyPrefix: 'meetingOccurrenceRegisterForm' });
    const theme = useTheme();
    const availOccurences = Object.values(meetingOccurrenceMap).length > 0;
    const [selectedOccurrenceId, setSelectedOccurenceId] = useState('');
    const [isOpenRegistrationOccurrenceMap, setIsOpenRegistrationOccurrenceMap] = useState({});
    const [ocurrenceRegistrationOpenWindowMap, setOcurrenceRegistrationOpenWindowMap] = useState({});
    
    const setMeetingOccurrenceRegistrationWindows = (meetingOccurrenceMap) => {
        const occurrenceOpenWindowMap = {};
        Object.values(meetingOccurrenceMap).forEach( occurrence => {
            let startsDateTime = new Date(occurrence.startsDateTime);
            let registrationStartsDate = new Date(occurrence.registrationStartsDateTime);
            if(isLowPriority){
                const minLpRgistrationStartMs = startsDateTime.getTime() - lowPriorityRegistrationWindowMs;
                registrationStartsDate = new Date( Math.max( minLpRgistrationStartMs, registrationStartsDate.getTime() ));
            }
            let securedStartsDateTime = new Date(startsDateTime.getTime() - securityOpenMeetingWindowMs)
            let registrationEndsDate = new Date(occurrence.registrationEndsDateTime);
            let securedRegistrationEndsDate =  registrationEndsDate.getTime() <= securedStartsDateTime.getTime() ? registrationEndsDate : securedStartsDateTime;

            occurrenceOpenWindowMap[occurrence.id] = {
                starts: registrationStartsDate,
                ends: securedRegistrationEndsDate
            };
        });
        setOcurrenceRegistrationOpenWindowMap(occurrenceOpenWindowMap);
    };
    const styles = {
        icon: {
            display: 'block',
            padding: '0px 5px',
            fontSize: '1.2em',
        },
        busyIcon:{
            display: 'block',
            paddingLeft: '10px',
            fontSize: '1.2em',
        },
        lowPriorityIcon:{
            display: 'block',
            padding: '0px 5px',
            fontSize: '1em'
        }
    }

    const handleOnChange = (e) => {
        e.preventDefault();
        setSelectedOccurenceId(e.target.value)
    }

    useEffect(() => {
        onUpdateForm(selectedOccurrenceId);
    },[selectedOccurrenceId])

    useEffect(() => {
        if(clearForm){
            setSelectedOccurenceId('');
        }
    }, [clearForm])

    useEffect(() => {
        setMeetingOccurrenceRegistrationWindows(meetingOccurrenceMap)
    }, [meetingOccurrenceMap])

    useEffect(() => {
        if(isNotEmptyObject(ocurrenceRegistrationOpenWindowMap)){
            const openRegistrationOccurrence = (occurrenceId) => {
                setIsOpenRegistrationOccurrenceMap(prev => ({ ...prev, [occurrenceId]: true}))
            }
            const closeRegistrationOccurrence = (occurrenceId) => {
                setIsOpenRegistrationOccurrenceMap(prev => ({ ...prev, [occurrenceId]: false}))
            }
            const timeoutIdToOpenRegistrationArray = [];
            const timeoutIdToCloseRegistrationArray = [];
            const isOpenAuxMap = {};
            Object.entries(ocurrenceRegistrationOpenWindowMap).forEach(([occurrenceId, registrationOpenDatesMap], index) => {
                let now = new Date();
                let startsRegistrationDate = registrationOpenDatesMap.starts;
                let endsRegistrationDate = registrationOpenDatesMap.ends;
                if(now.getTime() < startsRegistrationDate.getTime()){
                    isOpenAuxMap[occurrenceId] = false;
                    let timeToStartsRegistration = startsRegistrationDate.getTime() - now.getTime();
                    let timeToEndRegistration = endsRegistrationDate.getTime() - now.getTime();
                    let timeoutToOpenId = setTimeout_(() => openRegistrationOccurrence(occurrenceId), timeToStartsRegistration);
                    let timeoutToCloseId = setTimeout_(() => closeRegistrationOccurrence(occurrenceId), timeToEndRegistration);
                    timeoutIdToOpenRegistrationArray.push(timeoutToOpenId);
                    timeoutIdToCloseRegistrationArray.push(timeoutToCloseId)
                }else if(now.getTime() >= startsRegistrationDate.getTime() && now.getTime() < endsRegistrationDate.getTime()){
                    isOpenAuxMap[occurrenceId] = true;
                    let timeToEndRegistration = endsRegistrationDate.getTime() - now.getTime();
                    let timeoutToCloseId = setTimeout_(() => {
                        closeRegistrationOccurrence(occurrenceId);
                    }
                    , timeToEndRegistration);
                    timeoutIdToCloseRegistrationArray.push(timeoutToCloseId)
                }else{
                    isOpenAuxMap[occurrenceId] = false;
                }
            });
            setIsOpenRegistrationOccurrenceMap({ ...isOpenAuxMap });
            return(() => {
                timeoutIdToOpenRegistrationArray.forEach( timeoutId => clearTimeout_(timeoutId));
                timeoutIdToCloseRegistrationArray.forEach( timeoutId => clearTimeout_(timeoutId));
            })
        }
    }, [ocurrenceRegistrationOpenWindowMap])

    return (
        <TextField 
        disabled={isDisabled || !availOccurences}
        label={textTransform('title', (availOccurences ? t('selectDate') : t('noAvailableDates')))}
        name='selectedOccurrence'
        value={selectedOccurrenceId}
        onChange={handleOnChange} 
        inputProps={{style:{textOverflow:'ellipsis'}}}
        InputLabelProps={{shrink:selectedOccurrenceId !== ''}}
        select 
        fullWidth
        variant='standard'
        required
        >        
            {isNotEmptyObject(ocurrenceRegistrationOpenWindowMap) && isNotEmptyObject(isOpenRegistrationOccurrenceMap) ?
                Object.values(meetingOccurrenceMap).map( (occurrence, index) => {
                    let isSelected = selectedOccurrenceId === occurrence.id;
                    let occurrenceRegistrationOpenDates = ocurrenceRegistrationOpenWindowMap[occurrence.id];
                    let securedRegistrationStartsDate = occurrenceRegistrationOpenDates.starts;
                    let securedRegistrationEndsDate = occurrenceRegistrationOpenDates.ends;
                    let startsDateTime = new Date(occurrence.startsDateTime);
                    let isRegistrationOpen = isOpenRegistrationOccurrenceMap[occurrence.id];
                    let registrationLeft = occurrence.maxNumAttendees - occurrence.attendees.numAttendees;
                    let isAvail = registrationLeft > 0 && isRegistrationOpen;
                    let isBusy = registrationLeft < maxToBusy
                    let now = new Date(); 
                    // let startsDateTime = new Date(occurrence.startsDateTime);
                    // let securedStartsDateTime = new Date(startsDateTime.getTime() - securityOpenMeetingWindowMs)
                    // let lpRegistrationStartsDateTime = new Date(startsDateTime.getTime() - lowPriorityRegistrationWindowMs);
                    // let registrationStartsDate = new Date(occurrence.registrationStartsDate);
                    // let registrationEndsDate = new Date(occurrence.registrationEndsDateTime);
                    // let securedRegistrationEndsDate =  registrationEndsDate.getTime() <= securedStartsDateTime.getTime() ? registrationEndsDate : securedStartsDateTime;
                    // let registrationLeft = occurrence.maxNumAttendees - occurrence.attendees.numAttendees;
                    // let isRegistrationOpen = registrationStartsDate.getTime() <= now.getTime() && securedRegistrationEndsDate.getTime() > now.getTime();
                    // let isLPRegistrationOpen = lpRegistrationStartsDateTime.getTime() <= now.getTime() && securedRegistrationEndsDate.getTime() > now.getTime();
                    // let isAvail = registrationLeft > 0 && (isLowPriority ? isLPRegistrationOpen : isRegistrationOpen);
                    // let isBusy = registrationLeft < maxToBusy
                    return(
                        <MenuItem key={index} value={occurrence.id} disabled={!isAvail}>
                            <Grid container direction='column'>
                                <Grid item container className='meeting-occurrence-register-form-item-container' >
                                    <Grid item>
                                        <EventIcon style={styles.icon} />
                                    </Grid>
                                    <Grid item>
                                        <Typography display='inline' variant='body1'>
                                            {startsDateTime.toLocaleDateString(locale.localeCode)}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <ScheduleIcon style={styles.icon} />
                                    </Grid>
                                    <Grid item>
                                        <Typography display='inline' variant='body1'>
                                            {startsDateTime.toLocaleTimeString(locale.localeCode)}
                                        </Typography>
                                    </Grid>
                                    {isBusy &&
                                        <Grid item>
                                            <BusyIcon style={styles.busyIcon} />
                                        </Grid>
                                    }
                                    <Grid item className='meeting-occurrence-register-form-item-selection-mark'>
                                        {isSelected &&
                                            <Circle height={'0.5em'} fill={theme.palette.primary.main} display={"block"}/>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography variant='caption'>
                                        {
                                            isRegistrationOpen ?
                                                `${textTransform('title', t('registrationEndsAt'))}: ${securedRegistrationEndsDate.toLocaleString(locale.localeCode)}`
                                            :
                                                securedRegistrationEndsDate.getTime() <= now.getTime() ?
                                                `${textTransform('title', t('registrationClosedAt'))}: ${(securedRegistrationEndsDate).toLocaleString(locale.localeCode)}`
                                                :
                                                `${textTransform('title', t('registrationOpensAt'))}: ${(securedRegistrationStartsDate).toLocaleString(locale.localeCode)}`
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </MenuItem>
                    )})
                :
                    <MenuItem>
                        <Typography variant='body1'>
                            `${textTransform('title', t('noAvailableDates'))}`
                        </Typography>
                    </MenuItem>
            }
            
        </TextField>
    )
}

export default MeetingOccurrenceRegisterForm