import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { textTransform } from '../../aux/aux';
import CustomPopover from '../CustomPopover/CustomPopover';
import ImgDN from '../../cdn/imgDN';
import cloudinary from '../../cdn/providers/cloudinary';
// TODO: implement default img
// import defaultPanelistImg from '../../img/default/example.jpg'
import './meetingPanelistBadge.css';
import SocialProfileBadge, { getSocialProfilePalette } from '../SocialProfileBadge/SocialProfileBadge';
import SocialProfileBadgeWithPopover from '../SocialProfileBadgeWithPopover/SocialProfileBadgeWithPopover';

const MeetingPanelistBadge = ({ isProfessor, imgId, imgUrl, meetingOcurrenceStatus, label, ...props }) => {
  const DIAMETER_PANELIST_IMG_POPOVER_PX = 60;
  const IMG_RESOLUTION_SCALE_FACTOR = 3;
  const theme = useTheme();
  const badgeRef = useRef();
  const [isHover, setIsHover] = useState(false);
  const [imgPanelistUrl, setImgPanelistUrl] = useState(null);
  const [panelistProfilePalette, _] = useState(() => getSocialProfilePalette(theme));
  
  const styles = {
    status: {
      height: '10px',
      width: '10px',
      backgroundColor: meetingOcurrenceStatus === 'running' ? theme.palette.success.main : '#888' 
    },
  }

  const handleOnMouseEnter = (e)=>{
    setIsHover(true);
  }

  const handleOnMouseLeave = (e) => {
    setIsHover(false);
  }


  useEffect(() => {
    if(imgId || imgUrl){
      let imgPanelistUrl;
      let auxImgUrl;
      if(imgUrl){
        imgPanelistUrl = imgUrl
      }else if(imgId){
        const assetProvider = new ImgDN(cloudinary);
        auxImgUrl = assetProvider.getAssetUrl(imgId,{height: DIAMETER_PANELIST_IMG_POPOVER_PX * IMG_RESOLUTION_SCALE_FACTOR});
      }
      imgPanelistUrl = imgPanelistUrl || auxImgUrl;
      setImgPanelistUrl(imgPanelistUrl);
    }
  },[imgId, imgUrl])

  return (
    <div 
      ref={badgeRef} 
      className='meeting-panelist-badge-main-wrapper' 
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      {...props}
    >
      <SocialProfileBadgeWithPopover imgUrl={imgPanelistUrl} label={label} palette={panelistProfilePalette} popOverDiameterPx={DIAMETER_PANELIST_IMG_POPOVER_PX} />
      {meetingOcurrenceStatus &&
        <div className='meeting-panelist-badge-status' style={styles.status}/>
      }
    </div>
  ) 
}

export default MeetingPanelistBadge