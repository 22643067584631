import { Typography, Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/styles';
import { useEffect, useState } from 'react';
import { fromCentTo2dec, priceToString } from '../../aux/purchaseHelpers';
import Timer from '../Timer/Timer';
import './checkoutPromoCodeWidget.css';

const CheckoutPromoCodeWidget = ({ isCheckoutUpdating, appliedPromoCode, currencyId, amountOff, className }) => {
    const theme = useTheme()
    const { t } = useTranslation('checkout', { keyPrefix: 'checkoutPromoCodeWidget'})
    const [timerDate, setTimerDate] = useState(null)
    const style = {
        applied:{
            border: '1px solid',
            borderColor: theme.palette.success.main,
            color:theme.palette.success.main,
            backgroundColor: theme.palette.common.white
        },
        noApplied:{
            border: '1px solid',
            borderColor: theme.palette.grey[300], //#eeeee
            color: theme.palette.grey[600],
            backgroundColor: theme.palette.common.white
        },
        timer:{
            color: theme.palette.grey[600],
        }
    }
    useEffect(() => {
        if(appliedPromoCode && appliedPromoCode.lockDetails.releaseDate){
            setTimerDate(new Date(appliedPromoCode.lockDetails.releaseDate));
        }
        return(() => setTimerDate(null));
    },[appliedPromoCode])
    return (
        <div className={className ? className : 'checkout-promo-code-widget-main-container'}>
            {appliedPromoCode && timerDate &&
                    <div className='checkout-promo-code-widget-row-container' style={style.timer}>
                        <Typography variant="caption">
                                {t('reserved')}
                        </Typography>
                        <Timer finalDate={timerDate} variant='caption' />
                    </div>
            }
            <div className='checkout-promo-code-widget-container' style={appliedPromoCode ? style.applied : style.noApplied}>
                {appliedPromoCode ? 
                    <div className='checkout-promo-code-widget-row-container'>
                        <Typography variant="caption">
                            {appliedPromoCode.id}
                        </Typography> 
                        <Typography variant="caption">
                            {t('common:{{val, currency}}',{ val:fromCentTo2dec(amountOff * -1), currency: currencyId, trailingZeroDisplay:'stripIfInteger'})}
                        </Typography>
                    </div>
                    :
                    <Typography variant="body2">
                        {t('noPromoApplied')}
                    </Typography> 
                }
            </div>
        </div>
    )
}

export default CheckoutPromoCodeWidget