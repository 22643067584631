import { useState } from 'react';
import { useTheme } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import './clickableLoadingText.css'

const ClickableLoadingText = ( { isVisible, isLoading, text, onClick, disabled=false, underline=false, underlineOnHover=false, inline=false, variant='body1', className, ...props }) => {
    const theme = useTheme();
    const [hover, setHover] = useState(false);
    const styles = {
        circularProgress:{
            color: props.hasOwnProperty("color") ? props.color : theme.palette.primary.main,
        },
        text:{
            color: props.hasOwnProperty("color") ? props.color : theme.palette.primary.main,
            textDecoration: underline ? 'underline' : hover ? 'underline' : 'unset',
            maxHeight:`${theme.typography[variant].lineHeight}em`
        }
    };
    return (
        <div className={`clickable-loading-text-container ${isVisible ? 'visible': ''} ${isLoading ? 'loading' : ''} ${disabled ? 'disabled' : ''} ${inline ? 'inline' : ''} ${className ? className : ''}`} onClick={onClick} {...props} >
            {isLoading ?
                <CircularProgress size={theme.typography[variant].fontSize} style={styles.circularProgress}  />
            :
                <Typography variant={variant} style={styles.text} onMouseEnter={underlineOnHover ? ()=>setHover(true) : null} onMouseLeave={underlineOnHover ? () => setHover(false) : null}>
                    {text}
                </Typography>
            }
        </div>
    )
}

export default ClickableLoadingText