import { useState, useRef, useEffect } from 'react'
import { useTheme } from '@material-ui/styles';
import CustomPopover from '../CustomPopover/CustomPopover';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import './infoLabelPopOver.css'
import { Typography } from '@material-ui/core';
import { textTransform } from '../../aux/aux';
import PreventUnderZLayerInteraction from '../PreventUnderZLayerInteraction/PreventUnderZLayerInteraction';
import { useUpdateScrollableDocumentCtx } from '../../customHooks/useUpdateScrollableDocumentCtx';

const InfoLabelPopOver = ({ label, labelPopOverMsg, labelStyle, infoIconStyle, labelClassName, anchorOrigin, transformOrigin, variant, variantPopOver }) => {
    const theme = useTheme();
    const [isClick, setIsClick] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const setIsScrollableDocument = useUpdateScrollableDocumentCtx();
    const [popOver, setPopOver] = useState(null);
    const labelRef = useRef(null);
    const styles = {
        label: {
            color: theme.palette.grey[400],
            display: 'inline',
            ...labelStyle
        },
        infoIconStyle: {
            width: 'auto',
            height: theme.typography[variant || 'body2'].fontSize,
            display: 'inline',
            verticalAlign: 'middle',
            color: theme.palette.grey[400],
            ...infoIconStyle
        }

    }

    useEffect(() => {
        if(isClick){
            setIsScrollableDocument(false);
            const handleClickOutside = (e) => {
                if(popOver && !popOver.contains(e.target)){
                    setIsClick(false);
                }
            }
            document.addEventListener('pointerdown', handleClickOutside);
            return(() => {
                document.removeEventListener('pointerdown', handleClickOutside);
                setIsScrollableDocument(true)
            })
        }
    },[isClick])
    
    return (
        <>
            {isClick &&
                <PreventUnderZLayerInteraction/>
            }
            <div 
                ref={labelRef} 
                className={labelClassName ? labelClassName : 'info-label-pop-over-main-container'}
                onMouseEnter={popOver != null ? () => setIsHover(true) : null}
                onMouseLeave={popOver != null ? () => setIsHover(false) : null}
                onPointerDown={popOver != null ? (e) => {
                    e.stopPropagation();
                    setIsClick(true);
                } : null}
            >
                <Typography variant={variant || 'body2' } style={styles.label}>
                    {`${textTransform('title', label)}${labelPopOverMsg ? " " : ""}`}
                </Typography>
                {labelPopOverMsg &&
                    <>
                        <CustomPopover 
                        open={isClick || isHover}
                        anchorEl={labelRef}
                        timeTransform={null}
                        anchorOrigin={ anchorOrigin || {vertical:'top', horizontal:'center'}} 
                        transformOrigin={transformOrigin || {vertical:'bottom', horizontal:'center'}}
                        >
                            <div ref={setPopOver} className='info-label-pop-over-pop-over-container'>
                                <Typography variant={variantPopOver || 'body2'}>
                                    {labelPopOverMsg}
                                </Typography>
                            </div>
                        </CustomPopover>
                        <InfoOutlinedIcon style={styles.infoIconStyle} />
                    </>
                }
            </div>
        </>
)
}

export default InfoLabelPopOver