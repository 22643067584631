import { Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { getFromSafeObject, isNotEmptyObject, textTruncate } from '../../aux/aux'
import { fromCentTo2dec } from '../../aux/purchaseHelpers';
import { useTranslation } from 'react-i18next';
import BasicProductConsumptionOptionItem from '../BasicProductConsumptionOptionItem/BasicProductConsumptionOptionItem';
import './addToSubscriptionItem.css'

const AddToSubscriptionItem = ({ productOption, userSubscriptabilityDetails }) => {
    const theme = useTheme()
    const { t } = useTranslation();
    const styles = {
        wrapper:{
            backgroundColor: theme.palette.background.paper,
            borderRadius: '5px',
        },
        mainContainer: {
            backgroundColor: theme.palette.background.paper,
            borderRadius: '5px',
            boxShadow: theme.shadows[2],
            border: `0.5px solid ${theme.palette.primary.main}`
        },
        initialAmount:{
            opacity: '50%',
            textDecoration:'line-through'
        }
    }

    const getProductConsumptionOptionItem = (productOption) => {
        let component;
        switch(productOption.productTypeName){
            case 'content':
                component =            
                    <BasicProductConsumptionOptionItem 
                        productConsumptionOption={productOption}
                        disableOnClick={true}
                        ConfigSelectorComponent={
                            <div className='add-to-subscription-item-price-container'>
                                <Typography variant="body2" style={styles.initialAmount}>
                                        {t('{{val, currency}}',{ val:fromCentTo2dec(getFromSafeObject(productOption, 'consumptionDetails.details.purchasabilityDetails.details.amount.finalAmount')), currency: getFromSafeObject(productOption, 'consumptionDetails.currencyId'), trailingZeroDisplay:'stripIfInteger'})}
                                </Typography>
                            </div>
                        }
                    />
                break;
            default:
                component = 
                    <BasicProductConsumptionOptionItem 
                        disableOnClick={true}
                    />
        }
        return component
    }

    return (
        getProductConsumptionOptionItem(productOption)
    )
}
export default AddToSubscriptionItem