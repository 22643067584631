import { useState, useEffect, useRef } from 'react';
import { Grid, Divider, Typography, Box } from '@material-ui/core'
import { Alert } from '@mui/material'

import { getReliableLocale } from '../../aux/sessionHelpers';
import { requestMinimumTimeoutWrapper } from '../../aux/requestMethods';
import { fromArrayOfObjectsToMap, isEmptyObject, isNotEmptyObject } from '../../aux/aux';
import { updatePaymentIntentFn, updateCheckoutStatusFn } from '../../aux/purchaseQueries';
import { publicRequest } from '../../aux/requestMethods';
import { updateAndGetCheckout } from '../../aux/purchaseHelpers';

import { createPaymentIntentFn } from '../../aux/purchaseQueries';
import LogInSignIn from '../LogInSignIn/LogInSignIn';
import FastCheckoutConfig from '../FastCheckoutConfig/FastCheckoutConfig';
import PopUpModal from '../PopUpModal/PopUpModal';
import PurchaseProcessRoadMap from '../PurchaseProcessRoadMap/PurchaseProcessRoadMap';
import CheckoutPaymentMethod from '../CheckoutPaymentMethod/CheckoutPaymentMethod';
import FastCheckoutPay from '../FastCheckoutPay/FastCheckoutPay';
import FastCheckoutIsOwnerFeedback from '../FastCheckoutIsOwnerFeedback/FastCheckoutIsOwnerFeedback';

// Style
import './purchaseProcessModal.css'
import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CheckoutPaymentIntentFeedback from '../CheckoutPaymentIntentFeedback/CheckoutPaymentIntentFeedback';
import { productBelongToUser } from '../../aux/userHelpers';
import {  setTimeout_, clearTimeout_} from '../../aux/extendedTimeout';
import { justSetUser } from '../../services/sessionServices';
import { useUpdateLocaleCtx } from '../../customHooks/useUpdateLocaleCtx';
import { useUpdateCountryAndCurrencyCtx } from '../../customHooks/useUpdateCountryAndCurrencyCtx';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import { useUpdateScrollableDocumentCtx } from '../../customHooks/useUpdateScrollableDocumentCtx';

const DEFAULT_PRODUCT_IDENTIFIER = {
    productId:undefined,
    editionNum:undefined
};
const DEFAULT_QUANTITY = 1
const PurchaseProcessModal = ({ user, setUser, productIdentifier=DEFAULT_PRODUCT_IDENTIFIER, quantity=DEFAULT_QUANTITY, showModal, onClose, addMessages }) => {
    const POP_UP_WIDTH = 380; //450px
    const history = useHistory();
    const location = useLocation();
    const query = new URLSearchParams(location.search)
    const modalStateTypes = ['idle', 'loading', 'success', 'error'];
    const purchaseProcessSteps = ['login', 'setUp', 'pay', 'learn'];
    const [purchaseStep, setPurchaseStep] = useState(null);
    const [purchaseSubStep, setPurchaseSubStep] = useState(null);
    const [modalState, setModalState] = useState(modalStateTypes[0]);
    // NEW States: STARTS
    const locale = getReliableLocale();
    const updateLocaleCtx = useUpdateLocaleCtx();
    const updateCountryAndCurrencyCtx = useUpdateCountryAndCurrencyCtx();
    // Checkout redirect: STARTS
    const [isRedirect, setIsRedirect] = useState(query.get('fast_checkout_id') != null);
    const [fastCheckoutId, setFastCheckoutId] = useState(query.get('fast_checkout_id'));
    const [paymentIntentId, setPaymentIntentId] = useState(query.get('payment_intent'));
    const [paymentIntentClientSecret, setPaymentIntentClientSecret] = useState(query.get('payment_intent_client_secret'));
    // Checkout redirect: ENDS
    const [fastCheckoutContent, setFastCheckoutContent] = useState(null);
    const [isCheckoutPaymentMethodReady, setIsCheckoutPaymentMethodReady] = useState(false);
    const [appliedPromoCode, setAppliedPromoCode] = useState(null);
    const [innerContainerHeight, setInnerContainerHeight] = useState(null)
    const innerContainerRef = useRef(null);
    const popUpModalRef = useRef(null);
    const abortControllerSignalRef = useRef(null);

    const setPromoCodeLockTimeout = (promoCode, minimumTimeoutMilliseconds=2000) => {
        const lockDetails = promoCode.lockDetails;
        let lockTimeoutId;
        if(lockDetails.releaseJobId && lockDetails.releaseDate){
            const serverUnlockDate = new Date(lockDetails.releaseDate);
            const remainingMilliseconds = Math.max(minimumTimeoutMilliseconds, serverUnlockDate.getTime() - new Date().getTime());
            lockTimeoutId = setTimeout_(() => {
                try{
                    updateAndGetFastCheckoutContent('takeOutPromoCode', {});
                }catch(error){
                    // TODO: implement
                }
            }, remainingMilliseconds);
        }else{
            lockTimeoutId = null;
        }
        return lockTimeoutId;
    }

    const setPromoCode = (promoCode) => {
        if(appliedPromoCode){
            clearTimeout_(appliedPromoCode.lockDetails.clientReleaseTimeoutId);
        }
        if(promoCode){
            const timeoutId = setPromoCodeLockTimeout(promoCode);
            promoCode.lockDetails['clientReleaseTimeoutId'] = timeoutId;
        }
        setAppliedPromoCode(promoCode); // undefined if op !== 'applyPromoCode'
    };

    const resetPaymentIntent= () => {
        const resetSearchQuery = () => {
            history.replace({search: ''});
            setIsRedirect(null)
        }
        resetSearchQuery()
        setIsCheckoutPaymentMethodReady(false);
        setPaymentIntentClientSecret('')
        setPaymentIntentId('')
    }

    const resetFastCheckoutContent = () => {
        setFastCheckoutContent(null);
        setFastCheckoutId(null)
    }

    const updatePaymentIntent = async (updateIntentParams, minimumTimeout=0, signal=abortControllerSignalRef.current) => {
        try{
            const res = await requestMinimumTimeoutWrapper(updatePaymentIntentFn, updateIntentParams, minimumTimeout, signal);
            return res;
        }catch(error){
            if(!signal || !signal.aborted){
                if(error.response.data.hasOwnProperty('paymentIntentId')){
                    // payment intent was successful
                    setPaymentIntentId(error.response.data.paymentIntentId);
                }else{
                    // Error updating PI on Stripe
                    resetPaymentIntent()
                }
            }
        }
    }

    const checkoutSyncAfterPIFailureCallback = async () => {
        try{
            // 03/11
            // The purpose of this condition is ensure that if there is 
            // a checkout it must have "open" as status
            // if(fastCheckoutContent && fastCheckoutContent.status !== 'open'){
            //     await updateCheckoutStatus('open');
            // }
            if(fastCheckoutContent){
                await updateCheckoutStatus('open');
            }
            if(appliedPromoCode){
                await updateAndGetFastCheckoutContent('takeOutPromoCode', {})
            }
        }catch(error){
            // TODO: 
            console.log(error)
        }
    }

    const updateCheckoutStatus = async ( checkoutStatus, signal=abortControllerSignalRef.current, piClientSecret=paymentIntentClientSecret, userId=fastCheckoutContent.userId, id=fastCheckoutContent.id) => {
        try{
            const requestParams = {
                userId,
                checkoutId:id,
                checkoutStatus,
                paymentIntentClientSecret:piClientSecret,
            };
            const res = await requestMinimumTimeoutWrapper(updateCheckoutStatusFn, requestParams, 0, signal);
            if(!signal || !signal.aborted){
                if(res.data.checkout.status !== 'open' && res.data.promoCode){
                    if(appliedPromoCode && isNotEmptyObject(appliedPromoCode.lockDetails)){
                        clearTimeout_(appliedPromoCode.lockDetails.clientReleaseTimeoutId);
                        setAppliedPromoCode((prev) => ({...prev, lockDetails:res.data.promoCode.lockDetails}))
                    }
                }
                setFastCheckoutContent(prev => ({ ...prev, status: res.data.checkout.status }));
            }
            return res.data;
        }catch(error){
            throw error;
        }
    }

    const updateAndGetFastCheckoutContent = async ( opId, opParams, signal=abortControllerSignalRef.current) => {
        try{
            const requestParams = {
                userId: user.basicData.id, 
                checkoutId: fastCheckoutId,
                userLocaleId : locale.localeId,
                ...(fastCheckoutContent && fastCheckoutContent.orderAmounts ? {hashFinalAmount: fastCheckoutContent.orderAmounts.hashFinalAmount} : {})
            }
            const updateIntentParams = {
                userId: user.basicData.id,
                checkoutId: fastCheckoutId,
                paymentIntentClientSecret,
            };
            const res = await updateAndGetCheckout(opId, opParams, requestParams, 0, signal);
            const data = res.data;
            const {
                productsAndCouponsList,
                subscribedProductsAndCouponsList,
                ...updatedCheckoutContent
            } = data.updatedCheckoutContent
            updatedCheckoutContent.productsAndCouponsMap = fromArrayOfObjectsToMap(productsAndCouponsList, 'productId');
            updatedCheckoutContent.subscribedProductsAndCouponsMap = fromArrayOfObjectsToMap(subscribedProductsAndCouponsList, 'productId');
            if(!signal || !signal.aborted){
                setFastCheckoutContent(updatedCheckoutContent);
                setPromoCode(data.promoCode);
                if(paymentIntentClientSecret){
                    if(data.needsResetIntent){
                        resetPaymentIntent();
                        console.log('INFO: reset')
                    }else{
                        updateIntentParams.currencyId = updatedCheckoutContent.currencyId;
                        updateIntentParams.hashFinalAmount = updatedCheckoutContent.orderAmounts.hashFinalAmount;
                        updateIntentParams.paymentMethodUsage = isEmptyObject(updatedCheckoutContent.subscriptionProductMap) ? 'on_session' : 'off_session';
                        await updatePaymentIntent(updateIntentParams, 0, signal);
                        console.log('INFO: update')
                    }
                }
            }
            return {...data, updatedCheckoutContent};
        }catch(error){
            // Error handling occurs where updateCheckoutContent was called
            throw error 
        }
    }

    const handleOnClickClose = async (e, signal=abortControllerSignalRef.current) => {
        if(appliedPromoCode){
            clearTimeout_(appliedPromoCode.lockDetails.clientReleaseTimeoutId);
            updateAndGetFastCheckoutContent('takeOutPromoCode', {});
        }
        if(!signal || !signal.aborted){
            resetPaymentIntent();
        }
        onClose();
    };

    const handleOnLogIn = async(userData) => {
        justSetUser(userData, setUser);
        updateCountryAndCurrencyCtx.setCountryAndCurrency(userData.countryAndCurrency);
        await updateLocaleCtx(userData.locale);
    }

    useEffect(() => {
        if( user ){
            if(!purchaseStep){
                setPurchaseStep(1);
            }
        }else{
            setPurchaseStep(0)
        }
    }, [user])

    useEffect(() => {
        if(paymentIntentId && fastCheckoutContent){
            setPurchaseStep(3);
        }
    },[paymentIntentId, fastCheckoutContent])

    useEffect(() => {
        popUpModalRef.current.scrollTop = 0;
    },[purchaseSubStep, purchaseStep])

    const updateInnerContainerHeight = () => {
        setTimeout( () => {
            if(innerContainerRef.current != null){
                setInnerContainerHeight(innerContainerRef.current.offsetHeight);
            }
        }, 300)
    }
    // Could be removable if we implement a onReder strategy
    useEffect(() => {
        const abortController = new AbortController ()
        abortControllerSignalRef.current = abortController.signal;
        const intervalId = setInterval(() => {
            updateInnerContainerHeight();
        }, 2000);
        return(() => { 
            clearInterval(intervalId);
            abortController.abort();
        } )
    },[])


    const populateModalContent = () => {
        let modalContentComponent = null
        switch(purchaseStep){
            case 0:
                modalContentComponent = 
                    <LogInSignIn  
                        onLogin={handleOnLogIn} 
                        addMessages={addMessages} 
                        onRender={updateInnerContainerHeight} 
                        setProcessStep={setPurchaseSubStep}
                        passiveVerification={process.env.REACT_APP_TARGET_ENV === 'staging'}
                    />
                break;
            case 1:
                modalContentComponent = <FastCheckoutConfig  user={user} productIdentifier={productIdentifier} quantity={quantity} handlerFastCheckoutContent={[fastCheckoutContent, setFastCheckoutContent]} handlerFastCheckoutId={[fastCheckoutId, setFastCheckoutId]}
                updateAndGetFastCheckoutContent={updateAndGetFastCheckoutContent} appliedPromoCode={appliedPromoCode} handlerPurchaseStep={[purchaseStep, setPurchaseStep]} 
                isRedirect={isRedirect} addMessages={addMessages}
                />
                break;
            case 2:
                modalContentComponent = <FastCheckoutPay handlerAppliedPromoCode={[appliedPromoCode, setAppliedPromoCode]} 
                handlerPurchaseStep={[purchaseStep, setPurchaseStep]} handlerFastCheckoutContent={[fastCheckoutContent, setFastCheckoutContent]} addMessages={addMessages}
                handlerIsCheckoutPaymentMethodReady={[isCheckoutPaymentMethodReady, setIsCheckoutPaymentMethodReady]} handlerPaymentIntentClientSecret={[paymentIntentClientSecret, setPaymentIntentClientSecret]} setPaymentIntentId={setPaymentIntentId}
                checkoutSyncAfterPIFailureCallback={checkoutSyncAfterPIFailureCallback} updateCheckoutStatus={updateCheckoutStatus} updatePaymentIntent={updatePaymentIntent}
                />
                break;
            case 3:
                modalContentComponent = <CheckoutPaymentIntentFeedback user={user} setUser={setUser} checkoutContent={fastCheckoutContent} handlePaymentIntentId={[paymentIntentId, resetPaymentIntent]} 
                checkoutSyncAfterPIFailureCallback={checkoutSyncAfterPIFailureCallback} callbackToPurchase={() => {setPurchaseStep((purchaseStep) => purchaseStep - 1)}} 
                />
                break;
        }
        return modalContentComponent
    };

    return (
        <PopUpModal ref={popUpModalRef} showModal={showModal}  onClickClose={handleOnClickClose} width={POP_UP_WIDTH} height={innerContainerHeight}>
            <Grid ref={innerContainerRef} className='purchase-process-inner' container direction="column">
                <Grid item className='purchase-process-roadmap-container'>
                    <PurchaseProcessRoadMap purchaseProcessSteps={purchaseProcessSteps} currentStep={purchaseStep} />
                </Grid>
                <Grid item style={{minHeight:"100px"}} >
                    {populateModalContent()}
                </Grid>
            </Grid>
        </PopUpModal>
    )
}

export default PurchaseProcessModal
