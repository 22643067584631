import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// Stripe
import { getStripe } from '../../aux/stripeHelpers';
import { Elements } from '@stripe/react-stripe-js';
import { Grid, Typography, Box, Divider, Button } from '@material-ui/core';
import CheckoutPaymentMethod from '../CheckoutPaymentMethod/CheckoutPaymentMethod';
import CheckoutPaymentMethodSkeleton from '../CheckoutPaymentMethodSkeleton/CheckoutPaymentMethodSkeleton';
import { requestMinimumTimeoutWrapper } from '../../aux/requestMethods';
import { createPaymentIntentFn } from '../../aux/purchaseQueries';
import { isEmptyObject, isNotEmptyObject } from '../../aux/aux';
// SVg & img
import StripeLogo from '../../img/stripe-logo.svg'
import './fastCheckoutPay.css';
import { useTheme } from '@material-ui/styles';
import CheckoutPromoCode from '../CheckoutPromoCode/CheckoutPromoCode';
import { rgbToHex } from '@material-ui/core';
import { getReliableLocale } from '../../aux/sessionHelpers';
import BackButton from '../BackButton/BackButton';
import CheckoutTotal from '../CheckoutTotal/CheckoutTotal';
import { getUserContactData, getUserDefaultPaymentMethod } from '../../services/userServices';

const FastCheckoutPay = ({ 
    handlerAppliedPromoCode, handlerPurchaseStep, handlerFastCheckoutContent,
    handlerIsCheckoutPaymentMethodReady, handlerPaymentIntentClientSecret, addMessages, setPaymentIntentId, checkoutSyncAfterPIFailureCallback,
    updateCheckoutStatus, updatePaymentIntent
}) => {
    const theme = useTheme();
    const location = useLocation();
    const { t } = useTranslation('common', { keyPrefix: 'fastCheckoutPay' });
    const locale = getReliableLocale();
    const [fastCheckoutContent, setFastCheckoutContent] = handlerFastCheckoutContent;
    const [isLoading, setIsLoading] = useState(false);
    const [paymentIntentClientSecret, setPaymentIntentClientSecret] = handlerPaymentIntentClientSecret;
    const [appliedPromoCode, setAppliedPromoCode] = handlerAppliedPromoCode;
    const [purchaseProcess, setPurchaseProcess] = handlerPurchaseStep;
    const [isCheckoutPaymentMethodReady, setIsCheckoutPaymentMethodReady] = handlerIsCheckoutPaymentMethodReady;
    const [redirectUrl, setRedirectUrl] = useState(window.location.origin + location.pathname + `?fast_checkout_id=${fastCheckoutContent.id}`);
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
    const [userContactData, setUserContactData] = useState(null);
    const abortControllerSignalRef = useRef(null);
    const stripeCheckoutAppearance = {
        theme:'stripe',
        variables:{
            fontFamily: theme.typography.body1.fontFamily,
            fontSizeBase: theme.typography.fontSize,
            colorText: rgbToHex(theme.palette.text.primary),
            colorPrimary: theme.palette.primary.main,
        }
    };
    const stripeCheckoutOptions = {
        appearance: stripeCheckoutAppearance,
        clientSecret: paymentIntentClientSecret,
    }

    const handleOnBackButtonClick = (e) => {
        e.preventDefault();
        setPurchaseProcess(purchaseProcess => purchaseProcess - 1);
        setIsCheckoutPaymentMethodReady(false);
    }

    const getPaymentIntentClientSecret = async (userId, checkoutContent, userLocaleCode, signal) => {
        try{
            const requestParams = {
                userId,
                checkoutId: checkoutContent.id,
                userLocaleCode,
                paymentMethodUsage : isEmptyObject(checkoutContent.subscriptionProductMap) ? 'on_session' : 'off_session',
                hashFinalAmount: checkoutContent.orderAmounts.hashFinalAmount,
                currencyId: checkoutContent.currencyId,
            };
            const res = await requestMinimumTimeoutWrapper(createPaymentIntentFn, requestParams, 0, signal);
            // const redirectUrl = window.location.origin + location.pathname;
            // setRedirectUrl(redirectUrl);
            if(!signal || !signal.aborted){
                setPaymentIntentClientSecret(res.data.paymentIntentClientSecret);
            }
        }catch(error){
            // TODO: implement;
        };
    };

    const requestDefaultPaymentMethod = async (userId, signal) => {
        let result = {};
        try{
            const res = await getUserDefaultPaymentMethod(userId, 0, signal);
            result = res.data;
        }catch(error){
            // TODO: implement
            console.log('Default payment method no available');
        }finally{
            if(!signal.aborted){
                setDefaultPaymentMethod(result);
            }
        }
    }

    const requestUserContactData = async(userId, signal) => {
        let result = {};
        try{
            const res = await getUserContactData(userId, 0, signal);
            result = res.data;
        }catch(error){
            // Contact data is collected in the payment element
        }finally{
            if(!signal.aborted){
                setUserContactData(result);
            }
        }
    }

    const updatePaymentIntentWithPaymentMethod = async(paymentMethodId) => {
        const updateIntentParams = {
            paymentMethodId,
            userId: fastCheckoutContent.userId, 
            checkoutId: fastCheckoutContent.id,
            paymentIntentClientSecret,
        }
        return updatePaymentIntent(updateIntentParams, 0)
    }

    useEffect(() => {
        const abortController = new AbortController();
        abortControllerSignalRef.current = abortController.signal;
        requestDefaultPaymentMethod(fastCheckoutContent.userId, abortController.signal);
        requestUserContactData(fastCheckoutContent.userId, abortController.signal);
        return (() => abortController.abort())
    },[])

    useEffect(() => {
        if(!paymentIntentClientSecret){
            const abortController = new AbortController();
            getPaymentIntentClientSecret(fastCheckoutContent.userId, fastCheckoutContent, locale.localeCode, abortController.signal);
            return (() => abortController.abort());
        }
    },[paymentIntentClientSecret])

    return (
        <Grid container direction='column' >
             <Grid item>
                <Grid container justifyContent='space-between'>
                    <Grid item>
                        <Typography variant="h5">
                            <Box fontWeight="bold">
                            {t('payThePurchase')}
                            </Box>
                        </Typography>
                        <Typography variant='body2'>
                            {t("lastStepBeforeEnjoying")}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Divider style={{marginTop:"10px"}}/>
            <Grid item>
                <BackButton isDisabled={isLoading} onClick={handleOnBackButtonClick}/>
            </Grid>
            <Grid item className='fast-checkout-pay-total'>
                <CheckoutTotal isCheckoutUpdating={isLoading} orderAmounts={fastCheckoutContent.orderAmounts} appliedPromoCode={appliedPromoCode} />
            </Grid>
            <Grid item className='fast-checkout-pay-payment-method'>
                {paymentIntentClientSecret && redirectUrl && defaultPaymentMethod && userContactData &&
                    <Elements options={stripeCheckoutOptions} stripe={getStripe()} >
                        <CheckoutPaymentMethod 
                            checkoutId={fastCheckoutContent.id}
                            checkoutProductsMap={fastCheckoutContent.productsAndCouponsMap}
                            addMessages={addMessages} 
                            billingAddress={fastCheckoutContent}
                            contactData={userContactData}
                            redirectUrl={redirectUrl} 
                            setPaymentIntentId={setPaymentIntentId} 
                            setIsReady={setIsCheckoutPaymentMethodReady} 
                            isReady={isCheckoutPaymentMethodReady} 
                            defaultPaymentMethod={isEmptyObject(defaultPaymentMethod) ? null : defaultPaymentMethod}
                            finalAmountObject={ {hashFinalAmount: fastCheckoutContent.orderAmounts.hashFinalAmount, currencyId: fastCheckoutContent.currencyId} } 
                            handleIsCheckoutUpdating={[isLoading, setIsLoading]} 
                            updateCheckoutStatus={updateCheckoutStatus}
                            checkoutSyncAfterPIFailureCallback={checkoutSyncAfterPIFailureCallback} 
                            updatePaymentIntentWithPaymentMethod={updatePaymentIntentWithPaymentMethod}
                        />
                    </Elements>
                }
                {!isCheckoutPaymentMethodReady &&
                    <CheckoutPaymentMethodSkeleton />
                }
            </Grid>
            <Grid item className="fast-checkout-pay-stripe-logo" align="right">
                <StripeLogo height={20} />
            </Grid>
        </Grid>
    )
}

export default FastCheckoutPay