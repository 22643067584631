import { Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import CountDown from '../CountDown/CountDown'
import ClickableLoadingText from '../ClickableLoadingText/ClickableLoadingText'
import { useTranslation, Trans } from 'react-i18next'
import { fromCentTo2dec, round2dec } from '../../aux/purchaseHelpers'
import CloseIcon from '../../img/close-fill.svg';
import { getReliableCurrencyId } from '../../aux/sessionHelpers'
import CopyToClipboard from '../CopyToClipboard/CopyToClipboard'
import InfoLabelPopOver from '../InfoLabelPopOver/InfoLabelPopOver'
import { textTransform, textTruncate } from '../../aux/aux'
import './bannerPromo.css'

const BannerPromo = ({ promo, endsDate, color='primary', onClose=()=>{}, knowMorePath, txt={name:'', description:'', endsDateLabel:''}, wrapperClassName=undefined, wrapperStyle={} }) => {
    const MAX_PROMO_NAME_CHAR_LENGTH = 20;
    const MAX_PROMO_DESCRIPTION_CHAR_LENGTH = 30;
    const { t } = useTranslation('common');
    const currencyId = getReliableCurrencyId();
    const matchDesktop = useMediaQuery('(min-width:1120px)');
    const matchMobile = useMediaQuery('(max-width:680px)');
    const theme = useTheme();
    const styles = {
        wrapper:{
            backgroundColor: theme.palette[color].darker,
            color: theme.palette[color].contrastText,
            ...(wrapperStyle || {})
        },
        name:{
            fontWeight: 'bold'
        },
        label:{
            textAlign: 'center',
            color: theme.palette[color].light
        },
        labelinfoIcon:{
            fill: theme.palette[color].light
        },
        discountOff:{
            fontWeight: 'bold'
        },
        codeContainer:{
            borderRadius: '5px',
            backgroundColor: theme.palette[color].main,
            color:theme.palette[color].lighter,
        },
        available:{
            color:theme.palette[color].light,
            fontWeight: 'bold',
        },
        closeIconContainer:{
            height: `24px`,
        },
        closeIcon:{
            height: `24px`,
            display: 'block',
            fill: theme.palette[color].light
        }
    }
    const getDiscountOffString = () => {
        const isPercentOff = promo.details.coupon.percentOff !== null;
        const discountOff =  promo.details.coupon.percentOff || promo.details.coupon.amountOff;
        let discountOffString;
        if(discountOff){
            discountOffString = isPercentOff ? t('{{val, number}}',{ style:'percent', val:discountOff/100 * -1, trailingZeroDisplay:'stripIfInteger'}) : t('{{val, currency}}',{ val:fromCentTo2dec(discountOff * -1), currency: currencyId, trailingZeroDisplay:'stripIfInteger'  })
        }
        return discountOffString
    }

    const infoLabelProps = {
        anchorOrigin:  {
            vertical:'bottom', 
            horizontal:'center'
        },
        transformOrigin: {
            vertical:'top', 
            horizontal: 'center'
        }
    }
    const copyToClipboardProps = {
        textToCopy:promo.id,
        clickInIcon:false,
        textTruncateLength:10,
        type:'text',
        inputContainerStyle:{ 
            maxWidth:'110px', 
            padding:'0px', 
            border: 'none', 
            backgroundColor:'transparent', 
        },
        inputStyle:{
            textAlign: 'center',
            color: theme.palette[color].lighter,  
            fontSize: theme.typography.body2.fontSize, 
            fontWeight:'bold',
            padding: '0px',
            height: `${theme.typography.body2.lineHeight}em`,
        },
        iconStyle:{
            height: theme.typography.body2.fontSize,
            width: theme.typography.body2.fontSize,  
            color: theme.palette[color].lighter
        },
        customPopoverProps:{
            anchorOrigin:{
                vertical:'bottom', 
                horizontal:'center'
            },
            transformOrigin:{
                vertical:'top', 
                horizontal:'center'
            }
        }
    }

    return (
        <div className={`banner-promo-wrapper ${wrapperClassName || ''}`} style={styles.wrapper}>
            <div className='banner-promo-main-container'>
                {/* <div className='banner-promo-description-container banner-promo-main-container-item'>
                    <Typography variant='body2' style={styles.name} className='banner-promo-drescription-title'>
                        {textTruncate(txt.name, MAX_PROMO_NAME_CHAR_LENGTH)}
                    </Typography>
                    <Typography variant={matchDesktop ? 'body2' : 'caption'}>
                        {textTruncate(txt.description, MAX_PROMO_DESCRIPTION_CHAR_LENGTH)}
                    </Typography>
                </div> */}
                {matchMobile ?
                    <>
                        <div className='banner-promo-code-container banner-promo-main-container-item'>
                            <div className='banner-promo-code-value-container' style={styles.codeContainer}>
                                <CopyToClipboard {...copyToClipboardProps} />
                            </div>
                            {promo.details.occurrence.maxRedemptions ?
                                <InfoLabelPopOver 
                                    label={`${promo.details.occurrence.totalRedemptions}/${promo.details.occurrence.maxRedemptions}`} 
                                    labelPopOverMsg={<Trans t={t} values={{ code: promo.id }} i18nKey={'bannerPromo.numOfavailablePromoCode'}  />}
                                    labelStyle={styles.available}
                                    infoIconStyle={styles.labelinfoIcon}
                                    variant={'caption'}
                                    {...infoLabelProps}
                                />
                            :
                                <InfoLabelPopOver 
                                    label={textTransform('title', t('code'))}
                                    labelPopOverMsg={<Trans t={t} values={{ code: promo.id }} i18nKey={'bannerPromo.applyThisCodeInCheckout'}  />}
                                    labelStyle={styles.label}
                                    infoIconStyle={styles.labelinfoIcon}
                                    variant={'caption'}
                                    {...infoLabelProps}
                                />
                            }
                        </div>
                        <div className='banner-promo-discount-container banner-promo-main-container-item'>
                            <div className='banner-promo-discount-value-container'>
                                <Typography variant='body2' style={styles.discountOff}>
                                    {getDiscountOffString()}
                                </Typography>
                            </div>
                            <Typography variant='caption' style={styles.label}>
                                {textTransform('title', t('discount'))}
                            </Typography>
                        </div>
                        {endsDate ?
                            <div className='banner-promo-end-container banner-promo-main-container-item'>
                                <CountDown 
                                    endsDate={new Date()}
                                    label={txt.endsDateLabel}
                                    stylesMap={{
                                        mainContainer:{
                                            justifyContent:'center'
                                        },
                                        digitContainer:{
                                            backgroundColor: theme.palette[color].dark,
                                        },
                                        digits:{
                                            color: theme.palette[color].lighter,
                                        },
                                        label:{
                                            color: theme.palette[color].light
                                        }
                                    }}
                                />
                            </div>
                            :
                            null
                        }
                    </>
                :    
                    <>
                        <div className='banner-promo-description-container banner-promo-main-container-item'>
                            <Typography variant='body2' style={styles.name} className='banner-promo-drescription-title'>
                                {textTruncate(txt.name, MAX_PROMO_NAME_CHAR_LENGTH)}
                            </Typography>
                            <Typography variant={matchDesktop ? 'body2' : 'caption'}>
                                {textTruncate(txt.description, MAX_PROMO_DESCRIPTION_CHAR_LENGTH)}
                            </Typography>
                        </div>
                        <div className='banner-promo-promo-container banner-promo-main-container-item'>
                            <div className='banner-promo-promo-container-item'>
                                <div className='banner-promo-promo-container-item-value-container'>
                                    <Typography variant='body2' style={styles.discountOff}>
                                        {getDiscountOffString()}
                                    </Typography>
                                </div>
                                <Typography variant='caption' style={styles.label}>
                                    {textTransform('title',t('discount'))}
                                </Typography>
                            </div>
                            <div className='banner-promo-promo-container-item'>
                                <div className='banner-promo-promo-container-item-value-container' style={styles.codeContainer}>
                                    <CopyToClipboard {...copyToClipboardProps} />
                                </div>
                                <Typography variant='caption' style={styles.label}>
                                    {textTransform('title',t('promoCode'))}
                                </Typography>
                            </div>
                            {promo.details.occurrence.maxRedemptions ?
                                    <div className='banner-promo-promo-container-item'>
                                        <div className='banner-promo-promo-container-item-value-container'>
                                            <InfoLabelPopOver 
                                                label={`${promo.details.occurrence.totalRedemptions}/${promo.details.occurrence.maxRedemptions}`} 
                                                labelPopOverMsg={<Trans t={t} values={{ code: promo.id }} i18nKey={'bannerPromo.numOfavailablePromoCode'}  />}
                                                labelStyle={styles.available}
                                                infoIconStyle={styles.labelinfoIcon}
                                                variant={'body2'}
                                                {...infoLabelProps}
                                            />
                                        </div>
                                        <Typography variant='caption' style={styles.label}>
                                            {textTransform('title',t('available', {count: Math.max(promo.details.occurrence.maxRedemptions - promo.details.occurrence.totalRedemptions, 0)}))}
                                        </Typography>
                                    </div>
                                :
                                    null
                            }
                            {matchDesktop ?
                                <div className='banner-promo-know-more-container banner-promo-container-item'>
                                     <div className='banner-promo-promo-container-item-value-container'>
                                        <Typography variant='caption'>
                                            {t('bannerPromo.applyInCheckout')}
                                        </Typography>
                                    </div>
                                    {knowMorePath ?
                                        <ClickableLoadingText 
                                            isVisible={true}
                                            isLoading={false}
                                            variant='caption'
                                            text={t('knowMore')}
                                            color={theme.palette[color].light}
                                            underline
                                        />
                                        :
                                        null
                                    }
                                </div>
                                :
                                    null
                            }
                        </div>
                        {endsDate ?
                            <div className='banner-promo-end-container banner-promo-main-container-item'>
                                <CountDown 
                                    endsDate={endsDate}
                                    label={txt.endsDateLabel}
                                    stylesMap={{
                                        mainContainer:{
                                            justifyContent:'center'
                                        },
                                        digitContainer:{
                                            backgroundColor: theme.palette[color].dark,
                                        },
                                        digits:{
                                            color: theme.palette[color].lighter,
                                        },
                                        label:{
                                            color: theme.palette[color].light
                                        }
                                    }}
                                />
                            </div>
                            :
                                null
                        }
                    </>
                }   
                <div className='banner-promo-close-container' style={styles.closeIconContainer} onClick={onClose} >
                    <CloseIcon style={styles.closeIcon}/>
                </div>
            </div>
        </div>
    )
}

export default BannerPromo