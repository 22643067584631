import { Typography, useMediaQuery } from '@material-ui/core'
import './headerWrapperSection.css'

const HeaderWrapperSection = ({title, subtitle, className, wrapperClassName, titleClassName, subtitleClassName, children, styleMap={wrapper:undefined, main:undefined}}) => {
    const matchMobile = useMediaQuery('(max-width: 680px)');
    const styles = {
        title: {
            textAlign: 'center',
            fontWeight: 'bold'
        },
        subtitle: {
            fontWeight: 'bold'
        }
    }
    return (
        <div className={wrapperClassName ? wrapperClassName : 'section-header-wrapper'} style={styleMap.wrapper}>
            <div className={className ? className : 'section-header-wrapper-main-container'} style={styleMap.main}>
                {title &&
                    <Typography 
                        variant={matchMobile ? 'h5' : 'h4'} 
                        style={styles.title}
                        className={titleClassName ? titleClassName : 'section-header-wrapper-title'}
                    >
                        {title}
                    </Typography>
                }
                {subtitle &&
                    <Typography 
                        variant={matchMobile ? 'h6' : 'h5'} 
                        style={styles.subtitle}
                        className={subtitleClassName ? subtitleClassName : 'section-header-wrapper-subtitle'}
                    >
                        {subtitle}
                    </Typography>
                }
                {children}
            </div>
        </div>
    )
}

export default HeaderWrapperSection