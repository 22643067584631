import { useEffect, useState } from "react"
import CrownIcon from '../../img/crownIcon.svg'
import CrownIconOutlined from '../../img/crownIconOutlined.svg'

const DEFAULT_SUBSCRIPTION_TYPE = {
    level: undefined,
    name: undefined
}
const DEFAULT_STYLE = {}
const SubscriptionIcon = ({ subscriptionType=DEFAULT_SUBSCRIPTION_TYPE, isFilled=false, style=DEFAULT_STYLE, ...props }) => {
    const styles = {
        icon:{
            display: 'block',
        },
        level:{
            1:{
                color: '#9293ab'
                // color: "#c0c0c0", // silver
                // stroke: "#a9a9a9", // dark grey
                // strokeWidth: "1px"
            },
            2:{
                color: "#d7b404"
                // color: "#ffd700", // gold
                // stroke: "#b8860b", // darkgoldenrod
                // strokeWidth: "1px"
            },
        }
    }
    
    const renderIcon = () => {
        let Icon;
        if(subscriptionType && subscriptionType.level){
            if(subscriptionType.level === 1){
                Icon = isFilled ? CrownIcon : CrownIconOutlined;
            }else if(subscriptionType.level === 2){
                Icon = isFilled ? CrownIcon : CrownIconOutlined;
            }
        }else{
            Icon = isFilled ? CrownIcon : CrownIconOutlined;
        }
        return <Icon style={{ ...styles.icon, ...styles.level[subscriptionType.level] || {}, ...style}} {...props} />
    }
    
    return renderIcon()
}

export default SubscriptionIcon