import React from 'react'
import { useInfoBannerPropsCtx } from '../../customHooks/useInfoBannerPropsCtx'
import CookieConsentCustom from '../CookieConsentCustom/CookieConsentCustom'
import BannerAPIConnectivity from '../BannerAPIConnectivity/BannerAPIConnectivity'
import { loadScript } from '../../aux/aux'
import { useScrollableDocumentCtx } from '../../customHooks/useScrollableDocumentCtx'
import './infoBannerContainer.css'

const InfoBannerContainer = () => {
    const infoBannerProps = useInfoBannerPropsCtx()
    const {isScrollableDocument} = useScrollableDocumentCtx();
    const styles = {
        bannerContainer: {
            ...infoBannerProps.style,
            boxSizing: infoBannerProps.style.boxSizing || 'border-box',
            paddingRight: !isScrollableDocument && (infoBannerProps.style.position === 'fixed' || infoBannerProps.style.position === 'absolute') ? document.body.style.paddingRight : infoBannerProps.style.paddingRight
        }
    }
    const handleOnConsentCookies = ( acceptedByScrolling ) => {
        if(!acceptedByScrolling){
            // we need a proper consent
            loadScript(
                'klaviyo-api',
                `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${process.env.REACT_APP_KLAVIYO_COMPANY_ID}`,
                () => { console.log('Klaviyo available')},
                () => { console.log('Klaviyo no available')}
            )
        }
    }

    return (
        <div className='info-banner-container' style={styles.bannerContainer}>
            <CookieConsentCustom debug={process.env.REACT_APP_TARGET_ENV !== 'production'} onAccept={handleOnConsentCookies} />
            <BannerAPIConnectivity />
        </div>
    )
}

export default InfoBannerContainer