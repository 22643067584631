import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Button } from '@material-ui/core';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import ButtonLoading from '../ButtonLoading/ButtonLoading';
import StripeLogo from '../../img/stripe-logo.svg'
import './cardPaymentMethodForm.css'
import { getPaymentMethodFromIntentId } from '../../aux/purchaseHelpers';
import { textTransform } from '../../aux/aux';

const CardPaymentMethodForm = ({ billingAddress, redirectURL, onCancel, onUpdate, onError, onChange }) => {
    const PAYMENT_ELEMENT_OPTIONS = {
        fields: {
            billingDetails: {
                address: {
                    country: 'never',
                    postalCode: 'never'
                }
            }
        }
    }
    const elements = useElements();
    const stripe = useStripe();
    const { t } = useTranslation('common', { keyPrefix: 'cardPaymentMethodForm' })
    const [isLoading, setIsLoading] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const abortControllerRef = useRef(null);
    const styles = {
        agreeButton: { paddingLeft:'20px' },
        stripeLogo: { paddingTop:'20px' }
    }
    const confirmSetUpIntent = async (e, signal=abortControllerRef.current.signal) => {
        try{
            e.preventDefault();
            setIsLoading(true);
            if(!stripe || !elements){
                // stripe.js has not loaded yet
            }else{
                const res = await stripe.confirmSetup({
                    elements,
                    confirmParams: { 
                        return_url: redirectURL,
                        payment_method_data: {
                            billing_details: {
                                address: {
                                    country: billingAddress.countryCode,
                                    postal_code: billingAddress.zipCode, 
                                }
                            }
                        } 
                    },
                    redirect:'if_required'
                })
                if(res.error){
                    if(res.error.setup_intent){
                        // stripe intent life circle
                        // https://stripe.com/docs/payments/intents 
                        const intentStatus = res.error.setup_intent.status;
                        let msg;
                        let resetIntent = false;
                        switch(intentStatus){
                            case 'requires_action':
                                // authentication. 
                                // This should to be handled by Stripe PaymentElement
                                break;
                            case 'processing':
                                // while required actions are handled
                                // This should to be handled by Stripe PaymentElement
                                break;
                            default:
                                resetIntent = intentStatus !== 'requires_payment_method';
                                msg = `${textTransform('title', t('errorProcessingThePaymentMethod'))}. ${textTransform('title', t('tryItAgainWithADifferentPayMethod'))}`
                        }
                        if(!signal.aborted){
                            onError(textTransform('title',msg), resetIntent)
                        }
                    }else{
                        // handled by the form
                    }
                }else{
                    const intent = res.setupIntent;
                    // retrive pm from server
                    // TODO: update by push notification from server
                    // this object is retrived from stripe through the server-side
                    // it does not confirm that the new pm has been properly inserted in our db
                    const serverRes = await getPaymentMethodFromIntentId(intent.id, 0, signal);
                    const newPaymentMethod = serverRes.data;
                    if(!signal.aborted){
                        onUpdate(newPaymentMethod);
                    }
                }
            }
        }catch(error){
            console.log(error)
            if(!signal.aborted){
                const msg = `${textTransform('title',t('somethingWentWrong'))}. ${textTransform('title',t('tryItLater'))}`;
                onError(msg)
            }
        }finally{
            if(!signal.aborted){
                setIsLoading(false)
            }
        }
    }

    const handleOnReady = (e) => {
        setIsReady(true);
    }

    const handleOnClickCancel = (e) => {
        if(elements){
            const paymentElement = elements.getElement('payment');
            paymentElement.clear()
        }
        onCancel();
    }

    useEffect(() => {
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        return(() => { abortController.abort()})
    },[])

    return (
        <Grid container direction='column'>
            <form onSubmit={confirmSetUpIntent}>
                <Grid item>
                    <PaymentElement onReady={handleOnReady} onChange={onChange} options={PAYMENT_ELEMENT_OPTIONS} />
                </Grid>
                <Grid item container justifyContent='flex-end'>
                    <Grid item>
                        <Button
                            onClick={handleOnClickCancel}
                            color='primary'
                        >
                            {t('common:cancel')}
                        </Button>
                    </Grid>
                    <Grid item style={styles.agreeButton}>
                        <ButtonLoading 
                            variant='contained'
                            color='primary'
                            type='submit' 
                            isLoading={isLoading}
                            label={t('common:ok')}
                            disabled={!isReady}
                        />
                    </Grid>
                </Grid>
            </form>
            <Grid item align="right" style={styles.stripeLogo}>
                <StripeLogo height={20} />
            </Grid>
        </Grid>
    )
}

export default CardPaymentMethodForm