import { useEffect, useState } from 'react';
import { Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import AccountIcon from "../../img/account-icon.svg";
import ProIcon from './../../img/proIcon.svg';
import ImgDN from '../../cdn/imgDN';
import cloudinary from '../../cdn/providers/cloudinary';
import { getFromSafeObject, textTruncate } from '../../aux/aux';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import SubscriptionIcon from '../SubscriptionIcon/SubscriptionIcon';
import './userProfileBadge.css'

const UserProfileBadge = ({ user, isLoading=false, isBadgeExpanded=true}) => {
    const IMG_CONTAINER_HEIGHT_PX = 30;
    const IMG_RESOLUTION_SCALE_FACTOR = 3;
    const PRO_ICON_HEIGHT_PX = 20;
    const assetProvider = new ImgDN(cloudinary);
    const theme = useTheme();
    const [ImgElem, setImgElem] = useState(null);
    const styles = {
        loading:{
            height: IMG_CONTAINER_HEIGHT_PX,
            width: IMG_CONTAINER_HEIGHT_PX
        },
        avatarIcon:{
            display: 'block',
            height: IMG_CONTAINER_HEIGHT_PX
        },
        subscriptionIcon:{
            fontSize: theme.typography.body1.fontSize,
        },
        img:{
            height:`${IMG_CONTAINER_HEIGHT_PX}px`
        }
    }
    const getImgProfileComponent = () => {
        let imgProfileComponent;
        if(getFromSafeObject(user, 'basicData.profileImgURL')){
            const profileImgURL = assetProvider.getAssetUrl(user.basicData.profileImgURL, {height: IMG_CONTAINER_HEIGHT_PX * IMG_RESOLUTION_SCALE_FACTOR});
            // const profileImgURL = assetProvider.getAssetUrl(user.basicData.profileImgURL);
            imgProfileComponent = <img className="user-profile-badge-img" alt="profile-img" src={profileImgURL} style={styles.img} />
        }
        if(imgProfileComponent == undefined){
            imgProfileComponent = <AccountIcon style={styles.avatarIcon}/>
        }
        setImgElem(imgProfileComponent)
    }
    useEffect(() => {
        getImgProfileComponent();
    },[user])

    return (
        <div className='user-profile-badge-wrapper'>
            {user && ImgElem && !isLoading ?
                <div className='user-profile-badge-main-container'>
                    {ImgElem}
                    {isBadgeExpanded &&
                        <Typography variant='body2'>
                            {textTruncate(user.basicData.firstName, 15)}
                        </Typography>
                    }
                    {getFromSafeObject(user, 'subscription.isActive') == true &&
                        <SubscriptionIcon subscriptionType={user.subscription.subscriptionType} style={styles.subscriptionIcon} isFilled />
                    }
                </div>
                :
                <LoadingComponent visibleElements='circle' bothStyle={styles.loading} />
            }
        </div>
    )
}

export default UserProfileBadge