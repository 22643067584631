import { useEffect } from "react"
import { triggerEventWithData } from "../../aux/internalCommunicationHelpers";
import { clearUserSessionVariables } from "../../aux/sessionHelpers";
import { justSetUser, recoveryUser } from "../../services/sessionServices";
import { useUpdateLocaleCtx } from "../../customHooks/useUpdateLocaleCtx";
import { useUpdateCountryAndCurrencyCtx } from "../../customHooks/useUpdateCountryAndCurrencyCtx";
import { useTranslation } from "react-i18next";

const RestorePreviousUserSession = ({ setUser, addMessages }) => {
    const query = new URLSearchParams(window.location.search);
    const { t } = useTranslation('common');
    const updateLocaleCtx = useUpdateLocaleCtx();
    const updateCountryAndCurrencyCtx = useUpdateCountryAndCurrencyCtx();

    const recoveryUserFromSessionTokens = async (signal) => {
        try{
            triggerEventWithData('loadingPopUp', { message:`${t('restoringSession')}...`, show:true })
            const userData = await recoveryUser(1000, signal);
            justSetUser(userData, setUser);
            updateCountryAndCurrencyCtx.setCountryAndCurrency(userData.countryAndCurrency);
            await updateLocaleCtx(userData.locale);
        }catch(error){
            if(!signal.aborted){
                clearUserSessionVariables(); // logoutUserOnClient is not needed since user is null
                addMessages([{ message: `${t('sessionCouldNotBeRestored')}.\n${t('logInAgain')}`, severity:'error' }])
            }
        }finally{
            triggerEventWithData('loadingPopUp', { show:false })
        }
    }

    useEffect(() => {
        const abortController = new AbortController();
        const fromRedirect = query.get('redirect');
        if(!fromRedirect){
            recoveryUserFromSessionTokens(abortController.signal);
        }
        return(() => {
            abortController.abort();
        })
    },[]);
    return null
}

export default RestorePreviousUserSession