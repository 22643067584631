import { Grid } from '@material-ui/core';
// Components
import CheckoutBillingAddress from '../CheckoutBillingAddress/CheckoutBillingAddress';
import CheckoutDetails from '../CheckoutDetails/CheckoutDetails';
import CheckoutItems from '../CheckoutItems/CheckoutItems';
import { useEffect, useRef } from 'react';
// Style
import './checkoutOverview.css'
import { getCustomErrorOrUndefined } from '../../aux/errorHelpers';
import { isNotEmptyObject, textTransform } from '../../aux/aux';
import { useTranslation } from 'react-i18next';
import PurchaseProductSkeleton from '../PurchaseProductSkeleton/PurchaseProductSkeleton';

const CheckoutOverview = ({ updateCheckoutContent, checkoutContent, appliedPromoCode, isCheckoutUpdating, isCheckoutShowingFeedback, addMessages }) => {
    const { t } = useTranslation('checkout', {keyPrefix:'checkoutItemsOverview'});
    const abortSignalRef = useRef(null);

    const updateBillingAddress = async (billingAddress) => {
        try{
            const data = await updateCheckoutContent('updateBillingAddress', {billingAddress});
            return data;
        }catch(error){
            // It is handle on CheckoutBillingAddress
            throw error;
        }
    }
    const handleAddProductToCheckout = async(checkoutProductArray) => {
        try{
            await updateCheckoutContent('add', { checkoutProductArray });
        }catch(error){
            const customError = getCustomErrorOrUndefined(error);
            if(customError && customError.payload){
                const {
                    noPurchasableProductToAddIndexArray
                } = customError.payload;
                const errorMsgArray = [];
                for(let index of noPurchasableProductToAddIndexArray){
                    const checkoutProduct = checkoutProductArray[index];
                    if(checkoutProduct.productTranslatedName){
                        errorMsgArray.push({
                            severity:'error',
                            message: textTransform('title', t("cannotAddToCheckout", {productName: checkoutProduct.productTranslatedName})),
                        })
                    }
                }
                addMessages(errorMsgArray)
            }
            throw error;
        }
    }
    const handleDeleteOrUpdateProductQuantityToCheckout = async(product, quantity) => {
        try{
            if(quantity !== product.quantity){
                const checkoutProduct = {
                    quantity,
                    productId: product.productId,
                    productTypeName: product.productTypeName
                };
                let opId, opParam;
                if(quantity == 0){
                    opId = 'delete';
                    opParam = { checkoutProductArray: [checkoutProduct] };
                }else{
                    opId ='productQuantity';
                    opParam = { checkoutProduct };
                }
                await updateCheckoutContent(opId, opParam);
            }
        }catch(error){
            // handle by the caller function
            throw error;
        }
    }

    const handleSwapProductsToCheckout = async(oldProductId, newProductId) => {
        try{
            const opParam = {
                oldProductId,
                newProductId
            }
            await updateCheckoutContent('swap', opParam) 
        }catch(error){
            // handle by the caller function
            throw error
        }
    }

    const handleApplyPromoCodeToCheckout = async(promoCodeId) => {
        try{
            const opParam = { promoCodeId }
            const data = await updateCheckoutContent('applyPromoCode', opParam)
            return data;
        }catch(error){
            throw error
        }
    }

    const handleTakeOutPromoCodeToCheckout = async() => {
        try{
            const opParam = {}
            const data = await updateCheckoutContent('takeOutPromoCode', opParam);
            return data;
        }catch(error){
            throw error
        }
    }

    useEffect(() => {
        const abortController = new AbortController();
        abortSignalRef.current = abortController.signal;
        return(() => {
            abortController.abort()
        })
    },[])

    return (
        <div className='checkout-overview-main-container' style={{pointerEvents: isCheckoutShowingFeedback ? 'none' : ''}}>
            <Grid item className='checkout-overview-billing-address'>
                <CheckoutBillingAddress updateBillingAddress={updateBillingAddress} checkoutContent={checkoutContent} isCheckoutUpdating={isCheckoutUpdating} />
            </Grid>
            <Grid item className='checkout-overview-product-container'>
                <CheckoutItems 
                    addItemArray={handleAddProductToCheckout} 
                    deleteOrUpdateItemQuantity={handleDeleteOrUpdateProductQuantityToCheckout} 
                    swapItems={handleSwapProductsToCheckout}
                    checkoutContent={checkoutContent} 
                    isCheckoutUpdating={isCheckoutUpdating} 
                />
            </Grid>
            <Grid item className='checkout-overview-details'>
                <CheckoutDetails 
                    applyPromoCode={handleApplyPromoCodeToCheckout} 
                    takeOutPromoCode={handleTakeOutPromoCodeToCheckout}
                    checkoutContent={checkoutContent} appliedPromoCode={appliedPromoCode} 
                    isCheckoutUpdating={isCheckoutUpdating} 
                />
            </Grid>
        </div>
    )
}

export default CheckoutOverview