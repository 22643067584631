import { useState, useEffect } from 'react';
import { Chip, List, ListItem, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import ImgLazyLoad from '../ImgLazyLoad/ImgLazyLoad'
import ImgDN from '../../cdn/imgDN';
import cloudinary from '../../cdn/providers/cloudinary';
// import authorImg from '../../img/author.png';
// import authorImg from '../../img/fotoPruebaOlaya.png';
import './professorHero.css'
import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper';
import { useTranslation } from 'react-i18next';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import { getFromSafeObject, isNotEmptyObject, textTransform } from '../../aux/aux';
import { fetchPublicFileFromApi } from '../../services/staticContentServices';

const ProfessorHero = ({ professor, imgContainerClassname, imgStyle={}, style={}, noLeadPaperStyle={}, isLead=true, imgLast=true, imgLazyLoadThreshold='600px'}) => {
    const { t } = useTranslation('contentProduct');
    const theme = useTheme()
    const assetProvider = new ImgDN(cloudinary);
    const matchDesktop = useMediaQuery('(min-width:1120px');
    const matchMobile = useMediaQuery('(max-width:680px)');
    const [portraitImg, setPortraitImg] = useState(null);
    const [professorPage, setProfessorPage] = useState(null);
    const styles = {
        fullName:{
            // fontFamily: "'Young Serif', serif",
            fontWeight: "bold"
        },
        expertiseAreas:{
            color: theme.palette.grey[400]
        },
        chip:{
            opacity:'0.4'
        },
        mainContainer:{
            ...(isLead ?
                {
                    justifyContent: matchDesktop ? imgLast === true ? '' : 'flex-end' : ''
                }
                :
                {
                    justifyContent: 'center',
                    columnGap: '32px'
                }
            )
        },
        infoContainer:{
            order: matchDesktop && imgLast === true ? '0' : '1'
        },
        imgContainer:{
            ...(isLead ?
                {
                    right: matchDesktop ? imgLast === true ? '0px' : '' : '',
                    left: matchDesktop ? imgLast === true ? '' : '0px' : '',
                }
                :
                {
                    order: matchDesktop && imgLast === true ? '1' : '0',
                    justifyContent: matchDesktop && imgLast === true ? 'left' : 'right'
                }
            )
        }
    } 
    const getAchievementIcon = (type, props={}) => {
        let Icon;
        switch(type){
            case 'academic':
                Icon = SchoolOutlinedIcon;
                break;
            case 'teaching':
                Icon = EmojiEventsOutlinedIcon;
                break;
            case 'profession':
                Icon = GradeOutlinedIcon;
                break;
        };
        return <Icon {...props}/>
    }

    const getProfessorPage = async(jsonPageUri, abortSignal) => {
        try{
            if(jsonPageUri){
                const res = await fetchPublicFileFromApi('professor', jsonPageUri, 0, abortSignal);
                const professorPage = res.data;
                if(!abortSignal.aborted){
                    setProfessorPage(professorPage);
                }
            }else{
                throw new Error('jsonPageUri must be a string')
            }
        }catch(error){
            if(!abortSignal.aborted){
                setProfessorPage(null)
            }
        }
    }

    useEffect(() => {
        if(isLead){
            const jsonPageUri = getFromSafeObject(professor, 'description.jsonPageUri');
            if(jsonPageUri){
                const abortController = new AbortController()
                getProfessorPage(jsonPageUri, abortController.signal);
                return(() => {
                    abortController.abort()
                })
            }else{
                setProfessorPage(null)
            }
        }
    },[isLead, professor])

    useEffect(() => {
        const publicId = getFromSafeObject(professor, 'imageDetails.main.publicId');
        if(publicId){
        const imgURL = assetProvider.getAssetUrl(publicId);
        setPortraitImg(imgURL)
        }else{
            setPortraitImg(null);
        }
    },[professor])
    
    return (
        isNotEmptyObject(professor) ? 
            <div className='professor-hero-wrapper'>
                <div className={`professor-hero-main-container${isLead ? '' : ' no-vertical-padding'}`} style={{...styles.mainContainer, ...style}}>
                    <ConditionalWrapper 
                        condition={!isLead}
                        wrapperFn={(children) => {
                            return(
                                <div className='professor-hero-main-container-no-lead-paper' style={noLeadPaperStyle}>
                                    {children}
                                </div>
                            )
                        }}
                    >
                        <div className={`professor-hero-info-container${isLead ? '' : ' no-lead'}`} style={styles.infoContainer}>
                            <div>
                                <Typography variant={matchMobile ? isLead ? "h5" : "h6" : isLead ? "h4" : "h5"} style={styles.fullName}>
                                    {professor.fullName}
                                </Typography>
                                <Typography variant="body2" style={styles.expertiseAreas}>
                                    {professor.description?.shortExpertiseAreas}
                                </Typography>
                            </div>
                            {isLead &&
                                <div>
                                    <Chip 
                                        style={styles.chip}
                                        variant='outlined' 
                                        size='small' 
                                        label={textTransform('capitalize', t('leadInstructor', {count: 1}))}
                                    />
                                </div>
                            }
                            <div>
                                <Typography variant="body1">
                                    {professor.description?.shortBio}
                                </Typography>
                            </div>
                            {professorPage && professorPage.achievementArray ?
                                <div>
                                        <List>
                                            {   
                                                professorPage.achievementArray.map((achievementObject, index) => {
                                                    const {
                                                        type,
                                                        achievement
                                                    } = achievementObject;
                                                    return(
                                                        type && achievement ?
                                                            <ListItem key={index}>
                                                                <ListItemIcon>
                                                                    {getAchievementIcon(type, {htmlColor: theme.palette.primary.main})}
                                                                </ListItemIcon>
                                                                <ListItemText primary={textTransform('title',achievement)}/>
                                                            </ListItem>
                                                            :
                                                            <></>
                                                    )
                                                })
                                            }
                                        </List>
                                </div>
                                :
                                null
                            }
                        </div>
                        <div 
                            className={imgContainerClassname ? imgContainerClassname : `professor-hero-img-container${isLead ? ' lead' : ' no-lead'}`} 
                            style={styles.imgContainer}
                        >
                            <ImgLazyLoad 
                                style={imgStyle}
                                className={`professor-hero-img${isLead ? ' lead' : ' no-lead'}`}
                                alt={'professor-cover-img'}
                                src={portraitImg}
                                threshold={imgLazyLoadThreshold}
                            />
                        </div>
                    </ConditionalWrapper>
                </div>
            </div>
            :
            null
    )
}

export default ProfessorHero