import { useEffect, useState } from 'react';
import BasicProductConsumptionOptionItem from '../BasicProductConsumptionOptionItem/BasicProductConsumptionOptionItem';
import { isEmptyObject, isNotEmptyObject, textTransform } from '../../aux/aux';
import './basicProductConsumptionOptionGroupItem.css'
import { MenuItem } from '@material-ui/core';

const BasicProductConsumptionOptionGroupItem = ({ 
    groupItemIndex, 
    productConsumptionType, 
    userProductPurchasabilityDetailsMap, 
    ConfigSelectorComponent, 
    ConfigSelectorItemComponent, 
    isSelected, 
    isSelectedProductConsumptionType, 
    isRecommended, 
    isUnique, 
    disabled,
    warningMessage,
    selectedItem, 
    onSelectedGroup, 
    onSelectedItem, 
    ConfigSelectorCollapsedItemComponent=null, 
    stylesMap=undefined, 
    ...props 
}) => {
    const [selectedProductConsumptionOptionIndex, setSelectedProductConsumptionOptionIndex] = useState(null);

    const handleOnSelectProductConsumptionIndexAndOption = (selectedIndex, selectedOption) => {
        handleOnSelectedProductConsumptionIndex(selectedIndex);
        handleOnSelectProductConsumptionOption(selectedOption);
    }

    const handleOnSelectedProductConsumptionIndex = (selectedIndex) => {
        setSelectedProductConsumptionOptionIndex(selectedIndex);
    }

    const handleOnSelectProductConsumptionOption = (selectedOption) => {
        if(typeof onSelectedItem === 'function'){
            onSelectedItem(selectedOption)
        }
    }

    const handleOnSelectGroup = (productOption) => {
        onSelectedGroup(groupItemIndex);
    }

    useEffect(() => {
        if(selectedProductConsumptionOptionIndex === null){
            let selectedOptionIndex = null;
            if(isSelectedProductConsumptionType && isNotEmptyObject(selectedItem)){
                const index = productConsumptionType.productConsumptionOptionArray.findIndex(productOption => productOption.id === selectedItem.id);
                if(index >= 0){
                    selectedOptionIndex = index;
                }
            }else if(productConsumptionType && productConsumptionType.productConsumptionOptionArray.length > 0){
                selectedOptionIndex = productConsumptionType.productConsumptionOptionDefaultIndex || 0
            }
            handleOnSelectedProductConsumptionIndex(selectedOptionIndex);
            
        }
    },[isSelected, selectedItem, selectedProductConsumptionOptionIndex])
    
    useEffect(() => {
        if(isSelectedProductConsumptionType){
            if(isSelected){
                if(disabled){
                    handleOnSelectProductConsumptionOption(null);
                }else if(selectedProductConsumptionOptionIndex !== null){   
                    const selectedOption = productConsumptionType.productConsumptionOptionArray[selectedProductConsumptionOptionIndex];
                    handleOnSelectProductConsumptionOption(selectedOption);
                }
            }
        }   
    },[isSelected, selectedItem, selectedProductConsumptionOptionIndex])

    return (
        selectedProductConsumptionOptionIndex != null ?
            <BasicProductConsumptionOptionItem
                index={groupItemIndex}
                productConsumptionOption={productConsumptionType.productConsumptionOptionArray[selectedProductConsumptionOptionIndex]}
                isSelected={isSelected}
                isRecommended={isRecommended}
                isUnique={isUnique}
                disabled={disabled}
                onSelected={handleOnSelectGroup}
                warningMessage={warningMessage}
                stylesMap={stylesMap}
                ConfigSelectorComponent={
                    <ConfigSelectorComponent 
                        itemArray={productConsumptionType.productConsumptionOptionArray}
                        defaultItemIndex={selectedProductConsumptionOptionIndex}
                        onSelectItem={handleOnSelectProductConsumptionIndexAndOption} 
                        disabled={disabled}
                        selectedItemIndex={selectedProductConsumptionOptionIndex}
                        CollapsedItemComponent={ConfigSelectorCollapsedItemComponent}
                    >
                        {productConsumptionType.productConsumptionOptionArray.map((productConsumptionOption, index) => {
                            const userProductPurchasabilityDetails = userProductPurchasabilityDetailsMap[productConsumptionOption.id];
                            const isBestPrice = productConsumptionType.productConsumptionOptionBestPriceIndex === index;
                            const isDefault = productConsumptionType.productConsumptionOptionDefaultIndex === index;
                            const isUnique = productConsumptionType.productConsumptionOptionArray.length === 1;
                            const isDisabled = !userProductPurchasabilityDetails.isPurchasable;
                            // const isSelected = productConsumptionOption.id === selectedItem.id;
                            return(
                                <MenuItem key={index} value={index} disabled={isDisabled} >
                                    <ConfigSelectorItemComponent
                                        productConsumptionOption={productConsumptionOption} 
                                        userProductPurchasabilityDetails={userProductPurchasabilityDetails}
                                        isBestPrice={isBestPrice}
                                        isDefault={isDefault} 
                                        isUnique={isUnique}
                                        disabled={isDisabled}
                                    />
                                </MenuItem>
                            )
                        })}
                    </ConfigSelectorComponent> 
                }
            >
                {props.children}
            </BasicProductConsumptionOptionItem>
            :
            null
    )
}

export default BasicProductConsumptionOptionGroupItem