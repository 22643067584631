import { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { Button, Grid, Box, Typography, IconButton } from "@material-ui/core"
import { useTheme } from "@material-ui/styles"
import { TextField, MenuItem } from '@mui/material'

import ButtonLoading from "../ButtonLoading/ButtonLoading";

import ImgDN from "../../cdn/imgDN";
import cloudinary from "../../cdn/providers/cloudinary";

// Img and graphics
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AccountIcon from '../../img/account-icon.svg';
import { AddAPhoto,  } from "@material-ui/icons";
import DeleteIcon from '@mui/icons-material/Delete';
// Style
import './userBasicDataMenu.css'

import { isEmptyObject, isNotEmptyObject, textTransform } from '../../aux/aux'
import { getProfessions } from "../../services/staticContentServices";
import ToggleMsg from "../ToggleMsg/ToggleMsg";
import { sha1Signature } from "../../aux/cryptoHelpers";
import { useCallback } from "react";
import UploadImgWidget from "../UploadImgWidget/UploadImgWidget";
import PopUpModal from "../PopUpModal/PopUpModal";
import PopUpModalHeader from "../PopUpModalHeader/PopUpModalHeader";
import { WarningAmber } from "@mui/icons-material";
import SimpleNoModalDialog from "../SimpleNoModalDialog/SimpleNoModalDialog";
import { updateUserBasicData } from "../../services/userServices";
import { useLocaleCtx } from "../../customHooks/useLocaleCtx";


const UserBasicDataMenu = ({userProfile, onUpdateUserBasicData, onUpdateStatus}) => {
    const POP_UP_WIDTH = 380;
    const PROFILE_IMG_SIZE = 100;
    const assetProvider = new ImgDN(cloudinary);
    const userProfileMenuStatusTypes = ['idle', 'loading'];
    const localeCtx = useLocaleCtx();
    const { t } = useTranslation('userProfile', { keyPrefix:'userBasicDataMenu' });
    const [professionArray, setProfessionArray] = useState([]);
    const [userProfileMenuStatus, setUserProfileMenuStatus] = useState(userProfileMenuStatusTypes[0]);
    const [updateUserState, setUpdateUserState] = useState({});
    const [requestingProfessionsError, setRequestingProfessionsError] = useState(false);
    const [uploadingProfileError, setUploadingProfileError] = useState(false);
    const [isUploadingProfileImg, setIsUploadingProfileImg] = useState(false);
    const [isUploadingProfile, setIsUploadingProfile] = useState(false);
    const [isDeletingProfileImgError, setIsDeletingProfileImgError] = useState(false);
    const [isDeletingProfileImg, setIsDeletingProfileImg] = useState(false);
    const [profileImgToUpload, setProfileImgToUpload] = useState(null);
    const [showUploadImgWidget, setShowUploadImgWidget] = useState(false);
    const [showDeleteProfileImgWarning, setShowDeleteProfileImgWarning] = useState(false);
    const abortControllerRef = useRef(null);
    const fileInputRef = useRef(null);
    
    const styles = {
        icon: {
            display: 'block',
            fontSize: '1.2em', // theme.typography.body1.fontSize,
        },
        img: {
            width: `${PROFILE_IMG_SIZE}px`,
            height: `${PROFILE_IMG_SIZE}px`,
        },
        groupTitle: {
            fontWeight: 'bold'
        }
    }

    const handleOnChange = (e) => {
        e.preventDefault();
        const field = e.target.name;
        const value = e.target.value;
        setUpdateUserState(prev => ({...prev, [field]: value}));
    }

    const createProfileImgId = useCallback((userId=userProfile.id) => {
        return sha1Signature(`${userId}${process.env.REACT_APP_USER_IMG_PROFILE_ID_SECRET}`)
    },[userProfile.id])

    const extractProfileImgId = useCallback((imgURL=userProfile.profileImgURL) => {
        const IMG_PROFILE_HEX_ID_REGEX = /.*\/([0-9,a,b,c,d,e,f]+)(\?.*)?$/;
        return IMG_PROFILE_HEX_ID_REGEX.exec(imgURL)[1];
    },[userProfile.profileImgURL])

    const getProfileImgId = (imgURL) => {
        let result;
        if(imgURL){
            result = extractProfileImgId();
        }else{
            result = createProfileImgId();
        }
        return result
    }

    const handleOnDeleteProfileImg = async (e, signal=abortControllerRef.current.signal) => {
        try{
            e.preventDefault();
            const profileImgURL = userProfile.profileImgURL;
            const updatedValues = { profileImgURL: null};
            await updateUserBasicData(userProfile.id, updatedValues, 0, signal);
            try{
                await assetProvider.remove(profileImgURL, signal);
            }catch(error){
                // img was not removed from the CND but is not
                // assigned to the user anymore
            }
            if(!signal.aborted){
                onUpdateUserBasicData(updatedValues)
                setShowDeleteProfileImgWarning(false);
            }
        }catch(error){
            setIsDeletingProfileImgError(true);
        }
    }

    const handleOnUploadProfileImg = async (data, signal) => {
        try{
            const {
                public_id,
                secure_url,
                version,
                signature
            } = data;
            const updatedValues = { 'profileImgURL': `v${version}/${public_id}` }
            await updateUserBasicData( userProfile.id, updatedValues, 0, signal);
            if(!signal.aborted){
                onUpdateUserBasicData(updatedValues)
                onCloseUploadImgWidget();
            }
        }catch(err){
            throw err
        }
    }

    const handleOnUpdateBasicData = async (e, signal=abortControllerRef.current.signal) => {
        try{
            e.preventDefault();
            setUserProfileMenuStatus(userProfileMenuStatusTypes[1])
            setIsUploadingProfile(true);
            await updateUserBasicData(userProfile.id, updateUserState, 0, signal);
            if(!signal.aborted){
                onUpdateUserBasicData(updateUserState);
                setUpdateUserState({});
            }
        }catch(err){
            if(!signal.aborted){
                setUploadingProfileError(true)
            }
        }finally{
            if(!signal.aborted){
                setIsUploadingProfile(false);
                setUserProfileMenuStatus(userProfileMenuStatusTypes[0])
            }
        }
    }
    
    const onCloseUploadImgWidget = () => {
        setIsUploadingProfileImg(false);
        setShowUploadImgWidget(false);
        setProfileImgToUpload(null);

    }
    const handleOnSelectedProfileImg = (e) => {
        const file = e.target.files[0];
        setIsUploadingProfileImg(true);
        setProfileImgToUpload(file)
        setShowUploadImgWidget(true);
    }

    const requestProfessions = async(signal) => {
        try{
            setRequestingProfessionsError(prev => false);
            const res = await getProfessions(localeCtx.localeId, 0, signal)
            let {
                professionArray
            } = res.data;
            if(!signal.aborted){
                professionArray = professionArray.sort((a, b) => {
                    let sortValue = 0;
                    if(a.name === 'other'){
                        sortValue = 1
                    }else{
                        if(b.name === 'other'){
                            sortValue = -1
                        }
                    }
                    return sortValue
                })
                setProfessionArray(professionArray);
            }
        }catch(error){
            if(!signal.aborted){
                setProfessionArray([])
                setRequestingProfessionsError(prev => true);
            }
        }
    }

    const getValue = ( property ) => {
        const editedValue = updateUserState.hasOwnProperty(property);
        const value = editedValue ? updateUserState[property] : userProfile[property];
        return value
    }

    const getImgProfileComponent = (profileImgId) => {
        let imgProfileComponent;
        const profileImgURL = assetProvider.getAssetUrl(profileImgId);
        if(profileImgURL){
            imgProfileComponent = <img className="user-basic-data-menu-img" alt="profile-img" src={profileImgURL} style={styles.img}/>
        }else{
            imgProfileComponent =  <AccountIcon height={PROFILE_IMG_SIZE} />
        }
        return imgProfileComponent
    }

    useEffect(() => {
        onUpdateStatus('profile', isNotEmptyObject(updateUserState))
    },[updateUserState])

    useEffect(() => {
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        requestProfessions(abortController.signal);
        return(() => {
            abortController.abort()
        })
    }, [localeCtx])

    return (
        <>
            <PopUpModal 
                showModal={showUploadImgWidget} 
                isDialog={true} 
                width={POP_UP_WIDTH}
            >
                <PopUpModalHeader title={t("updateProfileImage")} Icon={FileUploadIcon} />
                <UploadImgWidget 
                    className='user-basic-data-menu-img-upload-img-widget-container'
                    imgFile={profileImgToUpload} 
                    imgId={getProfileImgId(userProfile.profileImgURL)} 
                    imgType={'profile'} 
                    onUpload={handleOnUploadProfileImg} 
                    onCancel={onCloseUploadImgWidget}
                />
            </PopUpModal>
            <PopUpModal
                showModal={showDeleteProfileImgWarning}
                isDialog={true}
                width={POP_UP_WIDTH}
            > 
                <PopUpModalHeader title={t("deleteProfileImage")} Icon={WarningAmber} />
                <SimpleNoModalDialog
                    style={{padding:'20px'}}
                    contentText={
                        `${textTransform('title', t("youAreGoingToDeleteYourProfileImage"))}.`
                        +`\n${textTransform('title', t("areYouSure"))}`
                    }
                    onDisagree={() => setShowDeleteProfileImgWarning(false)}
                    onAgree={handleOnDeleteProfileImg}
                    isError={isDeletingProfileImgError}
                    errorMsg={
                        `${textTransform('title', t("imgCannotBeDeleteNow"))}`
                        +`\n${textTransform('title', t("tryItLater"))}`
                    }
                />
            </PopUpModal>
            <div className="user-basic-data-menu-main-container">
                <Typography variant="h5" className="user-basic-data-menu-main-title">
                    {textTransform('capitalize', t("profile"))}
                </Typography>
                <form onSubmit={handleOnUpdateBasicData}>
                    <div className="user-basic-data-menu-content-container">
                        <div className="user-basic-data-menu-img-group-container">
                            <div className="user-basic-data-menu-img-container">
                                {getImgProfileComponent(userProfile.profileImgURL)}
                            </div>
                            <div className="user-basic-data-menu-img-group-icon-container">
                                <IconButton
                                    size='small'
                                    htmlFor='file_input' 
                                    component="label" 
                                    aria-label="choose-profile-image-bttn"
                                    color="primary"
                                    disabled={userProfileMenuStatus === userProfileMenuStatusTypes[1] || isUploadingProfile}
                                >
                                    <AddAPhoto style={styles.icon}/>
                                </IconButton>
                                <input
                                    ref={fileInputRef}
                                    id='file_input'
                                    type="file"
                                    accept="image/png, image/jpeg"
                                    name='file'
                                    onClick={() => fileInputRef.current.value = ''}
                                    onChange={handleOnSelectedProfileImg}
                                    style={{display: 'none'}}
                                />
                                <IconButton
                                    size='small'
                                    aria-label="delete-profile-image-bttn"
                                    onClick={() => setShowDeleteProfileImgWarning(true)} 
                                    color="secondary"
                                    disabled={userProfile.profileImgURL == null || userProfileMenuStatus === userProfileMenuStatusTypes[1] || isDeletingProfileImg}
                                >
                                    <DeleteIcon style={styles.icon}/>
                                </IconButton>
                            </div>
                        </div>
                        <div className="user-basic-data-menu-name-group-container">
                            <TextField 
                                required  
                                variant="standard" 
                                name="firstName" 
                                label={textTransform('title', t("name"))} 
                                value={getValue('firstName')}
                                onChange={ handleOnChange }
                                disabled={userProfileMenuStatus === userProfileMenuStatusTypes[1]} 
                                inputProps={{style:{textOverflow:'ellipsis'}}}
                                inputMode="text"
                                fullWidth
                            />
                            <TextField 
                                variant="standard" 
                                name="lastName" 
                                label={textTransform('title', t("lastName"))} 
                                value={getValue('lastName')}
                                onChange={ handleOnChange }
                                disabled={userProfileMenuStatus === userProfileMenuStatusTypes[1]}
                                inputProps={{style:{textOverflow:'ellipsis'}}}
                                inputMode="text"
                                fullWidth 
                            />
                        </div>
                        <div className="user-basic-data-menu-specialization-group-container">                
                            <Typography style={styles.groupTitle}>
                                {textTransform('capitalize', t("mySpeciality"))}
                            </Typography>
                            <TextField 
                                name="professionName" 
                                // label={textTransform('title', userProfileMenuTxt.especiality)}
                                value={getValue('professionName') || ''} 
                                onChange={ handleOnChange }
                                select
                                disabled={requestingProfessionsError || userProfileMenuStatus === userProfileMenuStatusTypes[1]} 
                                variant='standard' 
                                fullWidth
                            >
                                {professionArray.map((specialization) => {
                                return(
                                    <MenuItem  key={specialization.name} value={specialization.name}>
                                        {textTransform('title', specialization.description.nameTranslated || specialization.name)}
                                    </MenuItem>
                                )
                                })}
                                { professionArray.length == 0 && userProfile.professionName != null &&
                                    <MenuItem key={getValue('professionName')} value={getValue('professionName')}>
                                        {textTransform('title', userProfile.details.profession.description.nameTranslated)}
                                    </MenuItem>
                                }
                                <MenuItem key={'none'} value={''}>
                                    {textTransform('title', t("common:none"))}
                                </MenuItem>
                            </TextField>
                            <ToggleMsg 
                                type="warning"
                                isShown={requestingProfessionsError}
                                msg={
                                    `${textTransform('title', t("cannotUpdateYourSpecialityNow"))}`
                                    +`. ${textTransform('title', t("tryItLater"))}`
                                }
                            />
                        </div>
                        <div className="user-basic-data-menu-save-button-container-group">
                            <ButtonLoading 
                                isLoading={isUploadingProfile} 
                                label={t("save")} 
                                type="submit" 
                                variant="outlined" 
                                color="primary" 
                                disabled= {isEmptyObject(updateUserState) || userProfileMenuStatus === userProfileMenuStatusTypes[1]}
                                fullWidth
                            />
                            <ToggleMsg
                                isShown={uploadingProfileError} 
                                onShowTime={() => {
                                    setUploadingProfileError(false)
                                }}
                                msg={
                                    `${textTransform('title', t("uploadingProfileError"))}. ${textTransform('title', t("tryItLater"))}`
                                } 
                            />
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default UserBasicDataMenu
