// Componets
import { Breadcrumbs, Typography, useMediaQuery } from '@material-ui/core';
// Style
import { getFromSafeObject, textTruncate } from '../../aux/aux';
import './classContentBreadcrumb.css'
import ShowMoreStringPopOver from '../ShowMoreStringPopOver/ShowMoreStringPopOver';
import CustomPopover from '../CustomPopover/CustomPopover';
import { useRef, useState } from 'react';
import { Component } from 'react-image-crop';
import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper';

const ClassContentBreadcrumb = ({ contentSummary, contentStructure, contentSelected }) => {
    const MAX_CHAR_LESSON_MOBILE = 24
    const SEPARATOR = '>';
    const matchDesktop = useMediaQuery('(min-width:1120px)')
    const matchMobile = useMediaQuery('(max-width:680px)');
    const [showPopOver, setShowPopOver] = useState(false);
    const breadcrumRef = useRef(null);
    const getBreadCrumbs = (contentName, currentContent, contentStructure)=>{
        const crumbs = [];
        let isOverview = currentContent.currentSectionId === 0 && currentContent.currentLessonId === 0
        if (!isOverview){
            const sectionName = contentStructure.sectionSummaryMap[currentContent.currentSectionId].description.title;
            const lessonName = contentStructure.lessonSummaryMap[currentContent.currentLessonId][currentContent.currentLessonVersionId].description.title;
            if(matchDesktop){
                crumbs.push(contentName, sectionName, lessonName);
            }else{
                crumbs.push([contentName,sectionName,lessonName].join(`  ${SEPARATOR}  `))
                if(!matchMobile){
                    crumbs.push(sectionName)
                }
                crumbs.push(lessonName)
            }
        }else{
            crumbs.push(contentName);
        }
        return crumbs.map((crumb, index) =>{
            let Component;
            if(!isOverview && !matchDesktop && index == 0){
                Component = 
                    <ShowMoreStringPopOver 
                        elemArray={[crumb]} 
                        mapFunction={(elem) => (<Typography variant='body2'>{elem}</Typography>)} 
                        label='...'
                        anchorOrigin={{vertical:'bottom', horizontal:'center'}}
                        transformOrigin={{vertical:'top', horizontal:'center'}}
                    />
            }else{
                Component = <Typography variant='body2' className='class-content-breadcrumb-crumb' key={index}>{crumb}</Typography>
            }
            return Component
        })
    }

    return (
            // {matchDesktop ?
            <div className='class-content-breadcrumb-main-container'>
                <Breadcrumbs separator={SEPARATOR} arial-label="breadcrumbs" >
                    {getBreadCrumbs(getFromSafeObject(contentSummary,'description.nameTranslated'), contentSelected, contentStructure)}
                </Breadcrumbs>
            </div>
    )
}

export default ClassContentBreadcrumb