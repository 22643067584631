import { useTheme } from '@material-ui/styles'
import { Button, Chip, Typography, hexToRgb, useMediaQuery } from '@material-ui/core';
import { concatenatedArrayElemPropString, contentLocaleArrayToString } from '../../aux/contentProductHelpers';
import { getFromSafeObject, getSafeDateOrUndefined, isNotEmptyObject, rgbToRgbaOrUndefined, textTransform } from '../../aux/aux';
import ClosedCaptionOffIcon from '@mui/icons-material/ClosedCaptionOff';
import LanguageOutlined from '@mui/icons-material/LanguageOutlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SocialProofWidget from '../SocialProofWidget/SocialProofWidget';
import { useRef, useState } from 'react';
import CustomPopover from '../CustomPopover/CustomPopover';
import ShowMoreStringPopOver from '../ShowMoreStringPopOver/ShowMoreStringPopOver';
import { useTranslation } from 'react-i18next';
import PopUpContactSupport from '../PopUpContactSupport /PopUpContactSupport';
import AvailUnitWidget from '../AvailUnitWidget/AvailUnitWidget';
import ConsumableDateWidget from '../ConsumableDateWidget/ConsumableDateWidget';
import InfoLabelPopOver from '../InfoLabelPopOver/InfoLabelPopOver';
import './contentHero.css'
import ConsumableDateChip from '../ConsumableDateChip/ConsumableDateChip';
import CustomChip from '../CustomChip/CustomChip';
import ConsumabilityStatusChip from '../ConsumabilityStatusChip/ConsumabilityStatusChip';
import ContentTypeChip from '../ContentTypeChip/ContentTypeChip';
import SubscriptionIcon from '../SubscriptionIcon/SubscriptionIcon';
import ButtonLoading from '../ButtonLoading/ButtonLoading';
import FavoriteButton from '../FavoriteButton/FavoriteButton';

const DEFAULT_CONTENT_RELEVANT_INFO = {
    contentTypeName:undefined,
    contentTypeNameTranslated:undefined,
    nameTranslated:undefined,
    professorArray:[],
    localeArray:[],
    captionsLocaleArray:[],
    totalNumInscribed:undefined,
    totalNumCompleted:undefined,
}
const DEFAULT_PRODUCT_CONSUMPTION_DETAILS = {
    isScarce:undefined,
    isConsumable:undefined,
    isPresale:undefined,
    details:{
        availUnits:undefined
    }
}
const DEFAULT_CONTENT_CATCH = {
    catch:undefined,
    catchDescription:undefined
}
const DEFAULT_CONTENT_USERS = {
    userProfileImgIdAndNameArray:[],
    numDisplayedUsers:undefined,
}
const DEFAULT_BUTTON_PROPS = {
    label:undefined, 
    onClick:null, 
    isLoading:false,
    disabled:false,
}
const ContentHero = ({ 
    contentInfo=DEFAULT_CONTENT_RELEVANT_INFO, 
    productConsumptionStatusDetails=DEFAULT_PRODUCT_CONSUMPTION_DETAILS, 
    catchTxt=DEFAULT_CONTENT_CATCH, 
    contentUsers=DEFAULT_CONTENT_USERS, 
    config={}, 
    showPrimaryButton=true,
    showSecondaryButton=true,
    primaryButtonProps=DEFAULT_BUTTON_PROPS, 
    secondaryButtonProps=DEFAULT_BUTTON_PROPS
}) => {
    const {
        maxAuthorToShow= 3,
        maxLocaleToShow=2,
        maxProfileToShow=3, 
        minInscribedUserToShowValue=10,
    } = config;   
    const { t } = useTranslation('common');
    const theme = useTheme();
    const matchMobile = useMediaQuery('(max-width:680px)');
    const [isPrimaryLoading, setIsPrimaryLoading] = useState(false);
    const styles = {
        wrapper: {
            // backgroundColor: theme.palette.primary.lighter
        },
        title:{
            fontWeight: 'bold',
            color: theme.palette.primary.main,
        },
        author:{
            color: theme.palette.grey[400],
            display: 'inline'
        },
        infoLabel:{
            color: theme.palette.text.primary
        },
        catch:{
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            whiteSpace: 'pre-line',
            textAlign: 'center'
        },
        catchDescription:{
            whiteSpace: 'pre-line',
            textAlign: 'center'
        },
        chip:{
            course:{
                color: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
                backgroundColor: rgbToRgbaOrUndefined(hexToRgb(theme.palette.primary.main), 0.1),
            },
            protocol:{
                color: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
                backgroundColor: rgbToRgbaOrUndefined(hexToRgb(theme.palette.primary.main), 0.1),
            }

        },
        icon:{
            display: 'block',
            color: theme.palette.primary.main,
            fontSize: theme.typography.h6.fontSize
        },
        popOverContainer:{
            maxHeight: `calc(${theme.typography.body2.lineHeight}rem * 10)`,
            overflowY: 'auto',
        }
    }
    
    return (
        isNotEmptyObject(contentInfo) && isNotEmptyObject(catchTxt) ?
            <div className='content-hero-wrapper' style={styles.wrapper}>
                <div className='content-hero-main-container'>
                    <div className='content-hero-title-and-author-container'>
                        <div className='content-hero-chips-container'>
                            <ConsumableDateChip
                                hasConsumableDate={productConsumptionStatusDetails.details.hasConsumableDate}
                                consumableDateStatus={productConsumptionStatusDetails.details.consumableDateStatus}
                                releaseDate={getSafeDateOrUndefined(productConsumptionStatusDetails.details.releaseDate)}
                            />
                            <ContentTypeChip
                                contentTypeName={contentInfo.contentTypeName}
                                contentTypeNameTranslated={contentInfo.contentTypeNameTranslated}
                            />
                            <ConsumabilityStatusChip
                                consumabilityStatus={productConsumptionStatusDetails.details.consumabilityStatus}
                            />
                        </div>
                        <Typography
                            variant='body1'
                            style={styles.title}
                        >
                            {contentInfo.nameTranslated}
                        </Typography>
                        {contentInfo.professorArray?.length > 0  ?
                            <div className='content-hero-author-container'>
                                <Typography 
                                    variant={!matchMobile ? 'body1' : 'body2'} 
                                    style={styles.author}
                                >
                                    {textTransform('capitalize', concatenatedArrayElemPropString(contentInfo.professorArray, '.fullName', maxAuthorToShow))}
                                </Typography>
                                <ShowMoreStringPopOver 
                                    elemArray={contentInfo.professorArray.slice(maxAuthorToShow)}
                                    label={`, ${contentInfo.professorArray.length - maxAuthorToShow} ${t('more')}`}
                                    style={styles.author}
                                    innerStyles={{ popOverContainer: styles.popOverContainer }}
                                    mapFunction={(professor, index) => {
                                        return(
                                            <Typography key={index} variant='body2' className='content-hero-more-string-pop-over-elem'>
                                                {textTransform('title', professor.fullName)}
                                            </Typography>
                                        )}
                                    }
                                />
                            </div>
                            :
                            null
                        }
                    </div>
                    <div className='content-hero-description-container'>
                        {catchTxt.catch ? 
                            <Typography
                                variant={!matchMobile ? 'h3' : 'h5'}
                                style={styles.catch}
                            >
                                {catchTxt.catch}
                            </Typography>
                            :
                            null
                        }
                        {catchTxt.catchDescription ?
                            <Typography
                                variant='body1'
                                style={styles.catchDescription}
                            >
                                {catchTxt.catchDescription}
                            </Typography>
                            :
                            null
                        }
                    </div>
                    <div className='content-hero-button-container'>
                        {showPrimaryButton && primaryButtonProps && primaryButtonProps.label && typeof primaryButtonProps.onClick === 'function' ?
                            <ButtonLoading
                                variant="contained"
                                color="primary"
                                size='large'
                                {...primaryButtonProps}
                            />
                            :
                            null
                        }
                        {showSecondaryButton && secondaryButtonProps && secondaryButtonProps.label && typeof secondaryButtonProps.onClick === 'function' ?
                            <ButtonLoading
                                variant="outlined"
                                color="primary"
                                size='large'
                                {...secondaryButtonProps}
                            />
                            :
                            null
                        }
                    </div>
                    {productConsumptionStatusDetails.isConsumable || productConsumptionStatusDetails.details.consumabilityStatus === 'noUnitsLeft' ? 
                        <div className='content-hero-social-container'>
                            <SocialProofWidget 
                                numDisplayedUsers={maxProfileToShow}
                                userProfileImgIdAndNameArray={contentUsers?.userProfileImgIdAndNameArray}
                                ProfileIcon={null}
                            >
                                {productConsumptionStatusDetails.isScarce ? 
                                        <AvailUnitWidget
                                            variant={"body2"}
                                            unitType={t('common:place', {count: productConsumptionStatusDetails.details.availUnits})} 
                                            count={productConsumptionStatusDetails.details.availUnits}
                                            symbolAtEnd={true}
                                            fullMessage
                                        />
                                        :
                                        <Typography variant='body2'>
                                            {contentInfo.totalNumInscribed > minInscribedUserToShowValue ?
                                                t("inscribedColleage", { count: contentInfo.totalNumInscribed })
                                                :
                                                `${textTransform('title',t("joinYourColleages"))} ${t("symbolFire")}`
                                            }
                                        </Typography>
                                }
                            </SocialProofWidget>
                        </div>
                        :
                        null
                    }
                    <div className='content-hero-features-container'>
                        {productConsumptionStatusDetails.isPresale ?
                            <div className='content-hero-feature'>
                                <CalendarTodayIcon style={styles.icon}/>
                                <div className='content-hero-feature-values-container'>
                                    <ConsumableDateWidget 
                                        consumableDateStatus={productConsumptionStatusDetails.details.consumableDateStatus}
                                        hasConsumableDate={productConsumptionStatusDetails.details.hasConsumableDate}
                                        releaseDate={getSafeDateOrUndefined(productConsumptionStatusDetails.details.releaseDate)}
                                        dateProps={{
                                            variant:"body2",
                                            style:styles.consumableDate
                                        }}
                                        labelProps={{
                                            variant:"body2",
                                            style:styles.consumableDate
                                        }}
                                    />
                                </div>
                            </div>
                            :
                            null
                        }
                        {isNotEmptyObject(productConsumptionStatusDetails.details.subscriptability) ? 
                            <div className='content-hero-feature'>
                                <SubscriptionIcon style={styles.icon} />
                                <div className='content-hero-feature-values-container'>
                                    <InfoLabelPopOver 
                                        label={t('includeInSubscriptionProduct', {subscriptionProductName: productConsumptionStatusDetails.details.subscriptability.minimumSubscriptionType.name})}
                                        labelPopOverMsg={t("includeInAllSubscriptionOrHigher", {subscriptionProductName: productConsumptionStatusDetails.details.subscriptability.minimumSubscriptionType.name})}
                                        variant={'body2'}
                                        labelStyle={styles.infoLabel}
                                        infoIconStyle={styles.infoLabel}
                                    />
                                </div>
                            </div>
                            :
                            null
                        }
                        {contentInfo.localeArray?.length > 0 ?
                            <div className='content-hero-feature'>
                                <LanguageOutlined style={styles.icon}/>
                                <div className='content-hero-feature-values-container'>
                                    <Typography
                                        variant='body2' 
                                    >
                                        {textTransform('capitalize', contentLocaleArrayToString(contentInfo.localeArray, maxLocaleToShow, 'details.locale.details.language.description.name'))}
                                    </Typography>
                                    <ShowMoreStringPopOver 
                                        elemArray={contentInfo.localeArray.slice(maxLocaleToShow)}
                                        label={`, ${contentInfo.localeArray.length - maxLocaleToShow} ${t('more')}`}
                                        innerStyles={{ popOverContainer: styles.popOverContainer }}
                                        mapFunction={(locale, index) => {
                                            const localeName = getFromSafeObject(locale, 'details.locale.details.language.description.name');
                                            return(
                                                localeName ? 
                                                    <Typography key={index} variant='body2' className='content-hero-more-string-pop-over-elem'>
                                                        {textTransform('title', localeName)}
                                                    </Typography>
                                                    :
                                                    null
                                            )}
                                        }
                                    />
                                </div>
                            </div>
                            :
                            null
                        }
                        {contentInfo.captionsLocaleArray?.length > 0 ?
                            <div className='content-hero-feature'>
                                <ClosedCaptionOffIcon style={styles.icon}/>
                                <div className='content-hero-feature-values-container'>
                                    <Typography
                                        variant='body2' 
                                    >
                                        {textTransform('capitalize', contentLocaleArrayToString(contentInfo.captionsLocaleArray, maxLocaleToShow, 'details.locale.details.language.description.name'))}
                                    </Typography>
                                    <ShowMoreStringPopOver 
                                        elemArray={contentInfo.captionsLocaleArray.slice(maxLocaleToShow)}
                                        label={`, ${contentInfo.captionsLocaleArray.length - maxLocaleToShow} ${t('more')}`}
                                        innerStyles={{ popOverContainer: styles.popOverContainer }}
                                        mapFunction={(locale, index) => {
                                            const localeName = getFromSafeObject(locale, 'details.locale.details.language.description.name');
                                            return(
                                                localeName ?
                                                    <Typography key={index} variant='body2' className='content-hero-more-string-pop-over-elem'>
                                                        {textTransform('title', localeName)}
                                                    </Typography>
                                                    :
                                                    null
                                            )}
                                        }
                                    />
                                </div>   
                            </div>
                            :
                            null
                        }
                    </div>               
                </div>
            </div>
        :
            null
    )
}

export default ContentHero