import { useRef, useEffect,  } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography, Button } from '@material-ui/core'

// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import congrats from '../../lotties/congrats.json';

import PopUpModal from '../PopUpModal/PopUpModal';

import './classCongratsModal.css'
import { textTransform } from '../../aux/aux';

const ClassCongratsModal = ({ showModal, setShowModal, contentNavMoves, title }) => {
    const POP_UP_WIDTH = 380;
    const { t } = useTranslation('common', { keyPrefix: 'classCongratsModal' });
    const animationContainer = useRef(null);
    const animation = useRef(null);
    const styles = {
        title: {
            fontWeight: 'bold'
        },
    }

    useEffect(() => {
        if(animationContainer.current){
            animation.current = lottie.loadAnimation({
                container: animationContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: false,
                animationData: congrats
            });
            return(() => {animation.current?.destroy()})
        }
    },[])

    
    return (
        <PopUpModal showModal={showModal} onClickClose={(e) =>{setShowModal(false); animation.current?.stop()}} width={`${POP_UP_WIDTH}px`}>
            <Grid className='class-content-modal-inner' container direction='column'>
                <Grid item>
                    <Typography variant="h5" style={styles.title}>
                        {textTransform('title', t('common:congrats'))} 
                    </Typography>
                </Grid>
                <Grid item >
                    <div className='class-content-modal-animation' ref={animationContainer}>
                        {showModal && animation.current?.play()}
                    </div>
                </Grid>
                <Grid item align='center'>
                    <Typography variant="body1">
                        {`${textTransform('title', t("youFinishedThisLesson"))}: `}
                    </Typography>
                    <Typography variant="body2" style={styles.title}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button color="primary" variant="outlined" onClick={(e) => {setShowModal(false); contentNavMoves.nextElement(); animation.current?.stop()}}>
                        {`${textTransform('title', t('common:next'))} ${t('common:lesson',{ count: 1})}`}
                    </Button>
                </Grid>
            </Grid>
        </PopUpModal>
    )
}

export default ClassCongratsModal
