import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import './cookieConsentCustom.css';

const CookieConsentCustom = ({ debug=false, showInMs=2000, onAccept=()=>{}, onDecline=()=>{}}) => {
    const COOKIE_NAME = 'qxi_cookieConsent';
    const theme = useTheme()
    const { t } = useTranslation('common', { keyPrefix: 'cookieConsentCustom'});
    const [isShown, setIsShown] = useState(false);
    const styles = {
        wrapper:{
            background: theme.palette.primary.dark,
        },
        container:{
            // background: theme.palette.primary.dark
        },
        content:{
            color: theme.palette.primary.contrastText
        },
        button:{
            background: theme.palette.primary.contrastText,
        },
        declineButton:{
            background: theme.palette.primary.contrastText
        }
    }
    useEffect(() => {
        if(Boolean(getCookieConsentValue(COOKIE_NAME)) !== true || debug){
            const timeoutId = setTimeout( () => setIsShown(true), showInMs);
            return(() => {
                clearTimeout(timeoutId);
            })
        }else{
            onAccept(false);
        }
    },[])
    return (

        <div className={`cookie-consent-custom-wrapper ${isShown ? 'show' : ''}`} style={styles.wrapper}>
            <CookieConsent
                debug={debug}
                cookieName={COOKIE_NAME}
                onAccept={(acceptedByScrolling) => {onAccept(acceptedByScrolling); setIsShown(false)}}
                onDecline={onDecline}
                ButtonComponent={Button}
                customButtonProps={{ color:'primary', variant:'outlined', size:'small' }}
                buttonStyle={styles.button}
                buttonClasses='cookie-consent-custom-button'
                buttonText={t('iUnderstand')}
                enableDeclineButton={false}
                customDeclineButtonProps={{ color:'primary', variant:'outlined' }}
                declineButtonStyle={styles.declineButton}
                declineButtonClasses='cookie-consent-custom-decline-button'
                declineButtonText={t("onlyNeccessaryCookies")}
                disableStyles={true}
                containerClasses='cookie-consent-custom-main-container'
                customContainerAttributes={{ style: styles.container}}
                contentClasses='cookie-consent-custom-content-container'
            >
                <Typography variant='body2' style={styles.content}>
                    {t("basicConsent")}
                </Typography>
            </CookieConsent>
        </div>
    )
}

export default CookieConsentCustom