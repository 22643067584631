import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, hexToRgb, IconButton, Typography, Chip } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import ButtonLoading from '../ButtonLoading/ButtonLoading';
import { isNotEmptyObject,isEmptyObject, textTransform, rgbToRgbaOrUndefined, toMillisecondsFromTimescale, getFromSafeObject } from '../../aux/aux';
import PopUpModal from '../PopUpModal/PopUpModal';
import SimpleNoModalDialog from '../SimpleNoModalDialog/SimpleNoModalDialog';
import PopUpMeetingOccurrenceRegister from '../PopUpMeetingOccurrenceRegister/PopUpMeetingOccurrenceRegister';
import PopUpMeetingOccurrenceUnregister from '../PopUpMeetingOccurrenceUnregister/PopUpMeetingOccurrenceUnregister';
import JoinMeetingButton from '../JoinMeetingButton/JoinMeetingButton';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import WarningAmber from '@mui/icons-material/WarningAmber';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { amber } from '@material-ui/core/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EventIcon from '@mui/icons-material/Event';
import ScheduleIcon from '@mui/icons-material/Schedule';
import MeetingDescription from '../MeetingDescription/MeetingDescription';
import PopUpMeetingEditionChange from '../PopUpMeetingEditionChange/PopUpMeetingEditionChange';
import { setTimeout_, clearTimeout_ } from '../../aux/extendedTimeout';
import CustomPopover from '../CustomPopover/CustomPopover';
import { useRef } from 'react';
import UserMeetingStatusWidget from '../UserMeetingStatusWidget/UserMeetingStatusWidget';
import PopUpJoiningMeetingError from '../PopUpJoiningMeetingError/PopUpJoiningMeetingError';
import MeetingGrantedRepetitionFloatingBadge from '../MeetingGrantedRepetitionFloatingBadge/MeetingGrantedRepetitionFloatingBadge';
import PopUpLowPriorityRegistrationWarning from '../PopUpLowPriorityRegistrationWarning/PopUpLowPriorityRegistrationWarning';
import { useLocaleCtx } from '../../customHooks/useLocaleCtx';
import './userMeeting.css'

const UserMeeting = ({ 
    userMeeting, userMeetingStatus, userMeetingStatusArray, userMeetingOccurrenceStatusArray, isExpectedMeetingId, userStatus, userStatusArray, 
    isAlwaysOpen, onRegisterUserMeeting, onUnregisterUserMeeting, onChangeUserMeeting, onRefreshUserMeeting, 
    onCreateUserMeetingRepetition, onUserStatusChange, ...props 
}) => {
    const { t } = useTranslation('common', { keyPrefix: 'userMeeting' });
    const theme = useTheme();
    const locale = useLocaleCtx()
    const abortController = new AbortController();
    const abortControllerRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);  
    const [isOpenDetailsPopover, setIsOpenDetailsPopover] = useState(false);
    const [showRegistrationPopup, setShowRegistrationPopup] = useState(false);
    const [showUnregistrationPopup, setShowUnregistrationPopup] = useState(false);
    const [showChangeEditionPopup, setShowChangeEditionPopup] = useState(false);
    const [showOnJoiningMeetingErrorPopup, setShowOnJoiningMeetingErrorPopup] = useState(false);
    const [showPopUpLowPriorityRegistrationWarning, setShowPopUpLowPriorityRegistrationWarning] = useState(false);
    const [lowPriorityRegistrationAgreement, setLowPriorityRegistrationAgreement] = useState(false);
    const [joiningMeetingErrorCause, setJoiningMeetingErrorCause] = useState('');
    const [showNotExpectedMeetingIdInfo, setShowNotExpectedMeetingIdInfo] = useState(false);
    const [isCancelable, setIsCancelable] = useState(false);
    const [isOpenable, setIsOpenable] = useState(false);
    const [cancelTimeWindow, setCancelTimeWindow] = useState(null);
    const [openTimeWindow, setOpenTimeWindow] = useState(null);
    const [errorSyncingUnexpectedMeetingId, setErrorSyncingUnexpectedMeetingId] = useState(false);
    const [meetingAppWindow, setMeetingAppWindow] = useState(null);
    const joinButtonRef = useRef(null);

    const styles = {
        icon: {
            display: 'block',
            padding: '0px 5px',
            // fontSize: '1.5em',
        },
        notExpectedMeetingContainer:{
            border: `solid 1px ${theme.palette.warning.main}`,
            borderRadius: '5px',
            backgroundColor: amber[50],
        },
        noExpectedMeetingWarningContainer:{
            backgroundColor: theme.palette.warning.main,
            padding:'5px',
            alignItems: 'center'
        },
        differentMeetingContainer:{
            border: `solid 1px #f3f3f3`,
            borderRadius: '5px',
            backgroundColor: '#fff',
            padding: '10px',
        },
        topic: {
            fontWeight: 'bold'
        },
        error: {
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main,
        },
        alawaysOpenContainer: {
            border: `solid 1px ${theme.palette.primary.main}`,
            borderRadius: '5px',
            backgroundColor: '#fff',
        }
    }

    const onAgreeLowPriorityRegistrationWarning = (e) => {
        setShowPopUpLowPriorityRegistrationWarning(false);
        setLowPriorityRegistrationAgreement(true);
        setShowRegistrationPopup(true);
    };
    const onShowRegistrationPopup = (lowPriorityMeeting) => {
        if(lowPriorityMeeting && !lowPriorityRegistrationAgreement){
            setShowPopUpLowPriorityRegistrationWarning(true);
        }else{
            setShowRegistrationPopup(true);
        }
    }
    const onHideRegistrationPopup = (lowPriorityMeeting) => {
        setShowRegistrationPopup(false);
    }
    const handleOnShowUnregistrationPopup = (e) => {
        setShowUnregistrationPopup(prev => !prev);
    }
    const handleOnShowChangeEditionPopup = (e) => {
        setShowChangeEditionPopup(prev => !prev);
    }
    const handleOnShowUserJoinMeetingErrorPopup = (e) => {
        setShowOnJoiningMeetingErrorPopup(prev => !prev);
    }
    const showOpenDetailsPopover = () => {
        setIsOpenDetailsPopover(true);
    }
    const hideOpenDetailsPopover = () => {
        setIsOpenDetailsPopover(false);
    }

    const syncCanceledMeetingOccurrence = async(meetingOccurrenceId, signal=abortControllerRef.current.signal) => {
        try{
            setIsLoading(true);
            await onUnregisterUserMeeting(meetingOccurrenceId, true, signal);
        }catch(error){
            // Alowing to cancel registration from client
            if(!signal.aborted){
                setIsCancelable(true);
                setIsOpenable(false);
            }
        }finally{
            if(!signal.aborted){
                setIsLoading(false);
            }
        }
    }

    const syncUnexpectedMeetingId = async(signal=abortControllerRef.current.signal) => {
        try{
            setIsLoading(true);
            await onChangeUserMeeting(true, signal);
        }catch(error){
            if(!signal.aborted){
                setErrorSyncingUnexpectedMeetingId(true);
            }
        }finally{
            if(!signal.aborted){
                setIsLoading(false);
            }
        }
    }

    const handleOnOpenMeetingAppWindow = (openedWindowName, openedWindow) => {
        setMeetingAppWindow({
            name: openedWindowName,
            window: openedWindow
        });
    }

    const closeMeetingAppWindow = () => {
        if(isNotEmptyObject(meetingAppWindow)){
            meetingAppWindow.window.close();
        }
    }

    const handleOnUnregisterUserMeeting = async (...props) => {
            closeMeetingAppWindow();
            await onUnregisterUserMeeting(...props);
    }

    const onChangeMeetingInUserMeeting = async(changeToMeetingId, registerMeetingOccurrenceId, updateAfter=true, signal=null) => {
        // exceptions are cached on the corresponding component
        await onChangeUserMeeting(false, signal, changeToMeetingId);
        // After register the new occurrence we update userMeeting
        // This prevent to perform a new request to refresh userMeeting
        await onRegisterUserMeeting(registerMeetingOccurrenceId, updateAfter, signal);
    }

    const onHandleJoiningMeetingError = (cause) => {
        setJoiningMeetingErrorCause(cause);
        setShowOnJoiningMeetingErrorPopup(true);
    }

    const getCancelOccurrenceWindowOrNull = (occurrence, securityOpenWindowMs=0) => {
        if(occurrence.isReschedulable){
            const now = new Date();
            const registrationEnds = new Date(occurrence.registrationEndsDateTime);
            const securedStartingTime = new Date(occurrence.startsDateTime).getTime() - securityOpenWindowMs;
            const cancelationEndsTime = Math.min(securedStartingTime, registrationEnds.getTime());
            const securedEndsTime = new Date(cancelationEndsTime);
            const startsTime = new Date(occurrence.registrationStartsDateTime);
            return [startsTime, securedEndsTime];
        }else{
            return null
        }
    }
    const getOpenOccurrenceWindow = (occurrence, securityOpenWindowMs=0) => {
        const now = new Date();
        const starts = new Date(occurrence.startsDateTime);
        const securedStartingTime = new Date(starts.getTime() - securityOpenWindowMs);
        const endsTime = new Date(occurrence.endsDateTime);
        return [securedStartingTime, endsTime];
    }

    useEffect(() => {
        if(userMeetingStatus === userMeetingStatusArray[3]){
            const occurrence = userMeeting.details.occurrence;
            if(occurrence.status != 'canceled'){
                const now = new Date();
                const cancelWindow = getCancelOccurrenceWindowOrNull(occurrence, userMeeting.securityOpenMeetingWindowMs);
                setCancelTimeWindow(cancelWindow)
                // let timeoutToCancel;
                if(cancelWindow){
                    const [_, endsCancellation] = cancelWindow;
                    const timeToEndCancellation = endsCancellation.getTime() - now.getTime();
                    setIsCancelable(timeToEndCancellation > 0);
                    // if(timeToEndCancellation > 0){
                    //     setIsCancelable(true);
                    //     timeoutToCancel = setTimeout_(() => {setIsCancelable(false)}, timeToEndCancellation);
                    // }else{
                    //     setIsCancelable(false);
                    // }
                }else{
                    setIsCancelable(false);
                }
                const openWindow = getOpenOccurrenceWindow(occurrence, userMeeting.securityOpenMeetingWindowMs);
                setOpenTimeWindow(openWindow)
                const [startsIsOpen, endsIsOpen] = openWindow;
                const timeToStartsIsOpen = startsIsOpen.getTime() - now.getTime();
                const timeToCloseIsOpen = endsIsOpen.getTime() - now.getTime();
                setIsOpenable(timeToStartsIsOpen <= 0 && timeToCloseIsOpen > 0)
                // let timeoutToCloseOpen,
                //     timeoutToStartOpen;
                // if(timeToStartsIsOpen > 0){
                //     setIsOpenable(false);
                //     timeoutToStartOpen = setTimeout_(() => {setIsOpenable(true)}, timeToStartsIsOpen);
                // }else{
                //     setIsOpenable(true);
                // }
                // if(timeToCloseIsOpen > 0){
                //     timeoutToCloseOpen = setTimeout_(() => {setIsOpenable(false)}, timeToCloseIsOpen);
                // }else{
                //     setIsOpenable(false);
                // }
                // return(() => {
                //     clearTimeout_(timeoutToCancel);
                //     clearTimeout_(timeoutToCloseOpen);
                //     clearTimeout_(timeoutToStartOpen);
                // })
            }else{
                // Rare scenario where after a meeting occurrence cancelation 
                // an userMeeting has not been unregistered
                abortControllerRef.current = abortController;
                syncCanceledMeetingOccurrence(userMeeting.meetingOccurrenceId);
                return(() => {
                    abortControllerRef.current.abort();
                })
            }
        }else{
            if(userMeetingStatus === userMeetingStatusArray[2] && isExpectedMeetingId === false){
                abortControllerRef.current = abortController;
                syncUnexpectedMeetingId();
                return(() => {
                    abortControllerRef.current.abort();
                })
            }else{
                setIsOpenable(false);
                setIsCancelable(false);
                setCancelTimeWindow(null);
                setOpenTimeWindow(null);
            }
        }
    },[userMeetingStatus, userMeeting])

    const getNoExpectedMeetingSectionToRenderWhenUserMeetingOccurrenceIsFound = () => {
        let title,
            explanation;
        switch(userMeeting.details.occurrence.status){
            case 'ended':
                title = t('completedInDifferentMeetingEdition');
                explanation = t('completedInDifferentMeetingEditionLogic');
                break;
            default:
                title = t('registeredInDifferentMeetingEdition');
                explanation =  t('registeredInDifferentMeetingEditionLogic');
        }
        const noExpectedMeetingComponent = 
            <Grid container direction='column' style={styles.notExpectedMeetingContainer}>
                <Grid item >
                    <Grid container style={styles.noExpectedMeetingWarningContainer}>
                        <Grid item>
                            <WarningAmber style={styles.icon} />
                        </Grid>
                        <Grid item xs>
                            <Typography variant='body2'>
                                {textTransform('title',title)}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton
                                size='small'
                                onClick={(e) => (setShowNotExpectedMeetingIdInfo(prev => !prev))}
                            >
                                <ExpandMoreIcon className={`user-meeting-different-meeting-expand-icon ${showNotExpectedMeetingIdInfo ? 'less' : ''}`}/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item 
                    className={`user-meeting-different-meeting-info-wrapper ${showNotExpectedMeetingIdInfo === true ? 'show' : ''}`}
                >
                    <Grid container direction='column'>
                        <Grid item className='user-meeting-different-meeting-explanation-container' >
                            <Typography variant='body2'>
                                {textTransform('title', explanation)}
                            </Typography>
                        </Grid>
                        <Grid item style={{padding:'10px'}}>
                            <Grid container direction='column' style={styles.differentMeetingContainer}>
                                <Grid item>
                                    <UserMeetingStatusWidget 
                                        className={'user-meeting-status-widget-container'} 
                                        userMeeting={userMeeting} 
                                        userMeetingOccurrenceStatusArray={userMeetingOccurrenceStatusArray}
                                        userStatus={userStatus} 
                                        userStatusArray={userStatusArray} 
                                    />
                                </Grid>
                                <Grid item>
                                    <MeetingDescription meeting={userMeeting.details.meeting} fieldToPreventMap={{agenda:true}} />
                                </Grid>
                                <Grid item>
                                    <Grid container>
                                        <Grid item className='user-meeting-date-time-item'>
                                            <Grid container>
                                                <Grid item>
                                                    <EventIcon style={styles.icon} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant='body2'>
                                                        {new Date(userMeeting.details.occurrence.startsDateTime).toLocaleDateString(locale.localeCode)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item className='user-meeting-date-time-item'>
                                            <Grid container>
                                                <Grid item>
                                                    <ScheduleIcon style={styles.icon} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant='body2'>
                                                        {`${new Date(userMeeting.details.occurrence.startsDateTime).toLocaleTimeString(locale.localeCode)} - ${new Date(userMeeting.details.occurrence.endsDateTime).toLocaleTimeString(locale.localeCode)}`}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    {getLowSectionToRenderWhenUserMeetingOccurrenceIsFound()}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        return noExpectedMeetingComponent    
    }

    const getLowSectionToRenderWhenUserMeetingOccurrenceIsFound = () => {
        let lowSectionSubComponent;
        switch(userMeeting.details.occurrence.status){
            case 'canceled':
                lowSectionSubComponent = 
                    <>
                        <Grid item>
                            <Typography variant='caption'>
                                {`* ${textTransform('title', t('eventCanceled'))}. ${textTransform('title', t('pleaseCancelYourAttendanceAndGetRegisteredInAnotherEdition'))}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container className='user-meeting-button-container'>
                                <Grid item className='user-meeting-button'>
                                    <Button 
                                        color='primary' 
                                        onClick={handleOnShowUnregistrationPopup} 
                                        disabled={!isCancelable || isOpenable}
                                    >
                                        {t('cancelAttendance')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                break;
            case 'ended':
                lowSectionSubComponent = 
                    <>
                        {isAlwaysOpen &&
                            <MeetingGrantedRepetitionFloatingBadge onClaimGrant={onCreateUserMeetingRepetition} />
                        }
                        <Grid item>
                            <Typography variant='caption'>
                                {`* ${textTransform('title', t('eventEnded'))}: ${openTimeWindow[1].toLocaleString(locale.localeCode)}`}
                            </Typography>
                        </Grid>
                    </>
                break;
            default:
                // open, in_progress, close
                if(openTimeWindow == null){
                    lowSectionSubComponent =     
                        <Grid item xs>
                            <Grid container className='user-meeting-info-container'>
                                <Grid item>
                                    <Typography variant='body2'>
                                        {textTransform('title', t('accessingUserMeetingStatus'))}
                                    </Typography>
                                </Grid>
                                <Grid item className='user-meeting-info-loading-item-wrapper'>
                                    <LoadingComponent visibleElements='circle' circleSize='1.2em'/>
                                </Grid>
                            </Grid>
                        </Grid>                
                }else{
                    lowSectionSubComponent = 
                        <>
                            <Grid container className='user-meeting-button-container'>
                                <Grid item className='user-meeting-button'>
                                    <Button 
                                        color='primary' 
                                        onClick={handleOnShowUnregistrationPopup} 
                                        disabled={!isCancelable || isOpenable}
                                        size='small'
                                    >
                                        {t('cancelAttendance')}
                                    </Button>
                                </Grid>
                                <Grid item className='user-meeting-button' 
                                    {...!isOpenable ? 
                                        {
                                            onMouseEnter: showOpenDetailsPopover,
                                            onMouseLeave: hideOpenDetailsPopover
                                        }
                                        :{}
                                    }
                                >
                                    <CustomPopover 
                                        anchorEl={joinButtonRef}
                                        open={isOpenDetailsPopover}
                                        anchorOrigin={{vertical:'top', horizontal:'center'}} 
                                        transformOrigin={{vertical:'bottom', horizontal:'center'}}
                                    >
                                        <Typography variant='body2'>
                                            {`${textTransform('title', t('eventOpens'))}:`}
                                        </Typography>
                                        <Typography variant='body2'>
                                            {`${openTimeWindow[0].toLocaleDateString(locale.localeCode)} ${openTimeWindow[0].toLocaleTimeString(locale.localeCode)}`}
                                        </Typography>
                                    </CustomPopover>
                                    <JoinMeetingButton
                                        userId={userMeeting.userId} 
                                        userMeetingId={userMeeting.id} 
                                        label={t('common:open')}
                                        ref={joinButtonRef} 
                                        onOpenMeetingAppWindow={handleOnOpenMeetingAppWindow}
                                        onUserStatusChange={onUserStatusChange}
                                        onUserJoinMeetingError={onHandleJoiningMeetingError}
                                        disabled={!isOpenable || isCancelable}
                                        color='primary'
                                        variant='contained'
                                        size='small'
                                    />
                                </Grid>
                            </Grid>
                            <Typography variant='caption'>
                                {
                                    isCancelable ?
                                            `* ${textTransform('title', t('youCanCancelBefore'))}: ${cancelTimeWindow[1].toLocaleString(locale.localeCode)}`
                                        :
                                        isOpenable ?
                                            `* ${textTransform('title', t('youCanJoinBefore'))}: ${openTimeWindow[1].toLocaleString(locale.localeCode)}`
                                            :
                                            openTimeWindow[0] && openTimeWindow[0].getTime() > new Date().getTime() ?
                                                `* ${textTransform('title', t('eventOpens'))}: ${openTimeWindow[0].toLocaleString(locale.localeCode)}`
                                                :
                                                `* ${textTransform('title', t('eventEnded'))}: ${openTimeWindow[1].toLocaleString(locale.localeCode)}`
                                }
                            </Typography>
                        </>
                }
        }
        const lowSectionComponent = 
            <Grid container className='user-meeting-low-section-container' justifyContent='space-between' >
                {lowSectionSubComponent}
            </Grid>
        return lowSectionComponent
    }

    const getComponentToRenderWhenUserMeetingOccurrenceIsFound = () => {
        let componentToRender;
        if(isExpectedMeetingId){
            componentToRender =
                <>
                    <PopUpMeetingOccurrenceUnregister 
                        userMeeting={userMeeting}
                        userMeetingStatus={userMeetingStatus}
                        userMeetingStatusArray={userMeetingStatusArray}
                        header={t('cancelAttendance')}
                        show={showUnregistrationPopup} 
                        onUnregister={handleOnUnregisterUserMeeting}
                        onClickClose={handleOnShowUnregistrationPopup} 
                    />
                    <PopUpJoiningMeetingError
                        showModal={showOnJoiningMeetingErrorPopup}
                        onClickClose={handleOnShowUserJoinMeetingErrorPopup}
                        cause={joiningMeetingErrorCause}
                    />
                    <Grid container direction='column'>
                        <Grid item>
                            <Grid container>
                                <Grid item className='user-meeting-date-time-item'>
                                    <Grid container>
                                        <Grid item>
                                            <EventIcon style={styles.icon} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='body2'>
                                                {new Date(userMeeting.details.occurrence.startsDateTime).toLocaleDateString(locale.localeCode)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item className='user-meeting-date-time-item'>
                                    <Grid container>
                                        <Grid item>
                                            <ScheduleIcon style={styles.icon} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='body2'>
                                                {`${new Date(userMeeting.details.occurrence.startsDateTime).toLocaleTimeString(locale.localeCode)} - ${new Date(userMeeting.details.occurrence.endsDateTime).toLocaleTimeString(locale.localeCode)}`}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            {getLowSectionToRenderWhenUserMeetingOccurrenceIsFound()}
                        </Grid>
                    </Grid>
                </>
        }else{
            componentToRender =
                <>
                    <PopUpMeetingEditionChange 
                        userMeeting={userMeeting}
                        userMeetingStatus={userMeetingStatus}
                        userMeetingStatusArray={userMeetingStatusArray}
                        desiredMeeting={userMeeting.details.meeting}
                        header={t('changeEdition')}
                        show={showChangeEditionPopup} 
                        onMeetingEditionChange={onChangeMeetingInUserMeeting}
                        onUnregisterUserMeeting={onUnregisterUserMeeting}
                        onRefreshUserMeeting={onRefreshUserMeeting}
                        onClickClose={handleOnShowChangeEditionPopup} 
                    />
                    <PopUpJoiningMeetingError
                        showModal={showOnJoiningMeetingErrorPopup}
                        onClickClose={handleOnShowUserJoinMeetingErrorPopup}
                        cause={joiningMeetingErrorCause}
                    />
                    {getNoExpectedMeetingSectionToRenderWhenUserMeetingOccurrenceIsFound()}
                </>
        }
        return componentToRender;
    }

    const getComponentToRender = (userMeetingStatus) => {
        let componentToRender;
        try{
            switch(userMeetingStatus){
                case userMeetingStatusArray[4]:
                    componentToRender = 
                        <Grid container className='user-meeting-info-container'>
                            <Grid item>
                                <WarningAmber style={styles.icon}/>
                            </Grid>
                            <Grid item>
                                <Typography variant='body2'>
                                    {textTransform('title', t('requestingError'))}
                                </Typography>
                            </Grid>
                        </Grid>
                    break
                case userMeetingStatusArray[3]:
                    componentToRender = getComponentToRenderWhenUserMeetingOccurrenceIsFound()
                    break;
                case userMeetingStatusArray[2]:
                    componentToRender = 
                    <>
                        <PopUpLowPriorityRegistrationWarning
                            show={showPopUpLowPriorityRegistrationWarning}
                            onAgree={onAgreeLowPriorityRegistrationWarning}
                            lowPriorityRegistrationWindowMs={userMeeting.lowPriorityRegistrationWindowMs}
                        />
                        <PopUpMeetingOccurrenceRegister 
                            meetingId={userMeeting.meetingId}
                            isLowPriority={userMeeting.lowPriorityMeeting}
                            lowPriorityRegistrationWindowMs={userMeeting.lowPriorityRegistrationWindowMs} 
                            securityOpenMeetingWindowMs={userMeeting.securityOpenMeetingWindowMs}
                            header={t('registerAttendance')}
                            show={showRegistrationPopup} 
                            onRegisterMeetingOccurrence={onRegisterUserMeeting}
                            onClickClose={onHideRegistrationPopup} 
                        />
                        <Grid container className='user-meeting-low-section-container' justifyContent='space-between'>
                            <Grid item className={`user-meeting-error-toogle ${errorSyncingUnexpectedMeetingId ? 'show': ''}`}>
                                <Grid container alignItems='center'>
                                    <Grid item>
                                        <ErrorOutlineIcon style={{...styles.icon, ...styles.error}}/>
                                    </Grid>
                                    <Grid item>
                                    <Typography variant='caption' style={styles.error} >
                                        { 
                                            `${textTransform('title', t('cannotRegisterToThisEventEdition'))}`
                                            +`\n${textTransform('title', t('tryItLater'))}` 
                                        }
                                    </Typography>
                                    </Grid>
                                </Grid> 
                            </Grid>
                            <Grid item >
                                <Grid container className='user-meeting-button-container'>
                                    <Grid item className='user-meeting-button'>
                                        <ButtonLoading
                                            isLoading={isLoading}
                                            disabled={errorSyncingUnexpectedMeetingId}
                                            variant='contained' 
                                            color='primary' 
                                            label={t('registerAttendance')}
                                            onClick={() => onShowRegistrationPopup(userMeeting.lowPriorityMeeting)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid> 
                        </Grid>
                    </>
                    break;
                case userMeetingStatusArray[1]:
                    componentToRender =
                    <Grid container className='user-meeting-info-container'>
                        <Grid item>
                            <Typography variant='body2'>
                                {textTransform('title', t('accessingUserMeetingStatus'))}
                            </Typography>
                        </Grid>
                        <Grid item className='user-meeting-info-loading-item-wrapper'>
                            <LoadingComponent visibleElements='circle' circleSize='1.2em'/>
                        </Grid>
                    </Grid>
                    break;
                case userMeetingStatusArray[0]:
                    // Nothing to render
                    break
            }
        }catch(error){
            // Uncatched error: show the error component
            // This error could be occasionated by inconsistencies between userMeeting and userMeetingStatus
            // This state might be triggered by updating clearing userMeeting while userMeetingStatus has set 'occurrence' as status 
            componentToRender =  
                <Grid container className='user-meeting-info-container'>
                    <Grid item>
                        <WarningAmber style={styles.icon}/>
                    </Grid>
                    <Grid item>
                        <Typography variant='body2'>
                            {textTransform('title', t('requestingError'))}
                        </Typography>
                    </Grid>
                </Grid>
        }finally{
            return componentToRender
        }
    }

    return (
        <div {...props} >
            {
                getComponentToRender(userMeetingStatus)
            }
        </div>    
    )
}

export default UserMeeting