import { useState, useEffect, useRef, forwardRef } from 'react'
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Typography, Chip, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { 
    changeMeetingInUserMeeting, getMeeting, registerUserMeetingOccurrence, 
    unregisterUserMeetingOccurrence, createUserMeetingRepetition, getUserMeetingOccurrenceFromEventSource 
} from '../../services/meetingServices';
import { getUserMeeting } from '../../services/meetingServices';
import LoadingComponent from '../LoadingComponent/LoadingComponent';

import { getFromSafeObject, isEmptyObject, isNotEmptyObject, textTransform, toMillisecondsFromTimescale } from '../../aux/aux';

import UserMeeting from '../UserMeeting/UserMeeting';
import UserMeetingStatusWidget from '../UserMeetingStatusWidget/UserMeetingStatusWidget';
import MeetingDescription from '../MeetingDescription/MeetingDescription';
import UserMeetingDateBadge from '../UserMeetingDateBadge/UserMeetingDateBadge';
import RefreshIcon from '@mui/icons-material/Refresh';
// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import noConnection from '../../lotties/noConnection_200x200.json';
import './meetingWidget.css'
import { getReliableLocale, sessionVariables } from '../../aux/sessionHelpers';
import MeetingGrantedRepetitionFloatingBadge from '../MeetingGrantedRepetitionFloatingBadge/MeetingGrantedRepetitionFloatingBadge';
import PopUpModal from '../PopUpModal/PopUpModal';
import PopUpModalHeader from '../PopUpModalHeader/PopUpModalHeader';
import SimpleNoModalDialog from '../SimpleNoModalDialog/SimpleNoModalDialog';
import PopUpLowPriorityGrantedCongrats from '../PopUpLowPriorityGrantedCongrats/PopUpLowPriorityGrantedCongrats';
import { useImperativeHandle } from 'react';
import { getUserTrakingToken } from '../../services/userServices';

const MeetingWidget = ({ id, userId, meetingId, userMeetingId, translationLocaleId, isAlwaysOpen, onUserMeetingProductProgressUpdate, ...props }, ref) => {
    useImperativeHandle(ref, () => {
        return {
            refresh: (component) => {
                if(component === 'userMeeting'){
                    onRefreshUserMeeting();
                }else if(component === 'meeting'){
                    onRefreshMeeting()
                }else{
                    handleOnRefresh(true);
                }
            }
        }
    }, []);
    const { t } = useTranslation('common', { keyPrefix:"meetingWidget"});
    const theme = useTheme();
    const meetingStatusArray = ['mounting', 'requesting', 'ready', 'error'];
    const userMeetingStatusArray = ['mounting', 'requesting', 'noOccurrence', 'occurrence', 'error'];
    const userMeetingOccurrenceStatusArray = ['open', 'in_progress', 'ended', 'canceled'];
    const userStatusArray = ['idle', 'joinedMeeting', 'leftMeeting'];
    const MAX_REQUESTING_INTENTS = 10;
    const matchMobile = useMediaQuery('(max-width:680px)');
    const [requestingIntentsCount, setRequestingIntentsCount] = useState(0);
    const [meeting, setMeeting] = useState(null);
    const [userMeeting, setUserMeeting] = useState(null)
    const [isMeetingRequesting, setIsMeetingRequesting] = useState(false);
    const [isUserMeetingRequesting, setIsUserMeetingRequesting] = useState(false);
    const [meetingError, setMeetingError] = useState({});
    const [userMeetingError, setUserMeetingError] = useState({});
    const [meetingStatus, setMeetingStatus] = useState(meetingStatusArray[0]);
    const [userMeetingStatus, setUserMeetingStatus] = useState(userMeetingStatusArray[0]);
    const [userStatus, setUserStatus] = useState(userStatusArray[0]);
    const [userMeetingHasExpectedMeetingId, setUserMeetingHasExpectedMeetingId] = useState(null);
    const [animationContainer, setAnimationContainerRef] = useState(null);
    const [showLowPriorityGrantedCongratsPopup, setShowLowPriorityGrantedCongratsPopup] = useState(false);
    const [userTrakingToken, setUserTrakingToken] = useState(null);
    const animationRef = useRef(null);
    const meetingAbortControllerRef =  useRef(null);
    const userMeetingAbortControllerRef = useRef(null);
    const userTrakingTokenAbortControllerRef = useRef(null);
    const styles = {
        refreshIcon: {
            color: theme.palette.action.active, //#888
            opacity: requestingIntentsCount >= MAX_REQUESTING_INTENTS ? theme.palette.action.disabledOpacity : 1

        },
        topic: {
            fontWeight: 'bold'
        },
        wrapper:{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[1]
        }
    }


    const requestMeeting = async(meetingId, translationLocaleId, signal=meetingAbortControllerRef.current.signal, minimumTimeOut=0) => {
        try{
            setIsMeetingRequesting(true);
            const queryOpt = {
                translationLocaleId,
                expand: 'all',
            }
            const res = await getMeeting(meetingId, queryOpt, minimumTimeOut, signal);
            const {
                meeting
            } = res.data;
            if(isNotEmptyObject(meeting) && meeting.id){
                if(!signal.aborted){
                    setMeeting(meeting);
                }
            }else{
                throw new Error('empty meeting received');
            }
        }catch(error){
            if(!signal.aborted){
                setMeetingError({ type: 'requesting', error });
            }
        }finally{
            if(!signal.aborted){
                setRequestingIntentsCount( prev => (prev + 1))
                setIsMeetingRequesting(false);
            }
        }
    };

    const requestUserMeeting = async(userId, userMeetingId, signal=userMeetingAbortControllerRef.current.signal, minimumTimeOut=0) => {
        try{
            setIsUserMeetingRequesting(true);
            const queryOpt = {
                translationLocaleId,
                expand: 'all',
            }
            const res = await getUserMeeting(userId, userMeetingId, queryOpt, minimumTimeOut, signal);
            const {
                userMeeting
            } = res.data;
            if(isNotEmptyObject(userMeeting) && userMeeting.id && 
            userMeeting.details?.meeting && isNotEmptyObject(userMeeting.details.meeting)){
                if(!signal.aborted){
                    setUserMeeting(userMeeting);
                }
            }else{
                // TODO: Force resync userMeetingId on server
                // if userMeeting was removed but userProgress keeps userMeetingId
                throw new Error('empty user meeting received');
            }
        }catch(error){
            if(!signal.aborted){
                setUserMeetingError({ type: 'requesting', error });
            }
        }finally{
            if(!signal.aborted){
                setIsUserMeetingRequesting(false);
            }
        }
    };

    const onRegisterUserMeeting = async(selectedMeetingOccurrenceId, updateAfter=true, signal=userMeetingAbortControllerRef.current.signal, uId=userId, umId=userMeetingId) =>{
        try{
            let queryOpt = {};
            if(updateAfter){
                queryOpt = {
                    retrieveUpdated: updateAfter,
                    translationLocaleId,
                };
            }
            const res = await registerUserMeetingOccurrence(uId, umId, selectedMeetingOccurrenceId, queryOpt, 1000, signal);
            const {
                userMeeting
            } = res.data
            if(updateAfter){
                if(isNotEmptyObject(userMeeting) && userMeeting.id &&
                userMeeting.details?.meeting && isNotEmptyObject(userMeeting.details.meeting)){
                    if(signal){
                        if(!signal.aborted){
                            setUserMeeting(userMeeting);
                        }
                    }else{
                        setUserMeeting(userMeeting);
                    }
                }else{
                    // registration is performed but the updated value was not properly retrieved
                    setUserMeetingError({ type: 'requesting', error: 'empty user meeting received' });
                }
            }
        }catch(error){
            throw error 
        }
    };

    const onUnregisterUserMeeting = async(meetingOccurrenceId, updateAfter=true, signal=userMeetingAbortControllerRef.current.signal, uId=userId, umId=userMeetingId) =>{
        try{
            let queryOpt = {};
            if(updateAfter){
                queryOpt = {
                    retrieveUpdated: updateAfter,
                    translationLocaleId,
                };
            }
            const res = await unregisterUserMeetingOccurrence(uId, umId, meetingOccurrenceId, queryOpt, 1000, signal);
            const {
                userMeeting
            } = res.data
            if(updateAfter){
                if(isNotEmptyObject(userMeeting) && userMeeting.id &&
                userMeeting.details?.meeting && isNotEmptyObject(userMeeting.details.meeting)){
                    if(signal){
                        if(!signal.aborted){
                            setUserMeeting(userMeeting);
                        }
                    }else{
                        setUserMeeting(userMeeting);
                    }
                }else{
                    // unregistration is performed but the updated value was not retrieved
                    setUserMeetingError({ type: 'requesting', error: 'empty user meeting received' });
                }
            }
        }catch(error){
            throw error 
        }
    };

    /**
     * It update user meeting with the meeting id in the meeting product if no params are given.
     * Otherwise, It set the given meeting id to the user meeting as long as the user has access to
     * its corresponding meeting product
     * @param {*} mId 
     * @param {*} updateAfter 
     * @param {*} signal 
     * @param {*} uId 
     * @param {*} umId 
     */
    const onChangeMeetingInUserMeeting = async(updateAfter=true, signal=userMeetingAbortControllerRef.current.signal, mId=meeting.id,  uId=userId, umId=userMeetingId) =>{
        try{
            let queryOpt = {};
            if(updateAfter){
                queryOpt = {
                    retrieveUpdated: updateAfter,
                    translationLocaleId,
                };
            }
            const res = await changeMeetingInUserMeeting(uId, umId, mId, queryOpt, 1000, signal);
            const {
                userMeeting
            } = res.data
            if(updateAfter){
                if(isNotEmptyObject(userMeeting) && userMeeting.id &&
                userMeeting.details?.meeting && isNotEmptyObject(userMeeting.details.meeting)){
                    if(signal){
                        if(!signal.aborted){
                            setUserMeeting(userMeeting);
                        }
                    }else{
                        setUserMeeting(userMeeting);
                    }
                }else{
                    // unregistration is performed but the updated value was not retrieved
                    setUserMeetingError({ type: 'requesting', error: 'empty user meeting received' });
                }
            }
        }catch(error){
            throw error 
        }
    };

    const onCreateUserMeetingLowPriorityRepetition = async(updateAfter=false, signal=userMeetingAbortControllerRef.current.signal, uId=userId, umId=userMeetingId) => {
        try{
            let queryOpt = {}
            if(updateAfter){
                queryOpt.assign({
                    retrieveUpdated: updateAfter,
                    translationLocaleId,
                })
            }
            const res = await createUserMeetingRepetition(uId, umId, queryOpt, 1000 , signal);
            const {
                UserMeetingProductProgress,
                updatedUserMeetingProductProgressKeys,
            } = res.data;
            const updatedUserMeetingProductProgressProperties = {};
            updatedUserMeetingProductProgressKeys.forEach( key => updatedUserMeetingProductProgressProperties[key] = UserMeetingProductProgress[key]);
            await onUserMeetingProductProgressUpdate(id, updatedUserMeetingProductProgressProperties, undefined, false);
        }catch(error){
            throw error
        }
    }

    const getUserMeetingTrakingToken = async(uId=userId, umId=userMeetingId, signal=userTrakingTokenAbortControllerRef.current.signal) => {
        try{
            const res = await getUserTrakingToken(uId, [ umId ], undefined, 0, signal);
            const {
                userTrakingToken
            } = res.data;
            if(!signal.aborted){
                setUserTrakingToken(userTrakingToken)
            }
        }catch(error){
            // TODO: implement
            // Notify the user there is no realtime userMeeting status updates;
            if(!signal.aborted){
                setUserTrakingToken(null)
            }
        }

    }


    const resetUserMeeting = () => {
        setUserMeetingStatus(userMeetingStatusArray[0]);
        setUserMeeting(null);
        setUserMeetingError({});
        setIsUserMeetingRequesting(false);
    };
    const resetMeeting = () => {
        setMeetingStatus(meetingStatusArray[0]);
        setMeeting(null);
        setMeetingError({});
        setIsMeetingRequesting(false);
    }
    const resetAnimationContainer = () => {
        setAnimationContainerRef(null);
        animationRef.current = null;
    }

    const resetComponent = () => {
        resetMeeting();
        resetUserMeeting();
        resetAnimationContainer();
    }

    const handleOnRefresh = async( uncounted=false ) => {
        if(requestingIntentsCount < MAX_REQUESTING_INTENTS || uncounted){
            // explicit reset is unneeded 
            // request methods trigger a full reset
            // TODO: check if it triggers 
            // resetComponent();
            requestMeeting(meetingId, translationLocaleId, meetingAbortControllerRef.current.signal, 1000);
            requestUserMeeting(userId, userMeetingId, userMeetingAbortControllerRef.current.signal, 1000)
        }
    };

    const onRefreshUserMeeting = async () => {
        // explicit reset is uneeded
        // request methods trigger an user meeting reset
        // resetUserMeeting();
        requestUserMeeting(userId, userMeetingId, userMeetingAbortControllerRef.current.signal, 1000)
    };

    const onRefreshMeeting = async () => {
        // explicit reset is uneeded
        // request methods trigger an user meeting reset
        // resetUserMeeting();
        requestMeeting(meetingId, translationLocaleId, meetingAbortControllerRef.current.signal, 1000)
    };

    const onUserStatusChange = (status) => {
        setUserStatus(status);
    }

    useEffect(() => {
        if(meetingId){
            const abortController = new AbortController()
            meetingAbortControllerRef.current = abortController;
            requestMeeting(meetingId, translationLocaleId, abortController.signal);
            return(() => {
                abortController.abort();
            })
        }else{
            setMeeting({});
        }
    },[userId, meetingId, translationLocaleId])

    useEffect(() => {
        if(userMeetingId){
            const abortController = new AbortController();
            userMeetingAbortControllerRef.current = abortController;
            requestUserMeeting(userId, userMeetingId, abortController.signal);
            return(() => {
                abortController.abort();
            })
        }else{
            setUserMeeting({});
        }
    },[userId, userMeetingId])

    useEffect(() => {
        if(isNotEmptyObject(meetingError)){
            // set status to: error 
            setMeetingStatus( meetingStatusArray[3] )
        }else{
            if(isMeetingRequesting){
                // set status to: requesting 
                setMeetingStatus( meetingStatusArray[1] )
            }else{
                if(meeting != null && isNotEmptyObject(meeting) && meeting.id){
                    // defined meeting state
                    setMeetingStatus( meetingStatusArray[2] );
                }else{
                    // throws error from requestMeeting
                }
            }
        }
    },[meeting, isMeetingRequesting, meetingError]);

    useEffect(() => {
        if(isNotEmptyObject(userMeetingError)){
            // set status to: error
            setUserMeetingStatus(prev => userMeetingStatusArray[4])
        }else{
            if(isUserMeetingRequesting){
                // set status to: requesting
                setUserMeetingStatus(prev => userMeetingStatusArray[1])
            }else{
                if(userMeeting != null && isNotEmptyObject(userMeeting) && userMeeting.id){
                    if(userMeeting.meetingOccurrenceId != null){
                        if(userMeeting.details?.occurrence && isNotEmptyObject(userMeeting.details.occurrence)){
                            setUserMeetingStatus(prev => userMeetingStatusArray[3])
                        }else{
                            // possible local inconsistency after updating userMeeting. DB inconsistency is checked on request statement
                            setUserMeetingStatus(prev => userMeetingStatusArray[4])
                        }
                    }else{
                        // unregistered meeting 
                        setUserMeetingStatus(prev => userMeetingStatusArray[2])
                    }
                }else{
                    // throws error from requestUserMeeting
                }
            }
        }
    }, [userMeeting, isUserMeetingRequesting, userMeetingError])

    useEffect(() => {
        if(userMeetingStatus === userMeetingStatusArray[3]){
            const abortController = new AbortController();
            userTrakingTokenAbortControllerRef.current = abortController;
            getUserMeetingTrakingToken();
            return(() => abortController.abort())
        }else{
            setUserTrakingToken(null);
        }
    },[userMeetingStatus])

    useEffect(() => {
        if(userTrakingToken){
            function onmessage(event){
                const {
                    tokenStatus,
                    meetingOccurrenceMapByUserMeetingId,
                } = JSON.parse(event.data);
                if(tokenStatus === 'wrong'){
                    getUserMeetingTrakingToken();
                    this.close();
                }else{
                    console.log(userMeetingId)
                    if(isNotEmptyObject(meetingOccurrenceMapByUserMeetingId) && meetingOccurrenceMapByUserMeetingId.hasOwnProperty(userMeetingId)){
                        const meetingOccurrence = meetingOccurrenceMapByUserMeetingId[userMeetingId];
                        setUserMeeting(prev => ({...prev, details:{ ...prev.details, occurrence:meetingOccurrence }}))
                    }
                }
            }
            function onerror(event){
                this.close();
            }
            const eventSource = getUserMeetingOccurrenceFromEventSource(userMeeting.userId, userTrakingToken, onmessage, onerror);
            return(() => {
                eventSource.close();
            })
        }
    },[userTrakingToken])

    useEffect(() => {
        if(meeting && meeting.id != null && userMeeting && userMeeting.meetingId != null){
            const isExpectedMeeting = userMeeting.meetingId === meeting.id;
            setUserMeetingHasExpectedMeetingId(isExpectedMeeting);
        }else{
            setUserMeetingHasExpectedMeetingId(false);
        }
    },[userMeeting, meeting])

    useEffect(() => {
        if(animationContainer != null){
            animationRef.current = lottie.loadAnimation({
                container: animationContainer,
                renderer: 'svg',
                autoplay: true,
                animationData: noConnection,
                loop: false,
                name: 'animation'
            })
            return(() => {
                if(animationRef.current != null){
                    animationRef.current.stop();
                    animationRef.current?.destroy();
                }
            })
        }
    },[animationContainer])

    const getComponentToRender = () => {
        let componentToRender;
        switch(meetingStatus){
            case 'mounting':
            case 'requesting':
                // Uncatched error: show the loading component
                // This error could be occasionated by errors requesting userMeeting or meeting
                componentToRender = <LoadingComponent visibleElements='circle' />
                break;
            case 'ready':
                componentToRender = 
                <div className='meeting-widget-ready-main-container'>
                    <div className='meeting-widget-ready-status-and-refresh-container'>
                        {userMeetingHasExpectedMeetingId &&
                            <UserMeetingStatusWidget 
                                className={'meeting-widget-user-meeting-status-widget-container'} 
                                userMeeting={userMeeting} 
                                userMeetingOccurrenceStatusArray={userMeetingOccurrenceStatusArray}
                                userStatus={userStatus} 
                                userStatusArray={userStatusArray} 
                            />
                        }
                        <div className='meeting-widget-refresh-button-container'>
                            <IconButton
                                className='meeting-widget-refresh-icon-button'
                                size='small'
                                disabled={meetingStatus === meetingStatusArray[1] || requestingIntentsCount >= MAX_REQUESTING_INTENTS}
                                onClick={ handleOnRefresh }
                            >
                                <RefreshIcon 
                                    style={ styles.refreshIcon } />
                            </IconButton>
                        </div>
                    </div>
                    <div className='meeting-widget-ready-content-container'>
                        {!matchMobile ?
                            <UserMeetingDateBadge 
                                userMeeting={userMeeting} 
                                userMeetingStatus={userMeetingStatus} 
                                userMeetingStatusArray={userMeetingStatusArray} 
                                isExpectedMeetingId={userMeetingHasExpectedMeetingId} 
                            />
                            :
                                null
                        }
                        <Grid item xs >
                            <Grid container direction='column' className='meeting-widget-description-container'>
                                <Grid item>
                                    <Grid container>
                                        <Grid item>
                                            <Typography variant='body1' style={ styles.topic }>
                                                {textTransform('title', getFromSafeObject(meeting, 'description.topic',''))}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <MeetingDescription meeting={meeting} />
                                </Grid>
                                <Grid item className='meeting-widget-user-meeting-container'>
                                    <UserMeeting 
                                        userMeeting={userMeeting} 
                                        userMeetingStatus={userMeetingStatus} 
                                        userMeetingStatusArray={userMeetingStatusArray}
                                        userMeetingOccurrenceStatusArray={userMeetingOccurrenceStatusArray}
                                        isExpectedMeetingId={userMeetingHasExpectedMeetingId}
                                        userStatus={userStatus}
                                        userStatusArray={userStatusArray} 
                                        isAlwaysOpen={isAlwaysOpen}
                                        onRegisterUserMeeting={onRegisterUserMeeting}
                                        onUnregisterUserMeeting={onUnregisterUserMeeting}
                                        onChangeUserMeeting={onChangeMeetingInUserMeeting}
                                        onRefreshUserMeeting={onRefreshUserMeeting}
                                        onUserStatusChange={onUserStatusChange}
                                        onCreateUserMeetingRepetition={onCreateUserMeetingLowPriorityRepetition}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                break;
            case 'error':
                componentToRender = 
                <Grid container className='meeting-widget-error-container'>
                    <Grid item className='meeting-widget-badge'>
                        <div className='meeting-widget-error-animation' ref={setAnimationContainerRef} />
                    </Grid>
                    <Grid item xs >
                        <Grid container direction='column' className='meeting-widget-description-container'>
                            <Grid item>
                                <Typography className='meeting-widget-error-msg' variant='body1'>
                                    {
                                        `${textTransform('title', t("requestingError"))}.`
                                        + `\n${textTransform('title', requestingIntentsCount >= MAX_REQUESTING_INTENTS ? t("tryLater") : t("tryAgain"))}`
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className='meeting-widget-refresh-icon-container'>
                        <IconButton
                            className='meeting-widget-refresh-icon-button'
                            size='small'
                            disabled={meetingStatus === meetingStatusArray[1] || requestingIntentsCount >= MAX_REQUESTING_INTENTS}
                            onClick={ handleOnRefresh }
                        >
                            <RefreshIcon 
                                style={ styles.refreshIcon } />
                        </IconButton>
                    </Grid>
                </Grid>
                break;
            default:
        }
        return componentToRender;
    }
    return (
        <div ref={ref} id={`userMeeting-${userMeetingId}`} className='meeting-widget-wrapper' {...props} style={{...styles.wrapper, ...props.style}} >
            <PopUpLowPriorityGrantedCongrats show={showLowPriorityGrantedCongratsPopup} onClickClose={() => setShowLowPriorityGrantedCongratsPopup(false)} />
            {getComponentToRender()}
        </div>
    )
}

export default forwardRef(MeetingWidget)