import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { forwardRef } from 'react';
import { Box, Paper } from '@material-ui/core'
import { useUpdateScrollableDocumentCtx } from '../../customHooks/useUpdateScrollableDocumentCtx';

// Img and graphics
import CloseIcon from '../../img/close-fill.svg';

// Style
import './popUpModal.css'
import { useScrollableDocument } from '../../customHooks/useScrollableDocument';

const PopUpModal = ({showModal, onClickClose, onOutsideClick, height, width, isDialog=false, ...props}, ref) => {
    const setIsScrollableDocument = useUpdateScrollableDocumentCtx();
    const modalRef = useRef(null);
    const styles = {
        wrapper: {
            // animation: showModal ? 'fade-in 0.3s' : 'fade-in 0.3 reverse'
            ...props.wrapperStyle
        },
        paper: {
            height,
            width,
            ...props.style
        },
        closeIcon: {
            height: '25px',
            ...props.closeIconStyle
        }
    }

    useEffect(() => {
        if(onOutsideClick){
            if(modalRef.current){
                const closeOnOutsideClick = (e) => {
                    if(e.target === modalRef.current){
                        onOutsideClick();
                    }
                }
                window.addEventListener('click', closeOnOutsideClick)
                return( () => {
                    window.removeEventListener('click', closeOnOutsideClick)
                })
            }
        }
    },[])

    // Updated: 04/10/24 done in scrollableDocumentContext
    useEffect(() => {
        if(showModal){
            setIsScrollableDocument(false);
            return(() => {
                setIsScrollableDocument(true);
            })
        }
    }, [showModal])

    return ReactDOM.createPortal(
        <Box ref={modalRef} className={`pop-up-main ${showModal ? 'pop-up-main-visible' : 'pop-up-main-hidden'}`} style={ styles.wrapper }>
            <Paper ref={ref} className={`pop-up-modal ${props.className ? props.className : ''}`} style={ styles.paper }>
                {!isDialog &&
                    <CloseIcon className={`pop-up-modal-close-icon-basic ${props.closeIconClassName ? props.closeIconClassName : 'pop-up-modal-close-icon'}`} onClick={onClickClose} style={ styles.closeIcon }/>
                }
                {props.children}
            </Paper>
        </Box>
        , document.body
    )
}

export default forwardRef(PopUpModal)