import { useEffect } from "react"
import { useUpdateHeaderPropsCtx } from "../../customHooks/useUpdateHeaderPropsCtx";
import { isNotEmptyObject } from "../../aux/aux";

const HeaderUpdater = ({newProps, children}) => {
    const setHeaderProps = useUpdateHeaderPropsCtx();
    
    useEffect(() => {
        if(newProps && isNotEmptyObject(newProps)){
            setHeaderProps(newProps);
            return(() => setHeaderProps({})) // Reset to initial props
        }
    },[newProps])

    return( children )
}

export default HeaderUpdater