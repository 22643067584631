import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import HttpBackend from 'i18next-http-backend';
import commonTranslationEs_Es from './App/i18nLocalTranslation/es-ES/common.json';
import commonTranslationEn_US from './App/i18nLocalTranslation/en-US/common.json';

const localResources = {
    "es-ES": {
        common: commonTranslationEs_Es
    },
    ...process.env.REACT_APP_TARGET_ENV !== 'production' ? 
            {
                "en-US": {
                    common: commonTranslationEn_US
                },
            }
        :
            {}
}
export const I18N_AVAIL_LOCALECODE_ARRAY = process.env.REACT_APP_TARGET_ENV !== 'production' ? ['es-ES', 'en-US'] : ['es-ES'];
export const I18N_DEFAULT_LOCALECODE = 'es-ES';
i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    .use(ChainedBackend)
    .use(initReactI18next)
    .init({
            debug: process.env.REACT_APP_TARGET_ENV !== 'production', // Comment on development
            fallbackLng: {
                'es': ['es-ES'],
                'en': ['en-US'],
                default: [I18N_DEFAULT_LOCALECODE],
            },
            fallbackNS: 'common',
            backend: {
                backends: [
                    HttpBackend,
                    resourcesToBackend(localResources)
                ],
                backendOptions:[{
                    loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
                    addPath: `${process.env.PUBLIC_URL}/locales/add/{{lng}}/{{ns}}`
                }]
            },
            // lng: 'es',
            // supportedLngs: I18N_AVAIL_LOCALECODE_ARRAY,
            // preload: I18N_AVAIL_LOCALECODE_ARRAY,
            // defaultNs: 'translation',
            // ns: ['landing','common', 'passiveAccountVerification'],
            // backend: {
            //     loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json?lng={{lng}}&{{ns}}`,
            //     addPath: `${process.env.PUBLIC_URL}/locales/add/{{lng}}/{{ns}}`
            // }
            // react: {
            //     useSuspense: false,
            // }
    });
export default i18n;
