import { useState } from 'react'
import { Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import ButtonLoading from '../ButtonLoading/ButtonLoading'
import Ribbon from '../../img/ribbon_24x42.svg'
import { useTranslation } from 'react-i18next'
import { getFromSafeObject, textTransform } from '../../aux/aux'
import ProductAmount from '../ProductAmount/ProductAmount'
import AvailUnitWidget from '../AvailUnitWidget/AvailUnitWidget'
import { isProductScarce, isSubscriptionOrUpgradeSubscriptionProductTypeName } from '../../aux/productHelpers'
import './contentConsumptionOption.css'

const ContentConsumptionOption = ({ isFavorite, productConsumptionOption, productTypeConsumptionKeyMap, onClickProductConsumptionOption, shadow, ...props }) => {
    const theme = useTheme();
    const { t } = useTranslation('contentProduct', {keyPrefix: 'contentConsumptionOptions'});
    const [isLoading, setIsLoading] = useState(false);
    const styles = {
        isNotFavoriteContainer:{
            paddingBottom: `${theme.typography.body2.lineHeight}em`
        },
        mainContainer:{
            backgroundColor: theme.palette.background.paper,
            ...(shadow != null ? {boxShadow: theme.shadows[shadow]} : {}),
            ...(isFavorite ? 
                {
                    border: `solid 3px ${theme.palette.primary.main}` 
                }
                :
                {}
            )
        },
        isBest:{
            color: theme.palette.primary.main,
        },
        ribbon:{
            display:'block',
            fill: theme.palette.primary.main
        },
        features:{
            color: theme.palette.grey[500]
        }
    }

    const handleOnConsumptionOption = async (e) => {
        try{
            e.preventDefault();
            setIsLoading(true)
            if(typeof onClickProductConsumptionOption === "function"){
                await onClickProductConsumptionOption(productConsumptionOption);
            }
        }catch(error){
            // TODO: implement
            console.log(error)
        }finally{
            setIsLoading(false);
        }
    }

    return (
        <div className={`content-consumption-option-wrapper ${props.className ? props.className : ''}`}>
            <div className='content-consumption-option-is-best-container'>
                {isFavorite ?
                    <Typography variant='body2' style={styles.isBest}>
                        {textTransform('title', t('common:bestValue'))}
                    </Typography>
                    :
                    <div style={styles.isNotFavoriteContainer}/> 
                }
            </div>
            <div className='content-consumption-option-main-container' style={styles.mainContainer}>
                {isFavorite &&
                    <div className='content-consumption-option-ribbon-container'>
                        <Ribbon style={styles.ribbon}/>
                    </div>
                }
                { props.children }
                <div className='content-consumption-option-price-and-features-container'>
                    {isSubscriptionOrUpgradeSubscriptionProductTypeName(productConsumptionOption.productTypeName) &&
                        <Typography variant='body2' style={styles.features} className='content-consumption-option-feature'>
                            {textTransform('title', t('common:startsAt'))}
                        </Typography>
                    }
                    <ProductAmount amountObject={getFromSafeObject(productConsumptionOption, 'consumptionDetails.details.purchasabilityDetails.details.amount')}/>
                    {productTypeConsumptionKeyMap.featuresKeyArray.map((featureKey, index) =>
                        <Typography key={index} variant='body2' style={styles.features} className='content-consumption-option-feature'>
                            {textTransform('title',t(featureKey))}
                        </Typography>
                    )}
                </div>
                {isProductScarce(getFromSafeObject(productConsumptionOption, 'consumptionDetails.availUnits')) ?
                    <div className='content-consumption-option-avail-unit-container'>
                        <AvailUnitWidget 
                            variant={"body2"}
                            unitType={t(productTypeConsumptionKeyMap.unitTypeKey, {count: getFromSafeObject(productConsumptionOption, 'consumptionDetails.availUnits')})} 
                            count={getFromSafeObject(productConsumptionOption, 'consumptionDetails.availUnits')}
                            fullMessage
                        />
                    </div>
                    :
                    null
                }
                <ButtonLoading 
                    fullWidth
                    variant={isFavorite ? 'outlined' : 'contained'}
                    color={"primary"}
                    size={'large'} 
                    isLoading={isLoading}
                    noLabelWhileLoading={true}
                    disabled={!getFromSafeObject(productConsumptionOption, 'consumptionDetails.isPurchasable')}
                    label={t(productTypeConsumptionKeyMap.joinLabelKey)}
                    onClick={handleOnConsumptionOption}
                />
            </div>
        </div>
    )
}

export default ContentConsumptionOption