import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { rgbToHex } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { getStripe } from '../../aux/stripeHelpers';
import { Elements} from '@stripe/react-stripe-js';
import PopUpModal from '../PopUpModal/PopUpModal'
import PopUpModalHeader from '../PopUpModalHeader/PopUpModalHeader';
import SimpleNoModalDialog from '../SimpleNoModalDialog/SimpleNoModalDialog';
import './popUpPaymentMethodEdit.css'
import { requestMinimumTimeoutWrapper } from '../../aux/requestMethods';
import { createBasicSetUpIntentFn } from '../../aux/purchaseQueries';
import { useHistory, useLocation } from 'react-router-dom';
import CardPaymentMethodForm from '../CardPaymentMethodForm/CardPaymentMethodForm';
import ToggleMsg from '../ToggleMsg/ToggleMsg';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import { textTransform } from '../../aux/aux';

const PopUpPaymentMethodEdit = ({ userId, billingAddress, show, onClickClose, updateDefaultPaymentMethod}) => {
    const POP_UP_WIDTH = 380;
    const STRIPE_SETUP_INTENT_CLIENT_SECRET_QUERY_PARAM = 'setup_intent_client_secret';
    const DESIRED_INTENT_STATUS = 'succeeded';
    const { t } = useTranslation('common', { keyPrefix: 'popUpPaymentMethodEdit'});
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation()
    const [errorMsg, setErrorMsg] = useState(null);
    const [feedbackSetUpIntent, setFeedbackSetUpIntent] = useState({show:false, msg:null, onAgree:null});
    const [redirectURL, setRedirectURL] = useState(window.location.origin + location.pathname);
    const [setupIntentClientSecretInQuery, setSetupIntentClientSecretInQuery] = useState(null);
    const [setUpIntentClientSecret, setSetUpIntentClientSecret] = useState(null);
    const abortControllerRef = useRef(null);
    const stripeAppearance = {
        theme:'stripe',
        variables:{
            fontFamily: theme.typography.body1.fontFamily,
            fontSizeBase: theme.typography.fontSize,
            colorText: rgbToHex(theme.palette.text.primary),
            colorPrimary: theme.palette.primary.main,
        },
    };
    const stripeOptions = {
        appearance: stripeAppearance,
        clientSecret: setUpIntentClientSecret,
    }

    const getSetUpIntentClientSecret = async(userId, signal) => {
        try{
            const res = await requestMinimumTimeoutWrapper(createBasicSetUpIntentFn, { userId }, 0, signal)
            setSetUpIntentClientSecret(prev => (res.data.setUpIntentClientSecret));
        }catch(error){
            const msg = `${textTransform('title', t('cannotUpdateThePaymentMethod'))}\n${textTransform('title', t('tryItLater'))}`
            setErrorMsg(msg)
        }
    }

    const resetSetUpClientSecretIntent= () => {
        history.replace({search: ''});
        getSetUpIntentClientSecret(userId);
    }

    const onError = (message, showFeedback=false) => {
        if(showFeedback){ 
            setFeedbackSetUpIntent({
                show: true,
                msg: message,
                onAgree: onResetSetUpIntent
            })
        }else{
            setErrorMsg(message);
        }
    }

    const onChange = (e) => {
        if(errorMsg){
            setErrorMsg(null);
        }
    }

    const onResetSetUpIntent = () => {
        resetSetUpClientSecretIntent();
        setFeedbackSetUpIntent({
            show: false,
            msg: null,
            onAgree: null
        });
    }

    const onSuccessfullyUpdate = (tempNewPaymentMethod) =>{
        updateDefaultPaymentMethod(tempNewPaymentMethod);
        resetSetUpClientSecretIntent();
        onClickClose();
    }
    
    useEffect(() => {
        if(show){
            const abortController = new AbortController();
            abortControllerRef.current = abortController;
            if(setupIntentClientSecretInQuery != null){
                handleRedirectConfirmation(setupIntentClientSecretInQuery)
            }else{
                getSetUpIntentClientSecret(userId, abortController.signal);
            }
            return(() => {
                abortController.abort();
            })
        }else{
            setErrorMsg(null);
            setFeedbackSetUpIntent({ show:false, msg:null, onAgree:null });
            setSetUpIntentClientSecret(null);
            setSetupIntentClientSecretInQuery(null);
        }
    },[show])

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        if(query.has(STRIPE_SETUP_INTENT_CLIENT_SECRET_QUERY_PARAM)){
            setSetupIntentClientSecretInQuery(query.get(STRIPE_SETUP_INTENT_CLIENT_SECRET_QUERY_PARAM));
            query.delete(STRIPE_SETUP_INTENT_CLIENT_SECRET_QUERY_PARAM);
            history.replace({
                search: query.toString(),
            })
        }
    },[])

    const handleRedirectConfirmation = async (intentClientSecret) => {
        try{
            const stripe = await getStripe();
            const res = await stripe.retrieveSetupIntent(intentClientSecret);
            const {
                setupIntent,
                error
            } = res
            if(setupIntent.status !== DESIRED_INTENT_STATUS || error != null){
                const msg = `${textTransform('title', t('errorProcessingThePaymentMethod'))}.`
                            +` ${textTransform('title', t('tryItAgainWithADifferentPayMethod'))}`
                onError(msg, true);
            }else{
                const msg = `${textTransform('title', t('thePaymentMethodWasSuccessfullyUpdated'))}`;
                setFeedbackSetUpIntent({
                    show:true,
                    msg: msg,
                    onAgree: onClickClose
                })
            }
        }catch(error){
            const msg = `${textTransform('title', t('connectivityError'))}.`
                +` ${textTransform('title', t('tryItLater'))}`
            setFeedbackSetUpIntent({
                show:true,
                msg: msg,
                onAgree: onClickClose
            })
        }
    }

    // SetUpIntent is requested just when the component is mounted
    // or by resetSetUpIntent
    // const onCancel = () => {
    //     onClickClose();
        // setErrorMsg(null);
        // setWarningResetSetUpIntent({ show:false, msg:null });
    // }
    // useEffect(() => {
    //     const abortController = new AbortController();
    //     abortControllerRef.current = abortController;
    //     getSetUpIntentClientSecret(userId, abortController.signal);
    //     return(() => {
    //         abortController.abort();
    //     })
    // },[])

    return (
        <PopUpModal 
            showModal={show}
            onClickClose={onClickClose}
            width={POP_UP_WIDTH}
        >
            <PopUpModalHeader title={t('paymentMethod')} />
                <div className='popup-payment-method-edit-dialog-container'>
                    {feedbackSetUpIntent.show ?
                        <SimpleNoModalDialog
                            onDisagree={onClickClose}
                            onAgree={feedbackSetUpIntent.onAgree}
                            contentText={feedbackSetUpIntent.msg}
                        />
                        :
                        setUpIntentClientSecret ?
                            <Elements options={stripeOptions} stripe={getStripe()}>
                                <CardPaymentMethodForm 
                                    billingAddress={billingAddress}
                                    redirectURL={redirectURL} 
                                    onCancel={onClickClose} 
                                    onUpdate={onSuccessfullyUpdate}
                                    onError={onError}
                                    onChange={onChange}
                                />
                            </Elements>
                        :
                            <div className='popup-payment-method-edit-loading-container'>
                                <LoadingComponent visibleElements='circle' />
                            </div>
                    }
                    <div className={`popup-payment-method-edit-feedback-toggle ${errorMsg != null ? 'show' : ''}`}>
                        <ToggleMsg 
                            isShown={errorMsg !== null} 
                            msg={errorMsg} 
                            type='error' 
                        />
                    </div>
                </div>   
        </PopUpModal>
    )
}

export default PopUpPaymentMethodEdit