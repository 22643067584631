import { useEffect, useState } from "react";
import ImgDN from "../cdn/imgDN";
import cloudinary from "../cdn/providers/cloudinary";


export const useImgURL = (uri, url=null, cdnProvider=cloudinary) => {
    const assetProvider = new ImgDN(cdnProvider)
    const [imgURL, _setImgURL] = useState(null);
    const setImgURL = (imgURI, imgURL) => {
        let newURL;
        if(imgURI){
            try{
                newURL = assetProvider.getAssetUrl(imgURI);
            }catch(error){
                newURL = null;
            }
        }else if(imgURL){
            newURL = imgURL;
        }else{
            newURL = null
        }
        _setImgURL(newURL);
    }
    useEffect(() => {
        setImgURL(uri, url)
    },[uri, url, cdnProvider])
    return [imgURL, setImgURL]
}

