import { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { Alert } from '@mui/material'

// Style
import './messageDisplay.css'
import ImgLazyLoad from '../ImgLazyLoad/ImgLazyLoad'

const MessageDisplay = ({ messageList, setMessageList, timeToIn=500, timeToOut=5000 }) => {
    const [showingMessageList, setShowingMessageList] = useState([]);
    const maxSimultaneousMessages = 3;

    useEffect(() => {
        if (messageList.msgList.length > 0 && showingMessageList.length === 0 ){
            setTimeout( () => {
                setShowingMessageList(messageList.msgList.slice(0,maxSimultaneousMessages));
            }, timeToIn)
        }
    }, [messageList])

    useEffect(() => {
        if(showingMessageList.length > 0){
            const showedMessagesCount = showingMessageList.length;
            const totalMessagesCount = messageList.msgList.length;
            setTimeout( () => {
                setShowingMessageList([]);
                setMessageList({msgList:messageList.msgList.slice(showedMessagesCount, totalMessagesCount)});
            }, timeToOut)
        } 
    }, [showingMessageList])

    return (
        <div className='message-display-main'>
            <Grid container direction='column'>
                { showingMessageList.map( (message, index) => {
                    return(
                        <Grid key={index} item className='message-display-item'>
                            <Alert severity={message.severity}>
                                {message.message}
                            </Alert>
                        </Grid>
                    )
                })}
            </Grid>
        </div>
    )
}

export default MessageDisplay
