import ProductAmount from '../ProductAmount/ProductAmount'
import './checkoutProductAmount.css'

const DEFAULT_TYPO_INITIAL_STYLE = {
    opacity: '50%',
}
const DEFAULT_TYPO_FINAL_STYLE = {
    fontWeight: 'regular',
}
const CheckoutProductAmount = ({ amountObject, typoFinalStyle=DEFAULT_TYPO_FINAL_STYLE, typoInitialStyle=DEFAULT_TYPO_INITIAL_STYLE, showAmountBeforeDiscount=false, showAmountBeforePromoCode=true }) => {
  return (
    <ProductAmount 
        amountObject={amountObject} 
        typoInitialVariant={'body2'} 
        typoFinalVariant={'body1'} 
        typoFinalStyle={typoFinalStyle}
        typoInitialStyle={typoInitialStyle}
        showAmountBeforeDiscount={showAmountBeforeDiscount} 
        showAmountBeforePromoCode={showAmountBeforePromoCode}
        className={'checkout-product-amount-main-container'}
    /> 
  )
}

export default CheckoutProductAmount