import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';
import ClosedCaptionOffIcon from '@mui/icons-material/ClosedCaptionOff';
import CableIcon from '@mui/icons-material/Cable';
import Share from '@mui/icons-material/Share';

import './popUpClassContentMenu.css'
import { useState } from 'react';
import PopUpMenu from '../PopUpMenu/PopUpMenu';
import PopUpModal from '../PopUpModal/PopUpModal';
import ClassContentLanguageEdit from '../ClassContentLanguageEdit/ClassContentLanguageEdit';
import ClassContentCaptionsEdit from '../ClassContentCaptionsEdit/ClassContentCaptionsEdit';
import ClassContentSystemEdit from '../ClassContentSystemEdit/ClassContentSystemEdit';
import ClassContentUserSystemSelect from '../ClassContentUserSystemSelect/ClassContentUserSystemSelect';
import PopUpClassContentUserSystemSelect from '../PopUpClassContentUserSystemSelect/PopUpClassContentUserSystemSelect';
import PopUpShareModal from '../PopUpShareModal/PopUpShareModal';

const PopUpClassContentMenu = ({ anchorEl, isOpen, onClose, userConfig, contentSummary, onUpdateConfig, contentSelected, userAccessDetails }) => {
    const { t } = useTranslation('common');
    const [selectedOption, setSelectedOption] = useState(null); 
    const isLessonSelected = (contentSelected) => {
        // currentLessonId is 0 when overview is selected
        // Notice that Boolean(null || undefined) = false
        return Boolean(contentSelected && contentSelected.currentLessonId)
    }

    const isDisabledConfigButton = ( withNoLessonSelected=false ) => {
        const passLessonSelectionControl = withNoLessonSelected ? isLessonSelected(contentSelected) : true;
        return userAccessDetails?.hasAccess !== true || !passLessonSelectionControl 
    }
    const optionList = [
        {
            label: t('language', {count:1}),
            icon: LanguageIcon,
            value: 'language',
            followedByDivider: false,
            isDisabled: isDisabledConfigButton(),
        },
        {
            label: t('captions'),
            icon: ClosedCaptionOffIcon,
            value: 'captions',
            followedByDivider: false,
            isDisabled: isDisabledConfigButton(),
        },
        {
            label: t('system', { count:1}),
            icon: CableIcon,
            value: 'system',
            followedByDivider: true,
            isDisabled: isDisabledConfigButton(),
        },
        {
            label: t('share'),
            icon: Share,
            value: 'share',
            followedByDivider: false,
            isDisabled: false,
        }
    ];

    const onSelectedOption = (selectedMenuOptionIndex) => {
        const selectedValue = optionList[selectedMenuOptionIndex].value
        setSelectedOption(selectedValue);
    }

    const handleOnCloseModal = () => {
        setSelectedOption(null);
    }

    const getClassContentMenuOption = (selectedOption) => {
        let PopUpMenuOption = null;
        switch(selectedOption){
            case 'language':
                PopUpMenuOption = 
                <PopUpModal showModal={selectedOption} onClickClose={handleOnCloseModal} width={"380px"}>
                    <ClassContentLanguageEdit userConfig={userConfig} contentSummary={contentSummary} onUpdate={onUpdateConfig} autoCloseModal={handleOnCloseModal} />
                </PopUpModal>
                break;
            case 'captions':
                PopUpMenuOption = 
                <PopUpModal showModal={selectedOption} onClickClose={handleOnCloseModal} width={"380px"}>
                    <ClassContentCaptionsEdit userConfig={userConfig} contentSummary={contentSummary} onUpdate={onUpdateConfig} autoCloseModal={handleOnCloseModal} />
                </PopUpModal>
                break;
            case 'system':
                PopUpMenuOption = <PopUpClassContentUserSystemSelect userConfig={userConfig} contentSummary={contentSummary} onUpdate={onUpdateConfig} showModal={selectedOption} onClickClose={handleOnCloseModal} isDialog={false} title={t('system', { count: 1})} />
                // ClassContentMenuOption = <ClassContentSystemEdit userConfig={userConfig} contentSummary={contentSummary} onUpdate={onUpdateConfig} autoCloseModal={handleOnCloseModal} />
                break;
            case 'share':
                PopUpMenuOption = <PopUpShareModal contentSummary={contentSummary} showModal={selectedOption} onClickClose={handleOnCloseModal}/>
                break;
        }
        return PopUpMenuOption;
    }

    return (
        <>
            {getClassContentMenuOption(selectedOption)}
            <PopUpMenu anchorEl={anchorEl} isOpen={isOpen} onClose={onClose} optionList={optionList} onSelectedOption={onSelectedOption}/>
        </>
    )
}

export default PopUpClassContentMenu