import { Box, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/styles'
import { Skeleton } from '@mui/material'
// Style
import './checkoutInSubscription.css'
import { getFromSafeObject, textTransform } from '../../aux/aux';
import { useEffect, useState } from 'react';
import ImgDN from '../../cdn/imgDN';
import cloudinary from '../../cdn/providers/cloudinary';

const CheckoutInSubscriptionItem = ({ product }) => {
    const theme = useTheme();
    const PRODUCT_IMG_SIZE_PX = 22;
    const [imgUrl, setImgUrl] = useState(null);
    const { t } = useTranslation('checkout');
    const style = {
        ligthFont:{
            color: theme.palette.primary.light,
        }
    }
    useEffect(() => {
        let imgUrl
        const imgPublicId = getFromSafeObject(product, 'description.imageDetails.main.publicId');
        if(imgPublicId){
            const assetProvider = new ImgDN(cloudinary);
            imgUrl = assetProvider.getAssetUrl(imgPublicId);
        }else{
            imgUrl = null;
        }
        setImgUrl(imgUrl)
    },[product])
    return (
        <Grid container direction="row" className="checkout-in-subcription-main-container" justifyContent='space-between' alignItems="center">
            <Grid item style={{height:"100%"}}>
                <Grid container direction="row" style={{height:"100%"}} alignItems='center'>
                    <Grid item className='checkout-in-subcription-img-container' align='right'>
                        {imgUrl ?
                            <Box className='checkout-in-subscription-img' height={PRODUCT_IMG_SIZE_PX} component='img' alt='product-img' src={imgUrl}/>
                            :
                            <Skeleton height={PRODUCT_IMG_SIZE_PX} width={PRODUCT_IMG_SIZE_PX} variant="rectangular"/>
                        }
                    </Grid>
                    <Grid item className='checkout-in-subscription-description'>
                        <Typography variant="body2" style={style.ligthFont}>
                            {product.description.nameTranslated.lenght > 23 ?
                                `${product.description.nameTranslated.substring(0,23)}...` : product.description.nameTranslated
                            }
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className='checkout-in-subscription-price'>
                <Typography variant="body2" style={style.ligthFont}>
                    {textTransform('title', t('included'))}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default CheckoutInSubscriptionItem