import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { textTransform } from '../../aux/aux';

import PopUpModal from '../PopUpModal/PopUpModal'
import PopUpModalHeader from '../PopUpModalHeader/PopUpModalHeader';
import SimpleNoModalDialog from '../SimpleNoModalDialog/SimpleNoModalDialog'
import UserMeetingBadge from '../UserMeetingBadge/UserMeetingBadge';

import SwapVertIcon from '@mui/icons-material/SwapVert';
import './popUpMeetingEditionChange.css'
import PopUpMeetingOccurrenceUnregister from '../PopUpMeetingOccurrenceUnregister/PopUpMeetingOccurrenceUnregister';
import PopUpMeetingOccurrenceRegister from '../PopUpMeetingOccurrenceRegister/PopUpMeetingOccurrenceRegister';
import BackButton from '../BackButton/BackButton';

const PopUpMeetingEditionChange = ({ show, userMeeting, userMeetingStatus, userMeetingStatusArray, desiredMeeting, header, onMeetingEditionChange, onUnregisterUserMeeting, onRefreshUserMeeting, onClickClose}) => {
    const POP_UP_WIDTH= 380;
    const { t } = useTranslation('common', { keyPrefix: 'popUpMeetingEditionChange' });
    const [isUnregistering, setIsUnregistering] = useState(false);
    const [showUnregistration, setShowUnregistration] = useState(false);
    const [showRegistration, setShowRegistration] = useState(false);
    const popUpStateArray = ['unregister', 'register'];
    const [popUpState, setPopUpState] = useState(popUpStateArray[0]); 
    const [needToRefreshUserMeeting, setNeedToRefreshUserMeeting] = useState(false);

    const styles = {
        unRegistrationContainer: {
            transitionDelay: showUnregistration ? '0.3s' : '0s'
        },
        registrationContainer: {
            transitionDelay: showRegistration ? '0.3s' : '0s'
        }
    }
    const handleOnUnregister = async (selectedOccurrenceId, updateAfter, signal) => {
        // exceptions are handle on the caller component
        updateAfter = false; // force to no update
        await onUnregisterUserMeeting(selectedOccurrenceId, updateAfter, signal)
        setNeedToRefreshUserMeeting(true);
        setPopUpState(popUpStateArray[1])
    }
    const handleOnChange = async (selectedOccurrenceId, updateAfter, signal) => {
        // exceptions are handle on the caller component
        updateAfter = true; // force to update
        await onMeetingEditionChange(desiredMeeting.id, selectedOccurrenceId, updateAfter, signal)
        // userMeeting refresh is performed in onMeetingEditionChange
        onClickClose()
    }
    const handleOnClickCloseAndRefresh = async() => {
        if(needToRefreshUserMeeting){
            await onRefreshUserMeeting()
        }
        onClickClose();
    }
    const clearComponent = () => {
        setNeedToRefreshUserMeeting(false);
        setPopUpState(popUpStateArray[0])
    }
    useEffect(() => {
        if(popUpState === popUpStateArray[0]){
            setShowUnregistration(true);
            setShowRegistration(false);
        }else{
            setShowUnregistration(false);
            setShowRegistration(true);
        }
    },[popUpState])

    useEffect(() => {
        if(!show){
            clearComponent()
        }
    },[show])

    return (
        <PopUpModal 
        showModal={show} 
        onClickClose={onClickClose} 
        width={POP_UP_WIDTH}
        isDialog={true}
        style={{
            minHeight: '200px'
        }}
        >
            <PopUpModalHeader
                title={t('changeEdition')}
                Icon={SwapVertIcon}
                subtitle={`${showUnregistration ? 
                    textTransform('title', t('cancelAttendance'))
                    :
                    textTransform('title', t('registerAttendance'))
                }`}
            />
            <div 
                className={`pop-up-meeting-edition-change-unregister-container ${showUnregistration === true ? '' : 'hide'}`}
                style={styles.unRegistrationContainer}
            >
                <PopUpMeetingOccurrenceUnregister 
                    modal={false}
                    userMeeting={userMeeting}
                    userMeetingStatus={userMeetingStatus}
                    userMeetingStatusArray={userMeetingStatusArray}
                    onUnregister={handleOnUnregister}
                    onClickClose={handleOnClickCloseAndRefresh} 
                />
            </div>
            <div 
                className={`pop-up-meeting-edition-change-register-container ${showRegistration === true ? 'show' : ''}`}
                style={styles.registrationContainer}    
            >
                <PopUpMeetingOccurrenceRegister 
                    modal={false}
                    meetingId={desiredMeeting.id}
                    onRegisterMeetingOccurrence={handleOnChange}
                    onClickClose={handleOnClickCloseAndRefresh} 
                />
            </div>
        </PopUpModal>
    )
}

export default PopUpMeetingEditionChange