import { useState, useEffect } from 'react';
import { I18N_AVAIL_LOCALECODE_ARRAY } from '../../../i18n';
import { useTranslation } from 'react-i18next';
import { IconButton, Typography } from '@material-ui/core';
import LanguageIcon from '@mui/icons-material/Language';
import './languageMenu.css'
import { getLocales } from '../../services/staticContentServices';
import { getLanguageIdOrNull, getCountryCodeOrNull } from '../../aux/sessionHelpers';
import { textTransform } from '../../aux/aux';
import PopUpEditLanguage from '../PopUpEditLanguage/PopUpEditLanguage';
import PopUpModal from '../PopUpModal/PopUpModal';
import PopUpModalHeader from '../PopUpModalHeader/PopUpModalHeader';
import { useLocaleCtx } from '../../customHooks/useLocaleCtx';

const LanguageMenu = ({ user, iconStyle, labelStyle }) => {
    const { t } = useTranslation();
    const localeCtx = useLocaleCtx();
    const [showModal, setShowModal] = useState(false);
    const handleOnShowModal = (e) => {
        setShowModal(!showModal);
    }
    return (
        <>
            <PopUpEditLanguage showModal={showModal} onClickClose={handleOnShowModal} user={user} />
            <div className='language-menu-container' onClick={handleOnShowModal}>
                <LanguageIcon className='language-menu-icon'  style={{ ...iconStyle }} />
                <Typography className='language-menu-text' variant='body2' style={{ ...labelStyle }} >
                    {`${textTransform('title',t(getLanguageIdOrNull(localeCtx.localeCode)))} (${textTransform('title',t(getCountryCodeOrNull(localeCtx.localeCode)))})`}
                </Typography>
            </div>
        </>
    )
}

export default LanguageMenu