import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { TextField, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { getFromSafeObject, isEmptyObject, textTransform } from '../../aux/aux';

import './contactSupportFormFields.css';
import { isUserLogged } from '../../aux/sessionHelpers';

const ContactSupportFormFields = ({ resetForm, isDisabled, onUpdateForm=()=>{}, user=null, categoryObject={} }) => {
    const { t } = useTranslation('common', { keyPrefix: 'contactSupportFormFields' });
    const MAX_MESSAGE_CHAR_LENGTH = 255;
    const CATEGORY_DEFAULT_KEY = 'admissions'
    const CATEGORY_MAP = {
        'admissions': 'admissions',
        'contentsAndPrograms': 'contents and programs',
        'productsAndPrices': 'products and prices',
        'subscriptions': 'subscriptions',
        'accountActivation': 'account activation',
        'refund': 'refund',
        // 'technicalSupport': 'technical support',
        'other': 'other',
    }
    const {
        defaultKey= CATEGORY_DEFAULT_KEY,
        categoryArray = Object.keys(CATEGORY_MAP)
    } = categoryObject
    const getSupportType = (categoryKey) => {
        let type;
        if(categoryKey === 'technicalSupport'){
            type = 'technical'
        }else{
            type = 'faq'
        }
        return type
    }
    const defaultForm = { 
        categoryKey: defaultKey, 
        category: CATEGORY_MAP[defaultKey], 
        type: getSupportType(defaultKey), 
    };
    const theme = useTheme();
    const [form, setForm] = useState(defaultForm);

    const styles = {
        groupTitle: {
            fontWeight:'bold',
            paddingBottom:'8px'
        },
        remainingCounter: {
            color: theme.palette.grey[500], //'#888',
            textAlign: 'right',
            padding: '0px 10px'
        }
    }

    const clearForm = () => {
        setForm(defaultForm)
    }

    const handleOnChange = (e) => {
        e.preventDefault();
        const field = e.target.name;
        const value = e.target.value;
        if(field !== 'message' || value.length <= MAX_MESSAGE_CHAR_LENGTH){
            setForm(prev => {
                const newForm = { ...prev }
                if(value === '' || value == null){
                    if(field === 'categoryKey'){
                        delete newForm['category'];
                        delete newForm['type']
                    }
                    delete newForm[field];
                }else{
                    if(field === 'categoryKey'){
                        newForm['category'] = CATEGORY_MAP[value];
                        newForm['type'] = getSupportType(value);
                    }
                    newForm[field] = value;
                }
                return { ...newForm }
            })
        }
    }

    useEffect(() => {
        if(resetForm){
            clearForm();
        }
    },[resetForm])

    useEffect(() => {
        const userId = getFromSafeObject(user, 'basicData.id');
        if(userId){
            setForm(prev => {
                const { name, email, ...newForm } = prev;
                return { ...newForm, userId }
            })
        }else{
            setForm(prev => {
                const { userId, ...newForm } = prev;
                return newForm
            })
        }
    },[user])

    useEffect(() => {
        const {categoryKey, ...requestedForm} = form
        onUpdateForm(requestedForm)
    },[form])
    return (
        <div className='contact-support-form-fields-wrapper'>
            <div className='contant-support-form-fields-main-container'>
                {!isUserLogged(user) &&
                    <div className='contact-support-form-fields-fields-group'>
                        <Typography variant='body1' style={styles.groupTitle}>
                            {textTransform('title', t('contactData'))}
                        </Typography>
                        <div className='contact-support-form-fields-field'>
                            <TextField 
                                name='name'
                                label={textTransform('title', t('name'))}
                                value={form.name || ''}
                                onChange={handleOnChange}
                                type="text"
                                inputProps={{style:{textOverflow:'ellipsis'}}}
                                InputLabelProps={{ shrink: form.name !== undefined && form.name !== '' }}
                                variant='standard'
                                color='primary'
                                fullWidth
                                disabled={isDisabled}
                                required
                            />
                        </div>
                        <div className='contact-support-form-fields-field'>
                            <TextField 
                                name='email'
                                label={textTransform('title', t('email'))}
                                value={form.email || ''}
                                onChange={handleOnChange}
                                type="email"
                                inputProps={{style:{textOverflow:'ellipsis'}}}
                                InputLabelProps={{ shrink: form.email !== undefined && form.email !== '' }}
                                variant='standard'
                                color='primary'
                                fullWidth
                                disabled={isDisabled}
                                required
                            />
                        </div>
                    </div>
                }
                <div className='contact-support-form-fields-fields-group'>
                    <Typography variant='body1' style={styles.groupTitle}>
                        {textTransform('title', t('selectACategory'))}
                    </Typography>
                    <div className='contact-support-form-fields-field'>
                        <TextField 
                            name='categoryKey'
                            value={form.categoryKey}
                            onChange={handleOnChange}
                            sel
                            select
                            inputProps={{style:{textOverflow:'ellipsis'}}}
                            variant='standard'
                            color='primary'
                            disabled={isDisabled}
                            fullWidth
                            required
                        >
                            {categoryArray.map( (key, index) => {
                                let name = t(key);
                                return(
                                    <MenuItem key={index} value={key}>
                                        <Typography variant='body1' className='contact-support-form-filed-category-item'>
                                            {textTransform('title', name)}
                                        </Typography>
                                    </MenuItem>
                                )
                            })}
                        </TextField>
                    </div>
                </div>
                <div className='contact-support-form-fields-fields-group'> 
                    <Typography variant='body1' style={styles.groupTitle}>
                        {t('howCanWeHelpYou')}
                    </Typography>
                    <div className='contact-support-form-fields-field'>
                        <Typography variant='body2' style={ styles.remainingCounter}>
                            {`${MAX_MESSAGE_CHAR_LENGTH - (form.message?.length || 0)}`}
                        </Typography>
                        <TextField 
                            name='message'
                            value={form.message || ''}
                            placeholder={`${textTransform('title', t('iWouldLikeToAsk'))} ...`}
                            onChange={handleOnChange}
                            type="text"
                            multiline
                            minRows={4}
                            maxRows={4}
                            variant='outlined'
                            color='primary'
                            required
                            disabled={isDisabled}
                            fullWidth
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactSupportFormFields