import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/styles';

import { isUserLogged, sessionVariables, setStorage } from '../../aux/sessionHelpers';

import { Grid, Typography, Divider, Button, Box } from '@material-ui/core'

import CreateAccountForm from '../CreateAccountForm/CreateAccountForm';
import LogInForm from '../LogInForm/LogInForm';
import BackButton from '../BackButton/BackButton';

import { isNotEmptyObject, textTransform } from '../../aux/aux';

import './logInSignIn.css'
import PopUpForgetPassword from '../PopUpForgetPassword/PopUpForgetPassword';
import VerifyAccountForm from '../VerifyAccountForm/VerifyAccountForm';
import LoadingComponent from '../LoadingComponent/LoadingComponent';


const LogInSignIn = ({ addMessages, onRender=()=>{}, onLogin=async()=>{}, setProcessStep=null, passiveVerification=true, resetComponent=false }) => {
    const theme = useTheme();
    const { t } = useTranslation("common", { keyPrefix: 'logInSignIn'});
    const statusTypes = ['logInSignIn', 'verifyAccount', 'signIn', 'forgotPass'];
    const [status, setStatus] = useState(statusTypes[0]);
    const [valuesToLogin, setValuesToLogin] = useState(null);
    const [isLoadingLogIn, setIsLoadingLogIn] = useState(false);
    const [needsVerification, setNeedsVerification] = useState(false);

    const getTitleOrSubtitleString = (isSubtitle=false) => {
        let str = '';
        switch(status){
            case statusTypes[0]:
                str = isSubtitle ? t("welcome") : t("login"); 
                break;
            case statusTypes[1]:
                str = isSubtitle ? t("validateYourEmail") : t("verifyAccount"); 
                break;
            case statusTypes[2]:
                str = isSubtitle ? t("itIsEasyAndFast") : t("createYourAccount");
                break;
            case statusTypes[3]:
                str = isSubtitle ? t("setANewPassword") : t("restablishingPassword");
                break;
        }
        return textTransform('title', str);
    }

    const areCompleteValuesToLogIn = (valuesToLogin) => {
        return isNotEmptyObject(valuesToLogin) && valuesToLogin.email != null && valuesToLogin.password != null;
    }

    const handleOnCreateUser = (valuesToLogin) => {
        setValuesToLogin(valuesToLogin)
    }

    const handleIsLoadingLogIn = (isLoading) => {
        setIsLoadingLogIn(isLoading)
    }

    const handleOnVerifyAccount = (email, password) => {
        setNeedsVerification(true);
        setValuesToLogin({email, password})
    }

    const handleOnVerifiedUserAccount = () => {
        setNeedsVerification(false);
    }

    const handleOnClick = (e, buttonName) => {
        switch(buttonName){
            case 'signIn':
                setStatus(statusTypes[2]);
                break;
            case 'forgotPass':
                setStatus(statusTypes[3]);
                break;
        }
    }

    const handleOnBack = (e) => {
        if(status === statusTypes[1]){
            setNeedsVerification(false);
            setValuesToLogin(prev => ({ email:prev.email }))
        }
        setStatus(statusTypes[0]);
    }

    useEffect(() => {
        if(areCompleteValuesToLogIn(valuesToLogin)){
            let nextStatus;
            if(needsVerification){
                nextStatus = statusTypes[1];
            }else{
                nextStatus = statusTypes[0];
            }
            setStatus(nextStatus);
        }
    },[valuesToLogin, needsVerification])

    useEffect(() => {
        if(setProcessStep){
            setProcessStep(status)
        }
    }, [status])

    useEffect(() => {
        if(resetComponent){
            setStatus(prev => (null));
            const timeoutId = setTimeout(() => setStatus(prev => (statusTypes[0])), 0);
            return(() => clearTimeout(timeoutId));
        }
    }, [resetComponent])

    useEffect(() => {
        onRender();
    })

    const getComponent = () => {
        switch(status){
            case statusTypes[0]:
                return(
                    <div className='login-signin-container-animation'>
                        <Grid container direction="column" alignItems='center'>
                            <Grid item style={{width:'100%'}}>
                                <LogInForm 
                                    onLogin={onLogin} 
                                    formValues={valuesToLogin} 
                                    autoLogin={areCompleteValuesToLogIn(valuesToLogin)} 
                                    isLoading={handleIsLoadingLogIn}
                                    onRender={onRender}
                                    onNoVerifiedAccount={handleOnVerifyAccount}
                                />
                            </Grid>
                            <Grid className='login-signin-item' item>
                                <Typography 
                                    variant="body2"
                                    className={`login-signin-link ${isLoadingLogIn ? 'disabled' : ''}`}
                                    onClick={e => {handleOnClick(e, 'forgotPass')}}
                                    style={{color:theme.palette.primary.main}}
                                >
                                    {t("didYouForgetYourPass")}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider variant="middle" />
                        <Grid container direction="column" alignItems="center">
                            <Grid item style={{padding:'20px 0px'}}>
                                <Button 
                                    variant="outlined" 
                                    disabled={isLoadingLogIn}
                                    color="secondary" 
                                    onClick={ e => {handleOnClick(e,'signIn')}}
                                >
                                    {textTransform('capitalize', t("signUp"))}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                )
            case statusTypes[1]:
                return(
                    <Grid container className='login-sigin-container-animation'>
                        <Grid item>
                            <VerifyAccountForm 
                                account={valuesToLogin} 
                                onRender={onRender} 
                                onVerified={handleOnVerifiedUserAccount}
                                passiveVerification={passiveVerification}
                            />
                        </Grid>
                    </Grid>
                )
            case statusTypes[2]:
                return(
                    <Grid container className='login-signin-container-animation'>
                        <Grid item >
                            <CreateAccountForm onCreate={handleOnCreateUser} onRender={onRender} />
                        </Grid>
                    </Grid>
                )
            case statusTypes[3]:
                return(
                    <Grid className='login-signin-container-animation' container>
                        <Grid item>
                            <PopUpForgetPassword modal={false} onRender={onRender} />
                        </Grid>
                    </Grid>
                )
            default:
                return(null)
        }
    };


    return (
        <Box>
            {status ?
                    <>
                        <Grid container direction="column">
                            <Grid  item >
                                <Grid container justifyContent='space-between'>
                                    <Grid item>
                                        <Typography variant="h5">
                                            <Box fontWeight="bold">
                                                {getTitleOrSubtitleString()}
                                            </Box>
                                        </Typography>
                                        <Typography variant='body2'>
                                            {getTitleOrSubtitleString(true)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider style={{margin:"10px 0px"}} />
                        { status !== statusTypes[0] && status !== statusTypes[4]  &&
                            <Grid item>
                                <BackButton isDisabled={false} onClick={handleOnBack} />
                            </Grid>
                        }
                        {getComponent()}
                    </>
                :
                    <LoadingComponent visibleElements='circle'/>
            }
        </Box>
    )
}

export default LogInSignIn
