import { useMemo } from 'react';
import { useTheme } from '@material-ui/styles';
import ProfessorHero from '../ProfessorHero/ProfessorHero'
import './contentProfessors.css'
import { Typography, useMediaQuery } from '@material-ui/core';
import { isNotEmptyObject } from '../../aux/aux';

const ContentProfessors = ({ 
    title, 
    subtitle, 
    noLeadSubtitle, 
    professorArray=[], 
    professorImgStyle={}, 
    leadProfessorsContainerStyle={}, 
    noLeadProfessorsContainerStyle={}, 
    leadProfessorContainerStyle={},
    noLeadProfessorPaperStyle={}, 
    noLeadSubtitleStyle={},
    noVerticalPadding=false
}) => {
    const theme = useTheme();
    const matchMobile = useMediaQuery('(max-width:680px');
    const professorArrayTypeMap = useMemo(() => {
        return professorArray.reduce((professorMap, professor) => {
            const type = professor.isMain ? 'main' : 'secondary';
            professorMap[type].push(professor);
            return professorMap;
        },{main:[], secondary:[]})
    },[professorArray]) 

    const styles = {
        title: {
            fontWeight: 'bold',
            textAlign: 'center'
        },
        subtitle: {
            fontWeight: 'bold'
        },
        noLeadSubtitle:{
            fontWeight: 'bold',
            ...noLeadSubtitleStyle
        },
        bottomSeparator: {
            borderLeft: '100vw solid transparent',
            borderBottom: `72px solid ${theme.palette.surface.light[1]}`,
        },
        topSeparator: {
            borderRight: '100vw solid transparent',
            borderTop: `72px solid ${theme.palette.surface.light[1]}`,
        }
    }
    return (
        isNotEmptyObject(professorArrayTypeMap) ?
                <div className={`content-professors-main-container${noVerticalPadding ? ' no-vertical-padding' : ''}`}>
                    {professorArrayTypeMap.main.length > 0 ?
                            <>
                                {title ?
                                        <div className='content-professors-title-container max-width'>
                                            <Typography variant={matchMobile ? 'h5' : 'h4'} style={styles.title}>
                                                {title}
                                            </Typography>
                                        </div>
                                    :
                                    null
                                }
                                {subtitle ?
                                        <div className='content-professors-subtitle-container max-width'>
                                            <Typography variant={matchMobile ? 'h6' : 'h5'} style={styles.subtitle}>
                                                {subtitle}
                                            </Typography>
                                        </div>
                                    :
                                        null
                                }
                                <div className='content-professors-lead-professors-container max-width' style={leadProfessorsContainerStyle}>
                                    {professorArrayTypeMap.main.map((professor, index) => {
                                        const imgLast = index % 2 === 0
                                        return(
                                            <div key={index} className='content-professors-lead-content-professor-container'>
                                                <ProfessorHero 
                                                    professor={professor} 
                                                    isLead={true} 
                                                    imgLast={imgLast} 
                                                    style={leadProfessorContainerStyle}
                                                    imgStyle={professorImgStyle}
                                                />
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </>
                        :
                            null
                    }
                    {professorArrayTypeMap.secondary.length > 0 ?
                            <div className='content-professors-no-lead-professors-wrapper' style={noLeadProfessorsContainerStyle}>
                                {noLeadSubtitle &&
                                    <div className='content-professors-no-lead-subtitle-container max-width'>
                                        <Typography variant={matchMobile ? 'h6' : 'h5'} style={styles.noLeadSubtitle}>
                                            {noLeadSubtitle}
                                        </Typography>
                                    </div>
                                }
                                <div className='content-professors-no-lead-professors-container max-width'>
                                    {professorArrayTypeMap.secondary.map((professor, index) => {
                                        return(
                                            <div key={index} className='content-professors-content-professor-container no-lead'>
                                                <ProfessorHero 
                                                    professor={professor} 
                                                    isLead={false} 
                                                    imgLast={false} 
                                                    // style={noLeadProfessorContainerStyle} 
                                                    noLeadPaperStyle={noLeadProfessorPaperStyle}
                                                    imgStyle={professorImgStyle}
                                                />
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        :
                            null
                    }
                </div>
            :
                null
    )
}

export default ContentProfessors