import { useTheme } from "@material-ui/styles"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomChip from "../CustomChip/CustomChip";
import SubscriptionIcon from "../SubscriptionIcon/SubscriptionIcon";

const ConsumabilityStatusChip = ({ consumabilityStatus, size, variant }) => {
    const theme = useTheme();
    const { t } = useTranslation('common', { keyPrefix: 'consumabilityStatusChip' });
    const [labelKey, setLabelKey] = useState(undefined);
    const [Icon, setIcon] = useState(undefined);
    const colors = {
        exclusive: theme.palette.primary.main
    }
    const styles = {
        icon:{
            exclusive:{
                color: theme.palette.primary.main
            }
        },
        chip:{
            exclusive:{
                backgroundColor: theme.palette.common.white
            }
        }
    }
    useEffect(() => {
        if(consumabilityStatus){
            let labelKey;
            let Icon;
            if(consumabilityStatus === 'subscriptable'){
                labelKey = 'exclusive';
                Icon = < SubscriptionIcon style={styles.icon[labelKey]} />
            }else if(consumabilityStatus === 'purchasable'){
                // NONE
            }
            setIcon(Icon);
            setLabelKey(labelKey);
        }
    }, [consumabilityStatus])
    return (
        labelKey !== undefined ?
            <CustomChip
                size={size || 'small'}
                variant={variant || 'outlined'}
                label={t(labelKey)}
                color={colors[labelKey]}
                style={styles.chip[labelKey]}
                icon={ Icon }
            />
            :
            null
    )
}

export default ConsumabilityStatusChip