import { useEffect, useState } from 'react'
import Notes from '@mui/icons-material/Notes';
import List from '@mui/icons-material/List';
import {  Button, Grid, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import ClassSideBarMenu from '../ClassSideBarMenu/ClassSideBarMenu'
import ClassSideBar from '../ClassSideBar/ClassSideBar'
import ClassNotes from '../ClassNotes/ClassNotes'

import './classroomSideColumn.css'
import { useTranslation } from 'react-i18next';


const ClassroomSideColumn = ({ 
    userConfig, userProgress, userAccessDetails,contentStructure, contentSelected, setContentSelected
}) => {
    const { t } = useTranslation('common')
    const matchDesktop = useMediaQuery('(min-width:1120px)');
    const SIDE_COLM_HEADER_HEIGHT = 60;
    const SIDE_COLM_BAR_PADDING = 10;
    const theme = useTheme();
    const [sideColumnConfig, setSideColumnConfig] = useState({ selectedMenuItemId:'contentIndex' })

    const isDisabledMenuButton = ( withNoLessonSelected=false ) => {
        const isLessonSelected = (contentSelected) => {
            // currentLessonId is 0 when overview is selected
            // Notice that Boolean(null || undefined) = false
            return Boolean(contentSelected && contentSelected.currentLessonId)
        }
        const passLessonSelectionControl = withNoLessonSelected ? isLessonSelected(contentSelected) : true;
        return userAccessDetails?.hasAccess !== true || !passLessonSelectionControl 
    }

    const itemList = [
        {
            Icon: List,
            id: 'contentIndex',
            label: t('index'),
            isDisabled: isDisabledMenuButton()
        },
        {
            Icon: Notes,
            id: 'userNotes',
            label: t('note', {count: 2}),
            isDisabled: isDisabledMenuButton(true)
        },
    ]
    const styles = {
        mainContainer:{
        },
        headerContainer:{
            height: `${SIDE_COLM_HEADER_HEIGHT}px`,
        },
        sideBarContainer:{
            height: matchDesktop ? `calc(100% - ${SIDE_COLM_HEADER_HEIGHT}px - ${SIDE_COLM_BAR_PADDING}px)` : '100%',
            width: `100%`,
            backgroundColor: theme.palette.background.default,
            border: `solid 1px ${theme.palette.grey[100]}`,
            borderRadius: '4px'
        },
        selectedBarItemWrapper:{
            opacity: '1',
            paddingLeft: '0px',
            pointerEvents: 'initial',
            transitionDelay: '0.3s',
            // backgroundColor: theme.palette.background.default,
            // border: `solid 1px ${theme.palette.grey[100]}`,
            // borderRadius: '4px'
        }
    }

    const handleOnSelectedMenuItem = (itemId) => {
        setSideColumnConfig(prev => ({ ...prev, selectedMenuItemId:itemId }))
    }

    return (
        <div className='classroom-side-column-main-container' style={styles.mainContainer}>
            <div className='classroom-side-column-header-container' style={styles.headerContainer}>
                <ClassSideBarMenu 
                    contentSelected={contentSelected} 
                    userAccessDetails={userAccessDetails} 
                    onSelectItem={handleOnSelectedMenuItem} 
                    itemList={itemList}
                    selectedItemId={sideColumnConfig.selectedMenuItemId}
                />
            </div>
            <div className='classroom-side-column-bar-container' style={styles.sideBarContainer}>
                <div className='classroom-side-column-bar-item-wrapper' style={ sideColumnConfig.selectedMenuItemId === 'contentIndex' ? styles.selectedBarItemWrapper : {} }>
                    <ClassSideBar contentStructure={contentStructure} contentSelected={contentSelected} 
                    setContentSelected={setContentSelected} userProgress={userProgress} markerPlace='after' />
                </div>
                <div className='classroom-side-column-bar-item-wrapper' style={ sideColumnConfig.selectedMenuItemId === 'userNotes' ? styles.selectedBarItemWrapper : {}}>
                    <ClassNotes userConfig={userConfig} contentSelected={contentSelected} isShown={sideColumnConfig.selectedMenuItemId === 'userNotes'} />
                </div>
            </div>
        </div>
    )
}

export default ClassroomSideColumn