import { useEffect, useMemo, useState } from 'react';
import { getFromSafeObject } from '../../aux/aux';
import './productConsumptionTypeOptionGroup.css'

const ProductConsumptionTypeOptionGroup = ({productConsumptionType, userProductPurchasabilityDetailsMap, propPathStringToGroupBy, GroupItemComponent, isSelectedProductConsumptionType, selectedOption, onSelectOption}) => {
    const [selectedGroupedProductConsumptionTypeIndex, setSelectedGroupedProductConsumptionTypeIndex] = useState(null);
    const groupedProductConsumptionType = useMemo(() => {
        let result;
        if(productConsumptionType && productConsumptionType.productConsumptionOptionArray.length > 0){
            if(propPathStringToGroupBy){
                const recommendedProductConsumptionOptionIndex = productConsumptionType.productConsumptionOptionDefaultIndex;
                const productConsumptionTypeArray = []
                const groupIdIndexMap = {};
                const groupBestPrice = {};
                let recommendedGroupId;
                productConsumptionType.productConsumptionOptionArray.forEach((productOption, index) => {
                    const finalAmount = getFromSafeObject(productOption, 'consumptionDetails.details.purchasabilityDetails.details.amount.finalAmount')
                    const groupId = getFromSafeObject(productOption, propPathStringToGroupBy);
                    if(groupId){
                        let groupedProductConsumptionOption;
                        if(groupIdIndexMap.hasOwnProperty(groupId)){
                            const groupIdIndex = groupIdIndexMap[groupId];
                            groupedProductConsumptionOption = productConsumptionTypeArray[groupIdIndex];
                            if(groupBestPrice[groupId] > finalAmount){
                                groupBestPrice[groupId] = finalAmount;
                                groupedProductConsumptionOption.productConsumptionOptionBestPriceIndex = groupedProductConsumptionOption.productConsumptionOptionArray.length;
                            }
                            groupedProductConsumptionOption.productConsumptionOptionArray.push(productOption);
                        }else{
                            groupedProductConsumptionOption = {
                                productConsumptionOptionArray: [productOption],
                                productConsumptionOptionBestPriceIndex: 0,
                                productConsumptionOptionDefaultIndex: undefined
                            };
                            groupIdIndexMap[groupId] = productConsumptionTypeArray.length;
                            groupBestPrice[groupId] = finalAmount;
                            productConsumptionTypeArray.push(groupedProductConsumptionOption);
                        }
                        if(recommendedGroupId === undefined && index === recommendedProductConsumptionOptionIndex){
                            recommendedGroupId = groupId;
                            groupedProductConsumptionOption.productConsumptionOptionDefaultIndex = groupedProductConsumptionOption.productConsumptionOptionArray.length - 1;
                        }
                    }
                })
                result = {
                    productConsumptionTypeArray,
                    productConsumptionTypeDefaultIndex: groupIdIndexMap[recommendedGroupId]
                }
            }else{
                result = {
                    productConsumptionTypeArray: [productConsumptionType],
                    productConsumptionTypeDefaultIndex: productConsumptionType.productConsumptionOptionDefaultIndex !== undefined ? 0 : undefined, 
                };
            }
        }
        return result
    },[productConsumptionType])

    const handleOnSelectGroupProductConsumptionType = (groupedIndex) => {
        setSelectedGroupedProductConsumptionTypeIndex(groupedIndex);
    }

    const handleOnSelectedProductConsumptionOption = (selectedOption) => {
        if(typeof onSelectOption === 'function'){
            onSelectOption(selectedOption)
        }
    }

    useEffect(() => {
        if(isSelectedProductConsumptionType){
            if(selectedGroupedProductConsumptionTypeIndex === null){
                let defaultGroupedProductConsumptionTypeIndex = groupedProductConsumptionType?.productConsumptionTypeDefaultIndex || null
                const isUnique = (groupedProductConsumptionType?.productConsumptionTypeArray || []).length === 1;
                if(defaultGroupedProductConsumptionTypeIndex === null && isUnique){
                    defaultGroupedProductConsumptionTypeIndex = 0;
                }
                handleOnSelectGroupProductConsumptionType(defaultGroupedProductConsumptionTypeIndex)
            }
        }else{
            handleOnSelectGroupProductConsumptionType(null);
        }
    },[isSelectedProductConsumptionType])

    return (
        groupedProductConsumptionType && groupedProductConsumptionType.productConsumptionTypeArray.length > 0 ?
                <div className='product-consumption-type-option-group-options-main-container'>
                    {groupedProductConsumptionType.productConsumptionTypeArray.map((productConsumptionType, index) =>{
                        const isUnique = groupedProductConsumptionType.productConsumptionTypeArray.length === 1;
                        const isDefault = groupedProductConsumptionType.productConsumptionTypeDefaultIndex === index;
                        const isGroupSelected = isSelectedProductConsumptionType && selectedGroupedProductConsumptionTypeIndex === index;
                        return (
                            <GroupItemComponent 
                                key={index}
                                groupItemIndex={index}
                                productConsumptionType={productConsumptionType} 
                                userProductPurchasabilityDetailsMap={userProductPurchasabilityDetailsMap}
                                selectedItem={selectedOption}
                                isSelected={isGroupSelected}
                                isSelectedProductConsumptionType={isSelectedProductConsumptionType}
                                isRecommended={isDefault} 
                                isUnique={isUnique}
                                onSelectedGroup={handleOnSelectGroupProductConsumptionType} 
                                onSelectedItem={handleOnSelectedProductConsumptionOption}
                            />
                        )
                    })}
                </div>
            :
                null
    )
}

export default ProductConsumptionTypeOptionGroup