import { useContext } from "react";
import { HeaderPropsContext } from "../customContext/headerPropsProvider";

export function useHeaderPropsCtx(){
    const ctx = useContext(HeaderPropsContext);
    if(ctx == null){
        throw new Error("useHeaderPropsCtx must be used within the headerPropsProvider context")
    }
    return ctx
}
