import { Grid, MenuItem, TextField, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react'
import { isEmptyObject } from '../../aux/aux'
import SearchLocationInput from '../SearchLocationInput/SearchLocationInput'
import './billingAddressEditForm.css'

const BillingAddressEditForm = ({ selectedCountryCode, states, setFormValues }) => {
    const [disabled, setDisabled] = useState(true);
    const { t } = useTranslation('common', { keyPrefix: 'billingAddressEditForm' });
    const [hideSearchLocationInput, setHideSearchLocationInput] = useState(false);
    const [billingAddressForm, setBillingAddressForm] = useState({
        line: '',
        city: '',
        stateCode: '',
        zipCode: '',
    })
    const onChange = (e) => {
        e.preventDefault();
        setBillingAddressForm((billingAddressForm) => ({ ...billingAddressForm, [e.target.name]: e.target.value}));
    }

    const handleOnErrorInSearchLocationInput = () => {
        setHideSearchLocationInput(true);
        setDisabled(false);
    }

    const setAutoComplete = (addressObject, countryStates=states) => {
        const newAddressForm = {};
        let alternativeCityValue;
        Object.keys(billingAddressForm).forEach(key => newAddressForm[key] = '');
        addressObject.address_components.forEach( component => {
            switch(component.types[0]){
                case 'postal_code':
                    newAddressForm.zipCode = component.short_name;
                    break;
                case 'route':
                    newAddressForm.line = component.short_name;
                    break;
                case 'administrative_area_level_2':
                    alternativeCityValue = component.long_name;
                    break;
                case 'locality':
                    newAddressForm.city = component.long_name;
                    break;
                case 'administrative_area_level_1':
                    if(!isEmptyObject(countryStates) && countryStates.statesArray.includes(component.short_name)){
                        newAddressForm.stateCode = component.short_name;
                    }
                    break;
            }
        })
        if(!newAddressForm.city && alternativeCityValue){
            newAddressForm.city = alternativeCityValue;
        }
        setBillingAddressForm(newAddressForm);
        setDisabled(false);
    };

    useEffect(() => {
        setFormValues({...billingAddressForm});
    },[billingAddressForm])

    return (
        <div className='billing-address-form-main-container' >
            <Grid container direction='column'>
                <Grid item>
                    <SearchLocationInput 
                        callback={setAutoComplete} 
                        placeholder={t('enterYourAddress')} 
                        queryOptions={{ 
                            types: ["address"], 
                            componentRestrictions: { country: selectedCountryCode } 
                        }} 
                        onError={handleOnErrorInSearchLocationInput}
                        className={`${'billing-address-form-search-location-input'} ${hideSearchLocationInput ? 'hide' : ''}`}
                    />
                </Grid>
                <Grid item>
                    <TextField className='billing-address-form-input' label={t('streetAddress')} name="line" value={billingAddressForm.line}
                    onChange={onChange} disabled={disabled} required fullWidth inputProps={{style:{
                        textOverflow:'ellipsis'}}}/>
                </Grid>
                <Grid item>
                    <TextField className='billing-address-form-input' label={t('city')} name="city" value={billingAddressForm.city} 
                    onChange={onChange} disabled={disabled} required fullWidth inputProps={{style:{
                        textOverflow:'ellipsis'}}} />
                </Grid>
                <Grid item>
                    <Grid container direction='row' justifyContent='space-between'>
                        <Grid item xs={5}>
                            <TextField className='billing-address-form-input' label={t('stateOrProvince')} name="stateCode" value={billingAddressForm.stateCode} 
                            onChange={onChange} disabled={disabled || (!disabled && selectedCountryCode !== 'US' && selectedCountryCode !== 'CA')} select required={selectedCountryCode === 'US' || selectedCountryCode === 'CA'} fullWidth >
                                <MenuItem key={'none'} value={null}>
                                    {t('common:none')}
                                </MenuItem>
                                {states.statesArray.map( stateCode => {
                                    return(
                                        <MenuItem key={stateCode} value={stateCode}>
                                            {stateCode}
                                        </MenuItem>
                                        )
                                })}
                            </TextField>
                        </Grid>
                        <Grid item xs={5}>
                            <TextField className='billing-address-form-input' label={t('zipCode')} name="zipCode" value={billingAddressForm.zipCode} 
                            onChange={onChange} disabled={disabled} required />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default BillingAddressEditForm