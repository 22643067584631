import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Box, Typography, Paper } from '@material-ui/core'
import { Redirect } from 'react-router-dom'
import { requestMinimumTimeoutWrapper } from '../../aux/requestMethods';
import { getReactivateSubscriptionCheckoutIdFn, createReactivateSusbcriptionCheckoutFn, resetReactivateSubscriptionCheckoutFn } from '../../aux/purchaseQueries';
import { getUserSubscriptionFn } from '../../aux/userQueries';
import './checkSubscriptionStatus.css'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import Emoji from '../../components/Emoji/Emoji';
import { getReliableLocale, sessionVariables } from '../../aux/sessionHelpers';
import { getFromSafeObject, textTransform } from '../../aux/aux';
import { getUserSubscription } from '../../services/userServices';
import SmallFooter from '../../components/SmallFooter/SmallFooter';
import { getCustomErrorOrUndefined } from '../../aux/errorHelpers';

const CheckSubscriptionStatus = ({ user }) => {
    const { t } = useTranslation('checkSubscriptionStatus');
    const [subscriptionCheckoutId, setSubscriptionCheckoutId] = useState(''); // id could be string or int 
    const checkSubscriptionStatusTypes = ['deactive', 'active', 'checkoutNotOpen', 'noSubscription','error']
    const [status, setStatus] = useState('');
    const [subscription, setSubscription] = useState(undefined);
    const userLocaleCode = sessionVariables.get('userLocaleCode');
    const userLocaleId = sessionVariables.get('userLocaleId');
    const hasActiveSubscription = getFromSafeObject(user,'subscription.isActive');
    const redirectPathBase = '/app/checkout/';
    const checkSubscriptionStatusMap = {
        active:{
            title: textTransform('title', t('active')), 
            symbol: '❤️',
            msg: textTransform('title', t('yourSubscriptionIsActive'))
        },
        noSubscription:{
            title: textTransform('title', t('inactive')),
            symbol: '🙈',
            msg: textTransform('title', t("youDoNotHaveASubscription"))
        },
        checkoutNotOpen:{
            title: textTransform('title', t('reactivating')),
            symbol: '👌',
            msg: `${textTransform('title', t("weAreProcessingYourOrderToReactiveYourSubscription"))}\n${textTransform('title',t('thankYou'))}`

        },
        error: {
            title: textTransform('title', t('uups')),
            symbol: '🛸',
            msg: `${textTransform('title', t('connectionError'))}\n${textTransform('title', t('tryItLatter'))}`
        }
    };
    const getReactivateSubscriptionCheckoutIdOrUndefined = async (userId, signal=null) => {
        try{
            const requestParams = { userId };
            const res = await requestMinimumTimeoutWrapper(getReactivateSubscriptionCheckoutIdFn, requestParams, 0, signal);
            const data = res.data;
            return data.checkoutId;
        }catch(error){
            throw error;
        }
    }

    const createReactivateSubscriptionCheckout = async (userId, signal=null) => {
        try{
            const requestParams = {userId};
            const res = await requestMinimumTimeoutWrapper(createReactivateSusbcriptionCheckoutFn, requestParams, 0, signal);
            const data = res.data;
            return data.checkoutId;
        }catch(error){
            // TODO: implement
            if(error.response.data.name === 'CustomError'){
                switch(error.response.data.cause){
                    case 'db_103':
                        // There is no subscription to reactivate or no default billing address was defined
                        error.response.data.details = "noSubscriptionOrBillingAddress";
                        break;
                }
            }
            throw error;
        }
    }

    const resetReactivateSubscriptionCheckout = async (userId, checkoutId, signal=null) => {
        try{
            const requestParams = {userId, checkoutId};
            const res = await requestMinimumTimeoutWrapper(resetReactivateSubscriptionCheckoutFn, requestParams, 0, signal);
            const data = res.data;
            return data.checkoutId;
        }catch(error){
            if(error.response.data.name === 'CustomError'){
                switch(error.response.data.cause){
                    case 'db_103':
                        // There is no subscription to reactivate or no default billing address was defined
                        error.response.data.details = "noSubscriptionOrBillingAddress";
                        break;
                    case'sr_201':
                        // Checkout is not open
                        error.response.data.details = "checkoutNotOpen";
                        break;
                }
            }
            throw error
        }
    }
    
    const setReactivateSubscriptionCheckoutId = async (userId, signal) => {
        try{
            let checkoutId = await getReactivateSubscriptionCheckoutIdOrUndefined(userId, signal);
            if(checkoutId){
                // Reactivate subscription checkouts keeps their original state
                checkoutId = await resetReactivateSubscriptionCheckout(userId, checkoutId, signal);
            }else{
                checkoutId = await createReactivateSubscriptionCheckout(userId);
            }
            if(!signal.aborted){
                setSubscriptionCheckoutId(checkoutId);
                setStatus(checkSubscriptionStatusTypes[0]);
            }
        }catch(error){
            if(!signal.aborted){
                const customError = getCustomErrorOrUndefined(error);
                if(customError.details){
                    switch(customError.details){
                        case 'noSubscriptionOrBillingAddress':
                            setStatus(checkSubscriptionStatusTypes[3]);
                            break;
                        case 'checkoutNotOpen':
                            setStatus(checkSubscriptionStatusTypes[2])
                            break;
                    }
                }else{
                    setStatus(checkSubscriptionStatusTypes[4])
                }
            }
        }
    }

    const setUserSubscription = async (userId, userLocaleId, signal) => {
        try{
            const subscription = await getUserSubscription(userId, userLocaleId, signal);
            if(!signal.aborted){
                setSubscription(subscription.data);
                setStatus(checkSubscriptionStatusTypes[1]);
            }
        }catch(err){
            if(!signal.aborted){
                setStatus(checkSubscriptionStatusTypes[4])
            }
        }
    }

    useEffect(() => {
        const abortController = new AbortController();
        if(!hasActiveSubscription){
            setReactivateSubscriptionCheckoutId(user.basicData.id, abortController.signal);
        }else{
            setUserSubscription(user.basicData.id, userLocaleId, abortController.signal);
        }
        return(() => abortController.abort());
    },[hasActiveSubscription])

    const getStatusAction = (status) => {
        const text = checkSubscriptionStatusMap[status];
        let componentToRender;
        switch(status){
            case checkSubscriptionStatusTypes[0]:
                // Ready to checkout
                componentToRender = <Redirect to={`${redirectPathBase}${subscriptionCheckoutId}`} />
                break;
            case checkSubscriptionStatusTypes[1]:
                const subscriptionProduct = subscription.lastActiveUserSubscriptionProduct.subscriptionProduct;
            case checkSubscriptionStatusTypes[2]:
            case checkSubscriptionStatusTypes[3]:
            case checkSubscriptionStatusTypes[4]:
                // Show message
                componentToRender =
                    <Paper className='check-subscription-status-msg-container'>
                        <div>
                            <Grid item >
                                <Typography variant="h5">
                                    <Box fontWeight="bold" style={{ display: 'inline'}}>
                                        {`${text.title} `}
                                    </Box>
                                    <Emoji symbol={text.symbol}/>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography style={{whiteSpace:"pre-line"}}>
                                    {text.msg}
                                </Typography>
                            </Grid>
                        </div>
                        {subscription ?
                            <div>
                                <Typography variant='body2' >
                                    {`${textTransform('title', t('type'))} : ${subscriptionProduct.type.name}`}
                                </Typography>
                                <Typography variant='body2' >
                                    {`${textTransform('title', t('subscriptionLevel'))} : ${subscriptionProduct.type.level}`}
                                </Typography>
                                <Typography variant='body2' >
                                    {`${textTransform('title', t('endOfPeriod'))} : ${new Date(subscription.endsDate).toLocaleString(userLocaleCode)}`}
                                </Typography>
                            </div>
                            :
                            null
                        }
                    </Paper>
        }
        return componentToRender;
    }

    return (
        <div className='check-subscription-status-wrapper'>
            <div className="check-subscription-status-main-container">
                {status ?
                    getStatusAction(status)
                    :
                    <LoadingComponent />
                }
            </div>
            <SmallFooter onLightBackground={true} hideSocialLinks={true}/>
        </div>
    )
}

export default CheckSubscriptionStatus