import { hexToRgb } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import ContentDescription from '../ContentDescription/ContentDescription'
import ContentPreview from '../ContentPreview/ContentPreview'
import './contentPreviewAndDescription.css'

const ContentPreviewAndDescription = ({ contentPage, contentProductImgURI }) => {
    const theme = useTheme()
    const styles = {
        wrapper: {
            // backgroundImage: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0,0,0,0.2))',
            background: `linear-gradient(180deg, ${hexToRgb(contentPage.palette.primary.main)}, ${hexToRgb(theme.palette.background.dark)} 90%)`,
        }
    }
    return (
        <div className='content-preview-and-description-wrapper' style={styles.wrapper}>
            <div className='content-preview-and-description-main-container'>
                <ContentPreview contentPage={contentPage} noVerticalPadding={true} wrapperStyle={{}}/>
                <ContentDescription contentPage={contentPage} imgURI={contentProductImgURI} noVerticalPadding={true} wrapperStyle={{}}/>
            </div>
        </div>
    )
}

export default ContentPreviewAndDescription