/*
    This module contains a set of functions to handle the API queries related to user's content
*/
import { getLessonVersionExpRequestParameter, getSystemRequestParameter } from "./contentProductHelpers";
import { createAuthRequest } from "./requestMethods";

const getUserContentProductPreviewFn = async (params, signal=null) => {
    const {
        userId,
        query,
    } = params
    // const queryString = Object.entries(query).reduce((string, [key, value]) => {
    //     let append = ''
    //     if(string){
    //         append = `&${key}=${value}`;
    //     }else{
    //         append = `?${key}=${value}`;
    //     }
    //     return `${string}${append}`;
    // }, '');
    const userRequest = createAuthRequest();
    return userRequest.get(`/user-content/product-preview/user/${userId}`, { params:query, signal })
}

const getUserLessonNotesFn = async (params, signal=null) => {
    const {
        userId,
        productId,
        lessonId,
        lessonVersionId,
        filter
    } = params
    const query = { filter };
    const userRequest = createAuthRequest();
    return userRequest.get(`/user-content/get-content-product-lesson-notes/${userId}/${productId}/${lessonId}/${lessonVersionId}`, { params:query, signal: signal } );
}

const getUserContentProgressFn = async (params, signal=null) => {
    const {
        userId,
        productId,
        system,
        justCurrentSystem
    } = params;
    let systemParameter;
    if(system){
        systemParameter = getSystemRequestParameter(system);
    }   
    const userRequest = createAuthRequest();
    return userRequest.get(`/user-content/get-content-product-progress/${userId}/${productId}${systemParameter ? `/${systemParameter}`:''}`, { params:{ justCurrentSystem },  signal });
};

const getUserContentConfigFn = async (params) => {
    const {
        userId,
        productId
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.get(`/user-content/get-content-product-config/${userId}/${productId}`);
};

const getUserSectionProgressFn = async (params, signal) => {
    const {
        userId,
        productId,
        sectionId,
        system,
        query
    } = params
    const systemParameter = getSystemRequestParameter(system);
    const userRequest = createAuthRequest();
    return userRequest.get(`/user-content/get-content-product-section-progress/${userId}/${productId}/${sectionId}/${systemParameter}`, { params: query, signal });
}

const getUserLessonProgressFn = async (params) => {
    const {
        userId,
        productId,
        sectionId,
        lessonId,
        lessonVersionDecisor,
        query
    } = params
    const lessonVersionExpParameter = getLessonVersionExpRequestParameter(lessonVersionDecisor);
    const userRequest = createAuthRequest();
    return userRequest.get(`/user-content/get-content-product-lesson-progress/${userId}/${productId}/${sectionId}/${lessonId}/${lessonVersionExpParameter}`, { params: query });
}

const getUserLessonTypeProgressFn = async (params, signal=null) => {
    const {
        userId,
        productId,
        lessonId,
        lessonVersionId,
        lessonTypeName,
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.get(`/user-content/get-content-product-lesson-type-progress/${userId}/${productId}/${lessonId}/${lessonVersionId}/${lessonTypeName}`, { signal })
}

const getUserLessonActionProgressFn = async (params, signal=null) => {
    const {
        userId,
        productId,
        lessonId,
        lessonVersionId
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.get(`/user-content/get-content-product-lesson-action-progress/${userId}/${productId}/${lessonId}/${lessonVersionId}`, { signal })
}

const setUpUserSectionProgressFn = async (params, signal) => {
    const {
        userId,
        productId,
        sectionId,
    } = params;
    const section = {
        sectionId
    };
    const userRequest = createAuthRequest();
    return userRequest.post(`/user-content/set-up-content-product-section-progress/${userId}/${productId}`, { section }, { signal })
}

const setUpUserLessonProgressFn = async (params) => {
    const {
        userId,
        productId,
        sectionId,
        lessonId,
        lessonVersionId
    } = params;
    const lesson = {
        lessonId,
        lessonVersionId,
        sectionId
    };
    const userRequest = createAuthRequest();
    return userRequest.post(`/user-content/set-up-content-product-lesson-progress/${userId}/${productId}`, { lesson })
}

const setUpUserLessonTypeProgressFn = async (params, signal=null) => {
    const {
        userId,
        productId,
        lessonId,
        lessonVersionId,
        lessonTypeName
    } = params;
    const lesson = {
        lessonId,
        lessonVersionId,
        lessonTypeName
    };
    const userRequest = createAuthRequest();
    return userRequest.post(`/user-content/set-up-content-product-lesson-type-progress/${userId}/${productId}`, { lesson }, { signal });
}

const setUpUserLessonActionProgressFn = async (params, signal=null) => {
    const {
        userId,
        productId,
        lessonId,
        lessonVersionId,
    } = params;
    const lesson = {
        lessonId,
        lessonVersionId,
    };
    const userRequest = createAuthRequest();
    return userRequest.post(`/user-content/set-up-content-product-lesson-action-progress/${userId}/${productId}`, { lesson }, { signal });
}

const backUpUserConfigFn = async( params, signal=null ) => {
    const {
        userId,
        productId,
        updatedValues,
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.post(`/user-content/update-content-product-config/${userId}/${productId}`, { updatedValues }, { signal });
}

const backUpUserProgressFn = async( params ) => {
    const {
        userId,
        productId,
        contentIdsToUpdate,
        updatedValuesMap
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.post(`/user-content/update-content-product-progress/${userId}/${productId}`, { contentIdsToUpdate, updatedValuesMap });
}

const backUpUserLessonActionProgressFn = async( params, signal=null ) => {
    const {
        userId,
        productId,
        actionIdToUpdate, 
        updatedValues
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.post(`/user-content/update-content-product-lesson-action-progress/${userId}/${productId}`, { actionIdToUpdate, updatedValues }, { signal });
}

const backUpUserLessonTypeProgressFn = async( params, signal=null ) => {
    const {
        userId,
        productId,
        lessonTypeName,
        lessonIdsToUpdate,
        updatedValues
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.post(`/user-content/update-content-product-lesson-type-progress/${userId}/${productId}`, { lessonTypeName, lessonIdsToUpdate, updatedValues }, { signal });
}

const resyncUserProgressWithSystemFn = async( params, signal ) => {
    const {
        userId,
        productId,
        system
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.post(`/user-content/resync-content-product-progress/${userId}/${productId}`, { system }, { signal });
}

const createUserLessonNoteFn = async( params, signal=null ) => {
    const {
        userId,
        productId,
        lessonId,
        lessonVersionId,
        note
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.post(`/user-content/create-content-product-lesson-note/${userId}/${productId}/${lessonId}/${lessonVersionId}`, { note, signal: signal } );
}

const deleteUserLessonNoteFn = async( params, signal=null ) => {
    const {
        userId,
        noteId,
        noteTypeName
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.delete(`/user-content/remove-content-product-lesson-note/${userId}/${noteId}/${noteTypeName}`, { signal: signal });
}

const updateUserLessonNoteFn = async( params, signal=null ) => {
    const {
        userId,
        noteId,
        noteTypeName,
        updatedValues
    } = params;
    const userRequest = createAuthRequest();
    return userRequest.post(`/user-content/update-content-product-lesson-note/${userId}/${noteId}/${noteTypeName}`, { updatedValues, signal: signal });
}

export{
    setUpUserSectionProgressFn,
    setUpUserLessonProgressFn,
    setUpUserLessonTypeProgressFn,
    setUpUserLessonActionProgressFn,
    getUserContentProductPreviewFn,
    getUserLessonNotesFn,
    getUserContentConfigFn,
    getUserContentProgressFn,
    getUserSectionProgressFn,
    getUserLessonProgressFn,
    getUserLessonTypeProgressFn,
    getUserLessonActionProgressFn,
    backUpUserConfigFn,
    backUpUserProgressFn,
    backUpUserLessonActionProgressFn,
    backUpUserLessonTypeProgressFn,
    resyncUserProgressWithSystemFn,
    createUserLessonNoteFn,
    deleteUserLessonNoteFn,
    updateUserLessonNoteFn
}