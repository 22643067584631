import { useTheme } from '@material-ui/styles'
import { Divider, Chip, Grid, hexToRgb, Typography } from '@material-ui/core'

import MeetingPanelistBadge from '../MeetingPanelistBadge/MeetingPanelistBadge';

import { getFromSafeObject, rgbToRgbaOrUndefined, textTransform } from '../../aux/aux'

import LanguageIcon from '@mui/icons-material/Language';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import PersonIcon from '@mui/icons-material/Person';
// import ClosedCaptionOffIcon from '@mui/icons-material/ClosedCaptionOff';
import './meetingDescription.css'
import ShowMoreStringPopOver from '../ShowMoreStringPopOver/ShowMoreStringPopOver';

const MeetingDescription = ({ meeting, fieldToPreventMap={} }) => {
    const theme = useTheme();
    const styles = {
        topic: {
            fontWeight: 'bold'
        },
        icon: {
            display: 'block',
        },
        panelBadge: {
            width: '30px',
            height: '30px'
        },
        liveChip: {
            color: theme.palette.success.main,
            borderColor: theme.palette.success.main,
            backgroundColor: rgbToRgbaOrUndefined(hexToRgb(theme.palette.success.main), 0.1),
        },
    }
    const getMeetingPanelistBadge = (panelArray) => {
        const meetingPanelBadgeComponentArray = panelArray.map( (meetingPanelist, index) => {
            const isFirst = index === 0;
            const panelist = meetingPanelist.panelist;
            const isProfessor = panelist.professorUserId != null;
            const imgId = getFromSafeObject(panelist,'details.imageDetails.main.publicId');
            return(
                <div key={`panelist_${panelist.id}`} 
                    className={`meeting-description-panelist ${isFirst ? 'first-panelist' : ''}`}>
                    <MeetingPanelistBadge
                        isProfessor={isProfessor}
                        imgId={imgId} 
                        imgUrl={panelist.panelImgURL}
                        label={panelist.fullName} 
                        style={styles.panelBadge}
                    /> 
                </div>
            )
        })
        return meetingPanelBadgeComponentArray
    }
    return (
        <div className='meeting-description-main-container'>
            {!fieldToPreventMap.hasOwnProperty('agenda') &&
                <Typography variant='body2'>
                    {textTransform('title', meeting.description.agenda)}
                </Typography>
            }
            {/* <Divider  variant='middle' style={{margin:"10px 0px"}}/> */}
            <div className='meeting-description-feature-container'>
                {!fieldToPreventMap.hasOwnProperty('panel') &&
                    <div className='meeting-description-feature'>
                        <PersonIcon style={ styles.icon} />
                        {getMeetingPanelistBadge(meeting.details.meetingPanelArray)}
                    </div>
                }
                {!fieldToPreventMap.hasOwnProperty('language') &&
                    <div className='meeting-description-feature'>
                        <LanguageIcon style={ styles.icon } />
                        <Typography variant='body2'>
                            {textTransform('title', getFromSafeObject(meeting,'details.speakerLocale.details.language.description.name',''))}
                        </Typography>
                    </div>
                }
                {!fieldToPreventMap.hasOwnProperty('interpreter') &&   
                    <div className='meeting-description-feature'>
                        <InterpreterModeIcon style={ styles.icon } />
                        <Typography variant='body2'>
                            {textTransform('capitalize', meeting.details.interpreterArray.reduce((prev, interpreter) => {
                                prev += `${prev ? ',' : ''} ${getFromSafeObject(interpreter,'details.locale.details.language.description.name','')}`
                                return prev
                                }, '')
                            )}
                        </Typography>
                    </div>
                }
            </div>
        </div>
    )
}

export default MeetingDescription