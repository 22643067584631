import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Box} from '@material-ui/core';
import { useTheme } from '@material-ui/styles';


import ClassSideBarOverview from '../ClassSideBarOverview/ClassSideBarOverview';
import ClassSideBarSection from '../ClassSideBarSection/ClassSideBarSection';
import ClassSideBarElement from '../ClassSideBarElement/ClassSideBarElement';

// Style
import './classSideBar.css'

const ClassSideBar = ({ contentStructure, contentSelected, setContentSelected, userProgress, markerPlace='left'}) => {
    const { t } = useTranslation('classroom', { keyPrefix: 'classSideBar' });
    const theme = useTheme();
    const overviewIds = {
        sectionId: 0,
        lessonId: 0
    };
    const selectionMarkerColor = useTheme().palette.primary.main;
    const [openSections, setOpenSections] = useState(contentStructure.sectionIdsStructure.reduce(
        (acc, sectionId) => { 
            let value = sectionId === contentSelected.currentSectionId ? true : false ;
            return{
                ...acc,
                [sectionId] : value
            }
        },{})
        );
    const styles = {
        sectionContainer:{
            backgroundColor: theme.palette.background.paper,
            border: `solid 1px ${theme.palette.grey[100]}`
        },
        openSectionLessonContainer:{
            borderTop: `solid 1px ${theme.palette.grey[100]}`
        },
        sectionMarker:{
            // backgroundColor: selectionMarkerColor,
            ...(markerPlace === 'left' ? { order: '1' } : { order: '0' })
        },
        selectedSectionMarker: {
            backgroundColor: selectionMarkerColor,
        },
    };
    //////
    // Open current section automatically
    //
    // useEffect(() => {
    //     let tempOpenSections = {...openSections};
    //     tempOpenSections[contentSelected.currentSection] = true;
    //     setOpenSections(tempOpenSections);
    // }, [contentSelected])
    //////
    const handleSectionVisibility = (sectionId) => {
        let sections = openSections;
        sections[sectionId] = !openSections[sectionId];
        setOpenSections({...sections});
    }
    
    return (
        <div className="class-side-bar-main">
                <div className="class-side-bar-section-wrapper" >
                    <div className="class-side-bar-section-container" style={styles.sectionContainer}>
                        <div className='class-side-bar-section-name-wrapper'>
                            <ClassSideBarOverview lessonId={overviewIds.lessonId} sectionId={overviewIds.sectionId} title={t('aboutThisContent')}
                            setContentSelected={setContentSelected} contentSelected={contentSelected} />
                        </div>
                        <div className={`class-side-bar-section-marker-container`} style={{ ...styles.sectionMarker, ...(0 === contentSelected.currentSectionId ? styles.selectedSectionMarker : {})}}>
                            {/* <div className={`class-side-bar-section-marker ${0 === contentSelected.currentSectionId ? 'class-side-bar-section-marker-selected' : ''}`} style={ styles.sectionMarker }/> */}
                        </div>
                    </div>
                </div>
                {contentStructure.sectionIdsStructure.map((sectionId, sectionIndex) => {
                    let sectionNum = sectionIndex + 1;
                    let section = contentStructure.sectionSummaryMap[sectionId];
                    let userSectionProgress = userProgress.sectionProgressMap[sectionId];
                    let isSelected = sectionId === contentSelected.currentSectionId;
                    let isOpen = openSections[sectionId];
                    return(
                        // <div key={sectionIndex} className="class-side-bar-section-wrapper" style={styles.sectionContainer}>
                        <div key={sectionIndex}className="class-side-bar-section-wrapper" >
                            <div className="class-side-bar-section-container" style={styles.sectionContainer}>
                                <div className='class-side-bar-section-name-wrapper'>
                                    <ClassSideBarSection sectionNum={sectionNum} title={section.description.title} handleVisibility={handleSectionVisibility} sectionId={sectionId} currentSectionId={contentSelected.currentSectionId}
                                    totalCompletedMin={userSectionProgress ? userSectionProgress.totalCompletedMin : 0} lengthInMin={section.lengthInMin} />
                                    <div className={isOpen ? 'class-side-bar-section-opened' : 'class-side-bar-section-closed'} style={isOpen ? styles.openSectionLessonContainer : {}}>
                                        {section.lessonIdsStructure.map((lessonId, lessonIndex) => {
                                            let lessonVersionId = contentStructure.lessonVersionMap[lessonId]
                                            let lesson = contentStructure.lessonSummaryMap[lessonId][lessonVersionId];
                                            let userLessonProgress = userProgress.lessonProgressMap[lessonId] ? userProgress.lessonProgressMap[lessonId][lessonVersionId] : null;
                                            return(
                                                <ClassSideBarElement key={lessonIndex} lessonId={lessonId} lesson={lesson} sectionId={sectionId} isCompleted={userLessonProgress ? userLessonProgress.markAsCompleted : false}
                                                setContentSelected={setContentSelected} contentSelected={contentSelected} />   
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                                <div className={`class-side-bar-section-marker-container`} style={{ ...styles.sectionMarker, ...(isSelected ? styles.selectedSectionMarker : {})}}>
                                    {/* <div className={`class-side-bar-section-marker ${0 === contentSelected.currentSectionId ? 'class-side-bar-section-marker-selected' : ''}`} style={ styles.sectionMarker }/> */}
                                </div>
                                {/* <Grid item className='class-side-bar-section-marker-container'>
                                    <div className={`class-side-bar-section-marker ${sectionId === contentSelected.currentSectionId ? 'class-side-bar-section-marker-selected' : ''}`} style={ styles.sectionMarker }/>
                                </Grid> */}
                            </div>
                        </div>
                    )

                    
                })}
        </div>
    )
}

export default ClassSideBar
