import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next';
import { Grid, Button, Typography, } from '@material-ui/core';
// Request helpers
import { getUserSystems } from '../../services/userServices';
// Components
import ModifyContentUserSystemNoModalDialog from '../ModifyContentUserSystemNoModalDialog/ModifyContentUserSystemNoModalDialog';
import UserSystemSelect from '../UserSystemSelect/UserSystemSelect';
import BackButton from '../BackButton/BackButton';
import ClassContentCompatibleSystemSelect from '../ClassContentCompatibleSystemSelect/ClassContentCompatibleSystemSelect';
// Styles
import './classContentUserSystemSelect.css';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import CreateUserSystemFromSelection from '../CreateUserSystemFromSelection/CreateUserSystemFromSelection';
import { getSystemFromUserConfig } from '../../aux/userHelpers';
import DefaultUserSystemSelect from '../DefaultUserSystemSelect/DefaultUserSystemSelect';

const ClassContentUserSystemSelect = ({ userConfig, contentSummary, onUpdate=()=>{}, onFinish=()=>{}, onRender=()=>{} }) => {
    const { t } = useTranslation('common', { keyPrefix: 'classContentUserSystemSelect'});
    const [componentState, setComponentState] = useState(null);
    const [selectedSystem, setSelectedSystem] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isRequesting, setIsRequesting] = useState({
        userSystems:false
    });
    const [userSystems, setUserSystems] = useState(null);
    const [defaultUserSystem, setDefaultUserSystem] = useState(null);
    const [isValidDefaultUserSystem, setIsValidDefaultUserSystem] = useState(null);

    const handleOnUpdateUserSystem = async (updatedSelectedSystem, signal) => {
        try{
            if(!updatedSelectedSystem.hasOwnProperty('selectedUserSystemId')){
                updatedSelectedSystem.selectedUserSystemId = null;
            }
            setSelectedSystem(updatedSelectedSystem);
            const requestContent = userConfig.selectedSoftwareName != updatedSelectedSystem.selectedSoftwareName || userConfig.selectedDeviceName != updatedSelectedSystem.selectedDeviceName
            const requestUpdatedConfig = true;
            await onUpdate(updatedSelectedSystem, requestContent, requestUpdatedConfig);
        }catch(error){
            // TODO: implement
        }
    }

    const handleOnDidUpdateUserSystem = () => {
        // DEBUG: START
        // if(userSystems && userSystems.length === 0){
        //     setComponentState('createUserSystemFromSelection');
        // }else{
        //     onFinish();
        // }
        // DEBUG: ENDS
        setComponentState('createUserSystemFromSelection');
    }

    const handleOnDidCreateUserSystem = async(newUserSystem, signal) => {
        try{
            const updatedSelectedSystem = {
                selectedSoftwareName: newUserSystem.softwareName,
                selectedDeviceName: newUserSystem.deviceName,
                selectedUserSystemId: newUserSystem.id
            };
            await handleOnUpdateUserSystem(updatedSelectedSystem, signal);
        }catch(error){
            // TODO: implement
        }
    }

    const sameSystems = (system1, system2) => {
        return system1.deviceName === system2.deviceName && system1.softwareName === system2.softwareName
    }

    const isAValidSystemForContent = useCallback((defaultUserSystem, contentSummary) => {
        const availContentConfiguration = contentSummary.summaryDetails.availableConfiguration
        const isValidSoftware = availContentConfiguration.softwareArray.find( software => defaultUserSystem.softwareName === software.softwareName );
        const isValidDevice = availContentConfiguration.deviceArray.find( device => defaultUserSystem.deviceName === device.deviceName );
        return isValidSoftware && isValidDevice ? true : false
    },[defaultUserSystem, contentSummary])

    const requestUserSystems = async(userId, abortSignal) => {
        try{
            setIsRequesting((isRequesting) => ({...isRequesting, userSystems:true}))
            const res = await getUserSystems(userId, 'all', 0, abortSignal);
            if(!abortSignal.aborted){
                const userSystemsArray = res.data;
                const defaultUserSystem = userSystemsArray.find( system => system.isDefault == true);
                setUserSystems(userSystemsArray);
                if(defaultUserSystem && !sameSystems(defaultUserSystem, getSystemFromUserConfig(userConfig))){
                    setDefaultUserSystem(defaultUserSystem);
                    const isValid = isAValidSystemForContent(defaultUserSystem, contentSummary);
                    setIsValidDefaultUserSystem(isValid);
                    setComponentState('defaultUserSystem');
                }else{
                    setComponentState('selectOptions');
                }
            }
        }catch(error){
        }finally{
            if(!abortSignal.aborted){
                setIsRequesting((isRequesting) => ({...isRequesting, userSystems:false}))
            }
        }
    };

    const disableUserSystemOption = useCallback(() => {
        return !userSystems || userSystems.length == 0
    },[userSystems]);

    useEffect(() => {
        const isLoading = Object.values(isRequesting).find( request => request === true ) != null;
        setIsLoading(isLoading);
    },[isRequesting])


    useEffect(() => {
        const abortController = new AbortController();
        requestUserSystems(userConfig.userId, abortController.signal);
        return(() => {
            abortController.abort()
        })
    },[])

    useEffect(() => {
        const timeoutId = setTimeout(onRender, 300);
        return(() => clearTimeout(timeoutId))
    })

    const getComponentToRender = () => {
        let componentToRender;
        switch(componentState){
            case 'defaultUserSystem':
                componentToRender = 
                <div className='class-content-user-system-select-form-container-animation'>
                    <DefaultUserSystemSelect defaultSystem={defaultUserSystem} isAValidSystem={isValidDefaultUserSystem} onAgree={handleOnUpdateUserSystem} onDisagree={()=>{setComponentState('selectOptions')}} onFinish={onFinish} onRender={onRender}/>
                </div>
                break;
            case 'selectOptions':
                componentToRender = 
                <Grid item container direction='column' className='class-content-user-system-select-form-container-animation'>
                    <Grid item className='class-content-user-system-select-form-item'>
                        <Button variant='outlined' color='primary' disabled={disableUserSystemOption()} fullWidth onClick={ e => setComponentState('userSystem')}>
                            {t("selectOneOfYourSystems")}
                        </Button>
                        { userSystems &&
                            <Typography variant='body2' className='class-content-user-system-select-form-msg class-content-user-system-select-form-msg-disabled' >
                                {`${userSystems.length} ${t("registeredSystems")}`}
                            </Typography>
                        }
                    </Grid>
                    <Grid item className='class-content-user-system-select-form-item'>
                        <Button variant='outlined' color='primary' fullWidth onClick={ e => setComponentState('compatibleSystem')}>
                            {t("selectFromCompatible")}
                        </Button>
                    </Grid>
                </Grid>
                break;
            case 'compatibleSystem':
                componentToRender = 
                <div className='class-content-user-system-select-form-container-animation'>
                    <Grid item>
                        <BackButton onClick={ e => setComponentState('selectOptions')} />
                    </Grid> 
                    <ClassContentCompatibleSystemSelect userConfig={userConfig} contentSummary={contentSummary} userSystemArray={userSystems} onUpdate={handleOnUpdateUserSystem} onDidUpdate={handleOnDidUpdateUserSystem} onRender={onRender}
                    showHeader={false}/>
                </div>
                break;
            case 'userSystem':
                componentToRender = 
                <div className='class-content-user-system-select-form-container-animation'>
                    <Grid item>
                        <BackButton onClick={ e => setComponentState('selectOptions')} />
                    </Grid>
                    <UserSystemSelect userConfig={userConfig} contentSummary={contentSummary} onUpdate={handleOnUpdateUserSystem} onFinish={onFinish} onRender={onRender} /> 
                </div>
                break;
            case 'createUserSystemFromSelection':
                componentToRender = 
                <div className='class-content-user-system-select-form-container-animation'>
                    <Grid item>
                        <BackButton onClick={ e => setComponentState('selectOptions')} />
                    </Grid>
                    <Grid item>
                        <CreateUserSystemFromSelection userId={userConfig.userId} selectedSystem={selectedSystem} onDidCreate={handleOnDidCreateUserSystem} onFinish={onFinish}  onRender={onRender} />
                    </Grid>
                </div>
                break;
            default:
                componentToRender = <></>
        }
        return componentToRender
    }

    return (
        !componentState || isLoading ?
            <LoadingComponent visibleElements='circle'/>
            :
            getComponentToRender()
    )
}

export default ClassContentUserSystemSelect;