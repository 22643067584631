/**
 * This module contains helper functions to deal with internationalization services and actions
 */
const FILENAME_SEPARATOR = '_';
const getInternationalizedLocaleFileNameOrNull = (languageId, filename) =>{ 
    return filename && languageId ? `${languageId}${FILENAME_SEPARATOR}${filename}` : null
}

export{
    getInternationalizedLocaleFileNameOrNull
}