/**
 * This module containes useful and redundant API-authorization-related functions
 */
import jwt_decode from "jwt-decode";
import { createAuthRefreshRequest, publicRequest } from "./requestMethods";
import { sessionVariables } from "./sessionHelpers";

/**
 * Note: Note: A falsy or malformed token will throw an InvalidTokenError error.
 * @param {*} token 
 * @returns 
 */
function isTokenActive(token){
    try{
        const currentTime = new Date().getTime();
        const tokenPayload = jwt_decode(token);
        const tokenExpTime = tokenPayload.exp  * 1000
        return currentTime < tokenExpTime;
    }catch(err){
        throw err;
    }
}
/**
 * Note: Note: A falsy or malformed token will throw an InvalidTokenError error.
 * @returns 
 */
function getUserIdFromAccessToken(){
    try{
        const tokenPayload = jwt_decode(sessionVariables.get('accessToken'));
        return tokenPayload.id;
    }catch(err){
        throw err;
    }
}
/**
 * Note: Note: A falsy or malformed token will throw an InvalidTokenError error.
 * @returns 
 */
function getRefreshTokenId(){
    try{
        const tokenPayload = jwt_decode(sessionVariables.get('refreshToken'));
        return tokenPayload.jti;
    }catch(err){
        throw err;
    }
}
/**
 * Note: Note: A falsy or malformed token will throw an InvalidTokenError error.
 * @returns 
 */
function getEmailFromVerificationToken(verificationToken){
    try{
        const tokenPayload = jwt_decode(verificationToken);
        return tokenPayload.email;
    }catch(err){
        throw err;
    }
}
/**
 * Note: Note: A falsy or malformed token will throw an InvalidTokenError error.
 * @returns 
 */
function decodeJWTToken(token){
    try{
        return jwt_decode(token);
    }catch(err){
        throw err;
    }
}

async function refreshAndSetAuthTokens(userId){
    try{
        const userRefreshRequest = createAuthRefreshRequest();
        const res = await userRefreshRequest.get(`/auth/refresh-auth/${userId}`)
        const newAccessToken = res.data.accessToken;
        sessionVariables.set('accessToken', newAccessToken);
        const newRefreshToken = res.data.refreshToken;
        if(newRefreshToken){
            sessionVariables.set('refreshToken', newRefreshToken);
        }
    }catch(err){
        throw err;
    }
}

async function refreshAndGetAuthTokens(userId){
    try{
        const userRefreshRequest = createAuthRefreshRequest();
        const res = await userRefreshRequest.get(`/auth/refresh-auth/${userId}`)
        return res.data
    }catch(err){
        throw err;
    }
}

function removeAuthTokensOnClient(){
    sessionVariables.remove('accessToken');
    sessionVariables.remove('refreshToken');
}

// DEPRECATED 
// Date: 09/24/22
// NOTE: 
// It is note needed to remove refresh token from server. 
// It is innactive and it will be overwritten by an active one if needed
// It was a not controlled entry point that allows malintentioned user to remove other users refresh token from the server
// async function revokeAuthTokens(){
//     try{
//         const userId = getUserIdFromAccessToken()
//         const refreshTokenId = getRefreshTokenId()
//         const res = await publicRequest.post(`/auth/delete-refresh`,{ id: userId, refreshTokenId: refreshTokenId });
//     }catch(err){
//         throw err;
//     }finally{
//         sessionVariables.remove('accessToken');
//         sessionVariables.remove('refreshToken');
//     }
// }


export { 
        isTokenActive, 
        refreshAndSetAuthTokens, 
        removeAuthTokensOnClient,
        refreshAndGetAuthTokens, 
        getUserIdFromAccessToken, 
        getRefreshTokenId, 
        getEmailFromVerificationToken,
        decodeJWTToken
    }