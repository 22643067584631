import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

export function useRedirect(){
    const history = useHistory()

    const redirectToRoute = (routePath, query={}, replaceRoute=false) => {
        const queryParams = new URLSearchParams(query).toString();
        const path = `${routePath}${queryParams ? `?${queryParams}`:''}`;
        const redirect = replaceRoute ? history.replace : history.push;
        redirect(path);
    }

    const refreshRoute = () => {
        history.go(0)
    }

    const redirectForcingRefresh = (href) => {
        window.location.href = href
    }

    return ({ 
        redirectToRoute,
        refreshRoute,
        redirectForcingRefresh

    })
}
