import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getFromSafeObject, isNotEmptyObject, textTransform } from '../../aux/aux'
import BasicProductConsumptionOptionGroupItem from '../BasicProductConsumptionOptionGroupItem/BasicProductConsumptionOptionGroupItem'
import BasicGroupItemConfigSelector from '../BasicGroupItemConfigSelector/BasicGroupItemConfigSelector'
import SubscriptionConsumptionConfigSelectorItem from '../SubscriptionConsumptionConfigSelectorItem/SubscriptionConsumptionConfigSelectorItem'
import SubscriptionConsumptionConfigSelectorCollapsedItem from '../SubscriptionConsumptionConfigSelectorCollapsedItem/SubscriptionConsumptionConfigSelectorCollapsedItem'
import './subscriptionConsumptionOptionGroupItem.css'

const SubscriptionConsumptionOptionGroupItem = ({groupItemIndex, productConsumptionType, userProductPurchasabilityDetailsMap, isSelected, isSelectedProductConsumptionType, isRecommended, isUnique, onSelectedGroup, selectedItem, onSelectedItem, stylesMap=undefined}) => {
    const { t } = useTranslation('common')
    const [disableGroup, setDisableGroup] = useState(null);
    const [warningMessage, setWarningMessage] = useState(null);
    useEffect(() => {
        const getWarningMessage = (groupSimplifiedPurchasabilityDetails) => {
            const noPurchasabilityCause = getFromSafeObject(groupSimplifiedPurchasabilityDetails, 'details.noPurchasabilityType');
            let warningMessage = null;
            if(noPurchasabilityCause){
                switch(noPurchasabilityCause){
                    case 'wrongSubscriptionProductType':
                        const userSubscriptionTypeName = getFromSafeObject(groupSimplifiedPurchasabilityDetails, 'details.userSubscription.type.name');
                        warningMessage = textTransform('title', t('subscriptionConsumptionConfigSelectorItem.noPurchasabilityCause.differentTypeActiveSubscription', {subscriptionTypeName: userSubscriptionTypeName}));
                        break;
                    case 'unknownUserSubscription':
                    case 'noUnifiedCause':
                    default:
                        warningMessage = textTransform('title', t('cannotPurchaseThis'));
                }
            }
            return warningMessage;
        }
        if(productConsumptionType && isNotEmptyObject(userProductPurchasabilityDetailsMap)){
            const productConsumptionOptionArray = productConsumptionType.productConsumptionOptionArray || [];
            let noPurchasableOption = true;
            let noPurchasableFirstUserDetailsByCause= {}
            for(let productConsumptionOption of productConsumptionOptionArray){
                const userProductPurchasabilityDetails = userProductPurchasabilityDetailsMap[productConsumptionOption.id]; 
                if(!userProductPurchasabilityDetails.isPurchasable){
                    const noPurchasabilityCause = getFromSafeObject(userProductPurchasabilityDetails, 'details.noPurchasabilityType');
                    if(!noPurchasableFirstUserDetailsByCause.hasOwnProperty(noPurchasabilityCause)){
                        noPurchasableFirstUserDetailsByCause[noPurchasabilityCause] = userProductPurchasabilityDetails;
                    }
                }else{
                    noPurchasableOption = false;
                    break
                }
            }
            if(noPurchasableOption){
                let groupSimplifiedUserProductPurchasabilityDetails;
                if(Object.keys(noPurchasableFirstUserDetailsByCause).length > 1){
                    groupSimplifiedUserProductPurchasabilityDetails = { details: { noPurchasabilityType: 'noUnifiedCause'} };
                }else{
                    groupSimplifiedUserProductPurchasabilityDetails = (Object.values(noPurchasableFirstUserDetailsByCause))[0]
                }
                const warningMessage = getWarningMessage(groupSimplifiedUserProductPurchasabilityDetails)
                setWarningMessage(warningMessage)
            }
            setDisableGroup(noPurchasableOption);
        }else{
            setDisableGroup(null);
        }
    },[productConsumptionType, userProductPurchasabilityDetailsMap])

    return (
        disableGroup != null ? 
            <BasicProductConsumptionOptionGroupItem
                groupItemIndex={groupItemIndex}
                productConsumptionType={productConsumptionType}
                userProductPurchasabilityDetailsMap={userProductPurchasabilityDetailsMap}
                disabled={disableGroup}
                isSelectedProductConsumptionType={isSelectedProductConsumptionType}
                isSelected={isSelected}
                isRecommended={isRecommended}
                isUnique={isUnique}
                selectedItem={selectedItem}
                onSelectedGroup={onSelectedGroup}
                onSelectedItem={onSelectedItem}
                warningMessage={warningMessage}
                stylesMap={stylesMap}
                ConfigSelectorComponent={BasicGroupItemConfigSelector}
                ConfigSelectorCollapsedItemComponent={SubscriptionConsumptionConfigSelectorCollapsedItem}
                ConfigSelectorItemComponent={SubscriptionConsumptionConfigSelectorItem}
            />
            :
            null
    )
}

export default SubscriptionConsumptionOptionGroupItem