/**
 * This module contains all the meeting service-related methods
 */

import { getMeetingFn, getMeetingOcurrenciesFn, getMeetingSignatureFn } from "../aux/meetingQueries";
import { getUserMeetingFn, getUserAccessMeetingTokenFn, registerUserMeetingOccurrenceFn, unregisterUserMeetingOccurrenceCollectionFn,
    unregisterUserMeetingOccurrenceFn, changeMeetingInUserMeetingFn, createUserMeetingRepetitionFn, getUserMeetingCollectionFn, getUserMeetingCollectionByProductFn 
} from "../aux/userMeetingQueries";
import { requestMinimumTimeoutWrapper } from "../aux/requestMethods";
import { getAPIEventSource } from "../aux/eventSourceHelpers";

async function getMeeting(meetingId, query, minimumTimeOut=0, signal=null){
    const requestParameters = {
        meetingId,
        query
    };
    return requestMinimumTimeoutWrapper( getMeetingFn, requestParameters, minimumTimeOut, signal);
}

async function getMeetingOccurrencies(meetingId, query, minimumTimeOut=0, signal=null){
    const requestParameters = {
        meetingId,
        query
    };
    return requestMinimumTimeoutWrapper( getMeetingOcurrenciesFn, requestParameters, minimumTimeOut, signal);
}

async function getMeetingSignature(userId, meetingId, minimumTimeOut=0, signal=null){
    const requestParameters = {
        userId,
        meetingId
    };
    return requestMinimumTimeoutWrapper( getMeetingSignatureFn, requestParameters, minimumTimeOut, signal);
}

async function getUserAccessMeetingToken(userId, userMeetingId, minimumTimeOut=0, signal=null){
    const requestParameters = {
        userId,
        userMeetingId
    };
    return requestMinimumTimeoutWrapper( getUserAccessMeetingTokenFn, requestParameters, minimumTimeOut,  signal );
}

async function getUserMeeting(userId, userMeetingId, query={}, minimumTimeOut=0, signal=null){
    const requestParameters = {
        userId,
        userMeetingId,
        query
    };
    return requestMinimumTimeoutWrapper( getUserMeetingFn, requestParameters, minimumTimeOut, signal);
}

async function getUserMeetingCollectionByProduct(productId, userId=null, query={}, minimumTimeOut=0, signal=null){
    const requestParameters = {
        productId,
        userId,
        query,
    };
    return requestMinimumTimeoutWrapper( getUserMeetingCollectionByProductFn, requestParameters, minimumTimeOut, signal);
}

async function registerUserMeetingOccurrence( userId, userMeetingId, meetingOccurrenceId, bodyParams={}, minimumTimeOut=0, signal=null){
    const requestParameters = {
        userId,
        userMeetingId,
        meetingOccurrenceId,
        bodyParams
    };
    return requestMinimumTimeoutWrapper( registerUserMeetingOccurrenceFn, requestParameters, minimumTimeOut, signal);
}

async function unregisterUserMeetingOccurrenceCollection( userId, bodyParams={}, minimumTimeOut=0, signal=null){
    const requestParameters = {
        userId,
        bodyParams
    };
    return requestMinimumTimeoutWrapper( unregisterUserMeetingOccurrenceCollectionFn, requestParameters, minimumTimeOut, signal);
}

async function unregisterUserMeetingOccurrence( userId, userMeetingId, meetingOccurrenceId, bodyParams={}, minimumTimeOut=0, signal=null){
    const requestParameters = {
        userId,
        userMeetingId,
        meetingOccurrenceId,
        bodyParams
    };
    return requestMinimumTimeoutWrapper( unregisterUserMeetingOccurrenceFn, requestParameters, minimumTimeOut, signal);
}

async function changeMeetingInUserMeeting( userId, userMeetingId, meetingId, bodyParams={}, minimumTimeOut=0, signal=null){
    const requestParameters = {
        userId,
        userMeetingId,
        meetingId,
        bodyParams
    };
    return requestMinimumTimeoutWrapper( changeMeetingInUserMeetingFn, requestParameters, minimumTimeOut, signal);
}

async function createUserMeetingRepetition( userId, userMeetingId, bodyParams={}, minimumTimeOut=0, signal=null){
    const requestParameters = {
        userId,
        userMeetingId,
        bodyParams,
    };
    return requestMinimumTimeoutWrapper( createUserMeetingRepetitionFn , requestParameters, minimumTimeOut, signal);
}


function getUserMeetingOccurrenceFromEventSource(userId, userTrakingToken, onmessage, onerror=null){
    const path = `user-meeting/occurrence/realtime/user/${userId}/userTrakingToken/${userTrakingToken}`;
    return getAPIEventSource(path, onmessage, onerror);
}

export{
    getMeetingSignature,
    getMeeting,
    getMeetingOccurrencies,
    getUserAccessMeetingToken,
    getUserMeeting,
    getUserMeetingCollectionByProduct,
    registerUserMeetingOccurrence,
    unregisterUserMeetingOccurrence,
    unregisterUserMeetingOccurrenceCollection,
    changeMeetingInUserMeeting,
    createUserMeetingRepetition,
    getUserMeetingOccurrenceFromEventSource
}