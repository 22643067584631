import { useEffect, useState } from 'react';
import './productConsumptionTypeOptionList.css'
import { getFromSafeObject } from '../../aux/aux';

const ProductConsumptionTypeOptionList = ({productConsumptionType, userProductPurchasabilityDetailsMap,  ItemComponent, isSelectedProductConsumptionType, selectedOption, onSelectOption }) => {
    const handleOnSelectProductConsumptionOption = (selectedOption) => {
        if(typeof onSelectOption === 'function'){
            onSelectOption(selectedOption)
        }
    }
    useEffect(() => {
        if(isSelectedProductConsumptionType){
            const defaultProductOptionIndex = productConsumptionType?.productConsumptionOptionDefaultIndex;
            const isUnique = (productConsumptionType?.productConsumptionOptionArray || []).length === 1;
            let defaultSelectedProductOption;
            if(defaultProductOptionIndex != null){
                defaultSelectedProductOption = productConsumptionType.productConsumptionOptionArray[defaultProductOptionIndex];
            }else if(isUnique){
                defaultSelectedProductOption = productConsumptionType.productConsumptionOptionArray[0];
            }
            if(defaultSelectedProductOption){
                handleOnSelectProductConsumptionOption(defaultSelectedProductOption)
            }
        }
    },[isSelectedProductConsumptionType])
    return (
        productConsumptionType && productConsumptionType.productConsumptionOptionArray.length > 0 ?
                <div className='product-consumption-type-option-list-options-main-container'>
                    {productConsumptionType.productConsumptionOptionArray.map((productConsumptionOption, index) =>{
                        const userProductPurchasabilityDetails = userProductPurchasabilityDetailsMap[productConsumptionOption.id]; 
                        const isUnique = productConsumptionType.productConsumptionOptionArray.length === 1;
                        const isBestPrice = productConsumptionType.productConsumptionOptionBestPriceIndex === index;
                        const isDefault = productConsumptionType.productConsumptionOptionDefaultIndex === index;
                        const isSelected = isSelectedProductConsumptionType && selectedOption && selectedOption.id === productConsumptionOption.id;
                        return (
                            <ItemComponent 
                                key={index}
                                index={index}
                                productConsumptionOption={productConsumptionOption} 
                                userProductPurchasabilityDetails={userProductPurchasabilityDetails}
                                isSelected={isSelected} 
                                isBestPrice={isBestPrice} 
                                isRecommended={isDefault} 
                                isUnique={isUnique}
                                onSelected={handleOnSelectProductConsumptionOption} 
                            />
                        )
                    })}
                </div>
            :
                null
    )
}

export default ProductConsumptionTypeOptionList