import { useEffect, useState, Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { getFromSafeObject, textTransform } from '../../aux/aux';
import {Alert} from '@mui/material';
import BigFooterNoLinks from '../../components/BigFooterNoLinks/BigFooterNoLinks';
import UserContentProductPreview from '../../components/UserContentProductPreview/UserContentProductPreview';
// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import astronaut from '../../lotties/astronaut_300x150.json';

// Styles
import { getUserContentProductPreview } from '../../services/contentServices';
import { useLocaleCtx } from '../../customHooks/useLocaleCtx';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import { getUserAccessToProductDetailsCollection } from '../../services/userServices';
import './myContent.css'

const MyContent = ({ user }) => {
    const { t } = useTranslation('dashboard', { keyPrefix: "myContent"});
    const localeCtx = useLocaleCtx();
    const [isLoading, setIsLoading] = useState(false);
    const [userContentProductPreviewArray, setUserContentProductPreviewArray] = useState(null);
    const [userAccessToProductDetailsMap, setUserAccessToProductDetailsMap] = useState(null);
    const [animationContainer, setAnimationContainer] = useState(null);
    const [alert, setAlert] = useState({show:false, msg:undefined, severity:undefined});
    const animationRef = useRef(null);
    const theme = useTheme();
    const styles = {
        wrapper: {
            backgroundColor: theme.palette.surface.light[2]
        },
        title:{
            fontWeight: 'bold'
        },
        noNotesInfo:{
            color: theme.palette.grey[500]
        }
    }
    
    const retrieveUserContentProduct = async(userId, signal) => {
        try{
            setIsLoading(true);
            const query = {
                localeId: localeCtx.localeId,
            }
            const res = await getUserContentProductPreview(userId, query, 0, signal);
            const {
                userContentProductPreviewArray
            } = res.data;
            if(!signal.aborted){
                setUserContentProductPreviewArray(userContentProductPreviewArray)
            }
        }catch(error){
            if(!signal.aborted){
                const msg = `${textTransform('title',t('cannotRetrieveYourContentNow'))}. ${textTransform('title',t('tryItLater'))}`;
                setAlert({show:true, msg, severity:'error'});
            }
        }finally{
            setIsLoading(false);
        }
    }

    const requestUserAccessToProductDetails = async( userId, productDetailsArray, signal) => {
        try{
            setIsLoading(true);
            const res = await getUserAccessToProductDetailsCollection(userId, productDetailsArray, true, 0, signal);
            const {
                userAccessToProductDetailsCollection
            } = res.data;
            if(!signal.aborted){
                setUserAccessToProductDetailsMap(userAccessToProductDetailsCollection);
            }
        }catch(error){
            if(!signal.aborted){
                const msg = `${textTransform('title',t('cannotRetrieveYourContentNow'))}. ${textTransform('title',t('tryItLater'))}`;
                setAlert({show:true, msg, severity:'error'});
            }
        }finally{
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if(animationContainer != null){
            animationRef.current = lottie.loadAnimation({
                container: animationContainer,
                renderer: 'svg',
                autoplay: true,
                animationData: astronaut,
                loop:3,
            })
            return(() => {
                animationRef.current.stop();
                animationRef.current?.destroy();
            })
        }
    }, [animationContainer])

    useEffect(() => {
        const userId = getFromSafeObject(user, 'basicData.id');
        if(userId){
            const abortController = new AbortController();
            setAlert(prev => ({show:false, msg:undefined, severity:undefined}))
            retrieveUserContentProduct(userId, abortController.signal)
            return(() => {
                abortController.abort()
            })
        }
    },[user, localeCtx])

    useEffect(() => {
        if(userContentProductPreviewArray && userContentProductPreviewArray.length){
            const userId = getFromSafeObject(user, 'basicData.id');
            const abortController = new AbortController();
            const productDetailsArray = userContentProductPreviewArray.map( userContentProductPreview => ({ 
                productId: userContentProductPreview.contentProductId,
                // editionNum: userContentProductPreview.editionNum,
            }))
            requestUserAccessToProductDetails(userId, productDetailsArray, abortController.signal);
            return(() => abortController.abort());
        }
    },[userContentProductPreviewArray])

    return (
        <>
            <div className='my-content-wrapper' style={styles.wrapper}>
                <div className="my-content-main-container">
                    <Typography variant="h5" style={styles.title}>
                        {textTransform('title', t('myContent', { count: 1}))}
                    </Typography>
                    <div className='my-content-elem-container'>
                        {alert.show ?
                                <div className='my-content-alert-container'>
                                    <Alert severity={alert.severity}>
                                        {alert.msg}
                                    </Alert>
                                </div>
                            :
                                null
                        }
                        {isLoading ? 
                                <div className='my-content-loading-wrapper'>
                                    <LoadingComponent visibleElements='circle' style={{margin: 'auto'}} />
                                </div>
                            :
                                userAccessToProductDetailsMap && userContentProductPreviewArray && userContentProductPreviewArray.length > 0 ?
                                        userContentProductPreviewArray.map(((userContentProductPreview,index) => {
                                            const userAccessToProductDetails = userAccessToProductDetailsMap[userContentProductPreview.contentProductId];
                                            return (
                                                <Fragment key={index}>
                                                    <UserContentProductPreview userContentProductPreview={userContentProductPreview} userAccessToProductDetails={userAccessToProductDetails}/>
                                                </Fragment>
                                            )
                                        }))
                                    :
                                    <>
                                        <Typography variant='body2' style={styles.noNotesInfo}>
                                            {textTransform('title', t('noContent'))}
                                        </Typography>
                                        <div className='my-content-no-content-animation-wrapper'>
                                            <div ref={setAnimationContainer} className='my-content-no-content-animation-container'/>
                                        </div>
                                    </>
                        }
                    </div>
                </div>
            </div>  
            <BigFooterNoLinks user={user} />
        </>
    )
}

export default MyContent
