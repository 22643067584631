import {useTheme} from '@material-ui/styles'
import { Button, Paper, Typography } from '@material-ui/core'
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent'
import { useTranslation } from 'react-i18next'
import { useEffect, useState, useRef } from 'react'
import SmallFooter from '../../components/SmallFooter/SmallFooter'
import { decodeJWTToken } from '../../aux/authHelpers'
import PopUpContactSupport from '../../components/PopUpContactSupport /PopUpContactSupport'
// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import errorAnimation from '../../lotties/error.json';
import successAnimation from '../../lotties/success.json';
import './updateEmail.css'
import { useRedirect } from '../../customHooks/useRedirect'
import { isEmptyObject, textTransform } from '../../aux/aux'
import { updateUserEmailByToken } from '../../services/userServices'
import { getCustomErrorOrUndefined } from '../../aux/errorHelpers'
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ClickableLoadingText from '../../components/ClickableLoadingText/ClickableLoadingText'
import { tabMessageApi } from '../../aux/sessionHelpers'

const UpdateEmail = ({ updateEmailToken }) => {
    const USER_ACCOUNG_GENERAL_PROPERTIES_PATH = `/${process.env.REACT_APP_CLASS_SUBDOMAIN}/account/general`
    const theme = useTheme();
    const { t } = useTranslation('updateEmail');
    const redirectApi = useRedirect()
    const [feedback, setFeedback] = useState(null);
    const [tokenPayload, setTokenPayload] = useState(null);
    const [animationContainer, setAnimationContainer] = useState(null);
    const [showSupportModal, setShowSupportModal] = useState(false);
    const animationRef = useRef(null);
    const styles = {
        icon:{
            display:'block',
        },
        oldEmail:{
            color:theme.palette.grey[400]
        }
    }
    /**
     * Note: Note: A falsy or malformed token will throw an InvalidTokenError error.
     * @returns 
     */
    const setPayloadFromToken = (updateEmailToken) => {
        const isValidUpdateEmailPayload = (payload) => {
            return payload.email && payload.newEmail && payload.id
        }
        try{
            const payload = decodeJWTToken(updateEmailToken);
            if(isValidUpdateEmailPayload(payload)){
                setTokenPayload(payload);
            }else{
                throw new Error();
            }
        }catch(err){
            // invalid token
            setFeedback({
                type: 'wrongToken',
                animation: errorAnimation,
                title: textTransform('title', t('uups')),
                msg: `${textTransform('title',t('weCannotUpdateYourEmailNow'))}.\n${textTransform('title',t('theLinkIsWrong'))}`,
            })
        }
    }

    const updateEmail = async (updateEmailToken, signal) => {
        try{
            const res = await updateUserEmailByToken(updateEmailToken, 0, signal);
            const data = res.data;
            const {
                oldCipheredEmail,
                cipheredEmail
            } = data
            if(!signal.aborted){
                if(oldCipheredEmail !== cipheredEmail){
                    tabMessageApi.set('updateUserEmail', data)
                }
                setFeedback({
                    type: 'success',
                    animation: successAnimation,
                    title: textTransform('title', t('emailUpdated')),
                    msg: `${textTransform('title', t('yourAccountIsReadyToLogInWithYourNewEmail'))}.`,
                    action: () => {
                        redirectApi.redirectToRoute(USER_ACCOUNG_GENERAL_PROPERTIES_PATH, {}, true);
                    },
                    actionLabel: t('goToYourProfile')
                })
            }
        }catch(error){
            const customError = getCustomErrorOrUndefined(error);
            let resolveFeedback = {};
            if(customError){
                switch(customError.cause){
                    case 'db_101':
                        // duplicate email
                        resolveFeedback.type = 'duplicateEmail';
                        resolveFeedback.msg = `${textTransform('title', t('emailBelongToAnUser'))} `
                        +`${t('common:and')} ${t('weCannotUpdateYourEmailNow')}.`
                    case 'db_103':
                        // user could not be updated
                        resolveFeedback.type = 'updateUser'
                        resolveFeedback.msg = `${textTransform('title', t('couldNotIdentifyYourEmail'))} `
                        +`${t('common:and')} ${t('weCannotUpdateYourEmailNow')}.`
                        break;
                    case 'sr_005':
                        // wrong token does not contain compulsary params
                        resolveFeedback.type = 'wrongToken';
                        resolveFeedback.msg = `${textTransform('title',t('weCannotUpdateYourEmailNow'))}.\n${textTransform('title',t('theLinkIsWrong'))}`;
                        break;
                    case 'sr_103':
                        // wrong or expired token
                        resolveFeedback.type = 'expiredToken';
                        resolveFeedback.msg = `${textTransform('title',t('weCannotUpdateYourEmailNow'))}.\n${textTransform('title',t('theLinkHasExpired'))}`;
                        break;
                }
            }
            if(isEmptyObject(resolveFeedback)){
                resolveFeedback.type = 'unknown';
                resolveFeedback.msg = `${textTransform('title',t('weCannotUpdateYourEmailNow'))}.\n${textTransform('tite', t('tryItLater'))}` 
            }
            if(!signal.aborted){
                setFeedback({
                    ...resolveFeedback,
                    animation: errorAnimation,
                    title: textTransform('title', t('uups')),
                })
            }
        }
    }

    useEffect(() => {
        setFeedback(null);
        if(updateEmailToken){
            setPayloadFromToken(updateEmailToken);
        }else{
            setTokenPayload(null)
        }
    },[updateEmailToken])

    useEffect(() => {
        if(tokenPayload){
            const abortController = new AbortController();
            updateEmail(updateEmailToken, abortController.signal);
            return(() => {
                abortController.abort();
            })
        }
    },[tokenPayload])

    useEffect(() => {
        if(feedback && feedback.animation && animationContainer){
            animationRef.current = lottie.loadAnimation({
                container: animationContainer,
                renderer: 'svg',
                loop: false,
                autoplay: true,
                animationData: feedback.animation
            });
            return(() => {
                animationRef.current?.stop();
                animationRef.current?.destroy()
            })
        }
    }, [feedback, animationContainer])
    return (
        <div className='update-email-wrapper'>
            <div className='update-email-main-container'>
                {feedback ?
                        <Paper className='update-email-paper'>
                            <div className='update-email-animation' ref={setAnimationContainer}/>
                            {tokenPayload.newEmail && tokenPayload.email ?
                                    <div className='update-email-email-container'>
                                        <div>
                                            <SwapVertIcon style={styles.icon}/>
                                        </div>
                                        <div>
                                            <Typography variant='body1' className='update-email-email'>
                                                {tokenPayload.newEmail}
                                            </Typography>
                                            <Typography variant='body1' className='update-email-email' style={styles.oldEmail}>
                                                {tokenPayload.email}
                                            </Typography>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            <Typography variant='h6' className='update-email-title'>
                                {feedback.title}
                            </Typography>
                            <Typography variant='body1' className='update-email-msg'>
                                {feedback.msg}
                            </Typography>
                            <div className='update-email-button-container'>
                                <Button color='primary' size='large' variant='contained' onClick={feedback.action ? feedback.action  : () => redirectApi.redirectToRoute('/', {}, true)} >
                                    {feedback.actionLabel || t('goToHome')}
                                </Button>
                            </div>
                            {feedback.type != 'success' &&
                                <div className='update-email-support-container'>
                                    <PopUpContactSupport 
                                        showModal={showSupportModal} 
                                        onClickClose={()=>{setShowSupportModal(false)}} 
                                        width={380}
                                        categoryObject={{
                                            categoryArray: ['accountActivation'],
                                            defaultKey: 'accountActivation',
                                        }}

                                    />
                                    <Typography variant='body2'>
                                        {`¿${textTransform('title', t('doYouNeedHelp'))}?`}
                                    </Typography>
                                    <ClickableLoadingText 
                                        text={`${textTransform('title', t('contactSupport'))}`} 
                                        isVisible={true} 
                                        variant='body2' 
                                        onClick={e => {setShowSupportModal(true)}}
                                        underlineOnHover={true}
                                        className={'update-email-support-link'}
                                    />
                                </div>
                            }
                        </Paper>
                    :
                        <LoadingComponent visibleElements='circle'/>
                }
            </div>
            <SmallFooter hideSocialLinks={true} onLightBackground={true} />
        </div>
    )
}

export default UpdateEmail