import React, { useMemo } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useTheme } from '@material-ui/styles'
import HeaderWrapperSection from '../HeaderWrapperSection/HeaderWrapperSection'
import { Typography } from '@material-ui/core'
import ContentConsumptionOption from '../ContentConsumptionOption/ContentConsumptionOption'
import ClickableLoadingText from '../ClickableLoadingText/ClickableLoadingText'
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleOutlineIcon from '../../img/checkCircleOutline.svg'
import AccessAlarmOnIcon from '../../img/accessAlarmOn.svg';
import { getFromSafeObject, isNotEmptyObject, textTransform } from '../../aux/aux'
import { productAmountHasDiscountCouponApplied } from '../../aux/purchaseHelpers'
import './contentConsumptionOptions.css'
import NotIncludedInSubscriptionChip from '../NotIncludedInSubscriptionChip/NotIncludedInSubscriptionChip'
import InfoLabelPopOver from '../InfoLabelPopOver/InfoLabelPopOver'
import { getSubscriptionProductTypeName, getSubscriptionUpgradeProductTypeName, isSubscriptionOrUpgradeSubscriptionProductTypeName, isSubscriptionUpgradeProductTypeName } from '../../aux/productHelpers'

const DEFAULT_MAX_ALT_TO_SHOW = 1;
const DEFAULT_ALT_PRODUCT_CONSUMPTION_OPTION_CONFIG = {
    altProductTypeConsumptionOrderArray: ['subscription','subscription_upgrade'], // Order represents favoritism
    altProductTypeConsumptionConfig:{
        subscription: {
            showBestPrice: false
        },
        subscription_upgrade: {
            showBestPrice: true
        }
    }
}
const DEFAULT_USER = {}
const ContentConsumptionOptions = ({ 
    user,
    onClickProductConsumptionOption,
    productConsumptionOptionMap, 
    altProductConsumptionOptionConfig=DEFAULT_ALT_PRODUCT_CONSUMPTION_OPTION_CONFIG,
    recommendedConsumptionTypeOrderArray=['subscription'],
    maxAltOptionToShow=DEFAULT_MAX_ALT_TO_SHOW,
    title=undefined, 
    subtitle=undefined, 
    wrapperStyle={}
    }) => {

    const { t } = useTranslation('contentProduct', { keyPrefix: 'contentConsumptionOptions' });
    const theme = useTheme()
    const productOptionToShowArray = useMemo(() => {
        const resultArray = [];
        let favoriteOptionIndex = undefined;
        let favoriteOptionRecommendedPosition = undefined
        if(isNotEmptyObject(productConsumptionOptionMap.main)){
            if(recommendedConsumptionTypeOrderArray && recommendedConsumptionTypeOrderArray.length > 0){
                const recommendedPosition = recommendedConsumptionTypeOrderArray.findIndex(productType => productType === 'main');
                if(recommendedPosition >= 0){
                    favoriteOptionIndex = 0;
                    favoriteOptionRecommendedPosition = recommendedPosition;
                } 
            }else{
                favoriteOptionIndex = 0;
            }
            resultArray.push({
                isFavorite: false,
                isMain:true,
                productConsumptionOption: productConsumptionOptionMap.main
            });
        }
        if(isNotEmptyObject(productConsumptionOptionMap.alt)){
            const altResultArray = [];
            const validAltProductTypeConsumptionArray = altProductConsumptionOptionConfig.altProductTypeConsumptionOrderArray;
            for(let i = 0; i < validAltProductTypeConsumptionArray.length; i++){
                const altProductTypeKey = validAltProductTypeConsumptionArray[i];
                if(maxAltOptionToShow > altResultArray.length){
                    const altProductTypeConfig = altProductConsumptionOptionConfig.altProductTypeConsumptionConfig[altProductTypeKey];
                    const productTypeConsumption = productConsumptionOptionMap.alt[altProductTypeKey] || {};
                    const productConsumptionOptionArray = productTypeConsumption.productConsumptionOptionArray || [];
                    if(productConsumptionOptionArray.length > 0){
                        const productConsumptionOptionIndex = altProductTypeConfig.showBestPrice ? productTypeConsumption.productConsumptionOptionBestPriceIndex : productTypeConsumption.productConsumptionOptionDefaultIndex || 0
                        const productConsumptionOption = productConsumptionOptionArray[productConsumptionOptionIndex];
                        if(recommendedConsumptionTypeOrderArray && recommendedConsumptionTypeOrderArray.length > 0){
                            const recommendedPosition = recommendedConsumptionTypeOrderArray.findIndex(productType => productType === altProductTypeKey);
                            if(recommendedPosition >= 0 && (favoriteOptionIndex === undefined || recommendedPosition <= favoriteOptionRecommendedPosition)){
                                favoriteOptionIndex = altResultArray.length + resultArray.length 
                                favoriteOptionRecommendedPosition = recommendedPosition;
                            }
                        }else{
                            favoriteOptionIndex = favoriteOptionIndex === undefined ? altResultArray.length + resultArray.length : favoriteOptionIndex
                        }
                        altResultArray.push({
                            isFavorite: false,
                            isMain:false,
                            productConsumptionOption
                        })
                    }else{
                        continue
                    }
                }else{
                    break;
                }

            }
            resultArray.push(...altResultArray);
        }
        if(favoriteOptionIndex !== undefined){
            resultArray[favoriteOptionIndex].isFavorite = true;
        }
        return resultArray;
    }, [productConsumptionOptionMap, altProductConsumptionOptionConfig, maxAltOptionToShow])

    const consumptionTypesKeyMap = {
        'content': {
            perkKeyArray:['lifetimeAccess', 'allUpdatesAccess'],
            featuresKeyArray: ['oneTimePurchase', 'moneyBackGuarantee'],
            descriptionKey: 'purchase-description',
            joinLabelKey: 'purchase',
            unitTypeKey: 'common:place'
        },
        [getSubscriptionProductTypeName()]: {
            perkKeyArray: ['ourBestContentAccess', 'exclusiveSessionsAccess', 'eventsAndMastersBenefits'],
            featuresKeyArray: ['billedInOneOrSeveralAnuallyTerms'],
            descriptionKey: 'subscription-description',
            joinLabelKey: 'subscribeAndJoin',
            unitTypeKey: 'common:subscription'
        },
        [getSubscriptionUpgradeProductTypeName()]: {
            perkKeyArray: ['allPerksFromYourCurrentSubscription'],
            featuresKeyArray: ['billedInOneOrSeveralAnuallyTerms'],
            descriptionKey: 'subscriptionUpgrade-description',
            joinLabelKey: 'upgradeSubscriptionAndJoin',
            unitTypeKey: 'common:subscription'
        },
    } 

    const styles = {
        wrapper: wrapperStyle,
        description: {
            whiteSpace:'pre-line',
            width: '100%'
        },
        icon:{
            width: '1.2em', // theme.typography.body1.fontSize,
            height: `${theme.typography.body1.lineHeight}em`,
        },
        regularIconPerk:{
            fill: theme.palette.success.main,
        },
        temporalIconPerk:{
            fill: theme.palette.secondary.main,
        },
        perkHighlight:{
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            // textDecoration: `underline ${theme.palette.primary.main}`
        },
    }
    return (
        productOptionToShowArray.length > 0 ?
            <HeaderWrapperSection
                title={title}
                subtitle={subtitle || t("boostYourCareerToday")}
                wrapperClassName='content-consumption-options-wrapper'
                styleMap={{ wrapper: styles.wrapper }}
            >
                <div className='content-consumption-options-main-container'>
                    <Typography variant='body1' style={styles.description}>
                        {t("boostYourCareerToday-description")}
                    </Typography>
                    <div className='content-consumption-options-options-container'>
                        {productOptionToShowArray.map((optionObject, index) => {
                            const {
                                isFavorite,
                                isMain,
                                productConsumptionOption
                            } = optionObject;
                            const productConsumptionOptionTypeName = productConsumptionOption.productTypeName
                            const productTypeConsumptionKeyMap = consumptionTypesKeyMap[productConsumptionOption.productTypeName];
                            const hasDiscountApplied = productAmountHasDiscountCouponApplied(getFromSafeObject(productConsumptionOption, 'consumptionDetails.details.purchasabilityDetails.details.amount'));
                            return(
                                <React.Fragment key={index}>
                                    <ContentConsumptionOption 
                                        className='content-consumption-options-option-container'
                                        isFavorite={isFavorite} 
                                        productConsumptionOption={productConsumptionOption}
                                        productTypeConsumptionKeyMap={productTypeConsumptionKeyMap}
                                        shadow={1}
                                        onClickProductConsumptionOption={onClickProductConsumptionOption}
                                    >
                                        {isSubscriptionUpgradeProductTypeName(productConsumptionOptionTypeName) ?
                                            <div className='content-consumption-options-option-no-included-warning-container'>
                                                <NotIncludedInSubscriptionChip subscriptionTypeName={getFromSafeObject(user,'subscription.subscriptionType.name')} />
                                            </div>
                                            :
                                            null
                                        }
                                        {!isSubscriptionOrUpgradeSubscriptionProductTypeName(productConsumptionOptionTypeName) && isNotEmptyObject(getFromSafeObject(productConsumptionOption, 'consumptionDetails.details.subscriptability')) ?
                                                <div className='content-consumption-options-option-no-subscriptable-warningcontainer'>
                                                    <InfoLabelPopOver 
                                                        label={`${textTransform('title',t("noSubscriptableProduct"))}`}
                                                        labelPopOverMsg={t("productNoIncludedInAnySubscription")}
                                                    />
                                                </div>

                                            :
                                            null
                                        }
                                        <div className='content-consumption-options-option-description-and-perks-container'>
                                            <div className='content-consumption-options-option-description-container'>
                                                <Typography variant="body2">
                                                    {t(productTypeConsumptionKeyMap.descriptionKey)}
                                                </Typography>
                                                {isSubscriptionOrUpgradeSubscriptionProductTypeName(productConsumptionOptionTypeName) &&
                                                    <ClickableLoadingText 
                                                        isLoading={false} 
                                                        isVisible={true} 
                                                        text={textTransform('title', t('common:knowMore'))} 
                                                        underlineOnHover
                                                        variant='body2'
                                                    />
                                                }
                                            </div>
                                            <div className='content-consumption-options-option-perks-container'>
                                                {productTypeConsumptionKeyMap.perkKeyArray.map((perkKey, index) => 
                                                    <div key={index} className='content-consumption-options-option-perk-container'>
                                                        <CheckCircleOutlineIcon style={{...styles.icon, ...styles.regularIconPerk}} className='content-consumption-options-option-perk-icon'/>
                                                        <Typography variant='body1'>
                                                            <Trans t={t} i18nKey={perkKey} components={{ highlight: <span style={styles.perkHighlight} /> }}/>
                                                        </Typography>
                                                    </div>
                                                )}
                                                {hasDiscountApplied &&
                                                    <div className='content-consumption-options-option-perk-container'>
                                                        <AccessAlarmOnIcon style={{...styles.icon, ...styles.temporalIconPerk}} className='content-consumption-options-option-perk-icon'/>
                                                        <Typography variant='body1'>
                                                            {t('timeLimitedDiscount')}
                                                        </Typography>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </ContentConsumptionOption>
                                </React.Fragment>
                            )
                        })}
                    </div>
                </div>
            </HeaderWrapperSection>
        :
            null
    )
}

export default ContentConsumptionOptions