import { useState, useRef, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useTheme } from '@material-ui/styles' 

import { isNotEmptyObject, textTransform } from '../../aux/aux'

import ClassContent from '../ClassContent/ClassContent'
import AddUserNote from '../AddUserNote/AddUserNote'
import { useTranslation } from 'react-i18next'
import { createUserLessonNote } from '../../services/contentServices'
import { triggerEventWithData } from '../../aux/internalCommunicationHelpers'
import ClassContentHeader from '../ClassContentHeader/ClassContentHeader'
import './classroomMainColumn.css'

const ClassroomMainColumn = ({
    userConfig, userProgress, userAccessDetails, contentSummary, contentStructure, contentSelected,
    contentNav, contentNavMoves, onUpdateUserConfig, onUpdateUserProgress, onFetchUserProgress
}) => {
    const ERROR_TIMEOUT_MS = 4000;
    const MAX_LENGTH_NOTE = 2000;
    const MAIN_COLUMN_HEADER_HEIGHT = 60;
    const theme = useTheme();
    const { t } = useTranslation('classroom', { keyPrefix: 'classroomMainColumn' });
    const [lastScrollYNorm, _setLastScrollYNorm] = useState(0);
    const [isScrolling, _setIsScrolling] = useState(false);
    const [isContentHeaderToogled, _setIsContentHeaderToogled] = useState(true);
    const lastScrollYNormRef = useRef(lastScrollYNorm);
    const contentContainerRef = useRef(null);

    const setLastScrollYNorm = (data) => {
        lastScrollYNormRef.current = data;
        _setLastScrollYNorm(data);
    }
    const isScrollingYRef = useRef(isScrolling);
    const setIsScrolling = (data) => {
        isScrollingYRef.current = data;
        _setIsScrolling(data);
    }
    const isContentHeaderToogledRef = useRef(isContentHeaderToogled);
    const setIsContentHeaderToogled = (data) => {
        isContentHeaderToogledRef.current = data;
        _setIsContentHeaderToogled(data);
    }

    const styles = {
        headerStyle:{
            height: isContentHeaderToogled ? `${MAIN_COLUMN_HEADER_HEIGHT}px` : '0',
            opacity: isContentHeaderToogled ? '1' : '0',
            backgroundColor: theme.palette.background.paper,
            borderBottom: `solid 1px ${theme.palette.grey[100]}`
        },
        mainContainer:{
            backgroundColor: theme.palette.background.default,
        }
    };

    const isDisabledMenuButton = ( withNoLessonSelected=false ) => {
        const isLessonSelected = (contentSelected) => {
            // currentLessonId is 0 when overview is selected
            // Notice that Boolean(null || undefined) = false
            return Boolean(contentSelected && contentSelected.currentLessonId)
        }
        const passLessonSelectionControl = withNoLessonSelected ? isLessonSelected(contentSelected) : true;
        return userAccessDetails?.hasAccess !== true || !passLessonSelectionControl 
    }

    const handleRegisterUserLessonNote = async( note, abortSignal, lessonId=contentSelected.currentLessonId, lessonVersionId=contentSelected.currentLessonVersionId, userId=userConfig.userId, contentProductId=userConfig.contentProductId) => {
        try{
            const lessonSelected = {
                lessonId,
                lessonVersionId
            }
            // setIsRegistering(true);
            const res = await createUserLessonNote( userId, contentProductId, lessonSelected, note, 0, abortSignal);
            const {
                userNote
            } = res.data;
            triggerEventWithData('newUserLessonNote', { userNote })
            return true;
        }catch(error){
            // It is handles on the AddUserNote instance
            return false;
        }finally{
            if(!abortSignal.aborted){
                // setIsRegistering(false);
            }
        }
    }

    const scrollTrigger = (e) => {
        let updateToggleValue;
        const isScrolling = isScrollingYRef.current;
        const isContentHeaderToogled = isContentHeaderToogledRef.current;
        const scrollTopOffset = e.target.scrollTop;
        const scrollTopOffsetNorm = scrollTopOffset + (isContentHeaderToogled ? 0 : MAIN_COLUMN_HEADER_HEIGHT);
        if(scrollTopOffsetNorm <= MAIN_COLUMN_HEADER_HEIGHT ){
            if(!isContentHeaderToogled){
                updateToggleValue = true;
            }
        }
        if(!isScrolling && updateToggleValue == null){
            setIsScrolling(true);
            const lastScrollNorm = lastScrollYNormRef.current;
            const scrollDiff = scrollTopOffsetNorm - lastScrollNorm;
            const isOverDiffThreshold = Math.abs(scrollDiff) > MAIN_COLUMN_HEADER_HEIGHT;
            if( scrollDiff <= 0){
                if(isOverDiffThreshold){
                    updateToggleValue = true;
                }
            }else{
                if(isOverDiffThreshold){
                    updateToggleValue = false;
                }
            }
            setLastScrollYNorm(scrollTopOffsetNorm);
            setTimeout(() => {
                setIsScrolling(false);
            }, 300);
        }
        if(updateToggleValue != null && updateToggleValue != isContentHeaderToogledRef.current){
            setIsContentHeaderToogled(updateToggleValue);
        }
    };

    useEffect(() => {
        if(contentContainerRef.current){
            // This variable is needed to make the parent accessible on the cleanup
            // Make it visible in this context
            const parentElement = contentContainerRef.current.parentElement;
            setLastScrollYNorm(parentElement.scrollTop);
            parentElement.addEventListener('scroll', scrollTrigger);
            return(() => {
                parentElement.removeEventListener('scroll', scrollTrigger);
            })
        }
    },[contentSelected])

    return (
        <Grid container direction='column' className='classroom-main-column-container' ref={contentContainerRef} style={styles.mainContainer} >
            <Grid container item className='classroom-main-column-header' style={ styles.headerStyle }>
                <ClassContentHeader userConfig={userConfig} userAccessDetails={userAccessDetails} contentSummary={contentSummary} contentStructure={contentStructure} contentSelected={contentSelected} contentNav={contentNav} contentNavMoves={contentNavMoves} onUpdateUserConfig={onUpdateUserConfig} />
            </Grid>
            <Grid item className='classroom-main-column-content' style={ styles.mainColumnStyle } xs>
                {isNotEmptyObject(contentSelected) ?
                    <ClassContent 
                        userAccessDetails={userAccessDetails} 
                        contentSummary={contentSummary} 
                        userConfig={userConfig} 
                        updateUserConfig={onUpdateUserConfig} 
                        userProgress={userProgress}
                        updateUserProgress={onUpdateUserProgress} 
                        onFetchUserProgress={onFetchUserProgress}
                        contentSelected={contentSelected} 
                        contentNavMoves={contentNavMoves}
                    />
                    :
                    null
                }
            </Grid>
            {!isDisabledMenuButton(true) ?
                    <div className='classroom-main-column-add-user-note-wrapper'>
                        <div className='classroom-main-column-add-user-note-container'>
                            <AddUserNote 
                                noteTypeName={'regular'}
                                onCreate={handleRegisterUserLessonNote}
                                isRightPosition={true}
                                maxNoteLength={MAX_LENGTH_NOTE} 
                                errorTimeoutMs= {ERROR_TIMEOUT_MS}
                                errorMsg= { `${textTransform('title', t('errorRegisteringNote'))}.\n${textTransform('title',t('tryLater'))}` }
                            /> 
                        </div>
                    </div>
                :
                    null
            }
        </Grid>
    )
}

export default ClassroomMainColumn