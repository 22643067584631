import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { textTransform } from '../../aux/aux';
// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import calendar from '../../lotties/calendar.json';
import bird from '../../lotties/bird.json';

import './resourcePageError.css'
import { useRedirect } from '../../customHooks/useRedirect';

const DEFAULT_REDIRECT_DETAILS = {
    path: null,
    label: null
}
const ResourcePageError = ({ errorDetails, resourceType=null, redirectDetails=DEFAULT_REDIRECT_DETAILS }) => {
    const { t } = useTranslation('common', { keyPrefix: 'resourcePageError' });
    const redirectApi = useRedirect();
    let errorTypeKey;
    if(errorDetails?.noAvail){
        errorTypeKey = 'noAvail';
    }else if(errorDetails?.requestingDataError){
        errorTypeKey = 'requestingDataError';
    }else{
        errorTypeKey = 'generalError'
    }
    const errorTypeMap = {
        noAvail:{
            type:'noAvail',
            titleMsgKey:'uups',
            errorMsgKey: 'resourceNotAvailableNow',
        },
        requestingDataError:{
            type:'requestingDataError',
            titleMsgKey:'uups',
            errorMsgKey: 'resourceNotAccessible',
        },
        generalError:{
            type:'generalError',
            titleMsgKey:'uups',
            errorMsgKey: 'resourceNotAccessible',
        }
    }

    const animationContainerRef = useRef(null);
    const animationRef = useRef(null);
    const [errorType, setErrorType] = useState(errorTypeMap[errorTypeKey]);

    const handleOnClick = (e) => {
        redirectApi.redirectToRoute(redirectDetails.path)
    }

    useEffect(() => {
        let animationComponent
        switch(errorType.type){
            case 'noAvail':
                animationComponent = calendar;
                break;
            case 'requestingDataError':
            default:
                animationComponent = bird;
        };
        if(animationContainerRef.current){
            animationRef.current = lottie.loadAnimation({
                container: animationContainerRef.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: animationComponent
            });
            return(() => {animationRef.current.stop(); animationRef.current?.destroy()})
        }

    },[errorType])

    const getErrorMessage = () => {
        let msg;
        msg = textTransform('title', t(errorType.errorMsgKey, { resourceType: t(`resourceTypeMap.${resourceType}`) }));
        switch(errorType.type){
            case 'noAvail':
                break;
            case 'requestingDataError':
                msg += `. ${textTransform('title', t('tryItLater'))}`
                break;
        }
        return msg
    }

    return (
        <Grid container className='resource-page-error-main-container'>
            <Grid item>
                <Grid container direction='column' className='resource-page-error-container'>
                    <Grid item>
                        <Box ref={animationContainerRef}>
                            { animationRef.current?.play() }
                        </Box>
                    </Grid>
                    <Grid item>
                        <Typography className='resource-page-error-title'>
                            {textTransform('title', t(errorType.titleMsgKey))}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography className='resource-page-error-text'>
                            {getErrorMessage()}
                        </Typography>
                    </Grid>
                    { redirectDetails.path && redirectDetails.label && 
                        <Grid item className='resource-page-error-button-container'>
                            <Button variant='outlined' color='primary' onClick={handleOnClick}>
                                {textTransform('title', redirectDetails.label)}
                            </Button>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ResourcePageError