import { Grid, Typography, useMediaQuery } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import './valuePropositionItem.css'
import StyledTypography from '../StyledTypography/StyledTypography'

const ValuePropositionItem = ({ itemNum, title, description, keyPointArray, imgURL, imgDecoratorURL, reversed=false }) => {
    const theme = useTheme()
    const matchMobile = useMediaQuery('(max-width:680px)');
    const styles = {
        title:{
            color: theme.palette.text.primary,
            fontWeight: 'bold'
        },
        icon:{
            display:'block',
            width: '1.2em',
            fill: theme.palette.primary.main
        }
    }
    return (
        <div className='value-proposition-item-wrapper'>
            <Grid container className='value-proposition-item-main-container'>
                <Grid item  className={`value-proposition-item-content-container ${reversed ? 'reversed-item' : ''}`}>
                    <Grid container direction='column'>
                        <Grid item className='value-proposition-item-content-title'>
                            <StyledTypography variant={matchMobile ? 'h6' : 'h5'} style={styles.title}>
                                {title}
                            </StyledTypography>
                        </Grid>
                        <Grid item className='value-proposition-item-content-description'>
                            <StyledTypography variant="body1">
                                {description}
                            </StyledTypography>
                        </Grid>
                        {keyPointArray.map((keyPoint, index) => {
                            const {
                                title,
                                Icon
                            } = keyPoint;
                            return(
                                <Grid key={index} item container className='value-proposition-item-content-key-point-container'>
                                    <Grid item className='value-proposition-item-content-key-point-icon'>
                                        <Icon style={styles.icon} />
                                    </Grid>
                                    <Grid item xs className='value-proposition-item-content-key-point-title'>
                                        <StyledTypography variant='body1'>
                                            {title}
                                        </StyledTypography>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
                <Grid item className='value-proposition-item-img-container'>
                    {/* <div className='value-proposition-item-img' /> */}
                    <img src={imgURL} className='value-proposition-item-img' alt={`value-proposition-${itemNum}`}/>
                    <img src={imgDecoratorURL} className={`value-proposition-item-img-decorator show`} alt={`value-proposition-decorator-${itemNum}`}/>
                </Grid>
            </Grid>
        </div>
    )
}

export default ValuePropositionItem