import { useEffect, useState, useCallback } from "react";

export const useDetectSticky = (initialValue=true, observerSettings={threshold:[1], rootMargin:'0px'}) => {
    const [isSticky, setIsSticky] = useState(initialValue);
    const [cacheRef, setCacheRef] = useState(null);
    const setRef = useCallback(node => {
        if(node !== null){
            setCacheRef(node)
        }
    },[]);

    useEffect(() => {
        if(cacheRef){
            // IntersectionObserver is triggered always the first time
            // this help us to control what is our initial value
            let isFirstTrigger = true;
            const observer = new IntersectionObserver(
                ([e]) => {
                    setIsSticky(() => {
                        let newState;
                        if(isFirstTrigger && typeof initialValue === 'boolean'){
                            isFirstTrigger = false;
                            newState = initialValue;
                        }else{
                            newState = e.intersectionRatio < 1 && e.boundingClientRect.top > 0
                        }
                        return newState
                    })
                },
                observerSettings
            )
            observer.observe(cacheRef);
            return(() => {
                observer.disconnect();
            })
        }
    },[cacheRef])

    return [isSticky, setRef]
}