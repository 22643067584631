/**
 * This module contains helper functions to handle errors from the API
 */
const CUSTOM_ERROR_NAME = 'CustomError'

function isCustomError(error){
    return error.response?.data?.name === CUSTOM_ERROR_NAME;
};

function getCustomErrorOrUndefined(error){
    return isCustomError(error) ? error.response.data : undefined;
}

export{
    isCustomError,
    getCustomErrorOrUndefined
};