import { Typography } from '@material-ui/core'
import { useLocaleCtx } from '../../customHooks/useLocaleCtx';
import { getSafeDateOrUndefined } from '../../aux/aux';
const DEFAULT_DATE_FORMAT_OPTIONS  = {
    // weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZoneName: 'short'
};
const LocaleDateTypography = ({date, dateFormatOptions=DEFAULT_DATE_FORMAT_OPTIONS, ...props}) => {
    const localeCtx = useLocaleCtx();
    const safeDate = getSafeDateOrUndefined(date);
    return (
        safeDate ?
            <Typography {...props}>
                {safeDate.toLocaleDateString(localeCtx.localeCode, dateFormatOptions)}
            </Typography>
            :
            null
    )
}

export default LocaleDateTypography