import { Children, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography, Button } from '@material-ui/core'
import ButtonLoading from '../ButtonLoading/ButtonLoading'
import { useTheme } from '@material-ui/styles'
import './simpleNoModalDialog.css'

const SimpleNoModalDialog = ({ contentText, onAgree, onDisagree=undefined, title='', buttonSize='large', ...props }) => {
    const theme = useTheme()
    const { t } = useTranslation('common');
    const {
        agreeLabel = t('ok'),
        disagreeLabel = t('cancel'),
        isLoading = false, 
        isDisabled = false,
        isDisagreeDisabled = false,
        isError = false,
        errorMsg = 'error',
        errorStyle = {
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main,
        },
        agreeButtonType = 'button'
    } = props;

    return (
        <Grid container direction='column' className={`simple-no-modal-dialog-main-container ${props.className ? props.className : ''}`} style={props.style}>
            {title &&
                <Grid item className='simple-no-modal-dialog-title-container'>
                    <Typography variant='body1' className='simple-no-modal-dialog-title'>
                        {title}
                    </Typography>
                </Grid>
            }
            {contentText &&
            <Grid item className={`simple-no-modal-dialog-content-text ${title ? 'simple-no-modal-dialog-item' : ''}`}>
                <Typography variant='body1'>
                    {contentText}
                </Typography>
            </Grid>
            }
            {Children.count(props.children) > 0 && 
                Children.map(props.children, (child, index) => {
                    return(
                        <Grid item 
                            key={index} 
                            style={props.childrenStyle}
                            className={`simple-no-modal-dialog-content-text ${props.childrenClassName ? props.childrenClassName : ''}  ${title || contentText ? 'simple-no-modal-dialog-item' : ''}`}
                        >
                            {child}
                        </Grid>
                    )
            })}
            <Grid item className='simple-no-modal-dialog-actions simple-no-modal-dialog-item'>
                <Grid container justifyContent='flex-end'>
                    {onDisagree &&
                        <Grid item className='simple-no-modal-dialog-button'>
                            <Button 
                                onClick={onDisagree} 
                                color='primary'
                                disabled={isDisagreeDisabled}
                                style={{height:'100%'}}
                                size={buttonSize}
                            >
                                {disagreeLabel}
                            </Button>
                        </Grid>
                    }
                    <Grid item className='simple-no-modal-dialog-button'>
                        <ButtonLoading 
                            type={agreeButtonType}
                            onClick={ agreeButtonType !== 'submit' ? onAgree : undefined} 
                            isLoading={isLoading}
                            disabled={isDisabled}
                            color='primary'
                            variant='contained' 
                            label={agreeLabel} 
                            size={buttonSize}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={`simple-no-modal-dialog-error-toogle ${isError ? 'show' : ''}`} style={ errorStyle }>
                <Typography variant='body2'>
                    { errorMsg }
                </Typography>
            </Grid>
        </Grid>
    )     
}

export default SimpleNoModalDialog