import { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { textTransform } from '../../aux/aux';
import MaximizeDeviceInFigures from '../MaximizeDeviceInFigures/MaximizeDeviceInFigures';
import './landingHowItWorks.css';
import StyledTypography from '../StyledTypography/StyledTypography';

const LandingHowItWorks = () => {
    const { t } = useTranslation('landing', {keyPrefix:'landingHowItWorks'});
    const theme = useTheme()
    const matchDesktop = useMediaQuery('(min-width: 1120px)');
    const matchMobile = useMediaQuery('(max-width:680px)');
    const matchShortScreen = useMediaQuery('(max-height: 600px)'); 
    const matchLandscape = useMediaQuery('(orientation:landscape)');
    const [currentSectionId, _setCurrentSectionId] = useState(0);
    const currentSectionIdRef = useRef(currentSectionId);
    const titleAndProgressRef = useRef(null);
    const setCurrentSectionId = (elem) => {
        _setCurrentSectionId(elem);
        currentSectionIdRef.current = elem;
    }
    const [scrollYPX, setScrollYPX] = useState(0);
    const [componentHeight, setComponentHeight] = useState(null);
    const sectionArrayRef = useRef([]);
    const wrapperRef = useRef(null);
    const styles = {
        titleAndProgressContainer: {
            backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0), ${theme.palette.background.default} 20%)`,
        },
        title:{
            fontWeight: 'bold',
            textAlign: 'center',
            whiteSpace: 'pre-line',
        },
        sectionTitle:{
            fontWeight: 'bold',
        },
        sectionDescription:{
            whiteSpace:'pre-line',
        },
        scrollGuide:{
            clipPath: `inset(8px ${parseInt(100 - scrollYPX / componentHeight * 100)}% 8px 0px round 5px)`,
            backgroundImage: `linear-gradient(45deg, ${theme.palette.qxi.yellow}, ${theme.palette.qxi.green}, ${theme.palette.qxi.blue}, ${theme.palette.qxi.pink})`,
        }
    }

    const sectionArray = [
        {
            title: 'expandYourLimits',
            description: 'expandYourLimitsDescription',
            imgDecoratorURL: '/img/decorator-howItWorks-1_400x250.svg',
            imgURL:'/img/howItWorks-1_400x250.svg',
        },
        {
            title: 'connectWithMentors',
            description: 'connectWithMentorsDescription',
            imgDecoratorURL: '/img/decorator-howItWorks-2_400x250.svg',
            imgURL: '/img/howItWorks-2_400x250.svg',
        },
        {
            title: 'nonStopLearning',
            description: 'nonStopLearningDescription',
            imgDecoratorURL: '/img/decorator-howItWorks-3_400x250.svg',
            imgURL: '/img/howItWorks-3_400x250.svg',
        },
        {
            title: 'maximizeYourSystem',
            description: 'maximizeYourSystemDescription',
            component: MaximizeDeviceInFigures
        },
    ]

    useEffect(() => {
        const handleScroll = (e) => {
            const wrapperBoundingRect = wrapperRef.current.getBoundingClientRect();
            const scrollTop = wrapperBoundingRect.top * -1 - titleAndProgressContainerHeight; // inverse direction makes easy to compute 
            if(scrollTop >= 0){
                setScrollYPX(scrollTop);
            }
        }

        let isScrollListener = false;
        const wrapperObserver = new IntersectionObserver(elements => {
            const elem = elements[0];
            if(elem.intersectionRatio > 0){
                setComponentHeight(elem.boundingClientRect.height - titleAndProgressContainerHeight);
                if(!isScrollListener){
                    document.addEventListener('scroll', handleScroll);
                    isScrollListener = true;
                }
            }else{
                document.removeEventListener('scroll', handleScroll);
                isScrollListener = false;
            }
        })

        const sectionObserver = new IntersectionObserver(elements => {
            elements.forEach((elem) => {
                if(elem.isIntersecting){
                    const intersectedSectionId = parseInt(elem.target.getAttribute('data-id'));
                    if(elem.intersectionRatio >= 0.5 && currentSectionIdRef.current != intersectedSectionId){
                        setCurrentSectionId(intersectedSectionId);
                    }
                }
            })
        }, { threshold: 0.5 });

        let titleAndProgressContainerHeight = titleAndProgressRef.current ? titleAndProgressRef.current.getBoundingClientRect().height : 0;
        if(wrapperRef.current){
            wrapperObserver.observe(wrapperRef.current);
        }
        if(sectionArrayRef.current.length > 0){
            sectionArrayRef.current.forEach((section, index) => {
                sectionObserver.observe(section)
            });
        }

        return(() => {
            sectionObserver.disconnect();
            wrapperObserver.disconnect();
            document.removeEventListener('scroll', handleScroll);
        })
    },[matchDesktop, matchMobile])

    return (
        <div 
            className='landing-how-it-works-wrapper'
            ref={wrapperRef}
        >
            <div className='landing-how-it-works-main-container'>
                <div ref={titleAndProgressRef} className='landing-how-it-works-title-and-progress-container' style={styles.titleAndProgressContainer}>
                    <StyledTypography variant={matchMobile ? 'h5' : matchDesktop ? 'h4' : matchLandscape ? 'h4' : 'h4'} style={styles.title}>
                        {textTransform('title', t('masterYourQuantumSystem'))}
                    </StyledTypography>
                    {!matchShortScreen &&
                        <div className='landing-how-it-works-scroll-guide-wrapper'  style={styles.scrollGuide}>
                            <div className='landing-how-it-works-scroll-guide'/>
                        </div>
                    }
                </div>
                {!matchMobile ? 
                    <div className='landing-how-it-works-sections-container'>
                        <div className='landing-how-it-works-text-column'>
                            {sectionArray.map((section, index) => {
                                return(
                                    <div key={index} className='landing-how-it-works-text-container' data-id={index} ref={element => sectionArrayRef.current[index] = element}>
                                        <StyledTypography variant='h5' style={styles.sectionTitle}>
                                            {t(section.title)}
                                        </StyledTypography>
                                        <StyledTypography variant='body1' style={styles.sectionDescription}>
                                            {t(section.description)}
                                        </StyledTypography>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='landing-how-it-works-img-column'>
                            {sectionArray.map((section, index) => {
                                const hasComponent = section.component !== undefined;
                                const isCurrent = index === currentSectionId;
                                return(
                                    <div key={index} className={`landing-how-it-works-img-wrapper${isCurrent || matchShortScreen ? ' show' : ''}`}>
                                        {hasComponent ?
                                                <div className='landing-how-it-works-img-component-container'>
                                                    <section.component className='landing-how-it-works-img-component'/>
                                                </div>
                                            :
                                                <div className={`landing-how-it-works-img-container${isCurrent || matchShortScreen ? ' show' : ''}`}>
                                                    <img src={section.imgURL} className='landing-how-it-works-img' alt={`how it works - 1`}/>
                                                    <img src={section.imgDecoratorURL} className={`landing-how-it-works-img-decorator${isCurrent || matchShortScreen ? ' show' : ''} `} alt={`how it works decorator - 1`}/>
                                                </div>
                                        }
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    :
                    <div className='landing-how-it-works-sections-container'>
                        {sectionArray.map((section, index) => {
                            const hasComponent = section.component !== undefined;
                            const isCurrent = index === currentSectionId;
                            const lastSection = index === (sectionArray.length - 1);
                            return(
                                <div key={index} data-id={index} ref={element => sectionArrayRef.current[index] = element} className={`landing-how-it-works-section-container${isCurrent ? ' show' : ''}`}>
                                    <div className={`landing-how-it-works-img-wrapper${isCurrent ? ' show' : ''}`}>
                                        {hasComponent ?
                                                <div className='landing-how-it-works-img-component-container'>
                                                    <section.component className='landing-how-it-works-img-component'/>
                                                </div>
                                            :
                                                <div className={`landing-how-it-works-img-container${isCurrent ? ' show' : ''}`}>
                                                    <img src={section.imgURL} className='landing-how-it-works-img' alt={`how it works - 1`}/>
                                                    <img src={section.imgDecoratorURL} className={`landing-how-it-works-img-decorator${isCurrent ? ' show' : ''} `} alt={`how it works decorator - 1`}/>
                                                </div>
                                        }
                                    </div>
                                    <div key={index} className='landing-how-it-works-text-container' data-id={index} ref={element => sectionArrayRef.current[index] = element}>
                                        <StyledTypography variant='h6' style={styles.sectionTitle}>
                                            {t(section.title)}
                                        </StyledTypography>
                                        <StyledTypography variant='body1' style={styles.sectionDescription}>
                                            {t(section.description)}
                                        </StyledTypography>
                                    </div>
                                </div>

                            )
                        })}
                        <div className='landing-how-it-works-extra-section'/>
                    </div>
                }
            </div>
        </div>
    )
}

export default LandingHowItWorks