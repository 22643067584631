const extendedTimeoutIdMap = {};

function setTimeout_(fn, delay){
    const MAX_DELAY = Math.pow(2, 31)-1
    if(delay > MAX_DELAY){
        const args = arguments;
        args[1] -= MAX_DELAY;
        const context = { originalId: this?.originalId };
        const id = setTimeout( function () {
            if(context.originalId == undefined){
                context.originalId = id;
            }
            setTimeout_.apply(context, args)
        }, MAX_DELAY);
        if(context.originalId === undefined){
            extendedTimeoutIdMap[id] = id;
        }else{
            extendedTimeoutIdMap[context.originalId] = id;
        }
        return id;
    }else{
        return setTimeout.apply(undefined, arguments);
    }
}

function clearTimeout_(id){
    let timeoutId = extendedTimeoutIdMap[id];
    if(timeoutId === undefined){
        timeoutId = id;
    }else{
        delete extendedTimeoutIdMap[id];
    }
    clearTimeout(timeoutId);
}

export {
    setTimeout_,
    clearTimeout_
}