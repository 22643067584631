import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/styles';
import { Typography, Grid, Box, IconButton, useMediaQuery } from '@material-ui/core'

import PopUpModalHeader from '../PopUpModalHeader/PopUpModalHeader';
import AddUserNote from '../AddUserNote/AddUserNote';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import UserNote from '../UserNote/UserNote';
import PopUpModal from '../PopUpModal/PopUpModal';
import SimpleNoModalDialog from '../SimpleNoModalDialog/SimpleNoModalDialog';

import { 
    createUserLessonNote, getUserLessonNotes, deleteUserLessonNote,
    updateUserLessonNote
 } from '../../services/contentServices';

import { isEmptyObject, textTransform } from '../../aux/aux';
// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import astronaut from '../../lotties/astronaut_300x150.json';
import warningAnimation from '../../lotties/warning.json';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import './classNotes.css'


const ClassNotes = ({ userConfig, contentSelected, isShown }) => {
    const darkTheme = false;
    const ANIMATION_DELAY_MS = 400;
    const ERROR_TIMEOUT_MS = 4000;
    const MAX_LENGTH_NOTE = 2000;
    const TRUNCATE_LENGTH = 200;
    const MAX_REQUESTING_INTENTS = 3;
    const readOnlyConfig = { truncateLength: TRUNCATE_LENGTH };
    const theme = useTheme()
    const { t } = useTranslation('classroom', { keyPrefix: 'classNotes' });
    const matchDesktop = useMediaQuery('(min-width:1120px)');
    const componentStatus = ['mounting', 'requesting', 'ready', 'error']
    const animationContainerRef = useRef(null);
    const animationRef = useRef(null);
    const [refreshingUserLessonNotes, setRefresingUserLessonNotes] = useState(false);
    const [requestingIntentsCount, setRequestingIntentsCount] = useState(0);
    const [classNotesStatus, setClassNotesStatus] = useState(null);
    const [isRegistering, setIsRegistering] = useState(false);
    const [isRequesting, setIsRequesting] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);
    const [isError, setIsError] = useState({
        requesting: false,
        removing: false,
    });
    const [userNotesArray, setUserNotesArray] = useState(null);
    const [noteToRemove, setNoteToRemove] = useState(null);
    const styles = {
        notesWrapper: {
            backgroundColor: darkTheme ? '#1c1d1f' :  '#fafafa' 
        },
        notesInfo: {
            color: '#888'
        },
        refreshIcon: {
            color: '#888',
            opacity: requestingIntentsCount > MAX_REQUESTING_INTENTS ? 0.3 : 1

        }
    }
    const requestUserLessonNotes = async ( lessonId, lessonVersionId, abortSignal, userId=userConfig.userId, contentProductId=userConfig.contentProductId ) => {
        try{
            setIsRequesting(true)
            const filter = {
                sorting: {
                    by: 'date',
                    descending: true,
                }
            }
            const lessonSelected = {
                lessonId,
                lessonVersionId
            }
            const res = await getUserLessonNotes( userId, contentProductId, lessonSelected, filter, 0, abortSignal);
            const {
                userAccessDetails,
                userLessonNotesArray,
            } = res.data;
            if(!abortSignal.aborted){
                setUserNotesArray(userLessonNotesArray);
            }
        }catch(error){
            // this allow us to set error state
            // in the component
            setUserNotesArray(null);
            setIsError( isError => ({ ...isError, requesting:true }))
            //
        }finally{
            if(!abortSignal.aborted){
                setIsRequesting(false);
                setRequestingIntentsCount( requestingDataCount => requestingDataCount + 1);
                setRefresingUserLessonNotes(false);
            }
        }
    }

    const registerUserLessonNote = async ( note, abortSignal, lessonId=contentSelected.currentLessonId, lessonVersionId=contentSelected.currentLessonVersionId, userId=userConfig.userId, contentProductId=userConfig.contentProductId) => {
        try{
            const lessonSelected = {
                lessonId,
                lessonVersionId
            }
            setIsRegistering(true);
            const res = await createUserLessonNote( userId, contentProductId, lessonSelected, note, 0, abortSignal);
            const {
                userNote
            } = res.data;
            if(!abortSignal.aborted){
                setUserNotesArray( userNotesArray => {
                    // TODO: resort userNotesArray could be needed
                    return [userNote, ...userNotesArray]
                })
            }
            return true;
        }catch(error){
            // It is handles on the AddUserNote instance
            return false;
        }finally{
            if(!abortSignal.aborted){
                setIsRegistering(false);
            }
        }
    }

    const removeUserLessonNote = async (note, abortSignal, userId=userConfig.userId) => {
        try{
            setIsRemoving(true);
            const res = await deleteUserLessonNote( userId, note.id, note.noteTypeName, 0, abortSignal);
            const {
                userLessonNoteId
            } = res.data;
            if(userLessonNoteId){
                setUserNotesArray( userNotesArray => ( userNotesArray.filter( otherNote => ( otherNote.id !== userLessonNoteId))))
                handleOnCancelRemoveModal();
            }
        }catch(error){
            setIsError( isError => ({...isError, removing: true }));
        }finally{
            setIsRemoving(false);
        }
    }

    /**
     * 
     * @param {*} note 
     * @param {*} updatedValues 
     * @param {*} abortSignal 
     * @param {*} userId 
     * @returns {boolean} true if the note was successfully modified, false otherwise. 
     */
    const modifyUserLessonNote = async (note, updatedValues, abortSignal, userId=userConfig.userId) => {
        try{
            const res = await updateUserLessonNote( userId, note.id, note.noteTypeName, updatedValues, 0, abortSignal );
            const {
                userLessonNoteId
            } = res.data;
            const userLessonNoteIndex = userNotesArray.findIndex( otherNote => otherNote.id === userLessonNoteId);
            if(userLessonNoteId && userLessonNoteIndex >= 0){
                setUserNotesArray( userNoteArray => {
                    userNoteArray[userLessonNoteIndex] = { ...note, ...updatedValues };
                    return [...userNoteArray];
                });
            }
            return true;
        }catch(error){
            // It is handles on each userNote instance
            return false;
        }
    }

    const getComponentToRender = () => {
        let componentToRender;
        switch(classNotesStatus){
            case componentStatus[0]:
                // mounting
                break;
            case componentStatus[1]:
                // requesting
                componentToRender = <LoadingComponent visibleElements='circle' />;
                break;
            case componentStatus[2]:
                // ready
                let userNotesComponentsArray = userNotesArray.map(( note, index ) => {
                    return(
                        <Grid key={note.id} item className='class-notes-user-note'>
                            <UserNote 
                                note={note} 
                                readOnlyConfig={readOnlyConfig} 
                                onRemove={handleOnShowRemoveModal} 
                                onUpdate={modifyUserLessonNote} 
                                maxNoteLength={MAX_LENGTH_NOTE}
                                errorTimeoutMs= {ERROR_TIMEOUT_MS}
                                errorMsg= { `${textTransform('title', t('errorUpdatingNote'))}.\n${textTransform('title',t('tryLater'))}` }
                            />
                        </Grid>
                    )
                })
                if(userNotesComponentsArray.length === 0){
                    // no current notes
                    userNotesComponentsArray = 
                    <>
                        <Grid item style={ styles.notesInfo }>
                            <Typography variant='body2'>
                                {textTransform('title', t('noNotes'))}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <div ref={animationContainerRef} className='class-notes-animation-wrapper'/>
                        </Grid>
                    </>
                }
                componentToRender = 
                    <Grid container direction='column' className='class-notes-container' >
                        <Grid item xs className='class-notes-user-notes-container-wrapper'>
                            <Grid container direction='column' className='class-notes-user-notes-container' >
                                {userNotesComponentsArray}
                            </Grid>   
                        </Grid>
                        <Grid item className='class-notes-user-add-note-container'>
                            <AddUserNote 
                                noteTypeName={'regular'} 
                                onCreate={registerUserLessonNote} 
                                maxNoteLength={MAX_LENGTH_NOTE} 
                                errorTimeoutMs= {ERROR_TIMEOUT_MS}
                                errorMsg= { `${textTransform('title', t('errorRegisteringNote'))}.\n${textTransform('title',t('tryLater'))}` }
                                isRightPosition={!matchDesktop}
                                />
                        </Grid>                        
                    </Grid>
                break;
            default:
                // error
                componentToRender = 
                    <Grid container direction='column' className='class-notes-container'>
                        <Grid item>
                            <Grid container>
                                <Grid item style={ styles.notesInfo } xs>
                                    <Typography variant='body2'>
                                        {textTransform('title', t('errorNotes'))}
                                    </Typography>
                                    <Typography variant='body2'>
                                        { requestingIntentsCount > MAX_REQUESTING_INTENTS &&
                                            textTransform('title', t('tryLater'))
                                        }
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton 
                                        size='small'
                                        disabled={isRequesting || requestingIntentsCount > MAX_REQUESTING_INTENTS}
                                        onClick={ handleOnRefresh }
                                        >
                                        <RefreshIcon 
                                            style={ styles.refreshIcon } />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <div ref={animationContainerRef} className='class-notes-animation-wrapper'/>
                        </Grid>
                    </Grid>
                break;
        }
        return componentToRender
    }

    const handleOnCancelRemoveModal = () => {
        setNoteToRemove(null);
    }

    const handleOnShowRemoveModal = (note) => {
        setNoteToRemove(note);
    }

    const handleOnRemove = async ( e ) => {
        removeUserLessonNote(noteToRemove);
    }

    const handleOnRefresh = ( e ) => {
        setRefresingUserLessonNotes(true);
    }

    // Reset all states except:
    //  - userNotesArray: this is reset in requestingUserLessonNotes
    const resetClassNotes = ( keepRequestCount=false ) => {
        setClassNotesStatus(null);
        setIsRegistering(false);
        setIsRequesting(false);
        setIsRemoving(false);
        setIsError({
            requesting: false,
            removing: false,
            updating: false,
        });
        setNoteToRemove(null);
        if(!keepRequestCount){
            setRequestingIntentsCount(0);
        }
    }

    useEffect(() => {
        if(contentSelected){
            const updateUserLessonNoteArray = (e) => {
                const data = e.detail;
                if(data.hasOwnProperty('userNote')){
                    const userNote = data.userNote;
                    if(contentSelected.currentLessonId === userNote.lessonId && contentSelected.currentLessonVersionId === userNote.lessonVersionId ){
                        setUserNotesArray(prev => ([ userNote, ...prev ]))
                    }
                }
            }
            resetClassNotes();
            const abortController = new AbortController();
            const abortSignal = abortController.signal;
            requestUserLessonNotes( contentSelected.currentLessonId,
                contentSelected.currentLessonVersionId, abortSignal);
            document.addEventListener('newUserLessonNote', updateUserLessonNoteArray)
            return (() => {
                abortController.abort();
                document.removeEventListener('newUserLessonNote', updateUserLessonNoteArray)
            })
        }
    },[contentSelected])

    useEffect(() => {
        if(refreshingUserLessonNotes){
            if(requestingIntentsCount <= MAX_REQUESTING_INTENTS){
                if(isError.requesting){
                    // clear error from possible requests
                    // to reset component status after request
                    setIsError(isError => ({ ...isError, requesting: false }));
                }
                const abortController = new AbortController();
                const abortSignal = abortController.signal;
                requestUserLessonNotes( contentSelected.currentLessonId,
                    contentSelected.currentLessonVersionId, abortSignal);
                return (() => {
                    abortController.abort()
                })
            }
        }
    },[refreshingUserLessonNotes])

    useEffect(() => {
        let status;
        if(isError.requesting){
            status = componentStatus[3];
        }else{
            if(isRequesting){
                status = componentStatus[1];
            }else if(userNotesArray != null){
                status = componentStatus[2];
            }else{
                status = componentStatus[0]
            }
        }
        if(status){
            setClassNotesStatus(status);
        }
    },[isRequesting, userNotesArray, isError.requesting])

    useEffect(() => {
        if( (classNotesStatus === componentStatus[3] && !userNotesArray) 
            || (classNotesStatus === componentStatus[2] && userNotesArray.length === 0)
        ){
            let animationConfig = {}
            switch(classNotesStatus){
                case componentStatus[2]:
                    animationConfig['animationData'] = astronaut;
                    animationConfig['loop'] = true;
                    break;
                case componentStatus[3]:
                    animationConfig['animationData'] = warningAnimation
                    animationConfig['loop'] = false;
            }
            if(animationContainerRef && !isEmptyObject(animationConfig)){
                animationRef.current = lottie.loadAnimation({
                    container: animationContainerRef.current,
                    renderer: 'svg',
                    autoplay: false,
                    ...animationConfig
                })
                if(isShown){
                    animationRef.current.play()
                }
            }
            return(() => {
                animationRef.current.stop();
                animationRef.current?.destroy();
            })
        }
    // userNotesArray was appended to the dependencies array 
    // when 'readyNoNotes' status was removed to load the animation 
    // after userNotesArray is depleted
    }, [classNotesStatus, userNotesArray])

    useEffect(() => {
        if(animationRef.current){
            if(isShown){
                const animationDelay = animationRef.current.loop === false ? ANIMATION_DELAY_MS : 0
                const timeoutId = setTimeout(() => {
                    animationRef.current.play()
                }, animationDelay);
                return(() => clearTimeout(timeoutId))
            }else{
                const timeoutId = setTimeout(() => {
                    animationRef.current.stop()
                }, ANIMATION_DELAY_MS);
                return(() => clearTimeout(timeoutId))
            }
        }
    }, [isShown])

    useEffect(() => {
        if(isError.removing){
            const timeoutId = setTimeout(() => {
                setIsError(( isError ) => ({ isError, removing: false}))
            }, ERROR_TIMEOUT_MS)

            return(() => (clearTimeout(timeoutId)))
        }
    },[isError.removing])

    return (
        <div className='class-notes-wrapper' style={ styles.notesWrapper }>
            <PopUpModal showModal={ noteToRemove != null } isDialog={true} width='380px' >
                <PopUpModalHeader title={ textTransform('title', t('removeYourNote')) } Icon={DeleteIcon} />
                <SimpleNoModalDialog 
                    className='class-notes-modal-dialog'
                    contentText={textTransform('title', t('doYouWantToRemoveIt') )}
                    onAgree={ handleOnRemove } 
                    onDisagree={ handleOnCancelRemoveModal }
                    isLoading={ isRemoving }
                    isDisabled={ isRemoving }
                    isError={ isError.removing }
                    errorMsg={ `${textTransform('title', t('errorRemovingNote'))}.\n${textTransform('title',t('tryLater'))}` }
                />
            </PopUpModal>
            {
                getComponentToRender()
            }
        </div>
    )
}

export default ClassNotes;
