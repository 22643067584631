import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/styles'
import { Chip, Typography, hexToRgb, useMediaQuery } from '@material-ui/core';
import { Skeleton } from '@mui/material';
import { getFromSafeObject, isNotEmptyObject, rgbToRgbaOrUndefined } from '../../aux/aux';
import { textTransform } from '../../aux/aux';
import FavoriteButton from '../FavoriteButton/FavoriteButton';
import { contentProfessorArrayToString, getMainProfessorOrNull } from '../../aux/contentProductHelpers';
import { useUserFavoriteProductCtx } from '../../customHooks/useUserFavoriteProductCtx';

import ImgDN from "../../cdn/imgDN";
import cloudinary from "../../cdn/providers/cloudinary";
import ImgLazyLoad from '../ImgLazyLoad/ImgLazyLoad';
import ContentTypeChip from '../ContentTypeChip/ContentTypeChip';
import './contentResponsiveSliderItem.css'
import { useUpdateUserFavoriteProductCtx } from '../../customHooks/useUpdateUserFavoriteProductCtx';
import MeetingPanelistBadge from '../MeetingPanelistBadge/MeetingPanelistBadge';
import SocialProfileBadgeWithPopover from '../SocialProfileBadgeWithPopover/SocialProfileBadgeWithPopover';

const ContentResponsiveSliderItem = ({ content, fullHeightAuthorDisplay=false, userRelationship=null }) => {
    const theme = useTheme();
    const { t } = useTranslation('common');
    const { isUserFavoriteProduct } = useUserFavoriteProductCtx()
    const { setUserFavoriteProduct } = useUpdateUserFavoriteProductCtx();
    const IMG_CONTAINER_HEIGHT_PX = 180;
    const [isLoading, setIsLoading] = useState(false);
    const [mainProfessor, setMainProfessor] = useState(undefined);
    const [imgURLMap, setImgURLMap] = useState({content:undefined, author:undefined});
    const productIdentifierRef = useRef(null);
    const styles = {
        wrapper:{
            background: theme.palette.background.paper,
            boxShadow: theme.shadows[2] // applied by design: 0px 4px 4px rgba(0, 0, 0, 0.25); */
        },
        title:{
            fontWeight: 'bold'
        },
        description:{},
        author:{
            color: theme.palette.grey[400],
        },
        favoriteIcon:{
            display: 'border',
            height: '25px',
            color: isUserFavoriteProduct(productIdentifierRef.current) ? theme.palette.secondary.main : 'inherit'
        },
        imgWrapper:{
            height: IMG_CONTAINER_HEIGHT_PX,
            backgroundColor: theme.palette.grey[50],
        },
        img:{
        },
        authorImg:{
            backgroundColor: theme.palette.primary.lighter,
            border: 'none'
        }
    }

    const onClickFavorite = async (isFavorite) => {
        try{
            setIsLoading(true)
            setUserFavoriteProduct(productIdentifierRef.current, isFavorite)
        }catch(error){
            // TODO: Nothing is notifying now
        }finally{
            setIsLoading(false)
        }
    }

    useEffect(() => {
        const contentDetails = content?.details;
        if(isNotEmptyObject(contentDetails)){
            const assetProvider = new ImgDN(cloudinary);
            let contentImgURL = null;
            const contentImg = getFromSafeObject(contentDetails,'product.imageDetails.main');
            if(isNotEmptyObject(contentImg)){
                const imgPublicId = contentImg.publicId;
                contentImgURL = assetProvider.getAssetUrl(imgPublicId, {height: IMG_CONTAINER_HEIGHT_PX});
            }
            const professorArray  = contentDetails.professorArray || [];
            const mainProfessor = getMainProfessorOrNull(professorArray);
            const product = contentDetails.product || {}
            const productIdentifier = { productId: product.id };
            productIdentifierRef.current = productIdentifier
            setMainProfessor(mainProfessor);
            setImgURLMap(prev => ({...prev, content:contentImgURL}));
        }else{
            productIdentifierRef.current = null;
            setMainProfessor(undefined);
            setImgURLMap({content:undefined, author:undefined});
        }
    },[content])

    useEffect(() => {
        let authorImgURL = null;
        if(mainProfessor){
            const authorImg = getFromSafeObject(mainProfessor, 'imageDetails.main');
            if(isNotEmptyObject(authorImg)){
                const imgPublicId = authorImg.publicId;
                const assetProvider = new ImgDN(cloudinary);
                authorImgURL = assetProvider.getAssetUrl(imgPublicId, {height: IMG_CONTAINER_HEIGHT_PX});
            }
        }
        setImgURLMap(prev => ({...prev, author: authorImgURL}))
    },[mainProfessor])
    
    return (
        isNotEmptyObject(content) ?
            <div className='content-slider-item-responsive-wrapper' style={styles.wrapper}>
                <div className='content-slider-item-responsive-featured-type-container'>
                    <Typography variant={'body2'}>
                        {textTransform('title', t(content.details.selected.selectedProductTypeName))}
                    </Typography>
                </div>
                <div className='content-slider-item-responsive-img-wrapper' style={styles.imgWrapper}>
                    <div className='content-slider-item-responsive-content-img-container'>
                        {imgURLMap.content ? 
                            <ImgLazyLoad 
                                src={imgURLMap.content} 
                                alt={`content-cover-img`}
                                style={styles.img}
                            />
                            :
                            <Skeleton variant="rectangular" style={styles.img} height={'100%'}/>
                        }
                    </div>
                    {mainProfessor && imgURLMap.author ? 
                        <div className={`content-slider-item-responsive-author-img-container${fullHeightAuthorDisplay ? ' full-height' : ''}`}>
                            {fullHeightAuthorDisplay ? 
                                <ImgLazyLoad 
                                    src={imgURLMap.author} 
                                    alt={`author-img`}
                                    style={styles.authorImg}
                                />
                                :
                                <SocialProfileBadgeWithPopover
                                    diameterPx={50} 
                                    imgUrl={imgURLMap.author}
                                    label={mainProfessor.fullName}
                                    alt={`author-img`}
                                    style={styles.authorImg}
                                />
                            }
                        </div>
                        :
                        null
                    }
                </div>
                <div className='content-slider-item-responsive-text-container'>
                    <div className='content-slider-item-responsive-title-author-container'>
                        <Typography variant={'body1'} style={styles.title}>
                            {content.details.product.description.nameTranslated}
                        </Typography>
                        <Typography variant={'body2'} style={styles.author}>
                            {textTransform('capitalize', contentProfessorArrayToString(content.details.professorArray))}
                        </Typography>
                    </div>
                    <div className='content-slider-item-responsive-description-container'>
                        <Typography variant={'body2'} style={styles.description}>
                            {content.details.product.description.description}
                        </Typography>
                    </div>
                </div>
                <div className='content-slider-item-responsive-type-favorite-wrapper'>
                    <div className='content-slider-item-responsive-favorite-container'>
                        <FavoriteButton isClick={isUserFavoriteProduct(productIdentifierRef.current)} onClick={onClickFavorite} style={styles.favoriteIcon} hover={false} disabled={isLoading} />
                    </div>
                    <div className='content-slider-item-responsive-type-container'>
                        <ContentTypeChip
                            contentTypeName={content.contentTypeName}
                            contentTypeNameTranslated={content.details.contentType.description.nameTranslated}
                        />
                    </div>
                </div>
            </div>
        :
            <Skeleton variant='rectangular' className='content-slider-item-responsive-skeleton'/>
    )
}

export default ContentResponsiveSliderItem
