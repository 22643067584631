import { useTranslation } from 'react-i18next'
import LandingHero from '../../components/LandingHero/LandingHero'
import LandingBrands from '../../components/LandingBrands/LandingBrands'
import LandingFeatures from '../../components/LandingFeatures/LandingFeatures'
import LandingValuePropositions from '../../components/LandingValuePropositions/LandingValuePropositions'
import LandingFeaturedContent from '../../components/LandingFeaturedContent/LandingFeaturedContent'
import LandingValueInFigures from '../../components/LandingValueInFigures/LandingValueInFigures'
import LandingOurContent from '../../components/LandingOurContent/LandingOurContent'
import LandingHowItWorks from '../../components/LandingHowItWorks/LandingHowItWorks'
import LandingSocial from '../../components/LandingSocial/LandingSocial'
import LandingCenteredCTA from '../../components/LandingCenteredCTA/LandingCenteredCTA'
import LandingFAQ from '../../components/LandingFAQ/LandingFAQ'
import ContactSupportSection from '../../components/ContactSupportSection/ContactSupportSection'
import MailingListSubscriptionSection from '../../components/MailingListSubscriptionSection/MailingListSubscriptionSection'
// import BigFooter from '../../components/BigFooter/BigFooter'
import BigFooterNoLinks from '../../components/BigFooterNoLinks/BigFooterNoLinks'
import './landing.css'
import LandingHeroMessage from '../../components/LandingHeroMessage/LandingHeroMessage'

const Landing = ({ user, setUser }) => {
    const { t } = useTranslation('landing');
    return (
        <div className='landing-main-container'>
            <LandingHero user={user} setUser={setUser} />
            <LandingBrands />
            <LandingFeatures />
            <LandingValuePropositions />
            <LandingFeaturedContent />
            <LandingValueInFigures />
            <LandingOurContent user={user} />
            <LandingHowItWorks/>
            <LandingHeroMessage/>
            <LandingSocial/>
            <LandingCenteredCTA user={user} setUser={setUser} />
            <LandingFAQ />
            <ContactSupportSection
                user={user} 
                categoryObject={{
                    categoryArray: ['admissions', 'contentsAndPrograms', 'productsAndPrices', 'subscriptions', 'other'],
                    defaultKey: 'contentsAndPrograms'
                }}
            />
            <MailingListSubscriptionSection 
                user={user} 
                subscriptionListArray={[
                    {
                        name:'Newsletter', customSource:'landing-form', isRequired:true
                    },
                    {
                        name:'Marketing', customSource:'landing-form',
                    },
                ]}
                translations={{
                    title: '',
                    subtitle: t('landingMailingListSubscription.trySomeOfOurClasses'),
                    description: t('landingMailingListSubscription.landingMailingSubscriptionMessage'),

                }}
            />
            <BigFooterNoLinks user={user} />
            {/* <BigFooter /> */}
        </div>
    )
}

export default Landing