import { useTheme } from '@material-ui/styles'
import { isRgbColor, rgbToRgbaOrUndefined } from '../../aux/aux';
import { Typography, hexToRgb } from '@material-ui/core';
import './iconLabel.css'

const IconLabel = ({Icon, label, color, variant='caption', style={}, iconAtEnd=false}) => {
    const theme = useTheme();
    const styles = {
        wrapper:{
            backgroundColor: style.backgroundColor || '#fff',
            minHeight: theme.typography.body1.fontSize,
        },
        mainContainer:{
            color: color,
            minHeight: 'inherit',
            backgroundColor: rgbToRgbaOrUndefined(isRgbColor(color) ? color : hexToRgb(color), 0.1),
        },
        icon:{
            order: iconAtEnd ? '1' : '0',
            display: 'block',
            height: theme.typography[variant].fontSize,
            width: theme.typography[variant].fontSize,
        }
    }
    return (
        <div className='icon-label-wrapper' style={styles.wrapper}>
            <div className='icon-label-main-container' style={{...styles.mainContainer, ...style}}>
                {Icon ? <Icon style={styles.icon}/> : null}
                {label ? <Typography variant={variant}>{label}</Typography> : null}
            </div>
        </div>
    )
}

export default IconLabel