import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
// import { MenuItem, TextField } from '@material-ui/core';
// It is set from v5 material ui instead of v4 because 
// MenuItem does not cover the TextField
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Circle from '../../img/circle.svg';
import ButtonLoading from '../ButtonLoading/ButtonLoading';

import { textTruncate } from '../../aux/aux';

import { useTheme } from '@material-ui/styles';
import './classContentSystemSelectForm.css'

const ClassContentSystemSelectForm = ({ allSystems, availableSystems, currentUserSystem, isDisabled=false, setFormValues=()=>{} }) => {
    const TEXT_TRUNCATE_LENGTH = 35;
    const TEXT_TRUNCATE_END = '...';
    const theme = useTheme();
    const { t } = useTranslation('common', { keyPrefix: 'classContentSystemSelectForm' });
    const [contentSystemEditForm, setContentSystemEditForm] = useState({
        selectedSoftwareName: '',
        selectedDeviceName: '',
    });

    const onChange = (e) => {
        e.preventDefault();
        setContentSystemEditForm((contentSystemEditForm) => ({...contentSystemEditForm, [e.target.name]: e.target.value}))
    }

    useEffect(() => {
        setFormValues({...contentSystemEditForm})
    },[contentSystemEditForm])

    useEffect(() => {
        if(contentSystemEditForm.selectedDeviceName){
            setContentSystemEditForm((contentSystemEditForm) => ({...contentSystemEditForm, selectedDeviceName:''}));
        }
    },[contentSystemEditForm.selectedSoftwareName])

    return (
        <>
            <Grid item className='class-content-system-select-form-item'>
                <TextField 
                label={t("selectSoftware")}
                disabled={isDisabled}
                name='selectedSoftwareName'
                value={contentSystemEditForm.selectedSoftwareName}
                onChange={onChange} 
                inputProps={{style:{textOverflow:'ellipsis'}}}
                InputLabelProps={{shrink:contentSystemEditForm.selectedSoftwareName !== ''}}
                select 
                fullWidth
                variant='standard'
                required
                >
                    {Object.keys(allSystems).map( (softwareName, index) => {
                        let isSelected = currentUserSystem.softwareName && currentUserSystem.softwareName === softwareName; 
                        let softwareAvail = availableSystems.softwareArray.find(software => software.softwareName.toLowerCase() === softwareName.toLowerCase());
                        return(
                            <MenuItem key={index} value={softwareName} disabled={!softwareAvail}>
                                <Grid container >
                                    <Grid item>
                                        <Typography display='inline' variant='body1'>
                                            {textTruncate(softwareName, TEXT_TRUNCATE_LENGTH, TEXT_TRUNCATE_END).toUpperCase()}
                                        </Typography>
                                    </Grid>
                                    <Grid item className='class-content-system-select-form-item-icon'>
                                        {isSelected &&
                                            <Circle height={'0.5em'} fill={theme.palette.primary.main} display={"block"}/>
                                        }
                                    </Grid>
                                </Grid>
                            </MenuItem>
                        )
                    })}
                </TextField>
            </Grid>
            <Grid item className='class-content-system-select-form-item'>
                <TextField 
                color=''
                disabled={contentSystemEditForm.selectedSoftwareName === '' || isDisabled}
                label={t("selectDevice")}
                name='selectedDeviceName'
                value={contentSystemEditForm.selectedDeviceName}
                onChange={onChange} 
                inputProps={{style:{textOverflow:'ellipsis'}}}
                InputLabelProps={{shrink:contentSystemEditForm.selectedDeviceName !== ''}}
                select 
                fullWidth
                variant='standard'
                required
                >   
                    {contentSystemEditForm.selectedSoftwareName !== '' ?
                        Object.values(allSystems[contentSystemEditForm.selectedSoftwareName]).map( (device, index) => {
                            let isSelected = currentUserSystem.deviceName && currentUserSystem.deviceName === device.name; 
                            let deviceAvail = availableSystems.deviceArray.find(availDevice => availDevice.deviceName.toLowerCase() === device.name.toLowerCase()); 
                            return(
                                <MenuItem key={index} value={device.name} disabled={!deviceAvail}>
                                    <Grid container >
                                        <Grid item>
                                            <Typography display='inline' variant='body1'>
                                                {textTruncate(device.name, TEXT_TRUNCATE_LENGTH, TEXT_TRUNCATE_END).toUpperCase()}
                                            </Typography>
                                        </Grid>
                                        <Grid item className='class-content-system-select-form-item-icon'>
                                            {isSelected &&
                                                <Circle height={'0.5em'} fill={theme.palette.primary.main} display={"block"}/>
                                            }
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                            )
                        })
                        :
                        <MenuItem  value={''} disabled>
                            <Grid container >
                                <Grid item>
                                    <Typography display='inline' variant='body1'>
                                        ''
                                    </Typography>
                                </Grid>
                            </Grid>
                        </MenuItem>
                    }
                </TextField>
            </Grid>
        </>
    )
}

export default ClassContentSystemSelectForm