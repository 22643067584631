import { createContext, useEffect, useRef, useState } from "react"
import { useScrollableDocument } from "../customHooks/useScrollableDocument";
import { useScrollbarWidth } from "../customHooks/useScrollbarWidth";

const ScrollableDocumentContext = createContext(null);
const ScrollableDocumentUpdateContext = createContext(null);

const ScrollableDocumentProvider = ({ children }) => {
    const [isScrollable, setIsScrollable] = useScrollableDocument()
    const [isScrollableDocument, _setIsScrollableDocument] = useState(true);
    const isScrollManualRef = useRef(null);
    const scrollbarWidth = useScrollbarWidth()
    
    function setIsScrollableDocument(isDocScrollable, opt={}){
        isScrollManualRef.current = isDocScrollable;
        setIsScrollable(isDocScrollable, opt={});
    }

    useEffect(() => {
        const observer = new MutationObserver((elements) => {
            const bodyContainer = elements[0];
            const overflow = bodyContainer.target.style.overflow;
            const position = bodyContainer.target.style.position;
            const isScrollable = position !== 'fixed' && overflow !== 'hidden'
            _setIsScrollableDocument(isScrollable);
        });
        observer.observe(document.getElementsByTagName('body')[0], {
            attributes: true,
            attributeOldValue: true,
            attributeFilter: ['style']
        })
        return(() => {
            observer.disconnect();
        })
    },[])

    return(
        <ScrollableDocumentContext.Provider value={{isScrollableDocument, scrollbarWidth}}>
            <ScrollableDocumentUpdateContext.Provider value={setIsScrollableDocument}>
                { children }
            </ScrollableDocumentUpdateContext.Provider>
        </ScrollableDocumentContext.Provider>
    )
}

export { ScrollableDocumentProvider, ScrollableDocumentContext, ScrollableDocumentUpdateContext }