import React, { useRef, useState } from 'react'
import ClassContentBreadcrumb from '../ClassContentBreadcrumb/ClassContentBreadcrumb'
import ClassContentNavLinks from '../ClassContentNavLinks/ClassContentNavLinks'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@material-ui/core'
import './classContentHeader.css'
import PopUpClassContentMenu from '../PopUpClassContentMenu/PopUpClassContentMenu';

const ClassContentHeader = ({
        userConfig, 
        userAccessDetails, 
        contentSummary, 
        contentStructure, 
        contentSelected, 
        contentNav, 
        contentNavMoves, 
        onUpdateUserConfig,
    }) => {
    const [isMenuOpen, setIsMenuOpen] = useState({'contentConfigMenuButton':false})
    const anchorMenu = useRef(null);

    const handleOnOpen = (e) => {
        const menuItemId = e.currentTarget.id;
        setIsMenuOpen(isMenuOpen => ({...isMenuOpen, [menuItemId]:true }));
    };
    const handleOnClose = (menuItemId) => {
        setIsMenuOpen(isMenuOpen => ({...isMenuOpen, [menuItemId]:false }));
    };

    return (
        <>
            <PopUpClassContentMenu 
                anchorEl={anchorMenu} 
                isOpen={isMenuOpen.contentConfigMenuButton} 
                onClose={(id='contentConfigMenuButton') => {handleOnClose(id)}} 
                userConfig={userConfig} 
                contentSummary={contentSummary} 
                onUpdateConfig={onUpdateUserConfig}
                userAccessDetails={userAccessDetails}
                contentSelected={contentSelected}
            />
            <div className='class-content-header-main-container'>
                <div className='class-content-header-breadcrumb-wrapper'>
                    <ClassContentBreadcrumb contentSummary={contentSummary} contentStructure={contentStructure} contentSelected={contentSelected}/>
                </div>
                <ClassContentNavLinks  contentNav={contentNav} contentNavMoves={contentNavMoves} />
                <IconButton
                    size='small'
                    id='contentConfigMenuButton'
                    ref={anchorMenu}
                    onClick={handleOnOpen}
                >
                    <MoreVertIcon />
                </IconButton>
            </div>
        </>
    )
}

export default ClassContentHeader