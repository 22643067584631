import { useEffect } from 'react'
import { isValidDate, textTruncate } from '../../aux/aux'
import { Button, Typography, useMediaQuery } from '@material-ui/core'
import './bannerActionReminder.css'
import { useLocaleCtx } from '../../customHooks/useLocaleCtx'
import AvailUnitWidget from '../AvailUnitWidget/AvailUnitWidget'
import { useState } from 'react'
import ButtonLoading from '../ButtonLoading/ButtonLoading'
const DEFAULT_ACTION_SCARCITY = {
    count:undefined,
    unitType:undefined,
    isScarce:undefined
}
const DEFAULT_BUTTON_PROPS = {}
const BannerActionReminder = ({ actionTitle, actionScarcity=DEFAULT_ACTION_SCARCITY, actionDate=undefined, buttonProps=DEFAULT_BUTTON_PROPS }) => {
    const TOOGLE_DATE_TIME_MS = 4000;
    const localeCtx = useLocaleCtx()
    const matchMobile = useMediaQuery('(max-width:680px');
    const [toogleDate, setToogleDate] = useState(false);
    const MAX_MOBILE_CHAR_LENGTH = 30;
    const dateFormatOptions  = {
        // weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
        timeZoneName: 'short'
      };
    const styles = {
        title:{
            fontWeight: 'bold'
        },
        description:{}        
    }
    useEffect(() => {
        if(isValidDate(actionDate)){
            setToogleDate(true);
            if(matchMobile){
                if(actionScarcity.isScarce){
                    const toogleDate = () => {
                        setToogleDate(pre => !pre);
                    }
                    const intervalId = setInterval(toogleDate, TOOGLE_DATE_TIME_MS);
                    return(() => {
                        clearInterval(intervalId);
                    })
                }
            }
        }else{
            setToogleDate(false);
        }
    },[actionDate, actionScarcity, matchMobile])
    return (
        actionTitle ?
            <div className='banner-action-reminder-wrapper'>
                <div className='banner-action-reminder-main-container'>
                    <div className='banner-action-reminder-info-container'>
                        <div className='banner-action-reminder-description-container'>
                            <Typography variant='body2' style={styles.title}>
                                {textTruncate(actionTitle, matchMobile ? MAX_MOBILE_CHAR_LENGTH : undefined)}
                            </Typography>
                            {matchMobile ?
                                    <>
                                        {isValidDate(actionDate) ?
                                            <div className={`banner-action-reminder-fade-in-container${toogleDate ? ' show' : ' hide'}`}>
                                                <Typography variant='caption' style={styles.description}>
                                                    {actionDate.toLocaleTimeString(localeCtx.localeCode, dateFormatOptions)}
                                                </Typography>
                                            </div>
                                            :
                                            null
                                        }
                                        {actionScarcity.isScarce ?
                                            <div className={`banner-action-reminder-avail-unit-container banner-action-reminder-fade-in-container${toogleDate ? ' hide' : ' show'}`}>
                                                <AvailUnitWidget 
                                                    variant='caption' 
                                                    count={actionScarcity.count} 
                                                    symbolAtEnd={true} 
                                                />
                                            </div>
                                            :
                                            null
                                        }
                                    </>
                                :
                                    isValidDate(actionDate) ?
                                        <Typography variant='caption' style={styles.description}>
                                            {actionDate.toLocaleTimeString(localeCtx.localeCode, dateFormatOptions)}
                                        </Typography>
                                        :
                                        null
                            }
                        </div>
                        {!matchMobile && actionScarcity.isScarce ?
                            <div className='banner-action-reminder-avail-unit-container'>
                                <AvailUnitWidget 
                                    variant='caption' 
                                    count={actionScarcity.count} 
                                    unitType={actionScarcity.unitType}  
                                    symbolAtEnd={true} 
                                    fullMessage
                                />
                            </div>
                            :
                            null
                        }
                    </div>
                    {typeof buttonProps.onClick === 'function' && buttonProps.label ?
                        <div className='banner-action-reminder-button-container'>
                            <ButtonLoading size='small' color='primary' variant='contained' {...buttonProps}/>
                                {/* {actionLabel} */}
                            {/* </Button> */}
                        </div>
                        :
                        null
                    }
                </div>
            </div>
            :
            null
  )
}

export default BannerActionReminder