import { Chip, hexToRgb } from '@material-ui/core'
import { isRgbColor, rgbToRgbaOrUndefined } from '../../aux/aux'

/**
 * 
 * @param {*} props { color, stype , ...props}
 *  color: hex, rgb notation 
 * @returns 
 */
const CustomChip = ({ color, style={}, ...props }) => {
    const styles = {
        chip: color ? {
                color: color,
                borderColor: color,
                backgroundColor: rgbToRgbaOrUndefined(isRgbColor(color) ? color : hexToRgb(color), 0.1),
            }
            :
            {}
    }
    return (
        <Chip 
            style={{...styles.chip, ...style}}
            {...props}
        />
    )
}

export default CustomChip