/*
    This file contains helper functions related to the purchase process
*/
import { requestMinimumTimeoutWrapper } from "./requestMethods";
import { 
    getOpenCheckoutIdFn, clearCheckoutFn, createCheckoutFn, updateAndGetCheckoutFn,
    updateCheckoutBillingAddressAndCurrencyFn,
    getCheckoutContentFn, getPaymentMethodFromIntentIdFn, getCheckoutContentPurchasabilityDetailsFn
} from "./purchaseQueries";
import { getAPIEventSource } from "./eventSourceHelpers";

function productAmountHasDiscountCouponApplied(productAmount){
    const discount = productAmount?.couponAmountOff;
    return Number.isFinite(discount) && discount > 0
}

function productAmountHasPromoCodeApplied(productAmount){
    const discount = productAmount?.promoCodeAmountOff;
    return Number.isFinite(discount) && discount > 0
}

function getFinalAmountWithoutDiscountCoupon(productAmount){
    let finalAmount;
    if(productAmountHasDiscountCouponApplied(productAmount)){
        const beforeTaxes = productAmount?.finalAmountBeforeTaxes + productAmount?.couponAmountOff;
        finalAmount = round(beforeTaxes * (1 + productAmount?.taxDetails.salesTaxRate),0);
    }else{
        finalAmount = productAmount?.finalAmount
    }
    return finalAmount
}

function getFinalAmountWithoutPromoCode(productAmount){
    let finalAmount;
    if(productAmountHasPromoCodeApplied(productAmount)){
        const beforeTaxes = productAmount?.finalAmountBeforeTaxes + productAmount?.promoCodeAmountOff;
        finalAmount = round(beforeTaxes * (1 + productAmount?.taxDetails.salesTaxRate),0);
    }else{
        finalAmount = productAmount?.finalAmount;
    }
    return finalAmount
}

/* DEPRECATED BUT STILL IN USE */
function priceToString( currencyId, amount ){
    const currencies = {
        "eur":"€",
        "usd":"$"
    }
    const amount2dec = fromCentTo2dec(amount);
    const symbol = amount2dec < 0 ? '-' : '';
    const price = (currencyId ==='usd') ? symbol + currencies[currencyId] + Math.abs(amount2dec) : symbol + Math.abs(amount2dec) + currencies[currencyId]
    return  price
}

function fromCentTo2dec(amountInCent){
    return round2dec(amountInCent/100);
}

/* DEPRECATED BUT STILL IN USE */
function percentageToString(percentage, unitaryValue=true){
    if(unitaryValue){
        percentage *= 100;
    }
    return `${round2dec(percentage)}%`
}

function round2dec( amount ){
    return( Math.round((amount + Number.EPSILON) * 100 ) / 100 )
    // return round(amount,2);
}

function truncate2dec( amount ){
    const isNeg = amount < 0;
    const dropDecFn = isNeg ? Math.ceil : Math.floor; 
    return dropDecFn(((amount + Number.EPSILON ) * 100)) / 100
}

function round(number, precision){
    'use strict';
    number += Number.EPSILON
    precision = precision ? +precision : 0;

    const sNumber   = number + '',
        periodIndex = sNumber.indexOf('.'),
        factor      = Math.pow(10, precision);

    if (periodIndex === -1 || precision < 0) {
        return Math.round(number * factor) / factor;
    }

    number = +number;

    // sNumber[periodIndex + precision + 1] is the last digit
    if (sNumber[periodIndex + precision + 1] >= 5) {
        // Correcting float error
        // factor * 10 to use one decimal place beyond the precision
        number += (number < 0 ? -1 : 1) / (factor * 10);
    }

    return +number.toFixed(precision);
}

function subscriptionEquivalentTerms(amount, numMonths, equivalentBaseTermsList){
    let equivalentTerm = '';
    let equivalentAmount = 0;
    if(numMonths > 1){
        // Monthly Equivalent
        equivalentTerm = equivalentBaseTermsList[0];
        equivalentAmount = round2dec(amount / numMonths);
    } else {
        // Daily Equivalent
        equivalentTerm = equivalentBaseTermsList[1];
        equivalentAmount = round2dec(amount / 30);
    }
    return({
        equiTerm: equivalentTerm,
        equiAmount: equivalentAmount
    })
}

const getOpenCheckoutId = async (userId, checkoutType, minimumTimeout=2000, signal=null) => {
    const requestParams = {
        userId,
        checkoutType
    };
    const res = await requestMinimumTimeoutWrapper(getOpenCheckoutIdFn, requestParams, minimumTimeout, signal);
    return res.data.checkoutId;
};

const clearCheckout = async (userId, checkoutId,  minimumTimeout=2000, signal=null) => {
    const requestParams = {
        userId,
        checkoutId
    };
    const res = await requestMinimumTimeoutWrapper(clearCheckoutFn, requestParams, minimumTimeout, signal);
    return res.data.checkoutId;
}

const createCheckout = async (userId, checkoutType, checkoutProductArray=[],  minimumTimeout=2000, signal=null) => {
    const requestParams = {
        userId,
        checkoutType,
        checkoutProductArray
    };
    const res = await requestMinimumTimeoutWrapper(createCheckoutFn, requestParams, minimumTimeout, signal);
    return res.data.checkoutId;
}

/**
 * 
 * @param {*} opId 
 * @param {*} opParams 
 * @param {*} requestParams { userId:any, checkoutId:any, userLocaleId:any, hasFinalAmount?:int}
 */
const updateAndGetCheckout = async (opId, opParams, requestParams,  minTimeoutRequest=0, signal=null) => {
    requestParams.opId = opId;
    switch(opId){
        case 'swap':
            requestParams.productToSwap = {
                oldProductId: opParams.oldProductId,
                newProductId: opParams.newProductId,
            };
            minTimeoutRequest = 0;
            break;
        case 'productQuantity':
            requestParams.checkoutProduct = opParams.checkoutProduct
            break;
        case 'delete':
            requestParams.checkoutProductArray = opParams.checkoutProductArray;
            break;
        case 'add':
            requestParams.checkoutProductArray = opParams.checkoutProductArray;
            break;
        case 'addSubscriptable':
            requestParams.checkoutSubscriptableProductArray = opParams.checkoutSubscriptableProductArray;
            break;
        case 'applyPromoCode':
            minTimeoutRequest = 500;
            requestParams.promoCodeId = opParams.promoCodeId;
            break;
        case 'takeOutPromoCode':
            minTimeoutRequest = 500;
            break;
        case 'updateBillingAddress':
            minTimeoutRequest = 0;
            requestParams.billingAddress = opParams.billingAddress;
            break;
        case 'get':
            break;
    }
    const res = await requestMinimumTimeoutWrapper(updateAndGetCheckoutFn, requestParams, minTimeoutRequest, signal);
    return res;
}

const updateCheckoutBillingAddressAndCurrency = async(userId, checkoutId, billingAddressAndCurrency, minimumTimeout=2000) => {
    const requestParams = {
        userId,
        checkoutId,
        billingAddressAndCurrency,
    }
    return requestMinimumTimeoutWrapper(updateCheckoutBillingAddressAndCurrencyFn, requestParams, minimumTimeout);

}

const getCheckoutContent = async(userId, checkoutId, userLocaleId, minimumTimeout=2000) => {
    const requestParams = {
        userId,
        checkoutId,
        userLocaleId
    };
    return requestMinimumTimeoutWrapper(getCheckoutContentFn, requestParams, minimumTimeout)
}

const getProcessOrderFromCheckoutStatusEventSource = (userId, purchaseTrakingToken, onmessage, onerror=null) => {
    const path = `checkout/process-intent-status/realtime/${userId}/${purchaseTrakingToken}`;
    return getAPIEventSource(path, onmessage, onerror);
}

// DEPRECATED
function getFinalPrice(initialPrice, option, promoCode = null){
    let xDiscount = 0;
    let feeAmount = 0;
    let numTerms = 1;
    const terms = option.hasOwnProperty('numTerms') && option.numTerms > 1 ? option.numTerms : 30 // This will give us a daily rate
    let finalPrice = initialPrice.price - initialPrice.discount - option.discount;

    if( promoCode && Object.keys(promoCode).length > 0 ){
        if(promoCode.discount.type === 'percent'){
            xDiscount = round2dec(finalPrice *  promoCode.discount.amountOff / 100)
        } else { 
            xDiscount = promoCode.discount.amountOff;
        }
        finalPrice -= xDiscount;
    }

    if (option.fees){
        feeAmount = Math.ceil(finalPrice * option.fees);
        finalPrice += feeAmount;
    }

    if (option.hasOwnProperty('numTerms')){
        numTerms = option.numTerms > 1 ? option.numTerms : 30 // This will give us a daily rate
    }
    const termAmount = round2dec(finalPrice / numTerms)

    return ({
        amount: finalPrice,
        termAmount: termAmount,
        feesAmount: feeAmount,
        subTotalDiscount: initialPrice.discount + option.discount,
        extraDiscount: xDiscount,
    })
}
// DEPRECATED
// This method is duplicated on the server side path: /server/services/stripeHelpers.js
/**
 * 
 * @param {*} itemsList [{productId, couponid, salesTaxRate, salseTaxType, startsDate?}]
 * @param {*} keyBaseName 
 * @returns 
 */
function processProductItemsToStripeMetadata(itemsList, keyBaseName='itemsList'){
    const maxItemsPerMetadataKey = 5;
    // TODO: This requirement should be reinforced in Checkout or chart page
    // Prevents to hit Stripe metadata limitations of 50 keys (40 characters long) with a maximum of 500 char each value
    // Setting a threshold of 50 products in each order
    const maxItemsThreshold = 50;
    const exceptionMessage = `num of products over threshold of ${maxItemsThreshold}`;
    let metadataKeysCounter = 0;
    const metadata = {};
    let metadataKeyList = [];
    if(itemsList.length <= maxItemsThreshold){
        for(let i = 0; i < itemsList.length; i++){
            metadataKeyList.push({
                productId: itemsList[i].id,
                // TODO: Include applied coupon on each component of selectedOption.productsList
                // couponId: itemsList[i].appliedCouponId,
                // TODO: Include productTypeName on each component of selectedOption.productsList
                // productTypeName: itemsList[i].productTypeName,
                // TODO: Include startDateUTC on each subscription type component of selectedOption.productsList
                // startsDate: itemsList[i].startsDate,
            })
            if((i !== 0 && (i % maxItemsPerMetadataKey) === 0) || i === (itemsList.length - 1)){
                metadata[`${keyBaseName}_${metadataKeysCounter}`] = JSON.stringify(metadataKeyList);
                metadataKeysCounter++;
                metadataKeyList = [];
            }
        }
    }else{
        throw new Error(exceptionMessage);
    }
    console.debug(metadata)
    return metadata;
}

const getPaymentMethodFromIntentId = async (intentId, minimumTimeOut=0, signal=null) => {
    const requestParams = {
        intentId
    };
    return requestMinimumTimeoutWrapper(getPaymentMethodFromIntentIdFn, requestParams, minimumTimeOut, signal);
}

const getCheckoutContentPurchasabilityDetails = async (checkoutId, signal=null) => {
    const requestParams = {
        checkoutId,
    };
    return requestMinimumTimeoutWrapper(getCheckoutContentPurchasabilityDetailsFn, requestParams, 0, signal);
}

export {
    productAmountHasDiscountCouponApplied, 
    productAmountHasPromoCodeApplied, 
    getFinalAmountWithoutDiscountCoupon, 
    getFinalAmountWithoutPromoCode, 
    priceToString, 
    fromCentTo2dec, 
    round2dec, 
    truncate2dec,
    round, 
    getFinalPrice, 
    percentageToString,
    subscriptionEquivalentTerms, 
    processProductItemsToStripeMetadata, 
    getOpenCheckoutId,
    clearCheckout, 
    createCheckout, 
    updateAndGetCheckout, 
    getCheckoutContent, 
    updateCheckoutBillingAddressAndCurrency,
    getPaymentMethodFromIntentId, 
    getProcessOrderFromCheckoutStatusEventSource,
    getCheckoutContentPurchasabilityDetails
}