import { Grid, Box, Typography } from '@material-ui/core';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { textTransform } from '../../aux/aux';

import ForgetPasswordForm from '../ForgetPasswordForm/ForgetPasswordForm';
// Lottie player
// https://www.thisdot.co/blog/using-lottie-animations-for-ui-components-in-react
import lottie from 'lottie-web/build/player/lottie_light';
// Animations
import successAnimation from '../../lotties/success.json';
//Style
import PopUpModal from '../PopUpModal/PopUpModal'
import PopUpModalHeader from '../PopUpModalHeader/PopUpModalHeader';
import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper';
import './popUpForgetPassword.css'



const PopUpForgetPassword = ({ onClose, show=true, modal=true, onRender=()=>{} }) => {
    const POP_UP_WIDTH = 380;
    const { t } = useTranslation('common', { keyPrefix: 'popUpForgetPassword'});
    const [showEmailSent, setShowEmailSent] = useState(false);
    const [animationContainer, setAnimationContainerRef] = useState(null);  
    const animationRef = useRef(null);  
        
    const handleOnSuccess = (e) => {
        setShowEmailSent(true);
    }

    useEffect(() => {
        if(animationContainer){
            animationRef.current = lottie.loadAnimation({
                container: animationContainer,
                renderer: 'svg',
                autoplay: true,
                animationData: successAnimation,
                loop: false,
                name: 'animation'
            })
            return(() => {
                if(animationRef.current != null){
                    animationRef.current.stop();
                    animationRef.current?.destroy();
                }
            })
        }
    }, [animationContainer])

    useEffect(() => {
        onRender();
    })

    return (
            <ConditionalWrapper
                condition={modal}
                wrapperFn={ children => 
                    <PopUpModal 
                        showModal={show} 
                        onClickClose={onClose} 
                        width={POP_UP_WIDTH}
                    >
                        <PopUpModalHeader 
                            title={textTransform('title', t("restorePassword"))}
                        />
                        {children}
                    </PopUpModal>
                }
            >
                {showEmailSent === false ?
                    <Grid className="pop-up-forget-password-dialog-container" container direction="column" >
                        <Grid item>
                            <Typography variant='body1'>
                                {textTransform('title', t("weSendYouAnEmailToRestablishIt"))}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <ForgetPasswordForm onSuccess={handleOnSuccess}/>
                        </Grid>
                    </Grid>
                :
                    <Grid className="pop-up-forget-password-dialog-success-container" container direction="column" alignItems='center'>
                        <Grid item >
                            <Grid container justifyContent='center' alignItems='center'>
                                <Grid item className='pop-up-forget-password-dialog-success-animation-wrapper'>
                                    <div ref={setAnimationContainerRef} />
                                </Grid>
                                <Grid item>
                                    <Typography variant='body1' style={{ fontWeight:'bold' }}>
                                        {textTransform('title', t("successfullySent"))}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item >
                            <Typography variant='body1'>
                                {textTransform('title', t("emailCorrectlySent"))}
                            </Typography>
                        </Grid>
                        <Grid item className='pop-up-forget-password-dialog-success-reminder'>
                            <Typography variant='body2'>
                                {`* ${textTransform('title', t("canTakeALittle"))}`}
                            </Typography>
                        </Grid>
                    </Grid>
                }
            </ConditionalWrapper>
    )
}

export default PopUpForgetPassword
