import { Grid, Box, Typography, Chip, Button, Divider } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { getFromSafeObject, isValidDate, textTransform } from '../../aux/aux';
import ImgDN from '../../cdn/imgDN'
import cloudinary from '../../cdn/providers/cloudinary';
import { useEffect, useState } from 'react';
import CustomChip from '../CustomChip/CustomChip';
import { Skeleton } from '@mui/material'
import './classContentOverview.css';

const ClassContentOverview = ({ contentSummary, userProgress }) => {
    const theme = useTheme()
    const { t } = useTranslation('classroom', { keyPrefix: "classContentOverview"});
    // TODO: lastUpdate and totalDistributedUnits could be displayed
    const [imgBannerUrl, setImgBannerUrl] = useState(undefined)
    const assetProvider = new ImgDN(cloudinary);
    const styles = {
        divider:{
            backgroundColor: theme.palette.grey[100]
        }
    }
    useEffect(() => {
        if(contentSummary){
            const imgBannerPublicId = getFromSafeObject(contentSummary, 'imageDetails.main.publicId');
            if(imgBannerPublicId){
                const imgUrl = assetProvider.getAssetUrl(contentSummary.imageDetails.main.publicId);
                setImgBannerUrl(imgUrl);
            }else{
                setImgBannerUrl(undefined)
            }
        }
    },[contentSummary])
    return (
        <div className='class-content-overview-main-container'>
            <div className='class-content-overview-header-container'>
                {imgBannerUrl ?
                    <img className='class-content-overview-header-img' alt="contentHeader" src={imgBannerUrl} /> 
                    :
                    <Skeleton className='class-content-overview-header-skeleton' variant='rectangular' />
                }
            </div>
            {/* Title & Description Container */}
            <div className='class-content-lecture-title-container'>
                <Typography variant="h5" className='class-content-lecture-title'>
                    {textTransform('title', t("aboutThisContent"))}
                </Typography>
                <Typography variant="body1" className='class-content-lecture-description'>
                    {contentSummary.description.nameTranslated}
                </Typography>
            </div>
            <Divider variant='middle'/>
            <div className='class-content-overview-info-container'>
                <div className='class-content-overview-info-section-container'>
                    <div className='class-content-overview-info-section-title-container'>
                        <Typography variant="body1">
                            {textTransform('title', t("common:feature", {count:2}))}
                        </Typography>
                    </div> 
                    <div className='class-content-overview-info-section-info-container'>
                        <Typography variant="body1">
                            {`${textTransform('title', t("common:category", {count:1}))}: ${textTransform('title', getFromSafeObject(contentSummary,'summaryDetails.contentTypeTranslation.nameTranslated',''))}`}
                        </Typography>
                        <Typography variant="body1">
                            {`${textTransform('title', t("common:level", { count:1}))}: ${textTransform('title', getFromSafeObject(contentSummary,'summaryDetails.knowledgeLevelName',''))}`}
                        </Typography>
                        <Typography variant="body1">
                        {`${textTransform('title', t("common:length"))}: ${getFromSafeObject(contentSummary,'summaryDetails.lengthInMin','')} ${t("common:minuteAbbr", { count:1})}`}
                        </Typography>
                        {getFromSafeObject(contentSummary,'summaryDetails.topicArray') ?
                            <div className='class-content-overview-chip-container'>
                                {getFromSafeObject(contentSummary,'summaryDetails.topicArray',[]).map((topic, index) => {
                                    const topicNameTranslated = getFromSafeObject(topic, 'description.nameTranslated')
                                    return(
                                        topicNameTranslated ? 
                                            <div key={index}>
                                                <CustomChip
                                                    variant="outlined" 
                                                    size="small"
                                                    label={textTransform('capitalize', topicNameTranslated)}
                                                />
                                            </div>
                                            :
                                            null
                                    )
                                })}
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
                <Divider variant='middle' style={styles.divider} />
                <div className='class-content-overview-info-section-container'>
                    <div className='class-content-overview-info-section-title-container'>   
                        <Typography variant="body1">
                        {textTransform('title', t("common:compatibility"))}
                        </Typography>
                    </div>
                    <div className='class-content-overview-info-section-info-container'>
                        <Grid item>
                            <Typography variant="body1"> 
                                {`${textTransform('title', t("common:languages", {count:2}))}: ${textTransform('capitalize', getFromSafeObject(contentSummary,'summaryDetails.availableConfiguration.localeArray',[]).reduce((previous, current) => { return previous + `${previous ? ',' : ''} ${ textTransform('title', getFromSafeObject(current,'details.locale.details.language.description.name','')) }`},''))}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">
                            {`${textTransform('title', t("common:captions"))}: ${textTransform('capitalize', getFromSafeObject(contentSummary,'summaryDetails.availableConfiguration.captionsLocaleArray',[]).reduce((previous, current) => { return previous + `${previous ? ',' : ''} ${ textTransform('title', getFromSafeObject(current, 'details.locale.details.language.description.name','')) }`},''))}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">
                            {`${textTransform('title', t("common:software", { count: 2}))}: ${getFromSafeObject(contentSummary,'summaryDetails.availableConfiguration.softwareArray',[]).reduce((previous, current) =>  { return previous + `${previous ? ',' : ''} ${current.softwareName.toUpperCase()}`},'')}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">
                            {`${textTransform('title', t("common:device", { count: 2}))}: ${getFromSafeObject(contentSummary,'summaryDetails.availableConfiguration.deviceArray',[]).reduce((previous, current) => { return previous + `${previous ? ',' : ''} ${current.deviceName.toUpperCase()}`},'')}`}
                            </Typography>
                        </Grid>
                    </div>
                </div>
                <Divider variant='middle'style={styles.divider} />
                <div className='class-content-overview-info-section-container'>
                    <div className='class-content-overview-info-section-title-container'>
                        <Typography variant="body1">
                        {textTransform('title', t("common:certificate", { count: 2}))}
                        </Typography>
                    </div>
                    <div className='class-content-overview-info-section-info-container'>
                        { contentSummary.summaryDetails.certificate != null ?
                            <>
                                <Typography variant="body1">
                                    {t("getACertificateIf")}
                                </Typography>
                                {/* // TODO: Get a certificate    */}
                                <div className='class-content-overview-button'>
                                    <Button variant='outlined' color='primary' disabled={!isValidDate(userProgress.completionDate)}>
                                        {t("getACertificateButton")}
                                    </Button>
                                </div>
                            </>
                            :
                                <Typography variant="body1">
                                    {t("noOfferCertificate")}
                                </Typography>
                        }
                    </div>
                </div>
                <Divider variant='middle' style={styles.divider} />
                <div className='class-content-overview-info-section-container'>
                    <div className='class-content-overview-info-section-title-container'>
                        <Typography variant="body1">
                        {textTransform('title', t("common:description"))}
                        </Typography>
                    </div>
                    <div className='class-content-overview-info-section-info-container'>
                        <Typography variant="body1">
                            {getFromSafeObject(contentSummary,'description.description','')}
                        </Typography>
                    </div>
                </div>
                <Divider variant='middle' style={styles.divider} />
                <div className='class-content-overview-info-section-container'>
                    <div className='class-content-overview-info-section-title-container'>
                        <Typography variant="body1">
                            {textTransform('title', t("common:author", { count: 2 }))}
                        </Typography>
                    </div>
                    <div className='class-content-overview-info-section-info-container'>
                        {getFromSafeObject(contentSummary,'summaryDetails.professorArray',[]).map((professor, index) => {
                            return(
                                <div key={index}>
                                    <Typography variant="body1">
                                        {`${professor.fullName}`}
                                    </Typography>
                                </div >
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClassContentOverview