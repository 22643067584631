import { useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { ErrorOutline, WarningAmber, Done } from '@mui/icons-material';
import './toggleMsg.css'

const ToggleMsg = ({ isShown, absolutePositon=false, type='error', msg='', variant='body2', showTimeMs=3000, onShowTime=()=>{}, extraClassName, ...props }) => {
  const theme = useTheme();
  type = ['error', 'warning', 'success'].includes(type) ? type : 'error'
  const styles = {
    icon: {
      padding: '0px 5px',
      display: 'block',
      color: theme.palette[type].main,
      fontSize: theme.typography[variant].fontSize
    },
    msg: {
      color: theme.palette[type].main,
      whiteSpace: 'pre-line'
    }
  }

  const getIconComponent = () => {
    let IconComponent;
    switch(type){
      case 'error':
          IconComponent = ErrorOutline
        break;
      case 'success':
          IconComponent = Done
        break;
      default:
          IconComponent = WarningAmber
        break;
    }
    return <IconComponent style={styles.icon} />
  }
  
  useEffect(() => {
    if(isShown === true && showTimeMs != null && typeof onShowTime === 'function' ){
      const timeoutId = setTimeout(onShowTime, showTimeMs);
      return(() => {clearTimeout(timeoutId)})
    }
  },[isShown])

  return (
    <div {...props} 
      className={`${props.className ? `${props.className}` : `toggle-msg-wrapper${absolutePositon ? ' absolute' : ''} ${isShown ? absolutePositon ? ' show-absolute' : ' show' : ''}`}${extraClassName ? ` ${extraClassName}` : ''}`}>
      <Grid container className='toggle-msg-container'>
        <Grid item>
          {getIconComponent()}
        </Grid>
        <Grid item xs>
          <Typography style={styles.msg} variant={variant} >
            {msg}
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

export default ToggleMsg