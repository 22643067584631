import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Chip } from '@material-ui/core'
import { Skeleton } from '@mui/material';
import { textTransform, textTruncate } from '../../aux/aux';
import MeetingOccurrenceDate from '../MeetingOccurrenceDate/MeetingOccurrenceDate';
import WarningAmber from '@mui/icons-material/WarningAmber';
import ScheduleIcon from '@mui/icons-material/Schedule';
import './userMeetingBadge.css'
import { getReliableLocale } from '../../aux/sessionHelpers';

/**
 * 
 */
const UserMeetingBadge = ({ userMeeting, userMeetingStatus, userMeetingStatusArray }) => {
    // DEBUG: STARTS
    const locale = getReliableLocale();
    // DEBUG: ENDS
    const { t } = useTranslation('common', { keyPrefix: "userMeetingBadge" });
    const styles = {
        title:{
            fontWeight:'bold',
        },
        dateContainer:{
            height: '50px',
            width: '50px'
        },
        icon:{
            display: 'block',
            paddingRight: '5px',
            fontSize: '1.2em'
        },
        warningIcon:{
            display: 'block',
            fontSize: '2.5em'
        },
        warningMsg:{
            whiteSpace: 'pre-line'
        }
    }

    /**
     * 
     * @param {*} userMeetingStatus ['mounting', 'requesting', 'noOccurrence', 'occurrence', 'error'];
     */
    const getComponentToRender = (userMeetingStatus) => {
        let componentToRender;
        switch(userMeetingStatus){
            case userMeetingStatusArray[4]:
                componentToRender = 
                    <>
                        <Grid item className='user-meeting-badge-warning-icon-wrapper'>
                            <WarningAmber style={styles.warningIcon} /> 
                        </Grid>
                        <Grid item className='user-meeting-badge-description-container'>
                            <Typography style={styles.warningMsg} variant="body1">
                                {
                                    `${textTransform('title', t("meetingDataNoAvail"))}`
                                    +`\n${textTransform('title', t("tryLater"))}`
                                }
                            </Typography>
                        </Grid>
                    </>
                break
            case userMeetingStatusArray[3]:
                componentToRender = 
                    <>
                        <Grid item>
                            <MeetingOccurrenceDate 
                                startsDate={new Date(userMeeting.details.occurrence.startsDateTime)}
                                fontDateSize='1.2em'
                                fontMonthSize='0.8em'
                                style={styles.dateContainer}
                            />
                        </Grid>
                        <Grid item className='user-meeting-badge-description-container'>
                            <Grid container direction='column'>
                                <Grid item>
                                    <Typography variant='body1' style={styles.title}>
                                        {textTruncate(textTransform('title', userMeeting.details.meeting.description.topic), 30)}
                                    </Typography>
                                </Grid>
                                <Grid item >
                                    <Grid container alignItems='center'>
                                        <Grid item>
                                            <ScheduleIcon style={styles.icon} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='body2'>
                                                {`${new Date(userMeeting.details.occurrence.startsDateTime).toLocaleTimeString(locale.localeCode)} - ${new Date(userMeeting.details.occurrence.endsDateTime).toLocaleTimeString(locale.localeCode)}`}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>    
                break;
            case userMeetingStatusArray[2]:
                componentToRender = 
                    <>
                        <Grid item>
                            <MeetingOccurrenceDate 
                                fontDateSize='1.2em'
                                fontMonthSize='0.8em'
                                style={styles.dateContainer}
                            />
                        </Grid>
                        <Grid item className='user-meeting-badge-description-container'>
                            <Grid container direction='column'>
                                <Grid item>
                                    <Typography variant='body1' style={styles.title}>
                                        {textTruncate(textTransform('title', userMeeting.details.meeting.description.topic), 30)}
                                    </Typography>
                                </Grid>
                                <Grid item >
                                    <Typography variant='body2'>
                                        {textTransform('title', t("noAttendaceRegistration"))}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                break;
            case userMeetingStatusArray[1]:
            case userMeetingStatusArray[0]:
                componentToRender = 
                    <>
                        <Grid item>
                            <Skeleton variant="rectangular" height={50} width={50} />
                        </Grid>
                        <Grid item className='user-meeting-badge-description-container'>
                            <Skeleton variant='text' width={150}/>
                            <Skeleton variant='text' width={80}/>
                        </Grid>
                    </>
                break;
        }
        return componentToRender;
    }
    
  return (
    <Grid container className='user-meeting-badge-main-container'>
        {getComponentToRender(userMeetingStatus)}
    </Grid>
  )
}

export default UserMeetingBadge