import { useEffect, useState } from "react";
import PopUpModal from "../PopUpModal/PopUpModal"
import CopyToClipboard from "../CopyToClipboard/CopyToClipboard";
import SharingButtonGroup from "../SharingButtonGroup/SharingButtonGroup";
import { textTransform } from "../../aux/aux";
import { getShareableProductURLPath } from "../../aux/productHelpers";
import { useTranslation } from "react-i18next";
import PopUpModalHeader from "../PopUpModalHeader/PopUpModalHeader";
import Share from '@mui/icons-material/Share';
import { Divider } from "@material-ui/core";
import './popUpShareModal.css'
const DEFAULT_POP_UP_WIDTH = '380px';
const PopUpShareModal = ({contentSummary, showModal, onClickClose, isDialog, title='', width=DEFAULT_POP_UP_WIDTH}) => {
    const { t } = useTranslation('common', { keyPrefix:'popUpShare'});
    const [urlToShare, setURLToShare] = useState('');
    const [msgToShare, setMsgToShare] = useState('');
    const hashtagArray = ['#qxinstitute', '#quantumSystems', '#learningWithTheBest']
    const sharingButtonProps = {
        facebook:{
            quote: msgToShare,
            hashtag: hashtagArray[0],
        },
        whatsapp:{
            title: msgToShare,
        },
        twitter:{
            title: msgToShare,
            hashtags: hashtagArray,
            // related: ['']
        },
        email:{
            subject: `${contentSummary.description.nameTranslated} ${t('inQXInstitute')}`,
            body: msgToShare,
        }
    }
    const getMsgToShare = () => {
        let msg = `${textTransform('title', t('iAmWatching'))} \"${contentSummary.description.nameTranslated}\" ${t('inQXInstitute')}.`;
        msg = contentSummary.description.description ? ` ${contentSummary.description.description}.` : '';
        msg += ` ${textTransform('title', t('itIsInteresting'))}. ${textTransform('title', t('hereYouHaveTheLink'))}:`
        return msg
    }
    const handleOnClose = () => {
        onClickClose()
    }
    
    useEffect(() => {
        const productName = contentSummary.name;
        const productTypeName = contentSummary.productTypeName;
        const subproductTypeName = contentSummary.summaryDetails.contentTypeName;
        let url = getShareableProductURLPath(productName, productTypeName, subproductTypeName, true);
        setURLToShare(url);
    },[])

    useEffect(() => {
        if(urlToShare){
            const msg = getMsgToShare();
            setMsgToShare(msg);
        }
    }, [urlToShare])

    return(
        <PopUpModal showModal={showModal} onClickClose={handleOnClose} width={width} isDialog={isDialog} >
            <PopUpModalHeader title={textTransform('title', title || t('common:share'))} Icon={Share}/>
            <Divider />
            <div className="pop-up-share-modal-main-container">
                <CopyToClipboard textToCopy={urlToShare} type='url' />
                <SharingButtonGroup url={urlToShare} buttonProps={sharingButtonProps} />
            </div>
        </PopUpModal>
    )
}

export default PopUpShareModal