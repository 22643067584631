import { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core'
import SocialProfileBadge from '../SocialProfileBadge/SocialProfileBadge';
import AccountIcon from "../../img/account-icon.svg";
import ImgDN from '../../cdn/imgDN';
import cloudinary from '../../cdn/providers/cloudinary';
import { generateRandomInt } from '../../aux/aux';
import './socialProofWidget.css'

const SocialProofWidget = ({badgeClassName, badgeStyle, children, ProfileIcon=AccountIcon, userProfileImgIdAndNameArray=undefined, numDisplayedUsers=3, maxDefaultUserImgLikelihood=0.5}) => {
    const DEFAULT_PROFILE_POOL_SIZE_FACTOR = 2 // times the visible profiles size
    const [defaultUserProfileImgIdArray, setDefaultUserProfileImgIdArray] = useState(null);
    const [socialProfileBadge, setSocilaProfileBadge] = useState(null);
    const retrieveDefaultUserProfileImgArray = async (maxLength, signal) => {
        try{
            const cdn = new ImgDN(cloudinary);
            const imgIdArray = await cdn.getAllImageIdInFolder('qxi/user/default', maxLength, signal);
            if(!signal.aborted){
                setDefaultUserProfileImgIdArray(imgIdArray);
            }
        }catch(error){
            console.log(error)
        }
    }

    const getSocialProfileBadge = () => {
        let markedDefaultProfileArray = [];
        const socialProfileBagdeArray = [... new Array(numDisplayedUsers)].map(( _, index) => {
            let imgId;
            let charToDisplay;
            if(userProfileImgIdAndNameArray?.length > index){
                const userProfile = userProfileImgIdAndNameArray[index]
                imgId = userProfile.imgId;
                if(userProfile.fullName){
                    charToDisplay = userProfile.fullName[0].toUpperCase(); 
                }
            }else{
                if(defaultUserProfileImgIdArray?.length > markedDefaultProfileArray.length){
                    let randomNum = generateRandomInt(1, 100);
                    const pickDefault = randomNum <= maxDefaultUserImgLikelihood*100;
                    if(pickDefault){
                        let randomIndex = randomNum % defaultUserProfileImgIdArray.length;
                        while(markedDefaultProfileArray.includes(randomIndex)){
                            randomIndex = (randomIndex + 1) % defaultUserProfileImgIdArray.length;
                        }
                        if(defaultUserProfileImgIdArray[randomIndex]){
                            markedDefaultProfileArray.push(randomIndex);
                            imgId = defaultUserProfileImgIdArray[randomIndex].imgId;
                        }
                    }
                }
                charToDisplay =  String.fromCharCode(generateRandomInt('A'.charCodeAt(), 'Z'.charCodeAt()));
            }
            return { imgId, charToDisplay };
        })
        setSocilaProfileBadge(socialProfileBagdeArray);
    }

    useEffect(() => {
        if(!userProfileImgIdAndNameArray || userProfileImgIdAndNameArray.length === 0){
            if(!defaultUserProfileImgIdArray || defaultUserProfileImgIdArray.length < numDisplayedUsers){
                const abortController = new AbortController();
                retrieveDefaultUserProfileImgArray(numDisplayedUsers * DEFAULT_PROFILE_POOL_SIZE_FACTOR, abortController.signal);
                return(() => {
                    abortController.abort()
                })
            }
        }else{
            setDefaultUserProfileImgIdArray(null);
            getSocialProfileBadge();
        }
    },[userProfileImgIdAndNameArray, numDisplayedUsers]);
    useEffect(() => {
        if(defaultUserProfileImgIdArray){
            getSocialProfileBadge();
        }
    },[defaultUserProfileImgIdArray, userProfileImgIdAndNameArray, ])
    return (
        <div className='social-proof-widget-main-container'>
            <div className='social-proof-widget-imgs-container'>
                { socialProfileBadge ? 
                    socialProfileBadge.map(( profile, index) => {
                        return(
                            <SocialProfileBadge key={index} 
                                className={`social-proof-widget-img${badgeClassName ? ` ${badgeClassName}` : ''}`} 
                                style={badgeStyle}
                                uri={profile.imgId} 
                                ProfileIcon={ProfileIcon}
                                charToDisplay={profile.charToDisplay} 
                            />
                        )
                    })
                    :
                    null
                }
            </div>
            {children}
        </div>
    )
}

export default SocialProofWidget