import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, TextField, Typography, Box } from '@material-ui/core';
// Component
import Timer from '../Timer/Timer';
import ClickableLoadingText from '../ClickableLoadingText/ClickableLoadingText';
// Helpers
import { fromCentTo2dec, priceToString } from '../../aux/purchaseHelpers';
// Styles
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import './checkoutPromoCode.css';
import { useTheme } from '@material-ui/styles';
import { getReliableLocale } from '../../aux/sessionHelpers';
import { getFromSafeObject, isEmptyObject, isNotEmptyObject, isValidDate } from '../../aux/aux';
import { getCustomErrorOrUndefined, isCustomError } from '../../aux/errorHelpers';

const CheckoutPromoCode = ({ applyPromoCode, takeOutPromoCode, checkoutContent, appliedPromoCode, isCheckoutUpdating }) => {
    const theme = useTheme();
    const { t } = useTranslation('checkout', { keyPrefix: 'checkoutPromoCode' });
    const [isLoading, setIsLoading] = useState(false);
    const [isCheckoutEmpty, setIsCheckoutEmpty] = useState(null);
    const [promoFeedback, setPromoFeedback] = useState({});
    const [dateToUnlockPromoCode, setDateToUnlockPromoCode] = useState(undefined);
    const [showPromoFeedback, setShowPromoFeedback] = useState(false);
    const [inputExpanded, setInputExpanded] = useState(false);
    const [code, setCode] = useState('');
    const abortControllerRef = useRef(null);

    const styles = {
        wrapperOnDisabled:{
            opacity: theme.palette.action.disabledOpacity
        },
        wrapperOnFocus:{
            borderColor: theme.palette.primary.main,
        },
        wrapperOnSuccess:{
            borderColor: theme.palette.success.main,
            backgroundColor: theme.palette.common.white 
        },
        wrapperOnError:{
            borderColor: theme.palette.error.main,
        },
        inputButton:{
            color: theme.palette.primary.main,
        },
        inputOnError:{
            color: theme.palette.error.main,
        },
        inputOnSuccess:{
            color: theme.palette.success.main,
        },
        errorFeedback:{
            color: theme.palette.error.main,
        },
        icon:{
            display:'block',
            fontSize: theme.typography.body1.fontSize
        }

    }

    const getPromoCode = async ( e, code, abortController=abortControllerRef.current ) => {
        if(code){
            try{
                setIsLoading(true)
                // resetPromoFeedback();
                const data = await applyPromoCode(code)
                const {
                    updatedCheckoutContent,
                    promoCode
                } = data;
                // const feedback = getPromoFeedback(promoCode, updatedCheckoutContent.orderAmounts);
                // setPromoFeedback(feedback);
                updatePromoCodeFeedback(promoCode, updatedCheckoutContent)
            }catch(error){
                // set wrong code error
                if(!abortController.signal.aborted){
                    const customError = getCustomErrorOrUndefined(error);
                    if(customError !== undefined){
                        const promoCode = customError.payload.promoCode;
                        if(isNotEmptyObject(promoCode)){
                            updatePromoCodeFeedback(promoCode, checkoutContent);
                        }
                    }else{
                        const feedback = {
                            isApplicable: false,
                            errorMsgArray: [t("invalidCode.invalidCode")]
                        }
                        setPromoFeedback(feedback);
                    }
                }
            }finally{
                if(!abortController.signal.aborted){
                    setIsLoading(false)
                    setShowPromoFeedback(true);
                }
            }
        }
    };

    const updatePromoCodeFeedback = (promoCode, checkoutContent, abortController=abortControllerRef.current) => {
        if(!abortController.signal.aborted){
            setIsLoading(true);
            const feedback = getPromoFeedback(promoCode, checkoutContent.orderAmounts);
            setPromoFeedback(feedback);
            setIsLoading(false)
            setShowPromoFeedback(true);
        }
    }

    const removePromoCode = async ( e, abortController=abortControllerRef.current) => {
        if(appliedPromoCode){
            setIsLoading(true);
            try{
                await takeOutPromoCode();
            }catch(error){
                if(!abortController.signal.aborted){
                    const feedback = {
                        ...promoFeedback,
                        errorMsgArray: [t("errorTakingOut")]
                    }
                    setPromoFeedback(feedback);
                    setShowPromoFeedback(true);
                }
            }finally{
                if(!abortController.signal.aborted){
                    setIsLoading(false);
                }
            }
        }
    };

    const getPromoFeedback = ( promoCode, updatedOrderAmounts ) => {
        const isApplicableDetails = promoCode.isApplicableDetails;
        const infoMsgArray = [];
        const errorMsgArray = [];
        const checkoutDiscountArray = [];
        if(isApplicableDetails.isApplicable){
            const promoCodeApplicabilityDetails = isApplicableDetails.details.type.promoCode;
            const isExclusiveProductTypesPromo = promoCodeApplicabilityDetails.isExclusiveProductTypesPromo;
            const isExclusiveProductsPromo = promoCodeApplicabilityDetails.isExclusiveProductsPromo;
            if(isExclusiveProductsPromo || isExclusiveProductTypesPromo){
                const{
                    exclusiveProductInCheckoutArray,
                    isApplicableToProductAmountDetailsMap
                } = promoCodeApplicabilityDetails;
                const {
                    applicableProductArray,
                    excludedProductArray,
                } = exclusiveProductInCheckoutArray
                if(applicableProductArray.length > 0){
                    applicableProductArray.forEach( productId => {
                        let productAmount = updatedOrderAmounts.productAmounts[productId];
                        checkoutDiscountArray.push({
                            itemName: checkoutContent.productsAndCouponsMap[productId].description.nameTranslated,
                            promoCodeAmountOff: productAmount.promoCodeAmountOff,
                            currencyId: productAmount.currencyId,
                        })
                    });
                }else{
                    checkoutDiscountArray.push({
                        itemName: t("promoAppliedToNoProduct"),
                        promoCodeAmountOff: 0,
                        currencyId: updatedOrderAmounts.currencyId,
                    })
                }

                excludedProductArray.forEach( productId => {
                    let isApplicableToProductAmountDetails = isApplicableToProductAmountDetailsMap[productId];
                    let isNotApplicableToProductCaseKey = isApplicableToProductAmountDetails.details.isNotApplicableCase || '';
                    let msg = t(`invalidProductCode.${isNotApplicableToProductCaseKey}`, '');
                    if(msg){
                        msg = checkoutContent.productsAndCouponsMap[productId].description.nameTranslated + msg;
                        msg += isNotApplicableToProductCaseKey === 'wrongMinAmount' ? t('common:{{val, currency}}',{ val:fromCentTo2dec(isApplicableToProductAmountDetails.details.minAmount), currency: updatedOrderAmounts.currencyId, trailingZeroDisplay:'stripIfInteger'}) : '';
                        infoMsgArray.push(msg);
                    }
                });
            }else{
                checkoutDiscountArray.push({
                    itemName: updatedOrderAmounts.orderPromoCode.id.toUpperCase(),
                    promoCodeAmountOff: updatedOrderAmounts.totalOrderPromoCodeAmountOff,
                    currencyId: updatedOrderAmounts.currencyId, 
                })
            }
        }else{
            const isNotApplicableByDetailsType = isApplicableDetails.details.isNotApplicableByDetailsType;
            const isNotApplicableDetails = getFromSafeObject(isApplicableDetails, `details.type[${isNotApplicableByDetailsType}]`);
            let msg;
            if(isNotApplicableDetails){
                const isNotApplicableCaseKey = isApplicableDetails.details.isNotApplicableCase;
                msg = t(`invalidCode.${isNotApplicableByDetailsType}.${isNotApplicableCaseKey}`, '');
                if(msg){
                    msg += isNotApplicableCaseKey === 'wrongMinAmount' ? t('common:{{val, currency}}',{ val:fromCentTo2dec(isNotApplicableDetails.details.minAmount), currency: updatedOrderAmounts.currencyId, trailingZeroDisplay:'stripIfInteger'}) : '';
                }
            }
            if(!msg){
                msg = t("invalidCode.invalidCode");
            }
            errorMsgArray.push(msg);
                
        }
        const promoFeedback = {
            isApplicable: isApplicableDetails.isApplicable,
            errorMsgArray,
            infoMsgArray,
            checkoutDiscountArray
        };
        return promoFeedback
    }

    const onChangeInput = (e) => {
        setCode(e.target.value);
        if(showPromoFeedback){
            setShowPromoFeedback(false);
            setPromoFeedback({ ...promoFeedback, isApplicable: undefined});
        }
    }

    const resetPromoFeedback = () => {
        setShowPromoFeedback(false);
        setPromoFeedback((promoFeedback) => ({ ...promoFeedback, isApplicable: undefined }));
        setDateToUnlockPromoCode(undefined);
    }

    useEffect(() => {
        const abortController = new AbortController();
        abortControllerRef.current = abortController;
        return(() => abortController.abort())
    },[])

    useEffect(() => {
        if(!appliedPromoCode){
            // Relevant if appliedPromocode is modified from outside this component
            resetPromoFeedback();
        }else{
            setCode(appliedPromoCode.id)
            updatePromoCodeFeedback(appliedPromoCode, checkoutContent);
            if(appliedPromoCode.lockDetails?.releaseDate){
                setDateToUnlockPromoCode(new Date(appliedPromoCode.lockDetails.releaseDate))
            }else{
                setDateToUnlockPromoCode(undefined);
            }
        }
    }, [appliedPromoCode])

    useEffect(() => {
        let isEmpty = null;
        if(checkoutContent){
            isEmpty = isEmptyObject(checkoutContent.productsAndCouponsMap);
        }
        setIsCheckoutEmpty(isEmpty);
    },[checkoutContent])

    return (
        <div className="checkout-promo-code-main-container">
            {appliedPromoCode && isValidDate(dateToUnlockPromoCode) &&
                <div className={`checkout-promo-code-timer-container ${promoFeedback.isApplicable ? 'show' : ''} `}>
                    <div >
                        <Typography variant="body2">
                            {t("promoCodeIsLock")}
                        </Typography>
                    </div>
                    <div className='checkout-promo-code-timer'>
                        <Timer finalDate={dateToUnlockPromoCode} inSeconds={false} />
                    </div>
                </div>
            }
            <div className={`checkout-promo-code-input-container ${ inputExpanded || code ? 'expand': ''}`} onFocus={ e => setInputExpanded(true)}
            onBlur={ e => setInputExpanded(false)} style={isCheckoutEmpty ? styles.wrapperOnDisabled : promoFeedback.isApplicable === false ? {...styles.wrapperOnError} : promoFeedback.isApplicable === true ? {...styles.wrapperOnSuccess} : inputExpanded ? {...styles.wrapperOnFocus} : {}}>
                <div className={`checkout-promo-code-input-price-tag-container ${appliedPromoCode ? 'expand' : ''}`}>
                    <LocalOfferOutlinedIcon color='success' style={styles.icon}/>
                </div>
                <div className='checkout-promo-code-input-wrapper'>
                    <input className= 'checkout-promo-code-input' type={'text'} disabled={appliedPromoCode || isCheckoutEmpty} placeholder={t("addPromo")} onChange={onChangeInput}
                    value={code} style={ promoFeedback.isApplicable === false ? styles.inputOnError : promoFeedback.isApplicable === true ? styles.inputOnSuccess : {}}>
                    </input>
                </div>
                <div>
                    <ClickableLoadingText text={appliedPromoCode ? t("change") : t("apply")} isVisible={code && promoFeedback.isApplicable != false} disabled={isCheckoutUpdating && !isLoading} isLoading={isLoading} onClick={(e) => appliedPromoCode ? removePromoCode(e) : getPromoCode(e,code)} 
                    color={appliedPromoCode ? theme.palette.success.main : theme.palette.primary.main }/>
                </div>
            </div>
            <div className={`checkout-promo-code-input-feedback-container ${showPromoFeedback? 'expand': ''}`}>
                {promoFeedback.errorMsgArray && promoFeedback.errorMsgArray.length > 0 &&
                    <div className='checkout-promo-code-input-feedback-error'>
                        {promoFeedback.errorMsgArray.map( (errorMsg, index) => {
                            return(
                                <Typography key={index} variant='body2' style={styles.errorFeedback} >
                                    {errorMsg}
                                </Typography>
                            )
                        })}
                    </div>
                }
                {promoFeedback.infoMsgArray && promoFeedback.infoMsgArray.length > 0 && 
                    <div className='checkout-promo-code-input-feedback-error'>   
                        {promoFeedback.infoMsgArray.map( (infoMsg, index) => {
                            return(
                                <Typography key={index} variant='body2' >
                                    {infoMsg}
                                </Typography>
                            )
                        })}
                    </div>
                }
                { promoFeedback.checkoutDiscountArray && promoFeedback.checkoutDiscountArray.length > 0 &&
                    <div className="checkout-promo-code-input-feedback-success" >
                        {promoFeedback.checkoutDiscountArray.map( (item, index) => {
                            return(
                                <div key={index} style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                                    <div>
                                        <Typography>
                                            {`${t("promo")}: `}
                                            <Box display="inline">
                                                {item.itemName}
                                            </Box>
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography>
                                            {t('common:{{val, currency}}',{ val:fromCentTo2dec(item.promoCodeAmountOff * -1), currency: item.currencyId, trailingZeroDisplay:'stripIfInteger'})}
                                        </Typography>
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default CheckoutPromoCode