import { Typography } from '@material-ui/core'
import './availUnitWidget.css'
import { useTranslation } from 'react-i18next'


const AvailUnitWidget = ({
    symbol=undefined, 
    symbolNoLeft=undefined, 
    symbolAtEnd=false,
    fullMessage=false, 
    count=undefined, 
    unitType=undefined, 
    context='female', 
    ...props
}) => {
    const { t } = useTranslation('common', {keyPrefix:'availUnitWidget'});
    symbol = symbol || t('common:symbolFire');
    symbolNoLeft = symbolNoLeft || t('common:symbolLocked');
    return (
        <Typography {...props} >
            {`${symbolAtEnd ? '' : count === 0 ? symbolNoLeft : symbol} ${ count ? 
                    t(fullMessage ? 'onlyUnitLeft' : 'unitLeft', {unitType, count}) 
                : 
                    count === 0 ? 
                            t('noUnitLeft', {unitType, context}) 
                        : 
                            t('fewLeft', {unitType, context})
                }
            ${symbolAtEnd ? count === 0 ? symbolNoLeft : symbol : '' }`}
        </Typography>
    )
}

export default AvailUnitWidget