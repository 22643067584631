/**
 * This module containes product-related queries
 */
import { getLessonVersionExpRequestParameter, getSystemRequestParameter } from "./contentProductHelpers";
import { publicRequest, publicThirdPartyRequest } from "./requestMethods";
import { createAuthRequest } from "./requestMethods";

const getProductSummaryFn = async ( params, signal=null ) => {
    const {
        productId,
        localeId
    } = params;
    return publicRequest.get(`/product/get-summary/${productId}/${localeId}`, { signal });
}

const getProductPageFn = async( params, signal=null) => {
    const {
        productName,
        localeId
    } = params;
    return publicRequest.get(`/product/get-page/${productName}/${localeId}`, { signal });
}

const getProductConsumptionDetailsCollectionFn = async( params, signal=null) => {
    const {
        productDetailsArray,
        currencyId,
        countryCode,
        ...query
    } = params;
    return publicRequest.post(`/product/consumption-details/collection/${countryCode}/${currencyId}`, { productDetailsArray }, { params:query, signal });
}

const getProductConsumptionOptionMapFn = async( params, signal=null) => {
    const {
        productName,
        currencyId,
        countryCode,
        ...query
    } = params;
    return publicRequest.get(`/product/consumption-option-map/${countryCode}/${currencyId}/${productName}`, { params:query, signal });
}

const getContentProductStructurePreviewFn = async ( params, signal=null ) => {
    const {
        productIdOrName,
        localeId,
        system,
        query
    } = params;
    return publicRequest.get(`/content/content-product-structure-preview/${productIdOrName}/${localeId}/${system}`, {params:query, signal} )
}

const getContentProductStructureFn = async ( params, signal=null ) => {
    const {
        userId,
        productId,
        localeId,
        system,
        query
    } = params;
    const systemParameter = getSystemRequestParameter(system);
    const userRequest = createAuthRequest();
    return userRequest.get(`/content/content-product-structure/${userId}/${productId}/${localeId}/${systemParameter}`, {params:query, signal} ); 
}

const getContentProductLessonFn = async ( params, signal=null ) => {
    const {
        userId,
        productId,
        lessonId,
        localeId,
        lessonVersionDecisor
    } = params;
    const lessonVersionExpParameter = getLessonVersionExpRequestParameter(lessonVersionDecisor);
    const userRequest = createAuthRequest();
    return userRequest.get(`/content/get-content-product-lesson/${userId}/${productId}/${lessonId}/${localeId}/${lessonVersionExpParameter}`, { signal });
} 

const getContentProductOpenLessonFn = async ( params, signal=null ) => {
    const {
        productIdOrName,
        lessonId,
        localeId,
        lessonVersionDecisor
    } = params;
    const lessonVersionExpParameter = getLessonVersionExpRequestParameter(lessonVersionDecisor);
    return publicRequest.get(`/content/get-content-product-open-lesson/${productIdOrName}/${lessonId}/${localeId}/${lessonVersionExpParameter}`, { signal });
} 

const getContentProductTrailerFn = async ( params, signal=null ) => {
    const {
        productIdOrName,
        localeId
    } = params;
    return publicRequest.get(`/content/trailer/${productIdOrName}/${localeId}`, { signal });
} 

const getContentProductLessonDownloadFn = async ( params, signal=null ) => {
    const {
        userId,
        productId,
        lessonId,
        lessonVersionId,
        localeId,
        contentFileUri,
        contentFileType
    } = params;
    const config = {
        signal,
        responseType: 'blob',
        headers:{
            'Content-Type': contentFileType,
        }
    }
    const userRequest = createAuthRequest();
    return userRequest.get(`/content/get-content-product-lesson-download/${userId}/${productId}/${lessonId}/${localeId}/${lessonVersionId}/${contentFileUri}`, config);
}

const getFileFromCDNFn = async ( params, signal=null) => {
    const {
        contentType,
        url
    } = params;
    const config = {
        signal,
        responseType: 'blob',
        headers:{
            'Content-Type': contentType,
        }
    }
    // const proxyCORS =  'https://stormy-cliffs-96348.herokuapp.com/';
    // const url = new URL(proxyCORS + contentFileUri.);
    return publicThirdPartyRequest.get(url, config);
}

const getContentProductInfoFn = async(params, signal=null) => {
    return publicRequest.get('/content/product-info', { params, signal})
}

// const getFile = (downloadItem) => {
//     const proxyCORS =  'https://stormy-cliffs-96348.herokuapp.com/';//'https://thingproxy.freeboard.io/fetch/'; //'https://secret-ocean-49799.herokuapp.com/';
//     const url = new URL(proxyCORS + downloadItem.);
//     fetch(url, {
//             method: 'GET',
//             headers: {
//             'Content-Type': downloadItem.contentType,
//             // 'Content-Disposition' : 'attachment'
//             }
//         })
//         .then((resp) => resp.blob())
//         .then((blob) => {
//             const fileName = getLessonFileName(downloadItem);
//             downloadFileFromBlob(blob, fileName);
//     });
// }

export{
    getProductSummaryFn,
    getProductPageFn,
    getProductConsumptionDetailsCollectionFn,
    getProductConsumptionOptionMapFn,
    getContentProductStructurePreviewFn,
    getContentProductStructureFn,
    getContentProductLessonFn,
    getContentProductOpenLessonFn,
    getContentProductLessonDownloadFn,
    getFileFromCDNFn,
    getContentProductInfoFn,
    getContentProductTrailerFn
}