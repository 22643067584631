import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/styles'
import ValuePropositionItem from '../ValuePropositionItem/ValuePropositionItem'
import CheckCircleOutlineIcon from '../../img/checkCircleOutline.svg'
import './landingValuePropositions.css'
import StyledTypography from '../StyledTypography/StyledTypography';

const LandingValuePropositions = () => {
    const theme = useTheme()
    const { t } = useTranslation('landing', { keyPrefix: 'landingValuePropositions' });
    const matchMobile = useMediaQuery('(max-width:680px)')
    const valuePropositionMap = {
        bestExperts:{
            title: t('alongWithTheBestExperts'),
            description: t('alongWithTheBestExpertsDescription'),
            keyPointArray: [
                {
                    Icon: CheckCircleOutlineIcon,
                    title: t('connectWithLeadersInThisTechnology')
                },
                {
                    Icon: CheckCircleOutlineIcon,
                    title: t('learnTheirMostEffectiveStrategies')
                },
                {
                    Icon: CheckCircleOutlineIcon,
                    title: t('receiveSupportInYourCases')
                }
            ],
            imgURL: '/img/valueProposition-1_400x250.svg',
            imgDecoratorURL: '/img/decorator-valueProposition-1_400x250.svg'
        },
        stateOfTheArtContent:{
            title: t('contentDesignedForYou'),
            description: t('contentDesignedForYouDescription'),
            keyPointArray: [
                {
                    Icon: CheckCircleOutlineIcon,
                    title: t('exclusiveContent')
                },
                {
                    Icon: CheckCircleOutlineIcon,
                    title: t('completedClearAndSpecific')
                },
                {
                    Icon: CheckCircleOutlineIcon,
                    title: t('designedToBeAppliedInYourSessions')
                },
            ],
            imgURL: '/img/valueProposition-2_400x250.svg',
            imgDecoratorURL: '/img/decorator-valueProposition-2_400x250.svg'
        },
        theWayYouWantIt:{
            title: t('theWayYouWantIt'),
            description: t('theWayYouWantItDescription'),
            keyPointArray: [
                {
                    Icon: CheckCircleOutlineIcon,
                    title: t('adaptedToYourAgendaAndExpendingLittleTime')
                },
                {
                    Icon: CheckCircleOutlineIcon,
                    title: t('personalizedToYourSystem')
                },
                {
                    Icon: CheckCircleOutlineIcon,
                    title: t('combiningVideosDownloadableAndLiveSessions')
                },
            ],
            imgURL: '/img/valueProposition-3_400x250.svg',
            imgDecoratorURL: '/img/decorator-valueProposition-3_400x250.svg'
        }
    }
    const styles = {
        title:{
            color: theme.palette.text.primary,
            fontWeight: 'bold',
            textAlign: 'center'
        },
    }
    return (
        <div className='landing-value-propositions-wrapper'>
            <Grid container direction='column' className='landing-value-propositions-main-container'>
                <Grid item className='landing-value-propositions-title'>
                    <StyledTypography variant={matchMobile ? 'h5' : 'h4'} style={styles.title}>
                        {t('rediscoverYourQuantumSystem')}
                    </StyledTypography>
                </Grid>
                {Object.values(valuePropositionMap).map((valueProposition, index) => {
                    const { 
                        title,
                        description,
                        keyPointArray,
                        imgURL, 
                        imgDecoratorURL
                    }= valueProposition
                    const isReversed = index % 2 != 0
                    return(
                        <Grid item key={index} className={`landing-value-propositions-item-wrapper ${index === Object.values(valuePropositionMap).length - 1 ? 'last-child' : ''}`}>
                            <ValuePropositionItem itemNum={index} title={title} description={description} keyPointArray={keyPointArray} imgURL={imgURL} imgDecoratorURL={imgDecoratorURL} reversed={isReversed} />
                        </Grid>
                    )
                })}
            </Grid>
        </div>
    )
}

export default LandingValuePropositions