import { Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles'
import StyledTypography from '../StyledTypography/StyledTypography'
import './heroMessage.css'

const DEFAULT_PROPS = {}
export const HeroMessage = ({ title, subtitle, author, position, titleProps=DEFAULT_PROPS, subtitleProps=DEFAULT_PROPS, authorProps=DEFAULT_PROPS, positionProps=DEFAULT_PROPS, ...props}) => {
    const theme = useTheme()
    const matchMobile = useMediaQuery('(max-width: 680px)');
    const propMap = {
        title:{
            variant: matchMobile ? 'h5' : 'h4',
            ...titleProps,
            style:{
                fontWeight: 'bold',
                ...titleProps.style
            }
        },
        subtitle:{
            variant: matchMobile ? 'h6' : 'h5',
            ...subtitleProps,
            style:{
                fontWeight: 'normal',
                ...subtitleProps.style
            }
        },
        author:{
            variant: 'body1',
            ...authorProps,
        },
        position:{
            variant: 'body2',
            ...positionProps,
            style:{
                color: theme.palette.grey[400],
                ...positionProps.style
            }
        }
    }
    return (
        <div className='hero-message-main-container' {...props}>
            <StyledTypography {...propMap.title}>
                {title}
            </StyledTypography>
            <StyledTypography {...propMap.subtitle}>
                {subtitle}
            </StyledTypography>
            <Typography {...propMap.author}>
                {author}
            </Typography>
            <Typography {...propMap.position}>
                {position}
            </Typography>
        </div>
    )
}
