import { useEffect } from "react";
import { AppBar, useTheme, useMediaQuery } from "@material-ui/core";
import { Link } from 'react-router-dom';
import { useState } from "react";
import { useScrollTrigger } from "@material-ui/core";

import Logo from "../../img/logo-alpha.svg";
import LogoNeg from "../../img/logo-alpha-neg.svg";
import { useScrollbarWidth } from "../../customHooks/useScrollbarWidth";

// Style
import "./headerSimple.css";

const HeaderSimple = ({ elevation=4 }) => {
    const HEADER_HEIGHT = 60;
    const theme =  useTheme();
    const isScrollTriggered = useScrollTrigger({ disableHysteresis:true, threshold: 100 });
    const [isLogoAnimationEnded, setIsLogoAnimationEnded] = useState(false);
    const matchMobile = useMediaQuery('(max-width:680px)');
    const [hover, setHover] = useState(false);
    const styles = {
        mainContainer:{
            height: `${HEADER_HEIGHT}px`,
            // marginRight: isDocScrollable ? null : `${scrollbarWidth}px`,
            backgroundColor: isScrollTriggered ? 'white' : 'transparent',
            boxShadow: isScrollTriggered ? theme.shadows[elevation] : theme.shadows[0],
        },
        icon:{
            display:'block',
            textDecoration: 'none !important'
        }
    }

    const onMouseL = () => {
        setHover(false);
    }
    const onMouseE = () => {
        setHover(true);
    }

    const onLogoAnimationEnd = (e) => {
        if(e.animationName === 'translation-center'){
            setTimeout(() => setIsLogoAnimationEnded(true), 300);
        }
    }

    useEffect(() => {
        if(!matchMobile){
            setIsLogoAnimationEnded(false);
        }
    },[matchMobile])
    
    return (
            <div className='header-simple-wrapper'>
                <div className='header-simple-main-container' style={styles.mainContainer}>
                    {matchMobile ?
                        <div className='header-simple-toolbar'>
                            <div className='header-simple-logo-container' onAnimationEnd={onLogoAnimationEnd} >
                                <Link to="/" {... matchMobile ? {} : {onMouseEnter: onMouseE, onMouseLeave: onMouseL}}>
                                    {hover ? <LogoNeg alt="logo" height={30} style={styles.icon} /> : <Logo alt="logo" height={30} style={styles.icon} />} 
                                </Link>
                            </div>
                        </div>
                    :
                        <div className='header-simple-toolbar'>
                            <div className='header-simple-logo-container' >
                                <Link to="/" {... matchMobile ? {} : {onMouseEnter: onMouseE, onMouseLeave: onMouseL}}>
                                    {hover ? <LogoNeg alt="logo" height={30} style={styles.icon} /> : <Logo alt="logo" height={30} style={styles.icon} />} 
                                </Link>
                            </div>
                        </div>
                    }
                </div>
            </div>
    )
}

export default HeaderSimple