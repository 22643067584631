import { Trans, useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import MailingListSubscription from '../MailingListSubscription/MailingListSubscription'
import HeaderWrapperSection from '../HeaderWrapperSection/HeaderWrapperSection'
import { textTransform } from '../../aux/aux'
import './mailingListSubscriptionSection.css'

const MailingListSubscriptionSection = ({ 
    user, 
    subscriptionListArray,
    translations={
        title:undefined, 
        subtitle:undefined, 
        description:undefined,
        inputPlaceholder:undefined, 
        buttonLabel:undefined,
        subscriptionListTranslationMap:undefined
    },
    styles={
        wrapper:undefined
    }
}) => {
    const { t } = useTranslation('common', { keyPrefix:'mailingListSubscriptionSection'})
    const {
        title = <Trans t={t} i18nKey={'title'} components={{ underline: <span style={{textDecoration:'underline'}} /> }}/>,
        subtitle = t('subtitle'), 
        description = t('subscriptionDescription'),
        inputPlaceholder = textTransform('title', t('insertYourEmail')), 
        buttonLabel = t("common:send"), 
        subscriptionListTranslationMap = t('subscriptionLists', {returnObjects:true})
    } = translations
    return (
        <div className='mailing-list-subscription-section-wrapper' style={styles.wrapper}>
            <HeaderWrapperSection
                title={title}
                subtitle={subtitle}
                className={'mailing-list-subscription-section-main-container'}
                titleClassName={'mailing-list-subscription-section-title'}
                subtitleClassName={'mailing-list-subscription-section-subtitle'}
            >
                <div className='mailing-list-subscription-section-bottom-container'>
                    <div className='mailing-list-subscription-section-text-container'>
                        <Typography variant={'body1'}>
                            {description}
                        </Typography>
                    </div>
                    <div className='mailing-list-subscription-section-form-container'>
                        <MailingListSubscription 
                            user={user}
                            inputPlaceholder={inputPlaceholder}
                            buttonLabel={buttonLabel}
                            subscriptionListArray={subscriptionListArray}
                            subscriptionListTranslationMap={subscriptionListTranslationMap}
                        />
                    </div>
                </div>
            </HeaderWrapperSection>
        </div>
    )
}

export default MailingListSubscriptionSection