import { useEffect, useState } from 'react';
import { getFromSafeObject, isNotEmptyObject } from '../../aux/aux';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import MeetingOccurrenceDate from '../MeetingOccurrenceDate/MeetingOccurrenceDate'
import MeetingPanelistBadge from '../MeetingPanelistBadge/MeetingPanelistBadge'

import './userMeetingDateBadge.css'

const UserMeetingDateBadge = ({ userMeeting, userMeetingStatus, userMeetingStatusArray, isExpectedMeetingId }) => {
    const styles = {};
    const getMeetingPanelistBadge = (panelArray) => {
        const PX_BETWEEN_OVERLAPPING_BADGE = 7;
        const meetingPanelBadgeComponentArray = []
        for(let index = panelArray.length - 1; index >= 0; index--){
            let position = panelArray.length - (1 + index);
            let meetingPanelist = panelArray[index];
            let panelist = meetingPanelist.panelist;
            const isProfessor = panelist.professorUserId != null;
            const imgId = getFromSafeObject(panelist,'details.imageDetails.main.publicId');
            meetingPanelBadgeComponentArray.push(
                <div key={`panelist_${index}`} className='user-meeting-date-badge-panelist' 
                    style={{
                        right: `${position * PX_BETWEEN_OVERLAPPING_BADGE}px`
                    }}
                >
                    <MeetingPanelistBadge 
                        imgId={imgId}
                        isProfessor={isProfessor}
                        imgUrl={panelist.panelImgURL}
                        label={panelist.fullName} 
                    /> 
                </div>
            )
        }
        return meetingPanelBadgeComponentArray
    }

    const getComponentToRender = (userMeeting, userMeetingStatus) => {
        let componentToRender;
        try{
            switch(userMeetingStatus){
                case userMeetingStatusArray[3]:
                    componentToRender = 
                        <>
                            <MeetingOccurrenceDate 
                                startsDate={new Date(userMeeting.details.occurrence.startsDateTime)}
                            />
                            {getMeetingPanelistBadge(userMeeting.details.meeting.details.meetingPanelArray)}
                        </>
                    break;
                default:
                    // Nothing to render
                    break;
            }
        }catch(error){
            // Uncatched error: show the loading component
            // This error could be occasionated by inconsistencies between userMeeting and userMeetingStatus
            // This state might be triggered by updating clearing userMeeting while userMeetingStatus has set 'occurrence' as status
            componentToRender = <LoadingComponent visibleElements='circle' />
        }finally{
            return componentToRender
        }
    }
    
    return (
        <div className={`user-meeting-date-badge-main-container ${userMeetingStatus === userMeetingStatusArray[3] && isExpectedMeetingId ? 'show' : ''}`}>
            {getComponentToRender(userMeeting, userMeetingStatus)}
        </div>
    )
}

export default UserMeetingDateBadge