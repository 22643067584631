import { useRef, useState } from 'react'
import { Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import './countDown.css'
import { isValidDate, toMillisecondsFromTimescale, toTimescaleFromMilliseconds } from '../../aux/aux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const CountDown = ({ label, endsDate, stylesMap={mainContainer:undefined, digitContainer:undefined, digits:undefined, label:undefined} }) => {
    const MIN_INTERVAL_MS = 1000;
    const REVERSED_COUNTER_TIMESCALE = ['day', 'hour', 'minute', 'second'];
    const DEFAULT_COUNTER = [0, 0, 0, 0] // [sec, min, hour, day];
    const theme = useTheme()
    const { t } = useTranslation('common');
    const [counter, setCounter] = useState(DEFAULT_COUNTER);
    const intervalIdRef = useRef(null);
    const styles = {
        mainContainer:{
            color: theme.palette.grey[400],
            ...stylesMap.mainContainer
        },
        digitContainer:{
            borderRadius: '5px',
            backgroundColor: theme.palette.common.white,
            ...stylesMap.digitContainer
        },
        digits:{
            color: theme.palette.text.primary,
            fontWeight: 'bold',
            ...stylesMap.digits
        }
    }
    
    const getReversedIndex = (array, index ) => {
        return (array.length - 1) - index;
    }

    const computeCounter = () => {
        const nowMs = new Date().getTime();
        const endMs = endsDate.getTime();
        let diffMs = endMs - nowMs;
        if(diffMs > 0){
            const counter = [];
            REVERSED_COUNTER_TIMESCALE.forEach((timescale, index) => {
                let floatValue = toTimescaleFromMilliseconds(timescale, diffMs);
                const intValue  = Math.floor(floatValue); 
                diffMs = toMillisecondsFromTimescale(timescale, floatValue - intValue);
                const reversedIndex = getReversedIndex(DEFAULT_COUNTER, index);
                counter[reversedIndex] = intValue;
            });
            setCounter(counter);
        }else{
            setCounter(DEFAULT_COUNTER);
            clearInterval(intervalIdRef.current);
        }
    }
    const updateCounter = () =>{
        setCounter((prev) => {
            let updated = [];
            prev.forEach((timescaleValue, index) => {
                const isHour = index === 2
                let updatedTimescaleValue;
                if(index === 0){
                    updatedTimescaleValue = timescaleValue - 1;
                }else{
                    let accumulatedToUpdate;
                    if(index === prev.length - 1){
                        accumulatedToUpdate = 59 * (updated.length - 1) + 23;
                    }else{
                        accumulatedToUpdate = 59 * updated.length;
                    }
                    updatedTimescaleValue = updated.reduce((prev, current, ) => prev + current) === accumulatedToUpdate ? timescaleValue - 1 : timescaleValue;
                }
                if(updatedTimescaleValue < 0){
                    updatedTimescaleValue = prev.slice(index + 1).reduce((prev, current, ) => prev + current) > 0 ? isHour ? 23 : 59 : 0;
                }
                updated[index] = updatedTimescaleValue;
            })
            return updated
        })
    }
    useEffect(() => {
        if(isValidDate(endsDate)){
            computeCounter();
            const intervalId = setInterval(updateCounter, MIN_INTERVAL_MS)
            intervalIdRef.current = intervalId;
            return(() => {
                clearInterval(intervalId);
            })
        }
    }, [endsDate])
    return (
        <div className='count-down-main-container' style={styles.mainContainer}>
            <Typography variant='caption'>
                {label}
            </Typography>
            <div className='count-down-counter-container'>
                {counter.map((timescaleValue, index) => {
                    const reversedIndex = getReversedIndex(DEFAULT_COUNTER, index);
                    const values = `${timescaleValue}`.length < 2 ? `0${timescaleValue}` :`${timescaleValue}`;
                    const timescale = REVERSED_COUNTER_TIMESCALE[reversedIndex]; 
                    const timescaleAbbr = t(`${timescale}Abbr`, ' ', {count:1})[0]
                    return(
                        <div key={reversedIndex} className='count-down-counter-container-item' style={{ order:reversedIndex }}>
                            <div className='count-down-counter-digits-container'>
                                <div className='count-down-counter-digit-container' style={styles.digitContainer}>
                                    <Typography variant='body2' style={styles.digits}>
                                        {values}
                                    </Typography>
                                </div>
                            </div>
                            <Typography variant='caption' style={stylesMap.label}>
                                {timescaleAbbr.toUpperCase()}
                            </Typography>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default CountDown