import { Typography } from '@material-ui/core'
import { getFromSafeObject, textTransform } from '../../aux/aux'
import BasicConfigSelectorItem from '../BasicConfigSelectorItem/BasicConfigSelectorItem'
import './subscriptionConsumptionConfigSelectorItem.css'
import { useTranslation } from 'react-i18next'
import { fromCentTo2dec } from '../../aux/purchaseHelpers'
import { useEffect, useState } from 'react'

const SubscriptionConsumptionConfigSelectorItem = ({ productConsumptionOption, userProductPurchasabilityDetails, isBestPrice, isDefault, isSelected, isUnique, disabled}) => {
    const { t } = useTranslation('common');
    const productOptionAmount = getFromSafeObject(productConsumptionOption, 'consumptionDetails.details.purchasabilityDetails.details.amount') || {};
    const [disableMessage, setDisableMessage] = useState('');
    useEffect(() => {
        let disableMessage = ''
        if(userProductPurchasabilityDetails && !userProductPurchasabilityDetails.isPurchasable){
            const noPurchasabilityCause = getFromSafeObject(userProductPurchasabilityDetails, 'details.noPurchasabilityType');
            switch(noPurchasabilityCause){
                case 'wrongSubscriptionProductType':
                    const userSubscriptionTypeName = getFromSafeObject(userProductPurchasabilityDetails, 'details.userSubscription.type.name');
                    disableMessage = textTransform('title', t('subscriptionConsumptionConfigSelectorItem.noPurchasabilityCause.differentTypeActiveSubscription', {subscriptionTypeName: userSubscriptionTypeName}));
                    break;
                case 'unknownUserSubscription':
                default:
                    disableMessage = textTransform('title', t('cannotPurchaseThis'));
            }
        }
        setDisableMessage(disableMessage)
    },[userProductPurchasabilityDetails])
    return (
        productOptionAmount.finalAmount != null ?
                <BasicConfigSelectorItem 
                    isSelected={isSelected}
                    isBestPrice={isBestPrice}
                    isRecommended={isDefault}
                    isUnique={isUnique}
                    warningMessage={disableMessage}
                    disabled={disabled}
                >
                    <div className='subscription-consumption-config-selector-item-main-container'>
                        <div className='subscription-consumption-config-selector-item-term-container'>
                            <Typography variant='caption'>
                                {textTransform('title', getFromSafeObject(productConsumptionOption, 'typeProduct.term.translation.nameTranslated'))}
                            </Typography>
                        </div>
                        <div className='subscription-consumption-config-selector-item-value-container'>
                            <Typography variant="body2" >
                                {t('{{val, currency}}',{ val:fromCentTo2dec(productOptionAmount.finalAmount), currency: productOptionAmount.currencyId, trailingZeroDisplay:'stripIfInteger'})}
                            </Typography>
                        </div>
                    </div>
                </BasicConfigSelectorItem>
            :
            null
    )
}

export default SubscriptionConsumptionConfigSelectorItem