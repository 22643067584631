import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/styles';
import { Typography, Grid, Button, TextField } from '@material-ui/core';
import ButtonLoading from '../ButtonLoading/ButtonLoading';
import './createUserSystemFromSelection.css';
import { textTransform } from '../../aux/aux';
import { createNewUserSystem } from '../../services/userServices';
import { isCustomError } from '../../aux/errorHelpers';

const CreateUserSystemFromSelection = ({ userId, selectedSystem, onDidCreate=()=>{}, onFinish=()=>{}, onRender=()=>{}}) => {
    const theme = useTheme();
    const { t } = useTranslation('common', { keyPrefix: 'createUserSystemFromSelection'})
    const abortControllerRef = useRef(null);
    const [isCreating, setIsCreating] = useState(false);
    const [isError, setIsError] = useState(false)
    const [errorType, setErrorType] = useState({
        duplicateNickname:false,
        wrongDeviceOrSoftware:false,
        generalError:false,
    })
    const styleObject = {
        error:{
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main
        }
    };
    const SYSTEM_NICKNAME_MAX_LENGTH = 50;
    const [newUserSystemName, setNewUserSystemName] = useState(`${t("common:my")} ${selectedSystem.selectedDeviceName}`);
    
    const requestCreateUserSystem = async (userId, system, abortSignal) => {
        try{
            setIsCreating(true)
            const res = await createNewUserSystem( userId, system, 0, abortSignal);
            const newUserSystem = res.data;
            if(!abortSignal.aborted){
                await onDidCreate(newUserSystem, abortSignal);
                onFinish();
            }
        }catch(error){
            if(!abortSignal.aborted){
                if(isCustomError(error)){
                    const errorData = error.response.data;
                    switch(errorData.cause){
                        case 'db_101':
                            setErrorType(errorType => ({...errorType, 'duplicateNickname': true}))
                            break;
                        case 'db_104':
                            setErrorType(errorType => ({...errorType, 'wrongDeviceOrSoftware': true}))
                            break;
                        default:
                            setErrorType(errorType => ({...errorType, 'generalError': true}))
                            break;
                    }
                }else{
                    setErrorType(errorType => ({...errorType, 'generalError': true}))
                }
            }
        }finally{
            if(!abortSignal.aborted){
                setIsCreating(false)
            }
        }
    }

    const resetErrorType = () => {
        setErrorType({
            duplicateNickname:false,
            wrongDeviceOrSoftware:false,
            generalError:false,
        })
    }

    const getErrorMsg = () => {
        // There is currently no distinction
        let errorMsg = ''
        if(errorType.wrongDeviceOrSoftware){
            errorMsg = `${textTransform('title', t("sorryAnError"))}. ${textTransform('title', t("tryItLater"))}` 
        }else{
            errorMsg = `${textTransform('title', t("sorryAnError"))}. ${textTransform('title', t("tryItLater"))}`
        }
        return errorMsg;
    }

    const handleOnChange = (e) => {
        e.preventDefault();
        setNewUserSystemName(e.target.value.toLowerCase())
        if(isError){
            resetErrorType()
        }
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        const system = {
            softwareName: selectedSystem.selectedSoftwareName,
            deviceName: selectedSystem.selectedDeviceName,
            nickname: newUserSystemName,
            isDefault: true
        }
        const abortSignal = abortControllerRef.current.signal;
        await requestCreateUserSystem(userId, system, abortSignal);
    }

    const handleOnCancel = (e) => {
        onFinish()
    }

    
    useEffect(() => {
        const updatedIsError = Object.values(errorType).find(error => error === true) !== undefined;
        // if(errorType.wrongDeviceOrSoftware || errorType.generalError){
            //     setTimeout(() => setErrorType( errorType => ({...errorType, generalError:false, wrongDeviceOrSoftware:false})), 2000);
            // }
            setIsError(updatedIsError);
    },[errorType])
    
    useEffect(() => {
        abortControllerRef.current = new AbortController();
        return(() => {
            abortControllerRef.current.abort();
        })
    },[])
    
    useEffect(() => {
        onRender()
    })

    return (
        <Grid container direction='column'>
            <Grid item align='center' className='create-user-system-from-selection-form-item'>
                <Typography variant='body1'>
                    {t("doYouWantTocreateUserSystemWithSelection")}
                </Typography>
            </Grid>
            <form onSubmit={handleOnSubmit}>
                <Grid item className='create-user-system-from-selection-form-item'>
                    <TextField
                        type='text'
                        disabled={isCreating}
                        name='userSystemName'
                        value={newUserSystemName.toUpperCase()} 
                        onChange={handleOnChange} 
                        label={textTransform('title',t("systemName"))} 
                        inputProps={{
                            maxLength: SYSTEM_NICKNAME_MAX_LENGTH,
                        }}
                        error={isError && errorType.duplicateNickname}
                        helperText={isError && errorType.duplicateNickname ? t("duplicateNickname") : ''}
                        required 
                        fullWidth
                    />
                </Grid>
                <Grid item className='create-user-system-from-selection-form-item'>
                    <Grid container justifyContent='space-around'>
                        <Grid item xs={5}>
                            <ButtonLoading 
                            type='submit' 
                            name='actionSubmit' 
                            value='yes' 
                            variant='outlined' 
                            color='primary' 
                            isLoading={isCreating} 
                            label={t("common:yes")}
                            disabled={isCreating || (isError && (errorType.generalError || errorType.wrongDeviceOrSoftware))} 
                            fullWidth/>
                        </Grid>
                        <Grid item xs={5}>
                            <Button 
                            onClick={handleOnCancel} 
                            name='actionCancel' 
                            value='no' 
                            variant='outlined' 
                            color="secondary" 
                            disabled={isCreating}
                            fullWidth>
                                {t("common:no")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            <Grid item className={`create-user-system-from-selection-form-feedback-toggle ${isError && (errorType.generalError || errorType.wrongDeviceOrSoftware) ? 'show' : ''}`} style={styleObject.error}>
                <Typography variant='body2'>
                    {getErrorMsg()}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default CreateUserSystemFromSelection