import { Typography } from '@material-ui/core';
import { getFromSafeObject, textTransform } from '../../aux/aux'
import { fromCentTo2dec } from '../../aux/purchaseHelpers';
import './subscriptionConsumptionConfigSelectorCollapsedItem.css'
import { useTranslation } from 'react-i18next';

const SubscriptionConsumptionConfigSelectorCollapsedItem = ({ productConsumptionOption }) => {
    const { t } = useTranslation('common');
    const productOptionAmount = getFromSafeObject(productConsumptionOption, 'consumptionDetails.details.purchasabilityDetails.details.amount');
    return (
        <div className='subscription-consumption-config-selector-collapsed-item-main-container'>
            <div className='subscription-consumption-config-selector-collapsed-item-term-container'>
                <Typography variant='caption'>
                    {textTransform('title', getFromSafeObject(productConsumptionOption, 'typeProduct.term.translation.nameTranslated'))}
                </Typography>
            </div>
            <div className='subscription-consumption-config-selector-collapsed-item-value-container'>
                <Typography variant="body2" >
                    {t('{{val, currency}}',{ val:fromCentTo2dec(productOptionAmount.finalAmount), currency: productOptionAmount.currencyId, trailingZeroDisplay:'stripIfInteger'})}
                </Typography>
            </div>
        </div>
    )
}

export default SubscriptionConsumptionConfigSelectorCollapsedItem